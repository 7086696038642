import React from 'react';
import PropTypes from 'prop-types';
import PrintProvider, { Print, NoPrint } from 'react-easy-print';
import ZaglavljeTvrtke from './common_components/ZaglavljeTvrtke';
import { Button, Table } from 'semantic-ui-react';
import localization from '../../locales/localization';
import DatumIMjestoObicno from './common_components/DatumIMjestoObicno';
import moment from 'moment';
import FormatIznosa from '../common_components/FormatIznosa';
import { YEAR_EURO_WAS_INTRODUCED } from '../../utils/Valute';

const styles = {
    container: {
        padding: 24
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    zaglavljaRight: {
        width: '50%',
        display: 'flex'
    },
    zaglavlja: {
        width: '50%'
    },
    zaglavljeTvrtke: {
        paddingBottom: 24
    },
    datumImjesto: {
        paddingBottom: 8
    },
    p: {
        fontSize: 10
    },
    pdvmu: {
        fontSize: 16,
        textAlign: 'right'
    },
    title: {
        padding: 12,
        textAlign: 'center'
    },
    table: {
        fontSize: 'xx-small'
    },
    sumTable: {
        fontSize: 'xx-small',
        width: '50%'
    },
    tableCell: {
        paddingTop: 'unset',
        paddingBottom: 'unset'
    },
    tableCellKol: {
        paddingTop: 'unset',
        paddingBottom: 'unset',
        fontFamily: 'monospace'
    },
    footerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: 64,
        paddingBottom: 16
    },
    printButton: {
        textAlign: 'center',
        paddingBottom: 8
    }
};

export default class RekapitulacijaRacuna extends React.Component {
    constructor(props) {
        super(props);
        let rekapitulacija = JSON.parse(sessionStorage.getItem(this.props.match.params.id))
        let isKuna = rekapitulacija.godina < YEAR_EURO_WAS_INTRODUCED
        this.state = {
            document: rekapitulacija,
            isKuna: isKuna
        };
        this.printDocument = this.printDocument.bind(this);
        this.calculateUkupno = this.calculateUkupno.bind(this);
    }

    printDocument() {
        window.print();
    }

    calculateUkupno() {
        let ukupno = 0;
        this.state.document.otkupniBlokovi.forEach(otkupniBlok => {
            otkupniBlok.skladisniUlazi.forEach(su => {
                let kolicina = su.kolicina;
                if (otkupniBlok.isStorniran === null) {
                    kolicina = -su.kolicina;
                }
                ukupno += kolicina * (this.state.isKuna ? su.jedNabavnaCijenaHrk : su.jedNabavnaCijena);
            });
        });
        return ukupno;
    }

    render() {
        let rbr = 0;
        return (
            <PrintProvider>
                <Print>
                    <div style={styles.container}>
                        <div style={styles.headerContainer}>
                            <div style={styles.zaglavlja}>
                                <div style={styles.zaglavljeTvrtke}>
                                    <ZaglavljeTvrtke firma={this.state.document.otkupniBlokovi[0].firma} />
                                </div>
                            </div>
                            <div style={styles.zaglavljaRight}>
                                <div style={styles.zaglavlja}>
                                    <div style={styles.datumImjesto}>
                                        <DatumIMjestoObicno
                                            datum={moment().format('DD.MM.YYYY.')}
                                            mjesto={this.state.document.poslovnica ? this.state.document.poslovnica.naselje : '-'}
                                        />
                                    </div>
                                    <p>
                                        {localization.printingComponents.common.skladiste + ': '}
                                        <b>
                                            {this.state.document.poslovnica
                                                ? this.state.document.poslovnica.naziv
                                                : localization.printingComponents.common.skladiste}
                                        </b>
                                    </p>
                                </div>
                                <div style={styles.zaglavlja}>
                                    <p style={styles.pdvmu}>
                                        <b>{'PDV - MU'}</b>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div style={styles.title}>
                            <h3>{this.state.document.naslov}</h3>
                        </div>
                        <Table structured striped stackable style={styles.table}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={styles.tableCell} rowSpan={2} />
                                    <Table.HeaderCell style={styles.tableCell} rowSpan={2}>
                                        {localization.rekapitulacije.rekapitulacijaOtkupnihBlokova.brojOtkupnogBloka}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell} rowSpan={2}>
                                        {localization.rekapitulacije.rekapitulacijaOtkupnihBlokova.datumDokumenta}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell}>
                                        {localization.rekapitulacije.rekapitulacijaOtkupnihBlokova.klijent}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell}>
                                        {localization.rekapitulacije.rekapitulacijaOtkupnihBlokova.nazivArtikla}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell} textAlign="right" rowSpan={2} collapsing>
                                        {localization.rekapitulacije.rekapitulacijaOtkupnihBlokova.kolicina}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell} textAlign="right" rowSpan={2} collapsing>
                                        {localization.rekapitulacije.rekapitulacijaOtkupnihBlokova.nabavnaVrijednost}
                                    </Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.HeaderCell style={styles.tableCell}>
                                        {localization.rekapitulacije.rekapitulacijaOtkupnihBlokova.oib}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell}>
                                        {localization.rekapitulacije.rekapitulacijaOtkupnihBlokova.sifraArtikla}
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.state.document.otkupniBlokovi.map((otkupniBlok, index) => {
                                    return otkupniBlok.skladisniUlazi.map((su, suIndex) => {
                                        let kolicina = su.kolicina;
                                        if (otkupniBlok.isStorniran === null) {
                                            kolicina = -su.kolicina;
                                        }
                                        rbr++;
                                        return (
                                            <React.Fragment key={index}>
                                                <Table.Row>
                                                    <Table.Cell style={styles.tableCell} collapsing rowSpan={2}>
                                                        {rbr + '.'}
                                                    </Table.Cell>
                                                    <Table.Cell style={styles.tableCell} collapsing rowSpan={2}>
                                                        {otkupniBlok.rbrOtkupniBlok}
                                                    </Table.Cell>
                                                    <Table.Cell style={styles.tableCell} collapsing rowSpan={2}>
                                                        {moment(otkupniBlok.datumIVrijeme).format('DD.MM.YYYY.')}
                                                    </Table.Cell>
                                                    <Table.Cell style={styles.tableCell} collapsing>
                                                        {otkupniBlok.fizickaOsoba.puniNaziv.toUpperCase()}
                                                    </Table.Cell>
                                                    <Table.Cell style={styles.tableCell} collapsing>
                                                        {su.predmet.naziv}
                                                    </Table.Cell>
                                                    <Table.Cell style={styles.tableCellKol} collapsing textAlign="right" rowSpan={2}>
                                                        {kolicina}
                                                    </Table.Cell>
                                                    <Table.Cell style={styles.tableCellKol} collapsing textAlign="right" rowSpan={2}>
                                                        <FormatIznosa value={kolicina * (this.state.isKuna ? su.jedNabavnaCijenaHrk : su.jedNabavnaCijena)} suffix={this.state.isKuna ? localization.common.hrk : null} />
                                                    </Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell style={styles.tableCell} collapsing>
                                                        {otkupniBlok.fizickaOsoba.idBroj}
                                                    </Table.Cell>
                                                    <Table.Cell style={styles.tableCell} collapsing>
                                                        {su.predmet.sifra}
                                                    </Table.Cell>
                                                </Table.Row>
                                            </React.Fragment>
                                        );
                                    });
                                })}
                            </Table.Body>
                            <Table.Footer>
                                <Table.Row>
                                    <Table.HeaderCell style={styles.tableCell} colSpan={6} />
                                    <Table.HeaderCell style={styles.tableCellKol} textAlign="right" collapsing>
                                        <b>
                                            <FormatIznosa value={this.calculateUkupno()} suffix={this.state.isKuna ? localization.common.hrk : null} />
                                        </b>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        </Table>
                    </div>
                </Print>
                <NoPrint>
                    <div style={styles.printButton}>
                        <Button color="green" icon="print" size="massive" content={localization.common.ispis} onClick={this.printDocument} />
                    </div>
                </NoPrint>
            </PrintProvider>
        );
    }
}

RekapitulacijaRacuna.propTypes = {
    match: PropTypes.object.isRequired
};
