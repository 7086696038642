import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import localization from '../../locales/localization';
import endpoints from '../../environments/endpoints';
import { storeDocumentAndGenerateKey, findItemWithLatestDate } from '../../utils/PrintingDocumentsHandler';
import { Button, Dimmer, Dropdown, Grid, Header, Loader, Segment, Select, Table } from 'semantic-ui-react';
import moment from 'moment';
import * as RezervacijaDataAccess from '../../data_access/RezervacijaDataAccess';
import { bindActionCreators } from 'redux';
import enums from '../../utils/Enums';
import NesluzbenaNapomena from '../common_components/NesluzbenaNapomena';
import InputField from '../common_components/InputField';
import FormatIznosa from '../common_components/FormatIznosa';

const styles = {
    container: {
        textAlign: 'center'
    },
    kolicinaIcijena: {
        fontFamily: 'monospace'
    },
    dualnoInput: {
        paddingRight: 12
    },
    actionButtons: {
        marginTop: 16,
        display: 'flex',
        justifyContent: 'flex-end'
    }
};

const racuni = [
    {
        tip: enums.nacinPlacanja.GOTOVINSKI_RACUN,
        naziv: localization.prodaja.gotovinskiRacun
    },
    {
        tip: enums.nacinPlacanja.KARTICNI_RACUN,
        naziv: localization.prodaja.karticniRacun
    },
    {
        tip: enums.nacinPlacanja.DUALNI_RACUN,
        naziv: localization.prodaja.dualniRacun
    }
];

const tipNapomene = {
    RAZLICITA_CIJENA: 'razlicita_cijena',
    PREMALO_NAKNADA: 'premalo_naknada'
};

class PodizanjeRezervacijeModal extends React.Component {
    constructor(props) {
        super(props);
        let mjeseciRezervacije = this.calculateInitialMonths();
        this.state = {
            mjeseciRezervacije: mjeseciRezervacije,
            defaultMjeseciRezervacije: mjeseciRezervacije.map(mr => Object.assign({}, mr)),
            maxBrojMjeseci: _.maxBy(mjeseciRezervacije, 'brMjeseci').brMjeseci,
            cijene: this.calculateInitialPrice(),
            kartica: null,
            brojRata: 1,
            cijenaNaKarticu: 0,
            ukupno: undefined,
            nesluzbenaNapomena: '',
            nacinPlacanja: enums.nacinPlacanja.GOTOVINSKI_RACUN,
            obveznaNesluzbenaNapomena: false,
            enablePodigniRezervacijuButton: true,
            loader: false
        };
        this.state.ukupno = this.calculateUkupno();
        this.onChangeMjeseci = this.onChangeMjeseci.bind(this);
        this.onChangeCijena = this.onChangeCijena.bind(this);
        this.onChange = this.onChange.bind(this);
        this.setNapomenaIUkupno = this.setNapomenaIUkupno.bind(this);
        this.calculateUkupno = this.calculateUkupno.bind(this);
        this.onChangeDualnoNaKarticu = this.onChangeDualnoNaKarticu.bind(this);
        this.enablePodigniRezervacijuButton = this.enablePodigniRezervacijuButton.bind(this);
        this.checkKomponentePodizanja = this.checkKomponentePodizanja.bind(this);
        this.showLoader = this.showLoader.bind(this);
        this.hideLoader = this.hideLoader.bind(this);
        this.onPodigniRezervacije = this.onPodigniRezervacije.bind(this);
        this.openRacun = this.openRacun.bind(this);
    }

    calculateInitialMonths() {
        return this.props.zahtjevRezervacije.rezervacije.map(r => {
            let noviDatumIsteka = new Date(r.datumIsteka);
            let brMjeseci = 1;
            while (noviDatumIsteka.getTime() < Date.now()) {
                noviDatumIsteka.setMonth(noviDatumIsteka.getMonth() + 1);
                brMjeseci++;
            }
            return {
                rezervacijaId: r.id,
                brMjeseci: brMjeseci
            };
        });
    }

    calculateInitialPrice() {
        return this.props.zahtjevRezervacije.rezervacije.map(r => {
            return {
                rezervacijaId: r.id,
                cijena: undefined
            };
        });
    }

    onChangeMjeseci(rezervacijaId, e, { value }) {
        this.setState(
            {
                mjeseciRezervacije: this.state.mjeseciRezervacije.map(mr => {
                    if (mr.rezervacijaId === rezervacijaId) {
                        mr.brMjeseci = value;
                    }
                    return mr;
                })
            },
            this.setNapomenaIUkupno
        );
    }

    onChangeCijena(rezervacijaId, e, { value }) {
        this.setState(
            {
                cijene: this.state.cijene.map(c => {
                    if (c.rezervacijaId === rezervacijaId) {
                        c.cijena = value;
                    }
                    return c;
                })
            },
            this.setNapomenaIUkupno
        );
    }

    onChange(e, { name, value }) {
        this.setState({ [name]: value }, this.setNapomenaIUkupno);
    }

    setNapomenaIUkupno() {
        let obveznaNesluzbenaNapomena = undefined;
        this.props.zahtjevRezervacije.rezervacije.forEach(r => {
            let brMjeseci = this.state.mjeseciRezervacije.find(mr => mr.rezervacijaId === r.id).brMjeseci;
            let defaultBrMjeseci = this.state.defaultMjeseciRezervacije.find(mr => mr.rezervacijaId === r.id).brMjeseci;
            let cijena = this.state.cijene.find(c => c.rezervacijaId === r.id).cijena;
            let defaultnaCijena = Math.round((r.jedNabavnaCijena + brMjeseci * (r.jedProdajnaCijena - r.jedNabavnaCijena)) * 100) / 100;
            if (!this.state.nesluzbenaNapomena) {
                if (cijena !== undefined && cijena != defaultnaCijena) {
                    obveznaNesluzbenaNapomena = tipNapomene.RAZLICITA_CIJENA;
                } else if (brMjeseci !== defaultBrMjeseci) {
                    obveznaNesluzbenaNapomena = tipNapomene.PREMALO_NAKNADA;
                }
            }
        });
        this.setState(
            {
                ukupno: this.calculateUkupno(),
                obveznaNesluzbenaNapomena: obveznaNesluzbenaNapomena
            },
            this.enablePodigniRezervacijuButton
        );
    }

    calculateUkupno() {
        let ukupno = 0,
            missingData = false;
        this.props.zahtjevRezervacije.rezervacije.forEach(r => {
            let cijena = this.state.cijene.find(c => c.rezervacijaId === r.id).cijena;
            let brMjeseci = this.state.mjeseciRezervacije.find(c => c.rezervacijaId === r.id).brMjeseci;
            if (cijena === '') {
                missingData = true;
            } else if (cijena !== undefined) {
                ukupno += cijena * r.kolicina;
            } else {
                let tempUkupno = r.jedNabavnaCijena + brMjeseci * (r.jedProdajnaCijena - r.jedNabavnaCijena);
                if (this.state.nacinPlacanja !== enums.nacinPlacanja.GOTOVINSKI_RACUN) {
                    tempUkupno *= 1 + 1 / this.props.appPrefs.postotakZaKarticnoPlacanje;
                }
                ukupno += Math.round(tempUkupno * 100) / 100 * r.kolicina;
            }
        });
        return !missingData ? Math.round(ukupno * 100) / 100 : undefined;
    }

    onChangeDualnoNaKarticu(e, { value }) {
        this.setState(
            {
                cijenaNaKarticu: value
            },
            this.enablePodigniRezervacijuButton
        );
    }

    enablePodigniRezervacijuButton() {
        this.setState({ enablePodigniRezervacijuButton: this.checkKomponentePodizanja() });
    }

    checkKomponentePodizanja() {
        if (this.state.nacinPlacanja === enums.nacinPlacanja.KARTICNI_RACUN || this.state.nacinPlacanja === enums.nacinPlacanja.DUALNI_RACUN) {
            if (this.state.kartica === null) {
                return false;
            }
            if (!this.state.brojRata) {
                return false;
            }
            if (this.state.nacinPlacanja === enums.nacinPlacanja.DUALNI_RACUN) {
                if (!this.state.cijenaNaKarticu) {
                    return false;
                }
            }
        }
        if (this.state.obveznaNesluzbenaNapomena && !this.state.nesluzbenaNapomena) {
            return false;
        }
        return true;
    }

    showLoader(callback) {
        this.setState(
            {
                loader: true
            },
            callback
        );
    }

    hideLoader(callback, data) {
        this.setState(
            {
                loader: false
            },
            callback.bind(this, data)
        );
    }

    onPodigniRezervacije() {
        let iznosGotovina = 0;
        if (this.state.nacinPlacanja === enums.nacinPlacanja.GOTOVINSKI_RACUN) {
            iznosGotovina = this.state.ukupno;
        }
        if (this.state.nacinPlacanja === enums.nacinPlacanja.DUALNI_RACUN) {
            iznosGotovina = this.state.ukupno - parseFloat(this.state.cijenaNaKarticu);
        }
        let rezervacijeData = {
            nesluzbenaNapomena: this.state.nesluzbenaNapomena || null,
            rezervacije: this.props.zahtjevRezervacije.rezervacije.map(r => {
                let cijena = this.state.cijene.find(c => c.rezervacijaId === r.id).cijena;
                let brMjeseci = this.state.mjeseciRezervacije.find(c => c.rezervacijaId === r.id).brMjeseci;
                if (cijena !== undefined) {
                    cijena = parseFloat(cijena);
                } else {
                    cijena = r.jedNabavnaCijena + brMjeseci * (r.jedProdajnaCijena - r.jedNabavnaCijena);
                    if (this.state.nacinPlacanja !== enums.nacinPlacanja.GOTOVINSKI_RACUN) {
                        cijena = cijena * (1 + 1 / this.props.appPrefs.postotakZaKarticnoPlacanje);
                    }
                    cijena = Math.round(cijena * 100) / 100;
                }
                return {
                    rezervacijaId: r.id,
                    jedCijenaPredmeta: cijena
                };
            }),
            iznosGotovina: iznosGotovina,
            karticnoPlacanje:
                this.state.nacinPlacanja !== enums.nacinPlacanja.GOTOVINSKI_RACUN
                    ? {
                          karticaId: this.state.kartica,
                          iznosKarticno:
                              this.state.nacinPlacanja !== enums.nacinPlacanja.DUALNI_RACUN ? this.state.ukupno : parseFloat(this.state.cijenaNaKarticu),
                          brojRata: this.state.brojRata
                      }
                    : null
        };
        this.props.rezervacijaDataAccess.podigniRezervacije(
            this.props.zahtjevRezervacije.id,
            rezervacijeData,
            (rezervacija) => {
                this.hideLoader(() => this.openRacun(rezervacija));
                this.props.onPodizanjeSuccess();
            }
        );
    }

    openRacun(rezervacija) {
        window.open(endpoints.appEndpoints.print.racun + storeDocumentAndGenerateKey(findItemWithLatestDate(rezervacija.racuni)), '_blank');
        this.props.closeModal();
    }

    render() {
        return (
            <div style={styles.container}>
                <Segment>
                    <Grid stackable>
                        <Grid.Row columns={3} divided>
                            <Grid.Column verticalAlign="middle">
                                <Header as="h3">{localization.prodaja.nacinPlacanja + ':'}</Header>
                                <Select
                                    options={racuni.map(racun => {
                                        return { key: racun.tip, text: racun.naziv, value: racun.tip };
                                    })}
                                    name="nacinPlacanja"
                                    value={this.state.nacinPlacanja}
                                    onChange={this.onChange}
                                    search
                                />
                            </Grid.Column>
                            {this.state.nacinPlacanja === enums.nacinPlacanja.KARTICNI_RACUN ||
                            this.state.nacinPlacanja === enums.nacinPlacanja.DUALNI_RACUN ? (
                                <React.Fragment>
                                    <Grid.Column verticalAlign="middle">
                                        <Header as="h3">{localization.prodaja.kartica + ':'}</Header>
                                        <Select
                                            upward
                                            options={this.props.kartice.map(card => {
                                                return { key: card.id, text: card.naziv, value: card.id };
                                            })}
                                            name="kartica"
                                            value={this.state.kartica}
                                            onChange={this.onChange}
                                            search
                                            placeholder={localization.prodaja.odaberiKarticu}
                                        />
                                    </Grid.Column>
                                    <Grid.Column verticalAlign="middle">
                                        <Header as="h3">{localization.prodaja.brojRata + ':'}</Header>
                                        <Select
                                            upward
                                            options={Array.from(new Array(12), (val, index) => {
                                                return { key: index, text: index + 1, value: index + 1 };
                                            })}
                                            name="brojRata"
                                            value={this.state.brojRata}
                                            onChange={this.onChange}
                                            search
                                            placeholder={localization.prodaja.odaberiBrojRata}
                                        />
                                    </Grid.Column>
                                </React.Fragment>
                            ) : null}
                        </Grid.Row>
                        {this.state.nacinPlacanja === enums.nacinPlacanja.DUALNI_RACUN ? (
                            <Grid.Row>
                                <Grid.Column verticalAlign="middle">
                                    <Segment tertiary>
                                        <InputField
                                            label={localization.common.eur}
                                            placeholder={localization.prodaja.cijenaNaKarticu}
                                            name="cijenaNaKarticu"
                                            value={this.state.cijenaNaKarticu}
                                            onChange={this.onChangeDualnoNaKarticu}
                                            maxValue={this.state.ukupno}
                                            style={styles.dualnoInput}
                                        />
                                        <span>
                                            {' / '}
                                            <b>
                                                <FormatIznosa value={this.state.ukupno} />
                                            </b>
                                        </span>
                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>
                        ) : null}
                    </Grid>
                </Segment>
                <Table celled structured stackable selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>{localization.artikl.sifra}</Table.HeaderCell>
                            <Table.HeaderCell>{localization.artikl.naziv}</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center" collapsing>
                                {localization.rezervacije.naknadaZa}
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center" collapsing>
                                {localization.common.kolicina}
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">{localization.rezervacije.datumIsteka}</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center" collapsing>
                                {localization.rezervacije.jedinicnaCijena}
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center" collapsing>
                                {localization.rezervacije.cijenaRezervacije}
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.props.zahtjevRezervacije.rezervacije.map(rezervacija => {
                            let brMjeseci = this.state.mjeseciRezervacije.find(c => c.rezervacijaId === rezervacija.id).brMjeseci;
                            let cijena = this.state.cijene.find(c => c.rezervacijaId === rezervacija.id).cijena;
                            if (cijena === undefined) {
                                cijena = rezervacija.jedNabavnaCijena + brMjeseci * (rezervacija.jedProdajnaCijena - rezervacija.jedNabavnaCijena);
                                if (this.state.nacinPlacanja !== enums.nacinPlacanja.GOTOVINSKI_RACUN) {
                                    cijena = cijena * (1 + 1 / this.props.appPrefs.postotakZaKarticnoPlacanje);
                                }
                                cijena = Math.round(cijena * 100) / 100;
                            }
                            return (
                                <Table.Row key={rezervacija.id}>
                                    <Table.Cell>{rezervacija.predmet.sifra}</Table.Cell>
                                    <Table.Cell>{rezervacija.predmet.naziv}</Table.Cell>
                                    <Table.Cell textAlign="center" collapsing>
                                        <Dropdown
                                            selection
                                            compact
                                            value={brMjeseci}
                                            onChange={this.onChangeMjeseci.bind(this, rezervacija.id)}
                                            options={Array.from(new Array(this.state.maxBrojMjeseci), (val, index) => {
                                                return {
                                                    text: index + 1,
                                                    value: index + 1
                                                };
                                            })}
                                        />
                                        {'  '}
                                        {brMjeseci === 1
                                            ? localization.common.mjesec
                                            : [2, 3, 4].includes(brMjeseci)
                                            ? localization.common.mjeseca
                                            : localization.common.mjeseci}
                                    </Table.Cell>
                                    <Table.Cell textAlign="right" collapsing style={styles.kolicinaIcijena}>
                                        {rezervacija.kolicina}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">{moment(rezervacija.datumIsteka).format('DD.MM.YYYY.')}</Table.Cell>
                                    <Table.Cell textAlign="center" collapsing>
                                        <InputField
                                            label={localization.common.eur}
                                            placeholder={localization.common.cijena}
                                            name="cijena"
                                            value={cijena}
                                            onChange={this.onChangeCijena.bind(this, rezervacija.id)}
                                        />
                                    </Table.Cell>
                                    <Table.Cell textAlign="right" collapsing style={styles.kolicinaIcijena}>
                                        <FormatIznosa value={cijena * rezervacija.kolicina} />
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan={5} />
                            <Table.HeaderCell textAlign="center">
                                <b>{localization.common.ukupno + ':'}</b>
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="right" collapsing style={styles.kolicinaIcijena}>
                                <FormatIznosa value={this.state.ukupno} />
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
                <NesluzbenaNapomena
                    onChangeNapomena={this.onChange}
                    placeholder={localization.common.nesluzbenaNapomena}
                    required={!!this.state.obveznaNesluzbenaNapomena}
                    requiredText={
                        this.state.obveznaNesluzbenaNapomena === tipNapomene.RAZLICITA_CIJENA
                            ? localization.rezervacije.podizanjeRezervacijeObveznaNesluzbenaNapomena
                            : localization.rezervacije.podizanjeRezervacijePremaliBrojMjesecnihNaknada
                    }
                />
                <div style={styles.actionButtons}>
                    <Button icon="close" content={localization.common.odustani} onClick={this.props.closeModal} />
                    <Button
                        icon="calendar check"
                        color="green"
                        disabled={!this.state.enablePodigniRezervacijuButton}
                        content={localization.rezervacije.podigniRezervacije}
                        onClick={this.showLoader.bind(this, this.onPodigniRezervacije)}
                    />
                </div>
                <Dimmer active={this.state.loader} page>
                    <Loader />
                </Dimmer>
            </div>
        );
    }
}

PodizanjeRezervacijeModal.propTypes = {
    zahtjevRezervacije: PropTypes.object.isRequired,
    appPrefs: PropTypes.object.isRequired,
    kartice: PropTypes.array.isRequired,
    rezervacijaDataAccess: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
    onPodizanjeSuccess: PropTypes.func.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {
        appPrefs: state.appPreferencesReducer.appPrefs,
        kartice: state.karticeReducer.kartice || []
    };
};

let mapDispatchToProps = function(dispatch) {
    return {
        rezervacijaDataAccess: bindActionCreators(RezervacijaDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PodizanjeRezervacijeModal);
