import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Accordion, Button, Dimmer, Grid, Header, Icon, Loader, Message, Modal, Pagination, Search, Segment, Table } from 'semantic-ui-react';

import localization from '../../locales/localization';
import endpoints from '../../environments/endpoints';
import enums from '../../utils/Enums';
import icons from '../../utils/DefinedIcons';
import { storeDocumentAndGenerateKey } from '../../utils/PrintingDocumentsHandler';
import moment from 'moment';
import SearchResultsMessage from '../common_components/SearchResultsMessage';
import ImageUploadModal from '../common_components/ImageUploadModal';
import Diskusija from '../common_components/Diskusija';
import { bindActionCreators } from 'redux';
import * as RadniNalogDataAccess from '../../data_access/RadniNalogDataAccess';
import RadniNalogContent from './content_components/RadniNalogContent';
import FormatIznosa from '../common_components/FormatIznosa';
import FilterSortButton from '../common_components/FilterSortButton';

const styles = {
    headerTable: {
        padding: '.75em 1em'
    },
    iconCell: {
        width: 50
    },
    brojRadnogNalogaCell: {
        width: 180
    },
    klijentCell: {
        width: 350
    },
    ukupnaCijenaCell: {
        width: 200
    },
    accordionHeaderContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    accordionHeaderText: {
        paddingLeft: 4,
        paddingRight: 2,
        paddingBottom: '0.5em'
    },
    uslugeContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between'
    },
    uslugeHeader: {
        marginBottom: 8
    },
    actionButtons: {
        marginTop: 14,
        display: 'flex',
        justifyContent: 'flex-end'
    },
    semaforStyle: {
        marginLeft: 4
    }
};

const modaliEnums = {
    galerijaSlika: 'galerijaSlika',
    arhiviranjeRadnogNaloga: 'arhiviranjeRadnogNaloga'
};

const ITEMS_PER_PAGE = 10;

class RadniNalozi extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSearchLoading: false,
            value: '',
            radniNalozi: this.props.radniNalozi,
            results: this.props.radniNalozi,
            activeWorkTasks: [],
            activeComments: [],
            activeModal: undefined,
            sluzbenaNapomena: '',
            nesluzbenaNapomena: '',
            noveNapomene: [],
            loader: !this.props.radniNaloziCache,
            filter: 0,
            pageActive: 1
        };
        this.onNoviRadniNalog = this.onNoviRadniNalog.bind(this);
        this.calculatePage = this.calculatePage.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
        this.applyFilterAndSort = this.applyFilterAndSort.bind(this);
        this.filter = this.filter.bind(this);
        this.resetSearchComponent = this.resetSearchComponent.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.search = this.search.bind(this);
        this.onChangeNovaNapomena = this.onChangeNovaNapomena.bind(this);
        this.calculateUkupno = this.calculateUkupno.bind(this);
        this.expandRadniNalog = this.expandRadniNalog.bind(this);
        this.expandComments = this.expandComments.bind(this);
        this.ispisRadnogNaloga = this.ispisRadnogNaloga.bind(this);
        this.arhiviranjeRadnogNaloga = this.arhiviranjeRadnogNaloga.bind(this);
        this.dodajNapomenu = this.dodajNapomenu.bind(this);
        this.resetNapomena = this.resetNapomena.bind(this);
        this.showHideModal = this.showHideModal.bind(this);
        this.showLoader = this.showLoader.bind(this);

        if (sessionStorage.userRole !== enums.tipKorisnika.DJELATNIK) {
            this.FILTER_ITEMS = [localization.common.svePoslovnice];
            this.props.poslovnice.forEach(p => this.FILTER_ITEMS.push(p.naziv));
        }
    }

    componentWillMount() {
        this.resetSearchComponent();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.radniNaloziCache) {
            this.setState({
                radniNalozi: nextProps.radniNalozi,
                results: nextProps.radniNalozi,
                loader: false
            });
        }
    }

    onNoviRadniNalog() {
        this.props.history.push(endpoints.appEndpoints.radniNalog);
    }

    calculatePage(activePage) {
        let startItem = (activePage - 1) * ITEMS_PER_PAGE;
        let endItem = activePage * ITEMS_PER_PAGE;
        return [startItem, endItem];
    }

    onChangePage(e, { activePage }) {
        this.setState({
            pageActive: activePage
        });
    }

    applyFilterAndSort(filter, sort) {
        this.setState(
            {
                filter: filter,
                radniNalozi: this.filter(this.props.radniNalozi, filter)
            },
            this.search
        );
    }

    filter(radniNalozi, filter) {
        switch (this.FILTER_ITEMS[filter]) {
            case localization.common.svePoslovnice: {
                return radniNalozi;
            }
            default: {
                radniNalozi = _.filter(radniNalozi, o => {
                    return o.poslovnica.naziv === this.FILTER_ITEMS[filter];
                });
                return radniNalozi;
            }
        }
    }

    resetSearchComponent() {
        this.setState({
            isSearchLoading: false,
            value: ''
        });
    }

    handleSearchChange(e, { value }) {
        this.setState({
            isSearchLoading: true,
            value: value,
            pageActive: 1
        });

        setTimeout(this.search, 500);
    }

    search() {
        const re = new RegExp(_.escapeRegExp(this.state.value), 'i');
        const isMatch = result =>
            re.test(result.rbrBlokRadnihNaloga) || re.test(result.klijent.ime) || re.test(result.klijent.prezime) || re.test(result.klijent.naziv);

        this.setState({
            isSearchLoading: false,
            results: _.filter(this.state.radniNalozi, isMatch)
        });
    }

    onChangeNovaNapomena(id, e, { value }) {
        let noveNapomene = [];
        let index = this.state.noveNapomene.findIndex(el => el.id === id);
        if (index > -1) {
            noveNapomene = [...this.state.noveNapomene];
            noveNapomene[index] = {
                id: id,
                napomena: value
            };
        } else {
            noveNapomene = [
                ...this.state.noveNapomene,
                {
                    id: id,
                    napomena: value
                }
            ];
        }
        this.setState({
            noveNapomene: noveNapomene
        });
    }

    calculateUkupno(radniNalozi) {
        let ukupno = 0;
        radniNalozi.forEach(usluga => {
            ukupno += usluga.kolicinaUsluge * usluga.ocekivanaJedCijenaUsluge;
            if (usluga.stavkeRadnogNaloga.length) {
                usluga.stavkeRadnogNaloga.forEach(srn => {
                    ukupno += srn.kolicina * srn.jedCijenaSkladisneJedinke;
                });
            }
        });
        return ukupno;
    }

    expandRadniNalog(e, { index }) {
        let activeWorkTasks = [];
        if (this.state.activeWorkTasks.includes(index)) {
            activeWorkTasks = _.xor(this.state.activeWorkTasks, [index]);
        } else {
            activeWorkTasks = [...this.state.activeWorkTasks, index];
        }
        this.setState({
            activeWorkTasks: activeWorkTasks
        });
    }

    expandComments(e, { index }) {
        let activeComments = [];
        if (this.state.activeComments.includes(index)) {
            activeComments = _.xor(this.state.activeComments, [index]);
        } else {
            activeComments = [...this.state.activeComments, index];
        }
        this.setState({
            activeComments: activeComments
        });
    }

    ispisRadnogNaloga(radniNalog) {
        window.open(endpoints.appEndpoints.print.radniNalog + storeDocumentAndGenerateKey(radniNalog), '_blank');
    }

    arhiviranjeRadnogNaloga(radniNalogId) {
        this.props.radniNalogDataAccess.arhivirajRadniNalog(radniNalogId, this.showHideModal.bind(this, false));
    }

    dodajNapomenu(radniNalogId) {
        this.props.radniNalogDataAccess.dodavanjeNesluzbeneNapomeneNaRadniNalog(
            radniNalogId,
            this.state.noveNapomene.find(el => el.id === radniNalogId).napomena,
            this.resetNapomena.bind(this, radniNalogId)
        );
    }

    resetNapomena(radniNalogId) {
        let index = this.state.noveNapomene.findIndex(el => el.id === radniNalogId);
        this.setState({
            noveNapomene: this.state.noveNapomene.slice(0, index).concat(this.state.noveNapomene.slice(index + 1))
        });
    }

    showHideModal(value, name, sifra) {
        if (value) {
            this.setState({
                activeModal: {
                    type: name,
                    id: sifra
                }
            });
        } else {
            this.setState({ activeModal: undefined });
        }
    }

    showLoader(callback) {
        this.setState(
            {
                loader: true
            },
            callback
        );
    }

    render() {
        let [startItem, endItem] = this.calculatePage(this.state.pageActive);
        let totalPages = Math.ceil(this.state.results.length / ITEMS_PER_PAGE);
        let res = this.state.results.slice(startItem, endItem);
        return (
            <div>
                <Segment tertiary>
                    <Grid stackable>
                        <Grid.Row columns={6}>
                            <Grid.Column verticalAlign="middle">
                                <Header as="h2">{localization.radniNalozi.radniNalozi}</Header>
                            </Grid.Column>
                            <Grid.Column verticalAlign="middle">
                                <Search
                                    input={{ fluid: true }}
                                    loading={this.state.isSearchLoading}
                                    onSearchChange={this.handleSearchChange}
                                    value={this.state.value}
                                    open={false}
                                />
                            </Grid.Column>
                            {sessionStorage.userRole === enums.tipKorisnika.DJELATNIK ? (
                                <React.Fragment>
                                    <Grid.Column />
                                    <Grid.Column />
                                    <Grid.Column />
                                    <Grid.Column textAlign="right" verticalAlign="middle">
                                        <Button
                                            icon="asterisk"
                                            basic
                                            color="black"
                                            content={localization.radniNalozi.noviRadniNalog}
                                            onClick={this.onNoviRadniNalog}
                                        />
                                    </Grid.Column>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <Grid.Column />
                                    <Grid.Column />
                                    <Grid.Column />
                                    <Grid.Column textAlign="right" verticalAlign="middle">
                                        <FilterSortButton
                                            buttonName={localization.common.filtriranje}
                                            filteringOptions={this.FILTER_ITEMS}
                                            activeFilter={this.state.filter}
                                            callback={this.applyFilterAndSort}
                                        />
                                    </Grid.Column>
                                </React.Fragment>
                            )}
                        </Grid.Row>
                        {res.length ? (
                            <React.Fragment>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Message style={styles.headerTable}>
                                            <Table structured stackable>
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell style={styles.iconCell} />
                                                        <Table.HeaderCell style={styles.brojRadnogNalogaCell}>
                                                            {localization.radniNalozi.brojRadnogNaloga}
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell style={styles.klijentCell}>{localization.radniNalozi.klijent}</Table.HeaderCell>
                                                        <Table.HeaderCell style={styles.ukupnaCijenaCell}>{localization.common.ukupnaCijena}</Table.HeaderCell>
                                                        <Table.HeaderCell>{localization.radniNalozi.datumIVrijemeRadnogNaloga}</Table.HeaderCell>
                                                        <Table.HeaderCell>{localization.radniNalozi.ocekivaniDatumZavrsetka}</Table.HeaderCell>
                                                    </Table.Row>
                                                </Table.Header>
                                            </Table>
                                        </Message>
                                    </Grid.Column>
                                </Grid.Row>
                                {res.map(radniNalog => {
                                    return (
                                        <Grid.Row key={radniNalog.id}>
                                            <Grid.Column verticalAlign="middle">
                                                <Accordion fluid styled>
                                                    <Accordion.Title
                                                        active={this.state.activeWorkTasks.includes(radniNalog.id)}
                                                        index={radniNalog.id}
                                                        onClick={this.expandRadniNalog}
                                                    >
                                                        <Table structured stackable>
                                                            <Table.Header>
                                                                <Table.Row>
                                                                    <Table.Cell style={styles.iconCell}>
                                                                        <Icon name="dropdown" />
                                                                    </Table.Cell>
                                                                    <Table.Cell style={styles.brojRadnogNalogaCell}>
                                                                        {radniNalog.rbrBlokRadnihNaloga}
                                                                    </Table.Cell>
                                                                    <Table.Cell style={styles.klijentCell}>
                                                                        {radniNalog.klijent.tipKlijenta === enums.tipKlijenta.FIZICKA_OSOBA
                                                                            ? radniNalog.klijent.ime + ' ' + radniNalog.klijent.prezime
                                                                            : radniNalog.klijent.naziv}
                                                                    </Table.Cell>
                                                                    <Table.Cell style={styles.ukupnaCijenaCell}>
                                                                        <FormatIznosa value={this.calculateUkupno(radniNalog.radniNalozi)} />
                                                                    </Table.Cell>
                                                                    <Table.Cell>{moment(radniNalog.datumIVrijeme).format('DD.MM.YYYY. HH:mm')}</Table.Cell>
                                                                    <Table.Cell>{moment(radniNalog.ocekivaniDatumZavrsetka).format('DD.MM.YYYY.')}</Table.Cell>
                                                                </Table.Row>
                                                            </Table.Header>
                                                        </Table>
                                                    </Accordion.Title>
                                                    <Accordion.Content active={this.state.activeWorkTasks.includes(radniNalog.id)}>
                                                        <RadniNalogContent
                                                            radniNalog={radniNalog}
                                                            imageUploadModal={
                                                                <div style={styles.uslugeContainer}>
                                                                    <Header as="h4" style={styles.uslugeHeader}>
                                                                        {localization.radniNalozi.usluge}
                                                                    </Header>
                                                                    <Modal
                                                                        open={
                                                                            this.state.activeModal &&
                                                                            this.state.activeModal.type === modaliEnums.galerijaSlika &&
                                                                            this.state.activeModal.id === radniNalog.id
                                                                        }
                                                                        trigger={
                                                                            <Button
                                                                                icon="image"
                                                                                content={localization.common.galerijaSlika}
                                                                                onClick={this.showHideModal.bind(
                                                                                    this,
                                                                                    true,
                                                                                    modaliEnums.galerijaSlika,
                                                                                    radniNalog.id
                                                                                )}
                                                                            />
                                                                        }
                                                                    >
                                                                        <Header icon="image" content={localization.common.galerijaSlika} />
                                                                        <Modal.Content>
                                                                            <ImageUploadModal
                                                                                imageIds={radniNalog.klijentoviPredmetiImageIds}
                                                                                saveImages={this.props.radniNalogDataAccess.dodajSlikeNaRadniNalog.bind(
                                                                                    this,
                                                                                    radniNalog.id
                                                                                )}
                                                                                deleteImages={this.props.radniNalogDataAccess.izbrisiSlikeSaRadnogNaloga.bind(
                                                                                    this,
                                                                                    radniNalog.id
                                                                                )}
                                                                                closeModal={this.showHideModal.bind(this, false)}
                                                                            />
                                                                        </Modal.Content>
                                                                    </Modal>
                                                                </div>
                                                            }
                                                            calculateFunction={this.calculateUkupno}
                                                        />
                                                        <div style={styles.actionButtons}>
                                                            <Button
                                                                icon="print"
                                                                color="green"
                                                                content={localization.radniNalozi.ispisRadnogNaloga}
                                                                onClick={this.ispisRadnogNaloga.bind(this, radniNalog)}
                                                            />
                                                            {!radniNalog.isArhiviran ? (
                                                                <Modal
                                                                    open={
                                                                        this.state.activeModal &&
                                                                        this.state.activeModal.type === modaliEnums.arhiviranjeRadnogNaloga &&
                                                                        this.state.activeModal.id === radniNalog.id
                                                                    }
                                                                    closeOnDimmerClick={false}
                                                                    trigger={
                                                                        <Button
                                                                            icon="archive"
                                                                            color="red"
                                                                            content={localization.radniNalozi.arhiviranjeRadnogNaloga}
                                                                            onClick={this.showHideModal.bind(
                                                                                this,
                                                                                true,
                                                                                modaliEnums.arhiviranjeRadnogNaloga,
                                                                                radniNalog.id
                                                                            )}
                                                                        />
                                                                    }
                                                                >
                                                                    <Header icon="archive" content={localization.common.potvrda} />
                                                                    <Modal.Content>
                                                                        <p>{localization.radniNalozi.arhiviranjePitanjePotvrde}</p>
                                                                    </Modal.Content>
                                                                    <Modal.Actions>
                                                                        <Button
                                                                            icon="remove"
                                                                            content={localization.common.odustani}
                                                                            onClick={this.showHideModal.bind(this, false)}
                                                                        />
                                                                        <Button
                                                                            icon="copy"
                                                                            color="red"
                                                                            content={localization.radniNalozi.arhiviraj}
                                                                            onClick={this.arhiviranjeRadnogNaloga.bind(this, radniNalog.id)}
                                                                        />
                                                                    </Modal.Actions>
                                                                </Modal>
                                                            ) : null}
                                                        </div>
                                                        {radniNalog.sluzbenaNapomena ? (
                                                            <Message>
                                                                <Message.Header>{localization.common.sluzbenaNapomena}</Message.Header>
                                                                <p>{radniNalog.sluzbenaNapomena}</p>
                                                            </Message>
                                                        ) : null}
                                                        <Accordion fluid styled>
                                                            <Accordion.Title
                                                                active={this.state.activeComments.includes(radniNalog.id)}
                                                                index={radniNalog.id}
                                                                onClick={this.expandComments}
                                                            >
                                                                <Header as="h5" dividing style={styles.accordionHeaderContainer}>
                                                                    <Icon name="dropdown" />
                                                                    {localization.common.napomene}
                                                                    {radniNalog.nesluzbeneNapomene.length ? (
                                                                        <Icon name="exclamation circle" color="red" style={styles.semaforStyle} />
                                                                    ) : null}
                                                                </Header>
                                                            </Accordion.Title>
                                                            <Accordion.Content active={this.state.activeComments.includes(radniNalog.id)}>
                                                                <Diskusija
                                                                    napomene={radniNalog.nesluzbeneNapomene}
                                                                    textAreaValue={
                                                                        this.state.noveNapomene.find(el => el.id === radniNalog.id)
                                                                            ? this.state.noveNapomene.find(el => el.id === radniNalog.id).napomena
                                                                            : ''
                                                                    }
                                                                    onChangeNovaNapomena={this.onChangeNovaNapomena.bind(this, radniNalog.id)}
                                                                    onDodajNapomenu={this.showLoader.bind(this, this.dodajNapomenu.bind(this, radniNalog.id))}
                                                                />
                                                            </Accordion.Content>
                                                        </Accordion>
                                                    </Accordion.Content>
                                                </Accordion>
                                            </Grid.Column>
                                        </Grid.Row>
                                    );
                                })}
                                <Grid.Row centered>
                                    <Pagination
                                        activePage={this.state.pageActive}
                                        totalPages={totalPages}
                                        onPageChange={this.onChangePage}
                                        ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
                                        firstItem={{ content: <Icon name="angle double left" />, icon: true }}
                                        lastItem={{ content: <Icon name="angle double right" />, icon: true }}
                                        prevItem={{ content: <Icon name="angle left" />, icon: true }}
                                        nextItem={{ content: <Icon name="angle right" />, icon: true }}
                                    />
                                </Grid.Row>
                            </React.Fragment>
                        ) : (
                            <SearchResultsMessage
                                icon={icons.radniNalog}
                                header={this.state.value !== '' ? localization.common.unesenaPretragaNemaRezultata : localization.radniNalozi.nemaRadnihNaloga}
                                content={this.state.value !== '' ? localization.common.pokusajDrugacijuPretragu : undefined}
                            />
                        )}
                    </Grid>
                </Segment>
                <Dimmer active={this.state.loader} page>
                    <Loader />
                </Dimmer>
            </div>
        );
    }
}

RadniNalozi.propTypes = {
    history: PropTypes.object.isRequired,
    radniNalozi: PropTypes.array.isRequired,
    radniNaloziCache: PropTypes.bool.isRequired,
    poslovnice: PropTypes.array.isRequired,
    radniNalogDataAccess: PropTypes.object.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {
        radniNalozi: state.radniNaloziReducer.radniNalozi || [],
        radniNaloziCache: state.radniNaloziReducer.cached,
        poslovnice: state.mjestaReducer.poslovnice || []
    };
};

let mapDispatchToProps = function(dispatch) {
    return {
        radniNalogDataAccess: bindActionCreators(RadniNalogDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RadniNalozi);
