import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import localization from '../../locales/localization';
import { Button, Checkbox, Grid, Segment, Select } from 'semantic-ui-react';
import * as BlagajneDataAccess from '../../data_access/BlagajneDataAccess';
import { bindActionCreators } from 'redux';
import enums from '../../utils/Enums';
import InputField from './InputField';
import RequiredText from './RequiredText';
import NesluzbenaNapomena from './NesluzbenaNapomena';

const styles = {
    container: {
        textAlign: 'center'
    },
    actionButtons: {
        marginTop: 16,
        display: 'flex',
        justifyContent: 'flex-end'
    }
};

class UplatnicaIsplatnica extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tip: enums.tipUplatnicaIsplatnica.UPLATNICA,
            blagajna: enums.tipBlagajne.BLAGAJNA_OTKUPA,
            iznos: '',
            poslovnicaId: sessionStorage.userRole !== enums.tipKorisnika.DJELATNIK ? this.props.poslovnice[0].id : JSON.parse(sessionStorage.poslovnicaId),
            nesluzbenaNapomena: '',
            nedovoljnoBlagajnaOtkupa: false,
            nedovoljnoFiskalnaBlagajna: false
        };
        this.onChange = this.onChange.bind(this);
        this.provjeriBlagajne = this.provjeriBlagajne.bind(this);
        this.enableUplatiIsplatiButton = this.enableUplatiIsplatiButton.bind(this);
        this.checkKomponenteUplatniceIsplatnice = this.checkKomponenteUplatniceIsplatnice.bind(this);
        this.onNapraviUplatnicuIsplatnicu = this.onNapraviUplatnicuIsplatnicu.bind(this);
    }

    onChange(e, { name, value }) {
        this.setState({ [name]: value }, this.provjeriBlagajne);
    }

    provjeriBlagajne() {
        let nedovoljnoBlagajnaOtkupa = false,
            nedovoljnoFiskalnaBlagajna = false;
        if (this.state.tip === enums.tipUplatnicaIsplatnica.ISPLATNICA) {
            if (this.state.blagajna === enums.tipBlagajne.FISKALNA_BLAGAJNA) {
                if (this.state.iznos > this.props.stanjeBlagajni.stanjeFiskBlag) {
                    nedovoljnoFiskalnaBlagajna = true;
                }
            } else {
                if (this.state.iznos > this.props.stanjeBlagajni.stanjeBlagOtkupa) {
                    nedovoljnoBlagajnaOtkupa = true;
                }
            }
        }
        this.setState(
            {
                nedovoljnoFiskalnaBlagajna: nedovoljnoFiskalnaBlagajna,
                nedovoljnoBlagajnaOtkupa: nedovoljnoBlagajnaOtkupa
            },
            this.enableUplatiIsplatiButton
        );
    }

    enableUplatiIsplatiButton() {
        this.setState({ enableUplatiIsplatiButton: this.checkKomponenteUplatniceIsplatnice() });
    }

    checkKomponenteUplatniceIsplatnice() {
        if (
            !this.state.tip ||
            !this.state.blagajna ||
            this.state.poslovnicaId === undefined ||
            !this.state.iznos ||
            !this.state.nesluzbenaNapomena ||
            this.state.nedovoljnoFiskalnaBlagajna ||
            this.state.nedovoljnoBlagajnaOtkupa
        ) {
            return false;
        }
        return true;
    }

    onNapraviUplatnicuIsplatnicu() {
        let iznos = parseFloat(this.state.iznos);
        let uplatnicaIsplatnica = {
            iznos: this.state.tip === enums.tipUplatnicaIsplatnica.UPLATNICA ? iznos : -iznos,
            poslovnicaId: this.state.poslovnicaId,
            napomena: this.state.nesluzbenaNapomena
        };
        if (this.state.blagajna === enums.tipBlagajne.FISKALNA_BLAGAJNA) {
            this.props.blagajneDataAccess.uplataFiskalnaBlagajna(uplatnicaIsplatnica, this.props.closeModal);
        } else {
            this.props.blagajneDataAccess.uplataBlagajnaOtkupa(uplatnicaIsplatnica, this.props.closeModal);
        }
    }

    render() {
        let ob = localization.blagajne.uBlagajnuOtkupa,
            fb = localization.blagajne.uFiskalnuBlagajnu;
        if (this.state.tip === enums.tipUplatnicaIsplatnica.ISPLATNICA) {
            ob = localization.blagajne.izBlagajneOtkupa;
            fb = localization.blagajne.izFiskalneBlagajne;
        }
        return (
            <div style={styles.container}>
                <Segment>
                    <Grid stackable>
                        {sessionStorage.userRole !== enums.tipKorisnika.DJELATNIK ? (
                            <Grid.Row>
                                <Grid.Column verticalAlign="middle">
                                    <Select
                                        label={localization.blagajne.poslovnica}
                                        options={this.props.poslovnice.map(p => {
                                            return { key: p.id, text: p.naziv, value: p.id };
                                        })}
                                        name="poslovnicaId"
                                        onChange={this.onChange}
                                        value={this.state.poslovnicaId}
                                        compact
                                        required
                                        search
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        ) : null}
                        <Grid.Row columns={3}>
                            <Grid.Column verticalAlign="middle">
                                <Checkbox
                                    label={localization.blagajne.uplatnica}
                                    style={styles.checkbox}
                                    radio
                                    name="tip"
                                    value={enums.tipUplatnicaIsplatnica.UPLATNICA}
                                    onChange={this.onChange}
                                    checked={this.state.tip === enums.tipUplatnicaIsplatnica.UPLATNICA}
                                />
                                <br />
                                <Checkbox
                                    label={localization.blagajne.isplatnica}
                                    style={styles.checkbox}
                                    radio
                                    name="tip"
                                    value={enums.tipUplatnicaIsplatnica.ISPLATNICA}
                                    onChange={this.onChange}
                                    checked={this.state.tip === enums.tipUplatnicaIsplatnica.ISPLATNICA}
                                />
                            </Grid.Column>
                            <Grid.Column textAlign="left" verticalAlign="middle">
                                <Checkbox
                                    label={ob}
                                    style={styles.checkbox}
                                    radio
                                    name="blagajna"
                                    value={enums.tipBlagajne.BLAGAJNA_OTKUPA}
                                    onChange={this.onChange}
                                    checked={this.state.blagajna === enums.tipBlagajne.BLAGAJNA_OTKUPA}
                                />
                                <br />
                                <Checkbox
                                    label={fb}
                                    style={styles.checkbox}
                                    radio
                                    name="blagajna"
                                    value={enums.tipBlagajne.FISKALNA_BLAGAJNA}
                                    onChange={this.onChange}
                                    checked={this.state.blagajna === enums.tipBlagajne.FISKALNA_BLAGAJNA}
                                />
                            </Grid.Column>
                            <Grid.Column verticalAlign="middle">
                                <InputField
                                    label={localization.common.eur}
                                    placeholder={localization.blagajne.iznos}
                                    name="iznos"
                                    value={this.state.iznos}
                                    onChange={this.onChange}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <NesluzbenaNapomena
                    onChangeNapomena={this.onChange}
                    placeholder={localization.common.nesluzbenaNapomena}
                    required={!this.state.nesluzbenaNapomena}
                    requiredText={localization.blagajne.uplatnicaObveznaNesluzbenaNapomena}
                />
                <div style={styles.actionButtons}>
                    <Button icon="close" content={localization.common.odustani} onClick={this.props.closeModal} />
                    <Button
                        icon="money"
                        color="green"
                        disabled={!this.state.enableUplatiIsplatiButton}
                        content={
                            this.state.tip === enums.tipUplatnicaIsplatnica.UPLATNICA
                                ? localization.blagajne.napraviUplatu
                                : localization.blagajne.napraviIsplatu
                        }
                        onClick={this.onNapraviUplatnicuIsplatnicu}
                    />
                </div>
                {this.state.nedovoljnoFiskalnaBlagajna ? (
                    <div style={styles.actionButtons}>
                        <RequiredText requiredText={localization.common.nedovoljnoNovcaFiskalnaBlagajna} />
                    </div>
                ) : this.state.nedovoljnoBlagajnaOtkupa ? (
                    <div style={styles.actionButtons}>
                        <RequiredText requiredText={localization.common.nedovoljnoNovcaBlagajnaOtkupa} />
                    </div>
                ) : null}
            </div>
        );
    }
}

UplatnicaIsplatnica.propTypes = {
    stanjeBlagajni: PropTypes.object.isRequired,
    poslovnice: PropTypes.array.isRequired,
    blagajneDataAccess: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {
        stanjeBlagajni: state.blagajneReducer.stanje,
        poslovnice: state.mjestaReducer.poslovnice || []
    };
};

let mapDispatchToProps = function(dispatch) {
    return {
        blagajneDataAccess: bindActionCreators(BlagajneDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UplatnicaIsplatnica);
