import $ from 'jquery';

import endpoint from '../environments/endpoints';
import { showAlert } from '../utils/ErrorHandler';

export function createZahtjevZaProcjenu(zahtjevZaProcjenu, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'zahtjevi-za-procjenu',
            data: JSON.stringify(zahtjevZaProcjenu)
        })
            .done(data => {
                onSuccess(data);
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function arhivirajProcjenu(zahtjevZaProcjenuId, procjeneId, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'zahtjevi-za-procjenu/' + zahtjevZaProcjenuId + '/arhiviraj-procjene',
            data: JSON.stringify(procjeneId)
        })
            .done(data => {
                onSuccess();
            })
            .fail(error => {
                showAlert(error);
                onError();
            });
    };
}

export function dodavanjeNesluzbeneNapomeneNaProcjenu(zahtjevZaProcjenuId, napomena, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'zahtjevi-za-procjenu/' + zahtjevZaProcjenuId + '/nesluzbene-napomene',
            data: napomena
        })
            .done(data => {
                onSuccess();
            })
            .fail(error => {
                showAlert(error);
                onError();
            });
    };
}

export function dodajSlikeNaProcjenu(zahtjevZaProcjenuId, slike, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'zahtjevi-za-procjenu/' + zahtjevZaProcjenuId + '/slike/b64',
            data: JSON.stringify(slike)
        })
            .done(data => {
                onSuccess();
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function izbrisiSlikeSaProcjene(zahtjevZaProcjenuId, slike, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'zahtjevi-za-procjenu/' + zahtjevZaProcjenuId + '/slike',
            data: JSON.stringify(slike)
        })
            .done(data => {
                onSuccess();
            })
            .fail(error => {
                showAlert(error);
            });
    };
}
