import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Header } from 'semantic-ui-react';

import InputField from './InputField';
import localization from '../../locales/localization';

const styles = {
    deleteButton: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    container: {
        textAlign: 'left'
    },
    fiksnaGranica: {
        margin: 12
    }
};

export default class KarticniUvjeti extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pocetnoMjeseci: this.props.karticniUvjeti.pocetnoMjeseci,
            postotak: this.props.karticniUvjeti.postotak,
            odgodaUplateDana: this.props.karticniUvjeti.odgodaUplateDana
        };
        this.onChange = this.onChange.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            pocetnoMjeseci: nextProps.karticniUvjeti.pocetnoMjeseci,
            postotak: nextProps.karticniUvjeti.postotak,
            odgodaUplateDana: nextProps.karticniUvjeti.odgodaUplateDana
        });
    }

    onChange(e, { name, value }) {
        this.setState({ [name]: value }, () => {
            this.props.onChange(this.state);
        });
    }

    render() {
        return (
            <div>
                <div style={styles.deleteButton}>
                    <Button icon="delete" basic size="small" disabled={this.state.pocetnoMjeseci === 0} onClick={this.props.onDelete} />
                </div>
                <Form style={styles.container}>
                    <Form.Field required>
                        <label>{localization.postavke.pocetnoMjeseci}</label>
                        {this.state.pocetnoMjeseci !== 0 ? (
                            <InputField
                                label={localization.postavke.mjeseciPlaceholder}
                                placeholder={localization.postavke.mjeseciPlaceholder}
                                name="pocetnoMjeseci"
                                value={this.state.pocetnoMjeseci}
                                onChange={this.onChange}
                                isCjelobrojno={true}
                            />
                        ) : (
                            <Header as="h4" style={styles.fiksnaGranica}>
                                {'0 ' + localization.postavke.mjeseciPlaceholder}
                            </Header>
                        )}
                    </Form.Field>
                    <Form.Field required>
                        <label>{localization.postavke.postotakKartice}</label>
                        <InputField
                            label={localization.common.percentageSign}
                            placeholder={localization.common.percentageSign}
                            name="postotak"
                            value={this.state.postotak}
                            onChange={this.onChange}
                        />
                    </Form.Field>
                    <Form.Field required>
                        <label>{localization.postavke.odgodaUplate}</label>
                        <InputField
                            label={localization.postavke.danaPlaceholder}
                            placeholder={localization.postavke.danaPlaceholder}
                            name="odgodaUplateDana"
                            value={this.state.odgodaUplateDana}
                            onChange={this.onChange}
                            isCjelobrojno={true}
                        />
                    </Form.Field>
                </Form>
            </div>
        );
    }
}

KarticniUvjeti.propTypes = {
    karticniUvjeti: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired
};
