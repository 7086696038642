import React from 'react';
import PropTypes from 'prop-types';
import PrintProvider, { Print, NoPrint } from 'react-easy-print';
import ZaglavljeTvrtke from './common_components/ZaglavljeTvrtke';
import ZaglavljeKlijenta from './common_components/ZaglavljeKlijenta';
import { Button, Header, Segment, Table } from 'semantic-ui-react';
import localization from '../../locales/localization';
import DatumIMjestoObicno from './common_components/DatumIMjestoObicno';
import moment from 'moment';
import FormatIznosa from '../common_components/FormatIznosa';

const styles = {
    container: {
        padding: 24
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    zaglavlja: {
        width: '50%'
    },
    zaglavljeTvrtke: {
        paddingBottom: 24
    },
    poslovnaJedinica: {
        fontSize: 14
    },
    datumImjesto: {
        paddingBottom: 8
    },
    p: {
        fontSize: 10
    },
    title: {
        padding: 12,
        textAlign: 'center'
    },
    table: {
        fontSize: 'xx-small'
    },
    tableCell: {
        paddingTop: 'unset',
        paddingBottom: 'unset'
    },
    tableCellKol: {
        paddingTop: 'unset',
        paddingBottom: 'unset',
        fontFamily: 'monospace'
    },
    klijentovPredmetContainer: {
        display: 'flex',
        marginBottom: 14
    },
    artikliZaNaplatuContainer: {
        display: 'flex'
    },
    leftContainer: {
        width: 100,
        display: 'flex',
        alignItems: 'center'
    },
    rightContainer: {
        width: 'calc(100% - 100px)'
    },
    innerHeaderText: {
        fontSize: 10,
        textAlign: 'center'
    },
    footerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: 16,
        paddingBottom: 16
    },
    ukupnoContainer: {
        textAlign: 'right',
        paddingTop: 2,
        paddingBottom: 2
    },
    kolicinaIcijena: {
        fontFamily: 'monospace'
    },
    printButton: {
        textAlign: 'center',
        paddingBottom: 8
    }
};

export default class RadniNalog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            document: JSON.parse(sessionStorage.getItem(this.props.match.params.id))
        };
        this.printDocument = this.printDocument.bind(this);
        this.calculateUkupno = this.calculateUkupno.bind(this);
    }

    printDocument() {
        window.print();
    }

    calculateUkupno() {
        let ukupno = 0;
        this.state.document.radniNalozi.forEach(usluga => {
            ukupno += usluga.kolicinaUsluge * usluga.ocekivanaJedCijenaUsluge;
            if (usluga.stavkeRadnogNaloga.length) {
                usluga.stavkeRadnogNaloga.forEach(srn => {
                    ukupno += srn.kolicina * srn.jedCijena;
                });
            }
        });
        return ukupno;
    }

    render() {
        let ukupno = this.calculateUkupno();
        return (
            <PrintProvider>
                <Print>
                    <div style={styles.container}>
                        <div style={styles.headerContainer}>
                            <div style={styles.zaglavlja}>
                                <div style={styles.zaglavljeTvrtke}>
                                    <ZaglavljeTvrtke firma={this.state.document.firma} small={true} />
                                </div>
                                <p style={styles.poslovnaJedinica}>
                                    {localization.printingComponents.common.poslovnaJedinica + ': '}
                                    <b>{this.state.document.poslovnica.naziv}</b>
                                </p>
                                <div style={styles.datumImjesto}>
                                    <DatumIMjestoObicno
                                        datum={moment(this.state.document.datumIVrijeme).format('DD.MM.YYYY.')}
                                        mjesto={this.state.document.poslovnica.naselje}
                                    />
                                </div>
                                <p style={styles.p}>
                                    {localization.printingComponents.common.skladiste + ': '}
                                    <b>{this.state.document.poslovnica.naziv}</b>
                                </p>
                            </div>
                            <div style={styles.zaglavlja}>
                                <ZaglavljeKlijenta klijent={this.state.document.klijent} />
                            </div>
                        </div>
                        <div style={styles.title}>
                            <h3>
                                {localization.printingComponents.radniNalog.naslov + ' '}
                                <h5>{localization.printingComponents.common.br + ' ' + this.state.document.rbrBlokRadnihNaloga}</h5>
                            </h3>
                        </div>
                        {this.state.document.radniNalozi.map((rn, index) => {
                            return (
                                <Segment key={index}>
                                    <Table style={styles.table}>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell style={styles.tableCell}>{localization.usluga.sifra}</Table.HeaderCell>
                                                <Table.HeaderCell style={styles.tableCell}>{localization.usluga.naziv}</Table.HeaderCell>
                                                <Table.HeaderCell style={styles.tableCell} textAlign="center" collapsing>
                                                    {localization.common.kolicina}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell style={styles.tableCell} textAlign="center" collapsing>
                                                    {localization.usluga.jedinicnaCijena}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell style={styles.tableCell} textAlign="center" collapsing>
                                                    {localization.radniNalog.cijenaUsluge}
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            <Table.Row key={rn.uslugaInfo.sifra}>
                                                <Table.Cell style={styles.tableCell}>{rn.uslugaInfo.sifra}</Table.Cell>
                                                <Table.Cell style={styles.tableCell}>{rn.uslugaInfo.naziv}</Table.Cell>
                                                <Table.Cell style={styles.tableCellKol} textAlign="right" collapsing>
                                                    {rn.kolicinaUsluge}
                                                </Table.Cell>
                                                <Table.Cell style={styles.tableCellKol} textAlign="right" collapsing>
                                                    <FormatIznosa value={rn.ocekivanaJedCijenaUsluge} suffix={null} />
                                                </Table.Cell>
                                                <Table.Cell style={styles.tableCellKol} textAlign="right" collapsing>
                                                    <FormatIznosa value={rn.kolicinaUsluge * rn.ocekivanaJedCijenaUsluge} />
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                    {rn.klijentovPredmetInfo ? (
                                        <div style={styles.klijentovPredmetContainer}>
                                            <div style={styles.leftContainer}>
                                                <p style={styles.innerHeaderText}>
                                                    <b>{localization.radniNalozi.klijentovPredmet}</b>
                                                </p>
                                            </div>
                                            <div style={styles.rightContainer}>
                                                <Table style={styles.table}>
                                                    <Table.Header>
                                                        <Table.Row>
                                                            <Table.HeaderCell style={styles.tableCell}>{localization.artikl.sifra}</Table.HeaderCell>
                                                            <Table.HeaderCell style={styles.tableCell}>{localization.artikl.naziv}</Table.HeaderCell>
                                                            <Table.HeaderCell style={styles.tableCell} textAlign="center" collapsing>
                                                                {localization.common.kolicina}
                                                            </Table.HeaderCell>
                                                            <Table.HeaderCell style={styles.tableCell} textAlign="center" collapsing>
                                                                {localization.radniNalog.jedinicnaVrijednost}
                                                            </Table.HeaderCell>
                                                            <Table.HeaderCell style={styles.tableCell} textAlign="center" collapsing>
                                                                {localization.radniNalog.vrijednost}
                                                            </Table.HeaderCell>
                                                        </Table.Row>
                                                    </Table.Header>
                                                    <Table.Body>
                                                        <Table.Row key={rn.klijentovPredmetInfo.sifra}>
                                                            <Table.Cell style={styles.tableCell}>{rn.klijentovPredmetInfo.sifra}</Table.Cell>
                                                            <Table.Cell style={styles.tableCell}>{rn.klijentovPredmetInfo.naziv}</Table.Cell>
                                                            <Table.Cell style={styles.tableCellKol} textAlign="right" collapsing>
                                                                {rn.kolicinaKlijentovogPredmeta}
                                                            </Table.Cell>
                                                            <Table.Cell style={styles.tableCellKol} textAlign="right" collapsing>
                                                                <FormatIznosa value={rn.jedVrijednostKlijentovogPredmeta} suffix={null} />
                                                            </Table.Cell>
                                                            <Table.Cell style={styles.tableCellKol} textAlign="right" collapsing>
                                                                <FormatIznosa value={rn.kolicinaKlijentovogPredmeta * rn.jedVrijednostKlijentovogPredmeta} />
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    </Table.Body>
                                                </Table>
                                            </div>
                                        </div>
                                    ) : null}
                                    {rn.stavkeRadnogNaloga.length ? (
                                        <div style={styles.artikliZaNaplatuContainer}>
                                            <div style={styles.leftContainer}>
                                                <p style={styles.innerHeaderText}>
                                                    <b>{localization.radniNalozi.artikliZaNaplatu}</b>
                                                </p>
                                            </div>
                                            <div style={styles.rightContainer}>
                                                <Table style={styles.table}>
                                                    <Table.Header>
                                                        <Table.Row>
                                                            <Table.HeaderCell style={styles.tableCell}>{localization.artikl.sifra}</Table.HeaderCell>
                                                            <Table.HeaderCell style={styles.tableCell}>{localization.artikl.naziv}</Table.HeaderCell>
                                                            <Table.HeaderCell style={styles.tableCell} textAlign="center" collapsing>
                                                                {localization.common.kolicina}
                                                            </Table.HeaderCell>
                                                            <Table.HeaderCell style={styles.tableCell} textAlign="center" collapsing>
                                                                {localization.common.jedinicnaCijena}
                                                            </Table.HeaderCell>
                                                            <Table.HeaderCell style={styles.tableCell} textAlign="center" collapsing>
                                                                {localization.common.prodajnaCijena}
                                                            </Table.HeaderCell>
                                                        </Table.Row>
                                                    </Table.Header>
                                                    <Table.Body>
                                                        {rn.stavkeRadnogNaloga.map(item => {
                                                            return (
                                                                <Table.Row key={item.sifraPredmeta}>
                                                                    <Table.Cell style={styles.tableCell}>{item.sifraPredmeta}</Table.Cell>
                                                                    <Table.Cell style={styles.tableCell}>{item.nazivPredmeta}</Table.Cell>
                                                                    <Table.Cell style={styles.tableCellKol} textAlign="right" collapsing>
                                                                        {item.kolicina}
                                                                    </Table.Cell>
                                                                    <Table.Cell style={styles.tableCellKol} textAlign="right" collapsing>
                                                                        <FormatIznosa value={item.jedCijena} suffix={null} />
                                                                    </Table.Cell>
                                                                    <Table.Cell style={styles.tableCellKol} textAlign="right" collapsing>
                                                                        <FormatIznosa value={item.kolicina * item.jedCijena} />
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                            );
                                                        })}
                                                    </Table.Body>
                                                </Table>
                                            </div>
                                        </div>
                                    ) : null}
                                </Segment>
                            );
                        })}
                        <Segment clearing style={styles.ukupnoContainer}>
                            <p style={styles.p}>
                                <b>
                                    {localization.common.ukupno + ':   '}
                                    <span style={styles.kolicinaIcijena}>
                                        <FormatIznosa value={ukupno} /><br />
                                    </span>
                                </b>
                            </p>
                        </Segment>
                        {this.state.document.sluzbenaNapomena ? (
                            <React.Fragment>
                                <br />
                                <div>
                                    <p style={styles.p}>
                                        <b>{localization.printingComponents.common.sluzbenaNapomena + ':'}</b>
                                    </p>
                                    <p style={styles.p}>{this.state.document.sluzbenaNapomena}</p>
                                </div>
                            </React.Fragment>
                        ) : null}
                        <br />
                        <p style={styles.p}>{localization.printingComponents.common.dokumentObradenElektronickimPutem}</p>
                        <br />
                        <div style={styles.footerContainer}>
                            <p style={styles.p}>{localization.printingComponents.radniNalog.radniNalogIzvrsio + ':'}</p>
                            <p style={styles.p}>{localization.printingComponents.radniNalog.podnositeljZahtjeva + ':'}</p>
                        </div>
                    </div>
                </Print>
                <NoPrint>
                    <div style={styles.printButton}>
                        <Button color="green" icon="print" size="massive" content={localization.common.ispis} onClick={this.printDocument} />
                    </div>
                </NoPrint>
            </PrintProvider>
        );
    }
}

RadniNalog.propTypes = {
    match: PropTypes.object.isRequired
};
