import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, Header, Segment } from 'semantic-ui-react';

import PorezniZakon from '../common_components/PorezniZakon';
import localization from '../../locales/localization';

const styles = {};

class PorezniZakoni extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            porezniZakoni: this.props.porezniZakoni
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            porezniZakoni: nextProps.porezniZakoni
        });
    }

    render() {
        return (
            <Segment tertiary>
                <Header as="h2">{localization.postavke.porezniZakoni}</Header>
                <Card.Group itemsPerRow={2} stackable>
                    {this.state.porezniZakoni.map((pz, index) => {
                        return (
                            <Card key={index}>
                                <Card.Content textAlign="center">
                                    <PorezniZakon porezniZakon={pz} />
                                </Card.Content>
                            </Card>
                        );
                    })}
                </Card.Group>
            </Segment>
        );
    }
}

PorezniZakoni.propTypes = {
    history: PropTypes.object.isRequired,
    porezniZakoni: PropTypes.array.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {
        porezniZakoni: state.porezniZakoniReducer.porezniZakoni || []
    };
};

let mapDispatchToProps = function(dispatch) {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PorezniZakoni);
