import React from 'react';
import PropTypes from 'prop-types';
import PrintProvider, { Print, NoPrint } from 'react-easy-print';
import { Button } from 'semantic-ui-react';
import ZaglavljeTvrtke from './common_components/ZaglavljeTvrtke';
import localization from '../../locales/localization';
import DatumIMjestoObicno from './common_components/DatumIMjestoObicno';
import moment from 'moment';

const styles = {
    container: {
        padding: 24
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    zaglavlja: {
        width: '50%'
    },
    zaglavljeTvrtke: {
        paddingBottom: 24
    },
    datumImjesto: {
        paddingBottom: 8
    },
    p: {
        fontSize: 10
    },
    title: {
        padding: 12,
        textAlign: 'center'
    },
    footerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: 16,
        paddingBottom: 16
    },
    printButton: {
        textAlign: 'center',
        paddingBottom: 8
    }
};

export default class ZapisnikOProdajiIspodNabavneCijene extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            document: JSON.parse(sessionStorage.getItem(this.props.match.params.id))
        };
        this.printDocument = this.printDocument.bind(this);
    }

    printDocument() {
        window.print();
    }

    render() {
        return (
            <PrintProvider>
                <Print>
                    <div style={styles.container}>
                        <div style={styles.headerContainer}>
                            <div style={styles.zaglavlja}>
                                <div style={styles.zaglavljeTvrtke}>
                                    <ZaglavljeTvrtke firma={this.state.document.firma} />
                                </div>
                                <div style={styles.datumImjesto}>
                                    <DatumIMjestoObicno
                                        datum={moment(this.state.document.datumIVrijeme).format('DD.MM.YYYY.')}
                                        mjesto={this.state.document.poslovnica.naselje}
                                    />
                                </div>
                            </div>
                        </div>
                        <div style={styles.title}>
                            <h3>{localization.printingComponents.zapisnikOProdajiIspodNabavneCijene.naslov}</h3>
                        </div>
                        <br />
                        <div>
                            <p style={styles.p}>
                                {localization.printingComponents.zapisnikOProdajiIspodNabavneCijene.izjava + ' '}
                                <span>
                                    <b>{this.state.document.nazivArtikla}</b>
                                </span>
                                {' ' + localization.printingComponents.zapisnikOProdajiIspodNabavneCijene.podSifromArtikla + ': '}
                                <span>
                                    <b>{this.state.document.sifraArtikla}</b>
                                </span>
                                {' ' + localization.printingComponents.zapisnikOProdajiIspodNabavneCijene.ispodNabavneVrijednosti + '.'}
                                <br />
                                <br />
                                {localization.printingComponents.zapisnikOProdajiIspodNabavneCijene.objasnjenje}
                            </p>
                        </div>
                        <div style={styles.footerContainer}>
                            <p style={styles.p}>{localization.printingComponents.common.djelatnik + ':'}</p>
                        </div>
                    </div>
                </Print>
                <NoPrint>
                    <div style={styles.printButton}>
                        <Button color="green" icon="print" size="massive" content={localization.common.ispis} onClick={this.printDocument} />
                    </div>
                </NoPrint>
            </PrintProvider>
        );
    }
}

ZapisnikOProdajiIspodNabavneCijene.propTypes = {
    match: PropTypes.object.isRequired
};
