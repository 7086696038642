import $ from 'jquery';

import endpoint from '../environments/endpoints';

export const TIP_ARTIKLA = {
    all: 'all',
    predmet: 'predmet',
    usluga: 'usluga'
};

export const SORT = {
    naziv: 'naziv',
    sifra: 'sifra'
};

// TODO #pagination #refactor Does this have to somehow be reconciliated with redux?
//      How does redux work then the result of the API fetch does not have to be dispatched
//      throughout the whole application but only on a certain sub-page? 
export function getArtikliPaginated(q, page, pageSize, sort, tipArtikla, onSuccess, onError) {
    $.ajax({
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + sessionStorage.token
        },
        url: endpoint.apiEndpoint + 'artikli/paginated' +
            '?q=' + q +
            '&page=' + page +
            '&pageSize=' + pageSize +
            '&sort=' + sort +
            '&tipArtikla=' + tipArtikla
    })
        .done(onSuccess)
        .fail(onError);
}
