import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import localization from '../../locales/localization';
import { Button, Dimmer, Loader, Table } from 'semantic-ui-react';
import moment from 'moment';
import * as RezervacijaDataAccess from '../../data_access/RezervacijaDataAccess';
import { bindActionCreators } from 'redux';
import NesluzbenaNapomena from '../common_components/NesluzbenaNapomena';

const styles = {
    container: {
        textAlign: 'center'
    },
    quantityAndPrice: {
        width: 130
    },
    actionButtons: {
        marginTop: 16,
        display: 'flex',
        justifyContent: 'flex-end'
    }
};

class OtkazivanjeRezervacijeModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nesluzbenaNapomena: '',
            loader: false
        };
        this.onChange = this.onChange.bind(this);
        this.showLoader = this.showLoader.bind(this);
        this.hideLoader = this.hideLoader.bind(this);
        this.onOtkaziRezervacije = this.onOtkaziRezervacije.bind(this);
    }

    onChange(e, { name, value }) {
        this.setState({ [name]: value });
    }

    showLoader(callback) {
        this.setState(
            {
                loader: true
            },
            callback
        );
    }

    hideLoader(callback) {
        this.setState(
            {
                loader: false
            },
            callback
        );
    }

    onOtkaziRezervacije() {
        let rezervacijeData = {
            nesluzbenaNapomena: this.state.nesluzbenaNapomena || null,
            rezervacijeIds: this.props.zahtjevRezervacije.rezervacije.map(r => r.id)
        };
        this.props.rezervacijaDataAccess.otkaziRezervacije(
            this.props.zahtjevRezervacije.id,
            rezervacijeData,
            (rezOtkazanaResp) => this.hideLoader(() => this.props.onRezervacijaOtkazana(rezOtkazanaResp)),
            () => this.hideLoader()
        );
    }

    render() {
        return (
            <div style={styles.container}>
                <Table celled structured stackable selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>{localization.artikl.sifra}</Table.HeaderCell>
                            <Table.HeaderCell>{localization.artikl.naziv}</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">{localization.common.kolicina}</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">{localization.rezervacije.datumIsteka}</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">{localization.rezervacije.rokZaPodizanje}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.props.zahtjevRezervacije.rezervacije.map(rezervacija => {
                            return (
                                <Table.Row key={rezervacija.id}>
                                    <Table.Cell>{rezervacija.predmet.sifra}</Table.Cell>
                                    <Table.Cell>{rezervacija.predmet.naziv}</Table.Cell>
                                    <Table.Cell textAlign="center">{rezervacija.kolicina}</Table.Cell>
                                    <Table.Cell textAlign="center">{moment(rezervacija.datumIsteka).format('DD.MM.YYYY.')}</Table.Cell>
                                    <Table.Cell textAlign="center">{moment(rezervacija.rokZaPodizanje).format('DD.MM.YYYY.')}</Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
                <NesluzbenaNapomena
                    onChangeNapomena={this.onChange}
                    placeholder={localization.common.nesluzbenaNapomena}
                    required={!this.state.nesluzbenaNapomena}
                    requiredText={localization.rezervacije.otkazivanjeRezervacijeObveznaNesluzbenaNapomena}
                />
                <div style={styles.actionButtons}>
                    <Button icon="close" content={localization.common.odustani} onClick={this.props.closeModal} />
                    <Button
                        icon="calendar times"
                        color="red"
                        disabled={!this.state.nesluzbenaNapomena}
                        content={localization.rezervacije.otkaziRezervacije}
                        onClick={this.showLoader.bind(this, this.onOtkaziRezervacije)}
                    />
                </div>
                <Dimmer active={this.state.loader} page>
                    <Loader />
                </Dimmer>
            </div>
        );
    }
}

OtkazivanjeRezervacijeModal.propTypes = {
    zahtjevRezervacije: PropTypes.object.isRequired,
    rezervacijaDataAccess: PropTypes.object.isRequired,
    onRezervacijaOtkazana: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {};
};

let mapDispatchToProps = function(dispatch) {
    return {
        rezervacijaDataAccess: bindActionCreators(RezervacijaDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OtkazivanjeRezervacijeModal);
