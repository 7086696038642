import $ from 'jquery';

import * as success from '../actions/AppPreferencesActions';

import endpoint from '../environments/endpoints';
import { showAlert } from '../utils/ErrorHandler';

export function getAppPrefs(onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'app-preferences'
        })
            .done(data => {
                dispatch(success.getAppPrefs(data));
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function updateAppPrefs(prefs, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'app-preferences',
            data: JSON.stringify(prefs)
        })
            .done(data => {
                dispatch(success.getAppPrefs(data));
            })
            .fail(error => {
                showAlert(error);
            });
    };
}
