import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import localization from '../../../../locales/localization';
import FormatIznosa from '../../FormatIznosa';

const styles = {
    kolicinaIcijena: {
        fontFamily: 'monospace'
    }
};

export default class FooterCells extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Table.HeaderCell colSpan={this.props.numOfColsMerged} />
                <Table.HeaderCell textAlign="center">
                    <b>{localization.common.ukupno + ':'}</b>
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right" collapsing style={styles.kolicinaIcijena}>
                    <b>
                        <FormatIznosa value={this.props.ukupno} />
                    </b>
                </Table.HeaderCell>
            </React.Fragment>
        );
    }
}

FooterCells.propTypes = {
    ukupno: PropTypes.number.isRequired,
    numOfColsMerged: PropTypes.number.isRequired
};
