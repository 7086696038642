import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Form, Header, Segment } from 'semantic-ui-react';

import InputField from './InputField';
import localization from '../../locales/localization';
import enums from '../../utils/Enums';

const styles = {
    container: {
        textAlign: 'left'
    },
    selectField: {
        width: 'auto'
    },
    fiksnaGranica: {
        margin: 12
    }
};

const tipCijeneRezervacijeOptions = [enums.tipCijeneRezervacije.FIKSNO, enums.tipCijeneRezervacije.POSTOTAK];

export default class RezervacijskiRazred extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tip: this.props.rezervacijskiRazred.tip || enums.tipCijeneRezervacije.POSTOTAK,
            donjaGrCijeneArtikla: this.props.rezervacijskiRazred.donjaGrCijeneArtikla,
            vrijednost: this.props.rezervacijskiRazred.vrijednost
        };
        this.onChange = this.onChange.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            tip: nextProps.rezervacijskiRazred.tip || enums.tipCijeneRezervacije.POSTOTAK,
            donjaGrCijeneArtikla: nextProps.rezervacijskiRazred.donjaGrCijeneArtikla,
            vrijednost: nextProps.rezervacijskiRazred.vrijednost
        });
    }

    onChange(e, { name, value }) {
        this.setState({ [name]: value }, () => {
            this.props.onChange(this.state);
        });
    }

    render() {
        return (
            <Segment>
                <Form style={styles.container}>
                    <Form.Group widths={'equal'}>
                        <Form.Select
                            style={styles.selectField}
                            label={localization.postavke.tipCijeneRezervacije}
                            options={tipCijeneRezervacijeOptions.map((cr, index) => {
                                return { key: index, text: cr, value: cr };
                            })}
                            name="tip"
                            onChange={this.onChange}
                            value={this.state.tip}
                            required
                            search
                        />
                        <Form.Field required>
                            <label>{localization.postavke.donjaGrCijeneArtikla}</label>
                            {this.state.donjaGrCijeneArtikla !== 0 ? (
                                <InputField
                                    label={localization.common.eur}
                                    placeholder={localization.common.eur}
                                    name="donjaGrCijeneArtikla"
                                    value={this.state.donjaGrCijeneArtikla}
                                    onChange={this.onChange}
                                    isCjelobrojno={true}
                                />
                            ) : (
                                <Header as="h4" style={styles.fiksnaGranica}>
                                    {'0 ' + localization.common.eur}
                                </Header>
                            )}
                        </Form.Field>
                        <Form.Field required>
                            <label>{localization.postavke.vrijednostRezervacije}</label>
                            <InputField
                                label={this.state.tip === enums.tipCijeneRezervacije.FIKSNO ? localization.common.eur : localization.common.percentageSign}
                                placeholder={
                                    this.state.tip === enums.tipCijeneRezervacije.FIKSNO ? localization.common.eur : localization.common.percentageSign
                                }
                                name="vrijednost"
                                value={this.state.vrijednost}
                                onChange={this.onChange}
                            />
                        </Form.Field>
                        <Form.Button
                            width={1}
                            icon="delete"
                            basic
                            size="small"
                            disabled={this.state.donjaGrCijeneArtikla === 0}
                            onClick={this.props.onDelete}
                        />
                    </Form.Group>
                </Form>
            </Segment>
        );
    }
}

RezervacijskiRazred.propTypes = {
    rezervacijskiRazred: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired
};
