import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Dimmer, Form, Grid, Header, Loader, Segment, Table } from 'semantic-ui-react';

import localization from '../../locales/localization';
import enums from '../../utils/Enums';
import * as TroskoviDataAccess from '../../data_access/TroskoviDataAccess';
import moment from 'moment';
import FormatIznosa from '../common_components/FormatIznosa';

const styles = {
    dateContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    poslovnicaYearMonthPicker: {
        marginRight: 8
    },
    filterButton: {
        marginLeft: 8
    },
    kolicinaIcijena: {
        fontFamily: 'monospace'
    },
    datumIVrijemeCell: {
        width: 200
    },
    korisnikCell: {
        width: 200
    }
};

const GODINE = _.range(2019, parseInt(moment().get('year')) + 1);

class PregledTroskova extends React.Component {
    constructor(props) {
        super(props);
        let date = moment();
        this.state = {
            poslovnicaId: this.props.poslovnice[0].id,
            godina: parseInt(date.format('YYYY')),
            mjesec: parseInt(date.format('MM')),
            troskovi: [],
            loader: true
        };
        this.onTroskoviLoaded = this.onTroskoviLoaded.bind(this);
        this.onChange = this.onChange.bind(this);
        this.calculateUkupnoTroskova = this.calculateUkupnoTroskova.bind(this);
        this.onFilter = this.onFilter.bind(this);
        this.showLoader = this.showLoader.bind(this);

        this.onFilter();
    }

    onTroskoviLoaded(data) {
        this.setState({
            troskovi: data,
            loader: false
        });
    }

    onChange(e, { name, value }) {
        this.setState({ [name]: value });
    }

    calculateUkupnoTroskova() {
        let ukupno = 0;
        this.state.troskovi.forEach(t => {
            ukupno += t.iznos;
        });
        return ukupno;
    }

    resolveTrosak(tipTroska) {
        switch (tipTroska) {
            case enums.tipTroska.ONE_TIME_UPLATNICKI:
                return localization.pregledTroskova.uplatnickiJednokratniTrosak;
            case enums.tipTroska.ONE_TIME_BANKOVNI:
                return localization.pregledTroskova.bankovniJednokratniTrosak;
            case enums.tipTroska.RECURRING_PAID_UPLATNICKI:
                return localization.pregledTroskova.placenUplatnickiPonavljajuciTrosak;
            case enums.tipTroska.RECURRING_PAID_BANKOVNI:
                return localization.pregledTroskova.placenBankovniPonavljajuciTrosak;
            case enums.tipTroska.RECURRING_PENDING:
                return localization.pregledTroskova.ponavljajuciTrosakNaCekanju;
            case enums.tipTroska.RECURRING_DISMISSED:
                return localization.pregledTroskova.otkazanPonavljajuciTrosak;
        }
    }

    onFilter() {
        let date = moment(this.state.godina + '-' + this.state.mjesec + '-01');
        let startDate = date.format('YYYY-MM-DD');
        let endDateIncl = date.endOf('month').format('YYYY-MM-DD');
        this.props.troskoviDataAccess.getPaidDismissedTroskovi(startDate, endDateIncl, this.state.poslovnicaId, this.onTroskoviLoaded);
    }

    showLoader(callback) {
        this.setState(
            {
                loader: true
            },
            callback
        );
    }

    render() {
        return (
            <div>
                <Segment tertiary>
                    <Grid stackable>
                        <Grid.Row columns={2}>
                            <Grid.Column verticalAlign="middle">
                                <Header as="h2">{localization.pregledTroskova.pregledTroskova}</Header>
                            </Grid.Column>
                            <Grid.Column verticalAlign="middle">
                                <div style={styles.dateContainer}>
                                    <Form.Select
                                        label={localization.pregledTroskova.poslovnica + ': '}
                                        style={styles.poslovnicaYearMonthPicker}
                                        options={this.props.poslovnice.map(p => {
                                            return { key: p.id, text: p.naziv, value: p.id };
                                        })}
                                        name="poslovnicaId"
                                        onChange={this.onChange}
                                        value={this.state.poslovnicaId}
                                        compact
                                        required
                                        search
                                    />
                                    <Form.Select
                                        label={localization.pregledTroskova.mjesec + ': '}
                                        style={styles.poslovnicaYearMonthPicker}
                                        options={Array.from(new Array(12), (val, index) => {
                                            return { key: index, text: index + 1 + '.', value: index + 1 };
                                        })}
                                        name="mjesec"
                                        labeled
                                        onChange={this.onChange}
                                        value={this.state.mjesec}
                                        compact
                                        required
                                        search
                                    />
                                    <Form.Select
                                        label={localization.pregledTroskova.godina + ': '}
                                        style={styles.poslovnicaYearMonthPicker}
                                        options={GODINE.map((g, index) => {
                                            return { key: index, text: g + '.', value: g };
                                        })}
                                        name="godina"
                                        onChange={this.onChange}
                                        value={this.state.godina}
                                        compact
                                        required
                                        search
                                    />
                                    <Button
                                        icon="filter"
                                        basic
                                        color="black"
                                        size="small"
                                        style={styles.filterButton}
                                        content={localization.pregledTroskova.filtriraj}
                                        onClick={this.showLoader.bind(this, this.onFilter)}
                                    />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column verticalAlign="middle">
                                <Table structured stackable>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>{localization.pregledTroskova.tipTroska}</Table.HeaderCell>
                                            <Table.HeaderCell style={styles.datumIVrijemeCell}>{localization.pregledTroskova.datumIVrijeme}</Table.HeaderCell>
                                            <Table.HeaderCell>{localization.pregledTroskova.nazivTroska}</Table.HeaderCell>
                                            <Table.HeaderCell>{localization.pregledTroskova.grupaTroska}</Table.HeaderCell>
                                            <Table.HeaderCell textAlign="center">{localization.pregledTroskova.iznos}</Table.HeaderCell>
                                            <Table.HeaderCell style={styles.korisnikCell}>{localization.pregledTroskova.korisnik}</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {this.state.troskovi.map((trosak, index) => {
                                            return (
                                                <Table.Row key={index}>
                                                    <Table.Cell>{this.resolveTrosak(trosak.tip)}</Table.Cell>
                                                    <Table.Cell style={styles.datumIVrijemeCell}>
                                                        {moment(trosak.datumIVrijeme).format('DD.MM.YYYY. HH:mm')}
                                                    </Table.Cell>
                                                    <Table.Cell>{trosak.timeSpan ? trosak.ime + ': ' + trosak.timeSpan.value : trosak.ime}</Table.Cell>
                                                    <Table.Cell>{trosak.grupa ? trosak.grupa.ime : '-'}</Table.Cell>
                                                    <Table.Cell collapsing textAlign="right" style={styles.kolicinaIcijena}>
                                                        <FormatIznosa value={trosak.iznos} />
                                                    </Table.Cell>
                                                    <Table.Cell style={styles.korisnikCell}>
                                                        {trosak.user ? trosak.user.ime + ' ' + trosak.user.prezime : '-'}
                                                    </Table.Cell>
                                                </Table.Row>
                                            );
                                        })}
                                    </Table.Body>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan={3} />
                                            <Table.HeaderCell>
                                                <b>{localization.common.ukupno + ':'}</b>
                                            </Table.HeaderCell>
                                            <Table.HeaderCell collapsing textAlign="right" style={styles.kolicinaIcijena}>
                                                <FormatIznosa value={this.calculateUkupnoTroskova()} />
                                            </Table.HeaderCell>
                                            <Table.HeaderCell />
                                        </Table.Row>
                                    </Table.Header>
                                </Table>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Dimmer active={this.state.loader} page>
                    <Loader />
                </Dimmer>
            </div>
        );
    }
}

PregledTroskova.propTypes = {
    poslovnice: PropTypes.array.isRequired,
    troskoviDataAccess: PropTypes.object.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {
        poslovnice: state.mjestaReducer.poslovnice || []
    };
};

let mapDispatchToProps = function(dispatch) {
    return {
        troskoviDataAccess: bindActionCreators(TroskoviDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PregledTroskova);
