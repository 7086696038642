import React from 'react';
import PropTypes from 'prop-types';
import PrintProvider, { Print, NoPrint } from 'react-easy-print';
import ZaglavljeTvrtke from './common_components/ZaglavljeTvrtke';
import { Button, Table } from 'semantic-ui-react';
import localization from '../../locales/localization';
import DatumIMjestoObicno from './common_components/DatumIMjestoObicno';
import moment from 'moment';
import FormatIznosa from '../common_components/FormatIznosa';
import BlagajnickiDnevniciMappings from '../../utils/BlagajnickiDnevniciMappings';
import enums from '../../utils/Enums';
import { YEAR_EURO_WAS_INTRODUCED } from '../../utils/Valute';

const styles = {
    container: {
        padding: 24
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    zaglavlja: {
        width: '50%'
    },
    zaglavljeTvrtke: {
        paddingBottom: 24
    },
    datumImjesto: {
        paddingBottom: 8
    },
    p: {
        fontSize: 10
    },
    title: {
        padding: 12,
        textAlign: 'center'
    },
    table: {
        fontSize: 'xx-small'
    },
    sumTable: {
        fontSize: 'xx-small',
        width: '50%'
    },
    tableCell: {
        paddingTop: 'unset',
        paddingBottom: 'unset'
    },
    tableCellKol: {
        paddingTop: 'unset',
        paddingBottom: 'unset',
        fontFamily: 'monospace'
    },
    footerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: 64,
        paddingBottom: 16
    },
    printButton: {
        textAlign: 'center',
        paddingBottom: 8
    }
};

export default class BlagajnickiDnevnik extends React.Component {
    constructor(props) {
        super(props);
        let dnevnik = JSON.parse(sessionStorage.getItem(this.props.match.params.id))
        let isKuna = dnevnik.godina < YEAR_EURO_WAS_INTRODUCED && dnevnik.tipBlagajnickogDnevnika === 'OTKUPNA'
        this.state = {
            document: dnevnik,
            isKuna: isKuna
        };
        this.printDocument = this.printDocument.bind(this);
        this.calculateUkupno = this.calculateUkupno.bind(this);
    }

    printDocument() {
        window.print();
    }

    calculateUkupno() {
        let ukupnoUplate = 0,
            ukupnoIsplate = 0;
        this.state.document.blagajnickiDnevnik.stavke.forEach(stavkaBlagajne => {
            let mappedStavka = BlagajnickiDnevniciMappings[stavkaBlagajne.tipStavkeBlagajne](stavkaBlagajne, this.state.isKuna, this.state.document.tipBlagajnickogDnevnika);
            if (mappedStavka.iznos < 0) {
                ukupnoIsplate += Math.abs(mappedStavka.iznos);
            } else {
                ukupnoUplate += mappedStavka.iznos;
            }
        });
        return [ukupnoUplate, ukupnoIsplate];
    }

    render() {
        let [ukupnoUplate, ukupnoIsplate] = this.calculateUkupno();
        return (
            <PrintProvider>
                <Print>
                    <div style={styles.container}>
                        <div style={styles.headerContainer}>
                            <div style={styles.zaglavlja}>
                                <div style={styles.zaglavljeTvrtke}>
                                    <ZaglavljeTvrtke firma={this.state.document.blagajnickiDnevnik.firma} />
                                </div>
                            </div>
                            <div style={styles.zaglavlja}>
                                <div style={styles.datumImjesto}>
                                    <DatumIMjestoObicno
                                        datum={moment().format('DD.MM.YYYY.')}
                                        mjesto={this.state.document.poslovnica ? this.state.document.poslovnica.naselje : '-'}
                                    />
                                </div>
                                <p>
                                    {localization.printingComponents.common.skladiste + ': '}
                                    <b>
                                        {this.state.document.poslovnica
                                            ? this.state.document.poslovnica.naziv
                                            : localization.printingComponents.common.skladiste}
                                    </b>
                                </p>
                            </div>
                        </div>
                        <div style={styles.title}>
                            <h3>{this.state.document.naslov}</h3>
                        </div>
                        <Table structured stackable style={styles.table}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={styles.tableCell} />
                                    <Table.HeaderCell style={styles.tableCell}>{localization.blagajnickiDnevnici.datumIVrijeme}</Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell}>{localization.blagajnickiDnevnici.stavkaBlagajne}</Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell} textAlign="center">
                                        {localization.blagajnickiDnevnici.uplata}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell} textAlign="center">
                                        {localization.blagajnickiDnevnici.isplata}
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell style={styles.tableCell} colSpan={2} />
                                    <Table.Cell style={styles.tableCell}>
                                        <b>{localization.blagajnickiDnevnici.pocetnoStanje}</b>
                                    </Table.Cell>
                                    <Table.Cell style={styles.tableCellKol} collapsing textAlign="center" colSpan={2}>
                                        <b>
                                            <FormatIznosa value={this.state.isKuna ? this.state.document.blagajnickiDnevnik.pocetnoStanjeHrk : this.state.document.blagajnickiDnevnik.pocetnoStanje} suffix={this.state.isKuna ? localization.common.hrk : null} />
                                        </b>
                                    </Table.Cell>
                                </Table.Row>
                                {this.state.document.blagajnickiDnevnik.stavke.map((stavkaBlagajne, index) => {
                                    let mappedStavka = BlagajnickiDnevniciMappings[stavkaBlagajne.tipStavkeBlagajne](
                                        stavkaBlagajne,
                                        this.state.isKuna, 
                                        this.state.document.tipBlagajnickogDnevnika
                                    );
                                    return (
                                        <Table.Row key={index}>
                                            <Table.Cell style={styles.tableCell} collapsing>
                                                {index + 1 + '.'}
                                            </Table.Cell>
                                            <Table.Cell style={styles.tableCell}>{moment(stavkaBlagajne.datumIVrijeme).format('DD.MM.YYYY. HH:mm')}</Table.Cell>
                                            <Table.Cell style={styles.tableCell}>{mappedStavka.imeStavke}</Table.Cell>
                                            {mappedStavka.iznos > 0 ? (
                                                <React.Fragment>
                                                    <Table.Cell style={styles.tableCellKol} collapsing textAlign="right">
                                                        <FormatIznosa value={mappedStavka.iznos} suffix={this.state.isKuna ? localization.common.hrk : null} />
                                                    </Table.Cell>
                                                    <Table.Cell style={styles.tableCell} />
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <Table.Cell style={styles.tableCell} />
                                                    <Table.Cell style={styles.tableCellKol} collapsing textAlign="right">
                                                        <FormatIznosa value={Math.abs(mappedStavka.iznos)} suffix={this.state.isKuna ? localization.common.hrk : null} />
                                                    </Table.Cell>
                                                </React.Fragment>
                                            )}
                                        </Table.Row>
                                    );
                                })}
                                <Table.Row>
                                    <Table.Cell style={styles.tableCell} colSpan={2} />
                                    <Table.Cell style={styles.tableCell}>
                                        <b>{localization.blagajnickiDnevnici.zavrsnoStanje}</b>
                                    </Table.Cell>
                                    <Table.Cell style={styles.tableCellKol} collapsing textAlign="center" colSpan={2}>
                                        <b>
                                            <FormatIznosa value={this.state.isKuna ? this.state.document.blagajnickiDnevnik.zavrsnoStanjeHrk : this.state.document.blagajnickiDnevnik.zavrsnoStanje} suffix={this.state.isKuna ? localization.common.hrk : null} />
                                        </b>
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                        <br />
                        <br />
                        <Table structured stackable style={styles.sumTable}>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell style={styles.tableCell}>{localization.blagajnickiDnevnici.ukupnoUplate}</Table.Cell>
                                    <Table.Cell style={styles.tableCellKol} collapsing textAlign="right">
                                        <FormatIznosa value={ukupnoUplate} suffix={this.state.isKuna ? localization.common.hrk : null} />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell style={styles.tableCell}>{localization.blagajnickiDnevnici.ukupnoIsplate}</Table.Cell>
                                    <Table.Cell style={styles.tableCellKol} collapsing textAlign="right">
                                        <FormatIznosa value={ukupnoIsplate} suffix={this.state.isKuna ? localization.common.hrk : null} />
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </div>
                </Print>
                <NoPrint>
                    <div style={styles.printButton}>
                        <Button color="green" icon="print" size="massive" content={localization.common.ispis} onClick={this.printDocument} />
                    </div>
                </NoPrint>
            </PrintProvider>
        );
    }
}

BlagajnickiDnevnik.propTypes = {
    match: PropTypes.object.isRequired
};
