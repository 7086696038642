import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Button, Grid, Header, Message, Search, Segment } from 'semantic-ui-react';
import localization from '../../locales/localization';
import { renderUslugeResults } from '../../utils/SearchResultsRenderers';
import TablicaUsluga from './table_fragments/TablicaUsluga';
import OdabirArtikla from './OdabirArtikla';
import SearchResultsMessage from './SearchResultsMessage';
import enums from '../../utils/Enums';
import { generateKey } from '../../utils/KeyGenerator';

const styles = {
    buttons: {
        marginTop: 8,
        marginBottom: 8
    }
};

class OdabirUsluge extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isuslugeSearchLoading: false,
            ispredmetSearchLoading: false,
            isartikliSearchLoading: false,
            uslugeResults: [],
            predmetResults: [],
            artikliResults: [],
            uslugeValue: '',
            predmetValue: '',
            artikliValue: '',
            uslugeSelected: [],
            activeDodavanjePredmeta: [],
            activeDodavanjeArtikli: []
        };
        this.resetSearchComponent = this.resetSearchComponent.bind(this);
        this.handleUslugeSearchResultSelect = this.handleUslugeSearchResultSelect.bind(this);
        this.handleUslugeSearchChange = this.handleUslugeSearchChange.bind(this);
        this.enableOdabirArtikala = this.enableOdabirArtikala.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onRemoveItem = this.onRemoveItem.bind(this);
        this.handleOdabirKlijentovogPredmeta = this.handleOdabirKlijentovogPredmeta.bind(this);
        this.handleOdabirArtikli = this.handleOdabirArtikli.bind(this);
        this.runCallback = this.runCallback.bind(this);
    }

    componentWillMount() {
        this.resetSearchComponent('usluge');
        this.resetSearchComponent('predmet');
        this.resetSearchComponent('artikli');
    }

    resetSearchComponent(name) {
        this.setState({
            ['is' + name + 'SearchLoading']: false,
            [name + 'Results']: [],
            [name + 'Value']: ''
        });
    }

    handleUslugeSearchResultSelect(e, { result }) {
        this.setState(
            {
                uslugeSelected: _.concat(this.state.uslugeSelected, [Object.assign({}, result.result, { internaSifra: generateKey() })]),
                uslugeValue: ''
            },
            this.runCallback
        );
    }

    handleUslugeSearchChange(e, { value }) {
        this.setState({
            isuslugeSearchLoading: true,
            uslugeValue: value
        });

        setTimeout(() => {
            if (this.state.uslugeValue.length < 1) {
                return this.resetSearchComponent('usluge');
            }

            const re = new RegExp(_.escapeRegExp(this.state.uslugeValue), 'i');
            const isMatch = result => re.test(result.sifra) || re.test(result.naziv);

            this.setState({
                isuslugeSearchLoading: false,
                uslugeResults: _.filter(this.props.viewModel.usluge, isMatch).map(r => {
                    return { key: r.id, result: r };
                })
            });
        }, 500);
    }

    enableOdabirArtikala(sifra, e, { name }) {
        this.setState({
            [name]: _.xor(this.state[name], [sifra])
        });
    }

    onChange(sifra, e, { name, value }) {
        this.setState(
            {
                uslugeSelected: this.state.uslugeSelected.map(item => {
                    if (item.sifra === sifra) {
                        item[name] = value;
                    }
                    return Object.assign({}, item);
                })
            },
            this.runCallback
        );
    }

    onRemoveItem(item) {
        this.setState(
            {
                uslugeSelected: _.xor(this.state.uslugeSelected, [item]),
                activeDodavanjePredmeta: _.xor(this.state.activeDodavanjePredmeta, [item.internaSifra]),
                activeDodavanjeArtikli: _.xor(this.state.activeDodavanjeArtikli, [item.internaSifra])
            },
            this.runCallback
        );
    }

    handleOdabirKlijentovogPredmeta(uslugaInternaSifra, predmet) {
        this.setState(
            {
                uslugeSelected: this.state.uslugeSelected.map(usluga => {
                    if (usluga.internaSifra === uslugaInternaSifra) {
                        if (predmet.length) {
                            usluga.klijentovPredmet = predmet[0];
                        } else {
                            usluga.klijentovPredmet = undefined;
                        }
                    }
                    return usluga;
                }),
                activeDodavanjePredmeta: this.state.activeDodavanjePredmeta.filter(id => !(id === uslugaInternaSifra && !predmet.length))
            },
            this.runCallback
        );
    }

    handleOdabirArtikli(uslugaInternaSifra, stavke) {
        this.setState(
            {
                uslugeSelected: this.state.uslugeSelected.map(usluga => {
                    if (usluga.internaSifra === uslugaInternaSifra) {
                        usluga.stavkeRadnogNaloga = stavke;
                    }
                    return usluga;
                }),
                activeDodavanjeArtikli: this.state.activeDodavanjeArtikli.filter(id => !(id === uslugaInternaSifra && !stavke.length))
            },
            this.runCallback
        );
    }

    runCallback() {
        this.props.onUslugaSelected(this.state.uslugeSelected);
    }

    render() {
        return (
            <Grid stackable>
                <Grid.Row columns={6}>
                    <Grid.Column verticalAlign="middle">
                        <Header as="h3">{localization.common.usluge}</Header>
                    </Grid.Column>
                    <Grid.Column verticalAlign="middle">
                        <Search
                            input={{ fluid: true }}
                            loading={this.state.isuslugeSearchLoading}
                            onResultSelect={this.handleUslugeSearchResultSelect}
                            onSearchChange={this.handleUslugeSearchChange}
                            results={this.state.uslugeResults}
                            resultRenderer={renderUslugeResults}
                            value={this.state.uslugeValue}
                        />
                    </Grid.Column>
                </Grid.Row>
                {this.state.uslugeSelected.length ? (
                    <Grid.Row>
                        <Grid.Column verticalAlign="middle">
                            {this.state.uslugeSelected.map(usluga => {
                                return (
                                    <Segment key={usluga.internaSifra}>
                                        <TablicaUsluga
                                            type={enums.tipTabliceArtikala.RADNINALOG_EDITABLE}
                                            usluga={usluga}
                                            showActions={true}
                                            onChange={this.onChange}
                                            onRemoveItem={this.onRemoveItem}
                                        />
                                        {this.state.activeDodavanjePredmeta.includes(usluga.internaSifra) ? (
                                            <OdabirArtikla
                                                artikli={this.props.viewModel.predmeti}
                                                maxNumOfArtikli={1}
                                                showFooter={false}
                                                onItemSelected={this.handleOdabirKlijentovogPredmeta.bind(this, usluga.internaSifra)}
                                                type={enums.tipTabliceArtikala.RADNINALOG_EDITABLE}
                                                title={localization.radniNalog.klijentovPredmet + ':'}
                                            />
                                        ) : (
                                            <Button
                                                fluid
                                                name="activeDodavanjePredmeta"
                                                basic
                                                style={styles.buttons}
                                                onClick={this.enableOdabirArtikala.bind(this, usluga.internaSifra)}
                                            >
                                                {localization.radniNalog.dodajKlijentovPredmet}
                                            </Button>
                                        )}
                                        {this.state.activeDodavanjeArtikli.includes(usluga.internaSifra) ? (
                                            <OdabirArtikla
                                                artikli={this.props.viewModel.predmeti}
                                                onItemSelected={this.handleOdabirArtikli.bind(this, usluga.internaSifra)}
                                                type={enums.tipTabliceArtikala.RADNINALOG_EDITABLE}
                                                title={localization.radniNalog.artikliZaNaplatu + ':'}
                                            />
                                        ) : (
                                            <Button
                                                fluid
                                                name="activeDodavanjeArtikli"
                                                basic
                                                style={styles.buttons}
                                                onClick={this.enableOdabirArtikala.bind(this, usluga.internaSifra)}
                                            >
                                                {localization.radniNalog.dodajArtikleZaNaplatu}
                                            </Button>
                                        )}
                                    </Segment>
                                );
                            })}
                        </Grid.Column>
                    </Grid.Row>
                ) : null}
                {!this.state.uslugeSelected.length ? (
                    <SearchResultsMessage
                        icon="in cart"
                        header={localization.common.placeholderUnosUslugeTitle}
                        content={localization.common.placeholderUnosUslugeContent}
                    />
                ) : null}
            </Grid>
        );
    }
}

OdabirUsluge.propTypes = {
    viewModel: PropTypes.object.isRequired,
    onUslugaSelected: PropTypes.func.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {};
};

let mapDispatchToProps = function(dispatch) {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(OdabirUsluge);
