import $ from 'jquery';

import endpoint from '../environments/endpoints';

// TODO @Kec @Vranjes Parameter order?
export function getPredracuniPaginated(q, page, pageSize, poslovnicaId, startDateTimeIncl, endDateTimeIncl, onSuccess, onError) {
    $.ajax({
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + sessionStorage.token
        },
        url: endpoint.apiEndpoint + '/prodaja/predracuni/paginated' +
            '?q=' + q +
            '&poslovnicaId=' + (poslovnicaId ? poslovnicaId : '') +
            '&page=' + page +
            '&pageSize=' + pageSize +
            (startDateTimeIncl ? '&startDateTimeIncl=' + startDateTimeIncl : '') +
            (endDateTimeIncl ? '&endDateTimeIncl=' + endDateTimeIncl : '')
    })
        .done(onSuccess)
        .fail(onError);
}
