/**
 * Root reducer - combines all reducers in the system
 */
import { combineReducers } from 'redux';

import appPreferencesReducer from './appPreferencesReducer';
import blagajneReducer from './blagajneReducer';
import dnevnaEvidencijaReducer from './dnevnaEvidencijaReducer';
import firmaReducer from './firmaReducer';
import karticeReducer from './karticeReducer';
import kategorijeReducer from './kategorijeReducer';
import izdatniceReducer from './izdatniceReducer';
import mjerneJediniceReducer from './mjerneJediniceReducer';
import mjestaReducer from './mjestaReducer';
import porezniZakoniReducer from './porezniZakoniReducer';
import radniNaloziReducer from './radniNaloziReducer';
import skladisteReducer from './skladisteReducer';
import userReducer from './userReducer';
import korisniciReducer from './korisniciReducer';
import flagsReducer from './flagsReducer';
import troskoviReducer from './troskoviReducer';
import kontrolaReducer from './kontrolaReducer';

const appReducer = combineReducers({
    appPreferencesReducer,
    blagajneReducer,
    dnevnaEvidencijaReducer,
    firmaReducer,
    karticeReducer,
    kategorijeReducer,
    izdatniceReducer,
    mjerneJediniceReducer,
    mjestaReducer,
    porezniZakoniReducer,
    radniNaloziReducer,
    skladisteReducer,
    userReducer,
    korisniciReducer,
    flagsReducer,
    troskoviReducer,
    kontrolaReducer
});

const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;
