import $ from 'jquery';

import * as success from '../actions/IzdatniceActions';
import { resetStanjeCache, resetListaZaMaknutiSOglasavanjaCache } from '../actions/SkladisteActions';

import endpoint from '../environments/endpoints';
import { showAlert } from '../utils/ErrorHandler';

export function getIzdatniceViewModel(poslovnicaId, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'izdatnice/vm?poslovnicaId=' + poslovnicaId
        })
            .done(data => {
                onSuccess(data);
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function getIzdatnice(onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'izdatnice'
        })
            .done(data => {
                dispatch(success.getIzdatnice(data));
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function createIzdatnica(izdatnica, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'izdatnice',
            data: JSON.stringify(izdatnica)
        })
            .done(data => {
                dispatch(getIzdatnice());
                dispatch(resetStanjeCache());
                dispatch(resetListaZaMaknutiSOglasavanjaCache());
                onSuccess(data);
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function stornirajIzdatnica(izdatnicaId, napomene, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'izdatnice/' + izdatnicaId + '/storniraj',
            data: JSON.stringify(napomene)
        })
            .done(data => {
                dispatch(getIzdatnice());
                dispatch(resetStanjeCache());
                dispatch(resetListaZaMaknutiSOglasavanjaCache());
                onSuccess();
            })
            .fail(error => {
                showAlert(error);
            });
    };
}