import $ from 'jquery';

import endpoint from '../environments/endpoints';
import { showAlert } from '../utils/ErrorHandler';

// export function searchArtikli(query, onSuccess, onError) {
//     return function (dispatch) {
//         $.ajax({
//             method: 'GET',
//             headers: {
//                 'Accept': 'application/json',
//                 'Content-Type': 'application/json',
//                 'Authorization': 'Bearer ' + sessionStorage.token
//             },
//             url: endpoint.apiEndpoint + 'artikli?q=' + query
//         }).done((data) => {

//         }).fail((error) => {

//         });
//     };
// }

export function createPredmet(predmet, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'artikli',
            data: JSON.stringify(predmet)
        })
            .done(data => {
                onSuccess(data);
            })
            .fail(error => {
                showAlert(error);
                onError();
            });
    };
}

export function updatePredmet(id, predmet, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'artikli/' + id,
            data: JSON.stringify(predmet)
        })
            .done(data => {
                onSuccess(data);
            })
            .fail(error => {
                showAlert(error);
                onError();
            });
    };
}

export function createUsluga(usluga, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'artikli',
            data: JSON.stringify(usluga)
        })
            .done(data => {
                onSuccess(data);
            })
            .fail(error => {
                showAlert(error);
                onError();
            });
    };
}

export function updateUsluga(id, usluga, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'artikli/' + id,
            data: JSON.stringify(usluga)
        })
            .done(data => {
                onSuccess(data);
            })
            .fail(error => {
                showAlert(error);
                onError();
            });
    };
}
