import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router';
import { connect } from 'react-redux';

import NovaRezervacija from './rezervacija_components/NovaRezervacija';
import endpoints from '../environments/endpoints';
import RootRezervacija from './rezervacija_components/RootRezervacija';
import RezervacijeImporter from './rezervacija_components/RezervacijeImporter';

const styles = {};

class Rezervacija extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Switch>
                <Route
                    exact
                    path={endpoints.appEndpoints.rezervacije}
                    render={() => {
                        return <RootRezervacija history={this.props.history} />;
                    }}
                />
                <Route path={endpoints.appEndpoints.novaRezervacija} component={NovaRezervacija} />
                <Route path={endpoints.appEndpoints.importRezervacija} component={RezervacijeImporter} />
            </Switch>
        );
    }
}

Rezervacija.propTypes = {
    history: PropTypes.object.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {};
};

let mapDispatchToProps = function(dispatch) {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Rezervacija);
