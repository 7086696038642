import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Form, Header, Modal, Segment } from 'semantic-ui-react';

import localization from '../../locales/localization';
import enums from '../../utils/Enums';
import * as UsersDataAccess from '../../data_access/UsersDataAccess';

const styles = {
    container: {
        textAlign: 'left'
    },
    deleteContainer: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
};

const tipKorisnikaOptions = [enums.tipKorisnika.DJELATNIK, enums.tipKorisnika.VODITELJ, enums.tipKorisnika.VLASNIK, enums.tipKorisnika.KONTROLA];

const modals = {
    resetPasswordModal: 'resetPasswordModal',
    deleteKorisnikModal: 'deleteKorisnikModal'
};

class Korisnik extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            korisnik: this.props.korisnik,
            activeModal: undefined,
            enableSaveButton: false
        };
        this.onReset = this.onReset.bind(this);
        this.onChange = this.onChange.bind(this);
        this.enableSaveButton = this.enableSaveButton.bind(this);
        this.checkKorisnikRequiredFields = this.checkKorisnikRequiredFields.bind(this);
        this.showHideModal = this.showHideModal.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onResetPassword = this.onResetPassword.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            korisnik: Object.assign({}, this.state.korisnik, nextProps.korisnik),
            activeModal: undefined,
            enableSaveButton: false
        });
    }

    onReset() {
        this.setState({
            korisnik: Object.assign({}, this.state.korisnik, this.props.korisnik),
            activeModal: undefined,
            enableSaveButton: false
        });
    }

    onChange(e, { name, value }) {
        this.setState(
            {
                korisnik: Object.assign({}, this.state.korisnik, { [name]: value })
            },
            this.enableSaveButton
        );
    }

    enableSaveButton() {
        this.setState({
            enableSaveButton: this.checkKorisnikRequiredFields()
        });
    }

    checkKorisnikRequiredFields() {
        if (
            !this.state.korisnik.role ||
            !this.state.korisnik.username ||
            !this.state.korisnik.ime ||
            !this.state.korisnik.prezime ||
            !this.state.korisnik.oib ||
            !this.state.korisnik.brojDjelatnika
        ) {
            return false;
        }
        return true;
    }

    showHideModal(type, value) {
        if (value) {
            this.setState({ activeModal: type });
        } else {
            this.setState({ activeModal: undefined });
        }
    }

    onSave() {
        if (this.props.novo) {
            this.props.usersDataAccess.createUser(this.state.korisnik);
        } else {
            this.props.usersDataAccess.updateUser(this.state.korisnik);
        }
    }

    onCancel(e) {
        if (this.props.novo) {
            this.props.onCancel();
        } else {
            this.onReset();
        }
    }

    onDelete() {
        this.props.usersDataAccess.deleteUser(this.state.korisnik.id, this.showHideModal.bind(this, modals.deleteKorisnikModal, false));
    }

    onResetPassword() {
        this.props.usersDataAccess.resetPassword(this.state.korisnik.id, this.showHideModal.bind(this, modals.resetPasswordModal, false));
    }

    render() {
        return (
            <Segment>
                {!this.props.novo ? (
                    <div style={styles.deleteContainer}>
                        <Modal
                            open={this.state.activeModal === modals.deleteKorisnikModal}
                            trigger={
                                <Button
                                    icon="delete"
                                    color="red"
                                    basic
                                    size="small"
                                    onClick={this.showHideModal.bind(this, modals.deleteKorisnikModal, true)}
                                />
                            }
                        >
                            <Header icon="trash" content={localization.common.potvrdaBrisanja} />
                            <Modal.Content>
                                <p>{localization.postavke.brisanjeKorisnikaPotvrda}</p>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button
                                    icon="remove"
                                    content={localization.common.odustani}
                                    onClick={this.showHideModal.bind(this, modals.deleteKorisnikModal, false)}
                                />
                                <Button icon="trash" color="red" content={localization.common.obrisi} onClick={this.onDelete} />
                            </Modal.Actions>
                        </Modal>
                    </div>
                ) : null}
                <Form style={styles.container}>
                    <Form.Select
                        style={styles.selectField}
                        label={localization.postavke.tipKorisnika}
                        options={tipKorisnikaOptions.map((k, index) => {
                            return { key: index, text: k, value: k };
                        })}
                        name="role"
                        onChange={this.onChange}
                        value={this.state.korisnik.role}
                        required
                        search
                    />
                    <Form.Input
                        fluid
                        label={localization.postavke.brojDjelatnika}
                        name="brojDjelatnika"
                        onChange={this.onChange}
                        value={this.state.korisnik.brojDjelatnika || ''}
                        required
                    />
                    <Form.Input
                        fluid
                        label={localization.postavke.korisnickoIme}
                        name="username"
                        onChange={this.onChange}
                        value={this.state.korisnik.username || ''}
                        required
                    />
                    <Form.Input
                        fluid
                        label={localization.postavke.imeKorisnika}
                        name="ime"
                        onChange={this.onChange}
                        value={this.state.korisnik.ime || ''}
                        required
                    />
                    <Form.Input
                        fluid
                        label={localization.postavke.prezimeKorisnika}
                        name="prezime"
                        onChange={this.onChange}
                        value={this.state.korisnik.prezime || ''}
                        required
                    />
                    <Form.Input
                        fluid
                        label={localization.postavke.oibKorisnika}
                        name="oib"
                        onChange={this.onChange}
                        value={this.state.korisnik.oib || ''}
                        required
                    />
                    <Form.Input
                        fluid
                        label={localization.postavke.emailKorisnika}
                        name="email"
                        onChange={this.onChange}
                        value={this.state.korisnik.email || ''}
                    />
                    <Form.Group style={styles.buttons}>
                        <Form.Button
                            icon="cancel"
                            content={this.props.novo ? localization.common.odustani : localization.common.vratiNaSpremljeno}
                            onClick={this.onCancel}
                        />
                        <Modal
                            open={this.state.activeModal === modals.resetPasswordModal}
                            trigger={
                                <Form.Button
                                    icon="undo"
                                    color="red"
                                    content={localization.postavke.resetLozinke}
                                    onClick={this.showHideModal.bind(this, modals.resetPasswordModal, true)}
                                />
                            }
                        >
                            <Header icon="trash" content={localization.common.potvrda} />
                            <Modal.Content>
                                <p>{localization.postavke.resetLozinkePotvrda}</p>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button
                                    icon="remove"
                                    content={localization.common.odustani}
                                    onClick={this.showHideModal.bind(this, modals.resetPasswordModal, false)}
                                />
                                <Button icon="trash" color="red" content={localization.postavke.resetLozinke} onClick={this.onResetPassword} />
                            </Modal.Actions>
                        </Modal>
                        <Form.Button
                            icon="save"
                            color="green"
                            disabled={!this.state.enableSaveButton}
                            content={localization.common.spremi}
                            onClick={this.onSave}
                        />
                    </Form.Group>
                </Form>
            </Segment>
        );
    }
}

Korisnik.propTypes = {
    novo: PropTypes.bool.isRequired,
    korisnik: PropTypes.object.isRequired,
    usersDataAccess: PropTypes.object.isRequired,
    onCancel: PropTypes.func
};

let mapStateToProps = function(state, ownProps) {
    return {};
};

let mapDispatchToProps = function(dispatch) {
    return {
        usersDataAccess: bindActionCreators(UsersDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Korisnik);
