let initialState = {
    dnevnaEvidencija: []
};

export default function dnevnaEvidencijaReducer(state = initialState, action) {
    switch (action.type) {
        case 'GET_DNEVNA_EVIDENCIJA': {
            return Object.assign({}, state, {
                dnevnaEvidencija: [...action.dnevnaEvidencija]
            });
        }
        default:
            return state;
    }
}
