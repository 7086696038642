import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Accordion, Button, Dimmer, Grid, Header, Icon, Loader, Message, Modal, Pagination, Search, Segment, Table } from 'semantic-ui-react';

import localization from '../../locales/localization';
import enums from '../../utils/Enums';
import icons from '../../utils/DefinedIcons';
import endpoints from '../../environments/endpoints';
import PodizanjeRezervacijeModal from './PodizanjeRezervacijeModal';
import ProduzenjeRezervacijeModal from './ProduzenjeRezervacijeModal';
import ProduzenjeRokaZaPodizanjeModal from './ProduzenjeRokaZaPodizanjeModal';
import OtkazivanjeRezervacijeModal from './OtkazivanjeRezervacijeModal';
import PredlaganjeProdajnihCijenaModal from '../common_components/PredlaganjeProdajnihCijenaModal';
import * as RezervacijaDataAccess from '../../data_access/RezervacijaDataAccess';
import * as RezervacijeApi from '../../data_access/RezervacijeApi';
import { bindActionCreators } from 'redux';
import { storeDocumentAndGenerateKey } from '../../utils/PrintingDocumentsHandler';
import moment from 'moment';
import SearchResultsMessage from '../common_components/SearchResultsMessage';
import ImageUploadModal from '../common_components/ImageUploadModal';
import Diskusija from '../common_components/Diskusija';
import RezervacijaContent from '../dokumenti_components/content_components/RezervacijaContent';
import FilterSortButton from '../common_components/FilterSortButton';

// TODO #pagination #refactor Extract this to some other place when pagination for other components is implemented
//      and we solve the Redux/DataAccess dilemma.
const MAX_API_FETCH_ATTEMPTS = 3;
// TODO #pagination #refactor Probably extract somewhere else and use across all search components
const SEARCH_DEBOUNCE_RATE_MS = 1000;

const styles = {
    headerTable: {
        padding: '.75em 1em'
    },
    iconCell: {
        width: 50
    },
    rezervacijskiBrojCell: {
        width: 180
    },
    klijentCell: {
        width: 250
    },
    brojArtikalaCell: {
        width: 180
    },
    shortDatumCell: {
        width: 120
    },
    longDatumCell: {
        width: 180
    },
    sectionHeader: {
        marginTop: 4
    },
    accordionHeaderContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    accordionHeader: {
        paddingTop: 0
    },
    artikliContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between'
    },
    artikliHeader: {
        marginBottom: 8
    },
    actionButtons: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    semaforStyle: {
        marginLeft: 4
    }
};

const modaliEnums = {
    galerijaSlika: 'galerijaSlika',
    produzenjeRezervacije: 'produzenjeRezervacije',
    podizanjeRezervacije: 'podizanjeRezervacije',
    produzivanjeRoka: 'produzivanjeRoka',
    otkazivanjeRezervacije: 'otkazivanjeRezervacije',
    prijedlogProdajnihCijena: 'prijedlogProdajnihCijena'
};

const ITEMS_PER_PAGE = 10;

const LIST_STATE = {
    initial: 'initial',
    empty: 'empty',
    noResult: 'noResult',
    populated: 'populated',
    error: 'error'
};

class RootRezervacija extends React.Component {
    constructor(props) {
        super(props);
        this.predeselectRezervacije = this.predeselectRezervacije.bind(this);
        this.state = {
            value: '',
            results: [],
            deselected: [],
            activeReservation: [],
            activeComments: [],
            noveNapomene: [],
            stavkeZaPredlozitiCijenu: [],
            loader: false,
            activeModal: undefined,
            filter: 0,
            pageActive: 1
        };
        this.onNovaRezervacija = this.onNovaRezervacija.bind(this);
        this.onImportRezervacija = this.onImportRezervacija.bind(this);
        this.attachColor = this.attachColor.bind(this);
        this.calculateNumberOfItems = this.calculateNumberOfItems.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
        this.applyFilterAndSort = this.applyFilterAndSort.bind(this);
        this.resetSearchComponent = this.resetSearchComponent.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.onDeselectReservation = this.onDeselectReservation.bind(this);
        this.onChangeNovaNapomena = this.onChangeNovaNapomena.bind(this);
        this.expandReservation = this.expandReservation.bind(this);
        this.expandComments = this.expandComments.bind(this);
        this.extractRezervacije = this.extractRezervacije.bind(this);
        this.showPrijedlogProdajnihCijenaModal = this.showPrijedlogProdajnihCijenaModal.bind(this);
        this.onRezervacijaOtkazana = this.onRezervacijaOtkazana.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.showLoader = this.showLoader.bind(this);
        this.hideLoader = this.hideLoader.bind(this);
        this.dodajNapomenu = this.dodajNapomenu.bind(this);
        this.resetNapomena = this.resetNapomena.bind(this);
        this.findFirstDate = this.findFirstDate.bind(this);
        this.openDokument = this.openDokument.bind(this);
        this.renderSection = this.renderSection.bind(this);

        this.getPoslovnicaIdForFilter = this.getPoslovnicaIdForFilter.bind(this);
        this.fetchZahtjeviZaRezervaciju = this.fetchZahtjeviZaRezervaciju.bind(this);
        this.refreshZahtjeviZaRezervaciju = this.refreshZahtjeviZaRezervaciju.bind(this);
        this.debouncedFetchZahtjeviZaRezervaciju = _.debounce(function (filter, page) {
            this.fetchZahtjeviZaRezervaciju(filter, page);
        }, SEARCH_DEBOUNCE_RATE_MS).bind(this);

        if (sessionStorage.userRole !== enums.tipKorisnika.DJELATNIK) {
            this.FILTER_ITEMS = [{
                name: localization.common.svePoslovnice,
                apiParam: null
            }];
            this.props.poslovnice.forEach(p => this.FILTER_ITEMS.push({
                name: p.naziv,
                apiParam: p.id
            }));
        }
    }

    componentWillMount() {
        this.resetSearchComponent();
    }

    predeselectRezervacije(zahtjeviZaRezervaciju) {
        let nevazeceRezervacije = [];
        zahtjeviZaRezervaciju.forEach(zr => {
            zr.rezervacije.forEach(r => {
                if (!r.isVazeca) {
                    nevazeceRezervacije.push(
                        Object.assign({}, r, {
                            zahtjevRezervacije: zr.id
                        })
                    );
                }
            });
        });
        return nevazeceRezervacije;
    }

    onNovaRezervacija() {
        this.props.history.push(endpoints.appEndpoints.novaRezervacija);
    }

    onImportRezervacija() {
        this.props.history.push(endpoints.appEndpoints.importRezervacija);
    }

    attachColor(stanje) {
        if (stanje === 1) {
            return 'red';
        } else if (stanje === 2) {
            return 'blue';
        } else {
            return 'green';
        }
    }

    calculateNumberOfItems(rezervacije) {
        return rezervacije.filter(r => r.isVazeca).length;
    }

    onChangePage(e, { activePage }) {
        this.fetchZahtjeviZaRezervaciju(this.state.filter, activePage);
    }

    applyFilterAndSort(filter) {
        this.fetchZahtjeviZaRezervaciju(filter);
    }

    resetSearchComponent() {
        this.setState({
            listState: LIST_STATE.initial,
            fetchAttempts: 0
        }, this.fetchZahtjeviZaRezervaciju);
    }

    handleSearchChange(e, { value }) {
        this.setState({
            value: value
        }, this.debouncedFetchZahtjeviZaRezervaciju);
    }

    onDeselectReservation(reservation, id) {
        let comparator = (first, second) => first.id === second.id && first.zahtjevRezervacije === second.zahtjevRezervacije;
        let r = Object.assign({}, reservation, {
            zahtjevRezervacije: id
        });
        this.setState({
            deselected: _.xorWith(this.state.deselected, [r], comparator)
        });
    }

    onChangeNovaNapomena(id, e, { value }) {
        let noveNapomene = [];
        let index = this.state.noveNapomene.findIndex(el => el.id === id);
        if (index > -1) {
            noveNapomene = [...this.state.noveNapomene];
            noveNapomene[index] = {
                id: id,
                napomena: value
            };
        } else {
            noveNapomene = [
                ...this.state.noveNapomene,
                {
                    id: id,
                    napomena: value
                }
            ];
        }
        this.setState({
            noveNapomene: noveNapomene
        });
    }

    expandReservation(e, { index }) {
        let activeReservation = [];
        if (this.state.activeReservation.includes(index)) {
            activeReservation = _.xor(this.state.activeReservation, [index]);
        } else {
            activeReservation = [...this.state.activeReservation, index];
        }
        this.setState({
            activeReservation: activeReservation
        });
    }

    expandComments(e, { index }) {
        let activeComments = [];
        if (this.state.activeComments.includes(index)) {
            activeComments = _.xor(this.state.activeComments, [index]);
        } else {
            activeComments = [...this.state.activeComments, index];
        }
        this.setState({
            activeComments: activeComments
        });
    }

    extractRezervacije(zahtjevRezervacije) {
        let res = this.state.deselected.filter(r => r.zahtjevRezervacije === zahtjevRezervacije.id);
        if (res.length) {
            zahtjevRezervacije.rezervacije = _.xorBy(zahtjevRezervacije.rezervacije, res, 'id');
        }
        return zahtjevRezervacije;
    }

    showPrijedlogProdajnihCijenaModal(rezOtkazanaResp) {
        this.setState({
            stavkeZaPredlozitiCijenu: rezOtkazanaResp.skladisneStavkeOtkazanihRezervacija.map(ss => {
                let ukupnaNabavnaCijena = 0,
                    ukupnaKolicina = 0;
                ss.jedinke.forEach(j => {
                    ukupnaNabavnaCijena += j.jedNabavnaCijena;
                    ukupnaKolicina += j.kolicina;
                });
                let nabavnaCijena = ukupnaNabavnaCijena / ukupnaKolicina;
                return Object.assign({}, ss, {
                    skladisnaStavkaId: ss.id,
                    jedNabavnaCijena: nabavnaCijena
                });
            }),
            activeModal: Object.assign(
                this.state.activeModal,
                {},
                {
                    type: modaliEnums.prijedlogProdajnihCijena
                }
            )
        });
    }

    onRezervacijaOtkazana(rezOtkazanaResp) {
        this.refreshZahtjeviZaRezervaciju();
        this.showPrijedlogProdajnihCijenaModal(rezOtkazanaResp);
    }

    showModal(name, sifra) {
        this.setState({
            activeModal: {
                type: name,
                id: sifra
            }
        });
    }

    hideModal(callback) {
        this.setState({ activeModal: undefined }, callback);
    }

    showLoader() {
        this.setState(
            {
                loader: true
            }
        );
    }

    hideLoader(callback) {
        this.setState(
            {
                loader: false
            },
            callback
        );
    }

    dodajNapomenu(zahtjevRezervacijeId) {
        this.showLoader();
        this.props.rezervacijaDataAccess.dodavanjeNesluzbeneNapomeneNaRezervaciju(
            zahtjevRezervacijeId,
            this.state.noveNapomene.find(el => el.id === zahtjevRezervacijeId).napomena,
            () => this.hideLoader(() => this.resetNapomena(zahtjevRezervacijeId, () => this.refreshZahtjeviZaRezervaciju())),
            () => this.hideLoader()
        );
    }

    resetNapomena(id, callback) {
        this.setState({
            noveNapomene: _.reject(this.state.noveNapomene, (napomena) => napomena.id === id)
        }, callback);
    }

    findFirstDate(rezervacije, dateField) {
        let date;
        rezervacije.forEach(r => {
            let d = r[dateField];
            if (date) {
                if (date.isAfter(d)) {
                    date = moment(d);
                }
            } else {
                date = moment(d);
            }
        });
        return date;
    }

    openDokument(rezervacija) {
        window.open(
            endpoints.appEndpoints.print.potvrdaRezervacije +
                storeDocumentAndGenerateKey(
                    Object.assign(rezervacija.otkupniBlokovi[0], {
                        rezervacije: rezervacija.rezervacije,
                        sluzbenaNapomena: rezervacija.sluzbenaNapomena
                    })
                ),
            '_blank'
        );
    }

    renderSection(zahtjevRezervacije) {
        return (
            <Grid.Row key={zahtjevRezervacije.id} style={styles.accordionHeader}>
                <Grid.Column verticalAlign="middle">
                    <Accordion fluid styled>
                        <Accordion.Title
                            active={this.state.activeReservation.includes(zahtjevRezervacije.id)}
                            index={zahtjevRezervacije.id}
                            onClick={this.expandReservation}
                        >
                            <Table structured stackable color={this.attachColor(zahtjevRezervacije.stanjeZR)}>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.Cell style={styles.iconCell}>
                                            <Icon name="dropdown" />
                                        </Table.Cell>
                                        <Table.Cell style={styles.rezervacijskiBrojCell}>{zahtjevRezervacije.rbrRezervacije}</Table.Cell>
                                        <Table.Cell style={styles.klijentCell}>
                                            {zahtjevRezervacije.klijent.tipKlijenta === enums.tipKlijenta.FIZICKA_OSOBA
                                                ? zahtjevRezervacije.klijent.ime + ' ' + zahtjevRezervacije.klijent.prezime
                                                : zahtjevRezervacije.klijent.naziv}
                                        </Table.Cell>
                                        <Table.Cell style={styles.brojArtikalaCell}>{this.calculateNumberOfItems(zahtjevRezervacije.rezervacije)}</Table.Cell>
                                        <Table.Cell style={styles.shortDatumCell}>
                                            {this.findFirstDate(zahtjevRezervacije.rezervacije, 'datumIsteka').format('DD.MM.YYYY.')}
                                        </Table.Cell>
                                        <Table.Cell style={styles.shortDatumCell}>
                                            {this.findFirstDate(zahtjevRezervacije.rezervacije, 'rokZaPodizanje').format('DD.MM.YYYY.')}
                                        </Table.Cell>
                                        <Table.Cell style={styles.longDatumCell}>
                                            {moment(zahtjevRezervacije.datumIVrijeme).format('DD.MM.YYYY. HH:mm')}
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Header>
                            </Table>
                        </Accordion.Title>
                        <Accordion.Content active={this.state.activeReservation.includes(zahtjevRezervacije.id)}>
                            <RezervacijaContent
                                zahtjevRezervacije={zahtjevRezervacije}
                                showNevazece={false}
                                imageUploadModal={
                                    <div style={styles.artikliContainer}>
                                        <Header as="h4" style={styles.artikliHeader}>
                                            {localization.rezervacije.artikli}
                                        </Header>
                                        <div>
                                            {zahtjevRezervacije.otkupniBlokovi.length ? (
                                                <Button
                                                    icon="print"
                                                    content={localization.rezervacije.ispisPotvrdeRezervacije}
                                                    onClick={this.openDokument.bind(this, zahtjevRezervacije)}
                                                />
                                            ) : null}
                                            <Modal
                                                open={
                                                    this.state.activeModal &&
                                                    this.state.activeModal.type === modaliEnums.galerijaSlika &&
                                                    this.state.activeModal.id === zahtjevRezervacije.id
                                                }
                                                trigger={
                                                    <Button
                                                        icon="image"
                                                        content={localization.common.galerijaSlika}
                                                        onClick={() => this.showModal(modaliEnums.galerijaSlika, zahtjevRezervacije.id)}
                                                    />
                                                }
                                            >
                                                <Header icon="image" content={localization.common.galerijaSlika} />
                                                <Modal.Content>
                                                    <ImageUploadModal
                                                        imageIds={zahtjevRezervacije.imageIds}
                                                        saveImages={(images, callback) => {
                                                            this.props.rezervacijaDataAccess.dodajSlikeNaRezervaciju(
                                                                zahtjevRezervacije.id, images,
                                                                () => {
                                                                    // TODO @Kec This is freaking weird, fix this mess after paginating all pages since ImageUploadModal is used in multiple places
                                                                    callback();
                                                                    this.refreshZahtjeviZaRezervaciju();
                                                                }
                                                            );
                                                        }}
                                                        deleteImages={(imageIds, callback) => {
                                                            this.props.rezervacijaDataAccess.izbrisiSlikeSaRezervacije(
                                                                zahtjevRezervacije.id, imageIds,
                                                                () => {
                                                                    // TODO @Kec This is freaking weird, fix this mess after paginating all pages since ImageUploadModal is used in multiple places
                                                                    callback();
                                                                    this.refreshZahtjeviZaRezervaciju();
                                                                }
                                                            );
                                                        }}
                                                        closeModal={() => this.hideModal()}
                                                    />
                                                </Modal.Content>
                                            </Modal>
                                        </div>
                                    </div>
                                }
                                enableRowChecking={true}
                                listOfDeselected={this.state.deselected}
                                onRowSelected={this.onDeselectReservation}
                            />
                            {_.filter(this.state.deselected, zr => zr.zahtjevRezervacije === zahtjevRezervacije.id).length !==
                            zahtjevRezervacije.rezervacije.length ? (
                                <div style={styles.actionButtons}>
                                    {sessionStorage.userRole === enums.tipKorisnika.DJELATNIK ? (
                                        <React.Fragment>
                                            <Modal
                                                open={
                                                    this.state.activeModal &&
                                                    this.state.activeModal.type === modaliEnums.produzenjeRezervacije &&
                                                    this.state.activeModal.id === zahtjevRezervacije.id
                                                }
                                                trigger={
                                                    <Button
                                                        icon="calendar plus"
                                                        color="orange"
                                                        content={localization.rezervacije.produziRezervacije}
                                                        onClick={() => this.showModal(modaliEnums.produzenjeRezervacije, zahtjevRezervacije.id)}
                                                    />
                                                }
                                            >
                                                <Header icon="calendar plus" content={localization.rezervacije.produzenjeRezervacije} />
                                                <Modal.Content>
                                                    <ProduzenjeRezervacijeModal
                                                        zahtjevRezervacije={this.extractRezervacije(Object.assign({}, zahtjevRezervacije))}
                                                        closeModal={() => this.hideModal()}
                                                        onProduzenjeSuccess={() => this.refreshZahtjeviZaRezervaciju()}
                                                    />
                                                </Modal.Content>
                                            </Modal>
                                            <Modal
                                                open={
                                                    this.state.activeModal &&
                                                    this.state.activeModal.type === modaliEnums.podizanjeRezervacije &&
                                                    this.state.activeModal.id === zahtjevRezervacije.id
                                                }
                                                trigger={
                                                    <Button
                                                        icon="calendar check"
                                                        color="green"
                                                        content={localization.rezervacije.podigniRezervacije}
                                                        onClick={() => this.showModal(modaliEnums.podizanjeRezervacije, zahtjevRezervacije.id)}
                                                    />
                                                }
                                            >
                                                <Header icon="calendar check" content={localization.rezervacije.podizanjeRezervacije} />
                                                <Modal.Content>
                                                    <PodizanjeRezervacijeModal
                                                        zahtjevRezervacije={this.extractRezervacije(Object.assign({}, zahtjevRezervacije))}
                                                        closeModal={() => this.hideModal()}
                                                        onPodizanjeSuccess={() => this.refreshZahtjeviZaRezervaciju()}
                                                    />
                                                </Modal.Content>
                                            </Modal>
                                        </React.Fragment>
                                    ) : null}
                                    <Modal
                                        open={
                                            this.state.activeModal &&
                                            this.state.activeModal.type === modaliEnums.produzivanjeRoka &&
                                            this.state.activeModal.id === zahtjevRezervacije.id
                                        }
                                        trigger={
                                            <Button
                                                icon="calendar outline"
                                                color="blue"
                                                content={localization.rezervacije.produziRokZaPodizanje}
                                                onClick={() => this.showModal(modaliEnums.produzivanjeRoka, zahtjevRezervacije.id)}
                                            />
                                        }
                                    >
                                        <Header icon="calendar outline" content={localization.rezervacije.produzivanjeRoka} />
                                        <Modal.Content>
                                            <ProduzenjeRokaZaPodizanjeModal
                                                zahtjevRezervacije={this.extractRezervacije(Object.assign({}, zahtjevRezervacije))}
                                                onProduzenRok={() => this.refreshZahtjeviZaRezervaciju()}
                                                closeModal={() => this.hideModal()}
                                            />
                                        </Modal.Content>
                                    </Modal>
                                    <Modal
                                        open={
                                            this.state.activeModal &&
                                            this.state.activeModal.type === modaliEnums.otkazivanjeRezervacije &&
                                            this.state.activeModal.id === zahtjevRezervacije.id
                                        }
                                        trigger={
                                            <Button
                                                icon="calendar times"
                                                color="red"
                                                content={localization.rezervacije.otkaziRezervacije}
                                                onClick={() => this.showModal(modaliEnums.otkazivanjeRezervacije, zahtjevRezervacije.id)}
                                            />
                                        }
                                    >
                                        <Header icon="calendar times" content={localization.rezervacije.otkazivanjeRezervacije} />
                                        <Modal.Content>
                                            <OtkazivanjeRezervacijeModal
                                                zahtjevRezervacije={this.extractRezervacije(Object.assign({}, zahtjevRezervacije))}
                                                onRezervacijaOtkazana={this.onRezervacijaOtkazana}
                                                closeModal={() => this.hideModal()}
                                            />
                                        </Modal.Content>
                                    </Modal>
                                    <Modal
                                        open={
                                            this.state.activeModal &&
                                            this.state.activeModal.type === modaliEnums.prijedlogProdajnihCijena &&
                                            !!this.state.stavkeZaPredlozitiCijenu.length
                                        }
                                        closeOnDimmerClick={false}
                                    >
                                        <Header icon={icons.otkup} content={localization.otkup.predlaganjeProdajnihCijena} />
                                        <Modal.Content>
                                            <PredlaganjeProdajnihCijenaModal
                                                ulazi={this.state.stavkeZaPredlozitiCijenu}
                                                closeModal={() => this.hideModal()}
                                            />
                                        </Modal.Content>
                                    </Modal>
                                </div>
                            ) : (
                                <div style={styles.actionButtons}>
                                    <Button icon="calendar plus" disabled={true} content={localization.rezervacije.produziRezervacije} />
                                    <Button icon="calendar check" disabled={true} content={localization.rezervacije.podigniRezervacije} />
                                    <Button icon="calendar outline" disabled={true} content={localization.rezervacije.produziRokZaPodizanje} />
                                    <Button icon="calendar times" color="red" disabled={true} content={localization.rezervacije.otkaziRezervacije} />
                                </div>
                            )}
                            {zahtjevRezervacije.sluzbenaNapomena ? (
                                <Message>
                                    <Message.Header>{localization.common.sluzbenaNapomena}</Message.Header>
                                    <p>{zahtjevRezervacije.sluzbenaNapomena}</p>
                                </Message>
                            ) : null}
                            <Accordion fluid styled>
                                <Accordion.Title
                                    active={this.state.activeComments.includes(zahtjevRezervacije.id)}
                                    index={zahtjevRezervacije.id}
                                    onClick={this.expandComments}
                                >
                                    <Header as="h5" dividing style={styles.accordionHeaderContainer}>
                                        <Icon name="dropdown" />
                                        {localization.common.napomene}
                                        {zahtjevRezervacije.nesluzbeneNapomene.length ? (
                                            <Icon name="exclamation circle" color="red" style={styles.semaforStyle} />
                                        ) : null}
                                    </Header>
                                </Accordion.Title>
                                <Accordion.Content active={this.state.activeComments.includes(zahtjevRezervacije.id)}>
                                    <Diskusija
                                        napomene={zahtjevRezervacije.nesluzbeneNapomene}
                                        textAreaValue={
                                            this.state.noveNapomene.find(el => el.id === zahtjevRezervacije.id)
                                                ? this.state.noveNapomene.find(el => el.id === zahtjevRezervacije.id).napomena
                                                : ''
                                        }
                                        onChangeNovaNapomena={this.onChangeNovaNapomena.bind(this, zahtjevRezervacije.id)}
                                        onDodajNapomenu={() => this.dodajNapomenu(zahtjevRezervacije.id)}
                                    />
                                </Accordion.Content>
                            </Accordion>
                        </Accordion.Content>
                    </Accordion>
                </Grid.Column>
            </Grid.Row>
        );
    }

    getPoslovnicaIdForFilter(filter) {
        return this.FILTER_ITEMS
            ? this.FILTER_ITEMS[filter].apiParam
            : JSON.parse(sessionStorage.poslovnicaId);
    }

    fetchZahtjeviZaRezervaciju(filter = this.state.filter, page = 1) {

        let nextStateCommon = {
            loader: false,
            fetchAttempts: 0,
            filter: filter
        };

        let onFetchSuccess = (data) => {
            if (data.items.length !== 0) {
                let nextStateNonEmpty = {
                    listState: LIST_STATE.populated,
                    results: data.items,
                    deselected: this.predeselectRezervacije(data.items),
                    totalPages: data.totalPages,
                    pageActive: page
                };
                this.setState(Object.assign({}, nextStateCommon, nextStateNonEmpty));

            } else {

                let fetchedAll = this.state.value === '';

                let nextStateEmpty = {
                    listState: fetchedAll ? LIST_STATE.empty : LIST_STATE.noResult,
                    results: [],
                    deselected: []
                };

                this.setState(Object.assign({}, nextStateCommon, nextStateEmpty));
            }
        };

        let onFetchError = () => {
            let fetchAttempts = this.state.fetchAttempts + 1;
            if (fetchAttempts === MAX_API_FETCH_ATTEMPTS) {
                let nextStateError = {
                    listState: LIST_STATE.error
                };
                this.setState(Object.assign({}, nextStateCommon, nextStateError));
            } else {
                this.setState({
                    fetchAttempts: fetchAttempts
                }, () => {
                    this.fetchZahtjeviZaRezervaciju(filter, page);
                });
            }
        };

        this.showLoader();

        RezervacijeApi.getZahtjeviZaRezervacijuPaginated(
            this.state.value, this.getPoslovnicaIdForFilter(filter),
            page, ITEMS_PER_PAGE,
            onFetchSuccess, onFetchError
        );
    }

    refreshZahtjeviZaRezervaciju() {
        this.fetchZahtjeviZaRezervaciju(this.state.filter, this.state.pageActive);
    }

    renderInitialComponent() {
        return (
            <SearchResultsMessage
                icon={icons.rezervacija}
                header={localization.common.ucitavanje}
                content={localization.common.molimoPricekajte}
            />
        );
    }

    renderEmptyComponent() {
        return (
            <SearchResultsMessage
                icon={icons.rezervacija}
                header={localization.rezervacije.nemaRezervacija}
                content={undefined}
            />
        );
    }

    renderNoResultComponent() {
        return (
            <SearchResultsMessage
                icon={icons.rezervacija}
                header={localization.common.unesenaPretragaNemaRezultata}
                content={localization.common.pokusajDrugacijuPretragu}
            />
        );
    }

    renderErrorComponent() {
        return (
            <SearchResultsMessage
                icon={icons.rezervacija}
                header={localization.common.dogodilaSeGreska}
                content={localization.common.pokusajtePonovno}
            />
        );
    }

    renderListComponent() {

        let res = _.sortBy(this.state.results, ['stanjeZR']);
        let results = {};
        results.istekaoRZP = res.filter(zr => zr.stanjeZR === 1);
        results.nijeIstekaoRZPIstekla = res.filter(zr => zr.stanjeZR === 2);
        results.nijeIstekaoRZPNijeIstekla = res.filter(zr => zr.stanjeZR === 3);

        return (
            <React.Fragment>
                <Grid.Row>
                    <Grid.Column>
                        <Message style={styles.headerTable}>
                            <Table structured stackable>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell style={styles.iconCell} />
                                        <Table.HeaderCell style={styles.rezervacijskiBrojCell}>
                                            {localization.rezervacije.brojRezervacije}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell style={styles.klijentCell}>{localization.common.klijent}</Table.HeaderCell>
                                        <Table.HeaderCell style={styles.brojArtikalaCell}>
                                            {localization.rezervacije.brojArtikala}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell style={styles.shortDatumCell}>
                                            {localization.rezervacije.datumIsteka}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell style={styles.shortDatumCell}>
                                            {localization.rezervacije.rokZaPodizanje}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell style={styles.longDatumCell}>
                                            {localization.rezervacije.datumIVrijemeRezervacije}
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                            </Table>
                        </Message>
                    </Grid.Column>
                </Grid.Row>
                {results.istekaoRZP.length ? (
                    <React.Fragment>
                        <Header.Subheader as="h5" style={styles.sectionHeader}>
                            {localization.rezervacije.rezervacijeIstekaoRZP}
                        </Header.Subheader>
                        {results.istekaoRZP.map(this.renderSection)}
                    </React.Fragment>
                ) : null}
                {results.nijeIstekaoRZPIstekla.length ? (
                    <React.Fragment>
                        <Header.Subheader as="h5" style={styles.sectionHeader}>
                            {localization.rezervacije.rezervacijeNijeIstekaoRZPIstekla}
                        </Header.Subheader>
                        {results.nijeIstekaoRZPIstekla.map(this.renderSection)}
                    </React.Fragment>
                ) : null}
                {results.nijeIstekaoRZPNijeIstekla.length ? (
                    <React.Fragment>
                        <Header.Subheader as="h5" style={styles.sectionHeader}>
                            {localization.rezervacije.rezervacijeNijeIstekaoRZPNijeIstekla}
                        </Header.Subheader>
                        {results.nijeIstekaoRZPNijeIstekla.map(this.renderSection)}
                    </React.Fragment>
                ) : null}
                <Grid.Row centered>
                    <Pagination
                        activePage={this.state.pageActive}
                        totalPages={this.state.totalPages}
                        onPageChange={this.onChangePage}
                        ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
                        firstItem={{ content: <Icon name="angle double left" />, icon: true }}
                        lastItem={{ content: <Icon name="angle double right" />, icon: true }}
                        prevItem={{ content: <Icon name="angle left" />, icon: true }}
                        nextItem={{ content: <Icon name="angle right" />, icon: true }}
                    />
                </Grid.Row>
            </React.Fragment>
        );
    }

    render() {
        return (
            <div>
                <Segment tertiary>
                    <Grid stackable>
                        <Grid.Row columns={6}>
                            <Grid.Column verticalAlign="middle">
                                <Header as="h2">{localization.rezervacije.rezervacije}</Header>
                            </Grid.Column>
                            <Grid.Column verticalAlign="middle">
                                <Search
                                    input={{ fluid: true }}
                                    onSearchChange={this.handleSearchChange}
                                    value={this.state.value}
                                    open={false}
                                />
                            </Grid.Column>
                            <Grid.Column />
                            <Grid.Column />
                            {sessionStorage.userRole === enums.tipKorisnika.DJELATNIK ? (
                                <React.Fragment>
                                    <Grid.Column />
                                    <Grid.Column textAlign="right" verticalAlign="middle">
                                        <Button
                                            icon="asterisk"
                                            basic
                                            color="black"
                                            content={localization.rezervacija.novaRezervacija}
                                            onClick={this.onNovaRezervacija.bind(this)}
                                        />
                                    </Grid.Column>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <Grid.Column textAlign="right" verticalAlign="middle">
                                        <FilterSortButton
                                            buttonName={localization.common.filtriranje}
                                            filteringOptions={this.FILTER_ITEMS.map((f) => f.name)}
                                            activeFilter={this.state.filter}
                                            callback={this.applyFilterAndSort}
                                        />
                                    </Grid.Column>
                                    <Grid.Column textAlign="right" verticalAlign="middle">
                                        <Button
                                            icon="arrow circle up"
                                            basic
                                            color="black"
                                            content={localization.rezervacija.importRezervacije}
                                            onClick={this.onImportRezervacija.bind(this)}
                                        />
                                    </Grid.Column>
                                </React.Fragment>
                            )}
                        </Grid.Row>
                        {(() => {
                            switch (this.state.listState) {
                                case LIST_STATE.initial:
                                    return this.renderInitialComponent();
                                case LIST_STATE.populated:
                                    return this.renderListComponent();
                                case LIST_STATE.empty:
                                    return this.renderEmptyComponent();
                                case LIST_STATE.noResult:
                                    return this.renderNoResultComponent();
                                case LIST_STATE.error:
                                    return this.renderErrorComponent();
                            }
                        })()}
                    </Grid>
                </Segment>
                <Dimmer active={this.state.loader} page>
                    <Loader />
                </Dimmer>
            </div>
        );
    }
}

RootRezervacija.propTypes = {
    history: PropTypes.object.isRequired,
    poslovnice: PropTypes.array.isRequired,
    rezervacijaDataAccess: PropTypes.object.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {
        poslovnice: state.mjestaReducer.poslovnice || []
    };
};

let mapDispatchToProps = function(dispatch) {
    return {
        rezervacijaDataAccess: bindActionCreators(RezervacijaDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RootRezervacija);
