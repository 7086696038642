import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, Header, Segment } from 'semantic-ui-react';

import Flag from '../common_components/Flag';
import AddNewButton from '../common_components/AddNewButton';
import { generateKey } from '../../utils/KeyGenerator';
import localization from '../../locales/localization';

class CijeneOglasavanje extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            flags: this.props.flags,
            noviFlag: false
        };
        this.onDeleteFlag = this.onDeleteFlag.bind(this);
        this.onAddNew = this.onAddNew.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            flags: nextProps.flags,
            noviFlag: false
        });
    }

    onDeleteFlag(index) {
        this.setState({
            flags: this.state.flags.slice(0, index).concat(this.state.flags.slice(index + 1)),
            noviFlag: this.state.noviFlag && !(index === this.state.flags.length - 1)
        });
    }

    onAddNew(e) {
        this.setState({
            flags: [
                ...this.state.flags,
                {
                    naziv: '',
                    novi: true
                }
            ],
            noviFlag: true
        });
    }

    render() {
        return (
            <Segment tertiary>
                <Header as="h2">{localization.postavke.oznake}</Header>
                <Card.Group itemsPerRow={4} stackable>
                    {this.state.flags.map((f, index) => {
                        return (
                            <Card key={index}>
                                <Card.Content textAlign="center">
                                    <Flag novi={f.novi} flag={f} onDelete={this.onDeleteFlag.bind(this, index)} />
                                </Card.Content>
                            </Card>
                        );
                    })}
                    {!this.state.noviFlag ? (
                        <AddNewButton key={generateKey()} text={localization.postavke.dodajNovuOznaku} name="noviFlag" onAddNew={this.onAddNew} />
                    ) : null}
                </Card.Group>
            </Segment>
        );
    }
}

CijeneOglasavanje.propTypes = {
    history: PropTypes.object.isRequired,
    flags: PropTypes.array.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {
        flags: state.flagsReducer.flags || []
    };
};

let mapDispatchToProps = function(dispatch) {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CijeneOglasavanje);
