import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Icon, Pagination, Search } from 'semantic-ui-react';
import './SearchPaginated.css';


const DEFAULT_SEARCH_DEBOUNCE_RATE_MS = 1000;
const DEFAULT_PAGE_SIZE = 5;
const DEFAULT_MAX_FETCH_ATTEMPTS = 2;

const ITEM_TYPE = {
    listItem: 'LIST_ITEM',
    pagination: 'PAGINATION',
    error: 'ERROR',
};

// TODO @Kec #pagination #search-paginated Fix result popup width

/**
 * Wrapper around the Search component which renders the Pagination at the bottom of the result list.
 */
export default class SearchPaginated extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            loading: false,
        };
        this.onSearchChange = this.onSearchChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.search = this.search.bind(this);
        this.onResultSelect = this.onResultSelect.bind(this);
        this.wrapItems = this.wrapItems.bind(this);
        this.unwrapSelectedListItem = this.unwrapSelectedListItem.bind(this);
        this.createListItem = this.createListItem.bind(this);
        this.createPaginationItem = this.createPaginationItem.bind(this);
        this.createErrorItem = this.createErrorItem.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.renderPagination = this.renderPagination.bind(this);
        this.inputFocus = utilizeFocus();
        this.debouncedSearch = _.debounce(this.search, this.props.debounceRate).bind(this);
    }

    onSearchChange(e, { value }) {
        this.setState(
            {
                value: value,
            },
            this.debouncedSearch
        );
    }

    onPageChange(_e, { activePage }) {
        this.search(activePage);
    }

    search(page = 1) {
        // TODO @Kec Remove this `if` statement if we decide to show the search results when search query empty
        if (this.state.value.length === 0) {
            return;
        }

        let nextStateCommon = {
            loading: false,
        };

        let onFetchSuccess = (data) => {
            let nextStateSuccess = {
                items: this.wrapItems(data.items),
                totalPages: data.totalPages,
                activePage: page,
            };
            // TODO #pagination @Kec @Vranjes Why doesn't the spread operator work here? JS Version too old?
            //      Used the Object.assign quickfix: https://techstrology.com/spread-operator-unexpected-token-javascript
            this.setState(Object.assign({}, nextStateCommon, nextStateSuccess));
        };

        let onFetchError = () => {
            let fetchAttempts = this.state.fetchAttempts + 1;
            if (fetchAttempts === this.props.maxFetchAttempts) {
                // TODO @Kec #pagination handle error
                let nextStateError = {
                    items: [this.createErrorItem()]
                };
                this.setState(Object.assign({}, nextStateCommon, nextStateError));
            } else {
                this.setState(
                    {
                        fetchAttempts: fetchAttempts,
                    },
                    () => {
                        this.props.fetch(this.state.value, page, this.props.pageSize, onFetchSuccess, onFetchError);
                    }
                );
            }
        };

        this.setState({
            loading: true,
            fetchAttempts: 0,
        });

        this.props.fetch(this.state.value, page, this.props.pageSize, onFetchSuccess, onFetchError);
    }

    onResultSelect(e, data) {
        switch (data.result.type) {
            case ITEM_TYPE.listItem:
                this.props.onResultSelect(e, this.unwrapSelectedListItem(data));
                break;
            case ITEM_TYPE.error:
                break;
            case ITEM_TYPE.pagination:
                // This is an ugly solution to the problem where the search result popup disappears once
                // the user clicks on the item in the popup. That behavior is desirable if the user clicks
                // on the search result. But if they click on the pagination, we do not want the popup to disappear.
                // We make the popup appear again by manually setting focus on the input search field.
                // However, for some reason, that only works if it is done with the setTimeout function, even if
                // the timeout is 0 ms. There is still some flickering (the popup briefly disappears and reappears),
                // but we can live with that.
                setTimeout(() => {
                    this.inputFocus.setFocus();
                }, 0);
                break;
        }
    }

    wrapItems(results) {
        let items = results.map(this.createListItem);
        if (items.length > 0) {
            items.push(this.createPaginationItem());
        }
        return items;
    }

    unwrapSelectedListItem(selectedItemData) {
        return Object.assign({}, selectedItemData, {
            result: selectedItemData.result.payload,
        });
    }

    createListItem(payload) {
        return {
            type: ITEM_TYPE.listItem,
            payload: payload,
        };
    }

    createPaginationItem() {
        return {
            type: ITEM_TYPE.pagination,
        };
    }

    createErrorItem() {
        return {
            type: ITEM_TYPE.error,
        };
    }

    renderItem(item) {
        switch (item.type) {
            case ITEM_TYPE.listItem:
                return this.props.resultRenderer(item.payload);
            case ITEM_TYPE.pagination:
                return this.renderPagination();
            case ITEM_TYPE.error:
                return this.renderError();
        }
    }

    renderPagination() {
        return (
            <Pagination
                activePage={this.state.activePage}
                totalPages={this.state.totalPages}
                siblingRange={this.props.siblingRange}
                boundaryRange={this.props.boundaryRange}
                onPageChange={this.onPageChange}
                ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
                firstItem={{ content: <Icon name="angle double left" />, icon: true }}
                lastItem={{ content: <Icon name="angle double right" />, icon: true }}
                prevItem={{ content: <Icon name="angle left" />, icon: true }}
                nextItem={{ content: <Icon name="angle right" />, icon: true }}
            />
        );
    }

    renderError() {
        return (
            <p>{this.props.errorStateText}</p>
        );
    }

    render() {
        return (
            <Search
                loading={this.state.loading}
                onSearchChange={this.onSearchChange}
                value={this.state.value}
                results={this.state.items}
                resultRenderer={this.renderItem}
                onResultSelect={this.onResultSelect}
                input={Object.assign({}, this.props.input, { ref: this.inputFocus.ref })}
            />
        );
    }
}

SearchPaginated.propTypes = {
    onResultSelect: PropTypes.func.isRequired,
    resultRenderer: PropTypes.func.isRequired,
    fetch: PropTypes.func.isRequired,
    errorStateText: PropTypes.string.isRequired,
    pageSize: PropTypes.number,
    maxFetchAttempts: PropTypes.number,
    debounceRate: PropTypes.number,
    siblingRange: PropTypes.number,
    boundaryRange: PropTypes.number,
    onPageChange: PropTypes.func,
    input: PropTypes.object,
};

SearchPaginated.defaultProps = {
    pageSize: DEFAULT_PAGE_SIZE,
    debounceRate: DEFAULT_SEARCH_DEBOUNCE_RATE_MS,
    maxFetchAttempts: DEFAULT_MAX_FETCH_ATTEMPTS,
};

// TODO @Kec #pagination #search-paginated Extract to utils
const utilizeFocus = () => {
    const ref = React.createRef();
    const setFocus = () => {
        ref.current && ref.current.focus();
    };

    return { setFocus, ref };
};
