import $ from 'jquery';

import endpoint from '../environments/endpoints';
import { showAlert } from '../utils/ErrorHandler';

export function getImages(imageIds, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'slike/b64?imageIds=' + imageIds.join('&imageIds=')
        })
            .done(data => {
                onSuccess(
                    data.map(i => {
                        let d = 'data:' + i.mime + ';base64,' + i.imgB64;
                        return {
                            id: i.id,
                            original: d,
                            thumbnail: d
                        };
                    })
                );
            })
            .fail(error => {
                showAlert(error);
                onError();
            });
    };
}
