import React from 'react';
import PropTypes from 'prop-types';
import localization from '../../../locales/localization';

const styles = {
    datumImjesto: {
        fontSize: 10,
        margin: 0
    }
};

class DatumIMjestoObicno extends React.Component {
    render() {
        return (
            <div>
                <p style={styles.datumImjesto}>
                    {localization.printingComponents.common.mjesto + ': '}
                    <b>{this.props.mjesto}</b>
                </p>
                <p style={styles.datumImjesto}>
                    {localization.printingComponents.common.datum + ': '}
                    <b>{this.props.datum}</b>
                </p>
            </div>
        );
    }
}

DatumIMjestoObicno.propTypes = {
    mjesto: PropTypes.string.isRequired,
    datum: PropTypes.string.isRequired
};

export default DatumIMjestoObicno;
