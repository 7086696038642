export default {
    countries: [
        { name: 'Afganistan', countryCode: 'af' },
        { name: 'Alandski otoci', countryCode: 'ax' },
        { name: 'Albanija', countryCode: 'al' },
        { name: 'Alžir', countryCode: 'dz' },
        { name: 'Američka Samoa', countryCode: 'as' },
        { name: 'Andora', countryCode: 'ad' },
        { name: 'Angola', countryCode: 'ao' },
        { name: 'Angvila', countryCode: 'ai' },
        { name: 'Antigva', countryCode: 'ag' },
        { name: 'Argentina', countryCode: 'ar' },
        { name: 'Armenija', countryCode: 'am' },
        { name: 'Aruba', countryCode: 'aw' },
        { name: 'Australija', countryCode: 'au' },
        { name: 'Austrija', countryCode: 'at' },
        { name: 'Azerbajdžan', countryCode: 'az' },
        { name: 'Bahami', countryCode: 'bs' },
        { name: 'Bahrein', countryCode: 'bh' },
        { name: 'Bangladeš', countryCode: 'bd' },
        { name: 'Barbados', countryCode: 'bb' },
        { name: 'Belgija', countryCode: 'be' },
        { name: 'Belize', countryCode: 'bz' },
        { name: 'Benin', countryCode: 'bj' },
        { name: 'Bermudi', countryCode: 'bm' },
        { name: 'Bjelorusija', countryCode: 'by' },
        { name: 'Bocvana', countryCode: 'bw' },
        { name: 'Bolivija', countryCode: 'bo' },
        { name: 'Bosna i Hercegovina', countryCode: 'ba' },
        { name: 'Božićni otok', countryCode: 'cx' },
        { name: 'Brazil', countryCode: 'br' },
        { name: 'Britanski Djevičanski otoci', countryCode: 'vg' },
        { name: 'Britanski Indijskooceanski teritorij', countryCode: 'io' },
        { name: 'Brunej', countryCode: 'bn' },
        { name: 'Bugarska', countryCode: 'bg' },
        { name: 'Burkina Faso', countryCode: 'bf' },
        { name: 'Burundi', countryCode: 'bi' },
        { name: 'Butan', countryCode: 'bt' },
        { name: 'Cipar', countryCode: 'cy' },
        { name: 'Cookovo Otočja', countryCode: 'ck' },
        { name: 'Crna Gora', countryCode: 'me' },
        { name: 'Danska', countryCode: 'dk' },
        { name: 'Djevičansko otočje', countryCode: 'vi' },
        { name: 'Dominika', countryCode: 'dm' },
        { name: 'Dominikanska Republika', countryCode: 'do' },
        { name: 'Džibuti', countryCode: 'dj' },
        { name: 'Egipat', countryCode: 'eg' },
        { name: 'Ekvador', countryCode: 'ec' },
        { name: 'Ekvatorska Gvineja', countryCode: 'gq' },
        { name: 'Eritreja', countryCode: 'er' },
        { name: 'Estonija', countryCode: 'ee' },
        { name: 'Etiopija', countryCode: 'et' },
        { name: 'Falklandsko otočje', countryCode: 'fk' },
        { name: 'Farski otoci', countryCode: 'fo' },
        { name: 'Fidži', countryCode: 'fj' },
        { name: 'Filipini', countryCode: 'ph' },
        { name: 'Finska', countryCode: 'fi' },
        { name: 'Francuska', countryCode: 'fr' },
        { name: 'French Gijana', countryCode: 'gf' },
        { name: 'French Polinezija', countryCode: 'pf' },
        { name: 'Gabon', countryCode: 'ga' },
        { name: 'Gambija', countryCode: 'gm' },
        { name: 'Gana', countryCode: 'gh' },
        { name: 'Gibraltar', countryCode: 'gi' },
        { name: 'Grenada', countryCode: 'gd' },
        { name: 'Grenland', countryCode: 'gl' },
        { name: 'Gruzija', countryCode: 'ge' },
        { name: 'Guam', countryCode: 'gu' },
        { name: 'Gvadalupa', countryCode: 'gp' },
        { name: 'Gvajana', countryCode: 'gy' },
        { name: 'Gvatemala', countryCode: 'gt' },
        { name: 'Gvineja', countryCode: 'gn' },
        { name: 'Gvineja Bisau', countryCode: 'gw' },
        { name: 'Haiti', countryCode: 'ht' },
        { name: 'Havajsko otočje', countryCode: 'gs' },
        { name: 'Honduras', countryCode: 'hn' },
        { name: 'Hong Kong', countryCode: 'hk' },
        { name: 'Hrvatska', countryCode: 'hr' },
        { name: 'Indija', countryCode: 'in' },
        { name: 'Indonezija', countryCode: 'id' },
        { name: 'Irak', countryCode: 'iq' },
        { name: 'Iran', countryCode: 'ir' },
        { name: 'Irska', countryCode: 'ie' },
        { name: 'Island', countryCode: 'is' },
        { name: 'Istočni Timor', countryCode: 'tl' },
        { name: 'Italija', countryCode: 'it' },
        { name: 'Izrael', countryCode: 'il' },
        { name: 'Jamajka', countryCode: 'jm' },
        { name: 'Japan', countryCode: 'jp' },
        { name: 'Jemen', countryCode: 'ye' },
        { name: 'Jordan', countryCode: 'jo' },
        { name: 'Južna Koreja', countryCode: 'kr' },
        { name: 'Južnoafrička Republika', countryCode: 'za' },
        { name: 'Kajmansko Otočje', countryCode: 'ky' },
        { name: 'Kambodža', countryCode: 'kh' },
        { name: 'Kamerun', countryCode: 'cm' },
        { name: 'Kanada', countryCode: 'ca' },
        { name: 'Katar', countryCode: 'qa' },
        { name: 'Kazahstan', countryCode: 'kz' },
        { name: 'Kenija', countryCode: 'ke' },
        { name: 'Kina', countryCode: 'cn' },
        { name: 'Kirgistan', countryCode: 'kg' },
        { name: 'Kiribati', countryCode: 'ki' },
        { name: 'Kokosovi otoci', countryCode: 'cc' },
        { name: 'Kolumbija', countryCode: 'co' },
        { name: 'Komori', countryCode: 'km' },
        { name: 'Kongo', countryCode: 'cd' },
        { name: 'Kostarika', countryCode: 'cr' },
        { name: 'Kuba', countryCode: 'cu' },
        { name: 'Kuvajt', countryCode: 'kw' },
        { name: 'Laos', countryCode: 'la' },
        { name: 'Latvija', countryCode: 'lv' },
        { name: 'Lesoto', countryCode: 'ls' },
        { name: 'Libanon', countryCode: 'lb' },
        { name: 'Liberia', countryCode: 'lr' },
        { name: 'Libija', countryCode: 'ly' },
        { name: 'Lihtenštaj', countryCode: 'li' },
        { name: 'Litva', countryCode: 'lt' },
        { name: 'Luksemburg', countryCode: 'lu' },
        { name: 'Madagaskar', countryCode: 'mg' },
        { name: 'Makao', countryCode: 'mo' },
        { name: 'Makedonija', countryCode: 'mk' },
        { name: 'Malavi', countryCode: 'mw' },
        { name: 'Maldivi', countryCode: 'mv' },
        { name: 'Malezija', countryCode: 'my' },
        { name: 'Mali', countryCode: 'ml' },
        { name: 'Malta', countryCode: 'mt' },
        { name: 'Maroko', countryCode: 'ma' },
        { name: 'Martinik', countryCode: 'mq' },
        { name: 'Maršalovi Otoci', countryCode: 'mh' },
        { name: 'Mauretanija', countryCode: 'mr' },
        { name: 'Mauricijus', countryCode: 'mu' },
        { name: 'Mayotte', countryCode: 'yt' },
        { name: 'Mađarska', countryCode: 'hu' },
        { name: 'Meksiko', countryCode: 'mx' },
        { name: 'Mikronezija', countryCode: 'fm' },
        { name: 'Mjanmar', countryCode: 'mm' },
        { name: 'Moldavija', countryCode: 'md' },
        { name: 'Monako', countryCode: 'mc' },
        { name: 'Mongola', countryCode: 'mn' },
        { name: 'Mozambik', countryCode: 'mz' },
        { name: 'Namibija', countryCode: 'na' },
        { name: 'Nauru', countryCode: 'nr' },
        { name: 'Nepal', countryCode: 'np' },
        { name: 'Niger', countryCode: 'ne' },
        { name: 'Nigerija', countryCode: 'ng' },
        { name: 'Nikaragva', countryCode: 'ni' },
        { name: 'Niue', countryCode: 'nu' },
        { name: 'Nizozemska', countryCode: 'nl' },
        { name: 'Nizozemski Antili', countryCode: 'an' },
        { name: 'Grčka', countryCode: 'gr' },
        { name: 'Njemačka', countryCode: 'de' },
        { name: 'Norveška', countryCode: 'no' },
        { name: 'Nova Gvineja', countryCode: 'pg' },
        { name: 'Nova Kaledonija', countryCode: 'nc' },
        { name: 'Novi Zeland', countryCode: 'nz' },
        { name: 'Obala Bjekokosti', countryCode: 'ci' },
        { name: 'Oman', countryCode: 'om' },
        { name: 'Otoci Turks i Caicos', countryCode: 'tc' },
        { name: 'Otok Bouvet', countryCode: 'bv' },
        { name: 'Otok Heard i otočje McDonald', countryCode: 'hm' },
        { name: 'Otok Norfolk', countryCode: 'nf' },
        { name: 'Pakistan', countryCode: 'pk' },
        { name: 'Palau', countryCode: 'pw' },
        { name: 'Palestina', countryCode: 'ps' },
        { name: 'Panama', countryCode: 'pa' },
        { name: 'Paragvaj', countryCode: 'py' },
        { name: 'Peru', countryCode: 'pe' },
        { name: 'Pitcairnovo Otočje', countryCode: 'pn' },
        { name: 'Poljska', countryCode: 'pl' },
        { name: 'Portoriko', countryCode: 'pr' },
        { name: 'Portugal', countryCode: 'pt' },
        { name: 'Republika Kongo', countryCode: 'cg' },
        { name: 'Reunion', countryCode: 're' },
        { name: 'Ruanda', countryCode: 'rw' },
        { name: 'Rumunjska', countryCode: 'ro' },
        { name: 'Rusija', countryCode: 'ru' },
        { name: 'SAD', countryCode: 'us' },
        { name: 'Salvador', countryCode: 'sv' },
        { name: 'Samoa', countryCode: 'ws' },
        { name: 'San Marino', countryCode: 'sm' },
        { name: 'Saudijska Arabija', countryCode: 'sa' },
        { name: 'Sejšeli', countryCode: 'sc' },
        { name: 'Senegal', countryCode: 'sn' },
        { name: 'Sijera Leone', countryCode: 'sl' },
        { name: 'Singapur', countryCode: 'sg' },
        { name: 'Sirija', countryCode: 'sy' },
        { name: 'Sjeverna Koreja', countryCode: 'kp' },
        { name: 'Sjevernomarijanski otoci', countryCode: 'mp' },
        { name: 'Slovačka', countryCode: 'sk' },
        { name: 'Slovenija', countryCode: 'si' },
        { name: 'Solomonski Otoci', countryCode: 'sb' },
        { name: 'Somalija', countryCode: 'so' },
        { name: 'Srbija', countryCode: 'rs' },
        { name: 'Srednjoafrička Republika', countryCode: 'cf' },
        { name: 'Sudan', countryCode: 'sd' },
        { name: 'Surinam', countryCode: 'sr' },
        { name: 'Svazi', countryCode: 'sz' },
        { name: 'Sveta Helena', countryCode: 'sh' },
        { name: 'Sveta Lucija', countryCode: 'lc' },
        { name: 'Sveti Kristofor i Nevis', countryCode: 'kn' },
        { name: 'Sveti Pierre', countryCode: 'pm' },
        { name: 'Sveti Toma i Princip', countryCode: 'st' },
        { name: 'Sveti Vincent', countryCode: 'vc' },
        { name: 'Tadžikistan', countryCode: 'tj' },
        { name: 'Tajland', countryCode: 'th' },
        { name: 'Tajvan', countryCode: 'tw' },
        { name: 'Tanzanija', countryCode: 'tz' },
        { name: 'Togo', countryCode: 'tg' },
        { name: 'Tokelau', countryCode: 'tk' },
        { name: 'Tonga', countryCode: 'to' },
        { name: 'Trinidad i Tobago', countryCode: 'tt' },
        { name: 'Tunis', countryCode: 'tn' },
        { name: 'Turkmenistan', countryCode: 'tm' },
        { name: 'Turska', countryCode: 'tr' },
        { name: 'Tuvalu', countryCode: 'tv' },
        { name: 'Uganda', countryCode: 'ug' },
        { name: 'Ujedinjeni Arapski Emirati', countryCode: 'ae' },
        { name: 'Ujedinjeno Kraljevstvo', countryCode: 'gb' },
        { name: 'Ukrajina', countryCode: 'ua' },
        { name: 'Urugvaj', countryCode: 'uy' },
        { name: 'Uzbekistan', countryCode: 'uz' },
        { name: 'Vanuatu', countryCode: 'vu' },
        { name: 'Vatikan', countryCode: 'va' },
        { name: 'Venezuela', countryCode: 've' },
        { name: 'Vijetnam', countryCode: 'vn' },
        { name: 'Wales', countryCode: 'gb wls' },
        { name: 'Wallis i Futuna', countryCode: 'wf' },
        { name: 'Zambija', countryCode: 'zm' },
        { name: 'Zapadna Sahara', countryCode: 'eh' },
        { name: 'Zelenortska Republika', countryCode: 'cv' },
        { name: 'Zimbabve', countryCode: 'zw' },
        { name: 'Čad', countryCode: 'td' },
        { name: 'Češka Republika', countryCode: 'cz' },
        { name: 'Čile', countryCode: 'cl' },
        { name: 'Škotska', countryCode: 'gb sct' },
        { name: 'Španjolska', countryCode: 'es' },
        { name: 'Šri Lanka', countryCode: 'lk' },
        { name: 'Švedska', countryCode: 'se' },
        { name: 'Švicarska', countryCode: 'ch' }
    ]
};
