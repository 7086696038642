import $ from 'jquery';

import * as success from '../actions/KategorijeActions';

import endpoint from '../environments/endpoints';
import { showAlert } from '../utils/ErrorHandler';

export function getKategorije(onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'kategorije'
        })
            .done(data => {
                dispatch(success.getKategorije(data));
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function createKategorija(kategorija, callSuccess, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'kategorije',
            data: JSON.stringify(kategorija)
        })
            .done(data => {
                if (callSuccess) {
                    onSuccess(data);
                } else {
                    dispatch(getKategorije());
                }
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function updateKategorija(id, kategorija, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'kategorije/' + id,
            data: JSON.stringify(kategorija)
        })
            .done(data => {
                dispatch(getKategorije());
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function deleteKategorija(id, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'kategorije/' + id
        })
            .done(data => {
                dispatch(getKategorije());
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function createPotkategorija(kategorijaid, potkategorija, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'kategorije/' + kategorijaid + '/potkategorije',
            data: JSON.stringify(potkategorija)
        })
            .done(data => {
                dispatch(getKategorije());
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function updatePotkategorija(id, potkategorija, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'potkategorije/' + id,
            data: JSON.stringify(potkategorija)
        })
            .done(data => {
                dispatch(getKategorije());
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function deletePotkategorija(id, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'potkategorije/' + id
        })
            .done(data => {
                dispatch(getKategorije());
            })
            .fail(error => {
                showAlert(error);
            });
    };
}
