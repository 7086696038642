import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import localization from '../../locales/localization';
import { Button, Dimmer, Form, Loader } from 'semantic-ui-react';
import * as TroskoviDataAccess from '../../data_access/TroskoviDataAccess';
import { bindActionCreators } from 'redux';
import InputField from './InputField';
import moment from 'moment';
import enums from '../../utils/Enums';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    form: {
        width: '40%'
    },
    inlineContainer: {
        display: 'inline-flex',
        alignItems: 'center'
    },
    checkbox: {
        marginLeft: 8
    },
    actionButtons: {
        marginTop: 16,
        alignSelf: 'flex-end'
    }
};

const timeSpanType = {
    DAY: 'DAY',
    MONTH: 'MONTH',
    YEAR: 'YEAR'
};

const tipReccuringTroska = {
    RECURRING_PENDING: 'RECURRING_PENDING'
};

const oneTimeTrosak = {
    ime: localization.blagajne.jednokratniTrosak,
    iznos: undefined
};

const days = Array.from(new Array(31), (val, index) => index + 1);
const months = Array.from(new Array(12), (val, index) => index + 1);

let formatOneDigitNumbers = num => {
    if (num < 10) {
        return '0' + num;
    }
    return num;
};

class Troskovi extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            trosakDefinitions: [oneTimeTrosak],
            grupeOneTimeTroskova: [],
            godina: new Date().getFullYear(),
            troskovi: [],
            trosak: 0,
            mjeseci: months,
            dani: days,
            mjesec: months[0],
            dan: days[0],
            poslovnicaId: sessionStorage.userRole !== enums.tipKorisnika.DJELATNIK ? this.props.poslovnice[0].id : JSON.parse(sessionStorage.poslovnicaId),
            ime: '',
            grupaOneTimeTroskovaId: undefined,
            iznos: undefined,
            pozitivniTrosak: false,
            loader: false
        };
        this.onViewModelLoaded = this.onViewModelLoaded.bind(this);
        this.onChangeYear = this.onChangeYear.bind(this);
        this.onChangeTrosak = this.onChangeTrosak.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeMjesec = this.onChangeMjesec.bind(this);
        this.setAvailableMonths = this.setAvailableMonths.bind(this);
        this.setAvailableDays = this.setAvailableDays.bind(this);
        this.enableNapraviTrosakButton = this.enableNapraviTrosakButton.bind(this);
        this.checkRequiredKomponenteTroska = this.checkRequiredKomponenteTroska.bind(this);
        this.onNapraviTrosak = this.onNapraviTrosak.bind(this);
        this.showLoader = this.showLoader.bind(this);

        let startDate = this.state.godina + '-01-01';
        let endDateIncl = moment().format('YYYY-MM-DD');
        this.props.troskoviDataAccess.getTroskoviViewModel(startDate, endDateIncl, this.state.poslovnicaId, this.onViewModelLoaded);
    }

    onViewModelLoaded(vm) {
        this.setState({
            trosakDefinitions: [oneTimeTrosak, ...vm.recTroskoviDefs],
            grupeOneTimeTroskova: vm.grupeOneTimeTroskova,
            troskovi: vm.troskovi,
            trosak: 0,
            ime: ''
        });
    }

    onChangeYear(e, { value }) {
        this.setState({ godina: value }, () => {
            let startDate = this.state.godina + '-01-01';
            let endDateIncl = moment().format('YYYY-MM-DD');
            this.props.troskoviDataAccess.getTroskoviViewModel(startDate, endDateIncl, this.state.poslovnicaId, this.onViewModelLoaded);
        });
    }

    onChangeTrosak(e, { value }) {
        let iznos = undefined;
        if (value !== 0) {
            iznos = this.state.trosakDefinitions[value].defaultIznos;
        }
        this.setState(
            {
                trosak: value,
                iznos: iznos
            },
            this.setAvailableMonths
        );
    }

    onChange(e, { name, value, checked }) {
        if (checked !== undefined) {
            value = checked;
        }
        this.setState({ [name]: value }, this.enableNapraviTrosakButton);
    }

    onChangeMjesec(e, { value }) {
        this.setState({ mjesec: value }, this.setAvailableDays);
    }

    setAvailableMonths() {
        let trosak = this.state.trosakDefinitions[this.state.trosak];
        if (this.state.trosak !== 0 && trosak.timeSpanType !== timeSpanType.YEAR) {
            let availableTroskovi = this.state.troskovi.filter(t => t.recTrosakDefId === trosak.id && t.tip === tipReccuringTroska.RECURRING_PENDING);
            availableTroskovi = _.uniq(
                availableTroskovi.map(t => {
                    return parseInt(t.timeSpan.value.split('-')[1]);
                })
            );
            if (trosak.timeSpanType === timeSpanType.MONTH) {
                this.setState(
                    {
                        mjeseci: availableTroskovi,
                        mjesec: availableTroskovi[0]
                    },
                    this.enableNapraviTrosakButton
                );
            } else {
                this.setState(
                    {
                        mjeseci: availableTroskovi,
                        mjesec: availableTroskovi[0]
                    },
                    this.setAvailableDays
                );
            }
        } else {
            this.enableNapraviTrosakButton();
        }
    }

    setAvailableDays() {
        let trosak = this.state.trosakDefinitions[this.state.trosak];
        if (this.state.trosak !== 0 && trosak.timeSpanType === timeSpanType.DAY) {
            let availableTroskovi = this.state.troskovi.filter(t => {
                return (
                    t.recTrosakDefId === trosak.id &&
                    t.tip === tipReccuringTroska.RECURRING_PENDING &&
                    this.state.mjesec === parseInt(t.timeSpan.value.split('-')[1])
                );
            });
            availableTroskovi = availableTroskovi.map(t => {
                return parseInt(t.timeSpan.value.split('-')[2]);
            });
            this.setState(
                {
                    dani: availableTroskovi,
                    dan: availableTroskovi[0]
                },
                this.enableNapraviTrosakButton
            );
        }
    }

    enableNapraviTrosakButton() {
        this.setState({ enableNapraviTrosakButton: this.checkRequiredKomponenteTroska() });
    }

    checkRequiredKomponenteTroska() {
        if (this.state.trosak === 0) {
            //jednokratni trosak
            if (
                !this.state.ime ||
                this.state.iznos === undefined ||
                this.state.iznos <= 0 ||
                this.state.poslovnicaId === undefined ||
                this.state.grupaOneTimeTroskovaId === undefined
            ) {
                return false;
            }
        } else {
            //recurring troskovi
            if (this.state.iznos === undefined || this.state.iznos <= 0 || this.state.dan === undefined || this.state.mjesec === undefined) {
                return false;
            }
        }
        return true;
    }

    onNapraviTrosak() {
        let trosak = {};
        let iznos = parseFloat(this.state.iznos);
        if (!this.props.bankovni) {
            trosak.poslovnicaId = this.state.poslovnicaId;
        }
        if (this.state.trosak === 0) {
            //jednokratni trosak
            trosak.ime = this.state.ime;
            trosak.iznos = this.state.pozitivniTrosak ? -iznos : iznos;
            trosak.grupaOneTimeTroskovaId = this.state.grupaOneTimeTroskovaId;
            trosak.datumIVrijeme = moment().format('YYYY-MM-DDTHH:mm:ss');

            this.props.troskoviDataAccess.payOneTimeTrosak(trosak, this.hideLoader.bind(this, this.props.closeModal));
        } else {
            //recurring troskovi
            let trosakDef = this.state.trosakDefinitions[this.state.trosak];
            let timeSpanValue = this.state.godina.toString();
            if (trosakDef.timeSpanType === timeSpanType.MONTH) {
                timeSpanValue += '-' + formatOneDigitNumbers(this.state.mjesec);
            }
            if (trosakDef.timeSpanType === timeSpanType.DAY) {
                timeSpanValue += '-' + formatOneDigitNumbers(this.state.mjesec) + '-' + formatOneDigitNumbers(this.state.dan);
            }
            trosak.recTrosakDefId = trosakDef.id;
            trosak.iznos = this.state.pozitivniTrosak ? -iznos : iznos;
            trosak.timeSpan = {
                type: trosakDef.timeSpanType,
                value: timeSpanValue
            };

            this.props.troskoviDataAccess.payPendingReccuringTrosak(trosak, this.hideLoader.bind(this, this.props.closeModal));
        }
    }

    showLoader(callback) {
        this.setState(
            {
                loader: true
            },
            callback
        );
    }

    hideLoader(callback) {
        this.setState(
            {
                loader: false
            },
            callback
        );
    }

    render() {
        return (
            <div style={styles.container}>
                <Form style={styles.form}>
                    {sessionStorage.userRole !== enums.tipKorisnika.DJELATNIK && !this.props.bankovni ? (
                        <Form.Select
                            label={localization.blagajne.poslovnica}
                            options={this.props.poslovnice.map(p => {
                                return { key: p.id, text: p.naziv, value: p.id };
                            })}
                            name="poslovnicaId"
                            onChange={this.onChange}
                            value={this.state.poslovnicaId}
                            compact
                            required
                            search
                        />
                    ) : null}
                    <Form.Select
                        label={localization.blagajne.godina}
                        options={Array.from(new Array(83), (val, index) => {
                            return { key: index, text: index + 2018, value: index + 2018 };
                        })}
                        onChange={this.onChangeYear}
                        value={this.state.godina}
                        compact
                        required
                        search
                    />
                    <Form.Select
                        label={localization.blagajne.trosak}
                        options={this.state.trosakDefinitions.map((t, index) => {
                            return { key: index, text: t.ime, value: index };
                        })}
                        onChange={this.onChangeTrosak}
                        value={this.state.trosak}
                        required
                        search
                    />
                    {this.state.trosak !== 0 && this.state.trosakDefinitions[this.state.trosak].timeSpanType !== timeSpanType.YEAR ? (
                        <Form.Group widths="equal">
                            <Form.Select
                                label={localization.blagajne.mjesec}
                                options={this.state.mjeseci.map(val => {
                                    return { key: val, text: val, value: val };
                                })}
                                name="mjesec"
                                onChange={this.onChangeMjesec}
                                value={this.state.mjesec}
                                compact
                                required
                                search
                            />

                            {this.state.trosakDefinitions[this.state.trosak].timeSpanType === timeSpanType.DAY ? (
                                <Form.Select
                                    label={localization.blagajne.dan}
                                    options={this.state.dani.map(val => {
                                        return { key: val, text: val, value: val };
                                    })}
                                    name="dan"
                                    onChange={this.onChange}
                                    value={this.state.dan}
                                    compact
                                    required
                                    search
                                />
                            ) : null}
                        </Form.Group>
                    ) : null}
                    {this.state.trosak === 0 ? (
                        <React.Fragment>
                            <Form.Select
                                label={localization.blagajne.grupeOneTimeTroskova}
                                options={this.state.grupeOneTimeTroskova.map((grupa, index) => {
                                    return { key: index, text: grupa.ime, value: grupa.id };
                                })}
                                name="grupaOneTimeTroskovaId"
                                onChange={this.onChange}
                                value={this.state.grupaOneTimeTroskovaId}
                                compact
                                required
                                search
                            />
                            <Form.Input fluid label={localization.blagajne.nazivTroska} name="ime" onChange={this.onChange} value={this.state.ime} required />
                        </React.Fragment>
                    ) : null}
                    <Form.Field required>
                        <label>{localization.blagajne.iznos}</label>
                        <div style={styles.inlineContainer}>
                            <InputField
                                label={localization.common.eur}
                                placeholder={localization.blagajne.iznos}
                                name="iznos"
                                value={this.state.iznos}
                                onChange={this.onChange}
                                isCjelobrojno={false}
                            />
                            <Form.Checkbox
                                label={localization.blagajne.pozitivniTrosak}
                                style={styles.checkbox}
                                name="pozitivniTrosak"
                                checked={this.state.pozitivniTrosak}
                                onChange={this.onChange}
                            />
                        </div>
                    </Form.Field>
                </Form>
                <div style={styles.actionButtons}>
                    <Button icon="close" content={localization.common.odustani} onClick={this.props.closeModal} />
                    <Button
                        icon="money"
                        color="green"
                        disabled={!this.state.enableNapraviTrosakButton}
                        content={localization.blagajne.unesiTrosak}
                        onClick={this.showLoader.bind(this, this.onNapraviTrosak)}
                    />
                </div>
                <Dimmer active={this.state.loader} page>
                    <Loader />
                </Dimmer>
            </div>
        );
    }
}

Troskovi.propTypes = {
    bankovni: PropTypes.bool.isRequired,
    poslovnice: PropTypes.array.isRequired,
    troskoviDataAccess: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {
        poslovnice: state.mjestaReducer.poslovnice || []
    };
};

let mapDispatchToProps = function(dispatch) {
    return {
        troskoviDataAccess: bindActionCreators(TroskoviDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Troskovi);
