let initialState = {
    stanjeSkladista: {},
    cachedStanjeSkladista: false,
    listaZaMaknutiSOglasavanja: [],
    cachedLista: false
};

export default function skladisteReducer(state = initialState, action) {
    switch (action.type) {
        case 'GET_STANJE_SKLADISTA': {
            return Object.assign({}, state, {
                stanjeSkladista: action.stanjeSkladista,
                cachedStanjeSkladista: true
            });
        }
        case 'GET_LISTA_ZA_MAKNUTI_S_OGLASAVANJA': {
            return Object.assign({}, state, {
                listaZaMaknutiSOglasavanja: [...action.lista],
                cachedLista: true
            });
        }
        case 'RESET_STANJE_CACHE': {
            return Object.assign({}, state, {
                cachedStanjeSkladista: false
            });
        }
        case 'RESET_LISTA_ZA_MAKNUTI_S_OGLASAVANJA_CACHE': {
            return Object.assign({}, state, {
                cachedLista: false
            });
        }
        default:
            return state;
    }
}
