import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Form, Header, Modal, Segment } from 'semantic-ui-react';

import InputField from './InputField';
import enums from '../../utils/Enums';
import localization from '../../locales/localization';
import * as PorezniZakoniDataAccess from '../../data_access/PorezniZakoniDataAccess';

const styles = {
    deleteButton: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    container: {
        textAlign: 'left'
    },
    actionButtons: {
        marginTop: 16,
        display: 'flex',
        justifyContent: 'flex-end'
    }
};

const tipPoreza = [
    {
        tip: enums.tipPoreza.NOVI_PREDMET,
        naziv: localization.postavke.noviPredmetPorez
    },
    {
        tip: enums.tipPoreza.RABLJENI_PREDMET,
        naziv: localization.postavke.rabljeniPredmetPorez
    },
    {
        tip: enums.tipPoreza.INVESTICIJSKO_ZLATO,
        naziv: localization.postavke.investicijskoZlatoPorez
    },
    {
        tip: enums.tipPoreza.USLUGA,
        naziv: localization.postavke.uslugaPorez
    }
];

class PorezniZakon extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            postotak: this.props.porezniZakon.postotak,
            tekst: this.props.porezniZakon.tekst,
            tipPoreza: this.props.porezniZakon.tipPoreza,
            enableSaveButton: false
        };
        this.onChange = this.onChange.bind(this);
        this.enableSaveButton = this.enableSaveButton.bind(this);
        this.checkPorezniZakonRequiredFields = this.checkPorezniZakonRequiredFields.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            postotak: nextProps.porezniZakon.postotak,
            tekst: nextProps.porezniZakon.tekst,
            tipPoreza: nextProps.porezniZakon.tipPoreza,
            enableSaveButton: false
        });
    }

    onChange(e, { name, value }) {
        this.setState({ [name]: value }, this.enableSaveButton);
    }

    enableSaveButton() {
        this.setState({ enableSaveButton: this.checkPorezniZakonRequiredFields() });
    }

    checkPorezniZakonRequiredFields() {
        if (this.state.postotak === undefined || !this.state.tipPoreza) {
            return false;
        }
        return true;
    }

    onCancel() {
        this.setState({
            postotak: this.props.porezniZakon.postotak,
            tekst: this.props.porezniZakon.tekst,
            tipPoreza: this.props.porezniZakon.tipPoreza,
            enableSaveButton: false
        });
    }

    onSave() {
        let porezniZakon = {
            id: this.props.porezniZakon.id,
            postotak: parseFloat(this.state.postotak),
            tekst: this.state.tekst.trim() || null,
            tipPoreza: this.state.tipPoreza
        };
        this.props.porezniZakonDataAccess.createOrUpdatePorezniZakoni([porezniZakon]);
    }

    render() {
        return (
            <Segment>
                <Form style={styles.container}>
                    <Form.Select
                        label={localization.postavke.tipPoreza}
                        options={tipPoreza.map((p, index) => {
                            return { key: index, text: p.naziv, value: p.tip };
                        })}
                        name="tipPoreza"
                        onChange={this.onChange}
                        value={this.state.tipPoreza}
                        required
                        search
                    />
                    <Form.Field required>
                        <label>{localization.postavke.postotakPoreza}</label>
                        <InputField
                            label={localization.common.percentageSign}
                            placeholder={localization.common.percentageSign}
                            name="postotak"
                            value={this.state.postotak}
                            onChange={this.onChange}
                        />
                    </Form.Field>
                    <Form.Input fluid label={localization.postavke.tekstPoreza} name="tekst" onChange={this.onChange} value={this.state.tekst} required />
                </Form>
                <div style={styles.actionButtons}>
                    <Button icon="cancel" content={localization.common.vratiNaSpremljeno} onClick={this.onCancel} />
                    <Button icon="save" color="green" disabled={!this.state.enableSaveButton} content={localization.common.spremi} onClick={this.onSave} />
                </div>
            </Segment>
        );
    }
}

PorezniZakon.propTypes = {
    porezniZakon: PropTypes.object.isRequired,
    porezniZakonDataAccess: PropTypes.object.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {};
};

let mapDispatchToProps = function(dispatch) {
    return {
        porezniZakonDataAccess: bindActionCreators(PorezniZakoniDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PorezniZakon);
