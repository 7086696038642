import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Button, Dimmer, Grid, Header, Icon, Loader, Modal, Pagination, Search, Segment, Table } from 'semantic-ui-react';

import localization from '../../locales/localization';
import icons from '../../utils/DefinedIcons';
import Artikl from '../common_components/Artikl';
import SearchResultsMessage from '../common_components/SearchResultsMessage';
import * as ArtikliDataAccess from '../../data_access/ArtikliDataAccess';
import * as ArtikliApi from '../../data_access/ArtikliApi';
import { bindActionCreators } from 'redux';
import enums from '../../utils/Enums';
import FilterSortButton from '../common_components/FilterSortButton';

// TODO #pagination #refactor Extract this to some other place when pagination for other components is implemented
//      and we solve the Redux/DataAccess dilemma.
const MAX_API_FETCH_ATTEMPTS = 3;
// TODO #pagination #refactor Probably extract somewhere else and use across all search components
const SEARCH_DEBOUNCE_RATE_MS = 1000;

const styles = {
    paginationContainer: {
        textAlign: 'center'
    }
};

const SORT = [
    {
        name: localization.artikl.sifra,
        apiParam: ArtikliApi.SORT.sifra
    },
    {
        name: localization.artikl.naziv,
        apiParam: ArtikliApi.SORT.naziv
    }
];

const LIST_STATE = {
    initial: 'initial',
    empty: 'empty',
    noResult: 'noResult',
    populated: 'populated',
    error: 'error'
};

const ITEMS_PER_PAGE = 20;

class Artikli extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            newItem: false,
            editItem: undefined,
            loader: false,
            sort: 1,
            pageActive: 1
        };
        this.showNewArtikl = this.showNewArtikl.bind(this);
        this.hideNewArtikl = this.hideNewArtikl.bind(this);
        this.showEditArtikl = this.showEditArtikl.bind(this);
        this.hideEditArtikl = this.hideEditArtikl.bind(this);
        this.showLoader = this.showLoader.bind(this);
        this.hideLoader = this.hideLoader.bind(this);
        this.onNoviArtikl = this.onNoviArtikl.bind(this);
        this.onEditAsNoviArtikl = this.onEditAsNoviArtikl.bind(this);
        this.onEditArtiklSave = this.onEditArtiklSave.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
        this.applyFilterAndSort = this.applyFilterAndSort.bind(this);
        this.resetSearchComponent = this.resetSearchComponent.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.fetchArtikli = this.fetchArtikli.bind(this);
        this.refreshArtikli = this.refreshArtikli.bind(this);
        this.renderInitialComponent = this.renderInitialComponent.bind(this);
        this.renderEmptyComponent = this.renderEmptyComponent.bind(this);
        this.renderNoResultComponent = this.renderNoResultComponent.bind(this);
        this.renderErrorComponent = this.renderErrorComponent.bind(this);
        this.renderListComponent = this.renderListComponent.bind(this);
        this.debouncedFetchArtikli = _.debounce(function (sort, page) {
            this.fetchArtikli(sort, page);
        }, SEARCH_DEBOUNCE_RATE_MS).bind(this);
    }

    componentWillMount() {
        this.resetSearchComponent();
    }

    showNewArtikl() {
        this.setState({
            newItem: true
        });
    }

    hideNewArtikl(callback) {
        this.setState({
            newItem: false
        }, callback);
    }

    showEditArtikl(sifraArtikla) {
        this.setState({
            editItem: sifraArtikla
        });
    }

    hideEditArtikl(callback) {
        this.setState({
            editItem: undefined
        }, callback);
    }

    showLoader() {
        this.setState({
            loader: true
        });
    }

    hideLoader(callback) {
        this.setState({
            loader: false
        }, callback);
    }

    onNoviArtikl(predmet) {
        this.showLoader();
        this.props.artikliDataAccess.createPredmet(
            predmet,
            () => this.hideLoader(() => this.hideNewArtikl(() => this.refreshArtikli())),
            () => this.hideLoader()
        );
    }

    onEditAsNoviArtikl(predmet) {
        this.showLoader();
        this.props.artikliDataAccess.createPredmet(
            predmet, 
            () => this.hideLoader(() => this.hideEditArtikl(() => this.refreshArtikli())),
            () => this.hideLoader()
        );
    }

    onEditArtiklSave(id, predmet) {
        this.showLoader();
        this.props.artikliDataAccess.updatePredmet(
            id, predmet,
            () => this.hideLoader(() => this.hideEditArtikl(() => this.refreshArtikli())),
            () => this.hideLoader()
        );
    }

    onChangePage(_e, { activePage }) {
        this.fetchArtikli(this.state.sort, activePage);
    }

    applyFilterAndSort(_filter, sort) {
        this.fetchArtikli(sort);
    }

    resetSearchComponent() {
        this.setState({
            listState: LIST_STATE.initial,
            fetchAttempts: 0
        }, () => {
            this.fetchArtikli();
        });
    }

    handleSearchChange(_e, { value }) {
        this.setState({
            value: value
        }, () => {
            this.debouncedFetchArtikli();
        });
    }

    fetchArtikli(sort = this.state.sort, page = 1) {

        let nextStateCommon = {
            loader: false,
            fetchAttempts: 0,
            sort: sort
        };

        let onFetchSuccess = (data) => {
            if (data.items.length !== 0) {
                let nextStateNonEmpty = {
                    listState: LIST_STATE.populated,
                    artikli: data.items,
                    pages: data.totalPages,
                    pageActive: page
                };
                this.setState(Object.assign({}, nextStateCommon, nextStateNonEmpty));

            } else {

                let fetchedAll = this.state.value === '';

                let newListState = fetchedAll ? LIST_STATE.empty : LIST_STATE.noResult;
                let nextStateEmpty = {
                    listState: newListState,
                    artikli: []
                };

                this.setState(Object.assign({}, nextStateCommon, nextStateEmpty));
            }
        };

        let onFetchError = () => {
            let fetchAttempts = this.state.fetchAttempts + 1;
            if (fetchAttempts === MAX_API_FETCH_ATTEMPTS) {
                let nextStateError = {
                    listState: LIST_STATE.error
                };
                this.setState(Object.assign({}, nextStateCommon, nextStateError));
            } else {
                this.setState({
                    fetchAttempts: fetchAttempts
                }, () => {
                    this.fetchArtikli(sort, page);
                });
            }

        };

        this.showLoader();

        ArtikliApi.getArtikliPaginated(
            this.state.value, page, ITEMS_PER_PAGE, SORT[sort].apiParam, ArtikliApi.TIP_ARTIKLA.predmet,
            onFetchSuccess, onFetchError
        );
    }

    refreshArtikli() {
        this.fetchArtikli(this.state.sort, this.state.page);
    }

    renderInitialComponent() {
        return (
            <SearchResultsMessage
                icon={icons.artikli}
                header={localization.common.ucitavanje}
                content={localization.common.molimoPricekajte}
            />
        );
    }

    renderEmptyComponent() {
        return (
            <SearchResultsMessage
                icon={icons.artikli}
                header={localization.artikli.nemaArtikala}
                content={undefined}
            />
        );
    }

    renderNoResultComponent() {
        return (
            <SearchResultsMessage
                icon={icons.artikli}
                header={localization.common.unesenaPretragaNemaRezultata}
                content={localization.common.pokusajDrugacijuPretragu}
            />
        );
    }

    renderErrorComponent() {
        return (
            <SearchResultsMessage
                icon={icons.artikli}
                header={localization.common.dogodilaSeGreska}
                content={localization.common.pokusajtePonovno}
            />
        );
    }

    renderListComponent() {
        return (
            <React.Fragment>
                <Grid.Row>
                    <Grid.Column verticalAlign="middle">
                        <Table stackable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>{localization.artikl.sifra}</Table.HeaderCell>
                                    <Table.HeaderCell>{localization.artikl.naziv}</Table.HeaderCell>
                                    <Table.HeaderCell>{localization.artikl.kategorija}</Table.HeaderCell>
                                    <Table.HeaderCell>{localization.artikl.serijskiBroj}</Table.HeaderCell>
                                    <Table.HeaderCell>{localization.artikl.opis}</Table.HeaderCell>
                                    {sessionStorage.userRole !== enums.tipKorisnika.DJELATNIK ? <Table.HeaderCell /> : null}
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.state.artikli.map(artikl => {
                                    return (
                                        <Table.Row key={artikl.id}>
                                            <Table.Cell>{artikl.sifra}</Table.Cell>
                                            <Table.Cell>{artikl.naziv}</Table.Cell>
                                            <Table.Cell>
                                                {artikl.kategorija.ime + (artikl.potkategorija ? ' / ' + artikl.potkategorija.ime : '')}
                                            </Table.Cell>
                                            <Table.Cell>{artikl.serijskiBroj || '-'}</Table.Cell>
                                            <Table.Cell>{artikl.opis || '-'}</Table.Cell>
                                            {sessionStorage.userRole !== enums.tipKorisnika.DJELATNIK ? (
                                                <Table.Cell collapsing>
                                                    <Button.Group basic size="small">
                                                        <Modal
                                                            open={this.state.editItem === artikl.sifra}
                                                            closeOnDimmerClick={false}
                                                            trigger={
                                                                <Button
                                                                    icon="write"
                                                                    onClick={this.showEditArtikl.bind(this, artikl.sifra)}
                                                                />
                                                            }
                                                        >
                                                            <Modal.Header>{localization.common.urediArtikl}</Modal.Header>
                                                            <Modal.Content>
                                                                <Artikl
                                                                    item={artikl}
                                                                    onSave={this.onEditArtiklSave.bind(this, artikl.id)}
                                                                    saveAsNewItem={this.onEditAsNoviArtikl.bind(this)}
                                                                    onCancel={() => this.hideEditArtikl()}
                                                                />
                                                            </Modal.Content>
                                                        </Modal>
                                                    </Button.Group>
                                                </Table.Cell>
                                            ) : null}
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </Table>
                        <div style={styles.paginationContainer}>
                            <Pagination
                                activePage={this.state.pageActive}
                                totalPages={this.state.pages}
                                onPageChange={this.onChangePage}
                                ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
                                firstItem={{ content: <Icon name="angle double left" />, icon: true }}
                                lastItem={{ content: <Icon name="angle double right" />, icon: true }}
                                prevItem={{ content: <Icon name="angle left" />, icon: true }}
                                nextItem={{ content: <Icon name="angle right" />, icon: true }}
                            />
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </React.Fragment>
        );
    }

    render() {
        return (
            <div>
                <Segment tertiary>
                    <Grid stackable>
                        <Grid.Row columns={6}>
                            <Grid.Column verticalAlign="middle">
                                <Header as="h2">{localization.artikli.artikli}</Header>
                            </Grid.Column>
                            <Grid.Column verticalAlign="middle">
                                <Search
                                    input={{ fluid: true }}
                                    loading={this.state.isSearchLoading}
                                    onSearchChange={this.handleSearchChange}
                                    value={this.state.value}
                                    open={false}
                                />
                            </Grid.Column>
                            <Grid.Column />
                            <Grid.Column />
                            <Grid.Column textAlign="right" verticalAlign="middle">
                                <FilterSortButton
                                    buttonName={localization.common.sortiranje}
                                    sortingOptions={SORT.map((s) => s.name)}
                                    activeSort={this.state.sort}
                                    callback={this.applyFilterAndSort}
                                />
                            </Grid.Column>
                            <Grid.Column textAlign="right" verticalAlign="middle">
                                <Modal
                                    open={this.state.newItem}
                                    closeOnDimmerClick={false}
                                    trigger={
                                        <Button
                                            icon="asterisk"
                                            basic
                                            color="black"
                                            content={localization.artikli.noviArtikl}
                                            onClick={() => this.showNewArtikl()}
                                        />
                                    }
                                >
                                    <Modal.Header>{localization.artikli.noviArtikl}</Modal.Header>
                                    <Modal.Content>
                                        <Artikl
                                            item={{}}
                                            onSave={this.onNoviArtikl.bind(this)}
                                            onCancel={() => this.hideNewArtikl()}
                                        />
                                    </Modal.Content>
                                </Modal>
                            </Grid.Column>
                        </Grid.Row>
                        {(() => {
                            switch (this.state.listState) {
                                case LIST_STATE.initial:
                                    return this.renderInitialComponent();
                                case LIST_STATE.populated:
                                    return this.renderListComponent();
                                case LIST_STATE.empty:
                                    return this.renderEmptyComponent();
                                case LIST_STATE.noResult:
                                    return this.renderNoResultComponent();
                                case LIST_STATE.error:
                                    return this.renderErrorComponent();
                            }
                        })()}
                    </Grid>
                </Segment>
                <Dimmer active={this.state.loader} page>
                    <Loader />
                </Dimmer>
            </div>
        );
    }
}

Artikli.propTypes = {
    artikliDataAccess: PropTypes.object.isRequired
};

let mapStateToProps = function(_state, _ownProps) {
    return {
    };
};

let mapDispatchToProps = function(dispatch) {
    return {
        artikliDataAccess: bindActionCreators(ArtikliDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Artikli);
