import $ from 'jquery';

import * as success from '../actions/KontrolaActions';

import endpoint from '../environments/endpoints';

export function getUnosiKontrole(poslovnicaId, startDate, endDateIncl, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'radnje/daily-kontrola-summaries?mjestoId=' + poslovnicaId + '&startDate=' + startDate + '&endDateIncl=' + endDateIncl
        })
            .done(data => {
                dispatch(success.getKontrola(data));
                if (onSuccess) {
                    onSuccess();
                }
            })
            .fail(error => {});
    };
}

export function setUnosKontrole(radnjaId, unos, poslovnicaId, startDate, endDate, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'radnje/' + radnjaId + '/kontrola',
            data: JSON.stringify(unos)
        })
            .done(data => {
                dispatch(getUnosiKontrole(poslovnicaId, startDate, endDate));
                onSuccess();
            })
            .fail(error => {});
    };
}

export function addDnevnaNapomena(poslovnicaId, napomena, startDate, endDate, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'radnje/' + poslovnicaId + '/kontrola-napomena',
            data: JSON.stringify(napomena)
        })
            .done(data => {
                dispatch(getUnosiKontrole(poslovnicaId, startDate, endDate));
                onSuccess();
            })
            .fail(error => {});
    };
}
