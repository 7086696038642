let initialState = {
    izdatnice: [],
    cached: false,
};

export default function izdatniceReducer(state = initialState, action) {
    switch (action.type) {
        case 'GET_IZDATNICE': {
            return Object.assign({}, state, {
                izdatnice: [...action.izdatnice],
                cached: true
            });
        }
        default:
            return state;
    }
}
