import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Accordion, Button, Dimmer, Form, Grid, Header, Icon, Loader, Message, Modal, Pagination, Search, Segment, Table } from 'semantic-ui-react';

import localization from '../../locales/localization';
import endpoints from '../../environments/endpoints';
import icons from '../../utils/DefinedIcons';
import { storeDocumentAndGenerateKey } from '../../utils/PrintingDocumentsHandler';
import moment from 'moment';
import * as OtkupiDataAccess from '../../data_access/OtkupiDataAccess';
import * as OtkupiApi from '../../data_access/OtkupiApi';
import { bindActionCreators } from 'redux';
import enums from '../../utils/Enums';
import SearchResultsMessage from '../common_components/SearchResultsMessage';
import Diskusija from '../common_components/Diskusija';
import OtkupProdajaContent from './content_components/OtkupProdajaContent';
import FormatIznosa from '../common_components/FormatIznosa';
import FilterSortButton from '../common_components/FilterSortButton';

// TODO #pagination #refactor Extract this to some other place when pagination for other components is implemented
//      and we solve the Redux/DataAccess dilemma.
const MAX_API_FETCH_ATTEMPTS = 3;
// TODO #pagination #refactor Probably extract somewhere else and use across all search components
const SEARCH_DEBOUNCE_RATE_MS = 1000;

const styles = {
    headerTable: {
        padding: '.75em 1em'
    },
    iconCell: {
        width: 50
    },
    brojPrimkeCell: {
        width: 180
    },
    klijentCell: {
        width: 500
    },
    ukupnaCijenaCell: {
        width: 300
    },
    djelatnikCell: {
        width: 250
    },
    accordionHeaderContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    accordionHeaderText: {
        paddingLeft: 4,
        paddingRight: 2,
        paddingBottom: '0.5em'
    },
    actionButtons: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    semaforStyle: {
        marginLeft: 4
    }
};

const LIST_STATE = {
    initial: 'initial',
    empty: 'empty',
    noResult: 'noResult',
    populated: 'populated',
    error: 'error'
};

const ITEMS_PER_PAGE = 10;

class Primke extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            activePrimka: [],
            activeComments: [],
            noveNapomene: [],
            sluzbenaNapomena: '',
            nesluzbenaNapomena: '',
            activePotvrda: undefined,
            loader: false,
            filter: 0,
            pageActive: 1
        };
        this.onNovaPrimka = this.onNovaPrimka.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
        this.applyFilterAndSort = this.applyFilterAndSort.bind(this);
        this.resetSearchComponent = this.resetSearchComponent.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.onChangeNapomene = this.onChangeNapomene.bind(this);
        this.calculateUkupno = this.calculateUkupno.bind(this);
        this.onChangeNovaNapomena = this.onChangeNovaNapomena.bind(this);
        this.expandPrimka = this.expandPrimka.bind(this);
        this.expandComments = this.expandComments.bind(this);
        this.ispisPrimke = this.ispisPrimke.bind(this);
        this.stornoPrimke = this.stornoPrimke.bind(this);
        this.dodajNapomenu = this.dodajNapomenu.bind(this);
        this.resetNapomena = this.resetNapomena.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.showLoader = this.showLoader.bind(this);
        this.hideLoader = this.hideLoader.bind(this);
        this.fetchPrimke = this.fetchPrimke.bind(this);
        this.refreshPrimke = this.refreshPrimke.bind(this);
        this.getPoslovnicaIdForFilter = this.getPoslovnicaIdForFilter.bind(this);
        this.renderInitialComponent = this.renderInitialComponent.bind(this);
        this.renderEmptyComponent = this.renderEmptyComponent.bind(this);
        this.renderNoResultComponent = this.renderNoResultComponent.bind(this);
        this.renderErrorComponent = this.renderErrorComponent.bind(this);
        this.renderListComponent = this.renderListComponent.bind(this);
        this.debouncedFetchPrimke = _.debounce(function (filter, page) {
            this.fetchPrimke(filter, page);
        }, SEARCH_DEBOUNCE_RATE_MS).bind(this);

        if (sessionStorage.userRole !== enums.tipKorisnika.DJELATNIK) {
            this.FILTER_ITEMS = [{
                name: localization.common.svePoslovnice,
                apiParam: null
            }];
            this.props.poslovnice.forEach(p => this.FILTER_ITEMS.push({
                name: p.naziv,
                apiParam: p.id
            }));
        }
    }

    componentWillMount() {
        this.resetSearchComponent();
    }

    onNovaPrimka() {
        this.props.history.push(endpoints.appEndpoints.primka);
    }

    onChangePage(e, { activePage }) {
        this.fetchPrimke(this.state.filter, activePage);
    }

    applyFilterAndSort(filter, sort) {
        this.fetchPrimke(filter);
    }

    resetSearchComponent() {
        this.setState({
            listState: LIST_STATE.initial,
            fetchAttempts: 0
        }, () => {
            this.fetchPrimke();
        });
    }

    handleSearchChange(e, { value }) {
        this.setState({
            value: value
        }, () => {
            this.debouncedFetchPrimke();
        });
    }

    onChangeNapomene(e, { name, value }) {
        this.setState({ [name]: value });
    }

    calculateUkupno(primka) {
        let ukupno = 0;
        primka.skladisniUlazi.forEach(su => {
            if (primka.rbrStorniranogSUBa === null) {
                ukupno += su.kolicina * su.jedNabavnaCijena;
            } else {
                ukupno += -su.kolicina * su.jedNabavnaCijena;
            }
        });
        return ukupno;
    }

    onChangeNovaNapomena(id, e, { value }) {
        let noveNapomene = [];
        let index = this.state.noveNapomene.findIndex(el => el.id === id);
        if (index > -1) {
            noveNapomene = [...this.state.noveNapomene];
            noveNapomene[index] = {
                id: id,
                napomena: value
            };
        } else {
            noveNapomene = [
                ...this.state.noveNapomene,
                {
                    id: id,
                    napomena: value
                }
            ];
        }
        this.setState({
            noveNapomene: noveNapomene
        });
    }

    expandPrimka(e, { index }) {
        let activePrimka = [];
        if (this.state.activePrimka.includes(index)) {
            activePrimka = _.xor(this.state.activePrimka, [index]);
        } else {
            activePrimka = [...this.state.activePrimka, index];
        }
        this.setState({
            activePrimka: activePrimka
        });
    }

    expandComments(e, { index }) {
        let activeComments = [];
        if (this.state.activeComments.includes(index)) {
            activeComments = _.xor(this.state.activeComments, [index]);
        } else {
            activeComments = [...this.state.activeComments, index];
        }
        this.setState({
            activeComments: activeComments
        });
    }

    ispisPrimke(primka) {
        window.open(endpoints.appEndpoints.print.primka + storeDocumentAndGenerateKey(primka), '_blank');
    }

    stornoPrimke(primkaId) {
        this.showLoader();
        this.props.otkupiDataAccess.stornoPrimke(
            primkaId,
            {
                sluzbenaNapomena: this.state.sluzbenaNapomena,
                nesluzbenaNapomena: this.state.nesluzbenaNapomena || null
            },
            () => this.hideLoader(() => this.hideModal(() => this.refreshPrimke())),
            () => this.hideLoader()
        );
    }

    dodajNapomenu(primkaId) {
        this.showLoader();
        this.props.otkupiDataAccess.dodavanjeNesluzbeneNapomeneNaPrimku(
            primkaId,
            this.state.noveNapomene.find(el => el.id === primkaId).napomena,
            () => this.hideLoader(() => this.resetNapomena(primkaId, () => this.refreshPrimke())),
            () => this.hideLoader()
        );
    }

    resetNapomena(id, callback) {
        this.setState({
            noveNapomene: _.reject(this.state.noveNapomene, (napomena) => napomena.id === id)
        }, callback);
    }

    showModal(id) {
        this.setState({ activePotvrda: id });
    }

    hideModal(callback) {
        this.setState({ activePotvrda: false }, callback);
    }

    showLoader() {
        this.setState(
            {
                loader: true
            }
        );
    }

    hideLoader(callback) {
        this.setState(
            {
                loader: false
            },
            callback
        );
    }

    fetchPrimke(filter = this.state.filter, page = 1) {

        let nextStateCommon = {
            loader: false,
            fetchAttempts: 0,
            filter: filter
        };

        let onFetchSuccess = (data) => {
            if (data.items.length !== 0) {
                let nextStateNonEmpty = {
                    listState: LIST_STATE.populated,
                    primke: data.items,
                    pages: data.totalPages,
                    pageActive: page
                };
                this.setState(Object.assign({}, nextStateCommon, nextStateNonEmpty));

            } else {

                let fetchedAll = this.state.value === '';

                let newListState = fetchedAll ? LIST_STATE.empty : LIST_STATE.noResult;
                let nextStateEmpty = {
                    listState: newListState,
                    otkupi: []
                };

                this.setState(Object.assign({}, nextStateCommon, nextStateEmpty));
            }
        };

        let onFetchError = () => {
            let fetchAttempts = this.state.fetchAttempts + 1;
            if (fetchAttempts === MAX_API_FETCH_ATTEMPTS) {
                let nextStateError = {
                    listState: LIST_STATE.error
                };
                this.setState(Object.assign({}, nextStateCommon, nextStateError));
            } else {
                this.setState({
                    fetchAttempts: fetchAttempts
                }, () => {
                    this.fetchPrimke(filter, page);
                });
            }

        };

        this.showLoader();

        OtkupiApi.getOtkupiPaginated(
            this.state.value, page, ITEMS_PER_PAGE, this.getPoslovnicaIdForFilter(filter),
            OtkupiApi.TIP_SKL_UL_BLOKA.primka, onFetchSuccess, onFetchError
        );
    }

    refreshPrimke() {
        this.fetchPrimke(this.state.filter, this.state.pageActive);
    }

    getPoslovnicaIdForFilter(filter) {
        return this.FILTER_ITEMS
            ? this.FILTER_ITEMS[filter].apiParam
            : JSON.parse(sessionStorage.poslovnicaId);
    }

    renderInitialComponent() {
        return (
            <SearchResultsMessage
                icon={icons.primka}
                header={localization.common.ucitavanje}
                content={localization.common.molimoPricekajte}
            />
        );
    }

    renderEmptyComponent() {
        return (
            <SearchResultsMessage
                icon={icons.primka}
                header={localization.primke.nemaPrimki}
                content={undefined}
            />
        );
    }

    renderNoResultComponent() {
        return (
            <SearchResultsMessage
                icon={icons.primka}
                header={localization.common.unesenaPretragaNemaRezultata}
                content={localization.common.pokusajDrugacijuPretragu}
            />
        );
    }

    renderErrorComponent() {
        return (
            <SearchResultsMessage
                icon={icons.primka}
                header={localization.common.dogodilaSeGreska}
                content={localization.common.pokusajtePonovno}
            />
        );
    }

    renderListComponent() {
        return (
            <React.Fragment>
                <Grid.Row>
                    <Grid.Column>
                        <Message style={styles.headerTable}>
                            <Table structured stackable>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell style={styles.iconCell} />
                                        <Table.HeaderCell style={styles.brojPrimkeCell}>{localization.primke.brojPrimke}</Table.HeaderCell>
                                        <Table.HeaderCell style={styles.klijentCell}>{localization.primke.klijent}</Table.HeaderCell>
                                        {sessionStorage.userRole === enums.tipKorisnika.VLASNIK ? (
                                            <Table.HeaderCell style={styles.ukupnaCijenaCell}>
                                                {localization.common.ukupnaCijena}
                                            </Table.HeaderCell>
                                        ) : null}
                                        <Table.HeaderCell>{localization.primke.datumIVrijemePrimke}</Table.HeaderCell>
                                        <Table.HeaderCell style={styles.djelatnikCell}>{localization.primke.djelatnik}</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                            </Table>
                        </Message>
                    </Grid.Column>
                </Grid.Row>
                {this.state.primke.map(primka => {
                    return (
                        <Grid.Row key={primka.id}>
                            <Grid.Column verticalAlign="middle">
                                <Accordion fluid styled>
                                    <Accordion.Title
                                        active={this.state.activePrimka.includes(primka.id)}
                                        index={primka.id}
                                        onClick={this.expandPrimka}
                                    >
                                        <Table
                                            structured
                                            stackable
                                            color={primka.isStorniran ? 'grey' : undefined}
                                            inverted={primka.isStorniran}
                                        >
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.Cell style={styles.iconCell}>
                                                        <Icon name="dropdown" />
                                                    </Table.Cell>
                                                    <Table.Cell style={styles.brojPrimkeCell}>{primka.rbrPrimka}</Table.Cell>
                                                    <Table.Cell style={styles.klijentCell}>
                                                        {primka.isStorniran === null && primka.rbrStorniranogSUBa
                                                            ? localization.primke.stornoPrimke +
                                                              ': ' +
                                                              primka.rbrStorniranogSUBa +
                                                              ' - ' +
                                                              primka.pravnaOsoba.naziv
                                                            : primka.pravnaOsoba.naziv}
                                                    </Table.Cell>
                                                    {sessionStorage.userRole === enums.tipKorisnika.VLASNIK ? (
                                                        <Table.Cell style={styles.ukupnaCijenaCell}>
                                                            <FormatIznosa value={this.calculateUkupno(primka)} />
                                                        </Table.Cell>
                                                    ) : null}
                                                    <Table.Cell>{moment(primka.datumIVrijeme).format('DD.MM.YYYY. HH:mm')}</Table.Cell>
                                                    <Table.Cell style={styles.djelatnikCell}>
                                                        {primka.user.ime + ' ' + primka.user.prezime}
                                                    </Table.Cell>
                                                </Table.Row>
                                            </Table.Header>
                                        </Table>
                                    </Accordion.Title>
                                    <Accordion.Content active={this.state.activePrimka.includes(primka.id)}>
                                        <OtkupProdajaContent
                                            klijent={primka.pravnaOsoba}
                                            type={enums.tipTabliceArtikala.PRIMKA_FIXED}
                                            artikli={primka.skladisniUlazi.map(su => {
                                                return Object.assign({}, su, su.predmet, {
                                                    cijena: su.jedNabavnaCijena,
                                                    kolicina: primka.rbrStorniranogSUBa === null ? su.kolicina : -su.kolicina
                                                });
                                            })}
                                        />
                                        <div style={styles.actionButtons}>
                                            <Button
                                                icon="print"
                                                color="green"
                                                content={localization.primke.ispisPrimke}
                                                onClick={this.ispisPrimke.bind(this, primka)}
                                            />
                                            {!primka.isStorniran &&
                                            !primka.rbrStorniranogSUBa &&
                                            sessionStorage.userRole === enums.tipKorisnika.DJELATNIK ? (
                                                <Modal
                                                    open={this.state.activePotvrda === primka.id}
                                                    closeOnDimmerClick={false}
                                                    trigger={
                                                        <Button
                                                            icon="undo"
                                                            color="red"
                                                            content={localization.primke.storniranjePrimke}
                                                            onClick={() => this.showModal(primka.id)}
                                                        />
                                                    }
                                                >
                                                    <Header icon="undo" content={localization.common.potvrda} />
                                                    <Modal.Content>
                                                        <p>{localization.primke.storniranjePitanjePotvrde}</p>
                                                        <Form>
                                                            <Form.TextArea
                                                                rows={2}
                                                                placeholder={localization.otkup.sluzbenaNapomenaPrimke}
                                                                name="sluzbenaNapomena"
                                                                onChange={this.onChangeNapomene}
                                                            />
                                                            <Form.TextArea
                                                                rows={2}
                                                                placeholder={localization.otkup.nesluzbenaNapomenaPrimke}
                                                                name="nesluzbenaNapomena"
                                                                onChange={this.onChangeNapomene}
                                                            />
                                                        </Form>
                                                    </Modal.Content>
                                                    <Modal.Actions>
                                                        <Button
                                                            icon="remove"
                                                            content={localization.common.odustani}
                                                            onClick={() => this.hideModal()}
                                                        />
                                                        <Button
                                                            icon="copy"
                                                            color="red"
                                                            content={localization.primke.storniraj}
                                                            disabled={!this.state.sluzbenaNapomena}
                                                            onClick={() => this.stornoPrimke(primka.id)}
                                                        />
                                                    </Modal.Actions>
                                                </Modal>
                                            ) : null}
                                        </div>
                                        {primka.sluzbenaNapomena ? (
                                            <Message>
                                                <Message.Header>{localization.common.sluzbenaNapomena}</Message.Header>
                                                <p>{primka.sluzbenaNapomena}</p>
                                            </Message>
                                        ) : null}
                                        <Accordion fluid styled>
                                            <Accordion.Title
                                                active={this.state.activeComments.includes(primka.id)}
                                                index={primka.id}
                                                onClick={this.expandComments}
                                            >
                                                <Header as="h5" dividing style={styles.accordionHeaderContainer}>
                                                    <Icon name="dropdown" />
                                                    {localization.common.napomene}
                                                    {primka.nesluzbeneNapomene.length ? (
                                                        <Icon name="exclamation circle" color="red" style={styles.semaforStyle} />
                                                    ) : null}
                                                </Header>
                                            </Accordion.Title>
                                            <Accordion.Content active={this.state.activeComments.includes(primka.id)}>
                                                <Diskusija
                                                    napomene={primka.nesluzbeneNapomene}
                                                    textAreaValue={
                                                        this.state.noveNapomene.find(el => el.id === primka.id)
                                                            ? this.state.noveNapomene.find(el => el.id === primka.id).napomena
                                                            : ''
                                                    }
                                                    onChangeNovaNapomena={this.onChangeNovaNapomena.bind(this, primka.id)}
                                                    onDodajNapomenu={() => this.dodajNapomenu(primka.id)}
                                                />
                                            </Accordion.Content>
                                        </Accordion>
                                    </Accordion.Content>
                                </Accordion>
                            </Grid.Column>
                        </Grid.Row>
                    );
                })}
                <Grid.Row centered>
                    <Pagination
                        activePage={this.state.pageActive}
                        totalPages={this.state.pages}
                        onPageChange={this.onChangePage}
                        ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
                        firstItem={{ content: <Icon name="angle double left" />, icon: true }}
                        lastItem={{ content: <Icon name="angle double right" />, icon: true }}
                        prevItem={{ content: <Icon name="angle left" />, icon: true }}
                        nextItem={{ content: <Icon name="angle right" />, icon: true }}
                    />
                </Grid.Row>
            </React.Fragment>
        );
    }

    render() {
        return (
            <div>
                <Segment tertiary>
                    <Grid stackable>
                        <Grid.Row columns={6}>
                            <Grid.Column verticalAlign="middle">
                                <Header as="h2">{localization.primke.primke}</Header>
                            </Grid.Column>
                            <Grid.Column verticalAlign="middle">
                                <Search
                                    input={{ fluid: true }}
                                    loading={this.state.isSearchLoading}
                                    onSearchChange={this.handleSearchChange}
                                    value={this.state.value}
                                    open={false}
                                />
                            </Grid.Column>
                            {sessionStorage.userRole === enums.tipKorisnika.DJELATNIK ? (
                                <React.Fragment>
                                    <Grid.Column />
                                    <Grid.Column />
                                    <Grid.Column />
                                    <Grid.Column textAlign="right" verticalAlign="middle">
                                        <Button icon="asterisk" basic color="black" content={localization.primke.novaPrimka} onClick={this.onNovaPrimka} />
                                    </Grid.Column>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <Grid.Column />
                                    <Grid.Column />
                                    <Grid.Column />
                                    <Grid.Column textAlign="right" verticalAlign="middle">
                                        <FilterSortButton
                                            buttonName={localization.common.filtriranje}
                                            filteringOptions={this.FILTER_ITEMS.map((f) => f.name)}
                                            activeFilter={this.state.filter}
                                            callback={this.applyFilterAndSort}
                                        />
                                    </Grid.Column>
                                </React.Fragment>
                            )}
                        </Grid.Row>
                        {(() => {
                            switch (this.state.listState) {
                                case LIST_STATE.initial:
                                    return this.renderInitialComponent();
                                case LIST_STATE.populated:
                                    return this.renderListComponent();
                                case LIST_STATE.empty:
                                    return this.renderEmptyComponent();
                                case LIST_STATE.noResult:
                                    return this.renderNoResultComponent();
                                case LIST_STATE.error:
                                    return this.renderErrorComponent();
                            }
                        })()}
                    </Grid>
                </Segment>
                <Dimmer active={this.state.loader} page>
                    <Loader />
                </Dimmer>
            </div>
        );
    }
}

Primke.propTypes = {
    history: PropTypes.object.isRequired,
    poslovnice: PropTypes.array.isRequired,
    otkupiDataAccess: PropTypes.object.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {
        poslovnice: state.mjestaReducer.poslovnice || []
    };
};

let mapDispatchToProps = function(dispatch) {
    return {
        otkupiDataAccess: bindActionCreators(OtkupiDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Primke);
