import $ from 'jquery';

import * as success from '../actions/MjestaActions';

import endpoint from '../environments/endpoints';
import { showAlert } from '../utils/ErrorHandler';

export function getMjesta(onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'mjesta'
        })
            .done(data => {
                dispatch(success.getMjesta(data));
                if (onSuccess) {
                    onSuccess();
                }
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function createMjesto(mjesto, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'mjesta',
            data: JSON.stringify(mjesto)
        })
            .done(data => {
                dispatch(getMjesta());
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function createPoslovnica(poslovnica, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'poslovnice',
            data: JSON.stringify(poslovnica)
        })
            .done(data => {
                dispatch(getMjesta());
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function updateMjesto(id, mjesto, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'mjesta/' + id,
            data: JSON.stringify(mjesto)
        })
            .done(data => {
                dispatch(getMjesta());
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function updatePoslovnica(id, poslovnica, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'poslovnice/' + id,
            data: JSON.stringify(poslovnica)
        })
            .done(data => {
                dispatch(getMjesta());
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function deleteMjesto(id, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'mjesta/' + id
        })
            .done(data => {
                dispatch(getMjesta());
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function deletePoslovnica(id, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'poslovnice/' + id
        })
            .done(data => {
                dispatch(getMjesta());
            })
            .fail(error => {
                showAlert(error);
            });
    };
}
