import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Table } from 'semantic-ui-react';
import InputField from '../../InputField';
import localization from '../../../../locales/localization';
import Artikl from '../../Artikl';
import FormatIznosa from '../../FormatIznosa';

const styles = {
    kolicinaIcijena: {
        fontFamily: 'monospace'
    }
};

export default class EditablePredmetBodyCells extends React.Component {
    constructor(props) {
        super(props);
        this.hideModalAndRemoveItem = this.hideModalAndRemoveItem.bind(this);
    }

    hideModalAndRemoveItem() {
        this.props.showHideEditItem(undefined);
        this.props.onRemoveItem(this.props.item);
    }

    render() {
        return (
            <React.Fragment>
                <Table.Cell>{this.props.item.kategorija.ime + (this.props.item.potkategorija ? ' / ' + this.props.item.potkategorija.ime : '')}</Table.Cell>
                <Table.Cell collapsing>
                    <InputField
                        label={this.props.item.mjernaJedinica.naziv}
                        placeholder={localization.common.kol}
                        name="kolicina"
                        value={this.props.item.kolicina}
                        onChange={this.props.onChange.bind(this, this.props.item.sifra)}
                        isCjelobrojno={this.props.item.mjernaJedinica.isCjelobrojna}
                    />
                </Table.Cell>
                <Table.Cell collapsing>
                    <InputField
                        label={localization.common.eur}
                        placeholder={localization.common.cijena}
                        name="cijena"
                        value={this.props.item.cijena}
                        onChange={this.props.onChange.bind(this, this.props.item.sifra)}
                    />
                </Table.Cell>
                <Table.Cell textAlign="right" collapsing style={styles.kolicinaIcijena}>
                    <b>
                        {this.props.item.kolicina && this.props.item.cijena ? <FormatIznosa value={this.props.item.kolicina * this.props.item.cijena} /> : '-'}
                    </b>
                </Table.Cell>
                <Table.Cell collapsing>
                    <Button.Group basic size="small">
                        <Button icon="delete" onClick={this.props.onRemoveItem.bind(this, this.props.item)} />
                    </Button.Group>
                </Table.Cell>
                <Modal open={this.props.editingModalOpen} closeOnDimmerClick={false}>
                    <Modal.Header>{localization.common.urediArtikl}</Modal.Header>
                    <Modal.Content>
                        <Artikl item={this.props.item} saveAsNewItem={this.props.onSaveAsNewItem} onCancel={this.hideModalAndRemoveItem} />
                    </Modal.Content>
                </Modal>
            </React.Fragment>
        );
    }
}

EditablePredmetBodyCells.propTypes = {
    item: PropTypes.object.isRequired,
    editingModalOpen: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    showHideEditItem: PropTypes.func.isRequired,
    onSaveAsNewItem: PropTypes.func.isRequired,
    onRemoveItem: PropTypes.func.isRequired
};
