import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Accordion, Button, Dimmer, Grid, Icon, Loader, Message, Table } from 'semantic-ui-react';

import localization from '../../locales/localization';
import * as KontrolaDataAccess from '../../data_access/KontrolaDataAccess';
import moment from 'moment';
import Diskusija from '../common_components/Diskusija';
import enums from '../../utils/Enums';

const styles = {
    headerTable: {
        padding: '.75em 1em'
    },
    iconCell: {
        width: 50
    },
    datumIVrijemeCell: {
        width: 250
    },
    brojNetocnihUnosaCell: {
        width: 250
    },
    buttonCell: {
        width: 250,
        textAlign: 'right'
    }
};

class ListaKontrola extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeItems: [],
            noveNapomene: [],
            loader: !this.props.kontrolaCached
        };
        this.onChangeNovaNapomena = this.onChangeNovaNapomena.bind(this);
        this.dodajNapomenu = this.dodajNapomenu.bind(this);
        this.resetNapomena = this.resetNapomena.bind(this);
        this.expand = this.expand.bind(this);
        this.onClick = this.onClick.bind(this);
        this.showLoader = this.showLoader.bind(this);
        this.hideLoader = this.hideLoader.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.kontrolaCached) {
            this.setState({
                loader: false
            });
        }
    }

    onChangeNovaNapomena(id, e, { value }) {
        let noveNapomene = [];
        let index = this.state.noveNapomene.findIndex(el => el.id === id);
        if (index > -1) {
            noveNapomene = [...this.state.noveNapomene];
            noveNapomene[index] = {
                id: id,
                napomena: value
            };
        } else {
            noveNapomene = [
                ...this.state.noveNapomene,
                {
                    id: id,
                    napomena: value
                }
            ];
        }
        this.setState({
            noveNapomene: noveNapomene
        });
    }

    dodajNapomenu(key) {
        let date = moment(this.props.postavke.godina + '-' + this.props.postavke.mjesec + '-01');
        let startDate = date.format('YYYY-MM-DD');
        let endDate = date.endOf('month').format('YYYY-MM-DD');
        this.props.kontrolaDataAccess.addDnevnaNapomena(
            this.props.postavke.poslovnicaId,
            {
                datum: key,
                napomena: this.state.noveNapomene.find(el => el.id === key).napomena
            },
            startDate,
            endDate,
            this.resetNapomena.bind(this, key)
        );
    }

    resetNapomena(key) {
        let index = this.state.noveNapomene.findIndex(el => el.id === key);
        this.setState({
            noveNapomene: this.state.noveNapomene.slice(0, index).concat(this.state.noveNapomene.slice(index + 1))
        });
    }

    expand(e, { index }) {
        let activeItems = [];
        if (this.state.activeItems.includes(index)) {
            activeItems = _.xor(this.state.activeItems, [index]);
        } else {
            activeItems = [...this.state.activeItems, index];
        }
        this.setState({
            activeItems: activeItems
        });
    }

    onClick(kontrola, e) {
        e.stopPropagation();
        this.props.onDetailClick(kontrola);
    }

    showLoader(callback) {
        this.setState(
            {
                loader: true
            },
            callback
        );
    }

    hideLoader() {
        this.setState({
            loader: false
        });
    }

    render() {
        return (
            <React.Fragment>
                <Grid.Row>
                    <Grid.Column>
                        <Message style={styles.headerTable}>
                            <Table structured stackable>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell style={styles.iconCell} />
                                        <Table.HeaderCell style={styles.datumIVrijemeCell}>{localization.kontrola.datumKontrole}</Table.HeaderCell>
                                        <Table.HeaderCell>{localization.kontrola.postotakPopunjenosti}</Table.HeaderCell>
                                        {sessionStorage.userRole === enums.tipKorisnika.VLASNIK ? (
                                            <Table.HeaderCell style={styles.brojNetocnihUnosaCell}>{localization.kontrola.brojNetocnihUnosa}</Table.HeaderCell>
                                        ) : null}
                                        <Table.HeaderCell style={styles.buttonCell} />
                                    </Table.Row>
                                </Table.Header>
                            </Table>
                        </Message>
                    </Grid.Column>
                </Grid.Row>
                {this.props.kontrola.map((kontrola, index) => {
                    return (
                        <Grid.Row key={index}>
                            <Grid.Column verticalAlign="middle">
                                <Accordion fluid styled>
                                    <Accordion.Title active={this.state.activeItems.includes(index)} index={index} onClick={this.expand}>
                                        <Table structured stackable>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.Cell style={styles.iconCell}>
                                                        <Icon name="dropdown" />
                                                    </Table.Cell>
                                                    <Table.Cell style={styles.datumIVrijemeCell}>{moment(kontrola.date).format('DD.MM.YYYY.')}</Table.Cell>
                                                    <Table.Cell>{kontrola.postotakPopunjenosti.toFixed(2) + '%'}</Table.Cell>
                                                    {sessionStorage.userRole === enums.tipKorisnika.VLASNIK ? (
                                                        <Table.Cell style={styles.brojNetocnihUnosaCell}>{kontrola.brojNetocnihUnosa}</Table.Cell>
                                                    ) : null}
                                                    <Table.Cell style={styles.buttonCell}>
                                                        {
                                                            <Button
                                                                icon="pencil"
                                                                basic
                                                                color="black"
                                                                size="small"
                                                                content={
                                                                    sessionStorage.userRole === enums.tipKorisnika.KONTROLA
                                                                        ? localization.kontrola.unosKontrole
                                                                        : localization.kontrola.pregledUnosaKontrole
                                                                }
                                                                onClick={this.onClick.bind(this, kontrola.kontroleRadnji)}
                                                            />
                                                        }
                                                    </Table.Cell>
                                                </Table.Row>
                                            </Table.Header>
                                        </Table>
                                    </Accordion.Title>
                                    <Accordion.Content active={this.state.activeItems.includes(index)}>
                                        <Diskusija
                                            napomene={kontrola.dnevneNapomene.map(n => n.napomena)}
                                            textAreaValue={
                                                this.state.noveNapomene.find(el => el.id === kontrola.date)
                                                    ? this.state.noveNapomene.find(el => el.id === kontrola.date).napomena
                                                    : ''
                                            }
                                            onChangeNovaNapomena={this.onChangeNovaNapomena.bind(this, kontrola.date)}
                                            onDodajNapomenu={this.showLoader.bind(this, this.dodajNapomenu.bind(this, kontrola.date))}
                                        />
                                    </Accordion.Content>
                                </Accordion>
                            </Grid.Column>
                        </Grid.Row>
                    );
                })}
                <Dimmer active={this.state.loader} page>
                    <Loader />
                </Dimmer>
            </React.Fragment>
        );
    }
}

ListaKontrola.propTypes = {
    postavke: PropTypes.object.isRequired,
    kontrola: PropTypes.array.isRequired,
    kontrolaCached: PropTypes.bool.isRequired,
    kontrolaDataAccess: PropTypes.object.isRequired,
    onDetailClick: PropTypes.func.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {
        kontrola: state.kontrolaReducer.kontrola || [],
        kontrolaCached: state.kontrolaReducer.cached
    };
};

let mapDispatchToProps = function(dispatch) {
    return {
        kontrolaDataAccess: bindActionCreators(KontrolaDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListaKontrola);
