import enums from './Enums';

// const PREFIX_FISKALNI = 'P';
const PREFIX_TRANSAKCIJSKI = 'T';

const NAPLATNI_UREDAJ = 3;

export function calculateBrojRacuna(racun) {
    let prefixFiskalni = racun.poslovnica.oznakaPoslovnogProstora[0];
    let brojPoslovnice = racun.poslovnica.brojPoslovnice;
    if (racun.tipRacuna === enums.tipRacuna.FISKALNI_RACUN) {
        let brojRacuna = racun.rbrFiskalniRacun.split('-')[0];
        return brojRacuna + '/' + prefixFiskalni + brojPoslovnice + '/' + NAPLATNI_UREDAJ;
    } else {
        let brojRacuna = racun.rbrTransakcijskiRacun.split('-')[0];
        return brojRacuna + '/' + PREFIX_TRANSAKCIJSKI + brojPoslovnice + '/' + NAPLATNI_UREDAJ;
    }
}
