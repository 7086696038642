import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Grid, Dimmer, Header, Icon, Loader, Modal, Pagination, Search, Segment, Table } from 'semantic-ui-react';
import localization from '../../locales/localization';
import icons from '../../utils/DefinedIcons';
import SearchResultsMessage from '../common_components/SearchResultsMessage';
import * as SkladisteDataAccess from '../../data_access/SkladisteDataAccess';

const styles = {
    paginationContainer: {
        textAlign: 'center'
    }
};

const ITEMS_PER_PAGE = 20;

class MaknutiSOglasavanja extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSearchLoading: false,
            results: [],
            value: '',
            activeModal: undefined,
            loader: !this.props.listaOglasavanjaCache,
            pageActive: 1
        };
        this.onChangePage = this.onChangePage.bind(this);
        this.resetSearchComponent = this.resetSearchComponent.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.makniPredmet = this.makniPredmet.bind(this);
        this.showHideModal = this.showHideModal.bind(this);
    }

    componentWillMount() {
        this.resetSearchComponent();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.listaOglasavanjaCache) {
            this.setState({
                loader: false
            });
        }
    }

    onChangePage(e, { activePage }) {
        this.setState({
            pageActive: activePage
        });
    }

    resetSearchComponent() {
        this.setState({
            isSearchLoading: false,
            results: [],
            value: ''
        });
    }

    handleSearchChange(e, { value }) {
        this.setState({
            isSearchLoading: true,
            value: value,
            pageActive: 1
        });

        setTimeout(() => {
            if (this.state.value.length < 1) {
                return this.resetSearchComponent();
            }

            const re = new RegExp(_.escapeRegExp(this.state.value), 'i');
            const isMatch = result => {
                let testingArray = [result.artikl.sifra, result.artikl.naziv, result.artikl.serijskiBroj, result.artikl.kategorija.ime];
                if (result.artikl.potkategorija) {
                    testingArray.push(result.artikl.potkategorija.ime);
                }
                for (let i = 0; i < testingArray.length; i++) {
                    if (re.test(testingArray[i])) {
                        return true;
                    }
                }
                return false;
            };

            this.setState({
                isSearchLoading: false,
                results: _.filter(this.props.stavke, isMatch)
            });
        }, 500);
    }

    makniPredmet(skladisnaStavka) {
        let counter = 0;
        let numOfSettedFlags = skladisnaStavka.flags.filter(f => f.isSet).length;
        skladisnaStavka.flags.forEach(f => {
            if (f.isSet) {
                counter++;
                if (counter === numOfSettedFlags) {
                    this.props.skladisteDataAccess.updateSkladisnaStavkaFlag(
                        skladisnaStavka.id,
                        f.id,
                        false,
                        this.props.skladisteDataAccess.getListaArtikalaZaMaknutiSOglasavanja
                    );
                } else {
                    this.props.skladisteDataAccess.updateSkladisnaStavkaFlag(skladisnaStavka.id, f.id, false);
                }
            }
        });
    }

    showHideModal(value) {
        this.setState({
            activeModal: value
        });
    }

    render() {
        let res, totalPages;
        let startItem = (this.state.pageActive - 1) * ITEMS_PER_PAGE;
        let endItem = this.state.pageActive * ITEMS_PER_PAGE;
        if (this.state.value !== '' && !this.state.isSearchLoading) {
            res = this.state.results.slice(startItem, endItem);
            totalPages = Math.ceil(this.state.results.length / ITEMS_PER_PAGE);
        } else {
            res = this.props.stavke.slice(startItem, endItem);
            totalPages = Math.ceil(this.props.stavke.length / ITEMS_PER_PAGE);
        }
        return (
            <div>
                <Segment tertiary>
                    <Grid stackable>
                        <Grid.Row columns={4}>
                            <Grid.Column verticalAlign="middle">
                                <Header as="h2">{localization.maknutiSOglasavanja.artikliKojeJePotrebnoMaknutiSOglasavanja}</Header>
                            </Grid.Column>
                            <Grid.Column verticalAlign="middle">
                                <Search
                                    input={{ fluid: true }}
                                    loading={this.state.isSearchLoading}
                                    onSearchChange={this.handleSearchChange}
                                    value={this.state.value}
                                    open={false}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        {res.length ? (
                            <React.Fragment>
                                <Grid.Row>
                                    <Grid.Column verticalAlign="middle">
                                        <Table stackable>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell>{localization.artikl.sifra}</Table.HeaderCell>
                                                    <Table.HeaderCell>{localization.artikl.naziv}</Table.HeaderCell>
                                                    <Table.HeaderCell>{localization.artikl.kategorija}</Table.HeaderCell>
                                                    <Table.HeaderCell>{localization.artikl.serijskiBroj}</Table.HeaderCell>
                                                    <Table.HeaderCell />
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                {res.map((s, index) => {
                                                    return (
                                                        <Table.Row key={index}>
                                                            <Table.Cell>{s.artikl.sifra}</Table.Cell>
                                                            <Table.Cell>{s.artikl.naziv}</Table.Cell>
                                                            <Table.Cell>
                                                                {s.artikl.kategorija.ime + (s.artikl.potkategorija ? ' / ' + s.artikl.potkategorija.ime : '')}
                                                            </Table.Cell>
                                                            <Table.Cell>{s.artikl.serijskiBroj || '-'}</Table.Cell>
                                                            <Table.Cell collapsing>
                                                                <Modal
                                                                    open={this.state.activeModal === s.id}
                                                                    trigger={
                                                                        <Button
                                                                            icon="remove"
                                                                            basic
                                                                            size="small"
                                                                            onClick={this.showHideModal.bind(this, s.id)}
                                                                        />
                                                                    }
                                                                    closeOnDimmerClick={false}
                                                                >
                                                                    <Modal.Header>{localization.maknutiSOglasavanja.potvrdaMicanja}</Modal.Header>
                                                                    <Modal.Content>
                                                                        <p>{localization.maknutiSOglasavanja.pitanjePotvrde}</p>
                                                                    </Modal.Content>
                                                                    <Modal.Actions>
                                                                        <Button
                                                                            icon="remove"
                                                                            content={localization.common.odustani}
                                                                            onClick={this.showHideModal.bind(this, undefined)}
                                                                        />
                                                                        <Button
                                                                            icon="checkmark"
                                                                            color="green"
                                                                            content={localization.common.ok}
                                                                            onClick={this.makniPredmet.bind(this, s)}
                                                                        />
                                                                    </Modal.Actions>
                                                                </Modal>
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    );
                                                })}
                                            </Table.Body>
                                        </Table>
                                        <div style={styles.paginationContainer}>
                                            <Pagination
                                                activePage={this.state.pageActive}
                                                totalPages={totalPages}
                                                onPageChange={this.onChangePage}
                                                ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
                                                firstItem={{ content: <Icon name="angle double left" />, icon: true }}
                                                lastItem={{ content: <Icon name="angle double right" />, icon: true }}
                                                prevItem={{ content: <Icon name="angle left" />, icon: true }}
                                                nextItem={{ content: <Icon name="angle right" />, icon: true }}
                                            />
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                            </React.Fragment>
                        ) : (
                            <SearchResultsMessage
                                icon={icons.maknutiSOglasavanja}
                                header={
                                    this.state.value !== ''
                                        ? localization.common.unesenaPretragaNemaRezultata
                                        : localization.maknutiSOglasavanja.nemaArtikalaZaMicanjeSOglasavanja
                                }
                                content={this.state.value !== '' ? localization.common.pokusajDrugacijuPretragu : undefined}
                            />
                        )}
                    </Grid>
                </Segment>
                <Dimmer active={this.state.loader} page>
                    <Loader />
                </Dimmer>
            </div>
        );
    }
}

MaknutiSOglasavanja.propTypes = {
    history: PropTypes.object.isRequired,
    stavke: PropTypes.array.isRequired,
    listaOglasavanjaCache: PropTypes.bool.isRequired,
    skladisteDataAccess: PropTypes.object.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {
        stavke: state.skladisteReducer.listaZaMaknutiSOglasavanja || [],
        listaOglasavanjaCache: state.skladisteReducer.cachedLista
    };
};

let mapDispatchToProps = function(dispatch) {
    return {
        skladisteDataAccess: bindActionCreators(SkladisteDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MaknutiSOglasavanja);
