import $ from 'jquery';

import { resetStanjeCache } from '../actions/SkladisteActions';

import endpoint from '../environments/endpoints';
import { showAlert } from '../utils/ErrorHandler';

export function createZahtjevZaRezervaciju(zahtjevZaRezervaciju, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'rezervacije',
            data: JSON.stringify(zahtjevZaRezervaciju)
        })
            .done(data => {
                dispatch(resetStanjeCache());
                onSuccess(data);
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function createZahtjevZaRezervacijuFromPostojeca(zahtjevZaRezervaciju, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'rezervacije/from-postojeca',
            data: JSON.stringify(zahtjevZaRezervaciju)
        })
            .done(data => {
                dispatch(resetStanjeCache());
                onSuccess(data);
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function dodavanjeNesluzbeneNapomeneNaRezervaciju(zahtjevZaRezervacijuId, napomena, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'rezervacije/' + zahtjevZaRezervacijuId + '/nesluzbene-napomene',
            data: napomena
        })
            .done(data => {
                onSuccess();
            })
            .fail(error => {
                showAlert(error);
                onError();
            });
    };
}

export function otkaziRezervacije(zahtjevZaRezervacijuId, rezervacijeData, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'rezervacije/' + zahtjevZaRezervacijuId + '/otkazi',
            data: JSON.stringify(rezervacijeData)
        })
            .done(data => {
                dispatch(resetStanjeCache());
                onSuccess(data);
            })
            .fail(error => {
                showAlert(error);
                onError();
            });
    };
}

export function podigniRezervacije(zahtjevZaRezervacijuId, rezervacijeData, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'rezervacije/' + zahtjevZaRezervacijuId + '/podigni',
            data: JSON.stringify(rezervacijeData)
        })
            .done(data => {
                dispatch(resetStanjeCache());
                onSuccess(data);
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function produziRezervacije(zahtjevZaRezervacijuId, rezervacijeData, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'rezervacije/' + zahtjevZaRezervacijuId + '/produzi',
            data: JSON.stringify(rezervacijeData)
        })
            .done(data => {
                dispatch(resetStanjeCache());
                onSuccess(data);
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function produziRokZaPodizanje(zahtjevZaRezervacijuId, rezervacijeData, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'rezervacije/' + zahtjevZaRezervacijuId + '/rokovi-za-podizanje',
            data: JSON.stringify(rezervacijeData)
        })
            .done(data => {
                onSuccess();
            })
            .fail(error => {
                showAlert(error);
                onError();
            });
    };
}

export function dodajSlikeNaRezervaciju(zahtjevZaRezervacijuId, slike, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'rezervacije/' + zahtjevZaRezervacijuId + '/slike/b64',
            data: JSON.stringify(slike)
        })
            .done(data => {
                onSuccess();
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function izbrisiSlikeSaRezervacije(zahtjevZaRezervacijuId, slike, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'rezervacije/' + zahtjevZaRezervacijuId + '/slike',
            data: JSON.stringify(slike)
        })
            .done(data => {
                onSuccess();
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function getRezervacijeImportViewModel(poslovnicaId, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'rezervacije/vm-import?poslovnicaId=' + poslovnicaId
        })
            .done(data => {
                onSuccess(data);
            })
            .fail(error => {
                showAlert(error);
            });
    };
}
