import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Accordion, Button, Dimmer, Form, Grid, Loader, Message, Table } from 'semantic-ui-react';

import localization from '../../locales/localization';
import * as KontrolaDataAccess from '../../data_access/KontrolaDataAccess';
import moment from 'moment';
import KontrolaMappings from '../../utils/KontrolaMappings';
import enums from '../../utils/Enums';
import InputField from '../common_components/InputField';
import FormatIznosa from '../common_components/FormatIznosa';

const styles = {
    headerTable: {
        padding: '.75em 1em'
    },
    datumIVrijemeCell: {
        width: 250
    },
    unosCell: {
        width: 150
    },
    napomenaCell: {
        width: 250
    },
    buttonCell: {
        width: 65
    }
};

class DetaljiKontrole extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            detaljiKontrole: this.calculateIznosINapomena(this.props.detaljiKontrole),
            loader: false
        };
        this.onChange = this.onChange.bind(this);
        this.saveUnos = this.saveUnos.bind(this);
        this.showLoader = this.showLoader.bind(this);
        this.hideLoader = this.hideLoader.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            detaljiKontrole: this.calculateIznosINapomena(nextProps.detaljiKontrole)
        });
    }

    calculateIznosINapomena(unosiKontrola) {
        return unosiKontrola.map(uk => {
            if (!uk.unosKontrole) {
                return Object.assign({}, uk, {
                    unosKontrole: {
                        napomena: '',
                        iznos: ''
                    }
                });
            }
            return uk;
        });
    }

    onChange(id, e, { name, value }) {
        this.setState({
            detaljiKontrole: this.state.detaljiKontrole.map(uk => {
                if (uk.radnja.id === id) {
                    return Object.assign({}, uk, {
                        unosKontrole: {
                            napomena: name === 'napomena' ? value : uk.unosKontrole.napomena,
                            iznos: name === 'iznos' ? value : uk.unosKontrole.iznos
                        }
                    });
                }
                return uk;
            })
        });
    }

    saveUnos(id) {
        let date = moment(this.props.postavke.godina + '-' + this.props.postavke.mjesec + '-01');
        let startDate = date.format('YYYY-MM-DD');
        let endDate = date.endOf('month').format('YYYY-MM-DD');
        let unos = this.state.detaljiKontrole.find(uk => uk.radnja.id === id);
        this.props.kontrolaDataAccess.setUnosKontrole(
            unos.radnja.id,
            {
                iznos: parseFloat(unos.unosKontrole.iznos),
                napomena: unos.unosKontrole.napomena
            },
            this.props.postavke.poslovnicaId,
            startDate,
            endDate,
            this.hideLoader
        );
    }

    showLoader(callback) {
        this.setState(
            {
                loader: true
            },
            callback
        );
    }

    hideLoader() {
        this.setState({
            loader: false
        });
    }

    render() {
        return (
            <React.Fragment>
                <Grid.Row>
                    <Grid.Column>
                        <Message style={styles.headerTable}>
                            <Table structured stackable>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell style={styles.datumIVrijemeCell}>{localization.kontrola.datumIVrijemeRadnje}</Table.HeaderCell>
                                        <Table.HeaderCell>{localization.kontrola.radnja}</Table.HeaderCell>
                                        <Table.HeaderCell style={styles.unosCell}>{localization.kontrola.unosKontrole}</Table.HeaderCell>
                                        {sessionStorage.userRole === enums.tipKorisnika.VLASNIK ? (
                                            <Table.HeaderCell style={styles.unosCell}>{localization.kontrola.tocanIznos}</Table.HeaderCell>
                                        ) : null}
                                        <Table.HeaderCell style={styles.napomenaCell}>{localization.kontrola.napomenaKontrole}</Table.HeaderCell>
                                        {sessionStorage.userRole === enums.tipKorisnika.KONTROLA ? <Table.HeaderCell style={styles.buttonCell} /> : null}
                                    </Table.Row>
                                </Table.Header>
                            </Table>
                        </Message>
                    </Grid.Column>
                </Grid.Row>
                {this.state.detaljiKontrole.map((uk, index) => {
                    let mappedRadnja = KontrolaMappings[uk.radnja.tipRadnje](uk.radnja);
                    let highlightError =
                        sessionStorage.userRole === enums.tipKorisnika.VLASNIK && uk.unosKontrole.iznos && uk.unosKontrole.iznos !== uk.tocanIznos;
                    let highlightEqual =
                        sessionStorage.userRole === enums.tipKorisnika.VLASNIK && uk.unosKontrole.iznos && uk.unosKontrole.iznos === uk.tocanIznos;
                    return (
                        <Grid.Row key={index}>
                            <Grid.Column verticalAlign="middle">
                                <Accordion fluid styled>
                                    <Accordion.Title>
                                        <Table structured stackable>
                                            <Table.Header>
                                                <Table.Row positive={highlightEqual} error={highlightError}>
                                                    <Table.Cell style={styles.datumIVrijemeCell}>
                                                        {moment(uk.radnja.datumIVrijeme).format('DD.MM.YYYY. HH:mm')}
                                                    </Table.Cell>
                                                    <Table.Cell>{mappedRadnja.imeRadnje}</Table.Cell>
                                                    {sessionStorage.userRole === enums.tipKorisnika.KONTROLA ? (
                                                        <React.Fragment>
                                                            <Table.Cell style={styles.unosCell}>
                                                                <InputField
                                                                    label={localization.common.eur}
                                                                    placeholder={localization.common.cijena}
                                                                    name="iznos"
                                                                    value={uk.unosKontrole.iznos}
                                                                    onChange={this.onChange.bind(this, uk.radnja.id)}
                                                                />
                                                            </Table.Cell>
                                                            <Table.Cell style={styles.napomenaCell}>
                                                                <Form>
                                                                    <Form.TextArea
                                                                        placeholder={localization.common.napomena}
                                                                        name="napomena"
                                                                        value={uk.unosKontrole.napomena}
                                                                        onChange={this.onChange.bind(this, uk.radnja.id)}
                                                                    />
                                                                </Form>
                                                            </Table.Cell>
                                                            <Table.Cell style={styles.buttonCell}>
                                                                <Button
                                                                    icon="save"
                                                                    basic
                                                                    size="small"
                                                                    color="green"
                                                                    onClick={this.showLoader.bind(this, this.saveUnos.bind(this, uk.radnja.id))}
                                                                />
                                                            </Table.Cell>
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            <Table.Cell style={styles.unosCell}>
                                                                {uk.unosKontrole.iznos ? <FormatIznosa value={uk.unosKontrole.iznos} /> : '-'}
                                                            </Table.Cell>
                                                            <Table.Cell style={styles.unosCell}>{<FormatIznosa value={uk.tocanIznos} />}</Table.Cell>
                                                            <Table.Cell style={styles.napomenaCell}>{uk.unosKontrole.napomena || '-'}</Table.Cell>
                                                        </React.Fragment>
                                                    )}
                                                </Table.Row>
                                            </Table.Header>
                                        </Table>
                                    </Accordion.Title>
                                </Accordion>
                            </Grid.Column>
                        </Grid.Row>
                    );
                })}
                <Dimmer active={this.state.loader} page>
                    <Loader />
                </Dimmer>
            </React.Fragment>
        );
    }
}

DetaljiKontrole.propTypes = {
    postavke: PropTypes.object.isRequired,
    detaljiKontrole: PropTypes.array.isRequired,
    kontrolaDataAccess: PropTypes.object.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {};
};

let mapDispatchToProps = function(dispatch) {
    return {
        kontrolaDataAccess: bindActionCreators(KontrolaDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetaljiKontrole);
