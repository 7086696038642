import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Modal, Table } from 'semantic-ui-react';
import localization from '../../../locales/localization';
import enums from '../../../utils/Enums';
import moment from 'moment';
import Klijent from '../Klijent';
import { formatNumber } from '../../../utils/PhoneNumberFormatter';

const styles = {
    oprezRow: {
        color: 'red'
    }
};

class TablicaKlijenata extends React.Component {
    render() {
        return (
            <Table stackable>
                <Table.Header>
                    <Table.Row>
                        {this.props.klijent.tipKlijenta === enums.tipKlijenta.FIZICKA_OSOBA ? (
                            <React.Fragment>
                                <Table.HeaderCell>{localization.klijent.ime}</Table.HeaderCell>
                                <Table.HeaderCell>{localization.klijent.prezime}</Table.HeaderCell>
                                <Table.HeaderCell>{localization.klijent.datumRodenja}</Table.HeaderCell>
                            </React.Fragment>
                        ) : (
                            <Table.HeaderCell>{localization.klijent.naziv}</Table.HeaderCell>
                        )}
                        <Table.HeaderCell>{localization.klijent.adresa}</Table.HeaderCell>
                        <Table.HeaderCell>{localization.klijent.drzava}</Table.HeaderCell>
                        <Table.HeaderCell>{localization.klijent.kontaktBroj}</Table.HeaderCell>
                        {this.props.showActions ? <Table.HeaderCell /> : null}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row style={this.props.klijent.napomenaOpreza ? styles.oprezRow : undefined}>
                        {this.props.klijent.tipKlijenta === enums.tipKlijenta.FIZICKA_OSOBA ? (
                            <React.Fragment>
                                <Table.Cell>{this.props.klijent.ime || '-'}</Table.Cell>
                                <Table.Cell>{this.props.klijent.prezime || '-'}</Table.Cell>
                                <Table.Cell>{this.props.klijent.datumRodenja ? moment(this.props.klijent.datumRodenja).format('DD.MM.YYYY.') : '-'}</Table.Cell>
                            </React.Fragment>
                        ) : (
                            <Table.Cell>{this.props.klijent.naziv || '-'}</Table.Cell>
                        )}
                        <Table.Cell>{this.props.klijent.adresa || '-'}</Table.Cell>
                        <Table.Cell>{this.props.klijent.drzava || '-'}</Table.Cell>
                        <Table.Cell>{this.props.klijent.kontaktBroj ? formatNumber(this.props.klijent.kontaktBroj) : '-'}</Table.Cell>
                        {this.props.showActions ? (
                            <Table.Cell collapsing>
                                <Button.Group basic size="small">
                                    <Modal
                                        open={this.props.editingModalOpen}
                                        closeOnDimmerClick={false}
                                        trigger={<Button icon="write" onClick={this.props.showHideEditClient.bind(this, true)} />}
                                    >
                                        <Modal.Header>{localization.common.urediKlijenta}</Modal.Header>
                                        <Modal.Content>
                                            <Klijent
                                                client={this.props.klijent}
                                                clientType={this.props.allowedClientType}
                                                kontaktiRequired={this.props.kontaktiRequired}
                                                onSave={this.props.onEditClientSave}
                                                onCancel={this.props.showHideEditClient.bind(this, false)}
                                            />
                                        </Modal.Content>
                                    </Modal>
                                    <Button icon="delete" onClick={this.props.onRemoveClient} />
                                </Button.Group>
                            </Table.Cell>
                        ) : null}
                    </Table.Row>
                </Table.Body>
            </Table>
        );
    }
}

TablicaKlijenata.propTypes = {
    klijent: PropTypes.object.isRequired,
    allowedClientType: PropTypes.string,
    kontaktiRequired: PropTypes.bool,
    showActions: PropTypes.bool,
    editingModalOpen: PropTypes.bool,
    showHideEditClient: PropTypes.func,
    onEditClientSave: PropTypes.func,
    onRemoveClient: PropTypes.func
};

let mapStateToProps = function(state, ownProps) {
    return {};
};

let mapDispatchToProps = function(dispatch) {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TablicaKlijenata);
