import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Form, Header, Modal, Segment } from 'semantic-ui-react';

import localization from '../../locales/localization';
import * as TroskoviDataAccess from '../../data_access/TroskoviDataAccess';

const styles = {
    deleteButton: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    container: {
        textAlign: 'left'
    },
    actionButtons: {
        marginTop: 16,
        display: 'flex',
        justifyContent: 'flex-end'
    }
};

class OneTimeGrupa extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ime: this.props.grupa.ime,
            activeModal: false,
            enableSaveButton: false
        };
        this.onChange = this.onChange.bind(this);
        this.enableSaveButton = this.enableSaveButton.bind(this);
        this.checkOneTimeGrupaRequiredFields = this.checkOneTimeGrupaRequiredFields.bind(this);
        this.showHideModal = this.showHideModal.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            ime: nextProps.grupa.ime,
            activeModal: false,
            enableSaveButton: false
        });
    }

    onChange(e, { name, value }) {
        this.setState({ [name]: value }, this.enableSaveButton);
    }

    enableSaveButton() {
        this.setState({ enableSaveButton: this.checkOneTimeGrupaRequiredFields() });
    }

    checkOneTimeGrupaRequiredFields() {
        if (!this.state.ime) {
            return false;
        }
        return true;
    }

    showHideModal(value) {
        this.setState({ activeModal: value });
    }

    onCancel() {
        if (this.props.nova) {
            this.props.onDelete();
        } else {
            this.setState({
                ime: this.props.grupa.ime,
                activeModal: false,
                enableSaveButton: false
            });
        }
    }

    onSave() {
        let grupa = {
            ime: this.state.ime
        };
        if (this.props.nova) {
            this.props.troskoviDataAccess.createOneTimeGrupa(grupa);
        } else {
            this.props.troskoviDataAccess.updateOneTimeGrupa(this.props.grupa.id, grupa);
        }
    }

    onDelete() {
        this.props.troskoviDataAccess.deleteOneTimeGrupa(this.props.grupa.id);
    }

    render() {
        return (
            <Segment>
                {!this.props.nova ? (
                    <div style={styles.deleteButton}>
                        <Modal
                            open={this.state.activeModal}
                            trigger={<Button icon="delete" color="red" basic size="small" onClick={this.showHideModal.bind(this, true)} />}
                        >
                            <Header icon="trash" content={localization.common.potvrdaBrisanja} />
                            <Modal.Content>
                                <p>{localization.postavke.brisanjeGrupeJednokratnogTroska}</p>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button icon="remove" content={localization.common.odustani} onClick={this.showHideModal.bind(this, false)} />
                                <Button icon="trash" color="red" content={localization.common.obrisi} onClick={this.onDelete} />
                            </Modal.Actions>
                        </Modal>
                    </div>
                ) : null}
                <Form style={styles.container}>
                    <Form.Input
                        fluid
                        label={localization.postavke.nazivGrupeJednokratnogTroska}
                        name="ime"
                        onChange={this.onChange}
                        value={this.state.ime}
                        required
                    />
                </Form>
                <div style={styles.actionButtons}>
                    <Button
                        icon="cancel"
                        content={this.props.nova ? localization.common.odustani : localization.common.vratiNaSpremljeno}
                        onClick={this.onCancel}
                    />
                    <Button icon="save" color="green" disabled={!this.state.enableSaveButton} content={localization.common.spremi} onClick={this.onSave} />
                </div>
            </Segment>
        );
    }
}

OneTimeGrupa.propTypes = {
    nova: PropTypes.bool,
    grupa: PropTypes.object.isRequired,
    troskoviDataAccess: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {};
};

let mapDispatchToProps = function(dispatch) {
    return {
        troskoviDataAccess: bindActionCreators(TroskoviDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OneTimeGrupa);
