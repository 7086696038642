import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Menu from './common_components/Menu';
import endpoints from '../environments/endpoints';
import icons from '../utils/DefinedIcons';
import localization from '../locales/localization';
import * as AppPreferencesDataAccess from '../data_access/AppPreferencesDataAccess';
import * as BlagajneDataAccess from '../data_access/BlagajneDataAccess';
import * as KlijentiDataAccess from '../data_access/KlijentiDataAccess';
import * as KarticeDataAccess from '../data_access/KarticeDataAccess';
import * as KategorijeDataAccess from '../data_access/KategorijeDataAccess';
import * as MjerneJediniceDataAccess from '../data_access/MjerneJediniceDataAccess';
import { bindActionCreators } from 'redux';

const menuItems = [
    {
        icon: icons.rezervacija,
        url: endpoints.appEndpoints.rezervacije,
        text: localization.starting.rezervacija
    },
    {
        icon: icons.otkup,
        url: endpoints.appEndpoints.otkup,
        text: localization.starting.otkup
    },
    {
        icon: icons.primka,
        url: endpoints.appEndpoints.primka,
        text: localization.starting.primka
    },
    {
        icon: icons.prodaja,
        url: endpoints.appEndpoints.prodaja,
        text: localization.starting.prodaja
    },
    {
        icon: icons.procjena,
        url: endpoints.appEndpoints.procjena,
        text: localization.starting.procjena
    },
    {
        icon: icons.radniNalog,
        url: endpoints.appEndpoints.radniNalog,
        text: localization.starting.radniNalog
    },
    {
        icon: icons.meduskladisnica,
        url: endpoints.appEndpoints.meduskladisnica,
        text: localization.starting.meduskladisnica
    },
    {
        icon: icons.dokumenti,
        url: endpoints.appEndpoints.dokumentiRoot,
        text: localization.starting.dokumenti
    }
];

class DjelatnikPreloaderAndMenu extends React.Component {
    constructor(props) {
        super(props);
        this.props.blagajneDataAccess.getStanjeBlagajni(JSON.parse(sessionStorage.poslovnicaId));
        if (!this.props.appPrefsCache) {
            this.props.appPreferencesDataAccess.getAppPrefs();
        }
        if (!this.props.karticeCache) {
            this.props.karticeDataAccess.getKartice();
        }
        if (!this.props.kategorijeCache) {
            this.props.kategorijeDataAccess.getKategorije();
        }
        if (!this.props.mjerneJediniceCache) {
            this.props.mjerneJediniceDataAccess.getMjerneJedinice();
        }
    }

    render() {
        return <Menu menuItems={menuItems} history={this.props.history} />;
    }
}

DjelatnikPreloaderAndMenu.propTypes = {
    history: PropTypes.object.isRequired,
    appPreferencesDataAccess: PropTypes.object.isRequired,
    appPrefsCache: PropTypes.bool.isRequired,
    blagajneDataAccess: PropTypes.object.isRequired,
    stanjeBlagajniCache: PropTypes.bool.isRequired,
    klijentiDataAccess: PropTypes.object.isRequired,
    karticeDataAccess: PropTypes.object.isRequired,
    karticeCache: PropTypes.bool.isRequired,
    kategorijeDataAccess: PropTypes.object.isRequired,
    kategorijeCache: PropTypes.bool.isRequired,
    mjerneJediniceDataAccess: PropTypes.object.isRequired,
    mjerneJediniceCache: PropTypes.bool.isRequired,
};

let mapStateToProps = function(state, ownProps) {
    return {
        appPrefsCache: state.appPreferencesReducer.cached,
        stanjeBlagajniCache: state.blagajneReducer.cachedStanje,
        karticeCache: state.karticeReducer.cached,
        kategorijeCache: state.kategorijeReducer.cached,
        mjerneJediniceCache: state.mjerneJediniceReducer.cached,
    };
};

let mapDispatchToProps = function(dispatch) {
    return {
        appPreferencesDataAccess: bindActionCreators(AppPreferencesDataAccess, dispatch),
        blagajneDataAccess: bindActionCreators(BlagajneDataAccess, dispatch),
        klijentiDataAccess: bindActionCreators(KlijentiDataAccess, dispatch),
        karticeDataAccess: bindActionCreators(KarticeDataAccess, dispatch),
        kategorijeDataAccess: bindActionCreators(KategorijeDataAccess, dispatch),
        mjerneJediniceDataAccess: bindActionCreators(MjerneJediniceDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DjelatnikPreloaderAndMenu);
