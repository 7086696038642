let initialState = {
    kartice: [],
    cached: false
};

export default function karticeReducer(state = initialState, action) {
    switch (action.type) {
        case 'GET_KARTICE': {
            return Object.assign({}, state, {
                kartice: [...action.kartice],
                cached: true
            });
        }
        default:
            return state;
    }
}
