import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Menu from '../common_components/Menu';
import UpravljanjeFirmom from './UpravljanjeFirmom';
import UpravljanjeKorisnicima from './UpravljanjeKorisnicima';
import UpravljanjePostotcima from './UpravljanjePostotcima';
import Kartice from './Kartice';
import Kategorije from './Kategorije';
import MjerneJedinice from './MjerneJedinice';
import PorezniZakoni from './PorezniZakoni';
import CijeneOglasavanje from './CijeneOglasavanje';
import ReccuringTroskovi from './ReccuringTroskovi';
import endpoints from '../../environments/endpoints';
import icons from '../../utils/DefinedIcons';
import localization from '../../locales/localization';
import * as FirmaDataAccess from '../../data_access/FirmaDataAccess';
import * as PorezniZakoniDataAccess from '../../data_access/PorezniZakoniDataAccess';
import * as FlagsDataAccess from '../../data_access/FlagsDataAccess';
import * as TroskoviDataAccess from '../../data_access/TroskoviDataAccess';
import GrupeOneTimeTroskova from './GrupeOneTimeTroskova';

const menuItems = [
    {
        icon: icons.firma,
        url: endpoints.appEndpoints.postavke + endpoints.appEndpoints.upravljanjeFirmom,
        text: localization.postavke.upravljanjeFirmom
    },
    {
        icon: icons.djelatnici,
        url: endpoints.appEndpoints.postavke + endpoints.appEndpoints.upravljanjeKorisnicima,
        text: localization.postavke.upravljanjeKorisnicima
    },
    {
        icon: icons.postotci,
        url: endpoints.appEndpoints.postavke + endpoints.appEndpoints.upravljanjePostotcima,
        text: localization.postavke.upravljanjePostotcima
    },
    {
        icon: icons.kartice,
        url: endpoints.appEndpoints.postavke + endpoints.appEndpoints.kartice,
        text: localization.postavke.kartice
    },
    {
        icon: icons.kategorije,
        url: endpoints.appEndpoints.postavke + endpoints.appEndpoints.kategorije,
        text: localization.postavke.kategorije
    },
    {
        icon: icons.mjerneJedinice,
        url: endpoints.appEndpoints.postavke + endpoints.appEndpoints.mjerneJedinice,
        text: localization.postavke.mjerneJedinice
    },
    {
        icon: icons.porezniZakoni,
        url: endpoints.appEndpoints.postavke + endpoints.appEndpoints.porezniZakoni,
        text: localization.postavke.porezniZakoni
    },
    {
        icon: icons.cijeneOglasavanje,
        url: endpoints.appEndpoints.postavke + endpoints.appEndpoints.cijeneOglasavanje,
        text: localization.postavke.cijeneIoglasavanje
    },
    {
        icon: icons.grupeJednokratnihTroskova,
        url: endpoints.appEndpoints.postavke + endpoints.appEndpoints.grupeJednokratnihTroskova,
        text: localization.postavke.grupeJednokratnihTroskova
    },
    {
        icon: icons.ponavljajuciTroskovi,
        url: endpoints.appEndpoints.postavke + endpoints.appEndpoints.ponavljajuciTroskovi,
        text: localization.postavke.ponavljajuciTroskovi
    }
];

class Postavke extends React.Component {
    constructor(props) {
        super(props);
        if (!this.props.firmaCache) {
            this.props.firmaDataAccess.getFirma();
        }
        if (!this.props.poreziCache) {
            this.props.porezniZakoniDataAccess.getPorezniZakoni();
        }
        if (!this.props.flagsCache) {
            this.props.flagsDataAccess.getFlags();
        }
        if (!this.props.cachedReccuringDefinitions) {
            this.props.troskoviDataAccess.getAllReccuringTroskoviDefinitions();
        }
        if (!this.props.cachedOneTimeGrupe) {
            this.props.troskoviDataAccess.getOneTimeGrupe();
        }
    }

    render() {
        let root = endpoints.appEndpoints.postavke;
        return (
            <Switch>
                <Route
                    exact
                    path={root}
                    render={() => {
                        return <Menu menuItems={menuItems} history={this.props.history} />;
                    }}
                />
                <Route path={root + endpoints.appEndpoints.upravljanjeFirmom} component={UpravljanjeFirmom} />
                <Route path={root + endpoints.appEndpoints.upravljanjeKorisnicima} component={UpravljanjeKorisnicima} />
                <Route path={root + endpoints.appEndpoints.upravljanjePostotcima} component={UpravljanjePostotcima} />
                <Route path={root + endpoints.appEndpoints.kartice} component={Kartice} />
                <Route path={root + endpoints.appEndpoints.kategorije} component={Kategorije} />
                <Route path={root + endpoints.appEndpoints.mjerneJedinice} component={MjerneJedinice} />
                <Route path={root + endpoints.appEndpoints.porezniZakoni} component={PorezniZakoni} />
                <Route path={root + endpoints.appEndpoints.cijeneOglasavanje} component={CijeneOglasavanje} />
                <Route path={root + endpoints.appEndpoints.grupeJednokratnihTroskova} component={GrupeOneTimeTroskova} />
                <Route path={root + endpoints.appEndpoints.ponavljajuciTroskovi} component={ReccuringTroskovi} />
            </Switch>
        );
    }
}

Postavke.propTypes = {
    history: PropTypes.object.isRequired,
    firmaDataAccess: PropTypes.object.isRequired,
    firmaCache: PropTypes.bool.isRequired,
    porezniZakoniDataAccess: PropTypes.object.isRequired,
    poreziCache: PropTypes.bool.isRequired,
    flagsDataAccess: PropTypes.object.isRequired,
    flagsCache: PropTypes.bool.isRequired,
    troskoviDataAccess: PropTypes.object.isRequired,
    cachedReccuringDefinitions: PropTypes.bool.isRequired,
    cachedOneTimeGrupe: PropTypes.bool.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {
        firmaCache: state.firmaReducer.cached,
        poreziCache: state.porezniZakoniReducer.cached,
        flagsCache: state.flagsReducer.cached,
        cachedReccuringDefinitions: state.troskoviReducer.cachedReccuringDefinitions,
        cachedOneTimeGrupe: state.troskoviReducer.cachedOneTimeGrupe
    };
};

let mapDispatchToProps = function(dispatch) {
    return {
        firmaDataAccess: bindActionCreators(FirmaDataAccess, dispatch),
        porezniZakoniDataAccess: bindActionCreators(PorezniZakoniDataAccess, dispatch),
        flagsDataAccess: bindActionCreators(FlagsDataAccess, dispatch),
        troskoviDataAccess: bindActionCreators(TroskoviDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Postavke);
