export function getStanjeBlagajni(stanje) {
    return { type: 'GET_STANJE_BLAGAJNI', stanje };
}

export function getBlagajnickiDnevnikFisk(blagajnickiDnevnik) {
    return { type: 'GET_B_D_FISK', blagajnickiDnevnik };
}

export function getBlagajnickiDnevnikOtkup(blagajnickiDnevnik) {
    return { type: 'GET_B_D_OTKUP', blagajnickiDnevnik };
}
