import React from 'react';
import { Table } from 'semantic-ui-react';
import localization from '../../../../locales/localization';

export default class KlijentovPredmetHeaderCells extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Table.HeaderCell>{localization.artikl.kategorija}</Table.HeaderCell>
                <Table.HeaderCell textAlign="center" collapsing>
                    {localization.common.kolicina}
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center" collapsing>
                    {localization.radniNalog.jedinicnaVrijednost}
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center" collapsing>
                    {localization.radniNalog.vrijednost}
                </Table.HeaderCell>
            </React.Fragment>
        );
    }
}
