import $ from 'jquery';

import * as success from '../actions/KarticeActions';

import endpoint from '../environments/endpoints';
import { showAlert } from '../utils/ErrorHandler';

export function getKartice(onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'kartice'
        })
            .done(data => {
                dispatch(success.getKartice(data));
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function createKartica(kartica, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'kartice',
            data: JSON.stringify(kartica)
        })
            .done(data => {
                dispatch(getKartice());
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function updateKartica(id, kartica, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'kartice/' + id,
            data: JSON.stringify(kartica)
        })
            .done(data => {
                dispatch(getKartice());
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function deleteKartica(id, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'kartice/' + id
        })
            .done(data => {
                dispatch(getKartice());
            })
            .fail(error => {
                showAlert(error);
            });
    };
}
