import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Button, Dimmer, Grid, Header, Icon, Loader, Modal, Pagination, Search, Segment, Select, Table } from 'semantic-ui-react';

import localization from '../../locales/localization';
import enums from '../../utils/Enums';
import icons from '../../utils/DefinedIcons';
import SearchResultsMessage from '../common_components/SearchResultsMessage';
import Klijent from '../common_components/Klijent';
import * as KlijentiDataAccess from '../../data_access/KlijentiDataAccess';
import * as KlijentiApi from '../../data_access/KlijentiApi';
import { bindActionCreators } from 'redux';
import { formatNumber } from '../../utils/PhoneNumberFormatter';
import FilterSortButton from '../common_components/FilterSortButton';

// TODO #pagination #refactor Extract this to some other place when pagination for other components is implemented
//      and we solve the Redux/DataAccess dilemma.
const MAX_API_FETCH_ATTEMPTS = 3;
// TODO #pagination #refactor Probably extract somewhere else and use across all search components
const SEARCH_DEBOUNCE_RATE_MS = 1000;

const ITEMS_PER_PAGE = 20;

const styles = {
    paginationContainer: {
        textAlign: 'center'
    },
    oprezRow: {
        color: 'red'
    }
};

const FILTER = [
    {
        name: localization.klijenti.sviKlijenti,
        apiParam: KlijentiApi.FILTER.all
    },
    {
        name: localization.klijenti.fizickeOsobe,
        apiParam: KlijentiApi.FILTER.fizicka
    },
    {
        name: localization.klijenti.pravneOsobe,
        apiParam: KlijentiApi.FILTER.pravna
    },
    {
        name: localization.klijenti.klijentiSNapomenomOpreza,
        apiParam: KlijentiApi.FILTER.sNapomenomOpreza
    }
];

const SORT = [
    {
        name: localization.klijent.prezime + ', ' + localization.klijent.ime + ' / ' + localization.klijent.naziv,
        apiParam: KlijentiApi.SORT.naziv
    },
    {
        name: localization.klijent.drzava,
        apiParam: KlijentiApi.SORT.drzava
    }
];

const LIST_STATE = {
    initial: 'initial',
    empty: 'empty',
    noResult: 'noResult',
    populated: 'populated',
    error: 'error'
};

class Klijenti extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            newClient: false,
            editClient: undefined,
            loader: false,
            filter: 0,
            sort: 0
        };
        this.showNewClient = this.showNewClient.bind(this);
        this.hideNewClient = this.hideNewClient.bind(this);
        this.showEditClient = this.showEditClient.bind(this);
        this.hideEditClient = this.hideEditClient.bind(this);
        this.showLoader = this.showLoader.bind(this);
        this.hideLoader = this.hideLoader.bind(this);
        this.onNoviKlijent = this.onNoviKlijent.bind(this);
        this.onEditClientSave = this.onEditClientSave.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
        this.applyFilterAndSort = this.applyFilterAndSort.bind(this);
        this.resetSearchComponent = this.resetSearchComponent.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.fetchKlijenti = this.fetchKlijenti.bind(this);
        this.refreshKlijenti = this.refreshKlijenti.bind(this);
        this.renderInitialComponent = this.renderInitialComponent.bind(this);
        this.renderEmptyComponent = this.renderEmptyComponent.bind(this);
        this.renderNoResultComponent = this.renderNoResultComponent.bind(this);
        this.renderErrorComponent = this.renderErrorComponent.bind(this);
        this.renderListComponent = this.renderListComponent.bind(this);
        this.debouncedFetchKlijenti = _.debounce(function (filter, sort, page) {
            this.fetchKlijenti(filter, sort, page);
        }, SEARCH_DEBOUNCE_RATE_MS).bind(this);
    }

    componentWillMount() {
        this.resetSearchComponent();
    }

    showNewClient() {
        this.setState({
            newClient: true
        });
    }

    hideNewClient(callback) {
        this.setState({
            newClient: false
        }, callback);
    }

    showEditClient(klijentId) {
        this.setState({
            editClient: klijentId
        });
    }

    hideEditClient(callback) {
        this.setState({
            editClient: undefined
        }, callback);
    }

    showLoader() {
        this.setState({
            loader: true
        });
    }

    hideLoader(callback) {
        this.setState({
            loader: false
        }, callback);
    }

    onNoviKlijent(klijent) {
        this.showLoader();
        this.props.klijentiDataAccess.createKlijent(
            klijent,
            () => this.hideLoader(() => this.hideNewClient(() => this.refreshKlijenti())),
            () => this.hideLoader()
        );
    }

    onEditClientSave(id, klijent) {
        this.showLoader();
        this.props.klijentiDataAccess.updateKlijent(
            id, klijent,
            () => this.hideLoader(() => this.hideEditClient(() => this.refreshKlijenti())),
            () => this.hideLoader()
        );
    }

    onChangePage(e, { activePage }) {
        this.fetchKlijenti(this.state.filter, this.state.sort, activePage);
    }

    applyFilterAndSort(filter, sort) {
        this.fetchKlijenti(filter, sort);
    }

    resetSearchComponent() {
        this.setState({
            listState: LIST_STATE.initial,
            fetchAttempts: 0
        }, () => {
            this.fetchKlijenti();
        });
    }

    handleSearchChange(e, { value }) {
        this.setState({
            value: value
        }, () => {
            this.debouncedFetchKlijenti();
        });
    }

    fetchKlijenti(filter = this.state.filter, sort = this.state.sort, page = 1) {

        let nextStateCommon = {
            loader: false,
            fetchAttempts: 0,
            sort: sort,
            filter: filter
        };

        let onFetchSuccess = (data) => {
            if (data.items.length !== 0) {
                let nextStateNonEmpty = {
                    listState: LIST_STATE.populated,
                    klijenti: data.items,
                    pages: data.totalPages,
                    pageActive: page
                };
                // TODO #pagination @Kec @Vranjes Why doesn't the spread operator work here? JS Version too old?
                //      Used the Object.assign quickfix: https://techstrology.com/spread-operator-unexpected-token-javascript
                this.setState(Object.assign({}, nextStateCommon, nextStateNonEmpty));

            } else {

                let noFilteredApplied = FILTER[filter].apiParam === KlijentiApi.FILTER.all;
                let queryEmpty = this.state.value === '';
                let fetchedAll = noFilteredApplied && queryEmpty;

                let newListState = fetchedAll ? LIST_STATE.empty : LIST_STATE.noResult;
                let nextStateEmpty = {
                    listState: newListState,
                    klijenti: []
                };

                this.setState(Object.assign({}, nextStateCommon, nextStateEmpty));
            }
        };

        let onFetchError = () => {
            let fetchAttempts = this.state.fetchAttempts + 1;
            if (fetchAttempts === MAX_API_FETCH_ATTEMPTS) {
                let nextStateError = {
                    listState: LIST_STATE.error
                };
                this.setState(Object.assign({}, nextStateCommon, nextStateError));
            } else {
                this.setState({
                    fetchAttempts: fetchAttempts
                }, () => {
                    this.fetchKlijenti(filter, sort, page);
                });
            }
        };

        this.showLoader();

        KlijentiApi.getKlijentiPaginated(
            this.state.value, page, ITEMS_PER_PAGE, SORT[sort].apiParam, FILTER[filter].apiParam,
            onFetchSuccess, onFetchError
        );
    }

    refreshKlijenti() {
        this.fetchKlijenti(this.state.filter, this.state.sort, this.state.pageActive);
    }

    renderInitialComponent() {
        return (
            <SearchResultsMessage
                icon={icons.klijenti}
                header={localization.common.ucitavanje}
                content={localization.common.molimoPricekajte}
            />
        );
    }

    renderEmptyComponent() {
        return (
            <SearchResultsMessage
                icon={icons.klijenti}
                header={localization.klijenti.nemaKlijenata}
                content={undefined}
            />
        );
    }

    renderNoResultComponent() {
        return (
            <SearchResultsMessage
                icon={icons.klijenti}
                header={localization.common.unesenaPretragaNemaRezultata}
                content={localization.common.pokusajDrugacijuPretragu}
            />
        );
    }

    renderErrorComponent() {
        return (
            <SearchResultsMessage
                icon={icons.klijenti}
                header={localization.common.dogodilaSeGreska}
                content={localization.common.pokusajtePonovno}
            />
        );
    }

    renderListComponent() {
        return (
            <React.Fragment>
                <Grid.Row>
                    <Grid.Column verticalAlign="middle">
                        <Table stackable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>
                                        {localization.klijent.prezime + ', ' + localization.klijent.ime + ' / ' + localization.klijent.naziv}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>{localization.klijent.tipKlijenta}</Table.HeaderCell>
                                    <Table.HeaderCell>{localization.klijent.identifikacijskiBroj}</Table.HeaderCell>
                                    <Table.HeaderCell>{localization.klijent.adresa}</Table.HeaderCell>
                                    <Table.HeaderCell>{localization.klijent.drzava}</Table.HeaderCell>
                                    <Table.HeaderCell>{localization.klijent.kontaktBroj}</Table.HeaderCell>
                                    <Table.HeaderCell>{localization.klijent.kontaktEmail}</Table.HeaderCell>
                                    <Table.HeaderCell>{localization.klijent.kontaktOsoba}</Table.HeaderCell>
                                    <Table.HeaderCell />
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.state.klijenti.map(klijent => {
                                    return (
                                        <Table.Row key={klijent.id} style={klijent.napomenaOpreza ? styles.oprezRow : undefined}>
                                            {klijent.tipKlijenta === enums.tipKlijenta.FIZICKA_OSOBA ? (
                                                <React.Fragment>
                                                    <Table.Cell>{klijent.prezime + ', ' + klijent.ime}</Table.Cell>
                                                    <Table.Cell>{localization.klijent.fizickaOsoba}</Table.Cell>
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <Table.Cell>{klijent.naziv}</Table.Cell>
                                                    <Table.Cell>{localization.klijent.pravnaOsoba}</Table.Cell>
                                                </React.Fragment>
                                            )}
                                            {klijent.idBrojTip === enums.idBrojTip.OIB ? (
                                                <Table.Cell>
                                                    <b>{localization.klijent.oib + ': '}</b>
                                                    {klijent.idBroj}
                                                </Table.Cell>
                                            ) : klijent.idBrojTip === enums.idBrojTip.BR_OSOBNE ? (
                                                <Table.Cell>
                                                    <b>{localization.klijent.brojOsobne + ': '}</b>
                                                    {klijent.idBroj}
                                                </Table.Cell>
                                            ) : (
                                                <Table.Cell>
                                                    <b>{localization.klijent.brojPutovnice + ': '}</b>
                                                    {klijent.idBroj}
                                                </Table.Cell>
                                            )}
                                            <Table.Cell>{klijent.adresa || '-'}</Table.Cell>
                                            <Table.Cell>{klijent.drzava}</Table.Cell>
                                            <Table.Cell>{klijent.kontaktBroj ? formatNumber(klijent.kontaktBroj) : '-'}</Table.Cell>
                                            <Table.Cell>{klijent.kontaktEmail || '-'}</Table.Cell>
                                            <Table.Cell>{klijent.kontaktOsoba || '-'}</Table.Cell>
                                            <Table.Cell collapsing>
                                                <Button.Group basic size="small">
                                                    <Modal
                                                        open={this.state.editClient === klijent.id}
                                                        closeOnDimmerClick={false}
                                                        trigger={
                                                            <Button icon="write" onClick={this.showEditClient.bind(this, klijent.id)} />
                                                        }
                                                    >
                                                        <Modal.Header>{localization.common.urediKlijenta}</Modal.Header>
                                                        <Modal.Content>
                                                            <Klijent
                                                                client={klijent}
                                                                clientType={klijent.tipKlijenta}
                                                                onSave={this.onEditClientSave.bind(this, klijent.id)}
                                                                onCancel={() => this.hideEditClient()}
                                                            />
                                                        </Modal.Content>
                                                    </Modal>
                                                </Button.Group>
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </Table>
                        <div style={styles.paginationContainer}>
                            <Pagination
                                activePage={this.state.pageActive}
                                totalPages={this.state.pages}
                                onPageChange={this.onChangePage}
                                ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
                                firstItem={{ content: <Icon name="angle double left" />, icon: true }}
                                lastItem={{ content: <Icon name="angle double right" />, icon: true }}
                                prevItem={{ content: <Icon name="angle left" />, icon: true }}
                                nextItem={{ content: <Icon name="angle right" />, icon: true }}
                            />
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </React.Fragment>
        );
    }

    render() {
        return (
            <div>
                <Segment tertiary>
                    <Grid stackable>
                        <Grid.Row columns={6}>
                            <Grid.Column verticalAlign="middle">
                                <Header as="h2">{localization.klijenti.klijenti}</Header>
                            </Grid.Column>
                            <Grid.Column verticalAlign="middle">
                                <Search
                                    input={{ fluid: true }}
                                    onSearchChange={this.handleSearchChange}
                                    value={this.state.value}
                                    open={false}
                                />
                            </Grid.Column>
                            <Grid.Column />
                            <Grid.Column />
                            <Grid.Column textAlign="right" verticalAlign="middle">
                                <FilterSortButton
                                    filteringOptions={FILTER.map((f) => f.name)}
                                    sortingOptions={SORT.map((s) => s.name)}
                                    activeFilter={this.state.filter}
                                    activeSort={this.state.sort}
                                    callback={this.applyFilterAndSort}
                                />
                            </Grid.Column>
                            <Grid.Column textAlign="right" verticalAlign="middle">
                                <Modal
                                    open={this.state.newClient}
                                    closeOnDimmerClick={false}
                                    trigger={
                                        <Button
                                            icon="asterisk"
                                            basic
                                            color="black"
                                            content={localization.klijenti.noviKlijent}
                                            onClick={this.showNewClient}
                                        />
                                    }
                                >
                                    <Modal.Header>{localization.klijenti.noviKlijent}</Modal.Header>
                                    <Modal.Content>
                                        <Klijent client={{}} onSave={this.onNoviKlijent} onCancel={() => this.hideNewClient()} />
                                    </Modal.Content>
                                </Modal>
                            </Grid.Column>
                        </Grid.Row>
                        {(() => {
                            switch (this.state.listState) {
                                case LIST_STATE.initial:
                                    return this.renderInitialComponent();
                                case LIST_STATE.populated:
                                    return this.renderListComponent();
                                case LIST_STATE.empty:
                                    return this.renderEmptyComponent();
                                case LIST_STATE.noResult:
                                    return this.renderNoResultComponent();
                                case LIST_STATE.error:
                                    return this.renderErrorComponent();
                            }
                        })()}
                    </Grid>
                </Segment>
                <Dimmer active={this.state.loader} page>
                    <Loader />
                </Dimmer>
            </div>
        );
    }
}

// TODO #pagination These are some redux thingys that has to be dealt with

Klijenti.propTypes = {
    klijentiDataAccess: PropTypes.object.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {
    };
};

let mapDispatchToProps = function(dispatch) {
    return {
        klijentiDataAccess: bindActionCreators(KlijentiDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Klijenti);
