let initialState = {
    cachedStanje: false,
    stanje: {},
    blagajnickiDnevnikFisk: {},
    blagajnickiDnevnikOtkup: {}
};

export default function blagajneReducer(state = initialState, action) {
    switch (action.type) {
        case 'GET_STANJE_BLAGAJNI': {
            return Object.assign({}, state, {
                stanje: action.stanje,
                cachedStanje: true
            });
        }
        case 'GET_B_D_FISK': {
            return Object.assign({}, state, {
                blagajnickiDnevnikFisk: Object.assign(
                    {},
                    {
                        pocetnoStanje: action.blagajnickiDnevnik.pocetnoStanje,
                        zavrsnoStanje: action.blagajnickiDnevnik.zavrsnoStanje,
                        stavke: [...action.blagajnickiDnevnik.stavke],
                        firma: action.blagajnickiDnevnik.firma
                    }
                )
            });
        }
        case 'GET_B_D_OTKUP': {
            return Object.assign({}, state, {
                blagajnickiDnevnikOtkup: Object.assign(
                    {},
                    {
                        pocetnoStanje: action.blagajnickiDnevnik.pocetnoStanje,
                        zavrsnoStanje: action.blagajnickiDnevnik.zavrsnoStanje,
                        pocetnoStanjeHrk: action.blagajnickiDnevnik.pocetnoStanjeHrk,
                        zavrsnoStanjeHrk: action.blagajnickiDnevnik.zavrsnoStanjeHrk,
                        stavke: [...action.blagajnickiDnevnik.stavke],
                        firma: action.blagajnickiDnevnik.firma
                    }
                )
            });
        }
        default:
            return state;
    }
}
