import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Form } from 'semantic-ui-react';
import enums from '../../utils/Enums';
import localization from '../../locales/localization';

const styles = {
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
};

class Artikl extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            naziv: this.props.item.naziv || '',
            serijskiBroj: this.props.onSave ? this.props.item.serijskiBroj : '',
            kategorija: this.props.item.kategorija ? this.props.item.kategorija.id : this.props.kategorije[0].id,
            potkategorija: this.props.item.potkategorija ? this.props.item.potkategorija.id : undefined,
            mjernaJedinica: this.props.item.mjernaJedinica ? this.props.item.mjernaJedinica.id : this.props.mjerneJedinice[0].id,
            opis: this.props.item.opis || ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeKategorije = this.handleChangeKategorije.bind(this);
        this.preparePredmet = this.preparePredmet.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onSaveAsNewItem = this.onSaveAsNewItem.bind(this);
    }

    handleChange(e, { name, value }) {
        this.setState({ [name]: value });
    }

    handleChangeKategorije(e, { value }) {
        this.setState({
            kategorija: value,
            potkategorija: undefined
        });
    }

    preparePredmet() {
        return {
            naziv: this.state.naziv,
            mjernaJedinicaId: this.state.mjernaJedinica,
            kategorijaId: this.state.kategorija,
            potkategorijaId: this.state.potkategorija || null,
            serijskiBroj: this.state.serijskiBroj || null,
            opis: this.state.opis || null,
            tipArtikla: enums.tipArtikla.PREDMET
        };
    }

    onSave() {
        this.props.onSave(this.preparePredmet());
    }

    onSaveAsNewItem() {
        this.props.saveAsNewItem(this.preparePredmet());
    }

    render() {
        let categories = this.props.kategorije.map(cat => {
            return {
                key: cat.id,
                text: cat.ime,
                value: cat.id
            };
        });
        let subcategories = [];
        if (this.state.kategorija) {
            let cat = this.props.kategorije.find(obj => {
                return obj.id === this.state.kategorija;
            });
            subcategories = cat.potkategorije.map(subcat => {
                return {
                    key: subcat.id,
                    text: subcat.ime,
                    value: subcat.id
                };
            });
        }
        let mjerneJedinice = this.props.mjerneJedinice.map(mj => {
            return {
                key: mj.id,
                text: mj.naziv,
                value: mj.id
            };
        });
        return (
            <Form>
                <Form.Input
                    fluid
                    label={localization.artikl.naziv}
                    placeholder={localization.artikl.naziv}
                    name="naziv"
                    onChange={this.handleChange}
                    value={this.state.naziv}
                    required
                />
                <Form.Input
                    fluid
                    label={localization.artikl.serijskiBroj}
                    placeholder={localization.artikl.serijskiBrojPlaceholder}
                    name="serijskiBroj"
                    onChange={this.handleChange}
                    value={this.state.serijskiBroj}
                />
                <Form.Group widths="equal">
                    <Form.Select
                        fluid
                        label={localization.artikl.kategorija}
                        options={categories}
                        placeholder={localization.artikl.kategorija}
                        name="kategorija"
                        onChange={this.handleChangeKategorije}
                        value={this.state.kategorija}
                        required
                        search
                    />
                    <Form.Select
                        fluid
                        label={localization.artikl.potkategorija}
                        options={subcategories}
                        placeholder={localization.artikl.potkategorija}
                        name="potkategorija"
                        onChange={this.handleChange}
                        value={this.state.potkategorija}
                        disabled={!subcategories.length}
                        required={!!subcategories.length}
                        search
                    />
                    <Form.Select
                        fluid
                        label={localization.artikl.mjernaJedinica}
                        options={mjerneJedinice}
                        placeholder={localization.artikl.mjernaJedinica}
                        name="mjernaJedinica"
                        onChange={this.handleChange}
                        value={this.state.mjernaJedinica}
                        required
                        search
                    />
                </Form.Group>
                <Form.TextArea
                    label={localization.artikl.opis}
                    placeholder={localization.artikl.opis}
                    name="opis"
                    onChange={this.handleChange}
                    value={this.state.opis}
                />
                <Form.Group style={styles.buttons}>
                    <Form.Button icon="cancel" content={localization.common.odustani} onClick={this.props.onCancel} />
                    {this.props.onSave ? <Form.Button icon="save" color="green" content={localization.common.spremi} onClick={this.onSave} /> : null}
                    {this.props.saveAsNewItem ? (
                        <Form.Button icon="save" color="green" content={localization.artikl.spremiKaoNoviArtikl} onClick={this.onSaveAsNewItem} />
                    ) : null}
                </Form.Group>
            </Form>
        );
    }
}

Artikl.propTypes = {
    item: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    kategorije: PropTypes.array.isRequired,
    mjerneJedinice: PropTypes.array.isRequired,
    onSave: PropTypes.func,
    saveAsNewItem: PropTypes.func
};

let mapStateToProps = function(state, ownProps) {
    return {
        kategorije: state.kategorijeReducer.kategorije || [],
        mjerneJedinice: state.mjerneJediniceReducer.mjerneJedinice || []
    };
};

let mapDispatchToProps = function(dispatch) {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Artikl);
