import $ from 'jquery';

import { resetStanjeCache } from '../actions/SkladisteActions';

import endpoint from '../environments/endpoints';
import { showAlert } from '../utils/ErrorHandler';

export function createOtkup(otkup, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'otkupi',
            data: JSON.stringify(otkup)
        })
            .done(data => {
                dispatch(resetStanjeCache());
                onSuccess(data);
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function createPrimka(primka, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'otkupi',
            data: JSON.stringify(primka)
        })
            .done(data => {
                dispatch(resetStanjeCache());
                onSuccess(data);
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function dodavanjeNesluzbeneNapomeneNaOtkup(otkupId, napomena, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'otkupi/' + otkupId + '/nesluzbene-napomene',
            data: napomena
        })
            .done(data => {
                onSuccess();
            })
            .fail(error => {
                showAlert(error);
                onError();
            });
    };
}

export function dodavanjeNesluzbeneNapomeneNaPrimku(primkaId, napomena, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'otkupi/' + primkaId + '/nesluzbene-napomene',
            data: napomena
        })
            .done(data => {
                onSuccess();
            })
            .fail(error => {
                showAlert(error);
                onError();
            });
    };
}

// export function updateIsInKnjigovodstvoFlag(flagData, onSuccess, onError) {
//     return function (dispatch) {
//         $.ajax({
//             method: 'PUT',
//             headers: {
//                 'Accept': 'application/json',
//                 'Content-Type': 'application/json',
//                 'Authorization': 'Bearer ' + sessionStorage.token
//             },
//             url: endpoint.apiEndpoint + 'otkupi/otkupni-blokovi/in-knjigovodstvo-flag',
//             data: JSON.stringify(flagData)
//         }).done((data) => {

//         }).fail((error) => {

//         });
//     };
// }

export function updateIsPrintanFlag(flagData, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'otkupi/otkupni-blokovi/print-flag',
            data: JSON.stringify(flagData)
        })
            .done(data => {
                onSuccess(data);
            })
            .fail(error => {
                showAlert(error);
                onError();
            });
    };
}

// export function urediOtkupniBlok(otkupId, noveCijene, onSuccess, onError) {
//     return function (dispatch) {
//         $.ajax({
//             method: 'PUT',
//             headers: {
//                 'Accept': 'application/json',
//                 'Content-Type': 'application/json',
//                 'Authorization': 'Bearer ' + sessionStorage.token
//             },
//             url: endpoint.apiEndpoint + 'otkupi/' + otkupId + '/nabavne-cijene',
//             data: JSON.stringify(noveCijene)
//         }).done((data) => {
//             dispatch(getOtkupniBlokovi());
//             onSuccess();
//         }).fail((error) => {
//             showAlert(error);
//         });
//     };
// }

export function stornoOtkupa(otkupId, napomena, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'otkupi/' + otkupId + '/storniraj',
            data: JSON.stringify(napomena)
        })
            .done(data => {
                dispatch(resetStanjeCache());
                onSuccess();
            })
            .fail(error => {
                showAlert(error);
                onError();
            });
    };
}

export function stornoPrimke(primkaId, napomena, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'otkupi/' + primkaId + '/storniraj',
            data: JSON.stringify(napomena)
        })
            .done(data => {
                dispatch(resetStanjeCache());
                onSuccess();
            })
            .fail(error => {
                showAlert(error);
                onError();
            });
    };
}
