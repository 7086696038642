import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Dimmer, Header, Loader, Modal, Segment } from 'semantic-ui-react';

import localization from '../../locales/localization';
import endpoints from '../../environments/endpoints';
import enums from '../../utils/Enums';
import icons from '../../utils/DefinedIcons';
import { storeDocumentAndGenerateKey } from '../../utils/PrintingDocumentsHandler';
import * as ArtikliApi from '../../data_access/ArtikliApi';
import * as OtkupiDataAccess from '../../data_access/OtkupiDataAccess';
import PredlaganjeProdajnihCijenaModal from '../common_components/PredlaganjeProdajnihCijenaModal';
import { bindActionCreators } from 'redux';
import OdabirKlijenta from '../common_components/OdabirKlijenta';
import OdabirArtiklaPaginated from '../common_components/OdabirArtiklaPaginated';
import Napomene from '../common_components/Napomene';
import RequiredText from '../common_components/RequiredText';

const styles = {
    confirmButton: {
        textAlign: 'center',
        marginBottom: 16
    }
};

class NoviOtkup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            client: undefined,
            items: [],
            sluzbenaNapomena: '',
            nesluzbenaNapomena: '',
            napraviOtkupEnabled: false,
            nedovoljnoBlagajnaOtkupa: false,
            potvrdaOtkupa: false,
            prijedlogProdajnihCijena: false,
            loader: false,
            otkupniBlok: undefined
        };
        this.handleOdabirKlijenta = this.handleOdabirKlijenta.bind(this);
        this.handleOdabirArtikla = this.handleOdabirArtikla.bind(this);
        this.enableNapraviOtkupButton = this.enableNapraviOtkupButton.bind(this);
        this.onChangeNapomena = this.onChangeNapomena.bind(this);
        this.calculateUkupno = this.calculateUkupno.bind(this);
        this.checkKomponenteOtkupa = this.checkKomponenteOtkupa.bind(this);
        this.showHidePotvrdaOtkupa = this.showHidePotvrdaOtkupa.bind(this);
        this.showLoader = this.showLoader.bind(this);
        this.hideLoader = this.hideLoader.bind(this);
        this.napraviOtkup = this.napraviOtkup.bind(this);
        this.openUgovorOtkupa = this.openUgovorOtkupa.bind(this);
        this.redirect = this.redirect.bind(this);
        this.fetchPredmeti = this.fetchPredmeti.bind(this);
    }

    handleOdabirKlijenta(clientSelected) {
        this.setState({ client: clientSelected }, this.enableNapraviOtkupButton);
    }

    handleOdabirArtikla(itemsSelected) {
        this.setState({ items: [...itemsSelected] }, this.enableNapraviOtkupButton);
    }

    onChangeNapomena(e, { name, value }) {
        this.setState({ [name]: value });
    }

    calculateUkupno() {
        let ukupno = 0,
            missingData = false;
        this.state.items.forEach(item => {
            if (item.kolicina && item.cijena) {
                ukupno += item.kolicina * item.cijena;
            } else {
                missingData = true;
            }
        });
        return [ukupno, missingData];
    }

    enableNapraviOtkupButton() {
        let [ukupno, missingData] = this.calculateUkupno();
        this.setState({
            nedovoljnoBlagajnaOtkupa: !missingData && ukupno > this.props.stanjeBlagajni.stanjeBlagOtkupa,
            napraviOtkupEnabled: this.checkKomponenteOtkupa()
        });
    }

    checkKomponenteOtkupa() {
        if (!this.state.client) {
            return false;
        }
        if (!this.state.items.length) {
            return false;
        } else {
            for (let i = 0; i < this.state.items.length; i++) {
                if (!this.state.items[i].kolicina || !this.state.items[i].cijena) {
                    return false;
                }
            }
        }
        return true;
    }

    showHidePotvrdaOtkupa(value, callback) {
        if (typeof callback === 'function') {
            this.setState({ potvrdaOtkupa: value }, callback);
        } else {
            this.setState({ potvrdaOtkupa: value });
        }
    }

    showLoader(callback) {
        this.setState(
            {
                loader: true
            },
            callback
        );
    }

    hideLoader(callback, data) {
        this.setState(
            {
                loader: false
            },
            callback.bind(this, data)
        );
    }

    napraviOtkup() {
        let otkup = {
            tip: enums.tipSkladisnogUlaznogBloka.OTKUPNI_BLOK,
            fizickaOsobaId: this.state.client.id,
            poslovnicaId: JSON.parse(sessionStorage.poslovnicaId),
            sluzbenaNapomena: this.state.sluzbenaNapomena || null,
            nesluzbenaNapomena: this.state.nesluzbenaNapomena || null,
            ulazi: this.state.items.map(artikl => {
                return {
                    predmetId: artikl.id,
                    kolicina: parseFloat(artikl.kolicina),
                    jedNabavnaCijena: parseFloat(artikl.cijena),
                    napomena: artikl.napomena || null
                };
            })
        };
        this.props.otkupiDataAccess.createOtkup(otkup, this.hideLoader.bind(this, this.openUgovorOtkupa));
    }

    openUgovorOtkupa(otkupniBlok) {
        window.open(endpoints.appEndpoints.print.ugovorOtkupa + storeDocumentAndGenerateKey(otkupniBlok), '_blank');
        this.setState({
            prijedlogProdajnihCijena: true,
            otkupniBlok: otkupniBlok
        });
    }

    redirect() {
        this.props.history.goBack();
    }

    fetchPredmeti(q, page, pageSize, onFetchSuccess, onFetchError) {
        ArtikliApi.getArtikliPaginated(
            q, page, pageSize, ArtikliApi.SORT.naziv, ArtikliApi.TIP_ARTIKLA.predmet,
            onFetchSuccess, onFetchError
        )
    }

    render() {
        return (
            <div>
                <Segment tertiary>
                    <Header as="h2">{localization.otkup.noviOtkup}</Header>
                </Segment>
                <Segment tertiary>
                    <OdabirKlijenta
                        onClientSelected={this.handleOdabirKlijenta}
                        allowedClientType={enums.tipKlijenta.FIZICKA_OSOBA}
                    />
                </Segment>
                <Segment tertiary>
                    <OdabirArtiklaPaginated fetchArtikli={this.fetchPredmeti} onItemSelected={this.handleOdabirArtikla} type={enums.tipTabliceArtikala.OTKUP_EDITABLE} />
                </Segment>
                <Napomene
                    sluzbenaNapomenaHeader={localization.otkup.sluzbenaNapomenaOtkupa}
                    nesluzbenaNapomenaHeader={localization.otkup.nesluzbenaNapomenaOtkupa}
                    onChangeNapomena={this.onChangeNapomena}
                />
                <div style={styles.confirmButton}>
                    <Modal
                        open={this.state.potvrdaOtkupa}
                        closeOnDimmerClick={false}
                        trigger={
                            <Button
                                color="green"
                                content={localization.otkup.napraviOtkup}
                                disabled={!this.state.napraviOtkupEnabled || this.state.nedovoljnoBlagajnaOtkupa}
                                onClick={this.showHidePotvrdaOtkupa.bind(this, true)}
                            />
                        }
                    >
                        {!this.state.prijedlogProdajnihCijena ? (
                            <React.Fragment>
                                <Header icon={icons.otkup} content={localization.otkup.potvrdaOtkupa} />
                                <Modal.Content>
                                    <p>{localization.otkup.pitanjePotvrdeOtkupa}</p>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button icon="remove" content={localization.common.odustani} onClick={this.showHidePotvrdaOtkupa.bind(this, false)} />
                                    <Button
                                        icon="checkmark"
                                        color="green"
                                        content={localization.otkup.napraviOtkup}
                                        onClick={this.showLoader.bind(this, this.napraviOtkup)}
                                    />
                                </Modal.Actions>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Header icon={icons.otkup} content={localization.otkup.predlaganjeProdajnihCijena} />
                                <Modal.Content>
                                    <PredlaganjeProdajnihCijenaModal
                                        ulazi={this.state.otkupniBlok.skladisniUlazi}
                                        closeModal={this.showHidePotvrdaOtkupa.bind(this, false, this.redirect)}
                                    />
                                </Modal.Content>
                            </React.Fragment>
                        )}
                    </Modal>
                </div>
                {this.state.nedovoljnoBlagajnaOtkupa ? (
                    <div style={styles.confirmButton}>
                        <RequiredText requiredText={localization.common.nedovoljnoNovcaBlagajnaOtkupa} />
                    </div>
                ) : null}
                <Dimmer active={this.state.loader} page>
                    <Loader />
                </Dimmer>
            </div>
        );
    }
}

NoviOtkup.propTypes = {
    history: PropTypes.object.isRequired,
    stanjeBlagajni: PropTypes.object.isRequired,
    otkupiDataAccess: PropTypes.object.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {
        stanjeBlagajni: state.blagajneReducer.stanje
    };
};

let mapDispatchToProps = function(dispatch) {
    return {
        otkupiDataAccess: bindActionCreators(OtkupiDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NoviOtkup);
