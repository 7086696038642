import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Button, Dimmer, Grid, Header, Loader, Modal, Segment } from 'semantic-ui-react';

import localization from '../../locales/localization';
import endpoints from '../../environments/endpoints';
import { storeDocumentAndGenerateKey } from '../../utils/PrintingDocumentsHandler';
import { bindActionCreators } from 'redux';
import OdabirKlijenta from '../common_components/OdabirKlijenta';
import OdabirArtiklaPaginated from '../common_components/OdabirArtiklaPaginated';
import enums from '../../utils/Enums';
import icons from '../../utils/DefinedIcons';
import Napomene from '../common_components/Napomene';
import InputField from '../common_components/InputField';
import ImageUploadModal from '../common_components/ImageUploadModal';
import RequiredText from '../common_components/RequiredText';
import * as ArtikliApi from '../../data_access/ArtikliApi';
import * as RezervacijaDataAccess from '../../data_access/RezervacijaDataAccess';
import FormatIznosa from '../common_components/FormatIznosa';

const styles = {
    cijenaRezervacijeText: {
        color: 'red'
    },
    confirmButton: {
        textAlign: 'center',
        marginBottom: 16
    }
};

class NovaRezervacija extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            client: undefined,
            items: [],
            defaultCijenaRezervacije: undefined,
            naknade: {},
            cijenaRezervacije: '',
            prvaVecaCijenaRezervacije: undefined,
            prvaManjaCijenaRezervacije: undefined,
            sluzbenaNapomena: '',
            nesluzbenaNapomena: '',
            obveznaNesluzbenaNapomena: false,
            nedovoljnoBlagajnaOtkupa: false,
            napraviRezervacijuEnabled: false,
            potvrdaRezervacije: false,
            loader: false,
            novaRezervacijaId: undefined
        };
        this.handleOdabirKlijenta = this.handleOdabirKlijenta.bind(this);
        this.handleOdabirArtikla = this.handleOdabirArtikla.bind(this);
        this.resetNaknade = this.resetNaknade.bind(this);
        this.calculateCijena = this.calculateCijena.bind(this);
        this.setCijenaRezervacije = this.setCijenaRezervacije.bind(this);
        this.onChangeCijenaRezervacije = this.onChangeCijenaRezervacije.bind(this);
        this.recalculateProdajnaCijena = this.recalculateProdajnaCijena.bind(this);
        this.setProdajnaCijena = this.setProdajnaCijena.bind(this);
        this.setPrvaVecaPrvaManja = this.setPrvaVecaPrvaManja.bind(this);
        this.onChangeNapomena = this.onChangeNapomena.bind(this);
        this.calculateUkupno = this.calculateUkupno.bind(this);
        this.defineCijenaNaknade = this.defineCijenaNaknade.bind(this);
        this.checkObveznuNapomenu = this.checkObveznuNapomenu.bind(this);
        this.enableNapraviRezervacijuButton = this.enableNapraviRezervacijuButton.bind(this);
        this.checkKomponenteRezervacije = this.checkKomponenteRezervacije.bind(this);
        this.showHidePotvrdaRezervacije = this.showHidePotvrdaRezervacije.bind(this);
        this.showLoader = this.showLoader.bind(this);
        this.hideLoader = this.hideLoader.bind(this);
        this.napraviRezervaciju = this.napraviRezervaciju.bind(this);
        this.openDokumenti = this.openDokumenti.bind(this);
        this.redirect = this.redirect.bind(this);
        this.fetchPredmeti = this.fetchPredmeti.bind(this);
    }

    handleOdabirKlijenta(clientSelected) {
        this.setState({ client: clientSelected }, this.enableNapraviRezervacijuButton);
    }

    handleOdabirArtikla(itemsSelected) {
        let resetNaknade = false;
        let diff = _.differenceBy(this.state.items, itemsSelected, 'kolicina');
        if (this.state.items.length !== itemsSelected.length || diff) {
            resetNaknade = true;
        }
        this.setState({ items: itemsSelected.map(i => Object.assign({}, i)) }, resetNaknade ? this.resetNaknade : this.setCijenaRezervacije);
    }

    resetNaknade() {
        this.setState(
            {
                naknade: {}
            },
            this.setCijenaRezervacije
        );
    }

    calculateCijena(cijena, predefiniranaCijenaNaknade) {
        if (predefiniranaCijenaNaknade.tip === enums.tipCijeneRezervacije.FIKSNO) {
            return cijena + predefiniranaCijenaNaknade.vrijednost;
        } else {
            return Math.round(cijena * (1 + predefiniranaCijenaNaknade.vrijednost / 100));
        }
    }

    setCijenaRezervacije() {
        let [ukupno, predefiniranaCijenaNaknade] = this.defineCijenaNaknade();
        let cijena = predefiniranaCijenaNaknade !== undefined ? this.calculateCijena(ukupno, predefiniranaCijenaNaknade) : undefined;
        this.setState(
            {
                cijenaRezervacije: cijena || '',
                defaultCijenaRezervacije: cijena
            },
            this.recalculateProdajnaCijena.bind(this, true)
        );
    }

    onChangeCijenaRezervacije(e, { value }) {
        this.setState(
            {
                cijenaRezervacije: value,
                prvaManjaCijenaRezervacije: undefined,
                prvaVecaCijenaRezervacije: undefined
            },
            this.recalculateProdajnaCijena
        );
    }

    recalculateProdajnaCijena(setCijenaRezervacije) {
        if (this.state.naknade[this.state.cijenaRezervacije]) {
            this.setProdajnaCijena(this.state.naknade[this.state.cijenaRezervacije]);
        } else {
            let ukupnoArr = [],
                ukupno = 0;
            this.state.items.forEach(item => {
                ukupno += item.kolicina * item.cijena;
                ukupnoArr.push(item.kolicina * item.cijena);
            });
            if (!isNaN(ukupno)) {
                let ukupnaNaknada = this.state.cijenaRezervacije - ukupno; //np=pp-u
                if (ukupnaNaknada >= 0) {
                    let jedinicniOmjer = ukupnaNaknada / ukupno;
                    let omjeriArr = ukupnoArr.map(u => u * jedinicniOmjer);
                    let floorNaknada = [],
                        ceilNaknada = [],
                        roundNaknada = [];
                    this.state.items.forEach((item, index) => {
                        let naknada = omjeriArr[index] / item.kolicina;
                        floorNaknada.push(Math.floor(naknada));
                        ceilNaknada.push(Math.ceil(naknada));
                        roundNaknada.push(Math.round(naknada));
                    });

                    let jfloorNaknada = floorNaknada.map((fn, index) => {
                        return this.state.items[index].kolicina * fn;
                    });
                    let jceilNaknada = ceilNaknada.map((cn, index) => {
                        return this.state.items[index].kolicina * cn;
                    });
                    let jroundNaknada = roundNaknada.map((rn, index) => {
                        return this.state.items[index].kolicina * rn;
                    });
                    let floor = ukupno + _.sum(jfloorNaknada);
                    let ceil = ukupno + _.sum(jceilNaknada);
                    let round = ukupno + _.sum(jroundNaknada);

                    this.setState({
                        naknade: Object.assign({}, this.state.naknade, {
                            [floor]: floorNaknada,
                            [ceil]: ceilNaknada,
                            [round]: roundNaknada
                        })
                    });

                    if (floor == this.state.cijenaRezervacije) {
                        this.setProdajnaCijena(floorNaknada);
                    } else if (ceil == this.state.cijenaRezervacije) {
                        this.setProdajnaCijena(ceilNaknada);
                    } else if (round == this.state.cijenaRezervacije) {
                        this.setProdajnaCijena(roundNaknada);
                    } else {
                        if (round > this.state.cijenaRezervacije) {
                            if (setCijenaRezervacije) {
                                this.setState(
                                    {
                                        cijenaRezervacije: round,
                                        defaultCijenaRezervacije: round
                                    },
                                    this.setProdajnaCijena.bind(this, roundNaknada)
                                );
                            } else {
                                this.setPrvaVecaPrvaManja(round, floor);
                            }
                        } else {
                            if (setCijenaRezervacije) {
                                this.setState(
                                    {
                                        cijenaRezervacije: round,
                                        defaultCijenaRezervacije: round
                                    },
                                    this.setProdajnaCijena.bind(this, roundNaknada)
                                );
                            } else {
                                this.setPrvaVecaPrvaManja(ceil, round);
                            }
                        }
                    }
                }
            }
        }
    }

    setProdajnaCijena(arrayNaknada) {
        this.setState(
            {
                items: this.state.items.map((item, index) => {
                    return Object.assign({}, item, {
                        prodajnaCijena: parseFloat(item.cijena) + arrayNaknada[index]
                    });
                })
            },
            this.checkObveznuNapomenu
        );
    }

    setPrvaVecaPrvaManja(veca, manja) {
        this.setState({
            prvaManjaCijenaRezervacije: manja,
            prvaVecaCijenaRezervacije: veca
        });
    }

    onChangeNapomena(e, { name, value }) {
        this.setState({ [name]: value }, this.state.obveznaNesluzbenaNapomena ? this.enableNapraviRezervacijuButton : undefined);
    }

    calculateUkupno() {
        let ukupno = 0,
            missingData = false;
        this.state.items.forEach(item => {
            if (item.kolicina && item.cijena) {
                ukupno += item.kolicina * item.cijena;
            } else {
                missingData = true;
            }
        });
        return [ukupno, missingData];
    }

    defineCijenaNaknade() {
        let [ukupno, missingData] = this.calculateUkupno();
        if (!missingData) {
            let index = this.props.appPrefs.cijeneRezervacijaCalcPrefs.findIndex(c => c.donjaGrCijeneArtikla > ukupno);
            if (index !== -1) {
                index--;
            } else {
                index = this.props.appPrefs.cijeneRezervacijaCalcPrefs.length - 1;
            }
            return [ukupno, this.props.appPrefs.cijeneRezervacijaCalcPrefs[index]];
        } else {
            return [undefined, undefined];
        }
    }

    checkObveznuNapomenu() {
        this.setState(
            {
                obveznaNesluzbenaNapomena: this.state.cijenaRezervacije != this.state.defaultCijenaRezervacije
            },
            this.enableNapraviRezervacijuButton
        );
    }

    enableNapraviRezervacijuButton() {
        let [ukupno, missingData] = this.calculateUkupno();
        this.setState({
            nedovoljnoBlagajnaOtkupa: !missingData && ukupno > this.props.stanjeBlagajni.stanjeBlagOtkupa,
            napraviRezervacijuEnabled: this.checkKomponenteRezervacije()
        });
    }

    checkKomponenteRezervacije() {
        let checkItems = () => {
            if (!this.state.items.length) {
                return false;
            } else {
                for (let i = 0; i < this.state.items.length; i++) {
                    if (!this.state.items[i].kolicina || !this.state.items[i].cijena || !this.state.items[i].prodajnaCijena) {
                        return false;
                    }
                }
            }
            return true;
        };
        if (!this.state.client) {
            return false;
        }
        if (this.state.cijenaRezervacije === '' || this.state.prvaManjaCijenaRezervacije !== undefined || this.state.prvaVecaCijenaRezervacije !== undefined) {
            return false;
        }
        if (this.state.obveznaNesluzbenaNapomena && !this.state.nesluzbenaNapomena) {
            return false;
        }
        if (!checkItems()) {
            return false;
        }
        return true;
    }

    showHidePotvrdaRezervacije(value, callback) {
        if (typeof callback === 'function') {
            this.setState({ potvrdaRezervacije: value }, callback);
        } else {
            this.setState({ potvrdaRezervacije: value });
        }
    }

    showLoader(callback) {
        this.setState(
            {
                loader: true
            },
            callback
        );
    }

    hideLoader(callback, data) {
        this.setState(
            {
                loader: false
            },
            callback.bind(this, data)
        );
    }

    napraviRezervaciju() {
        let zahtjevZaRezervaciju = {
            poslovnicaId: JSON.parse(sessionStorage.poslovnicaId),
            fizickaOsobaId: this.state.client.id,
            sluzbenaNapomena: this.state.sluzbenaNapomena || null,
            nesluzbenaNapomena: this.state.nesluzbenaNapomena || null,
            rezervacije: this.state.items.map(item => {
                return {
                    predmetId: item.id,
                    jedNabavnaCijena: parseFloat(item.cijena),
                    jedProdajnaCijena: parseFloat(item.prodajnaCijena),
                    kolicina: parseFloat(item.kolicina),
                    napomenaZaPredmet: null
                };
            })
        };
        this.props.rezervacijaDataAccess.createZahtjevZaRezervaciju(zahtjevZaRezervaciju, this.hideLoader.bind(this, this.openDokumenti));
    }

    openDokumenti(rezervacija) {
        window.open(
            endpoints.appEndpoints.print.potvrdaRezervacije +
                storeDocumentAndGenerateKey(
                    Object.assign(rezervacija.otkupniBlokovi[0], {
                        rezervacije: rezervacija.rezervacije.nijeIstekaoRZPNijeIstekla,
                        sluzbenaNapomena: rezervacija.sluzbenaNapomena
                    })
                ),
            '_blank'
        );
        window.open(endpoints.appEndpoints.print.ugovorOtkupa + storeDocumentAndGenerateKey(rezervacija.otkupniBlokovi[0]), '_blank');
        this.setState({
            novaRezervacijaId: rezervacija.id
        });
    }

    redirect() {
        this.props.history.goBack();
    }

    fetchPredmeti(q, page, pageSize, onFetchSuccess, onFetchError) {
        ArtikliApi.getArtikliPaginated(
            q, page, pageSize, ArtikliApi.SORT.naziv, ArtikliApi.TIP_ARTIKLA.predmet,
            onFetchSuccess, onFetchError
        )
    }

    render() {
        return (
            <div>
                <Segment tertiary>
                    <Header as="h2">{localization.rezervacija.novaRezervacija}</Header>
                </Segment>
                <Segment tertiary>
                    <OdabirKlijenta
                        onClientSelected={this.handleOdabirKlijenta}
                        allowedClientType={enums.tipKlijenta.FIZICKA_OSOBA}
                        kontaktiRequired={true}
                    />
                </Segment>
                <Segment tertiary>
                    <OdabirArtiklaPaginated
                        fetchArtikli={this.fetchPredmeti}
                        onItemSelected={this.handleOdabirArtikla}
                        type={enums.tipTabliceArtikala.REZERVACIJA_EDITABLE}
                    />
                </Segment>
                <Segment tertiary>
                    <Grid stackable>
                        <Grid.Row columns={4}>
                            <Grid.Column verticalAlign="middle">
                                <Header as="h3">{localization.rezervacija.ukupnaCijenaPodizanjaRezervacije}</Header>
                            </Grid.Column>
                            <Grid.Column verticalAlign="middle" width={2}>
                                <InputField
                                    label={localization.common.eur}
                                    placeholder={localization.common.cijena}
                                    name="cijenaRezervacije"
                                    value={this.state.cijenaRezervacije}
                                    onChange={this.onChangeCijenaRezervacije}
                                    isCjelobrojno={true}
                                />
                            </Grid.Column>
                            {this.state.prvaManjaCijenaRezervacije !== undefined && this.state.prvaVecaCijenaRezervacije !== undefined ? (
                                <Grid.Column verticalAlign="middle" style={styles.cijenaRezervacijeText}>
                                    <span>{localization.rezervacija.cijenaNijeDjeljiva + '.'}</span>
                                    <br />
                                    <span>
                                        {localization.rezervacija.prvaNizaCijenaPodizanja + ': '}
                                        <FormatIznosa value={this.state.prvaManjaCijenaRezervacije} />
                                    </span>
                                    <br />
                                    <span>
                                        {localization.rezervacija.prvaVisaCijenaPodizanja + ': '}
                                        <FormatIznosa value={this.state.prvaVecaCijenaRezervacije} />
                                    </span>
                                </Grid.Column>
                            ) : null}
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Napomene
                    sluzbenaNapomenaHeader={localization.rezervacija.sluzbenaNapomenaRezervacije}
                    nesluzbenaNapomenaHeader={localization.rezervacija.nesluzbenaNapomenaRezervacije}
                    onChangeNapomena={this.onChangeNapomena}
                    required={this.state.obveznaNesluzbenaNapomena}
                    requiredText={localization.rezervacija.obveznaNesluzbenaNapomena}
                />
                <div style={styles.confirmButton}>
                    <Modal
                        open={this.state.potvrdaRezervacije}
                        closeOnDimmerClick={false}
                        trigger={
                            <Button
                                color="green"
                                content={localization.rezervacija.napraviRezervaciju}
                                disabled={!this.state.napraviRezervacijuEnabled || this.state.nedovoljnoBlagajnaOtkupa}
                                onClick={this.showHidePotvrdaRezervacije.bind(this, true)}
                            />
                        }
                    >
                        {!this.state.novaRezervacijaId ? (
                            <React.Fragment>
                                <Header icon={icons.rezervacija} content={localization.rezervacija.potvrdaRezervacije} />
                                <Modal.Content>
                                    <p>{localization.rezervacija.pitanjePotvrdeRezervacije}</p>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button icon="remove" content={localization.common.odustani} onClick={this.showHidePotvrdaRezervacije.bind(this, false)} />
                                    <Button
                                        icon="checkmark"
                                        color="green"
                                        content={localization.rezervacija.napraviRezervaciju}
                                        onClick={this.showLoader.bind(this, this.napraviRezervaciju)}
                                    />
                                </Modal.Actions>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Header icon="image" content={localization.rezervacija.ucitavanjeSlika} />
                                <Modal.Content>
                                    <ImageUploadModal
                                        saveImages={this.props.rezervacijaDataAccess.dodajSlikeNaRezervaciju.bind(this, this.state.novaRezervacijaId)}
                                        closeModal={this.showHidePotvrdaRezervacije.bind(this, false, this.redirect)}
                                    />
                                </Modal.Content>
                            </React.Fragment>
                        )}
                    </Modal>
                </div>
                {this.state.nedovoljnoBlagajnaOtkupa ? (
                    <div style={styles.confirmButton}>
                        <RequiredText requiredText={localization.common.nedovoljnoNovcaBlagajnaOtkupa} />
                    </div>
                ) : null}
                <Dimmer active={this.state.loader} page>
                    <Loader />
                </Dimmer>
            </div>
        );
    }
}

NovaRezervacija.propTypes = {
    history: PropTypes.object.isRequired,
    appPrefs: PropTypes.object.isRequired,
    stanjeBlagajni: PropTypes.object.isRequired,
    predmeti: PropTypes.array.isRequired,
    rezervacijaDataAccess: PropTypes.object.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {
        appPrefs: state.appPreferencesReducer.appPrefs,
        stanjeBlagajni: state.blagajneReducer.stanje
    };
};

let mapDispatchToProps = function(dispatch) {
    return {
        rezervacijaDataAccess: bindActionCreators(RezervacijaDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NovaRezervacija);
