import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, Header, Segment } from 'semantic-ui-react';

import Kartica from '../common_components/Kartica';
import AddNewButton from '../common_components/AddNewButton';
import { generateKey } from '../../utils/KeyGenerator';
import localization from '../../locales/localization';

const styles = {};

class Kartice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            kartice: this.props.kartice,
            novaKartica: false
        };
        this.onDeleteKartica = this.onDeleteKartica.bind(this);
        this.onAddNew = this.onAddNew.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            kartice: nextProps.kartice,
            novaKartica: false
        });
    }

    onDeleteKartica(index) {
        this.setState({
            kartice: this.state.kartice.slice(0, index).concat(this.state.kartice.slice(index + 1)),
            novaKartica: this.state.novaKartica && !(index === this.state.kartice.length - 1)
        });
    }

    onAddNew(e) {
        this.setState({
            kartice: [
                ...this.state.kartice,
                {
                    naziv: '',
                    nova: true,
                    karticniUvjeti: [
                        {
                            pocetnoMjeseci: 0,
                            postotak: undefined,
                            odgodaUplateDana: undefined
                        }
                    ]
                }
            ],
            novaKartica: true
        });
    }

    render() {
        return (
            <Segment tertiary>
                <Header as="h2">{localization.postavke.kartice}</Header>
                <Card.Group itemsPerRow={1} stackable>
                    {this.state.kartice.map((k, index) => {
                        return (
                            <Card key={index}>
                                <Card.Content textAlign="center">
                                    <Kartica novo={k.nova} kartica={k} onDelete={this.onDeleteKartica.bind(this, index)} />
                                </Card.Content>
                            </Card>
                        );
                    })}
                    {!this.state.novaKartica ? (
                        <AddNewButton key={generateKey()} text={localization.postavke.dodajNovuKarticu} name="novaKartica" onAddNew={this.onAddNew} />
                    ) : null}
                </Card.Group>
            </Segment>
        );
    }
}

Kartice.propTypes = {
    history: PropTypes.object.isRequired,
    kartice: PropTypes.array.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {
        kartice: state.karticeReducer.kartice || []
    };
};

let mapDispatchToProps = function(dispatch) {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Kartice);
