import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import endpoints from '../../environments/endpoints';
import enums from '../../utils/Enums';
import { Form, Grid, Message } from 'semantic-ui-react';
import localization from '../../locales/localization';
import * as AuthDataAccess from '../../data_access/AuthDataAccess';
import * as MjestaDataAccess from '../../data_access/MjestaDataAccess';
import { bindActionCreators } from 'redux';

const styles = {
    grid: {
        height: '100%'
    },
    column: {
        maxWidth: 450,
        margin: 'auto',
        marginTop: 48
    },
    title: {
        textAlign: 'center'
    }
};

class Prijava extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            showError: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.onLogin = this.onLogin.bind(this);
        this.redirect = this.redirect.bind(this);
        this.showError = this.showError.bind(this);
    }

    handleChange(e, { name, value }) {
        this.setState({
            [name]: value,
            showError: false
        });
    }

    onLogin() {
        let credentials = {
            username: this.state.username,
            password: this.state.password
        };
        this.props.authDataAccess.login(credentials, this.redirect, this.showError);
    }

    redirect(data) {
        sessionStorage.setItem('user', JSON.stringify(data.user));
        sessionStorage.setItem('userRole', data.user.role);
        sessionStorage.setItem('token', data.token);
        if (data.user.role === enums.tipKorisnika.DJELATNIK) {
            this.props.mjestaDataAccess.getMjesta(this.props.history.push.bind(this, endpoints.appEndpoints.odabirPoslovnice));
        } else {
            sessionStorage.setItem('isAuthenticated', true);
            this.props.history.push(endpoints.appEndpoints.root);
        }
    }

    showError() {
        this.setState({
            showError: true
        });
    }

    render() {
        return (
            <Grid verticalAlign="middle" style={styles.grid}>
                <Grid.Column style={styles.column}>
                    <Message attached>
                        <Message.Header style={styles.title}>{localization.zalagaonica} {localization.verzija}</Message.Header>
                    </Message>
                    <Form className="attached fluid segment">
                        <Form.Input
                            label={localization.login.korisnickoIme}
                            placeholder={localization.login.korisnickoIme}
                            name="username"
                            type="text"
                            error={this.state.showError}
                            onChange={this.handleChange}
                        />
                        <Form.Input
                            label={localization.login.lozinka}
                            name="password"
                            type="password"
                            error={this.state.showError}
                            onChange={this.handleChange}
                        />
                        <Form.Button fluid color="blue" content={localization.login.prijava} onClick={this.onLogin} />
                    </Form>
                </Grid.Column>
            </Grid>
        );
    }
}

Prijava.propTypes = {
    history: PropTypes.object.isRequired,
    authDataAccess: PropTypes.object.isRequired,
    mjestaDataAccess: PropTypes.object.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {};
};

let mapDispatchToProps = function(dispatch) {
    return {
        authDataAccess: bindActionCreators(AuthDataAccess, dispatch),
        mjestaDataAccess: bindActionCreators(MjestaDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Prijava);
