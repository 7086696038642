import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';

import endpoints from '../environments/endpoints';
import Prijava from './login_components/Prijava';
import OdabirPoslovnice from './login_components/OdabirPoslovnice';

class Login extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Switch>
                <Route exact path={endpoints.appEndpoints.root} component={Prijava} />
                <Route path={endpoints.appEndpoints.odabirPoslovnice} component={OdabirPoslovnice} />
            </Switch>
        );
    }
}

Login.propTypes = {
    history: PropTypes.object.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {};
};

let mapDispatchToProps = function(dispatch) {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
