import $ from 'jquery';

import * as success from '../actions/SkladisteActions';

import endpoint from '../environments/endpoints';
import enums from '../utils/Enums';
import { showAlert } from '../utils/ErrorHandler';

export function getStanjeSkladista(onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'skladiste'
        })
            .done(data => {
                dispatch(success.getStanjeSkladista(data));
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function getStanjeSkladistaZaPoslovnicu(mjestoId, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'skladiste?mjestoId=' + mjestoId
        })
            .done(data => {
                dispatch(success.getStanjeSkladista(data));
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function getStanjeSkladistaZaInventuru(onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'skladiste/inventura'
        })
            .done(data => {
                onSuccess(data);
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function getStanjeSkladistaZaInventuruZaPoslovnicu(mjestoId, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'skladiste/inventura?mjestoId=' + mjestoId
        })
            .done(data => {
                onSuccess(data);
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function getStanjeSkladistaZaKnjigovodstvo(onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'skladiste/knjigovodstvo'
        })
            .done(data => {
                onSuccess(data);
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function getStanjeSkladistaZaKnjigovodstvoZaPoslovnicu(mjestoId, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'skladiste/knjigovodstvo?mjestoId=' + mjestoId
        })
            .done(data => {
                onSuccess(data);
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function getStanjeSkladistaZaOdredeniDan(date, mjestoId, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'skladiste?date=' + date + '&mjestoId=' + mjestoId
        })
            .done(data => {
                onSuccess(data);
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function getStanjeSkladistaSNepotvrdenimCijenama(onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'skladiste/nepotvrdene-cijene'
        })
            .done(data => {
                onSuccess(data);
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function getListaArtikalaZaMaknutiSOglasavanja(onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'skladiste/maknuti-s-oglasavanja'
        })
            .done(data => {
                dispatch(success.getListaArtikalaZaMaknutiSOglasavanja(data));
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function dodajSlike(skladisnaStavkaId, slike, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'skladiste/' + skladisnaStavkaId + '/slike/b64',
            data: JSON.stringify(slike)
        })
            .done(data => {
                if (sessionStorage.userRole === enums.tipKorisnika.DJELATNIK) {
                    dispatch(getStanjeSkladistaZaPoslovnicu(JSON.parse(sessionStorage.poslovnicaId)));
                } else {
                    dispatch(getStanjeSkladista());
                }
                onSuccess();
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function brisanjeSlika(skladisnaStavkaId, imageIds, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'skladiste/' + skladisnaStavkaId + '/slike',
            data: JSON.stringify(imageIds)
        })
            .done(data => {
                if (sessionStorage.userRole === enums.tipKorisnika.DJELATNIK) {
                    dispatch(getStanjeSkladistaZaPoslovnicu(JSON.parse(sessionStorage.poslovnicaId)));
                } else {
                    dispatch(getStanjeSkladista());
                }
                onSuccess();
            })
            .fail(error => {
                showAlert(error);
            });
    };
}
//---------------------------
//SKLADISNE STAVKE CONTROLLER
//---------------------------

export function updateSkladisnaStavkaFlag(skladisnaStavkaId, flagId, value, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'skladisne-stavke/' + skladisnaStavkaId + '/flags/' + flagId,
            data: JSON.stringify(value)
        })
            .done(data => {
                dispatch(success.resetStanjeCache());
                if (onSuccess) {
                    onSuccess();
                }
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function dodavanjeNesluzbeneNapomeneNaSkladisnuStavku(skladisnaStavkaId, napomena, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'skladisne-stavke/' + skladisnaStavkaId + '/napomene',
            data: napomena
        })
            .done(data => {
                if (sessionStorage.userRole === enums.tipKorisnika.DJELATNIK) {
                    dispatch(getStanjeSkladistaZaPoslovnicu(JSON.parse(sessionStorage.poslovnicaId)));
                } else {
                    dispatch(getStanjeSkladista());
                }
                onSuccess();
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function promijenaNazivaZaPrintanje(skladisnaStavkaId, naziv, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'skladisne-stavke/' + skladisnaStavkaId + '/promjena-naziv-za-print',
            data: naziv
        })
            .done(data => {
                if (sessionStorage.userRole === enums.tipKorisnika.DJELATNIK) {
                    dispatch(getStanjeSkladistaZaPoslovnicu(JSON.parse(sessionStorage.poslovnicaId)));
                } else {
                    dispatch(getStanjeSkladista());
                }
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function prijedlogProdajnihCijena(prijedloziProdajnihCijena, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'skladisne-stavke/prijedlog-prodajnih-cijena',
            data: JSON.stringify(prijedloziProdajnihCijena)
        })
            .done(data => {
                onSuccess();
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function promjenaProdajnihCijena(prodajneCijene, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'skladisne-stavke/promjena-prodajnih-cijena',
            data: JSON.stringify(prodajneCijene)
        })
            .done(data => {
                dispatch(getStanjeSkladista());
                onSuccess();
            })
            .fail(error => {
                showAlert(error);
            });
    };
}
