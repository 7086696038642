import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Menu from './common_components/Menu';
import endpoints from '../environments/endpoints';
import icons from '../utils/DefinedIcons';
import enums from '../utils/Enums';
import localization from '../locales/localization';
import * as AppPreferencesDataAccess from '../data_access/AppPreferencesDataAccess';
import * as KlijentiDataAccess from '../data_access/KlijentiDataAccess';
import * as KarticeDataAccess from '../data_access/KarticeDataAccess';
import * as KategorijeDataAccess from '../data_access/KategorijeDataAccess';
import * as MjerneJediniceDataAccess from '../data_access/MjerneJediniceDataAccess';
import * as ProdajaDataAccess from '../data_access/ProdajaDataAccess';
import * as IzdatniceDataAccess from '../data_access/IzdatniceDataAccess';
import * as RadniNalogDataAccess from '../data_access/RadniNalogDataAccess';
import * as SkladisteDataAccess from '../data_access/SkladisteDataAccess';
import * as UsersDataAccess from '../data_access/UsersDataAccess';
import * as MjestaDataAccess from '../data_access/MjestaDataAccess';

let getMenuItems = prefix => {
    let menu = [
        {
            icon: icons.klijenti,
            url: prefix + endpoints.appEndpoints.klijenti,
            text: localization.dokumenti.klijenti
        },
        {
            icon: icons.artikli,
            url: prefix + endpoints.appEndpoints.artikli,
            text: localization.dokumenti.artikli
        },
        {
            icon: icons.usluge,
            url: prefix + endpoints.appEndpoints.usluge,
            text: localization.dokumenti.usluge
        },
        {
            icon: icons.stanjeSkladista,
            url: prefix + endpoints.appEndpoints.stanjeSkladista,
            text: localization.dokumenti.stanjeSkladista
        }
    ];
    if (sessionStorage.userRole !== enums.tipKorisnika.DJELATNIK) {
        menu.push({
            icon: icons.rezervacija,
            url: prefix + endpoints.appEndpoints.rezervacije,
            text: localization.dokumenti.rezervacije
        });
    }
    menu.push(
        {
            icon: icons.otkup,
            url: prefix + endpoints.appEndpoints.otkupi,
            text: localization.dokumenti.otkupi
        },
        {
            icon: icons.primka,
            url: prefix + endpoints.appEndpoints.primke,
            text: localization.dokumenti.primke
        },
        {
            icon: icons.racun,
            url: prefix + endpoints.appEndpoints.racuni,
            text: localization.dokumenti.racuni
        },
        {
            icon: icons.predracun,
            url: prefix + endpoints.appEndpoints.predracuni,
            text: localization.dokumenti.predracuni
        },
        {
            icon: icons.procjena,
            url: prefix + endpoints.appEndpoints.procjene,
            text: localization.dokumenti.zahtjeviZaProcjenu
        },
        {
            icon: icons.radniNalog,
            url: prefix + endpoints.appEndpoints.radniNalozi,
            text: localization.dokumenti.radniNalozi
        },
        {
            icon: icons.meduskladisnica,
            url: prefix + endpoints.appEndpoints.meduskladisnice,
            text: localization.dokumenti.meduskladisnice
        },
        {
            icon: icons.izdatnice,
            url: prefix + endpoints.appEndpoints.izdatnice,
            text: localization.dokumenti.izdatnice
        },
        {
            icon: icons.cijeneOglasavanje,
            url: prefix + endpoints.appEndpoints.cijeneOglasavanje,
            text: localization.dokumenti.cijeneIoglasavanje
        },
        {
            icon: icons.maknutiSOglasavanja,
            url: prefix + endpoints.appEndpoints.maknutiSOglasavanja,
            text: localization.dokumenti.maknutiSOglasavanja
        },
        {
            icon: icons.blagajnickiDnevnikFisk,
            url: prefix + endpoints.appEndpoints.blagajnickiDnevnikFisk,
            text: localization.dokumenti.blagajnickiDnevnikFisk
        }
    );
    if (sessionStorage.userRole !== enums.tipKorisnika.DJELATNIK) {
        menu.push(
            {
                icon: icons.blagajnickiDnevnikOtkup,
                url: prefix + endpoints.appEndpoints.blagajnickiDnevnikOtkup,
                text: localization.dokumenti.blagajnickiDnevnikOtkup
            },
            {
                icon: icons.ponavljajuciTroskovi,
                url: prefix + endpoints.appEndpoints.pregledTroskova,
                text: localization.dokumenti.pregledTroskova
            },
            {
                icon: icons.dnevnaEvidencija,
                url: prefix + endpoints.appEndpoints.dnevnaEvidencija,
                text: localization.dokumenti.dnevneRadnje
            },
            {
                icon: icons.rekapitulacije,
                url: prefix + endpoints.appEndpoints.rekapitulacije,
                text: localization.dokumenti.rekapitulacije
            },
            {
                icon: icons.pregledBankovnihTransakcija,
                url: prefix + endpoints.appEndpoints.pregledBankovnihTransakcija,
                text: localization.dokumenti.pregledBankovnihTransakcija
            }
        );
    }
    if (sessionStorage.userRole === enums.tipKorisnika.VLASNIK) {
        menu.push(
            {
                icon: icons.kontrola,
                url: prefix + endpoints.appEndpoints.kontrola,
                text: localization.kontrola.kontrola
            },
            {
                icon: icons.postavke,
                url: prefix + endpoints.appEndpoints.postavke,
                text: localization.postavke.postavke
            }
        );
    }
    return menu;
};

class DokumentiPreloaderAndMenu extends React.Component {
    constructor(props) {
        super(props);
        if (sessionStorage.userRole === enums.tipKorisnika.DJELATNIK) {
            this.fetchData();
        } else {
            this.props.mjestaDataAccess.getMjesta(this.fetchData.bind(this));
        }
    }

    fetchData() {
        if (!this.props.listaOglasavanjaCache) {
            this.props.skladisteDataAccess.getListaArtikalaZaMaknutiSOglasavanja();
        }
        let poslovnicaId;
        if (sessionStorage.userRole === enums.tipKorisnika.DJELATNIK) {
            poslovnicaId = JSON.parse(sessionStorage.poslovnicaId);
            if (!this.props.radniNaloziCache) {
                this.props.radniNalogDataAccess.getRadniNaloziZaPoslovnicu(poslovnicaId);
            }
            if (!this.props.skladisteCache) {
                this.props.skladisteDataAccess.getStanjeSkladistaZaPoslovnicu(poslovnicaId);
            }
        } else {
            poslovnicaId = this.props.poslovnice.length ? this.props.poslovnice[0].id : undefined;
            if (!this.props.appPrefsCache) {
                this.props.appPreferencesDataAccess.getAppPrefs();
            }
            if (!this.props.karticeCache) {
                this.props.karticeDataAccess.getKartice();
            }
            if (!this.props.kategorijeCache) {
                this.props.kategorijeDataAccess.getKategorije();
            }
            if (!this.props.mjerneJediniceCache) {
                this.props.mjerneJediniceDataAccess.getMjerneJedinice();
            }
            if (!this.props.izdatniceCache) {
                this.props.izdatniceDataAccess.getIzdatnice();
            }
            if (!this.props.radniNaloziCache) {
                this.props.radniNalogDataAccess.getRadniNalozi();
            }
            if (!this.props.skladisteCache) {
                this.props.skladisteDataAccess.getStanjeSkladista();
            }
            if (!this.props.korisniciCache) {
                this.props.usersDataAccess.getUsers();
            }
        }
    }

    render() {
        let prefix = '';
        if (sessionStorage.userRole === enums.tipKorisnika.DJELATNIK) {
            prefix = endpoints.appEndpoints.dokumentiRoot;
        }
        return <Menu menuItems={getMenuItems(prefix)} history={this.props.history} />;
    }
}

DokumentiPreloaderAndMenu.propTypes = {
    history: PropTypes.object.isRequired,
    poslovnice: PropTypes.array.isRequired,
    appPreferencesDataAccess: PropTypes.object.isRequired,
    appPrefsCache: PropTypes.bool.isRequired,
    klijentiDataAccess: PropTypes.object.isRequired,
    karticeDataAccess: PropTypes.object.isRequired,
    karticeCache: PropTypes.bool.isRequired,
    kategorijeDataAccess: PropTypes.object.isRequired,
    kategorijeCache: PropTypes.bool.isRequired,
    mjerneJediniceDataAccess: PropTypes.object.isRequired,
    mjerneJediniceCache: PropTypes.bool.isRequired,
    prodajaDataAccess: PropTypes.object.isRequired,
    izdatniceCache: PropTypes.bool.isRequired,
    izdatniceDataAccess: PropTypes.object.isRequired,
    radniNalogDataAccess: PropTypes.object.isRequired,
    radniNaloziCache: PropTypes.bool.isRequired,
    skladisteDataAccess: PropTypes.object.isRequired,
    skladisteCache: PropTypes.bool.isRequired,
    listaOglasavanjaCache: PropTypes.bool.isRequired,
    usersDataAccess: PropTypes.object.isRequired,
    korisniciCache: PropTypes.bool.isRequired,
    mjestaDataAccess: PropTypes.object.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {
        poslovnice: state.mjestaReducer.poslovnice || [],
        appPrefsCache: state.appPreferencesReducer.cached,
        karticeCache: state.karticeReducer.cached,
        kategorijeCache: state.kategorijeReducer.cached,
        izdatniceCache: state.izdatniceReducer.cached,
        mjerneJediniceCache: state.mjerneJediniceReducer.cached,
        listaOglasavanjaCache: state.skladisteReducer.cachedLista,
        skladisteCache: state.skladisteReducer.cachedStanjeSkladista,
        radniNaloziCache: state.radniNaloziReducer.cached,
        korisniciCache: state.korisniciReducer.cached
    };
};

let mapDispatchToProps = function(dispatch) {
    return {
        appPreferencesDataAccess: bindActionCreators(AppPreferencesDataAccess, dispatch),
        klijentiDataAccess: bindActionCreators(KlijentiDataAccess, dispatch),
        karticeDataAccess: bindActionCreators(KarticeDataAccess, dispatch),
        kategorijeDataAccess: bindActionCreators(KategorijeDataAccess, dispatch),
        mjerneJediniceDataAccess: bindActionCreators(MjerneJediniceDataAccess, dispatch),
        prodajaDataAccess: bindActionCreators(ProdajaDataAccess, dispatch),
        izdatniceDataAccess: bindActionCreators(IzdatniceDataAccess, dispatch),
        radniNalogDataAccess: bindActionCreators(RadniNalogDataAccess, dispatch),
        skladisteDataAccess: bindActionCreators(SkladisteDataAccess, dispatch),
        usersDataAccess: bindActionCreators(UsersDataAccess, dispatch),
        mjestaDataAccess: bindActionCreators(MjestaDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DokumentiPreloaderAndMenu);
