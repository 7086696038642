import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { Header } from 'semantic-ui-react';

import PotvrdaRezervacije from './printable_components/PotvrdaRezervacije';
import UgovorOtkupa from './printable_components/UgovorOtkupa';
import Primka from './printable_components/Primka';
import OtkupniBlok from './printable_components/OtkupniBlok';
import Racun from './printable_components/Racun';
import Izdatnica from './printable_components/Izdatnica';
import Predracun from './printable_components/Predracun';
import ZapisnikOProdajiIspodNabavneCijene from './printable_components/ZapisnikOProdajiIspodNabavneCijene';
import ZahtjevZaProcjenom from './printable_components/ZahtjevZaProcjenom';
import RadniNalog from './printable_components/RadniNalog';
import Meduskladisnica from './printable_components/Meduskladisnica';
import StanjeSkladista from './printable_components/StanjeSkladista';
import StanjeSkladistaInventura from './printable_components/StanjeSkladistaInventura';
import CeduljicaSCijenom from './printable_components/CeduljicaSCijenom';
import BlagajnickiDnevnik from './printable_components/BlagajnickiDnevnik';
import localization from '../locales/localization';
import endpoints from '../environments/endpoints';
import RekapitulacijaRacuna from './printable_components/RekapitulacijaRacuna';
import RekapitulacijaOtkupnihBlokova from './printable_components/RekapitulacijaOtkupnihBlokova';

const styles = {
    rootContainer: {
        padding: 8
    },
    contentContainer: {
        paddingTop: 8
    }
};

const pathParam = ':id';

let checkIfPrintCeduljice = location => {
    return location.pathname.split('/', 3).join('/') + '/' === endpoints.appEndpoints.print.ceduljicascijenom;
};

class PrintTemplate extends React.Component {
    render() {
        return (
            <div style={styles.rootContainer}>
                {!checkIfPrintCeduljice(this.props.location) ? (
                    <Header as="h2" textAlign="center" attached="top" inverted>
                        {localization.zalagaonica} {localization.verzija}
                    </Header>
                ) : null}
                <div style={styles.contentContainer}>
                    <Switch>
                        <Route exact path={endpoints.appEndpoints.print.potvrdaRezervacije + pathParam} component={PotvrdaRezervacije} />
                        <Route exact path={endpoints.appEndpoints.print.ugovorOtkupa + pathParam} component={UgovorOtkupa} />
                        <Route exact path={endpoints.appEndpoints.print.primka + pathParam} component={Primka} />
                        <Route exact path={endpoints.appEndpoints.print.otkupniBlok + pathParam} component={OtkupniBlok} />
                        <Route exact path={endpoints.appEndpoints.print.racun + pathParam} component={Racun} />
                        <Route exact path={endpoints.appEndpoints.print.izdatnica + pathParam} component={Izdatnica} />
                        <Route exact path={endpoints.appEndpoints.print.predracun + pathParam} component={Predracun} />
                        <Route
                            exact
                            path={endpoints.appEndpoints.print.zapisnikOProdajiIspodNabavneCijene + pathParam}
                            component={ZapisnikOProdajiIspodNabavneCijene}
                        />
                        <Route exact path={endpoints.appEndpoints.print.zahtjevZaProcjenom + pathParam} component={ZahtjevZaProcjenom} />
                        <Route exact path={endpoints.appEndpoints.print.radniNalog + pathParam} component={RadniNalog} />
                        <Route exact path={endpoints.appEndpoints.print.meduskladisnica + pathParam} component={Meduskladisnica} />
                        <Route exact path={endpoints.appEndpoints.print.stanjeSkladista + pathParam} component={StanjeSkladista} />
                        <Route exact path={endpoints.appEndpoints.print.stanjeSkladistaInventura + pathParam} component={StanjeSkladistaInventura} />
                        <Route exact path={endpoints.appEndpoints.print.ceduljicascijenom + pathParam} component={CeduljicaSCijenom} />
                        <Route exact path={endpoints.appEndpoints.print.blagajnickiDnevnik + pathParam} component={BlagajnickiDnevnik} />
                        <Route exact path={endpoints.appEndpoints.print.rekapitulacijaRacuna + pathParam} component={RekapitulacijaRacuna} />
                        <Route exact path={endpoints.appEndpoints.print.rekapitulacijaOtkupnihBlokova + pathParam} component={RekapitulacijaOtkupnihBlokova} />
                    </Switch>
                </div>
            </div>
        );
    }
}

PrintTemplate.propTypes = {
    location: PropTypes.object.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {};
};

let mapDispatchToProps = function(dispatch) {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PrintTemplate);
