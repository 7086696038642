import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Card, Header, Segment } from 'semantic-ui-react';

import Firma from '../common_components/Firma';
import Mjesto from '../common_components/Mjesto';
import AddNewButton from '../common_components/AddNewButton';
import enums from '../../utils/Enums';
import { generateKey } from '../../utils/KeyGenerator';
import localization from '../../locales/localization';

const styles = {
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
};

let validateOIBLength = oib => {
    return oib.length !== 11;
};

class UpravljanjeFirmom extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            novaPoslovnica: false,
            novoMjesto: false
        };
        this.onAddNew = this.onAddNew.bind(this);
    }

    onAddNew(value, e, { name }) {
        this.setState({ [name]: value });
    }

    render() {
        return (
            <div>
                <Segment tertiary>
                    <Header as="h2">{localization.postavke.upravljanjeFirmom}</Header>
                </Segment>
                <Segment tertiary>
                    <Header as="h3">{localization.postavke.opceInformacije}</Header>
                    <Segment>
                        <Firma firma={this.props.firma} poslovnice={this.props.poslovnice} mjesta={this.props.mjesta} />
                    </Segment>
                </Segment>
                <Segment tertiary>
                    <Header as="h3">{localization.postavke.poslovnice}</Header>
                    <Card.Group itemsPerRow={2} stackable>
                        {this.props.poslovnice.map(p => {
                            return (
                                <Card key={p.id}>
                                    <Card.Content textAlign="center">
                                        <Mjesto tipMjesta={enums.tipMjesta.POSLOVNICA} novo={false} mjesto={p} />
                                    </Card.Content>
                                </Card>
                            );
                        })}
                        {this.state.novaPoslovnica ? (
                            <Card key={generateKey()}>
                                <Card.Content textAlign="center">
                                    <Mjesto
                                        tipMjesta={enums.tipMjesta.POSLOVNICA}
                                        novo={true}
                                        mjesto={{}}
                                        name="novaPoslovnica"
                                        onCancel={this.onAddNew.bind(this, false)}
                                    />
                                </Card.Content>
                            </Card>
                        ) : (
                            <AddNewButton
                                key={generateKey()}
                                text={localization.postavke.dodajNovuPoslovnicu}
                                name="novaPoslovnica"
                                onAddNew={this.onAddNew.bind(this, true)}
                            />
                        )}
                    </Card.Group>
                </Segment>
                <Segment tertiary>
                    <Header as="h3">{localization.postavke.skladista}</Header>
                    <Card.Group itemsPerRow={2} stackable>
                        {this.props.mjesta.map(m => {
                            return (
                                <Card key={m.id}>
                                    <Card.Content textAlign="center">
                                        <Mjesto tipMjesta={enums.tipMjesta.MJESTO} novo={false} mjesto={m} />
                                    </Card.Content>
                                </Card>
                            );
                        })}
                        {this.state.novoMjesto ? (
                            <Card key={generateKey()}>
                                <Card.Content textAlign="center">
                                    <Mjesto
                                        tipMjesta={enums.tipMjesta.MJESTO}
                                        novo={true}
                                        mjesto={{}}
                                        name="novoMjesto"
                                        onCancel={this.onAddNew.bind(this, false)}
                                    />
                                </Card.Content>
                            </Card>
                        ) : (
                            <AddNewButton
                                key={generateKey()}
                                text={localization.postavke.dodajNovoSkladiste}
                                name="novoMjesto"
                                onAddNew={this.onAddNew.bind(this, true)}
                            />
                        )}
                    </Card.Group>
                </Segment>
            </div>
        );
    }
}

UpravljanjeFirmom.propTypes = {
    history: PropTypes.object.isRequired,
    firma: PropTypes.object.isRequired,
    poslovnice: PropTypes.array.isRequired,
    mjesta: PropTypes.array.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {
        firma: state.firmaReducer.firma,
        poslovnice: state.mjestaReducer.poslovnice || [],
        mjesta: state.mjestaReducer.mjesta || []
    };
};

let mapDispatchToProps = function(dispatch) {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(UpravljanjeFirmom);
