import React from 'react';
import PropTypes from 'prop-types';
import { Card, Header, Icon } from 'semantic-ui-react';

const styles = {
    centeredIcon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    iconColor: {
        color: 'rgba(0,0,0,.87)'
    }
};

export default class AddNewButton extends React.Component {
    render() {
        return (
            <Card onClick={this.props.onAddNew} style={styles.iconColor} name={this.props.name}>
                <Card.Content textAlign="center" style={styles.centeredIcon}>
                    <Icon name="plus" size="massive" />
                </Card.Content>
                <Card.Content extra>
                    <Header as="h4" textAlign="center">
                        {this.props.text}
                    </Header>
                </Card.Content>
            </Card>
        );
    }
}

AddNewButton.propTypes = {
    text: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onAddNew: PropTypes.func.isRequired
};
