import React from 'react';
import PropTypes from 'prop-types';
import { Header, Segment, Table } from 'semantic-ui-react';

import localization from '../../../locales/localization';
import FormatIznosa from '../../common_components/FormatIznosa';

const styles = {
    kolicinaIcijena: {
        fontFamily: 'monospace'
    }
};

export default class TrosakContent extends React.Component {
    render() {
        return (
            <Segment>
                <Header as="h4">{localization.pregledTroskova.detaljiTroska}</Header>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>{localization.pregledTroskova.tipTroska}</Table.HeaderCell>
                            <Table.HeaderCell>{localization.pregledTroskova.nazivTroska}</Table.HeaderCell>
                            <Table.HeaderCell>{localization.pregledTroskova.grupaTroska}</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center" collapsing>
                                {localization.pregledTroskova.iznos}
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>
                                {this.props.trosak.grupa ? localization.pregledTroskova.jednokratniTrosak : localization.pregledTroskova.ponavljajuciTrosak}
                            </Table.Cell>
                            <Table.Cell>{this.props.trosak.ime}</Table.Cell>
                            <Table.Cell>{this.props.trosak.grupa ? this.props.trosak.grupa.ime : '-'}</Table.Cell>
                            <Table.Cell collapsing textAlign="right" style={styles.kolicinaIcijena}>
                                <FormatIznosa value={this.props.trosak.iznos} />
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Segment>
        );
    }
}

TrosakContent.propTypes = {
    trosak: PropTypes.object.isRequired
};
