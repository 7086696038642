import enums from '../utils/Enums';

let initialState = {
    poslovnice: [],
    mjesta: [],
    cached: false
};

export default function mjestaReducer(state = initialState, action) {
    switch (action.type) {
        case 'GET_MJESTA': {
            return Object.assign({}, state, {
                poslovnice: action.mjesta.filter(m => m.tipMjesta === enums.tipMjesta.POSLOVNICA),
                mjesta: action.mjesta.filter(m => m.tipMjesta === enums.tipMjesta.MJESTO),
                cached: true
            });
        }
        default:
            return state;
    }
}
