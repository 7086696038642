export default {
    klijenti: 'users',
    artikli: 'cube',
    usluge: 'american sign language interpreting',
    stanjeSkladista: 'cubes',
    rezervacija: 'retweet',
    otkup: 'money',
    primka: 'money',
    prodaja: 'shop',
    racun: 'file text',
    predracun: 'file text outline',
    procjena: 'eye',
    radniNalog: 'wrench',
    meduskladisnica: 'exchange',
    dokumenti: 'clipboard',
    postavke: 'settings',
    postotci: 'percent',
    firma: 'building',
    djelatnici: 'users',
    kartice: 'credit card',
    kategorije: 'sitemap',
    mjerneJedinice: 'sort numeric descending',
    porezniZakoni: 'law',
    cijeneOglasavanje: 'list ul',
    izdatnice: 'file excel outline',
    maknutiSOglasavanja: 'clipboard',
    grupeJednokratnihTroskova: 'object group',
    ponavljajuciTroskovi: 'book',
    blagajnickiDnevnikOtkup: 'calculator',
    blagajnickiDnevnikFisk: 'calculator',
    dnevnaEvidencija: 'list',
    rekapitulacije: 'table',
    pregledBankovnihTransakcija: 'chart area',
    kontrola: 'spy'
};
