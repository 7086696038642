import React from 'react';
import PropTypes from 'prop-types';
import { Header, Segment, Table } from 'semantic-ui-react';

import localization from '../../../locales/localization';
import FormatIznosa from '../../common_components/FormatIznosa';

const styles = {
    kolicinaIcijena: {
        fontFamily: 'monospace'
    }
};

export default class CijeneContent extends React.Component {
    render() {
        return (
            <Segment>
                <Header as="h4">{localization.dnevnaEvidencija.artiklKojemJePromijenjenaCijena}</Header>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>{localization.artikl.sifra}</Table.HeaderCell>
                            <Table.HeaderCell>{localization.artikl.naziv}</Table.HeaderCell>
                            <Table.HeaderCell>{localization.artikl.serijskiBroj}</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center" collapsing>
                                {localization.dnevnaEvidencija.staraCijena}
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center" collapsing>
                                {localization.dnevnaEvidencija.novaCijena}
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.props.predmeti.map((p, index) => {
                            return (
                                <Table.Row key={index}>
                                    <Table.Cell>{p.sifra}</Table.Cell>
                                    <Table.Cell>{p.naziv}</Table.Cell>
                                    <Table.Cell>{p.serijskiBroj || '-'}</Table.Cell>
                                    <Table.Cell collapsing textAlign="right" style={styles.kolicinaIcijena}>
                                        <FormatIznosa value={p.staraCijena} />
                                    </Table.Cell>
                                    <Table.Cell collapsing textAlign="right" style={styles.kolicinaIcijena}>
                                        <FormatIznosa value={p.novaCijena} />
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            </Segment>
        );
    }
}

CijeneContent.propTypes = {
    predmeti: PropTypes.array.isRequired
};
