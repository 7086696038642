import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button, TextArea } from 'semantic-ui-react';
import localization from '../../locales/localization';

const styles = {
    actionButtons: {
        marginTop: 16,
        display: 'flex',
        justifyContent: 'flex-end'
    }
};

export default class NapomenaUzArtikl extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            napomena: this.props.napomena || ''
        };
        this.onChange = this.onChange.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    onChange(e, { value }) {
        this.setState({ napomena: value });
    }

    onSave(e) {
        this.props.onSave(e, {
            name: 'napomena',
            value: this.state.napomena
        });
        this.props.onCancel();
    }

    render() {
        return (
            <React.Fragment>
                <Form>
                    <TextArea placeholder={localization.common.napomena} value={this.state.napomena} onChange={this.onChange} />
                </Form>
                <div style={styles.actionButtons}>
                    <Button icon="close" content={localization.common.odustani} onClick={this.props.onCancel} />
                    <Button
                        icon="comment outline"
                        color="green"
                        name="napomena"
                        value={this.state.napomena}
                        content={localization.otkup.dodajNapomenuUzArtikl}
                        onClick={this.onSave}
                    />
                </div>
            </React.Fragment>
        );
    }
}

NapomenaUzArtikl.propTypes = {
    napomena: PropTypes.string,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};
