import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';

const styles = {
    quantityAndPrice: {
        width: 130
    }
};

export default class InputField extends React.Component {
    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(e, { name, value }) {
        let re;
        if (this.props.isCjelobrojno) {
            re = new RegExp('^[^0][0-9]*$');
        } else {
            re = new RegExp('^[0-9]+\\.?[0-9]?[0-9]?$');
        }
        if (re.test(value) || value === '') {
            if (this.props.maxValue) {
                if (this.props.maxValue >= value) {
                    this.props.onChange(e, { name, value });
                }
            } else {
                this.props.onChange(e, { name, value });
            }
        }
    }

    render() {
        return (
            <Input
                label={{
                    basic: true,
                    content: this.props.label
                }}
                labelPosition="right"
                fluid={!this.props.style}
                style={this.props.style || styles.quantityAndPrice}
                placeholder={this.props.placeholder}
                name={this.props.name}
                value={this.props.value || ''}
                onChange={this.handleInputChange}
            />
        );
    }
}

InputField.propTypes = {
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func.isRequired,
    isCjelobrojno: PropTypes.bool,
    maxValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    style: PropTypes.object
};
