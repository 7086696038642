import $ from 'jquery';

import * as success from '../actions/MjerneJediniceActions';

import endpoint from '../environments/endpoints';
import { showAlert } from '../utils/ErrorHandler';

export function getMjerneJedinice(onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'mjerne-jedinice'
        })
            .done(data => {
                dispatch(success.getMjerneJedinice(data));
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function createMjerneJedinica(mjernaJedinica, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'mjerne-jedinice',
            data: JSON.stringify(mjernaJedinica)
        })
            .done(data => {
                dispatch(getMjerneJedinice());
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function updateMjernaJedinica(id, mjernaJedinica, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'mjerne-jedinice/' + id,
            data: JSON.stringify(mjernaJedinica)
        })
            .done(data => {
                dispatch(getMjerneJedinice());
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function deleteMjerneJedinica(id, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'mjerne-jedinice/' + id
        })
            .done(data => {
                dispatch(getMjerneJedinice());
            })
            .fail(error => {
                showAlert(error);
            });
    };
}
