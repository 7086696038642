import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, Header, Segment } from 'semantic-ui-react';

import MjernaJedinica from '../common_components/MjernaJedinica';
import AddNewButton from '../common_components/AddNewButton';
import { generateKey } from '../../utils/KeyGenerator';
import localization from '../../locales/localization';

const styles = {};

class MjerneJedinice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mjerneJedinice: this.props.mjerneJedinice,
            novaMjernaJedinica: false
        };
        this.onDeleteMjernaJedinica = this.onDeleteMjernaJedinica.bind(this);
        this.onAddNew = this.onAddNew.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            mjerneJedinice: nextProps.mjerneJedinice,
            novaMjernaJedinica: false
        });
    }

    onDeleteMjernaJedinica(index) {
        this.setState({
            mjerneJedinice: this.state.mjerneJedinice.slice(0, index).concat(this.state.mjerneJedinice.slice(index + 1)),
            novaMjernaJedinica: this.state.novaMjernaJedinica && !(index === this.state.mjerneJedinice.length - 1)
        });
    }

    onAddNew(e) {
        this.setState({
            mjerneJedinice: [
                ...this.state.mjerneJedinice,
                {
                    naziv: '',
                    nova: true,
                    isCjelobrojna: true
                }
            ],
            novaMjernaJedinica: true
        });
    }

    render() {
        return (
            <Segment tertiary>
                <Header as="h2">{localization.postavke.mjerneJedinice}</Header>
                <Card.Group itemsPerRow={3} stackable>
                    {this.state.mjerneJedinice.map((mj, index) => {
                        return (
                            <Card key={index}>
                                <Card.Content textAlign="center">
                                    <MjernaJedinica novo={mj.nova} mjernaJedinica={mj} onDelete={this.onDeleteMjernaJedinica.bind(this, index)} />
                                </Card.Content>
                            </Card>
                        );
                    })}
                    {!this.state.novaMjernaJedinica ? (
                        <AddNewButton
                            key={generateKey()}
                            text={localization.postavke.dodajNovuMjernuJedinicu}
                            name="novaMjernaJedinica"
                            onAddNew={this.onAddNew}
                        />
                    ) : null}
                </Card.Group>
            </Segment>
        );
    }
}

MjerneJedinice.propTypes = {
    history: PropTypes.object.isRequired,
    mjerneJedinice: PropTypes.array.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {
        mjerneJedinice: state.mjerneJediniceReducer.mjerneJedinice || []
    };
};

let mapDispatchToProps = function(dispatch) {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MjerneJedinice);
