import localization from '../locales/localization';
import enums from '../utils/Enums';

let calculateIznos = (ulazi, prop) => {
    let iznos = 0;
    ulazi.forEach(u => {
        iznos += u.kolicina * u[prop];
    });
    return iznos;
};

export default {
    SUB_OTKUPNI_BLOK: (blagajnickaStavka, isKuna) => {
        let iznos = -calculateIznos(blagajnickaStavka.skladisniUlazi, isKuna ? 'jedNabavnaCijenaHrk' : 'jedNabavnaCijena');
        if (blagajnickaStavka.rbrStorniranogSUBa) {
            iznos = -iznos;
        }
        return {
            imeStavke: localization.blagajnickiDnevnici.otkup + ' ' + localization.common.br + ' ' + blagajnickaStavka.rbrOtkupniBlok,
            iznos: iznos
        };
    },
    RACUN_FISKALNI: (blagajnickaStavka, isKuna) => {
        let iznos = isKuna ? blagajnickaStavka.iznosGotovinaHrk : blagajnickaStavka.iznosGotovina;
        if (blagajnickaStavka.rbrStorniranogRacuna) {
            iznos = -iznos;
        }
        return {
            imeStavke: localization.blagajnickiDnevnici.fiskalniRacun + ' ' + localization.common.br + ' ' + blagajnickaStavka.rbrFiskalniRacun,
            iznos: iznos
        };
    },
    UPLATNICA: (blagajnickaStavka, isKuna) => {
        let resolveUplataIsplata = iznos => {
            if (iznos < 0) {
                return localization.blagajnickiDnevnici.napravljenaIsplata + ' ' + localization.common.iz;
            }
            return localization.blagajnickiDnevnici.napravljenaUplata + ' ' + localization.common.u;
        };
        let resolveTipBlagajne = tipBlagajne => {
            if (tipBlagajne === enums.tipBlagajneRadnje.OTKUPNA) {
                return localization.blagajnickiDnevnici.otkupna + ' ' + localization.blagajnickiDnevnici.blagajna;
            }
            return localization.blagajnickiDnevnici.fiskalna + ' ' + localization.blagajnickiDnevnici.blagajna;
        };
        return {
            imeStavke: resolveUplataIsplata(isKuna ? blagajnickaStavka.iznosHrk : blagajnickaStavka.iznos) + ' ' + resolveTipBlagajne(blagajnickaStavka.tipBlagajne),
            iznos: isKuna ? blagajnickaStavka.iznosHrk : blagajnickaStavka.iznos
        };
    },
    PRETOK: (blagajnickaStavka, isKuna, tipDnevnika) => {
        let resolveTipBlagajni = tipBlagajne => {
            let src, dest;
            if (tipBlagajne === enums.tipBlagajneRadnje.OTKUPNA) {
                dest = localization.blagajnickiDnevnici.otkupna + ' ' + localization.blagajnickiDnevnici.blagajna;
                src = localization.blagajnickiDnevnici.fiskalna + ' ' + localization.blagajnickiDnevnici.blagajna;
            } else {
                dest = localization.blagajnickiDnevnici.fiskalna + ' ' + localization.blagajnickiDnevnici.blagajna;
                src = localization.blagajnickiDnevnici.otkupna + ' ' + localization.blagajnickiDnevnici.blagajna;
            }
            return [src, dest];
        };
        let resolveIznos = (tipOdredisneBlagajne, iznos) => {
            if (tipOdredisneBlagajne === tipDnevnika) {
                return iznos;
            } else {
                return -iznos;
            }
        };
        let [src, dest] = resolveTipBlagajni(blagajnickaStavka.tipOdredisneBlagajne);
        return {
            imeStavke: localization.blagajnickiDnevnici.interniPrijenos + ' ' + localization.common.iz + ' ' + src + ' ' + localization.common.u + ' ' + dest,
            iznos: resolveIznos(blagajnickaStavka.tipOdredisneBlagajne, isKuna ? blagajnickaStavka.iznosHrk : blagajnickaStavka.iznos)
        };
    }
};
