import React from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Form, Grid, Header, Popup, Select } from 'semantic-ui-react';
import localization from '../../locales/localization';

const styles = {
    containerBox: {
        textAlign: 'left',
        paddingBottom: 12
    }
};

export default class FilterSortButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: this.props.activeFilter,
            sort: this.props.activeSort
        };
        this.applyChanges = this.applyChanges.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            filter: nextProps.activeFilter,
            sort: nextProps.activeSort
        });
    }

    applyChanges(e, { name, value }) {
        if (name === 'filter') {
            this.props.callback(value, this.state.sort);
        } else {
            this.props.callback(this.state.filter, value);
        }
    }

    render() {
        return (
            <Popup trigger={<Button icon="filter" basic color="black" content={this.props.buttonName || localization.common.filterIsort} />} flowing on="click">
                <Grid columns={this.props.filteringOptions && this.props.sortingOptions ? 2 : 1} centered divided>
                    {this.props.filteringOptions ? (
                        <Grid.Column textAlign="center">
                            <Header as="h4">{localization.common.filtriranje}</Header>
                            <div style={styles.containerBox}>
                                {this.props.filteringOptions.map((f, index) => {
                                    return (
                                        <Form.Field key={index}>
                                            <Checkbox
                                                radio
                                                label={f}
                                                name="filter"
                                                value={index}
                                                checked={this.state.filter === index}
                                                onChange={this.applyChanges}
                                            />
                                        </Form.Field>
                                    );
                                })}
                            </div>
                        </Grid.Column>
                    ) : null}
                    {this.props.sortingOptions ? (
                        <Grid.Column textAlign="center">
                            <Header as="h4">{localization.common.sortiranje}</Header>
                            <div style={styles.containerBox}>
                                {this.props.sortingOptions.map((s, index) => {
                                    return (
                                        <Form.Field key={index}>
                                            <Checkbox
                                                radio
                                                label={s}
                                                name="sort"
                                                value={index}
                                                checked={this.state.sort === index}
                                                onChange={this.applyChanges}
                                            />
                                        </Form.Field>
                                    );
                                })}
                            </div>
                        </Grid.Column>
                    ) : null}
                </Grid>
            </Popup>
        );
    }
}

FilterSortButton.propTypes = {
    buttonName: PropTypes.string,
    filteringOptions: PropTypes.array,
    sortingOptions: PropTypes.array,
    activeFilter: PropTypes.number,
    activeSort: PropTypes.number,
    callback: PropTypes.func
};
