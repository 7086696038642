let initialState = {
    reccuringTroskoviDefinitions: [],
    cachedReccuringDefinitions: false,
    grupeOneTimeTroskova: [],
    cachedOneTimeGrupe: false
};

export default function troskoviReducer(state = initialState, action) {
    switch (action.type) {
        case 'GET_RECCURING_TROSKOVI_DEFINITIONS': {
            return Object.assign({}, state, {
                reccuringTroskoviDefinitions: [...action.reccuringTroskoviDefinitions],
                cachedReccuringDefinitions: true
            });
        }
        case 'GET_GRUPE_ONE_TIME_TROSKOVA': {
            return Object.assign({}, state, {
                grupeOneTimeTroskova: [...action.grupeOneTimeTroskova],
                cachedOneTimeGrupe: true
            });
        }
        default:
            return state;
    }
}
