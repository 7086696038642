import $ from 'jquery';

import * as success from '../actions/BlagajneActions';

import endpoint from '../environments/endpoints';
import { showAlert } from '../utils/ErrorHandler';

export function getStanjeBlagajni(poslovnicaId, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'blagajna/stanje?poslovnicaId=' + poslovnicaId
        })
            .done(data => {
                dispatch(success.getStanjeBlagajni(data));
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function uplataBlagajnaOtkupa(uplatnica, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'blagajna/otkupa/uplati',
            data: JSON.stringify(uplatnica)
        })
            .done(data => {
                onSuccess();
                dispatch(success.getStanjeBlagajni(data));
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function uplataFiskalnaBlagajna(uplatnica, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'blagajna/fiskalna/uplati',
            data: JSON.stringify(uplatnica)
        })
            .done(data => {
                onSuccess();
                dispatch(success.getStanjeBlagajni(data));
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function getBlagajnickiDnevnici(poslovnicaId, startDateTime, endDateTimeIncl, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url:
                endpoint.apiEndpoint +
                'blagajna/dnevnik?poslovnicaId=' +
                poslovnicaId +
                '&startDateTime=' +
                startDateTime +
                'T00:00' +
                '&endDateTimeIncl=' +
                endDateTimeIncl +
                'T23:59'
        })
            .done(data => {
                let fiskBlagajna = {
                    pocetnoStanje: data.startStanjeBlagajni.stanjeFiskBlag,
                    zavrsnoStanje: data.endStanjeBlagajni.stanjeFiskBlag,
                    stavke: data.stavkeFiskalneBlagajne,
                    firma: data.firma
                };
                let blagajnaOtkup = {
                    pocetnoStanje: data.startStanjeBlagajni.stanjeBlagOtkupa,
                    zavrsnoStanje: data.endStanjeBlagajni.stanjeBlagOtkupa,
                    pocetnoStanjeHrk: data.startStanjeBlagajni.stanjeBlagOtkupaHrk,
                    zavrsnoStanjeHrk: data.endStanjeBlagajni.stanjeBlagOtkupaHrk,
                    stavke: data.stavkeBlagajneOtkupa,
                    firma: data.firma
                };
                dispatch(success.getBlagajnickiDnevnikFisk(fiskBlagajna));
                dispatch(success.getBlagajnickiDnevnikOtkup(blagajnaOtkup));
                if (onSuccess) {
                    onSuccess(fiskBlagajna, blagajnaOtkup);
                }
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function interniPrijenosUBlagajnu(uplatnica, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'blagajna/pretoci',
            data: JSON.stringify(uplatnica)
        })
            .done(data => {
                onSuccess();
                dispatch(success.getStanjeBlagajni(data));
            })
            .fail(error => {
                showAlert(error);
            });
    };
}
