import $ from 'jquery';

import * as success from '../actions/AuthActions';

import endpoint from '../environments/endpoints';
import { showAlert } from '../utils/ErrorHandler';

export function login(credentials, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'auth/login',
            data: JSON.stringify(credentials)
        })
            .done(data => {
                dispatch(success.userLogin(data));
                onSuccess(data);
            })
            .fail(error => {
                onError();
                showAlert(error);
            });
    };
}
