export function getStanjeSkladista(stanjeSkladista) {
    return { type: 'GET_STANJE_SKLADISTA', stanjeSkladista };
}

export function getListaArtikalaZaMaknutiSOglasavanja(lista) {
    return { type: 'GET_LISTA_ZA_MAKNUTI_S_OGLASAVANJA', lista };
}

export function resetStanjeCache() {
    return { type: 'RESET_STANJE_CACHE' };
}

export function resetListaZaMaknutiSOglasavanjaCache() {
    return { type: 'RESET_LISTA_ZA_MAKNUTI_S_OGLASAVANJA_CACHE' };
}
