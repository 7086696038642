import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import FormatIznosa from '../../FormatIznosa';
import enums from '../../../../utils/Enums';

const styles = {
    kolicinaIcijena: {
        fontFamily: 'monospace'
    }
};

export default class FixedOtkupBodyCells extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Table.Cell>{this.props.item.kategorija.ime + (this.props.item.potkategorija ? ' / ' + this.props.item.potkategorija.ime : '')}</Table.Cell>
                <Table.Cell textAlign="right" collapsing style={styles.kolicinaIcijena}>
                    {this.props.item.kolicina}
                </Table.Cell>
                {sessionStorage.userRole === enums.tipKorisnika.VLASNIK ? (
                    <React.Fragment>
                        <Table.Cell textAlign="right" collapsing style={styles.kolicinaIcijena}>
                            <FormatIznosa value={this.props.item.jedNabavnaCijena} suffix={null} />
                        </Table.Cell>
                        <Table.Cell textAlign="right" collapsing style={styles.kolicinaIcijena}>
                            <FormatIznosa value={this.props.item.kolicina * this.props.item.jedNabavnaCijena} />
                        </Table.Cell>
                    </React.Fragment>
                ) : null}
            </React.Fragment>
        );
    }
}

FixedOtkupBodyCells.propTypes = {
    item: PropTypes.object.isRequired
};
