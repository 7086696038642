import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Dimmer, Header, Loader, Modal, Segment } from 'semantic-ui-react';

import localization from '../../locales/localization';
import endpoints from '../../environments/endpoints';
import enums from '../../utils/Enums';
import icons from '../../utils/DefinedIcons';
import { storeDocumentAndGenerateKey } from '../../utils/PrintingDocumentsHandler';
import * as ArtikliApi from '../../data_access/ArtikliApi';
import * as ProcjenaDataAccess from '../../data_access/ProcjenaDataAccess';
import { bindActionCreators } from 'redux';
import OdabirKlijenta from '../common_components/OdabirKlijenta';
import OdabirArtiklaPaginated from '../common_components/OdabirArtiklaPaginated';
import Napomene from '../common_components/Napomene';
import ImageUploadModal from '../common_components/ImageUploadModal';

const styles = {
    confirmButton: {
        textAlign: 'center',
        marginBottom: 16
    },
    commentsButton: {
        marginTop: 8
    }
};

class NovaProcjena extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            client: undefined,
            items: [],
            sluzbenaNapomena: '',
            nesluzbenaNapomena: '',
            loader: false,
            zahtjevZaProcjenomId: undefined
        };
        this.handleOdabirKlijenta = this.handleOdabirKlijenta.bind(this);
        this.handleOdabirArtikla = this.handleOdabirArtikla.bind(this);
        this.showHidePotvrdaZahtjevaZaProcjenu = this.showHidePotvrdaZahtjevaZaProcjenu.bind(this);
        this.enableNapraviZahtjevZaProcjenuButton = this.enableNapraviZahtjevZaProcjenuButton.bind(this);
        this.checkKomponenteZahtjevaZaProcjenu = this.checkKomponenteZahtjevaZaProcjenu.bind(this);
        this.onChangeNapomena = this.onChangeNapomena.bind(this);
        this.showLoader = this.showLoader.bind(this);
        this.hideLoader = this.hideLoader.bind(this);
        this.napraviZahtjevZaProcjenu = this.napraviZahtjevZaProcjenu.bind(this);
        this.openZahtjevZaProcjenu = this.openZahtjevZaProcjenu.bind(this);
        this.redirect = this.redirect.bind(this);
        this.fetchPredmeti = this.fetchPredmeti.bind(this);
    }

    handleOdabirKlijenta(clientSelected) {
        this.setState({ client: clientSelected }, this.enableNapraviZahtjevZaProcjenuButton);
    }

    handleOdabirArtikla(itemsSelected) {
        this.setState({ items: [...itemsSelected] }, this.enableNapraviZahtjevZaProcjenuButton);
    }

    showHidePotvrdaZahtjevaZaProcjenu(value, callback) {
        if (typeof callback === 'function') {
            this.setState({ potvrdaZahtjevaZaProcjenu: value }, callback);
        } else {
            this.setState({ potvrdaZahtjevaZaProcjenu: value });
        }
    }

    enableNapraviZahtjevZaProcjenuButton() {
        this.setState({
            napraviZahtjevZaProcjenuEnabled: this.checkKomponenteZahtjevaZaProcjenu()
        });
    }

    checkKomponenteZahtjevaZaProcjenu() {
        if (!this.state.client) {
            return false;
        }
        if (!this.state.items.length) {
            return false;
        } else {
            for (let i = 0; i < this.state.items.length; i++) {
                if (!this.state.items[i].kolicina || !this.state.items[i].cijena) {
                    return false;
                }
            }
        }
        return true;
    }

    onChangeNapomena(e, { name, value }) {
        this.setState({ [name]: value });
    }

    showLoader(callback) {
        this.setState(
            {
                loader: true
            },
            callback
        );
    }

    hideLoader(callback, data) {
        this.setState(
            {
                loader: false
            },
            callback.bind(this, data)
        );
    }

    napraviZahtjevZaProcjenu() {
        let zahtjevZaProcjenu = {
            klijentId: this.state.client.id,
            poslovnicaId: JSON.parse(sessionStorage.poslovnicaId),
            sluzbenaNapomena: this.state.sluzbenaNapomena || null,
            nesluzbenaNapomena: this.state.nesluzbenaNapomena || null,
            procjene: this.state.items.map(predmet => {
                return {
                    predmetId: predmet.id,
                    kolicina: parseFloat(predmet.kolicina),
                    ocekivanaJedCijena: parseFloat(predmet.cijena)
                };
            })
        };
        this.props.procjenaDataAccess.createZahtjevZaProcjenu(zahtjevZaProcjenu, this.hideLoader.bind(this, this.openZahtjevZaProcjenu));
    }

    openZahtjevZaProcjenu(zahtjevZaProcjenu) {
        window.open(endpoints.appEndpoints.print.zahtjevZaProcjenom + storeDocumentAndGenerateKey(zahtjevZaProcjenu), '_blank');
        this.setState({
            zahtjevZaProcjenomId: zahtjevZaProcjenu.id
        });
    }

    redirect() {
        this.props.history.goBack();
    }

    fetchPredmeti(q, page, pageSize, onFetchSuccess, onFetchError) {
        ArtikliApi.getArtikliPaginated(
            q, page, pageSize, ArtikliApi.SORT.naziv, ArtikliApi.TIP_ARTIKLA.predmet,
            onFetchSuccess, onFetchError
        )
    }

    render() {
        return (
            <div>
                <Segment tertiary>
                    <Header as="h2">{localization.procjena.novaProcjena}</Header>
                </Segment>
                <Segment tertiary>
                    <OdabirKlijenta onClientSelected={this.handleOdabirKlijenta} kontaktiRequired={true} />
                </Segment>
                <Segment tertiary>
                    <OdabirArtiklaPaginated fetchArtikli={this.fetchPredmeti} onItemSelected={this.handleOdabirArtikla} type={enums.tipTabliceArtikala.PROCJENA_EDITABLE} />
                </Segment>
                <Napomene
                    sluzbenaNapomenaHeader={localization.procjena.sluzbenaNapomenaProcjena}
                    nesluzbenaNapomenaHeader={localization.procjena.nesluzbenaNapomenaProcjena}
                    onChangeNapomena={this.onChangeNapomena}
                />
                <div style={styles.confirmButton}>
                    <Modal
                        open={this.state.potvrdaZahtjevaZaProcjenu}
                        closeOnDimmerClick={false}
                        trigger={
                            <Button
                                color="green"
                                content={localization.procjena.napraviZahtjevaZaProcjenu}
                                disabled={!this.state.napraviZahtjevZaProcjenuEnabled}
                                onClick={this.showHidePotvrdaZahtjevaZaProcjenu.bind(this, true)}
                            />
                        }
                    >
                        {!this.state.zahtjevZaProcjenomId ? (
                            <React.Fragment>
                                <Header icon={icons.procjena} content={localization.procjena.potvrdaZahtjevaZaProcjenu} />
                                <Modal.Content>
                                    <p>{localization.procjena.pitanjePotvrdeZahtjevaZaProcjenu}</p>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button
                                        icon="remove"
                                        content={localization.common.odustani}
                                        onClick={this.showHidePotvrdaZahtjevaZaProcjenu.bind(this, false)}
                                    />
                                    <Button
                                        icon="checkmark"
                                        color="green"
                                        content={localization.procjena.napraviZahtjevaZaProcjenu}
                                        onClick={this.showLoader.bind(this, this.napraviZahtjevZaProcjenu)}
                                    />
                                </Modal.Actions>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Header icon="image" content={localization.procjena.ucitavanjeSlika} />
                                <Modal.Content>
                                    <ImageUploadModal
                                        saveImages={this.props.procjenaDataAccess.dodajSlikeNaProcjenu.bind(this, this.state.zahtjevZaProcjenomId)}
                                        closeModal={this.showHidePotvrdaZahtjevaZaProcjenu.bind(this, false, this.redirect)}
                                    />
                                </Modal.Content>
                            </React.Fragment>
                        )}
                    </Modal>
                </div>
                <Dimmer active={this.state.loader} page>
                    <Loader />
                </Dimmer>
            </div>
        );
    }
}

NovaProcjena.propTypes = {
    history: PropTypes.object.isRequired,
    procjenaDataAccess: PropTypes.object.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {
    };
};

let mapDispatchToProps = function(dispatch) {
    return {
        procjenaDataAccess: bindActionCreators(ProcjenaDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NovaProcjena);
