import localization from '../locales/localization';
import enums from './Enums';

export default {
    'PREDRACUN.TO_RACUN': radnja => {
        return {
            imeRadnje:
                localization.dnevnaEvidencija.izradenRacun +
                ' ' +
                localization.common.br +
                ' ' +
                (radnja.racun.rbrFiskalniRacun || radnja.racun.rbrTransakcijskiRacun) +
                ' ' +
                localization.dnevnaEvidencija.izPredracuna +
                ' ' +
                localization.common.br +
                ' ' +
                radnja.predracun.rbrPredracun
        };
    },
    'RACUN.CREATE': radnja => {
        return {
            imeRadnje:
                localization.dnevnaEvidencija.kreiranRacun +
                ' ' +
                localization.common.br +
                ' ' +
                (radnja.racun.rbrFiskalniRacun || radnja.racun.rbrTransakcijskiRacun)
        };
    },
    'RACUN.STORNO': radnja => {
        return {
            imeRadnje:
                localization.dnevnaEvidencija.storniranRacun +
                ' ' +
                localization.common.br +
                ' ' +
                (radnja.racun.rbrFiskalniRacun || radnja.racun.rbrTransakcijskiRacun)
        };
    },
    'REZERVACIJA.CREATE': radnja => {
        return {
            imeRadnje: localization.dnevnaEvidencija.kreiranaRezervacija + ' ' + localization.common.br + ' ' + radnja.zahtjevZaRezervaciju.rbrRezervacije
        };
    },
    'REZERVACIJA.PRODUZENJE': radnja => {
        return {
            imeRadnje: localization.dnevnaEvidencija.produzenaRezervacija + ' ' + localization.common.br + ' ' + radnja.zahtjevZaRezervaciju.rbrRezervacije
        };
    },
    'REZERVACIJA.PODIZANJE': radnja => {
        return {
            imeRadnje: localization.dnevnaEvidencija.podignutaRezervacija + ' ' + localization.common.br + ' ' + radnja.zahtjevZaRezervaciju.rbrRezervacije
        };
    },
    'SUB.CREATE': radnja => {
        if (radnja.sklUlazniBlok.tip === enums.tipSkladisnogUlaznogBloka.OTKUPNI_BLOK) {
            return {
                imeRadnje: localization.dnevnaEvidencija.kreiranOtkup + ' ' + localization.common.br + ' ' + radnja.sklUlazniBlok.rbrOtkupniBlok
            };
        } else {
            return {
                imeRadnje: localization.dnevnaEvidencija.kreiranaPrimka + ' ' + localization.common.br + ' ' + radnja.sklUlazniBlok.rbrPrimka
            };
        }
    },
    'SUB.STORNO': radnja => {
        if (radnja.sklUlazniBlok.tip === enums.tipSkladisnogUlaznogBloka.OTKUPNI_BLOK) {
            return {
                imeRadnje: localization.dnevnaEvidencija.storniranOtkup + ' ' + localization.common.br + ' ' + radnja.sklUlazniBlok.rbrOtkupniBlok
            };
        } else {
            return {
                imeRadnje: localization.dnevnaEvidencija.storniranaPrimka + ' ' + localization.common.br + ' ' + radnja.sklUlazniBlok.rbrPrimka
            };
        }
    },
    UPLATA: radnja => {
        let resolveUplataIsplata = iznos => {
            if (iznos < 0) {
                return localization.dnevnaEvidencija.napravljenaIsplata + ' ' + localization.common.iz;
            }
            return localization.dnevnaEvidencija.napravljenaUplata + ' ' + localization.common.u;
        };
        let resolveTipBlagajne = tipBlagajne => {
            if (tipBlagajne === enums.tipBlagajneRadnje.OTKUPNA) {
                return localization.dnevnaEvidencija.otkupna + ' ' + localization.dnevnaEvidencija.blagajna;
            }
            return localization.dnevnaEvidencija.fiskalna + ' ' + localization.dnevnaEvidencija.blagajna;
        };
        let contentObject = {
            imeRadnje: resolveUplataIsplata(radnja.uplatnica.iznos) + ' ' + resolveTipBlagajne(radnja.uplatnica.tipBlagajne)
        };
        return contentObject;
    },
    PRETAKANJE: radnja => {
        let resolveTipBlagajni = tipBlagajne => {
            let src, dest;
            if (tipBlagajne === enums.tipBlagajneRadnje.OTKUPNA) {
                dest = localization.dnevnaEvidencija.otkupna + ' ' + localization.dnevnaEvidencija.blagajna;
                src = localization.dnevnaEvidencija.fiskalna;
            } else {
                dest = localization.dnevnaEvidencija.fiskalna + ' ' + localization.dnevnaEvidencija.blagajna;
                src = localization.dnevnaEvidencija.otkupna;
            }
            return [src, dest];
        };
        let [src, dest] = resolveTipBlagajni(radnja.pretok.tipOdredisneBlagajne);
        return {
            imeRadnje: localization.dnevnaEvidencija.interniPrijenos + ' ' + localization.common.iz + ' ' + src + ' ' + localization.common.u + ' ' + dest
        };
    }
};
