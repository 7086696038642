import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Accordion, Button, Dimmer, Form, Grid, Header, Icon, Loader, Message, Modal, Pagination, Search, Segment, Table } from 'semantic-ui-react';

import localization from '../../locales/localization';
import endpoints from '../../environments/endpoints';
import enums from '../../utils/Enums';
import icons from '../../utils/DefinedIcons';
import { storeDocumentAndGenerateKey } from '../../utils/PrintingDocumentsHandler';
import moment from 'moment';
import * as ProdajaDataAccess from '../../data_access/ProdajaDataAccess';
import * as PredracuniApi from '../../data_access/PredracuniApi';
import { bindActionCreators } from 'redux';
import SearchResultsMessage from '../common_components/SearchResultsMessage';
import Diskusija from '../common_components/Diskusija';
import OtkupProdajaContent from './content_components/OtkupProdajaContent';
import FormatIznosa from '../common_components/FormatIznosa';
import FilterSortButton from '../common_components/FilterSortButton';

// TODO #pagination #refactor Extract this to some other place when pagination for other components is implemented
//      and we solve the Redux/DataAccess dilemma.
const MAX_API_FETCH_ATTEMPTS = 3;
// TODO #pagination #refactor Probably extract somewhere else and use across all search components
const SEARCH_DEBOUNCE_RATE_MS = 1000;

const styles = {
    headerTable: {
        padding: '.75em 1em'
    },
    iconCell: {
        width: 50
    },
    brojPredracunaCell: {
        width: 180
    },
    klijentCell: {
        width: 350
    },
    ukupnaCijenaCell: {
        width: 200
    },
    accordionHeaderContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    accordionHeaderText: {
        paddingLeft: 4,
        paddingRight: 2,
        paddingBottom: '0.5em'
    },
    actionButtons: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    semaforStyle: {
        marginLeft: 4
    }
};

const potvrdeEnums = {
    racunIzPredracun: 'racunIzPredracun',
    arhiviranjePredracuna: 'arhiviranjePredracuna'
};

const LIST_STATE = {
    initial: 'initial',
    empty: 'empty',
    noResult: 'noResult',
    populated: 'populated',
    error: 'error'
};

const ITEMS_PER_PAGE = 10;

class Predracuni extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            activePredracun: [],
            activeComments: [],
            activePotvrda: undefined,
            sluzbenaNapomena: '',
            nesluzbenaNapomena: '',
            noveNapomene: [],
            loader: false,
            filter: 0,
            pageActive: 1
        };
        this.onNoviPredracun = this.onNoviPredracun.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
        this.applyFilterAndSort = this.applyFilterAndSort.bind(this);
        this.resetSearchComponent = this.resetSearchComponent.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.onChangeNapomene = this.onChangeNapomene.bind(this);
        this.resetNapomene = this.resetNapomene.bind(this);
        this.calculateUkupno = this.calculateUkupno.bind(this);
        this.onChangeNovaNapomena = this.onChangeNovaNapomena.bind(this);
        this.expandPredracun = this.expandPredracun.bind(this);
        this.expandComments = this.expandComments.bind(this);
        this.ispisPredracuna = this.ispisPredracuna.bind(this);
        this.arhiviranjePredracuna = this.arhiviranjePredracuna.bind(this);
        this.izradaRacunaIzPredracuna = this.izradaRacunaIzPredracuna.bind(this);
        this.dodajNapomenu = this.dodajNapomenu.bind(this);
        this.resetNapomena = this.resetNapomena.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.showLoader = this.showLoader.bind(this);
        this.hideLoader = this.hideLoader.bind(this);
        this.fetchPredracuni = this.fetchPredracuni.bind(this);
        this.refreshPredracuni = this.refreshPredracuni.bind(this);
        this.getPoslovnicaIdForFilter = this.getPoslovnicaIdForFilter.bind(this);
        this.renderInitialComponent = this.renderInitialComponent.bind(this);
        this.renderEmptyComponent = this.renderEmptyComponent.bind(this);
        this.renderNoResultComponent = this.renderNoResultComponent.bind(this);
        this.renderErrorComponent = this.renderErrorComponent.bind(this);
        this.renderListComponent = this.renderListComponent.bind(this);
        this.debouncedFetchPredracuni = _.debounce(function (filter, page) {
            this.fetchPredracuni(filter, page);
        }, SEARCH_DEBOUNCE_RATE_MS).bind(this);

        if (sessionStorage.userRole !== enums.tipKorisnika.DJELATNIK) {
            this.FILTER_ITEMS = [{
                name: localization.common.svePoslovnice,
                apiParam: null
            }];
            this.props.poslovnice.forEach(p => this.FILTER_ITEMS.push({
                name: p.naziv,
                apiParam: p.id
            }));
        }
    }

    componentWillMount() {
        this.resetSearchComponent();
    }

    onNoviPredracun() {
        this.props.history.push(endpoints.appEndpoints.prodaja, 'predracun');
    }

    onChangePage(e, { activePage }) {
        this.fetchPredracuni(this.state.filter, activePage);
    }

    applyFilterAndSort(filter, sort) {
        this.fetchPredracuni(filter);
    }

    resetSearchComponent() {
        this.setState({
            listState: LIST_STATE.initial,
            fetchAttempts: 0
        }, () => {
            this.fetchPredracuni();
        });
    }

    handleSearchChange(e, { value }) {
        this.setState({
            value: value
        }, () => {
            this.debouncedFetchPredracuni();
        });
    }

    onChangeNapomene(e, { name, value }) {
        this.setState({ [name]: value });
    }

    resetNapomene() {
        this.setState({
            sluzbenaNapomena: '',
            nesluzbenaNapomena: ''
        });
    }

    calculateUkupno(predracun) {
        let ukupno = 0;
        predracun.stavkePredracuna.forEach(sp => {
            ukupno += sp.kolicina * sp.jedCijena;
        });
        return ukupno;
    }

    onChangeNovaNapomena(id, e, { value }) {
        let noveNapomene = [];
        let index = this.state.noveNapomene.findIndex(el => el.id === id);
        if (index > -1) {
            noveNapomene = [...this.state.noveNapomene];
            noveNapomene[index] = {
                id: id,
                napomena: value
            };
        } else {
            noveNapomene = [
                ...this.state.noveNapomene,
                {
                    id: id,
                    napomena: value
                }
            ];
        }
        this.setState({
            noveNapomene: noveNapomene
        });
    }

    expandPredracun(e, { index }) {
        let activePredracun = [];
        if (this.state.activePredracun.includes(index)) {
            activePredracun = _.xor(this.state.activePredracun, [index]);
        } else {
            activePredracun = [...this.state.activePredracun, index];
        }
        this.setState({
            activePredracun: activePredracun
        });
    }

    expandComments(e, { index }) {
        let activeComments = [];
        if (this.state.activeComments.includes(index)) {
            activeComments = _.xor(this.state.activeComments, [index]);
        } else {
            activeComments = [...this.state.activeComments, index];
        }
        this.setState({
            activeComments: activeComments
        });
    }

    ispisPredracuna(predracun) {
        window.open(endpoints.appEndpoints.print.predracun + storeDocumentAndGenerateKey(predracun), '_blank');
    }

    arhiviranjePredracuna(predracunId) {
        this.showLoader();
        this.props.prodajaDataAccess.arhivirajPredracun(
            predracunId,
            () => this.hideLoader(() => this.hideModal(() => this.refreshPredracuni())),
            () => this.hideLoader()
        );
    }

    izradaRacunaIzPredracuna(predracunId) {
        this.showLoader();
        this.props.prodajaDataAccess.predracunToRacun(
            predracunId,
            {
                sluzbenaNapomena: this.state.sluzbenaNapomena || null,
                nesluzbenaNapomena: this.state.nesluzbenaNapomena || null
            },
            () => this.hideLoader(() => this.hideModal(() => this.refreshPredracuni())),
            () => this.hideLoader()
        );
    }

    dodajNapomenu(predracunId) {
        this.showLoader();
        this.props.prodajaDataAccess.dodavanjeNesluzbeneNapomeneNaPredracun(
            predracunId,
            this.state.noveNapomene.find(el => el.id === predracunId).napomena,
            () => this.hideLoader(() => this.resetNapomena(predracunId, () => this.refreshPredracuni())),
            () => this.hideLoader()
        );
    }

    resetNapomena(id, callback) {
        this.setState({
            noveNapomene: _.reject(this.state.noveNapomene, (napomena) => napomena.id === id)
        }, callback);
    }

    showModal(name, sifra) {
        this.setState({
            activePotvrda: {
                type: name,
                id: sifra
            }
        });
    }

    hideModal(callback) {
        this.setState({ activePotvrda: undefined }, callback);
    }

    showLoader() {
        this.setState(
            {
                loader: true
            }
        );
    }

    hideLoader(callback) {
        this.setState(
            {
                loader: false
            },
            callback
        );
    }

    fetchPredracuni(filter = this.state.filter, page = 1) {

        let nextStateCommon = {
            loader: false,
            fetchAttempts: 0,
            filter: filter
        };

        let onFetchSuccess = (data) => {
            if (data.items.length !== 0) {
                let nextStateNonEmpty = {
                    listState: LIST_STATE.populated,
                    predracuni: data.items,
                    pages: data.totalPages,
                    pageActive: page
                };
                this.setState(Object.assign({}, nextStateCommon, nextStateNonEmpty));

            } else {

                let fetchedAll = this.state.value === '';

                let newListState = fetchedAll ? LIST_STATE.empty : LIST_STATE.noResult;
                let nextStateEmpty = {
                    listState: newListState,
                    predracuni: []
                };

                this.setState(Object.assign({}, nextStateCommon, nextStateEmpty));
            }
        };

        let onFetchError = () => {
            let fetchAttempts = this.state.fetchAttempts + 1;
            if (fetchAttempts === MAX_API_FETCH_ATTEMPTS) {
                let nextStateError = {
                    listState: LIST_STATE.error
                };
                this.setState(Object.assign({}, nextStateCommon, nextStateError));
            } else {
                this.setState({
                    fetchAttempts: fetchAttempts
                }, () => {
                    this.fetchPredracuni(filter, page);
                });
            }

        };

        this.showLoader();

        PredracuniApi.getPredracuniPaginated(
            this.state.value, page, ITEMS_PER_PAGE, this.getPoslovnicaIdForFilter(filter),
            null, null, onFetchSuccess, onFetchError
        );
    }

    refreshPredracuni() {
        this.fetchPredracuni(this.state.filter, this.state.pageActive);
    }

    getPoslovnicaIdForFilter(filter) {
        return this.FILTER_ITEMS
            ? this.FILTER_ITEMS[filter].apiParam
            : JSON.parse(sessionStorage.poslovnicaId);
    }

    renderInitialComponent() {
        return (
            <SearchResultsMessage
                icon={icons.predracun}
                header={localization.common.ucitavanje}
                content={localization.common.molimoPricekajte}
            />
        );
    }

    renderEmptyComponent() {
        return (
            <SearchResultsMessage
                icon={icons.predracun}
                header={localization.predracuni.nemaPredracuna}
                content={undefined}
            />
        );
    }

    renderNoResultComponent() {
        return (
            <SearchResultsMessage
                icon={icons.predracun}
                header={localization.common.unesenaPretragaNemaRezultata}
                content={localization.common.pokusajDrugacijuPretragu}
            />
        );
    }

    renderErrorComponent() {
        return (
            <SearchResultsMessage
                icon={icons.predracun}
                header={localization.common.dogodilaSeGreska}
                content={localization.common.pokusajtePonovno}
            />
        );
    }

    renderListComponent() {
        return (
            <React.Fragment>
                <Grid.Row>
                    <Grid.Column>
                        <Message style={styles.headerTable}>
                            <Table structured stackable>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell style={styles.iconCell} />
                                        <Table.HeaderCell style={styles.brojPredracunaCell}>
                                            {localization.predracuni.brojPredracuna}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell style={styles.klijentCell}>{localization.predracuni.klijent}</Table.HeaderCell>
                                        <Table.HeaderCell style={styles.ukupnaCijenaCell}>{localization.common.ukupnaCijena}</Table.HeaderCell>
                                        <Table.HeaderCell>{localization.predracuni.datumIVrijemePredracuna}</Table.HeaderCell>
                                        <Table.HeaderCell>{localization.predracuni.datumIstekaPredracuna}</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                            </Table>
                        </Message>
                    </Grid.Column>
                </Grid.Row>
                {this.state.predracuni.map(predracun => {
                    return (
                        <Grid.Row key={predracun.id}>
                            <Grid.Column verticalAlign="middle">
                                <Accordion fluid styled>
                                    <Accordion.Title
                                        active={this.state.activePredracun.includes(predracun.id)}
                                        index={predracun.id}
                                        onClick={this.expandPredracun}
                                    >
                                        <Table structured stackable>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.Cell style={styles.iconCell}>
                                                        <Icon name="dropdown" />
                                                    </Table.Cell>
                                                    <Table.Cell style={styles.brojPredracunaCell}>{predracun.rbrPredracun}</Table.Cell>
                                                    <Table.Cell style={styles.klijentCell}>
                                                        {predracun.klijent.tipKlijenta === enums.tipKlijenta.FIZICKA_OSOBA
                                                            ? predracun.klijent.ime + ' ' + predracun.klijent.prezime
                                                            : predracun.klijent.naziv}
                                                    </Table.Cell>
                                                    <Table.Cell style={styles.ukupnaCijenaCell}>
                                                        <FormatIznosa value={this.calculateUkupno(predracun)} />
                                                    </Table.Cell>
                                                    <Table.Cell>{moment(predracun.datumIVrijeme).format('DD.MM.YYYY. HH:mm')}</Table.Cell>
                                                    <Table.Cell>{moment(predracun.datumIstekaPredracuna).format('DD.MM.YYYY.')}</Table.Cell>
                                                </Table.Row>
                                            </Table.Header>
                                        </Table>
                                    </Accordion.Title>
                                    <Accordion.Content active={this.state.activePredracun.includes(predracun.id)}>
                                        <OtkupProdajaContent
                                            klijent={predracun.klijent}
                                            type={enums.tipTabliceArtikala.RACUN_FIXED}
                                            artikli={predracun.stavkePredracuna.map(si => {
                                                return Object.assign({}, si, {
                                                    sifra: si.sifraArtikla,
                                                    naziv: si.nazivArtikla,
                                                    cijena: si.jedCijena
                                                });
                                            })}
                                        />
                                        <div style={styles.actionButtons}>
                                            <Button
                                                icon="print"
                                                color="green"
                                                content={localization.predracuni.ispisPredracuna}
                                                onClick={this.ispisPredracuna.bind(this, predracun)}
                                            />
                                            {!predracun.isArhiviran ? (
                                                <React.Fragment>
                                                    {sessionStorage.userRole === enums.tipKorisnika.DJELATNIK ? (
                                                        <Modal
                                                            open={
                                                                this.state.activePotvrda &&
                                                                this.state.activePotvrda.type === potvrdeEnums.racunIzPredracun &&
                                                                this.state.activePotvrda.id === predracun.id
                                                            }
                                                            closeOnDimmerClick={false}
                                                            onClose={this.resetNapomene}
                                                            trigger={
                                                                <Button
                                                                    icon="copy"
                                                                    color="green"
                                                                    content={localization.predracuni.izradaRacunaIzPredracuna}
                                                                    onClick={() => this.showModal(potvrdeEnums.racunIzPredracun, predracun.id)}
                                                                />
                                                            }
                                                        >
                                                            <Header icon="copy" content={localization.common.potvrda} />
                                                            <Modal.Content>
                                                                <p>{localization.predracuni.izradaRacunaPitanjePotvrde}</p>
                                                                <Form>
                                                                    <Form.TextArea
                                                                        rows={2}
                                                                        placeholder={localization.prodaja.sluzbenaNapomenaRacuna}
                                                                        name="sluzbenaNapomena"
                                                                        onChange={this.onChangeNapomene}
                                                                    />
                                                                    <Form.TextArea
                                                                        rows={2}
                                                                        placeholder={localization.prodaja.nesluzbenaNapomenaRacuna}
                                                                        name="nesluzbenaNapomena"
                                                                        onChange={this.onChangeNapomene}
                                                                    />
                                                                </Form>
                                                            </Modal.Content>
                                                            <Modal.Actions>
                                                                <Button
                                                                    icon="remove"
                                                                    content={localization.common.odustani}
                                                                    onClick={() => this.hideModal()}
                                                                />
                                                                <Button
                                                                    icon="copy"
                                                                    color="green"
                                                                    content={localization.predracuni.izradi}
                                                                    onClick={() => this.izradaRacunaIzPredracuna(predracun.id)}
                                                                />
                                                            </Modal.Actions>
                                                        </Modal>
                                                    ) : null}
                                                    <Modal
                                                        open={
                                                            this.state.activePotvrda &&
                                                            this.state.activePotvrda.type === potvrdeEnums.arhiviranjePredracuna &&
                                                            this.state.activePotvrda.id === predracun.id
                                                        }
                                                        closeOnDimmerClick={false}
                                                        trigger={
                                                            <Button
                                                                icon="archive"
                                                                color="red"
                                                                content={localization.predracuni.arhiviranjePredracuna}
                                                                onClick={() => this.showModal(potvrdeEnums.arhiviranjePredracuna, predracun.id)}
                                                            />
                                                        }
                                                    >
                                                        <Header icon="archive" content={localization.common.potvrda} />
                                                        <Modal.Content>
                                                            <p>{localization.predracuni.arhiviranjePitanjePotvrde}</p>
                                                        </Modal.Content>
                                                        <Modal.Actions>
                                                            <Button
                                                                icon="remove"
                                                                content={localization.common.odustani}
                                                                onClick={() => this.hideModal()}
                                                            />
                                                            <Button
                                                                icon="copy"
                                                                color="red"
                                                                content={localization.predracuni.arhiviraj}
                                                                onClick={() => this.arhiviranjePredracuna(predracun.id)}
                                                            />
                                                        </Modal.Actions>
                                                    </Modal>
                                                </React.Fragment>
                                            ) : null}
                                        </div>
                                        {predracun.sluzbenaNapomena ? (
                                            <Message>
                                                <Message.Header>{localization.common.sluzbenaNapomena}</Message.Header>
                                                <p>{predracun.sluzbenaNapomena}</p>
                                            </Message>
                                        ) : null}
                                        <Accordion fluid styled>
                                            <Accordion.Title
                                                active={this.state.activeComments.includes(predracun.id)}
                                                index={predracun.id}
                                                onClick={this.expandComments}
                                            >
                                                <Header as="h5" dividing style={styles.accordionHeaderContainer}>
                                                    <Icon name="dropdown" />
                                                    {localization.common.napomene}
                                                    {predracun.nesluzbeneNapomene.length ? (
                                                        <Icon name="exclamation circle" color="red" style={styles.semaforStyle} />
                                                    ) : null}
                                                </Header>
                                            </Accordion.Title>
                                            <Accordion.Content active={this.state.activeComments.includes(predracun.id)}>
                                                <Diskusija
                                                    napomene={predracun.nesluzbeneNapomene}
                                                    textAreaValue={
                                                        this.state.noveNapomene.find(el => el.id === predracun.id)
                                                            ? this.state.noveNapomene.find(el => el.id === predracun.id).napomena
                                                            : ''
                                                    }
                                                    onChangeNovaNapomena={this.onChangeNovaNapomena.bind(this, predracun.id)}
                                                    onDodajNapomenu={() => this.dodajNapomenu(predracun.id)}
                                                />
                                            </Accordion.Content>
                                        </Accordion>
                                    </Accordion.Content>
                                </Accordion>
                            </Grid.Column>
                        </Grid.Row>
                    );
                })}
                <Grid.Row centered>
                    <Pagination
                        activePage={this.state.pageActive}
                        totalPages={this.state.pages}
                        onPageChange={this.onChangePage}
                        ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
                        firstItem={{ content: <Icon name="angle double left" />, icon: true }}
                        lastItem={{ content: <Icon name="angle double right" />, icon: true }}
                        prevItem={{ content: <Icon name="angle left" />, icon: true }}
                        nextItem={{ content: <Icon name="angle right" />, icon: true }}
                    />
                </Grid.Row>
            </React.Fragment>
        );
    }

    render() {
        return (
            <div>
                <Segment tertiary>
                    <Grid stackable>
                        <Grid.Row columns={6}>
                            <Grid.Column verticalAlign="middle">
                                <Header as="h2">{localization.predracuni.predracuni}</Header>
                            </Grid.Column>
                            <Grid.Column verticalAlign="middle">
                                <Search
                                    input={{ fluid: true }}
                                    loading={this.state.isSearchLoading}
                                    onSearchChange={this.handleSearchChange}
                                    value={this.state.value}
                                    open={false}
                                />
                            </Grid.Column>
                            {sessionStorage.userRole === enums.tipKorisnika.DJELATNIK ? (
                                <React.Fragment>
                                    <Grid.Column />
                                    <Grid.Column />
                                    <Grid.Column />
                                    <Grid.Column textAlign="right" verticalAlign="middle">
                                        <Button
                                            icon="asterisk"
                                            basic
                                            color="black"
                                            content={localization.predracuni.noviPredracun}
                                            onClick={this.onNoviPredracun}
                                        />
                                    </Grid.Column>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <Grid.Column />
                                    <Grid.Column />
                                    <Grid.Column />
                                    <Grid.Column textAlign="right" verticalAlign="middle">
                                        <FilterSortButton
                                            buttonName={localization.common.filtriranje}
                                            filteringOptions={this.FILTER_ITEMS.map((f) => f.name)}
                                            activeFilter={this.state.filter}
                                            callback={this.applyFilterAndSort}
                                        />
                                    </Grid.Column>
                                </React.Fragment>
                            )}
                        </Grid.Row>
                        {(() => {
                            switch (this.state.listState) {
                                case LIST_STATE.initial:
                                    return this.renderInitialComponent();
                                case LIST_STATE.populated:
                                    return this.renderListComponent();
                                case LIST_STATE.empty:
                                    return this.renderEmptyComponent();
                                case LIST_STATE.noResult:
                                    return this.renderNoResultComponent();
                                case LIST_STATE.error:
                                    return this.renderErrorComponent();
                            }
                        })()}
                    </Grid>
                </Segment>
                <Dimmer active={this.state.loader} page>
                    <Loader />
                </Dimmer>
            </div>
        );
    }
}

Predracuni.propTypes = {
    history: PropTypes.object.isRequired,
    poslovnice: PropTypes.array.isRequired,
    prodajaDataAccess: PropTypes.object.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {
        poslovnice: state.mjestaReducer.poslovnice || []
    };
};

let mapDispatchToProps = function(dispatch) {
    return {
        prodajaDataAccess: bindActionCreators(ProdajaDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Predracuni);
