import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, Header, Segment } from 'semantic-ui-react';

import OneTimeGrupa from '../common_components/OneTimeGrupa';
import AddNewButton from '../common_components/AddNewButton';
import { generateKey } from '../../utils/KeyGenerator';
import localization from '../../locales/localization';

class GrupeOneTimeTroskova extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            grupe: this.props.grupe,
            novaGrupa: false
        };
        this.onDeleteGrupa = this.onDeleteGrupa.bind(this);
        this.onAddNew = this.onAddNew.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            grupe: nextProps.grupe,
            novaGrupa: false
        });
    }

    onDeleteGrupa(index) {
        this.setState({
            grupe: this.state.grupe.slice(0, index).concat(this.state.grupe.slice(index + 1)),
            novaGrupa: this.state.novaGrupa && !(index === this.state.grupe.length - 1)
        });
    }

    onAddNew(e) {
        this.setState({
            grupe: [
                ...this.state.grupe,
                {
                    ime: '',
                    nova: true
                }
            ],
            novaGrupa: true
        });
    }

    render() {
        return (
            <Segment tertiary>
                <Header as="h2">{localization.postavke.grupeJednokratnihTroskova}</Header>
                <Card.Group itemsPerRow={4} stackable>
                    {this.state.grupe.map((g, index) => {
                        return (
                            <Card key={index}>
                                <Card.Content textAlign="center">
                                    <OneTimeGrupa nova={g.nova} grupa={g} onDelete={this.onDeleteGrupa.bind(this, index)} />
                                </Card.Content>
                            </Card>
                        );
                    })}
                    {!this.state.novaGrupa ? (
                        <AddNewButton
                            key={generateKey()}
                            text={localization.postavke.dodajNovuGrupuJednokratnogTroska}
                            name="novaGrupa"
                            onAddNew={this.onAddNew}
                        />
                    ) : null}
                </Card.Group>
            </Segment>
        );
    }
}

GrupeOneTimeTroskova.propTypes = {
    history: PropTypes.object.isRequired,
    grupe: PropTypes.array.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {
        grupe: state.troskoviReducer.grupeOneTimeTroskova || []
    };
};

let mapDispatchToProps = function(dispatch) {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(GrupeOneTimeTroskova);
