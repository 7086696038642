import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Header, Icon } from 'semantic-ui-react';

const styles = {
    iconColor: {
        color: 'rgba(0,0,0,.87)'
    }
};

class Menu extends React.Component {
    constructor(props) {
        super(props);
        this.handleMenu = this.handleMenu.bind(this);
    }

    handleMenu(e, { url }) {
        this.props.history.push(url);
    }

    render() {
        return (
            <Card.Group itemsPerRow={4} stackable>
                {this.props.menuItems.map((item, index) => {
                    return (
                        <Card key={index} style={styles.iconColor} url={item.url} onClick={this.handleMenu}>
                            <Card.Content textAlign="center">
                                <Icon name={item.icon} size="massive" />
                            </Card.Content>
                            <Card.Content extra>
                                <Header as="h4" textAlign="center">
                                    {item.text}
                                </Header>
                            </Card.Content>
                        </Card>
                    );
                })}
            </Card.Group>
        );
    }
}

Menu.propTypes = {
    menuItems: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired
};

export default Menu;
