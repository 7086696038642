import React from 'react';
import PropTypes from 'prop-types';
import localization from '../../../locales/localization';

const styles = {
    tvrtka: {
        marginBottom: 8,
        fontSize: 12
    },
    podaci: {
        margin: 0,
        fontSize: 10
    }
};

class ZaglavljeTvrtke extends React.Component {
    render() {
        return (
            <div>
                {this.props.small ? (
                    <p style={styles.tvrtka}>{this.props.firma.ime}</p>
                ) : (
                    <p style={styles.tvrtka}>
                        <b>{this.props.firma.ime}</b>
                    </p>
                )}
                <p style={styles.podaci}>{this.props.firma.sjedisteAdresa}</p>
                <p style={styles.podaci}>{this.props.firma.sjedistePostanskiBroj + ' ' + this.props.firma.sjedisteNaselje}</p>
                <p style={styles.podaci}>{localization.printingComponents.common.oib + ': ' + this.props.firma.oib}</p>
            </div>
        );
    }
}

ZaglavljeTvrtke.propTypes = {
    firma: PropTypes.object.isRequired,
    small: PropTypes.bool
};

export default ZaglavljeTvrtke;
