import $ from 'jquery';

import { resetStanjeCache } from '../actions/SkladisteActions';

import endpoint from '../environments/endpoints';
import enums from '../utils/Enums';
import { showAlert } from '../utils/ErrorHandler';

export function getMeduskladisnicaViewModel(mjestoId, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'meduskladisnice/vm?mjestoId=' + mjestoId
        })
            .done(data => {
                onSuccess(data);
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function createMeduskladisnica(meduskladisnica, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'meduskladisnice',
            data: JSON.stringify(meduskladisnica)
        })
            .done(data => {
                dispatch(resetStanjeCache());
                onSuccess(data);
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

// export function deleteMeduskladisnica(id, onSuccess, onError) {
//     return function (dispatch) {
//         $.ajax({
//             method: 'DELETE',
//             headers: {
//                 'Accept': 'application/json',
//                 'Content-Type': 'application/json',
//                 'Authorization': 'Bearer ' + sessionStorage.token
//             },
//             url: endpoint.apiEndpoint + 'meduskladisnice/' + id
//         }).done((data) => {
//             dispatch(getMeduskladisniceZaPoslovnicu(JSON.parse(sessionStorage.poslovnicaId)));
//             dispatch(resetStanjeCache());
//             onSuccess(data);
//         }).fail((error) => {

//         });
//     };
// }

export function izvrsiMeduskladisnicu(id, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'meduskladisnice/' + id + '/izvrsi'
        })
            .done(data => {
                dispatch(resetStanjeCache());
                onSuccess(data);
            })
            .fail(error => {
                showAlert(error);
                onError();
            });
    };
}

export function dodavanjeNesluzbeneNapomeneNaMeduskladisnicu(meduskladisnicaId, napomena, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'meduskladisnice/' + meduskladisnicaId + '/nesluzbene-napomene',
            data: napomena
        })
            .done(data => {
                onSuccess();
            })
            .fail(error => {
                showAlert(error);
                onError();
            });
    };
}

export function stornoMeduskladisnice(meduskladisnicaId, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'meduskladisnice/' + meduskladisnicaId + '/storniraj'
        })
            .done(data => {
                dispatch(resetStanjeCache());
                onSuccess();
            })
            .fail(error => {
                showAlert(error);
                onError();
            });
    };
}
