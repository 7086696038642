import React from 'react';
import PropTypes from 'prop-types';
import { Header, Segment } from 'semantic-ui-react';

import TablicaKlijenata from '../../common_components/table_fragments/TablicaKlijenata';
import TablicaUsluga from '../../common_components/table_fragments/TablicaUsluga';
import TablicaArtikala from '../../common_components/table_fragments/TablicaArtikala';
import enums from '../../../utils/Enums';
import localization from '../../../locales/localization';
import FormatIznosa from '../../common_components/FormatIznosa';

const styles = {
    klijentovPredmetContainer: {
        display: 'flex',
        marginBottom: 14
    },
    artikliZaNaplatuContainer: {
        display: 'flex'
    },
    leftContainer: {
        width: 100,
        display: 'flex',
        alignItems: 'center'
    },
    rightContainer: {
        width: 'calc(100% - 100px)'
    },
    rotatedText: {
        transform: 'rotate(-90deg)',
        textAlign: 'center'
    },
    kolicinaIcijena: {
        fontFamily: 'monospace'
    }
};

export default class RadniNalogContent extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Header as="h4">{localization.radniNalozi.klijent}</Header>
                <TablicaKlijenata klijent={this.props.radniNalog.klijent} />
                {this.props.imageUploadModal}
                {this.props.radniNalog.radniNalozi.map((rn, index) => {
                    return (
                        <Segment key={index}>
                            <TablicaUsluga
                                type={enums.tipTabliceArtikala.RADNINALOG_FIXED}
                                usluga={Object.assign({}, rn.uslugaInfo, {
                                    kolicina: rn.kolicinaUsluge,
                                    jedCijena: rn.ocekivanaJedCijenaUsluge
                                })}
                            />
                            {rn.klijentovPredmetInfo ? (
                                <div style={styles.klijentovPredmetContainer}>
                                    <div style={styles.leftContainer}>
                                        <Header as="h5" style={styles.rotatedText}>
                                            {localization.radniNalozi.klijentovPredmet}
                                        </Header>
                                    </div>
                                    <div style={styles.rightContainer}>
                                        <TablicaArtikala
                                            type={enums.tipTabliceArtikala.RADNINALOG_FIXED}
                                            artikli={[
                                                Object.assign({}, rn.klijentovPredmetInfo, {
                                                    kolicina: rn.kolicinaKlijentovogPredmeta,
                                                    vrijednost: rn.jedVrijednostKlijentovogPredmeta,
                                                    cijena: rn.jedVrijednostKlijentovogPredmeta
                                                })
                                            ]}
                                        />
                                    </div>
                                </div>
                            ) : null}
                            {rn.stavkeRadnogNaloga.length ? (
                                <div style={styles.artikliZaNaplatuContainer}>
                                    <div style={styles.leftContainer}>
                                        <Header as="h5" style={styles.rotatedText}>
                                            {localization.radniNalozi.artikliZaNaplatu}
                                        </Header>
                                    </div>
                                    <div style={styles.rightContainer}>
                                        <TablicaArtikala
                                            type={enums.tipTabliceArtikala.RACUN_FIXED}
                                            artikli={rn.stavkeRadnogNaloga.map(srn => {
                                                return Object.assign({}, srn, {
                                                    sifra: srn.sifraPredmeta,
                                                    naziv: srn.nazivPredmeta,
                                                    serijskiBroj: srn.serijskiBrojPredmeta,
                                                    cijena: srn.jedCijenaSkladisneJedinke,
                                                    jedCijena: srn.jedCijenaSkladisneJedinke
                                                });
                                            })}
                                        />
                                    </div>
                                </div>
                            ) : null}
                        </Segment>
                    );
                })}
                <Segment clearing>
                    <Header as="h4" floated="right">
                        {localization.common.ukupno + ': '}
                        <span style={styles.kolicinaIcijena}>
                            <FormatIznosa value={this.props.calculateFunction(this.props.radniNalog.radniNalozi)} />
                        </span>
                    </Header>
                </Segment>
            </React.Fragment>
        );
    }
}

RadniNalogContent.propTypes = {
    radniNalog: PropTypes.object.isRequired,
    imageUploadModal: PropTypes.node,
    calculateFunction: PropTypes.func.isRequired
};
