import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Dimmer, Checkbox, Grid, Header, Loader, Modal, Segment, Select } from 'semantic-ui-react';

import OdabirArtikla from '../common_components/OdabirArtikla';
import Napomene from '../common_components/Napomene';
import localization from '../../locales/localization';
import endpoints from '../../environments/endpoints';
import enums from '../../utils/Enums';
import icons from '../../utils/DefinedIcons';
import { storeDocumentAndGenerateKey } from '../../utils/PrintingDocumentsHandler';
import * as MeduskladisniceDataAccess from '../../data_access/MeduskladisniceDataAccess';
import { bindActionCreators } from 'redux';

const styles = {
    checkbox: {
        marginLeft: 24,
        marginRight: 24
    },
    confirmButton: {
        textAlign: 'center',
        marginBottom: 16
    }
};

class NovaMeduskladisnica extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            isUlazna: false,
            srcdest: undefined,
            sluzbenaNapomena: '',
            nesluzbenaNapomena: '',
            resetArtikli: undefined,
            viewModel: {
                mjesta: [],
                stavkeNaSkladistu: []
            },
            potvrdaMeduskladisnice: false,
            loader: true,
            napraviMeduskladisnicuEnabled: false
        };
        this.onViewModelLoaded = this.onViewModelLoaded.bind(this);
        this.handleOdabirArtikla = this.handleOdabirArtikla.bind(this);
        this.onChangeUlaznaMeduskladisnica = this.onChangeUlaznaMeduskladisnica.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeNapomena = this.onChangeNapomena.bind(this);
        this.resetArtikli = this.resetArtikli.bind(this);
        this.enableNapraviMeduskladisnicu = this.enableNapraviMeduskladisnicu.bind(this);
        this.checkKomponenteMeduskladsnice = this.checkKomponenteMeduskladsnice.bind(this);
        this.showHidePotvrdaMeduskladisnice = this.showHidePotvrdaMeduskladisnice.bind(this);
        this.showLoader = this.showLoader.bind(this);
        this.hideLoader = this.hideLoader.bind(this);
        this.napraviMeduskladisnicu = this.napraviMeduskladisnicu.bind(this);
        this.openMeduskladisnica = this.openMeduskladisnica.bind(this);

        this.props.meduskladisniceDataAccess.getMeduskladisnicaViewModel(JSON.parse(sessionStorage.poslovnicaId), this.onViewModelLoaded);
    }

    onViewModelLoaded(data) {
        data.mjesta = data.mjesta.filter(m => m.id !== JSON.parse(sessionStorage.poslovnicaId));
        this.setState(
            {
                viewModel: data,
                loader: false
            },
            this.enableNapraviMeduskladisnicu
        );
    }

    handleOdabirArtikla(itemsSelected) {
        this.setState({ items: [...itemsSelected] }, this.enableNapraviMeduskladisnicu);
    }

    onChangeUlaznaMeduskladisnica(e, { checked }) {
        this.setState(
            {
                isUlazna: checked,
                srcdest: undefined
            },
            !checked ? this.resetArtikli.bind(this, JSON.parse(sessionStorage.poslovnicaId)) : this.resetArtikli
        );
    }

    onChange(e, { value }) {
        this.setState(
            {
                srcdest: value
            },
            this.state.isUlazna ? this.resetArtikli.bind(this, value) : this.enableNapraviMeduskladisnicu
        );
    }

    onChangeNapomena(e, { name, value }) {
        this.setState({ [name]: value });
    }

    resetArtikli(mjesto) {
        this.setState(
            {
                items: [],
                resetArtikli: true
            },
            mjesto
                ? this.showLoader.bind(this, this.props.meduskladisniceDataAccess.getMeduskladisnicaViewModel.bind(this, mjesto, this.onViewModelLoaded))
                : this.enableNapraviMeduskladisnicu
        );
    }

    enableNapraviMeduskladisnicu() {
        this.setState({
            resetArtikli: undefined,
            napraviMeduskladisnicuEnabled: this.checkKomponenteMeduskladsnice()
        });
    }

    checkKomponenteMeduskladsnice() {
        if (!this.state.items.length) {
            return false;
        } else {
            for (let i = 0; i < this.state.items.length; i++) {
                if (!this.state.items[i].kolicina) {
                    return false;
                }
            }
        }
        if (this.state.srcdest === undefined) {
            return false;
        }
        return true;
    }

    showHidePotvrdaMeduskladisnice(value) {
        this.setState({ potvrdaMeduskladisnice: value });
    }

    showLoader(callback) {
        this.setState(
            {
                loader: true
            },
            callback
        );
    }

    hideLoader(callback, data) {
        this.setState(
            {
                loader: false
            },
            callback.bind(this, data)
        );
    }

    napraviMeduskladisnicu() {
        let meduskladisnica = {
            srcId: this.state.isUlazna ? this.state.srcdest : JSON.parse(sessionStorage.poslovnicaId),
            destId: this.state.isUlazna ? JSON.parse(sessionStorage.poslovnicaId) : this.state.srcdest,
            sluzbenaNapomena: this.state.sluzbenaNapomena || null,
            nesluzbenaNapomena: this.state.nesluzbenaNapomena || null,
            stavke: this.state.items.map(artikl => {
                return {
                    predmetId: artikl.predmet.id,
                    kolicina: parseFloat(artikl.kolicina)
                };
            })
        };
        this.props.meduskladisniceDataAccess.createMeduskladisnica(meduskladisnica, this.hideLoader.bind(this, this.openMeduskladisnica));
    }

    openMeduskladisnica(meduskladisnica) {
        window.open(endpoints.appEndpoints.print.meduskladisnica + storeDocumentAndGenerateKey(meduskladisnica), '_blank');
        this.props.history.goBack();
    }

    render() {
        return (
            <div>
                <Segment tertiary>
                    <Header as="h2">
                        {localization.meduskladisnica.novaMeduskladisnica}
                        <Checkbox
                            label={localization.meduskladisnica.ulaznaMeduskladisnica}
                            style={styles.checkbox}
                            onChange={this.onChangeUlaznaMeduskladisnica}
                            checked={this.state.isUlazna}
                        />
                    </Header>
                </Segment>
                <Segment tertiary>
                    <Grid stackable>
                        <Grid.Row columns={6}>
                            <Grid.Column verticalAlign="middle">
                                <Header as="h3">
                                    {!this.state.isUlazna ? localization.meduskladisnica.odrediste : localization.meduskladisnica.polaziste}
                                </Header>
                            </Grid.Column>
                            <Grid.Column verticalAlign="middle">
                                <Select
                                    options={this.state.viewModel.mjesta.map(mj => {
                                        return { key: mj.id, text: mj.naziv, value: mj.id };
                                    })}
                                    value={this.state.srcdest}
                                    onChange={this.onChange}
                                    search
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Segment tertiary>
                    <OdabirArtikla
                        artikli={this.state.viewModel.stavkeNaSkladistu.map(ss => {
                            let maxKolicina = 0;
                            ss.jedinke.forEach(j => {
                                maxKolicina += j.kolicina;
                            });
                            return Object.assign({}, ss, ss.predmet, {
                                maxKolicina: maxKolicina,
                                kolicina: maxKolicina >= 1 ? 1 : maxKolicina,
                                cijena: ss.jedProdajnaCijena
                            });
                        })}
                        onItemSelected={this.handleOdabirArtikla}
                        type={enums.tipTabliceArtikala.MEDUSKLADISNICA_EDITABLE}
                        title={localization.meduskladisnica.artikliSaSkladista}
                        newItemDisabled={true}
                        resetArtikli={this.state.resetArtikli}
                    />
                </Segment>
                <Napomene
                    sluzbenaNapomenaHeader={localization.meduskladisnica.sluzbenaNapomenaMeduskladisnice}
                    nesluzbenaNapomenaHeader={localization.meduskladisnica.nesluzbenaNapomenaMeduskladisnice}
                    onChangeNapomena={this.onChangeNapomena}
                />
                <div style={styles.confirmButton}>
                    <Modal
                        open={this.state.potvrdaMeduskladisnice}
                        closeOnDimmerClick={false}
                        trigger={
                            <Button
                                color="green"
                                content={localization.meduskladisnica.napraviMeduskladisnicu}
                                disabled={!this.state.napraviMeduskladisnicuEnabled}
                                onClick={this.showHidePotvrdaMeduskladisnice.bind(this, true)}
                            />
                        }
                    >
                        <Header icon={icons.meduskladisnica} content={localization.meduskladisnica.potvrdaMeduskladisnice} />
                        <Modal.Content>
                            <p>{localization.meduskladisnica.pitanjePotvrdeMeduskladisnice}</p>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button icon="remove" content={localization.common.odustani} onClick={this.showHidePotvrdaMeduskladisnice.bind(this, false)} />
                            <Button
                                icon="checkmark"
                                color="green"
                                content={localization.meduskladisnica.napraviMeduskladisnicu}
                                onClick={this.showLoader.bind(this, this.napraviMeduskladisnicu)}
                            />
                        </Modal.Actions>
                    </Modal>
                </div>
                <Dimmer active={this.state.loader} page>
                    <Loader />
                </Dimmer>
            </div>
        );
    }
}

NovaMeduskladisnica.propTypes = {
    history: PropTypes.object.isRequired,
    meduskladisniceDataAccess: PropTypes.object.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {};
};

let mapDispatchToProps = function(dispatch) {
    return {
        meduskladisniceDataAccess: bindActionCreators(MeduskladisniceDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NovaMeduskladisnica);
