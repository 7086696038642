import React from 'react';
import { Table } from 'semantic-ui-react';
import localization from '../../../../locales/localization';
import enums from '../../../../utils/Enums';

export default class FixedPrimkaHeaderCells extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Table.HeaderCell>{localization.artikl.kategorija}</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">{localization.otkup.noviPredmet}</Table.HeaderCell>
                <Table.HeaderCell textAlign="center" collapsing>
                    {localization.common.kolicina}
                </Table.HeaderCell>
                {sessionStorage.userRole === enums.tipKorisnika.VLASNIK ? (
                    <React.Fragment>
                        <Table.HeaderCell textAlign="center" collapsing>
                            {localization.otkup.jedinicnaCijenaOtkupa}
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center" collapsing>
                            {localization.common.otkupnaCijena}
                        </Table.HeaderCell>
                    </React.Fragment>
                ) : null}
            </React.Fragment>
        );
    }
}
