import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Dimmer, Grid, Header, Loader, Modal, Segment, Select } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';

import moment from 'moment';
import localization from '../../locales/localization';
import { bindActionCreators } from 'redux';
import OdabirKlijenta from '../common_components/OdabirKlijenta';
import OdabirArtikla from '../common_components/OdabirArtikla';
import enums from '../../utils/Enums';
import icons from '../../utils/DefinedIcons';
import Napomene from '../common_components/Napomene';
import * as RezervacijaDataAccess from '../../data_access/RezervacijaDataAccess';

const styles = {
    confirmButton: {
        textAlign: 'center',
        marginBottom: 16
    }
};

class RezervacijeImporter extends React.Component {
    constructor(props) {
        super(props);
        let poslovnicaId = this.props.poslovnice[0].id;
        this.state = {
            client: undefined,
            items: [],
            poslovnicaId: poslovnicaId,
            viewModel: [],
            datumIsteka: moment(),
            sluzbenaNapomena: '',
            nesluzbenaNapomena: '',
            obveznaNesluzbenaNapomena: false,
            napraviRezervacijuEnabled: false,
            potvrdaRezervacije: false,
            resetArtikli: undefined,
            loader: true
        };
        this.onViewModelLoaded = this.onViewModelLoaded.bind(this);
        this.handleOdabirKlijenta = this.handleOdabirKlijenta.bind(this);
        this.handleOdabirArtikla = this.handleOdabirArtikla.bind(this);
        this.onChangePoslovnica = this.onChangePoslovnica.bind(this);
        this.resetArtikli = this.resetArtikli.bind(this);
        this.onChangeDatumIsteka = this.onChangeDatumIsteka.bind(this);
        this.onChangeNapomena = this.onChangeNapomena.bind(this);
        this.enableNapraviRezervacijuButton = this.enableNapraviRezervacijuButton.bind(this);
        this.checkKomponenteRezervacije = this.checkKomponenteRezervacije.bind(this);
        this.showHidePotvrdaRezervacije = this.showHidePotvrdaRezervacije.bind(this);
        this.napraviRezervaciju = this.napraviRezervaciju.bind(this);
        this.redirect = this.redirect.bind(this);
        this.showLoader = this.showLoader.bind(this);
        this.hideLoader = this.hideLoader.bind(this);

        this.props.rezervacijaDataAccess.getRezervacijeImportViewModel(poslovnicaId, this.onViewModelLoaded);
    }

    onViewModelLoaded(data) {
        this.setState(
            {
                viewModel: data,
                loader: false
            },
            this.resetArtikli
        );
    }

    handleOdabirKlijenta(clientSelected) {
        this.setState({ client: clientSelected }, this.enableNapraviRezervacijuButton);
    }

    handleOdabirArtikla(itemsSelected) {
        this.setState({ items: [...itemsSelected] }, this.enableNapraviRezervacijuButton);
    }

    onChangePoslovnica(e, { name, value }) {
        this.setState(
            { [name]: value },
            this.showLoader.bind(this, this.props.rezervacijaDataAccess.getRezervacijeImportViewModel.bind(this, value, this.onViewModelLoaded))
        );
    }

    resetArtikli() {
        this.setState(
            {
                items: [],
                resetArtikli: true
            },
            this.enableNapraviRezervacijuButton
        );
    }

    onChangeDatumIsteka(date) {
        this.setState(
            {
                datumIsteka: date
            },
            this.enableNapraviRezervacijuButton
        );
    }

    onChangeNapomena(e, { name, value }) {
        this.setState({ [name]: value }, this.state.obveznaNesluzbenaNapomena ? this.enableNapraviRezervacijuButton : undefined);
    }

    enableNapraviRezervacijuButton() {
        this.setState({
            resetArtikli: undefined,
            napraviRezervacijuEnabled: this.checkKomponenteRezervacije()
        });
    }

    checkKomponenteRezervacije() {
        let checkItems = () => {
            if (!this.state.items.length) {
                return false;
            } else {
                for (let i = 0; i < this.state.items.length; i++) {
                    if (!this.state.items[i].cijena) {
                        return false;
                    }
                }
            }
            return true;
        };
        if (!this.state.client) {
            return false;
        }
        if (!checkItems()) {
            return false;
        }
        if (this.state.obveznaNesluzbenaNapomena && !this.state.nesluzbenaNapomena) {
            return false;
        }
        return true;
    }

    showHidePotvrdaRezervacije(value) {
        this.setState({ potvrdaRezervacije: value });
    }

    napraviRezervaciju() {
        let zahtjevZaRezervaciju = {
            poslovnicaId: this.state.poslovnicaId,
            fizickaOsobaId: this.state.client.id,
            sluzbenaNapomena: this.state.sluzbenaNapomena || null,
            nesluzbenaNapomena: this.state.nesluzbenaNapomena || null,
            datumIsteka: this.state.datumIsteka.format('YYYY-MM-DD'),
            rezervacijePostojecihJedinkiApiReq: this.state.items.map(item => {
                return {
                    skladisnaJedinkaId: item.selectedJedinka,
                    jedProdajnaCijena: parseFloat(item.cijena)
                };
            })
        };
        this.props.rezervacijaDataAccess.createZahtjevZaRezervacijuFromPostojeca(zahtjevZaRezervaciju, this.hideLoader.bind(this, this.redirect));
    }

    redirect() {
        this.props.history.goBack();
    }

    showLoader(callback) {
        this.setState(
            {
                loader: true
            },
            callback
        );
    }

    hideLoader(callback, data) {
        this.setState(
            {
                loader: false
            },
            callback.bind(this, data)
        );
    }

    render() {
        return (
            <div>
                <Segment tertiary>
                    <Header as="h2">{localization.rezervacija.importRezervacije}</Header>
                </Segment>
                <Segment tertiary>
                    <OdabirKlijenta
                        onClientSelected={this.handleOdabirKlijenta}
                        allowedClientType={enums.tipKlijenta.FIZICKA_OSOBA}
                        kontaktiRequired={true}
                    />
                </Segment>
                <Segment tertiary>
                    <Grid stackable>
                        <Grid.Row columns={6}>
                            <Grid.Column verticalAlign="middle">
                                <Header as="h3">{localization.rezervacija.poslovnica}</Header>
                            </Grid.Column>
                            <Grid.Column verticalAlign="middle">
                                <Select
                                    style={styles.poslovnicaButton}
                                    options={this.props.poslovnice.map(p => {
                                        return { key: p.id, text: p.naziv, value: p.id };
                                    })}
                                    name="poslovnicaId"
                                    onChange={this.onChangePoslovnica}
                                    value={this.state.poslovnicaId}
                                    compact
                                    required
                                    search
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Segment tertiary>
                    <OdabirArtikla
                        artikli={this.state.viewModel.map(ss => {
                            let jedinke = ss.jedinke.filter(j => j.isSlobodna);
                            return Object.assign({}, ss, ss.predmet, {
                                jedinke: jedinke,
                                selectedJedinka: jedinke[0].id
                            });
                        })}
                        onItemSelected={this.handleOdabirArtikla}
                        type={enums.tipTabliceArtikala.REZERVACIJA_IMPORT}
                        newItemDisabled={true}
                        resetArtikli={this.state.resetArtikli}
                    />
                </Segment>
                <Segment tertiary>
                    <Grid stackable>
                        <Grid.Row columns={6}>
                            <Grid.Column verticalAlign="middle">
                                <Header as="h3">{localization.rezervacija.datumIsteka}</Header>
                            </Grid.Column>
                            <Grid.Column verticalAlign="middle">
                                <DatePicker
                                    customInput={
                                        <Button fluid compact basic color="black">
                                            {this.state.datumIsteka.format('DD.MM.YYYY.')}
                                        </Button>
                                    }
                                    dateFormat="DD.MM.YYYY."
                                    locale="hr-HR"
                                    selected={this.state.datumIsteka}
                                    onChange={this.onChangeDatumIsteka}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Napomene
                    sluzbenaNapomenaHeader={localization.rezervacija.sluzbenaNapomenaRezervacije}
                    nesluzbenaNapomenaHeader={localization.rezervacija.nesluzbenaNapomenaRezervacije}
                    onChangeNapomena={this.onChangeNapomena}
                    required={this.state.obveznaNesluzbenaNapomena}
                    requiredText={localization.rezervacija.obveznaNesluzbenaNapomena}
                />
                <div style={styles.confirmButton}>
                    <Modal
                        open={this.state.potvrdaRezervacije}
                        closeOnDimmerClick={false}
                        trigger={
                            <Button
                                color="green"
                                content={localization.rezervacija.napraviRezervaciju}
                                disabled={!this.state.napraviRezervacijuEnabled}
                                onClick={this.showHidePotvrdaRezervacije.bind(this, true)}
                            />
                        }
                    >
                        <Header icon={icons.rezervacija} content={localization.rezervacija.potvrdaRezervacije} />
                        <Modal.Content>
                            <p>{localization.rezervacija.pitanjePotvrdeRezervacije}</p>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button icon="remove" content={localization.common.odustani} onClick={this.showHidePotvrdaRezervacije.bind(this, false)} />
                            <Button
                                icon="checkmark"
                                color="green"
                                content={localization.rezervacija.napraviRezervaciju}
                                onClick={this.showLoader.bind(this, this.napraviRezervaciju)}
                            />
                        </Modal.Actions>
                    </Modal>
                </div>
                <Dimmer active={this.state.loader} page>
                    <Loader />
                </Dimmer>
            </div>
        );
    }
}

RezervacijeImporter.propTypes = {
    history: PropTypes.object.isRequired,
    appPrefs: PropTypes.object.isRequired,
    poslovnice: PropTypes.array.isRequired,
    rezervacijaDataAccess: PropTypes.object.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {
        appPrefs: state.appPreferencesReducer.appPrefs,
        poslovnice: state.mjestaReducer.poslovnice || []
    };
};

let mapDispatchToProps = function(dispatch) {
    return {
        rezervacijaDataAccess: bindActionCreators(RezervacijaDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RezervacijeImporter);
