import $ from 'jquery';

import endpoint from '../environments/endpoints';
import { showAlert } from '../utils/ErrorHandler';

export function getBankTransactions(startDate, endDate, poslovnicaId, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'bank?poslovnicaId=' + poslovnicaId + '&startDate=' + startDate + '&endDateIncl=' + endDate
        })
            .done(data => {
                onSuccess(data);
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function confirmTransaction(data, startDate, endDate, poslovnicaId, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'bank',
            data: JSON.stringify(data)
        })
            .done(data => {
                dispatch(getBankTransactions(startDate, endDate, poslovnicaId, onSuccess, onError));
            })
            .fail(error => {
                showAlert(error);
            });
    };
}
