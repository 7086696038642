import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Dimmer, Grid, Image, Loader, Modal } from 'semantic-ui-react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import localization from '../../locales/localization';
import * as ImagesDataAccess from '../../data_access/ImagesDataAccess';

const styles = {
    imageInput: {
        cursor: 'pointer',
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        width: '100%',
        opacity: 0
    },
    actionButtons: {
        marginTop: 16,
        display: 'flex',
        justifyContent: 'flex-end'
    }
};

class ImageUploadModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            images: [],
            imagesForUpload: [],
            imagesForErasing: [],
            loader: false
        };
        this.isEmptyAtStart = this.isEmptyAtStart.bind(this);
        this.hasAnythingChanged = this.hasAnythingChanged.bind(this);
        this.getConfirmButtonText = this.getConfirmButtonText.bind(this);
        this.loadImages = this.loadImages.bind(this);
        this.onImagesLoaded = this.onImagesLoaded.bind(this);
        this.onUploadPicture = this.onUploadPicture.bind(this);
        this.setImagesForUpload = this.setImagesForUpload.bind(this);
        this.removeImage = this.removeImage.bind(this);
        this.prepareSlikeForUpload = this.prepareSlikeForUpload.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
        this.showLoader = this.showLoader.bind(this);
        this.hideLoader = this.hideLoader.bind(this);
    }

    componentWillMount() {
        if (!this.isEmptyAtStart()) {
            this.loadImages(this.props.imageIds);
        }
    }

    isEmptyAtStart() {
        return this.props.imageIds.length === 0;
    }

    hasAnythingChanged() {
        return this.state.imagesForUpload.length || this.state.imagesForErasing.length;
    }

    getConfirmButtonText() {
        return this.isEmptyAtStart() ? localization.common.spremiSlike : localization.common.spremiPromjene;
    }

    loadImages(imageIds) {
        this.showLoader();
        this.props.imagesDataAccess.getImages(
            imageIds,
            (images) => this.hideLoader(() => this.onImagesLoaded(images)),
            () => this.hideLoader()
        );
    }

    onImagesLoaded(images) {
        this.setState({
            images: images
        });
    }

    onUploadPicture(e) {
        let files = e.target.files;
        let imgs = [];
        for (let i = 0; i < files.length; i++) {
            let reader = new FileReader();
            reader.onloadend = () => {
                imgs.push({ original: reader.result, thumbnail: reader.result });
                if (imgs.length === files.length) {
                    this.setImagesForUpload(imgs);
                }
            };
            reader.readAsDataURL(files[i]);
        }
    }

    setImagesForUpload(imgs) {
        this.setState({
            imagesForUpload: this.state.imagesForUpload.concat(imgs)
        });
    }

    removeImage(index) {
        if (index < this.state.images.length) {
            let img = this.state.images[index];
            this.setState({
                images: this.state.images.slice(0, index).concat(this.state.images.slice(index + 1)),
                imagesForErasing: [...this.state.imagesForErasing, img.id]
            });
        } else {
            index = index - this.state.images.length;
            this.setState({
                imagesForUpload: this.state.imagesForUpload.slice(0, index).concat(this.state.imagesForUpload.slice(index + 1))
            });
        }
    }

    prepareSlikeForUpload() {
        return this.state.imagesForUpload.map(i => {
            let imageParts = i.original.split('data:')[1].split(';base64,');
            return {
                mime: imageParts[0],
                imgB64: imageParts[1]
            };
        });
    }

    onConfirm() {
        let slikeForUpload = this.prepareSlikeForUpload();
        this.showLoader();
        if (this.isEmptyAtStart()) {
            this.props.saveImages(slikeForUpload, () => this.hideLoader(this.props.closeModal));
        } else {
            this.props.saveImages(slikeForUpload, () => {
                this.props.deleteImages(this.state.imagesForErasing, () => this.hideLoader(this.props.closeModal));
            });
        }
    }

    showLoader(callback) {
        this.setState(
            {
                loader: true
            },
            callback
        );
    }

    hideLoader(callback) {
        this.setState(
            {
                loader: false
            },
            callback
        );
    }

    render() {
        let images = [].concat(this.state.images, this.state.imagesForUpload);
        return (
            <div>
                <Grid stackable>
                    <Grid.Row columns={images.length ? 4 : 3}>
                        <Grid.Column />
                        <Grid.Column verticalAlign="middle">
                            <Button fluid compact basic color="black">
                                <input type="file" multiple style={styles.imageInput} onChange={this.onUploadPicture} />
                                {localization.common.ucitajSlike}
                            </Button>
                        </Grid.Column>
                        {images.length ? (
                            <Grid.Column verticalAlign="middle">
                                <Modal
                                    trigger={
                                        <Button fluid compact basic color="black">
                                            {localization.common.pregledajSlike + ' (' + images.length + ')'}
                                        </Button>
                                    }
                                >
                                    <Modal.Header>{localization.rezervacija.slikeArtikla}</Modal.Header>
                                    <Modal.Content>
                                        <ImageGallery items={images} />
                                    </Modal.Content>
                                </Modal>
                            </Grid.Column>
                        ) : null}
                    </Grid.Row>
                    {images.length ? (
                        <Grid.Row columns={8}>
                            {images.map((img, index) => {
                                return (
                                    <Grid.Column key={index} verticalAlign="middle">
                                        <Image
                                            src={img.original}
                                            fluid
                                            label={{
                                                as: 'a',
                                                corner: 'right',
                                                color: 'red',
                                                icon: 'window close outline',
                                                onClick: this.removeImage.bind(this, index)
                                            }}
                                        />
                                    </Grid.Column>
                                );
                            })}
                        </Grid.Row>
                    ) : null}
                </Grid>
                <div style={styles.actionButtons}>
                    <Button icon="close" content={localization.common.odustani} onClick={this.props.closeModal} />
                    <Button
                        icon="image"
                        color="green"
                        disabled={!this.hasAnythingChanged()}
                        content={this.getConfirmButtonText()}
                        onClick={this.onConfirm}
                    />
                </div>
                <Dimmer active={this.state.loader} page>
                    <Loader />
                </Dimmer>
            </div>
        );
    }
}

ImageUploadModal.defaultProps = {
    imageIds: []
};

ImageUploadModal.propTypes = {
    imageIds: PropTypes.array,
    imagesDataAccess: PropTypes.object.isRequired,
    saveImages: PropTypes.func.isRequired,
    deleteImages: PropTypes.func,
    closeModal: PropTypes.func.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {};
};

let mapDispatchToProps = function(dispatch) {
    return {
        imagesDataAccess: bindActionCreators(ImagesDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageUploadModal);
