import $ from 'jquery';

import { resetStanjeCache, resetListaZaMaknutiSOglasavanjaCache } from '../actions/SkladisteActions';

import endpoint from '../environments/endpoints';
import { showAlert } from '../utils/ErrorHandler';

export function getProdajaViewModel(poslovnicaId, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'prodaja/vm?poslovnicaId=' + poslovnicaId
        })
            .done(data => {
                onSuccess(data);
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function createRacun(racun, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'prodaja/racuni',
            data: JSON.stringify(racun)
        })
            .done(data => {
                dispatch(resetStanjeCache());
                dispatch(resetListaZaMaknutiSOglasavanjaCache());
                onSuccess(data);
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function stornirajRacun(racunId, napomene, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'prodaja/racuni/' + racunId + '/storniraj',
            data: JSON.stringify(napomene)
        })
            .done(data => {
                dispatch(resetStanjeCache());
                dispatch(resetListaZaMaknutiSOglasavanjaCache());
                onSuccess();
            })
            .fail(error => {
                showAlert(error);
                onError();
            });
    };
}

export function dodavanjeNesluzbeneNapomeneNaRacun(racunId, napomena, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'prodaja/racuni/' + racunId + '/nesluzbene-napomene',
            data: napomena
        })
            .done(data => {
                onSuccess();
            })
            .fail(error => {
                showAlert(error);
                onError();
            });
    };
}

export function createPredracun(predracun, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'prodaja/predracuni',
            data: JSON.stringify(predracun)
        })
            .done(data => {
                dispatch(resetStanjeCache());
                onSuccess(data);
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function predracunToRacun(predracunId, napomene, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'prodaja/predracuni/' + predracunId + '/create-racun',
            data: JSON.stringify(napomene)
        })
            .done(data => {
                dispatch(resetStanjeCache());
                dispatch(resetListaZaMaknutiSOglasavanjaCache());
                onSuccess();
            })
            .fail(error => {
                showAlert(error);
                onError();
            });
    };
}

export function arhivirajPredracun(predracunId, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'prodaja/predracuni/' + predracunId + '/arhiviraj'
        })
            .done(data => {
                dispatch(resetStanjeCache());
                onSuccess();
            })
            .fail(error => {
                showAlert(error);
                onError();
            });
    };
}

export function dodavanjeNesluzbeneNapomeneNaPredracun(predracunId, napomena, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'prodaja/predracuni/' + predracunId + '/nesluzbene-napomene',
            data: napomena
        })
            .done(data => {
                onSuccess();
            })
            .fail(error => {
                showAlert(error);
                onError();
            });
    };
}
