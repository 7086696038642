import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Accordion, Button, Dimmer, Grid, Header, Icon, Loader, Message, Segment, Select, Table } from 'semantic-ui-react';

import DnevnaEvidencijaMappings from '../../utils/DnevnaEvidencijaMappings';
import localization from '../../locales/localization';
import * as DnevnaEvidencijaDataAccess from '../../data_access/DnevnaEvidencijaDataAccess';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

const styles = {
    dateContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    poslovnicaButton: {
        marginRight: 8
    },
    korisnikButton: {
        marginRight: 8
    },
    filterButton: {
        marginLeft: 8
    },
    headerTable: {
        padding: '.75em 1em'
    },
    iconCell: {
        width: 50
    },
    datumIVrijemeCell: {
        width: 250
    },
    korisnikCell: {
        width: 300
    }
};
const SVE_RADNJE = 'SVE';

let RADNJE = [
    {
        tip: SVE_RADNJE,
        ime: 'Sve radnje'
    }
];

class DnevnaEvidencija extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            korisnik: this.props.korisnici[0].id,
            poslovnicaId: this.props.poslovnice[0].id,
            dnevnaEvidencija: this.props.dnevnaEvidencija,
            radnje: this.extractRadnje(this.props.dnevnaEvidencija),
            selectedDate: moment(),
            activeItems: [],
            loader: true,
            filterRadnji: SVE_RADNJE
        };
        this.extractRadnje = this.extractRadnje.bind(this);
        this.onChangeSelectedDate = this.onChangeSelectedDate.bind(this);
        this.onChange = this.onChange.bind(this);
        this.filterDnevnaEvidencija = this.filterDnevnaEvidencija.bind(this);
        this.expand = this.expand.bind(this);
        this.onFilter = this.onFilter.bind(this);
        this.showLoader = this.showLoader.bind(this);
        this.hideLoader = this.hideLoader.bind(this);

        this.onFilter();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            korisnik: nextProps.korisnici[0].id,
            dnevnaEvidencija: nextProps.dnevnaEvidencija,
            radnje: this.extractRadnje(nextProps.dnevnaEvidencija),
            activeItems: [],
            loader: false,
            filterRadnji: SVE_RADNJE
        });
    }

    extractRadnje(radnje) {
        radnje = _.uniqBy(radnje, 'tipRadnje');
        return RADNJE.concat(
            radnje.map(radnja => {
                return {
                    tip: radnja.tipRadnje,
                    ime: DnevnaEvidencijaMappings.tipoviRadnji[radnja.tipRadnje](radnja)
                };
            })
        );
    }

    onChange(e, { name, value }) {
        this.setState({ [name]: value }, this.filterDnevnaEvidencija);
    }

    filterDnevnaEvidencija() {
        let res = this.props.dnevnaEvidencija;
        if (this.state.korisnik) {
            res = res.filter(radnja => radnja.user && radnja.user.id === this.state.korisnik);
        }
        if (this.state.filterRadnji !== SVE_RADNJE) {
            res = res.filter(radnja => radnja.tipRadnje === this.state.filterRadnji);
        }
        this.setState({ dnevnaEvidencija: res });
    }

    onChangeSelectedDate(date) {
        this.setState({
            selectedDate: date
        });
    }

    expand(e, { index }) {
        let activeItems = [];
        if (this.state.activeItems.includes(index)) {
            activeItems = _.xor(this.state.activeItems, [index]);
        } else {
            activeItems = [...this.state.activeItems, index];
        }
        this.setState({
            activeItems: activeItems
        });
    }

    onFilter() {
        let date = this.state.selectedDate.format('YYYY-MM-DD');
        this.props.dnevnaEvidencijaDataAccess.getDnevnaEvidencija(date, date, this.state.poslovnicaId, this.hideLoader);
    }

    showLoader(callback) {
        this.setState(
            {
                loader: true
            },
            callback
        );
    }

    hideLoader() {
        this.setState({
            loader: false
        });
    }

    render() {
        return (
            <div>
                <Segment tertiary>
                    <Grid stackable>
                        <Grid.Row columns={2}>
                            <Grid.Column verticalAlign="middle">
                                <Header as="h2">{localization.dnevnaEvidencija.dnevneRadnje}</Header>
                            </Grid.Column>
                            <Grid.Column verticalAlign="middle">
                                <div style={styles.dateContainer}>
                                    <Select
                                        style={styles.poslovnicaButton}
                                        options={this.props.poslovnice.map(p => {
                                            return { key: p.id, text: p.naziv, value: p.id };
                                        })}
                                        name="poslovnicaId"
                                        onChange={this.onChange}
                                        value={this.state.poslovnicaId}
                                        compact
                                        required
                                        search
                                    />
                                    <Select
                                        style={styles.korisnikButton}
                                        options={this.state.radnje.map(r => {
                                            return { key: r.tip, text: r.ime, value: r.tip };
                                        })}
                                        name="filterRadnji"
                                        onChange={this.onChange}
                                        value={this.state.filterRadnji}
                                        compact
                                        required
                                        search
                                    />
                                    <Select
                                        style={styles.korisnikButton}
                                        options={this.props.korisnici.map(k => {
                                            return { key: k.id, text: k.ime + ' ' + k.prezime, value: k.id };
                                        })}
                                        name="korisnik"
                                        onChange={this.onChange}
                                        value={this.state.korisnik}
                                        compact
                                        required
                                        search
                                    />
                                    <DatePicker
                                        customInput={
                                            <Button fluid compact basic size="large" color="black">
                                                {this.state.selectedDate.format('DD.MM.YYYY.')}
                                            </Button>
                                        }
                                        dateFormat="DD.MM.YYYY."
                                        locale="hr-HR"
                                        maxDate={moment()}
                                        selected={this.state.selectedDate}
                                        onChange={this.onChangeSelectedDate}
                                    />
                                    <Button
                                        icon="filter"
                                        basic
                                        color="black"
                                        size="small"
                                        style={styles.filterButton}
                                        content={localization.dnevnaEvidencija.filtriraj}
                                        onClick={this.showLoader.bind(this, this.onFilter)}
                                    />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Message style={styles.headerTable}>
                                    <Table structured stackable>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell style={styles.iconCell} />
                                                <Table.HeaderCell style={styles.datumIVrijemeCell}>
                                                    {localization.dnevnaEvidencija.datumIVrijemeRadnje}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>{localization.dnevnaEvidencija.radnja}</Table.HeaderCell>
                                                <Table.HeaderCell style={styles.korisnikCell}>{localization.dnevnaEvidencija.korisnik}</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                    </Table>
                                </Message>
                            </Grid.Column>
                        </Grid.Row>
                        {this.state.dnevnaEvidencija.map((radnja, index) => {
                            let mappedRadnja = DnevnaEvidencijaMappings.prikazRadnji[radnja.tipRadnje](radnja);
                            return (
                                <Grid.Row key={index}>
                                    <Grid.Column verticalAlign="middle">
                                        <Accordion fluid styled>
                                            <Accordion.Title active={this.state.activeItems.includes(index)} index={index} onClick={this.expand}>
                                                <Table structured stackable>
                                                    <Table.Header>
                                                        <Table.Row>
                                                            {mappedRadnja.content ? (
                                                                <Table.Cell style={styles.iconCell}>
                                                                    <Icon name="dropdown" />
                                                                </Table.Cell>
                                                            ) : (
                                                                <Table.Cell style={styles.iconCell} />
                                                            )}
                                                            <Table.Cell style={styles.datumIVrijemeCell}>
                                                                {moment(radnja.datumIVrijeme).format('DD.MM.YYYY. HH:mm')}
                                                            </Table.Cell>
                                                            <Table.Cell>{mappedRadnja.imeRadnje}</Table.Cell>
                                                            <Table.Cell style={styles.korisnikCell}>
                                                                {radnja.user ? radnja.user.ime + ' ' + radnja.user.prezime : '-'}
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    </Table.Header>
                                                </Table>
                                            </Accordion.Title>
                                            {mappedRadnja.content ? (
                                                <Accordion.Content active={this.state.activeItems.includes(index)}>{mappedRadnja.content}</Accordion.Content>
                                            ) : null}
                                        </Accordion>
                                    </Grid.Column>
                                </Grid.Row>
                            );
                        })}
                    </Grid>
                </Segment>
                <Dimmer active={this.state.loader} page>
                    <Loader />
                </Dimmer>
            </div>
        );
    }
}

DnevnaEvidencija.propTypes = {
    history: PropTypes.object.isRequired,
    korisnici: PropTypes.array.isRequired,
    poslovnice: PropTypes.array.isRequired,
    dnevnaEvidencija: PropTypes.array.isRequired,
    dnevnaEvidencijaDataAccess: PropTypes.object.isRequired
};

let mapStateToProps = function(state, ownProps) {
    let korisnici = [
        {
            id: 0,
            ime: localization.dnevnaEvidencija.svi,
            prezime: localization.dnevnaEvidencija.korisnici
        }
    ];
    korisnici = korisnici.concat(state.korisniciReducer.korisnici);
    return {
        korisnici: korisnici,
        dnevnaEvidencija: state.dnevnaEvidencijaReducer.dnevnaEvidencija,
        poslovnice: state.mjestaReducer.poslovnice || []
    };
};

let mapDispatchToProps = function(dispatch) {
    return {
        dnevnaEvidencijaDataAccess: bindActionCreators(DnevnaEvidencijaDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DnevnaEvidencija);
