import { generateKey } from './KeyGenerator';
import moment from 'moment';

export function storeDocumentAndGenerateKey(document) {
    let key = generateKey();
    sessionStorage.setItem(key, JSON.stringify(document));
    return key;
}

export function getRbrForDocument(rbr, datumDokumenta) {
    return rbr + '-' + moment(datumDokumenta).format('YYYY');
}

export function findItemWithLatestDate(lista) {
    let latestItem;
    lista.forEach(i => {
        if (latestItem) {
            if (moment(i.datumIVrijeme).isAfter(moment(latestItem.datumIVrijeme))) {
                latestItem = i;
            }
        } else {
            latestItem = i;
        }
    });
    return latestItem;
}
