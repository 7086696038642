import $ from 'jquery';

import * as troskoviSuccess from '../actions/TroskoviActions';
import * as blagajneSuccess from '../actions/BlagajneActions';

import endpoint from '../environments/endpoints';
import { showAlert } from '../utils/ErrorHandler';

export function getTroskoviViewModel(startDate, endDateIncl, poslovnicaId, onSuccess, onError) {
    let url = endpoint.apiEndpoint + 'troskovi/vm?startDate=' + startDate + '&endDateIncl=' + endDateIncl;
    if (poslovnicaId !== undefined) {
        url += '&poslovnicaId=' + poslovnicaId;
    }
    return function(dispatch) {
        $.ajax({
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: url
        })
            .done(data => {
                onSuccess(data);
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function getPaidDismissedTroskovi(startDate, endDateIncl, poslovnicaId, onSuccess, onError) {
    let url = endpoint.apiEndpoint + 'troskovi/paid-dismiss?startDate=' + startDate + '&endDateIncl=' + endDateIncl;
    if (poslovnicaId !== undefined) {
        url += '&poslovnicaId=' + poslovnicaId;
    }
    return function(dispatch) {
        $.ajax({
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: url
        })
            .done(data => {
                onSuccess(data);
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function getAllReccuringTroskoviDefinitions(onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'troskovi/recurring/definitions'
        })
            .done(data => {
                dispatch(troskoviSuccess.getReccuringTroskoviDefinitions(data));
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function createReccuringTrosakDefinition(trosak, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'troskovi/recurring/definitions',
            data: JSON.stringify(trosak)
        })
            .done(data => {
                dispatch(getAllReccuringTroskoviDefinitions());
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function activateReccuringTrosak(trosakId, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'troskovi/recurring/definitions/' + trosakId + '/activate'
        })
            .done(data => {
                dispatch(getAllReccuringTroskoviDefinitions());
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function deactivateReccuringTrosak(trosakId, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'troskovi/recurring/definitions/' + trosakId + '/deactivate'
        })
            .done(data => {
                dispatch(getAllReccuringTroskoviDefinitions());
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function deleteReccuringTrosakDefinition(trosakId, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'troskovi/recurring/definitions/' + trosakId
        })
            .done(data => {
                dispatch(getAllReccuringTroskoviDefinitions());
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function payOneTimeTrosak(trosak, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'troskovi/one-time/pay',
            data: JSON.stringify(trosak)
        })
            .done(data => {
                onSuccess();
                dispatch(blagajneSuccess.getStanjeBlagajni(data.stanjeBlagajni));
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function payPendingReccuringTrosak(trosak, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'troskovi/recurring/pay',
            data: JSON.stringify(trosak)
        })
            .done(data => {
                onSuccess();
                dispatch(blagajneSuccess.getStanjeBlagajni(data.stanjeBlagajni));
            })
            .fail(error => {
                showAlert(error);
            });
    };
}
/*
export function dismissPendingReccuringTrosak(trosak, onSuccess, onError) {
    return function (dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'troskovi/recurring/dismiss',
            data: JSON.stringify(trosak)
        }).done((data) => {

        }).fail((error) => {

        });
    };
}
*/

export function getOneTimeGrupe(onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'troskovi/one-time/grupe'
        })
            .done(data => {
                dispatch(troskoviSuccess.getOneTimeGrupe(data));
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function createOneTimeGrupa(grupa, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'troskovi/one-time/grupe',
            data: JSON.stringify(grupa)
        })
            .done(data => {
                dispatch(getOneTimeGrupe());
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function updateOneTimeGrupa(grupaId, grupa, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'troskovi/one-time/grupe/' + grupaId,
            data: JSON.stringify(grupa)
        })
            .done(data => {
                dispatch(getOneTimeGrupe());
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function deleteOneTimeGrupa(grupaId, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'troskovi/one-time/grupe/' + grupaId
        })
            .done(data => {
                dispatch(getOneTimeGrupe());
            })
            .fail(error => {
                showAlert(error);
            });
    };
}
