import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NoviOtkup from './otkup_components/NoviOtkup';

const styles = {};

class Otkup extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <NoviOtkup history={this.props.history} />;
    }
}

Otkup.propTypes = {
    history: PropTypes.object.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {};
};

let mapDispatchToProps = function(dispatch) {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Otkup);
