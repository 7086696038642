import localization from '../locales/localization';

export function showAlert(errorObject) {
    let message = localization.common.nepoznataGreska;
    if (errorObject.responseJSON) {
        if (errorObject.responseJSON.errorMessage) {
            message = errorObject.responseJSON.errorMessage;
        } else if (errorObject.responseJSON.message) {
            message = errorObject.responseJSON.message;
        }
    }
    alert(localization.common.dogodilaSeGreska + '\n' + errorObject.status + ' - ' + message);
}
