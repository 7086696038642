import React from 'react';
import { Table } from 'semantic-ui-react';
import localization from '../../../../locales/localization';

export default class EditableRacunHeaderCells extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Table.HeaderCell>{localization.artikl.kategorija}</Table.HeaderCell>
                <Table.HeaderCell textAlign="center" collapsing>
                    {localization.common.kolicina}
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center" collapsing>
                    {localization.prodaja.maksimalnaKolicina}
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center" collapsing>
                    {localization.common.jedinicnaCijena}
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center" collapsing>
                    {localization.common.prodajnaCijena}
                </Table.HeaderCell>
            </React.Fragment>
        );
    }
}
