import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Message } from 'semantic-ui-react';

export default class SearchResultsMessage extends React.Component {
    render() {
        return (
            <Grid.Row>
                <Grid.Column verticalAlign="middle">
                    <Message icon={this.props.icon} header={this.props.header} content={this.props.content} />
                </Grid.Column>
            </Grid.Row>
        );
    }
}

SearchResultsMessage.propTypes = {
    icon: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    content: PropTypes.string
};
