import React from 'react';
import PropTypes from 'prop-types';
import PrintProvider, { Print, NoPrint } from 'react-easy-print';
import ZaglavljeTvrtke from './common_components/ZaglavljeTvrtke';
import { Button, Table } from 'semantic-ui-react';
import localization from '../../locales/localization';
import DatumIMjestoObicno from './common_components/DatumIMjestoObicno';
import moment from 'moment';
import FormatIznosa from '../common_components/FormatIznosa';

const styles = {
    container: {
        padding: 24
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    zaglavlja: {
        width: '50%'
    },
    zaglavljeTvrtke: {
        paddingBottom: 24
    },
    datumImjesto: {
        paddingBottom: 8
    },
    title: {
        padding: 12,
        textAlign: 'center'
    },
    table: {
        fontSize: 'xx-small'
    },
    tableCell: {
        paddingTop: 'unset',
        paddingBottom: 'unset'
    },
    tableCellKol: {
        paddingTop: 'unset',
        paddingBottom: 'unset',
        fontFamily: 'monospace'
    },
    footerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: 64,
        paddingBottom: 16
    },
    printButton: {
        textAlign: 'center',
        paddingBottom: 8
    }
};

export default class StanjeSkladistaInventura extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            document: JSON.parse(sessionStorage.getItem(this.props.match.params.id))
        };
        this.printDocument = this.printDocument.bind(this);
    }

    resolveMjesto(stanjeSkladista) {
        if (stanjeSkladista.stanja.length > 1) {
            return ['-', localization.printingComponents.common.skladiste];
        }
        const mjesto = stanjeSkladista.stanja[0].mjesto.naziv;
        return [mjesto, mjesto];
    }

    printDocument() {
        window.print();
    }

    render() {
        const [mjesto, poslovnica] = this.resolveMjesto(this.state.document);
        return (
            <PrintProvider>
                <Print>
                    <div style={styles.container}>
                        <div style={styles.headerContainer}>
                            <div style={styles.zaglavlja}>
                                <div style={styles.zaglavljeTvrtke}>
                                    <ZaglavljeTvrtke firma={this.state.document.firma} />
                                </div>
                            </div>
                            <div style={styles.zaglavlja}>
                                <div style={styles.datumImjesto}>
                                    <DatumIMjestoObicno datum={moment(this.state.document.date).format('DD.MM.YYYY.')} mjesto={mjesto} />
                                </div>
                                <p>
                                    {localization.printingComponents.common.skladiste + ': '}
                                    <b>{poslovnica}</b>
                                </p>
                            </div>
                        </div>
                        <div style={styles.title}>
                            <h3>{localization.printingComponents.stanjeSkladistaInventura.naslov}</h3>
                        </div>
                        <Table style={styles.table}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={styles.tableCell} />
                                    <Table.HeaderCell style={styles.tableCell}>{localization.artikl.sifra}</Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell}>{localization.artikl.naziv}</Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell} textAlign="center">
                                        {localization.artikl.mjernaJedinica}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell} textAlign="center" collapsing>
                                        {localization.common.kolicina}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell} textAlign="center">
                                        {localization.stanjeSkladista.jedinicnaProdajnaCijena}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell} textAlign="center">
                                        {localization.common.vrijednost}
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.state.document.stanja
                                    .flatMap(mjesto => mjesto.stavke)
                                    .map((s, index) => (
                                        <Table.Row key={index}>
                                            <Table.Cell style={styles.tableCell}>{index + 1}</Table.Cell>
                                            <Table.Cell style={styles.tableCell}>{s.artikl.sifra}</Table.Cell>
                                            <Table.Cell style={styles.tableCell}>
                                                <b>{s.artikl.naziv}</b>
                                            </Table.Cell>
                                            <Table.Cell style={styles.tableCell} collapsing>
                                                {s.artikl.nazivMjernaJedinica}
                                            </Table.Cell>
                                            <Table.Cell style={styles.tableCellKol} collapsing textAlign="right">
                                                {s.kolicina}
                                            </Table.Cell>
                                            <Table.Cell style={styles.tableCellKol} collapsing textAlign="right">
                                                <FormatIznosa value={s.jedProdajnaCijena} suffix={null} />
                                            </Table.Cell>
                                            <Table.Cell style={styles.tableCellKol} collapsing textAlign="right">
                                                <b>{s.prodajnaVrijednost ? <FormatIznosa value={s.prodajnaVrijednost} /> : '-'}</b>
                                            </Table.Cell>
                                        </Table.Row>
                                    ))}
                            </Table.Body>
                            <Table.Footer>
                                <Table.Row>
                                    <Table.HeaderCell style={styles.tableCell} colSpan={5} />
                                    <Table.HeaderCell style={styles.tableCell} collapsing textAlign="center">
                                        <b>{localization.common.ukupno + ':'}</b>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCellKol} collapsing textAlign="right">
                                        <b>
                                            <FormatIznosa value={this.state.document.ukupnaProdajnaVrijednost} />
                                        </b>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        </Table>
                    </div>
                </Print>
                <NoPrint>
                    <div style={styles.printButton}>
                        <Button color="green" icon="print" size="massive" content={localization.common.ispis} onClick={this.printDocument} />
                    </div>
                </NoPrint>
            </PrintProvider>
        );
    }
}

StanjeSkladistaInventura.propTypes = {
    match: PropTypes.object.isRequired
};
