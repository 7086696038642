import React from 'react';
import localization from '../locales/localization';
import enums from './Enums';
import MeduskladisnicaContent from '../components/dokumenti_components/content_components/MeduskladisnicaContent';
import OtkupProdajaContent from '../components/dokumenti_components/content_components/OtkupProdajaContent';
import ProcjenaContent from '../components/dokumenti_components/content_components/ProcjenaContent';
import RadniNalogContent from '../components/dokumenti_components/content_components/RadniNalogContent';
import RezervacijaContent from '../components/dokumenti_components/content_components/RezervacijaContent';
import CijeneContent from '../components/dokumenti_components/content_components/CijeneContent';
import StornoRacunContent from '../components/dokumenti_components/content_components/StornoRacunContent';
import PredracunRacunContent from '../components/dokumenti_components/content_components/PredracunRacunContent';
import ArhiviraneProcjeneContent from '../components/dokumenti_components/content_components/ArhiviraneProcjeneContent';
import ProduzeneRezervacijeContent from '../components/dokumenti_components/content_components/ProduzeneRezervacijeContent';
import PodizanjeRezervacijeContent from '../components/dokumenti_components/content_components/PodizanjeRezervacijeContent';
import RezervacijeAkcijeContent from '../components/dokumenti_components/content_components/RezervacijeAkcijeContent';
import UplataNapomeneContent from '../components/dokumenti_components/content_components/UplataNapomeneContent';
import StornoSUBContent from '../components/dokumenti_components/content_components/StornoSUBContent';

let calculateUkupno = radniNalozi => {
    let ukupno = 0;
    radniNalozi.forEach(usluga => {
        ukupno += usluga.kolicinaUsluge * usluga.ocekivanaJedCijenaUsluge;
        if (usluga.stavkeRadnogNaloga.length) {
            usluga.stavkeRadnogNaloga.forEach(srn => {
                ukupno += srn.kolicina * srn.jedCijenaSkladisneJedinke;
            });
        }
    });
    return ukupno;
};

const tipoviRadnji = {
    'MEDUSKLADISNICA.CREATE': radnja => localization.dnevnaEvidencija.kreiranaMeduskladisnica,
    'MEDUSKLADISNICA.IZVRSENA': radnja => localization.dnevnaEvidencija.izvrsenaMeduskladisnica,
    'PRODAJNA_CIJENA.POTVRDA': radnja => localization.dnevnaEvidencija.potvrdenaProdajnaCijena,
    'PRODAJNA_CIJENA.PRIJEDLOG': radnja => localization.dnevnaEvidencija.predlozenaProdajnaCijena,
    'PRODAJNA_CIJENA.PROMJENA': radnja => localization.dnevnaEvidencija.promjenjenaProdajnaCijena,
    'PREDRACUN.CREATE': radnja => localization.dnevnaEvidencija.kreiranPredracun,
    'PREDRACUN.ARHIVIRAN': radnja => localization.dnevnaEvidencija.arhiviranPredracun,
    'PREDRACUN.TO_RACUN': radnja => localization.dnevnaEvidencija.izradenRacun + ' ' + localization.dnevnaEvidencija.izPredracuna,
    'PROCJENA.CREATE': radnja => localization.dnevnaEvidencija.kreiranaProcjena,
    'PROCJENA.ARHIVIRANA': radnja => localization.dnevnaEvidencija.arhiviranaProcjena,
    'RACUN.CREATE': radnja => localization.dnevnaEvidencija.kreiranRacun,
    'RACUN.STORNO': radnja => localization.dnevnaEvidencija.storniranRacun,
    'BRN.CREATE': radnja => localization.dnevnaEvidencija.kreiranRadniNalog,
    'BRN.ARHIVIRAN': radnja => localization.dnevnaEvidencija.arhiviranRadniNalog,
    'REZERVACIJA.CREATE': radnja => localization.dnevnaEvidencija.kreiranaRezervacija,
    'REZERVACIJA.PRODUZENJE': radnja => localization.dnevnaEvidencija.produzenaRezervacija,
    'REZERVACIJA.PODIZANJE': radnja => localization.dnevnaEvidencija.podignutaRezervacija,
    'REZERVACIJA.OTKAZIVANJE': radnja => localization.dnevnaEvidencija.otkazanaRezervacija,
    'REZERVACIJA.UPDATE_ROKOVA': radnja => localization.dnevnaEvidencija.produzenRokRezervacije,
    'SUB.CREATE': radnja => {
        if (radnja.sklUlazniBlok.tip === enums.tipSkladisnogUlaznogBloka.OTKUPNI_BLOK) {
            return localization.dnevnaEvidencija.kreiranOtkup;
        }
        return localization.dnevnaEvidencija.kreiranaPrimka;
    },
    'SUB.STORNO': radnja => {
        if (radnja.sklUlazniBlok.tip === enums.tipSkladisnogUlaznogBloka.OTKUPNI_BLOK) {
            return localization.dnevnaEvidencija.storniranOtkup;
        }
        return localization.dnevnaEvidencija.storniranaPrimka;
    },
    'SUB.PRINT': radnja => localization.dnevnaEvidencija.isprintanOtkupniBlok,
    UPDATE_NABAVNA_CIJENA: radnja => localization.dnevnaEvidencija.promjenjenaNabavnaCijena,
    LOGIN: radnja => localization.dnevnaEvidencija.prijavaKorisnika,
    UPLATA: radnja => {
        if (radnja.uplatnica.iznos < 0) {
            return localization.dnevnaEvidencija.napravljenaIsplata;
        }
        return localization.dnevnaEvidencija.napravljenaUplata;
    },
    PRETAKANJE: radnja => localization.dnevnaEvidencija.interniPrijenos
};

const prikazRadnji = {
    'MEDUSKLADISNICA.CREATE': radnja => {
        return {
            imeRadnje: localization.dnevnaEvidencija.kreiranaMeduskladisnica + ' ' + localization.common.br + ' ' + radnja.meduskladisnica.rbrMeduskladisnice,
            content: (
                <MeduskladisnicaContent
                    artikli={radnja.meduskladisnica.stavke.map(ss => {
                        return Object.assign({}, ss, ss.predmet);
                    })}
                />
            )
        };
    },
    'MEDUSKLADISNICA.IZVRSENA': radnja => {
        return {
            imeRadnje: localization.dnevnaEvidencija.izvrsenaMeduskladisnica + ' ' + localization.common.br + ' ' + radnja.meduskladisnica.rbrMeduskladisnice,
            content: (
                <MeduskladisnicaContent
                    artikli={radnja.meduskladisnica.stavke.map(ss => {
                        return Object.assign({}, ss, ss.predmet);
                    })}
                />
            )
        };
    },
    'PRODAJNA_CIJENA.POTVRDA': radnja => {
        return {
            imeRadnje:
                localization.dnevnaEvidencija.potvrdenaProdajnaCijena +
                ' ' +
                localization.common.za +
                ' ' +
                localization.common.artikl +
                ' ' +
                radnja.predmet.sifra,
            content: <CijeneContent predmeti={[Object.assign({}, radnja.predmet, { novaCijena: radnja.cijena })]} />
        };
    },
    'PRODAJNA_CIJENA.PRIJEDLOG': radnja => {
        return {
            imeRadnje:
                localization.dnevnaEvidencija.predlozenaProdajnaCijena +
                ' ' +
                localization.common.za +
                ' ' +
                localization.common.artikl +
                ' ' +
                radnja.predmet.sifra,
            content: (
                <CijeneContent
                    predmeti={[
                        Object.assign({}, radnja.predmet, {
                            staraCijena: radnja.staraCijena,
                            novaCijena: radnja.novaCijena
                        })
                    ]}
                />
            )
        };
    },
    'PRODAJNA_CIJENA.PROMJENA': radnja => {
        return {
            imeRadnje:
                localization.dnevnaEvidencija.promjenjenaProdajnaCijena +
                ' ' +
                localization.common.za +
                ' ' +
                localization.common.artikl +
                ' ' +
                radnja.predmet.sifra,
            content: (
                <CijeneContent
                    predmeti={[
                        Object.assign({}, radnja.predmet, {
                            staraCijena: radnja.staraCijena,
                            novaCijena: radnja.novaCijena
                        })
                    ]}
                />
            )
        };
    },
    'PREDRACUN.CREATE': radnja => {
        return {
            imeRadnje: localization.dnevnaEvidencija.kreiranPredracun + ' ' + localization.common.br + ' ' + radnja.predracun.rbrPredracun,
            content: (
                <OtkupProdajaContent
                    klijent={radnja.predracun.klijent}
                    type={enums.tipTabliceArtikala.RACUN_FIXED}
                    artikli={radnja.predracun.stavkePredracuna.map(si => {
                        return Object.assign({}, si, {
                            sifra: si.sifraArtikla,
                            naziv: si.nazivArtikla,
                            cijena: si.jedCijena
                        });
                    })}
                />
            )
        };
    },
    'PREDRACUN.ARHIVIRAN': radnja => {
        return {
            imeRadnje: localization.dnevnaEvidencija.arhiviranPredracun + ' ' + localization.common.br + ' ' + radnja.predracun.rbrPredracun,
            content: (
                <OtkupProdajaContent
                    klijent={radnja.predracun.klijent}
                    type={enums.tipTabliceArtikala.RACUN_FIXED}
                    artikli={radnja.predracun.stavkePredracuna.map(si => {
                        return Object.assign({}, si, {
                            sifra: si.sifraArtikla,
                            naziv: si.nazivArtikla,
                            cijena: si.jedCijena
                        });
                    })}
                />
            )
        };
    },
    'PREDRACUN.TO_RACUN': radnja => {
        return {
            imeRadnje:
                localization.dnevnaEvidencija.izradenRacun +
                ' ' +
                localization.common.br +
                ' ' +
                (radnja.racun.rbrFiskalniRacun || radnja.racun.rbrTransakcijskiRacun) +
                ' ' +
                localization.dnevnaEvidencija.izPredracuna +
                ' ' +
                localization.common.br +
                ' ' +
                radnja.predracun.rbrPredracun,
            content: <PredracunRacunContent predracun={radnja.predracun} racun={radnja.racun} />
        };
    },
    'PROCJENA.CREATE': radnja => {
        return {
            imeRadnje: localization.dnevnaEvidencija.kreiranaProcjena + ' ' + localization.common.br + ' ' + radnja.zahtjevZaProcjenu.rbrZahtjevZaProcjenu,
            content: <ProcjenaContent zahtjevZaProcjenu={radnja.zahtjevZaProcjenu} showArhivirani={true} />
        };
    },
    'PROCJENA.ARHIVIRANA': radnja => {
        return {
            imeRadnje: localization.dnevnaEvidencija.arhiviranaProcjena + ' ' + localization.common.br + ' ' + radnja.zahtjevZaProcjenu.rbrZahtjevZaProcjenu,
            content: <ArhiviraneProcjeneContent procjene={radnja.procjene} />
        };
    },
    'RACUN.CREATE': radnja => {
        return {
            imeRadnje:
                localization.dnevnaEvidencija.kreiranRacun +
                ' ' +
                localization.common.br +
                ' ' +
                (radnja.racun.rbrFiskalniRacun || radnja.racun.rbrTransakcijskiRacun),
            content: (
                <OtkupProdajaContent
                    klijent={radnja.racun.klijent}
                    type={enums.tipTabliceArtikala.RACUN_FIXED}
                    artikli={radnja.racun.skladisniIzlazi.map(si => {
                        return Object.assign({}, si, {
                            sifra: si.sifraArtikla,
                            naziv: si.nazivArtikla,
                            cijena: si.jedCijena,
                            kolicina: radnja.racun.rbrStorniranogRacuna === null ? si.kolicina : -si.kolicina
                        });
                    })}
                />
            )
        };
    },
    'RACUN.STORNO': radnja => {
        return {
            imeRadnje:
                localization.dnevnaEvidencija.storniranRacun +
                ' ' +
                localization.common.br +
                ' ' +
                (radnja.racun.rbrFiskalniRacun || radnja.racun.rbrTransakcijskiRacun),
            content: <StornoRacunContent racun={radnja.racun} stornoRacun={radnja.racunStorno} />
        };
    },
    'BRN.CREATE': radnja => {
        return {
            imeRadnje: localization.dnevnaEvidencija.kreiranRadniNalog + ' ' + localization.common.br + ' ' + radnja.blokRadnihNaloga.rbrBlokRadnihNaloga,
            content: <RadniNalogContent radniNalog={radnja.blokRadnihNaloga} calculateFunction={calculateUkupno} />
        };
    },
    'BRN.ARHIVIRAN': radnja => {
        return {
            imeRadnje: localization.dnevnaEvidencija.arhiviranRadniNalog + ' ' + localization.common.br + ' ' + radnja.blokRadnihNaloga.rbrBlokRadnihNaloga,
            content: <RadniNalogContent radniNalog={radnja.blokRadnihNaloga} calculateFunction={calculateUkupno} />
        };
    },
    'REZERVACIJA.CREATE': radnja => {
        return {
            imeRadnje: localization.dnevnaEvidencija.kreiranaRezervacija + ' ' + localization.common.br + ' ' + radnja.zahtjevZaRezervaciju.rbrRezervacije,
            content: <RezervacijaContent zahtjevRezervacije={radnja.zahtjevZaRezervaciju} showNevazece={true} />
        };
    },
    'REZERVACIJA.PRODUZENJE': radnja => {
        return {
            imeRadnje: localization.dnevnaEvidencija.produzenaRezervacija + ' ' + localization.common.br + ' ' + radnja.zahtjevZaRezervaciju.rbrRezervacije,
            content: <ProduzeneRezervacijeContent produzeneRezervacije={radnja.produzeneRezervacije} racun={radnja.racun} otkupniBlok={radnja.otkupniBlok} />
        };
    },
    'REZERVACIJA.PODIZANJE': radnja => {
        return {
            imeRadnje: localization.dnevnaEvidencija.podignutaRezervacija + ' ' + localization.common.br + ' ' + radnja.zahtjevZaRezervaciju.rbrRezervacije,
            content: <PodizanjeRezervacijeContent podignuteRezervacije={radnja.rezervacije} racun={radnja.racun} />
        };
    },
    'REZERVACIJA.OTKAZIVANJE': radnja => {
        return {
            imeRadnje: localization.dnevnaEvidencija.otkazanaRezervacija + ' ' + localization.common.br + ' ' + radnja.zahtjevZaRezervaciju.rbrRezervacije,
            content: <RezervacijeAkcijeContent rezervacije={radnja.rezervacije} />
        };
    },
    'REZERVACIJA.UPDATE_ROKOVA': radnja => {
        return {
            imeRadnje: localization.dnevnaEvidencija.produzenRokRezervacije + ' ' + localization.common.br + ' ' + radnja.zahtjevZaRezervaciju.rbrRezervacije,
            content: <RezervacijeAkcijeContent rezervacije={radnja.rezervacije} />
        };
    },
    'SUB.CREATE': radnja => {
        if (radnja.sklUlazniBlok.tip === enums.tipSkladisnogUlaznogBloka.OTKUPNI_BLOK) {
            return {
                imeRadnje: localization.dnevnaEvidencija.kreiranOtkup + ' ' + localization.common.br + ' ' + radnja.sklUlazniBlok.rbrOtkupniBlok,
                content: (
                    <OtkupProdajaContent
                        klijent={radnja.sklUlazniBlok.fizickaOsoba}
                        type={enums.tipTabliceArtikala.OTKUP_FIXED}
                        artikli={radnja.sklUlazniBlok.skladisniUlazi.map(su => {
                            return Object.assign({}, su, su.predmet, {
                                cijena: su.jedNabavnaCijena
                            });
                        })}
                    />
                )
            };
        } else {
            return {
                imeRadnje: localization.dnevnaEvidencija.kreiranaPrimka + ' ' + localization.common.br + ' ' + radnja.sklUlazniBlok.rbrPrimka,
                content: (
                    <OtkupProdajaContent
                        klijent={radnja.sklUlazniBlok.pravnaOsoba}
                        type={enums.tipTabliceArtikala.PRIMKA_FIXED}
                        artikli={radnja.sklUlazniBlok.skladisniUlazi.map(su => {
                            return Object.assign({}, su, su.predmet, {
                                cijena: su.jedNabavnaCijena
                            });
                        })}
                    />
                )
            };
        }
    },
    'SUB.STORNO': radnja => {
        let imeRadnje;
        if (radnja.sklUlazniBlok.tip === enums.tipSkladisnogUlaznogBloka.OTKUPNI_BLOK) {
            imeRadnje = localization.dnevnaEvidencija.storniranOtkup + ' ' + localization.common.br + ' ' + radnja.sklUlazniBlok.rbrOtkupniBlok;
        } else {
            imeRadnje = localization.dnevnaEvidencija.storniranaPrimka + ' ' + localization.common.br + ' ' + radnja.sklUlazniBlok.rbrPrimka;
        }
        return {
            imeRadnje: imeRadnje,
            content: <StornoSUBContent tip={radnja.sklUlazniBlok.tip} SUB={radnja.sklUlazniBlokStorno} stornoSUB={radnja.sklUlazniBlok} />
        };
    },
    'SUB.PRINT': radnja => {
        return {
            imeRadnje: localization.dnevnaEvidencija.isprintanOtkupniBlok + ' ' + localization.common.br + ' ' + radnja.sklUlazniBlok.rbrOtkupniBlok,
            content: (
                <OtkupProdajaContent
                    klijent={radnja.sklUlazniBlok.fizickaOsoba}
                    type={enums.tipTabliceArtikala.OTKUP_FIXED}
                    artikli={radnja.sklUlazniBlok.skladisniUlazi.map(su => {
                        return Object.assign({}, su, su.predmet, {
                            cijena: su.jedNabavnaCijena
                        });
                    })}
                />
            )
        };
    },
    UPDATE_NABAVNA_CIJENA: radnja => {
        return {
            imeRadnje:
                localization.dnevnaEvidencija.promjenjenaNabavnaCijena +
                ' ' +
                localization.common.za +
                ' ' +
                localization.common.artikl +
                ' ' +
                radnja.skladisniUlazi
                    .map(su => {
                        return su.predmet.sifra;
                    })
                    .join(', '),
            content: (
                <CijeneContent
                    predmeti={radnja.skladisniUlazi.map(su => {
                        return Object.assign({}, su.predmet, { novaCijena: su.jedNabavnaCijena });
                    })}
                />
            )
        };
    },
    LOGIN: radnja => {
        return {
            imeRadnje: localization.dnevnaEvidencija.prijavaKorisnika
        };
    },
    UPLATA: radnja => {
        let resolveUplataIsplata = iznos => {
            if (iznos < 0) {
                return localization.dnevnaEvidencija.napravljenaIsplata + ' ' + localization.common.iz;
            }
            return localization.dnevnaEvidencija.napravljenaUplata + ' ' + localization.common.u;
        };
        let resolveNapomenaUplatniceIsplatnice = iznos => {
            if (iznos < 0) {
                return localization.common.napomena + ' ' + localization.dnevnaEvidencija.isplatnice;
            }
            return localization.common.napomena + ' ' + localization.dnevnaEvidencija.uplatnice;
        };
        let resolveTipBlagajne = tipBlagajne => {
            if (tipBlagajne === enums.tipBlagajneRadnje.OTKUPNA) {
                return localization.dnevnaEvidencija.otkupna + ' ' + localization.dnevnaEvidencija.blagajna;
            }
            return localization.dnevnaEvidencija.fiskalna + ' ' + localization.dnevnaEvidencija.blagajna;
        };
        let contentObject = {
            imeRadnje:
                resolveUplataIsplata(radnja.uplatnica.iznos) +
                ' ' +
                resolveTipBlagajne(radnja.uplatnica.tipBlagajne) +
                ' ' +
                localization.dnevnaEvidencija.uIznosuOd +
                ' ' +
                Math.abs(radnja.uplatnica.iznos) +
                ' ' +
                localization.common.eur +
                '.'
        };
        if (radnja.uplatnica.napomena) {
            contentObject.content = (
                <UplataNapomeneContent header={resolveNapomenaUplatniceIsplatnice(radnja.uplatnica.iznos)} napomena={radnja.uplatnica.napomena} />
            );
        }
        return contentObject;
    },
    PRETAKANJE: radnja => {
        let resolveTipBlagajni = tipBlagajne => {
            let src, dest;
            if (tipBlagajne === enums.tipBlagajneRadnje.OTKUPNA) {
                dest = localization.dnevnaEvidencija.otkupna + ' ' + localization.dnevnaEvidencija.blagajna;
                src = localization.dnevnaEvidencija.fiskalna;
            } else {
                dest = localization.dnevnaEvidencija.fiskalna + ' ' + localization.dnevnaEvidencija.blagajna;
                src = localization.dnevnaEvidencija.otkupna;
            }
            return [src, dest];
        };
        let [src, dest] = resolveTipBlagajni(radnja.pretok.tipOdredisneBlagajne);
        return {
            imeRadnje:
                localization.dnevnaEvidencija.interniPrijenos +
                ' ' +
                localization.common.iz +
                ' ' +
                src +
                ' ' +
                localization.common.u +
                ' ' +
                dest +
                ' ' +
                localization.dnevnaEvidencija.uIznosuOd +
                ' ' +
                radnja.pretok.iznos +
                ' ' +
                localization.common.eur
        };
    }
};

export default {
    tipoviRadnji,
    prikazRadnji
};
