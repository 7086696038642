import React from 'react';
import PropTypes from 'prop-types';
import PrintProvider, { Print, NoPrint } from 'react-easy-print';
import ZaglavljeTvrtke from './common_components/ZaglavljeTvrtke';
import { Button, Table } from 'semantic-ui-react';
import localization from '../../locales/localization';
import DatumIMjestoObicno from './common_components/DatumIMjestoObicno';
import moment from 'moment';
import FormatIznosa from '../common_components/FormatIznosa';
import enums from '../../utils/Enums';
import { calculateBrojRacuna } from '../../utils/BrojRacunaCalculator';

const styles = {
    container: {
        padding: 24
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    zaglavljaRight: {
        width: '50%',
        display: 'flex'
    },
    zaglavlja: {
        width: '50%'
    },
    zaglavljeTvrtke: {
        paddingBottom: 24
    },
    datumImjesto: {
        paddingBottom: 8
    },
    p: {
        fontSize: 10
    },
    pdvmi: {
        fontSize: 16,
        textAlign: 'right'
    },
    title: {
        padding: 12,
        textAlign: 'center'
    },
    table: {
        fontSize: 'xx-small'
    },
    sumTable: {
        fontSize: 'xx-small',
        width: '50%'
    },
    tableCell: {
        paddingTop: 'unset',
        paddingBottom: 'unset'
    },
    tableCellKol: {
        paddingTop: 'unset',
        paddingBottom: 'unset',
        fontFamily: 'monospace'
    },
    footerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: 64,
        paddingBottom: 16
    },
    printButton: {
        textAlign: 'center',
        paddingBottom: 8
    }
};

export default class RekapitulacijaRacuna extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            document: JSON.parse(sessionStorage.getItem(this.props.match.params.id))
        };
        this.printDocument = this.printDocument.bind(this);
        this.calculateUkupno = this.calculateUkupno.bind(this);
    }

    printDocument() {
        window.print();
    }

    calculateStavkeRekapitulacije(racun) {
        let iznosNovaRoba = 0,
            iznosRabljenaRoba = 0,
            iznosInvZlata = 0,
            iznosUsluga = 0,
            nabavnaVrijednost = 0,
            fakturnaVrijednost = 0,
            iznosMarze = 0,
            vrijednostBezPoreza = 0,
            osnovicaPoreza = 0,
            iznosPoreza = 0,
            vrijednostSPorezom = 0;

        racun.skladisniIzlazi.forEach(a => {
            let storno = 1;
            if (racun.rbrStorniranogRacuna) {
                storno = -1;
            }

            let vrijednost = storno * a.kolicina * a.jedCijena;
            nabavnaVrijednost += storno * a.ukupnaNabavnaCijena;
            fakturnaVrijednost += vrijednost;
            vrijednostSPorezom += vrijednost;

            let pojedinacniIznosMarze = vrijednost - storno * a.ukupnaNabavnaCijena;
            iznosMarze += pojedinacniIznosMarze;

            let poreznaStopa = a.postotakPDV / 100;
            let calculatePorez = iznos => {
                let iznosPoreza = (iznos * poreznaStopa) / (1 + poreznaStopa);
                let osnovicaPoreza = iznos / (1 + poreznaStopa);
                return { iznosPoreza, osnovicaPoreza };
            };
            let pojedinacniIznosPoreza = 0;
            let val;
            if (a.tipPoreza === enums.tipPoreza.NOVI_PREDMET) {
                val = calculatePorez(vrijednost);
                pojedinacniIznosPoreza = val.iznosPoreza;
                osnovicaPoreza += val.osnovicaPoreza;
                iznosNovaRoba += vrijednost;
            } else if (a.tipPoreza === enums.tipPoreza.INVESTICIJSKO_ZLATO) {
                val = calculatePorez(vrijednost);
                pojedinacniIznosPoreza = val.iznosPoreza;
                osnovicaPoreza += 0;
                iznosInvZlata += vrijednost;
            } else if (a.tipPoreza === enums.tipPoreza.USLUGA) {
                val = calculatePorez(vrijednost);
                pojedinacniIznosPoreza = val.iznosPoreza;
                osnovicaPoreza += val.osnovicaPoreza;
                iznosUsluga += vrijednost;
            } else {
                if (!a.isProdanoIspodNabavne || (racun.rbrFiskalniRacun === '739-2020' && racun.poslovnica.adresa === 'Ilica 78')) {
                    val = calculatePorez(pojedinacniIznosMarze);
                    pojedinacniIznosPoreza = val.iznosPoreza;
                    osnovicaPoreza += val.osnovicaPoreza;
                } else {
                    pojedinacniIznosPoreza = 0;
                }
                iznosRabljenaRoba += vrijednost;
            }
            iznosPoreza += pojedinacniIznosPoreza;
            vrijednostBezPoreza += vrijednost - pojedinacniIznosPoreza;
        });

        //za nestorno racun i negativnu marzu ili storno racun i pozitivnu marzu
        // if ((!racun.rbrStorniranogRacuna && iznosMarze < 0) || (racun.rbrStorniranogRacuna && iznosMarze > 0)) {
        //     vrijednostBezPoreza = vrijednostSPorezom;
        //     osnovicaPoreza = 0;
        //     iznosPoreza = 0;
        // }

        return {
            iznosNovaRoba,
            iznosRabljenaRoba,
            iznosInvZlata,
            iznosUsluga,
            nabavnaVrijednost,
            fakturnaVrijednost,
            iznosMarze,
            vrijednostBezPoreza,
            osnovicaPoreza,
            iznosPoreza,
            vrijednostSPorezom
        };
    }

    calculateUkupno() {
        let ukupnaNabavnaVrijednost = 0,
            ukupnaFakturnaVrijednost = 0,
            ukupniIznosMarze = 0,
            ukupnaVrijednostBezPoreza = 0,
            ukupniIznosPoreza = 0,
            ukupnaVrijednostSPorezom = 0,
            ukupnaOsnovicaPoreza = 0,
            gotovina = 0,
            kartice = 0,
            transakcijskiRacun = 0;

        this.state.document.racuni.forEach(racun => {
            let calData = this.calculateStavkeRekapitulacije(racun);

            ukupnaNabavnaVrijednost += calData.nabavnaVrijednost;
            ukupnaFakturnaVrijednost += calData.fakturnaVrijednost;
            ukupniIznosMarze += calData.iznosMarze;
            ukupnaVrijednostBezPoreza += calData.vrijednostBezPoreza;
            ukupnaOsnovicaPoreza += calData.osnovicaPoreza;
            ukupniIznosPoreza += calData.iznosPoreza;
            ukupnaVrijednostSPorezom += calData.vrijednostSPorezom;

            if (racun.tipRacuna === enums.tipRacuna.TRANSAKCIJSKI_RACUN) {
                transakcijskiRacun += calData.vrijednostSPorezom;
            } else {
                let storno = 1;
                if (racun.rbrStorniranogRacuna) {
                    storno = -1;
                }
                if (racun.iznosGotovina) {
                    gotovina += storno * racun.iznosGotovina;
                }
                if (racun.karticnoPlacanje) {
                    kartice += storno * racun.karticnoPlacanje.iznosKarticno;
                }
            }
        });

        return {
            ukupnaNabavnaVrijednost,
            ukupnaFakturnaVrijednost,
            ukupniIznosMarze,
            ukupnaVrijednostBezPoreza,
            ukupniIznosPoreza,
            ukupnaVrijednostSPorezom,
            ukupnaOsnovicaPoreza,
            gotovina,
            kartice,
            transakcijskiRacun
        };
    }

    render() {
        let calSumData = this.calculateUkupno();
        return (
            <PrintProvider>
                <Print>
                    <div style={styles.container}>
                        <div style={styles.headerContainer}>
                            <div style={styles.zaglavlja}>
                                <div style={styles.zaglavljeTvrtke}>
                                    <ZaglavljeTvrtke firma={this.state.document.racuni[0].firma} />
                                </div>
                            </div>
                            <div style={styles.zaglavljaRight}>
                                <div style={styles.zaglavlja}>
                                    <div style={styles.datumImjesto}>
                                        <DatumIMjestoObicno
                                            datum={moment().format('DD.MM.YYYY.')}
                                            mjesto={this.state.document.poslovnica ? this.state.document.poslovnica.naselje : '-'}
                                        />
                                    </div>
                                    <p>
                                        {localization.printingComponents.common.skladiste + ': '}
                                        <b>
                                            {this.state.document.poslovnica
                                                ? this.state.document.poslovnica.naziv
                                                : localization.printingComponents.common.skladiste}
                                        </b>
                                    </p>
                                </div>
                                <div style={styles.zaglavlja}>
                                    <p style={styles.pdvmi}>
                                        <b>{'PDV - MI'}</b>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div style={styles.title}>
                            <h3>{this.state.document.naslov}</h3>
                        </div>
                        <Table structured striped stackable style={styles.table}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={styles.tableCell} rowSpan={2} />
                                    <Table.HeaderCell style={styles.tableCell}>{localization.rekapitulacije.rekapitulacijaRacuna.brojRacuna}</Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell}>{localization.rekapitulacije.rekapitulacijaRacuna.klijent}</Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell}>{localization.rekapitulacije.rekapitulacijaRacuna.vrstaRacuna}</Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell} textAlign="center">
                                        {localization.rekapitulacije.rekapitulacijaRacuna.iznosRabljeneRobe}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell} textAlign="center">
                                        {localization.rekapitulacije.rekapitulacijaRacuna.iznosNoveRobe}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell} textAlign="center" rowSpan={2}>
                                        {localization.rekapitulacije.rekapitulacijaRacuna.nabavnaVrijednost}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell} textAlign="center" rowSpan={2}>
                                        {localization.rekapitulacije.rekapitulacijaRacuna.fakturnaVrijednost}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell} textAlign="center" rowSpan={2}>
                                        {localization.rekapitulacije.rekapitulacijaRacuna.iznosRabata}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell} textAlign="center" rowSpan={2}>
                                        {localization.rekapitulacije.rekapitulacijaRacuna.iznosMarze}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell} textAlign="center" rowSpan={2}>
                                        {localization.rekapitulacije.rekapitulacijaRacuna.vrijednostBezPoreza}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell} textAlign="center" rowSpan={2}>
                                        {localization.rekapitulacije.rekapitulacijaRacuna.osnovicaPoreza}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell} textAlign="center" rowSpan={2}>
                                        {localization.rekapitulacije.rekapitulacijaRacuna.iznosPoreza}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell} textAlign="center" rowSpan={2}>
                                        {localization.rekapitulacije.rekapitulacijaRacuna.vrijednostSPorezom}
                                    </Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.HeaderCell style={styles.tableCell}>
                                        {localization.rekapitulacije.rekapitulacijaRacuna.datumIzdavanja}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell}>{localization.rekapitulacije.rekapitulacijaRacuna.oib}</Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell}>
                                        {localization.rekapitulacije.rekapitulacijaRacuna.nacinPlacanja}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell} textAlign="center">
                                        {localization.rekapitulacije.rekapitulacijaRacuna.iznosInvZlata}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell} textAlign="center">
                                        {localization.rekapitulacije.rekapitulacijaRacuna.iznosUsluga}
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.state.document.racuni.map((racun, index) => {
                                    let calData = this.calculateStavkeRekapitulacije(racun);
                                    return (
                                        <React.Fragment key={index}>
                                            <Table.Row>
                                                <Table.Cell style={styles.tableCell} collapsing rowSpan={2}>
                                                    {index + 1 + '.'}
                                                </Table.Cell>
                                                <Table.Cell style={styles.tableCell} collapsing>
                                                    {calculateBrojRacuna(racun)}
                                                </Table.Cell>
                                                <Table.Cell style={styles.tableCell} collapsing>
                                                    {racun.klijent
                                                        ? racun.klijent.puniNaziv.toUpperCase()
                                                        : localization.rekapitulacije.rekapitulacijaRacuna.privatnaOsoba.toUpperCase()}
                                                </Table.Cell>
                                                <Table.Cell style={styles.tableCell} collapsing>
                                                    {racun.tipRacuna === enums.tipRacuna.FISKALNI_RACUN
                                                        ? localization.rekapitulacije.rekapitulacijaRacuna.fiskalniRacun
                                                        : localization.rekapitulacije.rekapitulacijaRacuna.transakcijskiRacun}
                                                </Table.Cell>
                                                <Table.Cell style={styles.tableCellKol} collapsing textAlign="right">
                                                    <FormatIznosa value={calData.iznosRabljenaRoba} />
                                                </Table.Cell>
                                                <Table.Cell style={styles.tableCellKol} collapsing textAlign="right">
                                                    <FormatIznosa value={calData.iznosNovaRoba} />
                                                </Table.Cell>
                                                <Table.Cell style={styles.tableCellKol} collapsing textAlign="right" rowSpan={2}>
                                                    <FormatIznosa value={calData.nabavnaVrijednost} />
                                                </Table.Cell>
                                                <Table.Cell style={styles.tableCellKol} collapsing textAlign="right" rowSpan={2}>
                                                    <FormatIznosa value={calData.fakturnaVrijednost} />
                                                </Table.Cell>
                                                <Table.Cell style={styles.tableCellKol} collapsing textAlign="right" rowSpan={2}>
                                                    <FormatIznosa value={0} />
                                                </Table.Cell>
                                                <Table.Cell style={styles.tableCellKol} collapsing textAlign="right" rowSpan={2}>
                                                    <FormatIznosa value={calData.iznosMarze} />
                                                </Table.Cell>
                                                <Table.Cell style={styles.tableCellKol} collapsing textAlign="right" rowSpan={2}>
                                                    <FormatIznosa value={calData.vrijednostBezPoreza} />
                                                </Table.Cell>
                                                <Table.Cell style={styles.tableCellKol} collapsing textAlign="right" rowSpan={2}>
                                                    <FormatIznosa value={calData.osnovicaPoreza} />
                                                </Table.Cell>
                                                <Table.Cell style={styles.tableCellKol} collapsing textAlign="right" rowSpan={2}>
                                                    <FormatIznosa value={calData.iznosPoreza} />
                                                </Table.Cell>
                                                <Table.Cell style={styles.tableCellKol} collapsing textAlign="right" rowSpan={2}>
                                                    <FormatIznosa value={calData.vrijednostSPorezom} />
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell style={styles.tableCell} collapsing>
                                                    {moment(racun.datumIVrijeme).format('DD.MM.YYYY.')}
                                                </Table.Cell>
                                                <Table.Cell style={styles.tableCell} collapsing>
                                                    {racun.klijent && racun.klijent.tipKlijenta === enums.tipKlijenta.PRAVNA_OSOBA ? racun.klijent.idBroj : ''}
                                                </Table.Cell>
                                                <Table.Cell style={styles.tableCell} collapsing>
                                                    {racun.tipRacuna === enums.tipRacuna.FISKALNI_RACUN
                                                        ? racun.karticnoPlacanje
                                                            ? localization.rekapitulacije.rekapitulacijaRacuna.kartica
                                                            : localization.rekapitulacije.rekapitulacijaRacuna.gotovina
                                                        : ''}
                                                </Table.Cell>
                                                <Table.Cell style={styles.tableCellKol} collapsing textAlign="right">
                                                    <FormatIznosa value={calData.iznosInvZlata} />
                                                </Table.Cell>
                                                <Table.Cell style={styles.tableCellKol} collapsing textAlign="right">
                                                    <FormatIznosa value={calData.iznosUsluga} />
                                                </Table.Cell>
                                            </Table.Row>
                                        </React.Fragment>
                                    );
                                })}
                            </Table.Body>
                            <Table.Footer>
                                <Table.Row>
                                    <Table.HeaderCell style={styles.tableCell} colSpan={6} />
                                    <Table.HeaderCell style={styles.tableCellKol} textAlign="right" collapsing>
                                        <b>
                                            <FormatIznosa value={calSumData.ukupnaNabavnaVrijednost} />
                                        </b>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCellKol} textAlign="right" collapsing>
                                        <b>
                                            <FormatIznosa value={calSumData.ukupnaFakturnaVrijednost} />
                                        </b>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCellKol} textAlign="right" collapsing>
                                        <b>
                                            <FormatIznosa value={0} />
                                        </b>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCellKol} textAlign="right" collapsing>
                                        <b>
                                            <FormatIznosa value={calSumData.ukupniIznosMarze} />
                                        </b>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCellKol} textAlign="right" collapsing>
                                        <b>
                                            <FormatIznosa value={calSumData.ukupnaVrijednostBezPoreza} />
                                        </b>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCellKol} textAlign="right" collapsing>
                                        <b>
                                            <FormatIznosa value={calSumData.ukupnaOsnovicaPoreza} />
                                        </b>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCellKol} textAlign="right" collapsing>
                                        <b>
                                            <FormatIznosa value={calSumData.ukupniIznosPoreza} />
                                        </b>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCellKol} textAlign="right" collapsing>
                                        <b>
                                            <FormatIznosa value={calSumData.ukupnaVrijednostSPorezom} />
                                        </b>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        </Table>
                        <br />
                        <br />
                        <Table structured stackable style={styles.sumTable}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={styles.tableCell}>
                                        {localization.rekapitulacije.rekapitulacijaRacuna.sumaPoNacinuPlacanja}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell} colSpan={2} />
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.state.document.tipRacuna === enums.tipRacuna.FISKALNI_RACUN ? (
                                    <React.Fragment>
                                        <Table.Row>
                                            <Table.Cell style={styles.tableCell} collapsing rowSpan={2}>
                                                {localization.rekapitulacije.rekapitulacijaRacuna.fiskalniRacun}
                                            </Table.Cell>
                                            <Table.Cell style={styles.tableCell} collapsing>
                                                {localization.rekapitulacije.rekapitulacijaRacuna.gotovina}
                                            </Table.Cell>
                                            <Table.Cell style={styles.tableCellKol} collapsing textAlign="right">
                                                <FormatIznosa value={calSumData.gotovina} />
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell style={styles.tableCell} collapsing>
                                                {localization.rekapitulacije.rekapitulacijaRacuna.kartica}
                                            </Table.Cell>
                                            <Table.Cell style={styles.tableCellKol} collapsing textAlign="right">
                                                <FormatIznosa value={calSumData.kartice} />
                                            </Table.Cell>
                                        </Table.Row>
                                    </React.Fragment>
                                ) : (
                                    <Table.Row>
                                        <Table.Cell style={styles.tableCell}>{localization.rekapitulacije.rekapitulacijaRacuna.transakcijskiRacun}</Table.Cell>
                                        <Table.Cell style={styles.tableCell} />
                                        <Table.Cell style={styles.tableCellKol} collapsing textAlign="right">
                                            <FormatIznosa value={calSumData.transakcijskiRacun} />
                                        </Table.Cell>
                                    </Table.Row>
                                )}
                            </Table.Body>
                        </Table>
                    </div>
                </Print>
                <NoPrint>
                    <div style={styles.printButton}>
                        <Button color="green" icon="print" size="massive" content={localization.common.ispis} onClick={this.printDocument} />
                    </div>
                </NoPrint>
            </PrintProvider>
        );
    }
}

RekapitulacijaRacuna.propTypes = {
    match: PropTypes.object.isRequired
};
