import $ from 'jquery';

import * as success from '../actions/UsersActions';

import endpoint from '../environments/endpoints';
import { showAlert } from '../utils/ErrorHandler';

export function getUsers(onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'users'
        })
            .done(data => {
                dispatch(success.getUsers(data));
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function createUser(user, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'users',
            data: JSON.stringify(user)
        })
            .done(data => {
                dispatch(getUsers());
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function updateUser(user, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'users/' + user.id,
            data: JSON.stringify(user)
        })
            .done(data => {
                dispatch(getUsers());
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function resetPassword(userId, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'users/' + userId + '/reset-password'
        })
            .done(data => {
                dispatch(getUsers());
                onSuccess();
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function deleteUser(userId, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'users/' + userId
        })
            .done(data => {
                dispatch(getUsers());
                onSuccess();
            })
            .fail(error => {
                showAlert(error);
            });
    };
}
