import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Accordion, Button, Dimmer, Form, Grid, Header, Icon, Loader, Message, Modal, Segment, Table } from 'semantic-ui-react';

import localization from '../../locales/localization';
import * as BankAccountManagementDataAccess from '../../data_access/BankAccountManagementDataAccess';
import moment from 'moment';
import BankovneTransakcijeMappings from '../../utils/BankovneTransakcijeMappings';
import FormatIznosa from '../common_components/FormatIznosa';

const styles = {
    dateContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    poslovnicaYearMonthPicker: {
        marginRight: 8
    },
    filterButton: {
        marginLeft: 8
    },
    iconCell: {
        width: 50
    },
    datumIVrijemeCell: {
        width: 250
    },
    iznosCell: {
        width: 200
    },
    izradioCell: {
        width: 150
    },
    potvrdenaCell: {
        width: 100
    },
    actionButtons: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
};

const GODINE = _.range(2019, parseInt(moment().get('year')) + 1);

class PregledBankovnihTransakcija extends React.Component {
    constructor(props) {
        super(props);
        let date = moment();
        this.state = {
            poslovnicaId: this.props.poslovnice[0].id,
            godina: parseInt(date.format('YYYY')),
            mjesec: parseInt(date.format('MM')),
            transakcije: [],
            activeItems: [],
            potvrdaPotvrde: undefined,
            loader: true
        };
        this.onTransakcijeLoad = this.onTransakcijeLoad.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onFilter = this.onFilter.bind(this);
        this.expand = this.expand.bind(this);
        this.potvrdiTransakciju = this.potvrdiTransakciju.bind(this);
        this.showHidePotvrda = this.showHidePotvrda.bind(this);
        this.showLoader = this.showLoader.bind(this);

        this.onFilter();
    }

    onTransakcijeLoad(data) {
        this.setState({
            transakcije: data,
            loader: false
        });
    }

    onChange(e, { name, value }) {
        this.setState({ [name]: value });
    }

    onFilter() {
        let date = moment(this.state.godina + '-' + this.state.mjesec + '-01');
        let startDate = date.format('YYYY-MM-DD');
        let endDateIncl = date.endOf('month').format('YYYY-MM-DD');
        this.props.bankAccountManagementDataAccess.getBankTransactions(startDate, endDateIncl, this.state.poslovnicaId, this.onTransakcijeLoad);
    }

    expand(e, { index }) {
        let activeItems = [];
        if (this.state.activeItems.includes(index)) {
            activeItems = _.xor(this.state.activeItems, [index]);
        } else {
            activeItems = [...this.state.activeItems, index];
        }
        this.setState({
            activeItems: activeItems
        });
    }

    potvrdiTransakciju(transakcija) {
        let confirmTransaction = {
            type: transakcija.type,
            id: transakcija.entityId,
            shouldBeConfirmed: true
        };
        let date = moment(this.state.godina + '-' + this.state.mjesec + '-01');
        let startDate = date.format('YYYY-MM-DD');
        let endDateIncl = date.endOf('month').format('YYYY-MM-DD');
        this.props.bankAccountManagementDataAccess.confirmTransaction(
            confirmTransaction,
            startDate,
            endDateIncl,
            this.state.poslovnicaId,
            this.onTransakcijeLoad
        );
    }

    showHidePotvrda(value) {
        this.setState({
            potvrdaPotvrde: value
        });
    }

    showLoader(callback) {
        this.setState(
            {
                loader: true
            },
            callback
        );
    }

    render() {
        return (
            <div>
                <Segment tertiary>
                    <Grid stackable>
                        <Grid.Row columns={2}>
                            <Grid.Column verticalAlign="middle">
                                <Header as="h2">{localization.pregledBankovnihTransakcija.pregledBankovnihTransakcija}</Header>
                            </Grid.Column>
                            <Grid.Column verticalAlign="middle">
                                <div style={styles.dateContainer}>
                                    <Form.Select
                                        label={localization.pregledBankovnihTransakcija.poslovnica + ': '}
                                        style={styles.poslovnicaYearMonthPicker}
                                        options={this.props.poslovnice.map(p => {
                                            return { key: p.id, text: p.naziv, value: p.id };
                                        })}
                                        name="poslovnicaId"
                                        onChange={this.onChange}
                                        value={this.state.poslovnicaId}
                                        compact
                                        required
                                        search
                                    />
                                    <Form.Select
                                        label={localization.pregledBankovnihTransakcija.mjesec + ': '}
                                        style={styles.poslovnicaYearMonthPicker}
                                        options={Array.from(new Array(12), (val, index) => {
                                            return { key: index, text: index + 1 + '.', value: index + 1 };
                                        })}
                                        name="mjesec"
                                        labeled
                                        onChange={this.onChange}
                                        value={this.state.mjesec}
                                        compact
                                        required
                                        search
                                    />
                                    <Form.Select
                                        label={localization.pregledBankovnihTransakcija.godina + ': '}
                                        style={styles.poslovnicaYearMonthPicker}
                                        options={GODINE.map((g, index) => {
                                            return { key: index, text: g + '.', value: g };
                                        })}
                                        name="godina"
                                        onChange={this.onChange}
                                        value={this.state.godina}
                                        compact
                                        required
                                        search
                                    />
                                    <Button
                                        icon="filter"
                                        basic
                                        color="black"
                                        size="small"
                                        style={styles.filterButton}
                                        content={localization.pregledBankovnihTransakcija.filtriraj}
                                        onClick={this.showLoader.bind(this, this.onFilter)}
                                    />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Message style={styles.headerTable}>
                                    <Table structured stackable>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell style={styles.iconCell} />
                                                <Table.HeaderCell style={styles.datumIVrijemeCell}>
                                                    {localization.pregledBankovnihTransakcija.datumIVrijemeDokumenta}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>{localization.pregledBankovnihTransakcija.tipTransakcije}</Table.HeaderCell>
                                                <Table.HeaderCell style={styles.iznosCell}>
                                                    {localization.pregledBankovnihTransakcija.uplaceniIsplaceniIznos}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell style={styles.izradioCell}>
                                                    {localization.pregledBankovnihTransakcija.izradio}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell style={styles.potvrdenaCell}>
                                                    {localization.pregledBankovnihTransakcija.potvrdena}
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                    </Table>
                                </Message>
                            </Grid.Column>
                        </Grid.Row>
                        {this.state.transakcije.map((transakcija, index) => {
                            let mappedTransakcija = BankovneTransakcijeMappings[transakcija.type](transakcija);
                            return (
                                <Grid.Row key={index}>
                                    <Grid.Column verticalAlign="middle">
                                        <Accordion fluid styled>
                                            <Accordion.Title active={this.state.activeItems.includes(index)} index={index} onClick={this.expand}>
                                                <Table structured stackable>
                                                    <Table.Header>
                                                        <Table.Row positive={!transakcija.isConfirmed} error={!transakcija.isConfirmed}>
                                                            <Table.Cell style={styles.iconCell}>
                                                                <Icon name="dropdown" />
                                                            </Table.Cell>
                                                            <Table.Cell style={styles.datumIVrijemeCell}>
                                                                {moment(transakcija.datumIVrijeme).format('DD.MM.YYYY. HH:mm')}
                                                            </Table.Cell>
                                                            <Table.Cell>{mappedTransakcija.imeTransakcije}</Table.Cell>
                                                            <Table.Cell style={styles.iznosCell}>
                                                                <FormatIznosa value={transakcija.iznos} />
                                                            </Table.Cell>
                                                            <Table.Cell style={styles.izradioCell}>{mappedTransakcija.korisnik}</Table.Cell>
                                                            <Table.Cell style={styles.potvrdenaCell}>
                                                                {transakcija.isConfirmed ? localization.common.da : localization.common.ne}
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    </Table.Header>
                                                </Table>
                                            </Accordion.Title>
                                            <Accordion.Content active={this.state.activeItems.includes(index)}>
                                                {mappedTransakcija.content}
                                                {!transakcija.isConfirmed ? (
                                                    <div style={styles.actionButtons}>
                                                        <Modal
                                                            open={this.state.potvrdaPotvrde === transakcija.entityId}
                                                            closeOnDimmerClick={false}
                                                            trigger={
                                                                <Button
                                                                    color="green"
                                                                    content={localization.pregledBankovnihTransakcija.potvrdi}
                                                                    onClick={this.showHidePotvrda.bind(this, transakcija.entityId)}
                                                                />
                                                            }
                                                        >
                                                            <Header content={localization.pregledBankovnihTransakcija.potvrdaHeader} />
                                                            <Modal.Content>
                                                                <p>{localization.pregledBankovnihTransakcija.pitanjePotvrde}</p>
                                                            </Modal.Content>
                                                            <Modal.Actions>
                                                                <Button
                                                                    icon="remove"
                                                                    content={localization.common.odustani}
                                                                    onClick={this.showHidePotvrda.bind(this, undefined)}
                                                                />
                                                                <Button
                                                                    icon="checkmark"
                                                                    color="green"
                                                                    content={localization.pregledBankovnihTransakcija.potvrdi}
                                                                    onClick={this.showLoader.bind(this, this.potvrdiTransakciju.bind(this, transakcija))}
                                                                />
                                                            </Modal.Actions>
                                                        </Modal>
                                                    </div>
                                                ) : null}
                                            </Accordion.Content>
                                        </Accordion>
                                    </Grid.Column>
                                </Grid.Row>
                            );
                        })}
                    </Grid>
                </Segment>
                <Dimmer active={this.state.loader} page>
                    <Loader />
                </Dimmer>
            </div>
        );
    }
}

PregledBankovnihTransakcija.propTypes = {
    poslovnice: PropTypes.array.isRequired,
    bankAccountManagementDataAccess: PropTypes.object.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {
        poslovnice: state.mjestaReducer.poslovnice || []
    };
};

let mapDispatchToProps = function(dispatch) {
    return {
        bankAccountManagementDataAccess: bindActionCreators(BankAccountManagementDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PregledBankovnihTransakcija);
