import React from 'react';
import PropTypes from 'prop-types';
import { Form, Grid, Header, Segment } from 'semantic-ui-react';
import localization from '../../locales/localization';
import NesluzbenaNapomena from './NesluzbenaNapomena';

const styles = {
    required: {
        borderColor: 'red'
    },
    requiredText: {
        color: 'red'
    }
};

export default class Napomene extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Segment tertiary>
                    <Grid stackable>
                        <Grid.Row>
                            <Grid.Column verticalAlign="middle">
                                <Header as="h3">{this.props.sluzbenaNapomenaHeader}</Header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column verticalAlign="middle">
                                <Form>
                                    <Form.TextArea placeholder={localization.common.napomena} name="sluzbenaNapomena" onChange={this.props.onChangeNapomena} />
                                </Form>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Segment tertiary>
                    <Grid stackable>
                        <Grid.Row>
                            <Grid.Column verticalAlign="middle">
                                <Header as="h3">{this.props.nesluzbenaNapomenaHeader}</Header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column verticalAlign="middle">
                                <NesluzbenaNapomena
                                    onChangeNapomena={this.props.onChangeNapomena}
                                    required={this.props.required}
                                    requiredText={this.props.requiredText}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            </React.Fragment>
        );
    }
}

Napomene.propTypes = {
    sluzbenaNapomenaHeader: PropTypes.string.isRequired,
    nesluzbenaNapomenaHeader: PropTypes.string.isRequired,
    onChangeNapomena: PropTypes.func.isRequired,
    required: PropTypes.bool,
    requiredText: PropTypes.string
};
