import React from 'react';

import enums from '../utils/Enums';
import localization from '../locales/localization';
import OtkupProdajaContent from '../components/dokumenti_components/content_components/OtkupProdajaContent';
import TrosakContent from '../components/dokumenti_components/content_components/TrosakContent';

export default {
    RACUN: transakcija => {
        let fiskIliTrans = localization.pregledBankovnihTransakcija.uplataZaFiskalniRacun;
        if (transakcija.entity.rbrTransakcijskiRacun !== undefined) {
            fiskIliTrans = localization.pregledBankovnihTransakcija.uplataZaTransakcijskiRacun;
        }
        return {
            imeTransakcije:
                fiskIliTrans + ' ' + localization.common.br + ' ' + (transakcija.entity.rbrFiskalniRacun || transakcija.entity.rbrTransakcijskiRacun),
            korisnik: transakcija.entity.user.ime + ' ' + transakcija.entity.user.prezime,
            content: (
                <OtkupProdajaContent
                    klijent={transakcija.entity.klijent}
                    type={enums.tipTabliceArtikala.RACUN_FIXED}
                    artikli={transakcija.entity.skladisniIzlazi.map(si => {
                        return Object.assign({}, si, {
                            sifra: si.sifraArtikla,
                            naziv: si.nazivArtikla,
                            cijena: si.jedCijena,
                            kolicina: transakcija.entity.rbrStorniranogRacuna === null ? si.kolicina : -si.kolicina
                        });
                    })}
                />
            )
        };
    },
    SUB: transakcija => {
        return {
            imeTransakcije: localization.pregledBankovnihTransakcija.uplataZaPrimku + ' ' + localization.common.br + ' ' + transakcija.entity.rbrPrimka,
            korisnik: transakcija.entity.user.ime + ' ' + transakcija.entity.user.prezime,
            content: (
                <OtkupProdajaContent
                    klijent={transakcija.entity.pravnaOsoba}
                    type={enums.tipTabliceArtikala.PRIMKA_FIXED}
                    artikli={transakcija.entity.skladisniUlazi.map(su => {
                        return Object.assign({}, su, su.predmet, {
                            cijena: su.jedNabavnaCijena
                        });
                    })}
                />
            )
        };
    },
    TROSAK_ONE_TIME: transakcija => {
        return {
            imeTransakcije: localization.pregledBankovnihTransakcija.bankovnaIsplataJednokratnogTroska + ' - ' + transakcija.entity.ime,
            korisnik: transakcija.entity.user.ime + ' ' + transakcija.entity.user.prezime,
            content: <TrosakContent trosak={transakcija.entity} />
        };
    },
    TROSAK_RECURRING: transakcija => {
        return {
            imeTransakcije: localization.pregledBankovnihTransakcija.bankovnaIsplataPonavljajucegTroska + ' - ' + transakcija.entity.ime,
            korisnik: transakcija.entity.user.ime + ' ' + transakcija.entity.user.prezime,
            content: <TrosakContent trosak={transakcija.entity} />
        };
    }
};
