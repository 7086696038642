import React from 'react';
import PropTypes from 'prop-types';
import { Header, Segment, Table } from 'semantic-ui-react';

import localization from '../../../locales/localization';
import moment from 'moment';
import FormatIznosa from '../../common_components/FormatIznosa';
import enums from '../../../utils/Enums';

const styles = {
    kolicinaIcijena: {
        fontFamily: 'monospace'
    }
};

export default class RezervacijeAkcijeContent extends React.Component {
    render() {
        return (
            <Segment>
                <Header as="h4">{localization.rezervacije.rezervacije}</Header>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>{localization.artikl.sifra}</Table.HeaderCell>
                            <Table.HeaderCell>{localization.artikl.naziv}</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center" collapsing>
                                {localization.common.kolicina}
                            </Table.HeaderCell>
                            {sessionStorage.userRole === enums.tipKorisnika.VLASNIK ? (
                                <Table.HeaderCell textAlign="center" collapsing>
                                    {localization.common.trzisnaOtkupnaVrijednost}
                                </Table.HeaderCell>
                            ) : null}
                            <Table.HeaderCell textAlign="center">{localization.rezervacije.datumIsteka}</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">{localization.rezervacije.rokZaPodizanje}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.props.rezervacije.map(rezervacija => {
                            return (
                                <Table.Row key={rezervacija.id}>
                                    <Table.Cell>{rezervacija.predmet.sifra}</Table.Cell>
                                    <Table.Cell>{rezervacija.predmet.naziv}</Table.Cell>
                                    <Table.Cell collapsing textAlign="right" style={styles.kolicinaIcijena}>
                                        {rezervacija.kolicina}
                                    </Table.Cell>
                                    {sessionStorage.userRole === enums.tipKorisnika.VLASNIK ? (
                                        <Table.Cell collapsing textAlign="right" style={styles.kolicinaIcijena}>
                                            <FormatIznosa value={rezervacija.kolicina * rezervacija.jedNabavnaCijena} />
                                        </Table.Cell>
                                    ) : null}
                                    <Table.Cell textAlign="center">{moment(rezervacija.datumIsteka).format('DD.MM.YYYY.')}</Table.Cell>
                                    <Table.Cell textAlign="center">{moment(rezervacija.rokZaPodizanje).format('DD.MM.YYYY.')}</Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            </Segment>
        );
    }
}

RezervacijeAkcijeContent.propTypes = {
    rezervacije: PropTypes.array.isRequired
};
