import KontrolaCalculator from '../utils/KontrolaCalculator';

let initialState = {
    kontrola: [],
    cached: false
};

export default function kontrolaReducer(state = initialState, action) {
    switch (action.type) {
        case 'GET_KONTROLA': {
            action.kontrola.forEach(k => {
                let brojPopunjenih = 0,
                    brojNetocnihUnosa = 0;
                k.kontroleRadnji.forEach(kr => {
                    kr.tocanIznos = KontrolaCalculator[kr.radnja.tipRadnje](kr.radnja);
                    if (kr.unosKontrole) {
                        brojPopunjenih++;
                        if (kr.tocanIznos !== kr.unosKontrole.iznos) {
                            brojNetocnihUnosa++;
                        }
                    }
                });
                k.postotakPopunjenosti = (brojPopunjenih / k.kontroleRadnji.length) * 100;
                k.brojNetocnihUnosa = brojNetocnihUnosa;
            });
            return Object.assign({}, state, {
                kontrola: action.kontrola,
                cached: true
            });
        }
        default:
            return state;
    }
}
