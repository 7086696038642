import 'babel-polyfill';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';

import App from './components/App';
import PrintTemplate from './components/PrintTemplate';
import Login from './components/Login';
import endpoints from './environments/endpoints';

import startStore from './store/store';

const store = startStore();

render(
    <Provider store={store}>
        <BrowserRouter>
            <Route
                path={endpoints.appEndpoints.root}
                render={props =>
                    sessionStorage.isAuthenticated && sessionStorage.userRole ? (
                        !props.location.pathname.startsWith('/print') ? (
                            <App {...props} />
                        ) : (
                            <PrintTemplate {...props} />
                        )
                    ) : (
                        <Login {...props} />
                    )
                }
            />
        </BrowserRouter>
    </Provider>,
    document.getElementById('app')
);
