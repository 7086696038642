import React from 'react';
import PropTypes from 'prop-types';
import { Header } from 'semantic-ui-react';
import localization from '../locales/localization';
import enums from './Enums';

// eslint-disable-next-line
export function renderClientResults({ result }) {
    let prefix;
    if (result.idBrojTip === enums.idBrojTip.OIB) {
        prefix = localization.klijent.oib;
    } else if (result.idBrojTip === enums.idBrojTip.BR_OSOBNE) {
        prefix = localization.klijent.brojOsobne;
    } else {
        prefix = localization.klijent.brojPutovnice;
    }
    return (
        <div key={result.id}>
            <Header as="h5">
                {result.ime ? result.ime + ' ' + result.prezime : result.naziv}
                <Header.Subheader>{prefix + ': ' + result.idBroj}</Header.Subheader>
            </Header>
        </div>
    );
}

// eslint-disable-next-line
export function renderItemsResults({ result }) {
    return (
        <div key={result.id}>
            <Header as="h5">
                {result.naziv}
                <Header.Subheader>{localization.artikl.sifra + ': ' + result.sifra}</Header.Subheader>
                {result.serijskiBroj !== null ? <Header.Subheader>{localization.artikl.serijskiBroj + ': ' + result.serijskiBroj}</Header.Subheader> : null}
            </Header>
        </div>
    );
}

// eslint-disable-next-line
export function renderUslugeResults({ result }) {
    return (
        <div key={result.id}>
            <Header as="h5">
                {result.naziv}
                <Header.Subheader>{localization.artikl.sifra + ': ' + result.sifra}</Header.Subheader>
            </Header>
        </div>
    );
}
