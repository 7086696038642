let initialState = {
    kategorije: [],
    cached: false
};

export default function kategorijeReducer(state = initialState, action) {
    switch (action.type) {
        case 'GET_KATEGORIJE': {
            return Object.assign({}, state, {
                kategorije: [...action.kategorije],
                cached: true
            });
        }
        default:
            return state;
    }
}
