import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import PrintProvider, { Print, NoPrint } from 'react-easy-print';
import ZaglavljeTvrtke from './common_components/ZaglavljeTvrtke';
import { Button, Table } from 'semantic-ui-react';
import localization from '../../locales/localization';
import DatumIMjestoObicno from './common_components/DatumIMjestoObicno';
import moment from 'moment';
import FormatIznosa from '../common_components/FormatIznosa';
import { YEAR_EURO_WAS_INTRODUCED } from '../../utils/Valute';

const styles = {
    container: {
        padding: 24
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    zaglavlja: {
        width: '50%'
    },
    zaglavljeTvrtke: {
        paddingBottom: 24
    },
    poslovnaJedinica: {
        fontSize: 14
    },
    datumImjesto: {
        paddingBottom: 8
    },
    p: {
        fontSize: 10
    },
    title: {
        paddingTop: 48
    },
    table: {
        fontSize: 'xx-small'
    },
    tableCell: {
        paddingTop: 'unset',
        paddingBottom: 'unset'
    },
    tableCellKol: {
        paddingTop: 'unset',
        paddingBottom: 'unset',
        fontFamily: 'monospace'
    },
    mp: {
        fontSize: 10,
        textAlign: 'center'
    },
    printButton: {
        textAlign: 'center',
        paddingBottom: 8
    }
};

export default class Izdatnica extends React.Component {
    constructor(props) {
        super(props);
        let izdatnica = JSON.parse(sessionStorage.getItem(this.props.match.params.id));
        let isKuna = moment(izdatnica.datumIVrijeme).year() < YEAR_EURO_WAS_INTRODUCED
        this.state = {
            document: izdatnica,
            isKuna: isKuna
        };
        this.printDocument = this.printDocument.bind(this);
    }

    printDocument() {
        window.print();
    }

    calculateUkupno(stavke) {
        let ukupno = 0;
        stavke.forEach(stavka => {
            if (this.state.document.rbrStorniraneIzdatnice) {
                stavka.kolicina = -stavka.kolicina;
            }
            ukupno += stavka.kolicina * (this.state.isKuna ? stavka.jedNabavnaCijenaHrk : stavka.jedNabavnaCijena);
        });
        return ukupno;
    }

    render() {
        let ukupno = this.calculateUkupno([...this.state.document.stavke]);
        return (
            <PrintProvider>
                <Print>
                    <div style={styles.container}>
                        <div style={styles.headerContainer}>
                            <div style={styles.zaglavlja}>
                                <div style={styles.zaglavljeTvrtke}>
                                    <ZaglavljeTvrtke firma={this.state.document.firma} small={true} />
                                </div>
                                <p style={styles.poslovnaJedinica}>
                                    {localization.printingComponents.common.poslovnaJedinica + ' ' + this.state.document.poslovnica.brojPoslovnice + ': '}
                                    <b>{this.state.document.poslovnica.naziv}</b>
                                </p>
                                <div style={styles.datumImjesto}>
                                    <DatumIMjestoObicno
                                        datum={moment(this.state.document.datumIVrijeme).format('DD.MM.YYYY.')}
                                        mjesto={this.state.document.poslovnica.naselje}
                                    />
                                </div>
                            </div>
                            <div style={styles.zaglavlja}>
                                <div style={styles.title}>
                                    <h3>
                                        {localization.printingComponents.izdatnica.naslov +
                                            ' ' +
                                            localization.printingComponents.common.br +
                                            ' ' +
                                            this.state.document.rbrIzdatnice}
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <Table style={styles.table}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={styles.tableCell} />
                                    <Table.HeaderCell style={styles.tableCell}>{localization.artikl.sifra}</Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell}>{localization.artikl.naziv}</Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell} textAlign="center">
                                        {localization.artikl.mjernaJedinica}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell} textAlign="center">
                                        {localization.common.kolicina}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell} textAlign="center">
                                        {localization.izdatnice.jedinicnaNabavnaCijena}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell} textAlign="center">
                                        {localization.izdatnice.nabavnaCijena}
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.state.document.stavke.map((stavka, index) => {
                                    return (
                                        <Table.Row key={index}>
                                            <Table.Cell style={styles.tableCell}>{index + 1}</Table.Cell>
                                                <Table.Cell style={styles.tableCell}>{stavka.predmet.sifra}</Table.Cell>
                                            <Table.Cell style={styles.tableCell} singleLine>
                                                <b>{stavka.predmet.naziv}</b>
                                            </Table.Cell>
                                            <Table.Cell style={styles.tableCell} textAlign="center" collapsing>
                                                {stavka.predmet.mjernaJedinica}
                                            </Table.Cell>
                                            <Table.Cell style={styles.tableCellKol} textAlign="right" collapsing>
                                                {stavka.kolicina}
                                            </Table.Cell>
                                            <Table.Cell style={styles.tableCellKol} textAlign="right" collapsing>
                                                <FormatIznosa value={this.state.isKuna ? stavka.jedNabavnaCijenaHrk : stavka.jedNabavnaCijena} suffix={this.state.isKuna ? localization.common.hrk : null} />
                                            </Table.Cell>
                                            <Table.Cell style={styles.tableCellKol} textAlign="right" collapsing>
                                                <FormatIznosa value={stavka.kolicina * (this.state.isKuna ? stavka.jedNabavnaCijenaHrk : stavka.jedNabavnaCijena)} suffix={this.state.isKuna ? localization.common.hrk : null} />
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                            <Table.Footer>
                                <Table.Row>
                                    <Table.HeaderCell style={styles.tableCell} colSpan={4} />
                                    <Table.HeaderCell style={styles.tableCell} colSpan={2}>
                                        <b>{localization.common.ukupno + ':'}</b>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCellKol} textAlign="right" collapsing>
                                        <b>
                                            <FormatIznosa value={ukupno} suffix={this.state.isKuna ? localization.common.hrk : null} />
                                        </b>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        </Table>
                        {this.state.document.sluzbenaNapomena ? (
                            <React.Fragment>
                                <br />
                                <div>
                                    <p style={styles.p}>
                                        <b>{localization.printingComponents.common.sluzbenaNapomena + ':'}</b>
                                    </p>
                                    <p style={styles.p}>{this.state.document.sluzbenaNapomena}</p>
                                </div>
                            </React.Fragment>
                        ) : null}
                        <p style={styles.mp}>{localization.printingComponents.common.dokumentObradenElektronickimPutem}</p>
                    </div>
                </Print>
                <NoPrint>
                    <div style={styles.printButton}>
                        <Button color="green" icon="print" size="massive" content={localization.common.ispis} onClick={this.printDocument} />
                    </div>
                </NoPrint>
            </PrintProvider>
        );
    }
}

Izdatnica.propTypes = {
    match: PropTypes.object.isRequired
};
