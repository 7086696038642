let initialState = {
    flags: [],
    cached: false
};

export default function flagsReducer(state = initialState, action) {
    switch (action.type) {
        case 'GET_FLAGS': {
            return Object.assign({}, state, {
                flags: [...action.flags],
                cached: true
            });
        }
        default:
            return state;
    }
}
