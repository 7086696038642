import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Dimmer, Form, Grid, Header, Loader, Segment } from 'semantic-ui-react';

import localization from '../locales/localization';
import moment from 'moment';
import * as KontrolaDataAccess from '../data_access/KontrolaDataAccess';
import * as MjestaDataAccess from '../data_access/MjestaDataAccess';
import ListaKontrola from './kontrola_components/ListaKontrola';
import DetaljiKontrole from './kontrola_components/DetaljiKontrole';
import enums from '../utils/Enums';

const styles = {
    titleContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    dateContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    header: {
        margin: 0
    },
    povratakButton: {
        marginLeft: 16
    },
    yearMonthPicker: {
        marginRight: 8
    },
    filterButton: {
        marginLeft: 8
    }
};

const GODINE = _.range(2019, parseInt(moment().get('year')) + 1);
const views = {
    MASTER: 0,
    DETAIL: 1
};

class Kontrola extends React.Component {
    constructor(props) {
        super(props);
        let date = moment();
        this.state = {
            active: views.MASTER,
            poslovnicaId: this.props.poslovnice.length ? this.props.poslovnice[0].id : undefined,
            detalji: undefined,
            godina: parseInt(date.format('YYYY')),
            mjesec: parseInt(date.format('MM')),
            loader: !this.props.poslovniceCached
        };
        this.onChange = this.onChange.bind(this);
        this.onFilter = this.onFilter.bind(this);
        this.showLoader = this.showLoader.bind(this);
        this.hideLoader = this.hideLoader.bind(this);

        if (!this.props.poslovniceCached) {
            this.props.mjestaDataAccess.getMjesta(this.onFilter);
        } else {
            this.onFilter();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.poslovniceCached) {
            this.setState({
                poslovnicaId: nextProps.poslovnice[0].id,
                loader: false
            });
        }
    }

    onChange(e, { name, value }) {
        this.setState({ [name]: value });
    }

    onChangeMasterDetail(view, detail) {
        if (view === views.MASTER) {
            this.setState({
                active: view,
                detalji: undefined
            });
        } else {
            this.setState({
                active: view,
                detalji: detail
            });
        }
    }

    onFilter() {
        let date = moment(this.state.godina + '-' + this.state.mjesec + '-01');
        let startDate = date.format('YYYY-MM-DD');
        let endDate = date.endOf('month').format('YYYY-MM-DD');
        this.props.kontrolaDataAccess.getUnosiKontrole(this.state.poslovnicaId, startDate, endDate, this.hideLoader);
    }

    showLoader(callback) {
        this.setState(
            {
                loader: true
            },
            callback
        );
    }

    hideLoader() {
        this.setState({
            loader: false
        });
    }

    render() {
        return (
            <div>
                <Segment tertiary>
                    <Grid stackable>
                        <Grid.Row columns={2}>
                            <Grid.Column verticalAlign="middle">
                                <div style={styles.titleContainer}>
                                    <Header as="h2" style={styles.header}>
                                        {localization.kontrola.kontrola}
                                    </Header>
                                    {this.state.active === views.DETAIL ? (
                                        <Button
                                            icon="arrow left"
                                            basic
                                            color="black"
                                            size="small"
                                            style={styles.povratakButton}
                                            content={localization.kontrola.povratak}
                                            onClick={this.onChangeMasterDetail.bind(this, views.MASTER)}
                                        />
                                    ) : null}
                                </div>
                            </Grid.Column>
                            {this.state.active === views.MASTER ? (
                                <Grid.Column verticalAlign="middle">
                                    <div style={styles.dateContainer}>
                                        <Form.Select
                                            label={localization.kontrola.poslovnica + ': '}
                                            style={styles.yearMonthPicker}
                                            options={this.props.poslovnice.map(p => {
                                                return { key: p.id, text: p.naziv, value: p.id };
                                            })}
                                            name="poslovnicaId"
                                            onChange={this.onChange}
                                            value={this.state.poslovnicaId}
                                            compact
                                            required
                                            search
                                        />
                                        <Form.Select
                                            label={localization.kontrola.mjesec + ': '}
                                            style={styles.yearMonthPicker}
                                            options={Array.from(new Array(12), (val, index) => {
                                                return { key: index, text: index + 1 + '.', value: index + 1 };
                                            })}
                                            name="mjesec"
                                            labeled
                                            onChange={this.onChange}
                                            value={this.state.mjesec}
                                            compact
                                            required
                                            search
                                        />
                                        <Form.Select
                                            label={localization.kontrola.godina + ': '}
                                            style={styles.yearMonthPicker}
                                            options={GODINE.map((g, index) => {
                                                return { key: index, text: g + '.', value: g };
                                            })}
                                            name="godina"
                                            onChange={this.onChange}
                                            value={this.state.godina}
                                            compact
                                            required
                                            search
                                        />
                                        <Button
                                            icon="filter"
                                            basic
                                            color="black"
                                            size="small"
                                            style={styles.filterButton}
                                            content={localization.kontrola.filtriraj}
                                            onClick={this.showLoader.bind(this, this.onFilter)}
                                        />
                                    </div>
                                </Grid.Column>
                            ) : null}
                        </Grid.Row>
                        {this.state.active === views.MASTER ? (
                            <ListaKontrola
                                onDetailClick={this.onChangeMasterDetail.bind(this, views.DETAIL)}
                                postavke={{
                                    poslovnicaId: this.state.poslovnicaId,
                                    godina: this.state.godina,
                                    mjesec: this.state.mjesec
                                }}
                            />
                        ) : (
                            <DetaljiKontrole
                                detaljiKontrole={this.state.detalji}
                                postavke={{
                                    poslovnicaId: this.state.poslovnicaId,
                                    godina: this.state.godina,
                                    mjesec: this.state.mjesec
                                }}
                            />
                        )}
                    </Grid>
                </Segment>
                <Dimmer active={this.state.loader} page>
                    <Loader />
                </Dimmer>
            </div>
        );
    }
}

Kontrola.propTypes = {
    poslovnice: PropTypes.array.isRequired,
    poslovniceCached: PropTypes.bool.isRequired,
    kontrolaDataAccess: PropTypes.object.isRequired,
    mjestaDataAccess: PropTypes.object.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {
        poslovnice: state.mjestaReducer.poslovnice || [],
        poslovniceCached: state.mjestaReducer.cached
    };
};

let mapDispatchToProps = function(dispatch) {
    return {
        kontrolaDataAccess: bindActionCreators(KontrolaDataAccess, dispatch),
        mjestaDataAccess: bindActionCreators(MjestaDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Kontrola);
