import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Card, Form, Header, Modal, Segment } from 'semantic-ui-react';

import KarticniUvjeti from './KarticniUvjeti';
import AddNewButton from './AddNewButton';
import { generateKey } from '../../utils/KeyGenerator';
import localization from '../../locales/localization';
import * as KarticeDataAccess from '../../data_access/KarticeDataAccess';

const styles = {
    deleteButton: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    container: {
        textAlign: 'left'
    },
    label: {
        display: 'block',
        textAlign: 'left',
        color: 'rgba(0,0,0,0.87)',
        fontWeight: 700,
        marginTop: 8,
        marginBottom: 8
    },
    actionButtons: {
        marginTop: 16,
        display: 'flex',
        justifyContent: 'flex-end'
    }
};

class Kartica extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            naziv: this.props.kartica.naziv,
            karticniUvjeti: this.props.kartica.karticniUvjeti,
            activeModal: false,
            enableSaveButton: false,
            noviKarticniUvjeti: false
        };
        this.onChange = this.onChange.bind(this);
        this.onChangeKarticniUvjeti = this.onChangeKarticniUvjeti.bind(this);
        this.onDeleteKarticniUvjeti = this.onDeleteKarticniUvjeti.bind(this);
        this.onAddNew = this.onAddNew.bind(this);
        this.enableSaveButton = this.enableSaveButton.bind(this);
        this.checkKarticaRequiredFields = this.checkKarticaRequiredFields.bind(this);
        this.showHideModal = this.showHideModal.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            naziv: nextProps.kartica.naziv,
            karticniUvjeti: nextProps.kartica.karticniUvjeti,
            activeModal: false,
            enableSaveButton: false,
            noviKarticniUvjeti: false
        });
    }

    onChange(e, { name, value }) {
        this.setState({ [name]: value }, this.enableSaveButton);
    }

    onChangeKarticniUvjeti(index, karticniUvjeti) {
        this.setState(
            {
                karticniUvjeti: [...this.state.karticniUvjeti.slice(0, index), ...[karticniUvjeti], ...this.state.karticniUvjeti.slice(index + 1)]
            },
            this.enableSaveButton
        );
    }

    onDeleteKarticniUvjeti(index) {
        this.setState(
            {
                karticniUvjeti: this.state.karticniUvjeti.slice(0, index).concat(this.state.karticniUvjeti.slice(index + 1)),
                noviKarticniUvjeti: this.state.noviKarticniUvjeti && !(index === this.state.karticniUvjeti.length - 1)
            },
            this.enableSaveButton
        );
    }

    onAddNew(e) {
        this.setState(
            {
                karticniUvjeti: [
                    ...this.state.karticniUvjeti,
                    {
                        pocetnoMjeseci: undefined,
                        postotak: undefined,
                        odgodaUplateDana: undefined
                    }
                ],
                noviKarticniUvjeti: true
            },
            this.enableSaveButton
        );
    }

    enableSaveButton() {
        this.setState({ enableSaveButton: this.checkKarticaRequiredFields() });
    }

    checkKarticaRequiredFields() {
        let checkKarticniUvjeti = karticniUvjeti => {
            for (let i = 0; i < karticniUvjeti.length; i++) {
                if (
                    karticniUvjeti[i].pocetnoMjeseci === undefined ||
                    karticniUvjeti[i].postotak === undefined ||
                    karticniUvjeti[i].odgodaUplateDana === undefined
                ) {
                    return true;
                }
            }
            return false;
        };
        if (!this.state.naziv || checkKarticniUvjeti(this.state.karticniUvjeti)) {
            return false;
        }
        return true;
    }

    showHideModal(value) {
        this.setState({ activeModal: value });
    }

    onCancel() {
        if (this.props.novo) {
            this.props.onDelete();
        } else {
            this.setState({
                naziv: this.props.kartica.naziv,
                karticniUvjeti: this.props.kartica.karticniUvjeti,
                activeModal: false,
                enableSaveButton: false,
                noviKarticniUvjeti: false
            });
        }
    }

    onSave() {
        let kartica = {
            naziv: this.state.naziv,
            karticniUvjeti: this.state.karticniUvjeti.map(ku => {
                return {
                    pocetnoMjeseci: parseFloat(ku.pocetnoMjeseci),
                    postotak: parseFloat(ku.postotak),
                    odgodaUplateDana: parseFloat(ku.odgodaUplateDana)
                };
            })
        };
        if (this.props.novo) {
            this.props.karticeDataAccess.createKartica(kartica);
        } else {
            this.props.karticeDataAccess.updateKartica(this.props.kartica.id, kartica);
        }
    }

    onDelete() {
        this.props.karticeDataAccess.deleteKartica(this.props.kartica.id);
    }

    render() {
        return (
            <Segment>
                {!this.props.novo ? (
                    <div style={styles.deleteButton}>
                        <Modal
                            open={this.state.activeModal}
                            trigger={<Button icon="delete" color="red" basic size="small" onClick={this.showHideModal.bind(this, true)} />}
                        >
                            <Header icon="trash" content={localization.common.potvrdaBrisanja} />
                            <Modal.Content>
                                <p>{localization.postavke.brisanjeKarticePotvrda}</p>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button icon="remove" content={localization.common.odustani} onClick={this.showHideModal.bind(this, false)} />
                                <Button icon="trash" color="red" content={localization.common.obrisi} onClick={this.onDelete} />
                            </Modal.Actions>
                        </Modal>
                    </div>
                ) : null}
                <Form style={styles.container}>
                    <Form.Input fluid label={localization.postavke.nazivKartice} name="naziv" onChange={this.onChange} value={this.state.naziv} required />
                </Form>
                <div>
                    <label style={styles.label}>{localization.postavke.karticniUvjeti}</label>
                    <Card.Group itemsPerRow={3} stackable>
                        {this.state.karticniUvjeti.map((ku, index) => {
                            return (
                                <Card key={index}>
                                    <Card.Content textAlign="center">
                                        <KarticniUvjeti
                                            karticniUvjeti={ku}
                                            onChange={this.onChangeKarticniUvjeti.bind(this, index)}
                                            onDelete={this.onDeleteKarticniUvjeti.bind(this, index)}
                                        />
                                    </Card.Content>
                                </Card>
                            );
                        })}
                        {!this.state.noviKarticniUvjeti ? (
                            <AddNewButton
                                key={generateKey()}
                                text={localization.postavke.dodajNoveKarticneUvjete}
                                name="noviKarticniUvjeti"
                                onAddNew={this.onAddNew}
                            />
                        ) : null}
                    </Card.Group>
                </div>
                <div style={styles.actionButtons}>
                    <Button
                        icon="cancel"
                        content={this.props.novo ? localization.common.odustani : localization.common.vratiNaSpremljeno}
                        onClick={this.onCancel}
                    />
                    <Button icon="save" color="green" disabled={!this.state.enableSaveButton} content={localization.common.spremi} onClick={this.onSave} />
                </div>
            </Segment>
        );
    }
}

Kartica.propTypes = {
    novo: PropTypes.bool,
    kartica: PropTypes.object.isRequired,
    karticeDataAccess: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {};
};

let mapDispatchToProps = function(dispatch) {
    return {
        karticeDataAccess: bindActionCreators(KarticeDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Kartica);
