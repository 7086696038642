import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Dimmer, Dropdown, Grid, Header, Icon, Loader, Modal, Pagination, Search, Segment, Select, Table } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import localization from '../../locales/localization';
import enums from '../../utils/Enums';
import icons from '../../utils/DefinedIcons';
import { storeDocumentAndGenerateKey } from '../../utils/PrintingDocumentsHandler';
import SearchResultsMessage from '../common_components/SearchResultsMessage';
import ImageUploadModal from '../common_components/ImageUploadModal';
import Diskusija from '../common_components/Diskusija';
import UnosProdajnihCijena from '../otkup_components/UnosProdajnihCijena';
import * as SkladisteDataAccess from '../../data_access/SkladisteDataAccess';
import endpoints from '../../environments/endpoints';
import FormatIznosa from '../common_components/FormatIznosa';
import FilterSortButton from '../common_components/FilterSortButton';

const styles = {
    paginationContainer: {
        textAlign: 'center'
    },
    modalLabel: {
        marginRight: 16
    },
    kolicinaIcijena: {
        fontFamily: 'monospace'
    }
};

const modaliEnums = {
    unosProdajnihCijena: 'unosProdajnihCijena',
    printStanjaSkladistaNaOdredeniDan: 'printStanjaSkladistaNaOdredeniDan',
    printZaKnjigovodstvoPotvrda: 'printZaKnjigovodstvoPotvrda',
    slikeModal: 'slikeModal',
    napomeneModal: 'napomeneModal',
    promjenaProdajnihCijena: 'promjenaProdajnihCijena'
};

const ITEMS_PER_PAGE = 20;

class StanjeSkladista extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSearchLoading: false,
            value: '',
            mjestoId: this.props.mjesta[0].id,
            stanjeSkladista: this.props.stanjeSkladista,
            results: this.props.stanjeSkladista,
            activeModal: undefined,
            noveNapomene: [],
            loader: !this.props.stanjeSkladistaCache,
            filter: 0,
            sort: 1,
            pageActive: 1,
            selectedDate: moment()
        };
        this.calculatePage = this.calculatePage.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
        this.applyFilterAndSort = this.applyFilterAndSort.bind(this);
        this.filterAndSort = this.filterAndSort.bind(this);
        this.filter = this.filter.bind(this);
        this.sort = this.sort.bind(this);
        this.resetSearchComponent = this.resetSearchComponent.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.search = this.search.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeSelectedDate = this.onChangeSelectedDate.bind(this);
        this.onPrintZaInventuru = this.onPrintZaInventuru.bind(this);
        this.onPrintZaKnjigovodstvo = this.onPrintZaKnjigovodstvo.bind(this);
        this.onChangeNovaNapomena = this.onChangeNovaNapomena.bind(this);
        this.dodajNapomenu = this.dodajNapomenu.bind(this);
        this.resetNapomena = this.resetNapomena.bind(this);
        this.showHideModal = this.showHideModal.bind(this);
        this.showLoader = this.showLoader.bind(this);
        this.hideLoader = this.hideLoader.bind(this);

        this.FILTER_ITEMS = [localization.common.svePoslovnice];

        this.SORT_ITEMS = [
            localization.artikl.sifra,
            localization.artikl.naziv,
            localization.stanjeSkladista.jedinicnaProdajnaCijena,
            localization.common.vrijednost
        ];

        if (sessionStorage.userRole !== enums.tipKorisnika.DJELATNIK) {
            this.props.mjesta.forEach(m => this.FILTER_ITEMS.push(m.naziv));
            this.SORT_ITEMS.push(localization.common.skladiste);
            if (sessionStorage.userRole === enums.tipKorisnika.VLASNIK) {
                this.SORT_ITEMS.push(localization.stanjeSkladista.jedinicnaCijenaOtkupa);
            }
        }
    }

    componentWillMount() {
        this.resetSearchComponent();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.stanjeSkladistaCache) {
            this.setState({
                stanjeSkladista: nextProps.stanjeSkladista,
                results: nextProps.stanjeSkladista,
                loader: false
            });
        }
    }

    calculatePage(activePage) {
        let startItem = (activePage - 1) * ITEMS_PER_PAGE;
        let endItem = activePage * ITEMS_PER_PAGE;
        return [startItem, endItem];
    }

    onChangePage(e, { activePage }) {
        this.setState({
            pageActive: activePage
        });
    }

    applyFilterAndSort(filter, sort) {
        this.setState(
            {
                filter: filter,
                sort: sort,
                stanjeSkladista: this.filterAndSort(this.props.stanjeSkladista, filter, sort)
            },
            this.search
        );
    }

    filterAndSort(stanjeSkladista, filter, sort) {
        if (sessionStorage.userRole !== enums.tipKorisnika.DJELATNIK) {
            stanjeSkladista = this.filter(stanjeSkladista, filter);
        }
        stanjeSkladista = this.sort(stanjeSkladista, sort);
        return stanjeSkladista;
    }

    filter(stanjeSkladista, filter) {
        switch (this.FILTER_ITEMS[filter]) {
            case localization.common.svePoslovnice: {
                return stanjeSkladista;
            }
            default: {
                stanjeSkladista = _.filter(stanjeSkladista, { nazivMjesta: this.FILTER_ITEMS[filter] });
                return stanjeSkladista;
            }
        }
    }

    sort(stanjeSkladista, sort) {
        switch (this.SORT_ITEMS[sort]) {
            case localization.artikl.sifra: {
                stanjeSkladista = _.sortBy(stanjeSkladista, o => o.artikl.sifra);
                return stanjeSkladista;
            }
            case localization.artikl.naziv: {
                stanjeSkladista = _.sortBy(stanjeSkladista, o => o.artikl.naziv);
                return stanjeSkladista;
            }
            case localization.stanjeSkladista.jedinicnaProdajnaCijena: {
                stanjeSkladista = _.sortBy(stanjeSkladista, ['jedProdajnaCijena']);
                return stanjeSkladista;
            }
            case localization.common.vrijednost: {
                stanjeSkladista = _.sortBy(stanjeSkladista, ['prodajnaVrijednost']);
                return stanjeSkladista;
            }
            case localization.common.skladiste: {
                stanjeSkladista = _.sortBy(stanjeSkladista, ['nazivMjesta']);
                return stanjeSkladista;
            }
            case localization.stanjeSkladista.jedinicnaCijenaOtkupa: {
                stanjeSkladista = _.sortBy(stanjeSkladista, ['jedNabavnaCijena']);
                return stanjeSkladista;
            }
        }
    }

    resetSearchComponent() {
        this.setState({
            isSearchLoading: false,
            value: ''
        });
    }

    handleSearchChange(e, { value }) {
        this.setState({
            isSearchLoading: true,
            value: value,
            pageActive: 1
        });

        setTimeout(this.search, 500);
    }

    search() {
        const re = new RegExp(_.escapeRegExp(this.state.value), 'i');
        const isMatch = result => {
            let testingArray = [result.artikl.sifra, result.artikl.naziv, result.artikl.serijskiBroj, result.artikl.kategorija.ime];
            if (result.artikl.potkategorija) {
                testingArray.push(result.artikl.potkategorija.ime);
            }
            for (let i = 0; i < testingArray.length; i++) {
                if (re.test(testingArray[i])) {
                    return true;
                }
            }
            return false;
        };

        this.setState({
            isSearchLoading: false,
            results: _.filter(this.state.stanjeSkladista, isMatch)
        });
    }

    onChange(e, { name, value }) {
        this.setState({ [name]: value });
    }

    onChangeSelectedDate(date) {
        this.setState({
            selectedDate: date
        });
    }

    onPrintZaInventuru(stanjeSkladista) {
        window.open(endpoints.appEndpoints.print.stanjeSkladistaInventura + storeDocumentAndGenerateKey(stanjeSkladista), '_blank');
    }

    onPrintZaKnjigovodstvo(stanjeSkladista) {
        window.open(endpoints.appEndpoints.print.stanjeSkladista + storeDocumentAndGenerateKey(stanjeSkladista), '_blank');
        this.showHideModal(false);
    }

    onChangeNovaNapomena(id, e, { value }) {
        let noveNapomene = [];
        let index = this.state.noveNapomene.findIndex(el => el.id === id);
        if (index > -1) {
            noveNapomene = [...this.state.noveNapomene];
            noveNapomene[index] = {
                id: id,
                napomena: value
            };
        } else {
            noveNapomene = [
                ...this.state.noveNapomene,
                {
                    id: id,
                    napomena: value
                }
            ];
        }
        this.setState({
            noveNapomene: noveNapomene
        });
    }

    dodajNapomenu(artiklId, internalId) {
        this.props.skladisteDataAccess.dodavanjeNesluzbeneNapomeneNaSkladisnuStavku(
            artiklId,
            this.state.noveNapomene.find(el => el.id === internalId).napomena,
            this.resetNapomena.bind(this, internalId)
        );
    }

    resetNapomena(artiklId) {
        let index = this.state.noveNapomene.findIndex(el => el.id === artiklId);
        this.setState({
            noveNapomene: this.state.noveNapomene.slice(0, index).concat(this.state.noveNapomene.slice(index + 1))
        });
    }

    showHideModal(value, name, sifra) {
        if (value) {
            this.setState({
                activeModal: {
                    type: name,
                    id: sifra
                }
            });
        } else {
            this.setState({ activeModal: undefined });
        }
    }

    showLoader(callback) {
        this.setState(
            {
                loader: true
            },
            callback
        );
    }

    hideLoader(callback, data) {
        this.setState(
            {
                loader: false
            },
            callback.bind(this, data)
        );
    }

    render() {
        let [startItem, endItem] = this.calculatePage(this.state.pageActive);
        let totalPages = Math.ceil(this.state.results.length / ITEMS_PER_PAGE);
        let res = this.state.results.slice(startItem, endItem);
        return (
            <div>
                <Segment tertiary>
                    <Grid stackable>
                        <Grid.Row columns={5}>
                            <Grid.Column verticalAlign="middle">
                                <Header as="h2">{localization.stanjeSkladista.stanjeSkladista}</Header>
                            </Grid.Column>
                            <Grid.Column verticalAlign="middle">
                                <Search
                                    input={{ fluid: true }}
                                    loading={this.state.isSearchLoading}
                                    onSearchChange={this.handleSearchChange}
                                    value={this.state.value}
                                    open={false}
                                />
                            </Grid.Column>
                            {sessionStorage.userRole === enums.tipKorisnika.DJELATNIK ? (
                                <React.Fragment>
                                    <Grid.Column textAlign="right" verticalAlign="middle">
                                        <FilterSortButton
                                            buttonName={localization.common.sortiranje}
                                            sortingOptions={this.SORT_ITEMS}
                                            activeSort={this.state.sort}
                                            callback={this.applyFilterAndSort}
                                        />
                                    </Grid.Column>
                                    <Grid.Column textAlign="right" verticalAlign="middle">
                                        <Button
                                            icon="print"
                                            basic
                                            color="black"
                                            fluid
                                            content={localization.stanjeSkladista.printZaInventuru}
                                            onClick={this.showLoader.bind(
                                                this,
                                                this.props.skladisteDataAccess.getStanjeSkladistaZaInventuruZaPoslovnicu.bind(
                                                    this,
                                                    JSON.parse(sessionStorage.poslovnicaId),
                                                    this.hideLoader.bind(this, this.onPrintZaInventuru)
                                                )
                                            )}
                                        />
                                    </Grid.Column>
                                    <Grid.Column textAlign="right" verticalAlign="middle">
                                        <Modal
                                            open={this.state.activeModal && this.state.activeModal.type === modaliEnums.printZaKnjigovodstvoPotvrda}
                                            trigger={
                                                <Button
                                                    icon="print"
                                                    basic
                                                    color="red"
                                                    fluid
                                                    content={localization.stanjeSkladista.printZaKnjigovodstvo}
                                                    onClick={this.showHideModal.bind(this, true, modaliEnums.printZaKnjigovodstvoPotvrda)}
                                                />
                                            }
                                        >
                                            <Header icon="print" content={localization.common.potvrda} />
                                            <Modal.Content>
                                                <p>{localization.stanjeSkladista.printanjeZaKnjigovodstvoPitanjePotvrde}</p>
                                            </Modal.Content>
                                            <Modal.Actions>
                                                <Button icon="remove" content={localization.common.odustani} onClick={this.showHideModal.bind(this, false)} />
                                                <Button
                                                    icon="print"
                                                    color="red"
                                                    content={localization.common.ispis}
                                                    onClick={this.showLoader.bind(
                                                        this,
                                                        this.props.skladisteDataAccess.getStanjeSkladistaZaKnjigovodstvoZaPoslovnicu.bind(
                                                            this,
                                                            JSON.parse(sessionStorage.poslovnicaId),
                                                            this.hideLoader.bind(this, this.onPrintZaKnjigovodstvo)
                                                        )
                                                    )}
                                                />
                                            </Modal.Actions>
                                        </Modal>
                                    </Grid.Column>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <Grid.Column />
                                    <Grid.Column textAlign="right" verticalAlign="middle">
                                        <FilterSortButton
                                            filteringOptions={this.FILTER_ITEMS}
                                            sortingOptions={this.SORT_ITEMS}
                                            activeFilter={this.state.filter}
                                            activeSort={this.state.sort}
                                            callback={this.applyFilterAndSort}
                                        />
                                    </Grid.Column>
                                    <Grid.Column textAlign="right" verticalAlign="middle">
                                        <Dropdown
                                            text={localization.stanjeSkladista.akcijeStanjaSkladista}
                                            icon="caret down"
                                            fluid
                                            floating
                                            labeled
                                            button
                                            direction="left"
                                            className="icon"
                                        >
                                            <Dropdown.Menu>
                                                <Modal
                                                    open={this.state.activeModal && this.state.activeModal.type === modaliEnums.unosProdajnihCijena}
                                                    trigger={
                                                        <Dropdown.Item
                                                            text={localization.stanjeSkladista.unosProdajnihCijena}
                                                            icon="dollar"
                                                            disabled={this.props.stanjeSkladista.findIndex(ss => !ss.isPotvrdenaCijena) <= -1}
                                                            onClick={this.showHideModal.bind(this, true, modaliEnums.unosProdajnihCijena)}
                                                        />
                                                    }
                                                >
                                                    <Header icon="dollar" content={localization.stanjeSkladista.unosProdajnihCijena} />
                                                    <Modal.Content>
                                                        <UnosProdajnihCijena showNabavnaCijena={true} closeModal={this.showHideModal.bind(this, false)} />
                                                    </Modal.Content>
                                                </Modal>
                                                <Dropdown.Divider />
                                                <Modal
                                                    open={
                                                        this.state.activeModal && this.state.activeModal.type === modaliEnums.printStanjaSkladistaNaOdredeniDan
                                                    }
                                                    trigger={
                                                        <Dropdown.Item
                                                            text={localization.stanjeSkladista.printStanjaSkladistaNaOdredeniDan}
                                                            icon="print"
                                                            onClick={this.showHideModal.bind(this, true, modaliEnums.printStanjaSkladistaNaOdredeniDan)}
                                                        />
                                                    }
                                                >
                                                    <Header icon="print" content={localization.common.potvrda} />
                                                    <Modal.Content>
                                                        <span style={styles.modalLabel}>
                                                            {localization.stanjeSkladista.odabirSkladistaZaPrintanjeStanjaSkladista}
                                                        </span>
                                                        <Select
                                                            style={styles.poslovnicaButton}
                                                            options={this.props.mjesta.map(p => {
                                                                return { key: p.id, text: p.naziv, value: p.id };
                                                            })}
                                                            name="mjestoId"
                                                            onChange={this.onChange}
                                                            value={this.state.mjestoId}
                                                            compact
                                                            required
                                                            search
                                                        />
                                                        <br />
                                                        <br />
                                                        <span style={styles.modalLabel}>
                                                            {localization.stanjeSkladista.odabirDatumaZaPrintanjeStanjaSkladista}
                                                        </span>
                                                        <DatePicker
                                                            customInput={
                                                                <Button fluid compact basic size="large" color="black">
                                                                    {this.state.selectedDate.format('DD.MM.YYYY.')}
                                                                </Button>
                                                            }
                                                            dateFormat="DD.MM.YYYY."
                                                            locale="hr-HR"
                                                            maxDate={moment()}
                                                            selected={this.state.selectedDate}
                                                            onChange={this.onChangeSelectedDate}
                                                        />
                                                    </Modal.Content>
                                                    <Modal.Actions>
                                                        <Button
                                                            icon="remove"
                                                            content={localization.common.odustani}
                                                            onClick={this.showHideModal.bind(this, false)}
                                                        />
                                                        <Button
                                                            icon="print"
                                                            color="red"
                                                            content={localization.common.ispis}
                                                            onClick={this.showLoader.bind(
                                                                this,
                                                                this.props.skladisteDataAccess.getStanjeSkladistaZaOdredeniDan.bind(
                                                                    this,
                                                                    this.state.selectedDate.format('YYYY-MM-DD'),
                                                                    this.state.mjestoId,
                                                                    this.hideLoader.bind(this, this.onPrintZaKnjigovodstvo)
                                                                )
                                                            )}
                                                        />
                                                    </Modal.Actions>
                                                </Modal>
                                                <Dropdown.Item
                                                    text={localization.stanjeSkladista.printTrenutnogStanjaSkladistaZaInventuru}
                                                    icon="print"
                                                    onClick={this.showLoader.bind(
                                                        this,
                                                        this.props.skladisteDataAccess.getStanjeSkladistaZaInventuru.bind(
                                                            this,
                                                            this.hideLoader.bind(this, this.onPrintZaInventuru)
                                                        )
                                                    )}
                                                />
                                                <Modal
                                                    open={this.state.activeModal && this.state.activeModal.type === modaliEnums.printZaKnjigovodstvoPotvrda}
                                                    trigger={
                                                        <Dropdown.Item
                                                            text={localization.stanjeSkladista.printTrenutnogStanjaSkladistaZaKnjigovodstvo}
                                                            icon="print"
                                                            onClick={this.showHideModal.bind(this, true, modaliEnums.printZaKnjigovodstvoPotvrda)}
                                                        />
                                                    }
                                                >
                                                    <Header icon="print" content={localization.common.potvrda} />
                                                    <Modal.Content>
                                                        <p>{localization.stanjeSkladista.printanjeZaKnjigovodstvoPitanjePotvrde}</p>
                                                    </Modal.Content>
                                                    <Modal.Actions>
                                                        <Button
                                                            icon="remove"
                                                            content={localization.common.odustani}
                                                            onClick={this.showHideModal.bind(this, false)}
                                                        />
                                                        <Button
                                                            icon="print"
                                                            color="red"
                                                            content={localization.common.ispis}
                                                            onClick={this.showLoader.bind(
                                                                this,
                                                                this.props.skladisteDataAccess.getStanjeSkladistaZaKnjigovodstvo.bind(
                                                                    this,
                                                                    this.hideLoader.bind(this, this.onPrintZaKnjigovodstvo)
                                                                )
                                                            )}
                                                        />
                                                    </Modal.Actions>
                                                </Modal>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Grid.Column>
                                </React.Fragment>
                            )}
                        </Grid.Row>
                        {res.length ? (
                            <React.Fragment>
                                <Grid.Row>
                                    <Grid.Column verticalAlign="middle">
                                        <Table stackable>
                                            {sessionStorage.userRole !== enums.tipKorisnika.DJELATNIK && (
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell colSpan={sessionStorage.userRole === enums.tipKorisnika.VLASNIK ? 6 : 5} />
                                                        <Table.HeaderCell collapsing textAlign="center" colSpan={2}>
                                                            <b>{localization.stanjeSkladista.ukupnaVrijednostSkladista + ':'}</b>
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell collapsing textAlign="right" style={styles.kolicinaIcijena}>
                                                            <b>
                                                                <FormatIznosa value={this.props.ukupnaProdajnaVrijednost} />
                                                            </b>
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell />
                                                    </Table.Row>
                                                </Table.Header>
                                            )}
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell>{localization.artikl.sifra}</Table.HeaderCell>
                                                    <Table.HeaderCell>{localization.artikl.naziv}</Table.HeaderCell>
                                                    {sessionStorage.userRole === enums.tipKorisnika.DJELATNIK && (
                                                        <Table.HeaderCell>{localization.artikl.kategorija}</Table.HeaderCell>
                                                    )}
                                                    <Table.HeaderCell>{localization.artikl.serijskiBroj}</Table.HeaderCell>
                                                    <Table.HeaderCell>{localization.artikl.opis}</Table.HeaderCell>
                                                    {sessionStorage.userRole !== enums.tipKorisnika.DJELATNIK && (
                                                        <Table.HeaderCell>{localization.common.skladiste}</Table.HeaderCell>
                                                    )}
                                                    <Table.HeaderCell textAlign="center">
                                                        {localization.stanjeSkladista.kolicinaSlobodnihKolicinaUkupno}
                                                    </Table.HeaderCell>
                                                    {sessionStorage.userRole === enums.tipKorisnika.VLASNIK && (
                                                        <Table.HeaderCell textAlign="center">
                                                            {localization.stanjeSkladista.jedinicnaCijenaOtkupa}
                                                        </Table.HeaderCell>
                                                    )}
                                                    <Table.HeaderCell textAlign="center">
                                                        {localization.stanjeSkladista.jedinicnaProdajnaCijena}
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell textAlign="center">{localization.common.vrijednost}</Table.HeaderCell>
                                                    <Table.HeaderCell />
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                {res.map((stavka, index) => {
                                                    return (
                                                        <Table.Row key={index}>
                                                            <Table.Cell>{stavka.artikl.sifra}</Table.Cell>
                                                            <Table.Cell>{stavka.artikl.naziv}</Table.Cell>
                                                            {sessionStorage.userRole === enums.tipKorisnika.DJELATNIK && (
                                                                <Table.Cell>
                                                                    {stavka.artikl.kategorija.ime +
                                                                        (stavka.artikl.potkategorija ? ' / ' + stavka.artikl.potkategorija.ime : '')}
                                                                </Table.Cell>
                                                            )}
                                                            <Table.Cell>{stavka.artikl.serijskiBroj || '-'}</Table.Cell>
                                                            <Table.Cell>{stavka.artikl.opis || '-'}</Table.Cell>
                                                            {sessionStorage.userRole !== enums.tipKorisnika.DJELATNIK && (
                                                                <Table.Cell>
                                                                    {stavka.nazivMjesta ? stavka.nazivMjesta : localization.common.uTranzitu}
                                                                </Table.Cell>
                                                            )}
                                                            <Table.Cell collapsing textAlign="center" style={styles.kolicinaIcijena}>
                                                                {stavka.kolicinaSlobodnih + ' / ' + stavka.kolicina}
                                                            </Table.Cell>
                                                            {sessionStorage.userRole === enums.tipKorisnika.VLASNIK && (
                                                                <Table.Cell collapsing textAlign="right" style={styles.kolicinaIcijena}>
                                                                    <FormatIznosa value={stavka.jedNabavnaCijena} suffix={null} />
                                                                </Table.Cell>
                                                            )}
                                                            <Table.Cell collapsing textAlign="right" style={styles.kolicinaIcijena}>
                                                                <FormatIznosa value={stavka.jedProdajnaCijena} suffix={null} />
                                                            </Table.Cell>
                                                            <Table.Cell collapsing textAlign="right" style={styles.kolicinaIcijena}>
                                                                <b>{stavka.prodajnaVrijednost ? <FormatIznosa value={stavka.prodajnaVrijednost} /> : '-'}</b>
                                                            </Table.Cell>
                                                            <Table.Cell collapsing>
                                                                <Button.Group basic size="small">
                                                                    <Modal
                                                                        open={
                                                                            this.state.activeModal &&
                                                                            this.state.activeModal.type === modaliEnums.napomeneModal &&
                                                                            this.state.activeModal.id === index
                                                                        }
                                                                        trigger={
                                                                            <Button
                                                                                icon="comment outline"
                                                                                onClick={this.showHideModal.bind(this, true, modaliEnums.napomeneModal, index)}
                                                                            />
                                                                        }
                                                                    >
                                                                        <Header
                                                                            icon="comment outline"
                                                                            content={localization.stanjeSkladista.napomeneZaArtikl}
                                                                        />
                                                                        <Modal.Content>
                                                                            <Diskusija
                                                                                napomene={stavka.napomene}
                                                                                textAreaValue={
                                                                                    this.state.noveNapomene.find(el => el.id === index)
                                                                                        ? this.state.noveNapomene.find(el => el.id === index).napomena
                                                                                        : ''
                                                                                }
                                                                                onChangeNovaNapomena={this.onChangeNovaNapomena.bind(this, index)}
                                                                                onDodajNapomenu={this.dodajNapomenu.bind(this, stavka.id, index)}
                                                                                closeModal={this.showHideModal.bind(this, false)}
                                                                            />
                                                                        </Modal.Content>
                                                                    </Modal>
                                                                    <Modal
                                                                        open={
                                                                            this.state.activeModal &&
                                                                            this.state.activeModal.type === modaliEnums.slikeModal &&
                                                                            this.state.activeModal.id === index
                                                                        }
                                                                        trigger={
                                                                            <Button
                                                                                icon="image"
                                                                                onClick={this.showHideModal.bind(this, true, modaliEnums.slikeModal, index)}
                                                                            />
                                                                        }
                                                                    >
                                                                        <Header icon="image" content={localization.common.galerijaSlika} />
                                                                        <Modal.Content>
                                                                            <ImageUploadModal
                                                                                imageIds={stavka.imageIds}
                                                                                saveImages={this.props.skladisteDataAccess.dodajSlike.bind(this, stavka.id)}
                                                                                deleteImages={this.props.skladisteDataAccess.brisanjeSlika.bind(
                                                                                    this,
                                                                                    stavka.id
                                                                                )}
                                                                                closeModal={this.showHideModal.bind(this, false)}
                                                                            />
                                                                        </Modal.Content>
                                                                    </Modal>
                                                                    {sessionStorage.userRole !== enums.tipKorisnika.DJELATNIK && (
                                                                        <Modal
                                                                            open={
                                                                                this.state.activeModal &&
                                                                                this.state.activeModal.type === modaliEnums.promjenaProdajnihCijena &&
                                                                                this.state.activeModal.id === index
                                                                            }
                                                                            trigger={
                                                                                <Button
                                                                                    icon="dollar"
                                                                                    onClick={this.showHideModal.bind(
                                                                                        this,
                                                                                        true,
                                                                                        modaliEnums.promjenaProdajnihCijena,
                                                                                        index
                                                                                    )}
                                                                                />
                                                                            }
                                                                        >
                                                                            <Header
                                                                                icon="dollar"
                                                                                content={localization.stanjeSkladista.promjenaProdajnihCijena}
                                                                            />
                                                                            <Modal.Content>
                                                                                <UnosProdajnihCijena
                                                                                    skladisnaStavka={stavka}
                                                                                    showNabavnaCijena={sessionStorage.userRole === enums.tipKorisnika.VLASNIK}
                                                                                    closeModal={this.showHideModal.bind(this, false)}
                                                                                />
                                                                            </Modal.Content>
                                                                        </Modal>
                                                                    )}
                                                                </Button.Group>
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    );
                                                })}
                                            </Table.Body>
                                        </Table>
                                        <div style={styles.paginationContainer}>
                                            <Pagination
                                                activePage={this.state.pageActive}
                                                totalPages={totalPages}
                                                onPageChange={this.onChangePage}
                                                ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
                                                firstItem={{ content: <Icon name="angle double left" />, icon: true }}
                                                lastItem={{ content: <Icon name="angle double right" />, icon: true }}
                                                prevItem={{ content: <Icon name="angle left" />, icon: true }}
                                                nextItem={{ content: <Icon name="angle right" />, icon: true }}
                                            />
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                            </React.Fragment>
                        ) : (
                            <SearchResultsMessage
                                icon={icons.stanjeSkladista}
                                header={
                                    this.state.value !== ''
                                        ? localization.common.unesenaPretragaNemaRezultata
                                        : localization.stanjeSkladista.nemaArtikalaNaSkladistu
                                }
                                content={this.state.value !== '' ? localization.common.pokusajDrugacijuPretragu : undefined}
                            />
                        )}
                    </Grid>
                </Segment>
                <Dimmer active={this.state.loader} page>
                    <Loader />
                </Dimmer>
            </div>
        );
    }
}

StanjeSkladista.propTypes = {
    history: PropTypes.object.isRequired,
    stanjeSkladista: PropTypes.array.isRequired,
    stanjeSkladistaCache: PropTypes.bool.isRequired,
    ukupnaNabavnaVrijednost: PropTypes.number,
    ukupnaProdajnaVrijednost: PropTypes.number,
    mjesta: PropTypes.array.isRequired,
    skladisteDataAccess: PropTypes.object.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {
        stanjeSkladista: state.skladisteReducer.stanjeSkladista.stanja ? state.skladisteReducer.stanjeSkladista.stanja.flatMap(mjesto => mjesto.stavke) : [],
        stanjeSkladistaCache: state.skladisteReducer.cachedStanjeSkladista,
        ukupnaProdajnaVrijednost: state.skladisteReducer.stanjeSkladista.ukupnaProdajnaVrijednost,
        ukupnaNabavnaVrijednost: state.skladisteReducer.stanjeSkladista.ukupnaNabavnaVrijednost,
        mjesta: [...state.mjestaReducer.poslovnice, ...state.mjestaReducer.mjesta] || []
    };
};

let mapDispatchToProps = function(dispatch) {
    return {
        skladisteDataAccess: bindActionCreators(SkladisteDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StanjeSkladista);
