import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Dimmer, Grid, Header, Input, Loader, Modal, Segment } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

import localization from '../../locales/localization';
import endpoints from '../../environments/endpoints';
import enums from '../../utils/Enums';
import icons from '../../utils/DefinedIcons';
import { storeDocumentAndGenerateKey } from '../../utils/PrintingDocumentsHandler';
import * as ArtikliApi from '../../data_access/ArtikliApi';
import * as OtkupiDataAccess from '../../data_access/OtkupiDataAccess';
import PredlaganjeProdajnihCijenaModal from '../common_components/PredlaganjeProdajnihCijenaModal';
import { bindActionCreators } from 'redux';
import OdabirKlijenta from '../common_components/OdabirKlijenta';
import OdabirArtiklaPaginated from '../common_components/OdabirArtiklaPaginated';
import Napomene from '../common_components/Napomene';

const styles = {
    confirmButton: {
        textAlign: 'center',
        marginBottom: 16
    }
};

class NovaPrimka extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            client: undefined,
            items: [],
            brojKupovnogRacuna: '',
            datumIVrijemeKupovnogRacuna: moment(),
            sluzbenaNapomena: '',
            nesluzbenaNapomena: '',
            napraviPrimkuEnabled: false,
            potvrdaPrimke: false,
            prijedlogProdajnihCijena: false,
            loader: false,
            primka: undefined
        };
        this.handleOdabirKlijenta = this.handleOdabirKlijenta.bind(this);
        this.handleOdabirArtikla = this.handleOdabirArtikla.bind(this);
        this.enableNapraviPrimkuButton = this.enableNapraviPrimkuButton.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeDatumIVrijeme = this.onChangeDatumIVrijeme.bind(this);
        this.checkKomponentePrimke = this.checkKomponentePrimke.bind(this);
        this.showHidePotvrdaPrimke = this.showHidePotvrdaPrimke.bind(this);
        this.showLoader = this.showLoader.bind(this);
        this.hideLoader = this.hideLoader.bind(this);
        this.napraviPrimku = this.napraviPrimku.bind(this);
        this.openPrimka = this.openPrimka.bind(this);
        this.redirect = this.redirect.bind(this);
        this.fetchPredmeti = this.fetchPredmeti.bind(this);
    }

    handleOdabirKlijenta(clientSelected) {
        this.setState({ client: clientSelected }, this.enableNapraviPrimkuButton);
    }

    handleOdabirArtikla(itemsSelected) {
        this.setState({ items: [...itemsSelected] }, this.enableNapraviPrimkuButton);
    }

    onChange(e, { name, value }) {
        this.setState({ [name]: value }, this.enableNapraviPrimkuButton);
    }

    onChangeDatumIVrijeme(dateTime) {
        this.setState(
            {
                datumIVrijemeKupovnogRacuna: dateTime
            },
            this.enableNapraviPrimkuButton
        );
    }

    enableNapraviPrimkuButton() {
        this.setState({
            napraviPrimkuEnabled: this.checkKomponentePrimke()
        });
    }

    checkKomponentePrimke() {
        if (!this.state.client) {
            return false;
        }
        if (!this.state.items.length) {
            return false;
        } else {
            for (let i = 0; i < this.state.items.length; i++) {
                if (!this.state.items[i].kolicina || !this.state.items[i].cijena) {
                    return false;
                }
            }
        }
        if (!this.state.brojKupovnogRacuna) {
            return false;
        }
        if (!this.state.datumIVrijemeKupovnogRacuna) {
            return false;
        }
        return true;
    }

    showHidePotvrdaPrimke(value, callback) {
        if (typeof callback === 'function') {
            this.setState({ potvrdaPrimke: value }, callback);
        } else {
            this.setState({ potvrdaPrimke: value });
        }
    }

    showLoader(callback) {
        this.setState(
            {
                loader: true
            },
            callback
        );
    }

    hideLoader(callback, data) {
        this.setState(
            {
                loader: false
            },
            callback.bind(this, data)
        );
    }

    napraviPrimku() {
        let primka = {
            tip: enums.tipSkladisnogUlaznogBloka.PRIMKA,
            pravnaOsobaId: this.state.client.id,
            poslovnicaId: JSON.parse(sessionStorage.poslovnicaId),
            brojKupovnogRacuna: this.state.brojKupovnogRacuna,
            datumIVrijemeKupovnogRacuna: this.state.datumIVrijemeKupovnogRacuna.format('YYYY-MM-DDTHH:mm') + ':00',
            sluzbenaNapomena: this.state.sluzbenaNapomena || null,
            nesluzbenaNapomena: this.state.nesluzbenaNapomena || null,
            ulazi: this.state.items.map(artikl => {
                return {
                    predmetId: artikl.id,
                    kolicina: parseFloat(artikl.kolicina),
                    jedNabavnaCijena: parseFloat(artikl.cijena),
                    isNoviPredmet: artikl.isNoviPredmet,
                    napomena: artikl.napomena || null
                };
            })
        };
        this.props.otkupiDataAccess.createPrimka(primka, this.hideLoader.bind(this, this.openPrimka));
    }

    openPrimka(primka) {
        window.open(endpoints.appEndpoints.print.primka + storeDocumentAndGenerateKey(primka), '_blank');
        this.setState({
            prijedlogProdajnihCijena: true,
            primka: primka
        });
    }

    redirect() {
        this.props.history.goBack();
    }

    fetchPredmeti(q, page, pageSize, onFetchSuccess, onFetchError) {
        ArtikliApi.getArtikliPaginated(
            q, page, pageSize, ArtikliApi.SORT.naziv, ArtikliApi.TIP_ARTIKLA.predmet,
            onFetchSuccess, onFetchError
        )
    }

    render() {
        return (
            <div>
                <Segment tertiary>
                    <Header as="h2">{localization.otkup.novaPrimka}</Header>
                </Segment>
                <Segment tertiary>
                    <OdabirKlijenta
                        onClientSelected={this.handleOdabirKlijenta}
                        allowedClientType={enums.tipKlijenta.PRAVNA_OSOBA}
                    />
                </Segment>
                <Segment tertiary>
                    <OdabirArtiklaPaginated fetchArtikli={this.fetchPredmeti} onItemSelected={this.handleOdabirArtikla} type={enums.tipTabliceArtikala.PRIMKA_EDITABLE} />
                </Segment>
                <Segment tertiary>
                    <Grid stackable>
                        <Grid.Row columns={3} divided>
                            <Grid.Column verticalAlign="middle">
                                <Header as="h3">{localization.otkup.brojKupovnogRacuna}</Header>
                                <Input
                                    placeholder={localization.otkup.brojKupovnogRacuna}
                                    value={this.state.brojKupovnogRacuna}
                                    name="brojKupovnogRacuna"
                                    onChange={this.onChange}
                                />
                            </Grid.Column>
                            <Grid.Column verticalAlign="middle">
                                <Header as="h3">{localization.otkup.datumIVrijemeKupovnogRacuna}</Header>
                                <DatePicker
                                    customInput={
                                        <Button fluid compact basic color="black">
                                            {this.state.datumIVrijemeKupovnogRacuna.format('DD.MM.YYYY.  HH:mm')}
                                        </Button>
                                    }
                                    dateFormat="DD.MM.YYYY. HH:mm"
                                    timeFormat="HH:mm"
                                    locale="hr-HR"
                                    timeCaption={localization.common.vrijeme}
                                    timeIntervals={1}
                                    showTimeSelect
                                    selected={this.state.datumIVrijemeKupovnogRacuna}
                                    onChange={this.onChangeDatumIVrijeme}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Napomene
                    sluzbenaNapomenaHeader={localization.otkup.sluzbenaNapomenaPrimke}
                    nesluzbenaNapomenaHeader={localization.otkup.nesluzbenaNapomenaPrimke}
                    onChangeNapomena={this.onChange}
                />
                <div style={styles.confirmButton}>
                    <Modal
                        open={this.state.potvrdaPrimke}
                        closeOnDimmerClick={false}
                        trigger={
                            <Button
                                color="green"
                                content={localization.otkup.napraviPrimku}
                                disabled={!this.state.napraviPrimkuEnabled}
                                onClick={this.showHidePotvrdaPrimke.bind(this, true)}
                            />
                        }
                    >
                        {!this.state.prijedlogProdajnihCijena ? (
                            <React.Fragment>
                                <Header icon={icons.primka} content={localization.otkup.potvrdaPrimke} />
                                <Modal.Content>
                                    <p>{localization.otkup.pitanjePotvrdePrimke}</p>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button icon="remove" content={localization.common.odustani} onClick={this.showHidePotvrdaPrimke.bind(this, false)} />
                                    <Button
                                        icon="checkmark"
                                        color="green"
                                        content={localization.otkup.napraviPrimku}
                                        onClick={this.showLoader.bind(this, this.napraviPrimku)}
                                    />
                                </Modal.Actions>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Header icon={icons.primka} content={localization.otkup.predlaganjeProdajnihCijena} />
                                <Modal.Content>
                                    <PredlaganjeProdajnihCijenaModal
                                        ulazi={this.state.primka.skladisniUlazi}
                                        closeModal={this.showHidePotvrdaPrimke.bind(this, false, this.redirect)}
                                    />
                                </Modal.Content>
                            </React.Fragment>
                        )}
                    </Modal>
                </div>
                <Dimmer active={this.state.loader} page>
                    <Loader />
                </Dimmer>
            </div>
        );
    }
}

NovaPrimka.propTypes = {
    history: PropTypes.object.isRequired,
    stanjeBlagajni: PropTypes.object.isRequired,
    otkupiDataAccess: PropTypes.object.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {
        stanjeBlagajni: state.blagajneReducer.stanje
    };
};

let mapDispatchToProps = function(dispatch) {
    return {
        otkupiDataAccess: bindActionCreators(OtkupiDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NovaPrimka);
