import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Checkbox, Dimmer, Grid, Header, Icon, Input, Loader, Pagination, Modal, Search, Segment, Table } from 'semantic-ui-react';
import localization from '../../locales/localization';
import endpoints from '../../environments/endpoints';
import icons from '../../utils/DefinedIcons';
import SearchResultsMessage from '../common_components/SearchResultsMessage';
import * as SkladisteDataAccess from '../../data_access/SkladisteDataAccess';
import { storeDocumentAndGenerateKey } from '../../utils/PrintingDocumentsHandler';

const styles = {
    paginationContainer: {
        textAlign: 'center'
    },
    labelNaziva: {
        marginRight: 8
    }
};

const ITEMS_PER_PAGE = 10;

class CijeneOglasavanje extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stavke: this.props.stavke,
            isSearchLoading: false,
            results: [],
            value: '',
            activeModal: undefined,
            naziv: '',
            noveNapomene: [],
            loader: !this.props.stanjeSkladistaCache,
            pageActive: 1
        };
        this.onChangePage = this.onChangePage.bind(this);
        this.resetSearchComponent = this.resetSearchComponent.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.onChangeNaziv = this.onChangeNaziv.bind(this);
        this.onChangeFlag = this.onChangeFlag.bind(this);
        this.onFlagChanged = this.onFlagChanged.bind(this);
        this.onPrintCeduljica = this.onPrintCeduljica.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.showLoader = this.showLoader.bind(this);
    }

    componentWillMount() {
        this.resetSearchComponent();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.stanjeSkladistaCache) {
            this.setState({
                stavke: nextProps.stavke,
                loader: !nextProps.stanjeSkladistaCache
            });
        }
    }

    onChangePage(e, { activePage }) {
        this.setState({
            pageActive: activePage
        });
    }

    resetSearchComponent() {
        this.setState({
            isSearchLoading: false,
            results: [],
            value: ''
        });
    }

    handleSearchChange(e, { value }) {
        this.setState({
            isSearchLoading: true,
            value: value,
            pageActive: 1
        });

        setTimeout(() => {
            if (this.state.value.length < 1) {
                return this.resetSearchComponent();
            }

            const re = new RegExp(_.escapeRegExp(this.state.value), 'i');
            const isMatch = result => {
                let testingArray = [result.artikl.sifra, result.artikl.naziv, result.artikl.serijskiBroj, result.artikl.kategorija.ime];
                if (result.artikl.potkategorija) {
                    testingArray.push(result.artikl.potkategorija.ime);
                }
                for (let i = 0; i < testingArray.length; i++) {
                    if (re.test(testingArray[i])) {
                        return true;
                    }
                }
                return false;
            };

            this.setState({
                isSearchLoading: false,
                results: _.filter(this.state.stavke, isMatch)
            });
        }, 500);
    }

    onChangeNaziv(e, { value }) {
        this.setState({ naziv: value });
    }

    onChangeFlag(skladisnaStavkaId, flagId, checked) {
        this.props.skladisteDataAccess.updateSkladisnaStavkaFlag(
            skladisnaStavkaId,
            flagId,
            checked,
            this.onFlagChanged.bind(this, skladisnaStavkaId, flagId, checked)
        );
    }

    onFlagChanged(skladisnaStavkaId, flagId, checked) {
        let setFlags = listaArtikala => {
            return listaArtikala.map(ss => {
                if (skladisnaStavkaId === ss.id) {
                    return Object.assign({}, ss, {
                        flags: ss.flags.map(f => {
                            if (flagId === f.id) {
                                return Object.assign({}, f, { isSet: checked });
                            }
                            return f;
                        })
                    });
                }
                return ss;
            });
        };
        this.setState({
            stavke: setFlags(this.state.stavke),
            results: setFlags(this.state.results),
            loader: false
        });
    }

    onPrintCeduljica(artikl) {
        let printArtikl;
        if (artikl.nazivZaPrint === this.state.naziv) {
            printArtikl = Object.assign({}, artikl, {
                postotakNaKartice: this.props.appPrefs.postotakZaKarticnoPlacanje
            });
        } else {
            printArtikl = Object.assign({}, artikl, {
                nazivZaPrint: this.state.naziv,
                postotakNaKartice: this.props.appPrefs.postotakZaKarticnoPlacanje
            });
            this.props.skladisteDataAccess.promijenaNazivaZaPrintanje(artikl.id, this.state.naziv);
        }
        window.open(endpoints.appEndpoints.print.ceduljicascijenom + storeDocumentAndGenerateKey(printArtikl), '_blank');
        this.hideModal();
    }

    showModal(id, nazivZaPrint) {
        this.setState({
            naziv: nazivZaPrint,
            activeModal: id
        });
    }

    hideModal() {
        this.setState({
            naziv: '',
            activeModal: undefined
        });
    }

    showLoader(callback, skladisnaStavkaId, flagId, e, { checked }) {
        this.setState(
            {
                loader: true
            },
            callback.bind(this, skladisnaStavkaId, flagId, checked)
        );
    }

    render() {
        let res, totalPages;
        let startItem = (this.state.pageActive - 1) * ITEMS_PER_PAGE;
        let endItem = this.state.pageActive * ITEMS_PER_PAGE;
        if (this.state.value !== '' && !this.state.isSearchLoading) {
            res = this.state.results.slice(startItem, endItem);
            totalPages = Math.ceil(this.state.results.length / ITEMS_PER_PAGE);
        } else {
            res = this.state.stavke.slice(startItem, endItem);
            totalPages = Math.ceil(this.state.stavke.length / ITEMS_PER_PAGE);
        }
        return (
            <div>
                <Segment tertiary>
                    <Grid stackable>
                        <Grid.Row columns={5}>
                            <Grid.Column verticalAlign="middle">
                                <Header as="h2">{localization.cijeneOglasavanje.artikliNaSkladistu}</Header>
                            </Grid.Column>
                            <Grid.Column verticalAlign="middle">
                                <Search
                                    input={{ fluid: true }}
                                    loading={this.state.isSearchLoading}
                                    onSearchChange={this.handleSearchChange}
                                    value={this.state.value}
                                    open={false}
                                />
                            </Grid.Column>
                            <Grid.Column />
                            <Grid.Column />
                            <Grid.Column />
                        </Grid.Row>
                        {res.length ? (
                            <React.Fragment>
                                <Grid.Row>
                                    <Grid.Column verticalAlign="middle">
                                        <Table stackable>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell>{localization.artikl.sifra}</Table.HeaderCell>
                                                    <Table.HeaderCell>{localization.artikl.naziv}</Table.HeaderCell>
                                                    <Table.HeaderCell>{localization.artikl.kategorija}</Table.HeaderCell>
                                                    <Table.HeaderCell>{localization.artikl.serijskiBroj}</Table.HeaderCell>
                                                    {res[0].flags.map(flag => {
                                                        return (
                                                            <Table.HeaderCell key={flag.id} textAlign="center">
                                                                {flag.naziv}
                                                            </Table.HeaderCell>
                                                        );
                                                    })}
                                                    <Table.HeaderCell />
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                {res.map((s, index) => {
                                                    return (
                                                        <Table.Row key={index}>
                                                            <Table.Cell>{s.artikl.sifra}</Table.Cell>
                                                            <Table.Cell>{s.artikl.naziv}</Table.Cell>
                                                            <Table.Cell>
                                                                {s.artikl.kategorija.ime + (s.artikl.potkategorija ? ' / ' + s.artikl.potkategorija.ime : '')}
                                                            </Table.Cell>
                                                            <Table.Cell>{s.artikl.serijskiBroj || '-'}</Table.Cell>
                                                            {s.flags.map(flag => {
                                                                return (
                                                                    <Table.Cell key={flag.id} collapsing textAlign="center">
                                                                        <Checkbox
                                                                            checked={flag.isSet}
                                                                            onChange={this.showLoader.bind(this, this.onChangeFlag, s.id, flag.id)}
                                                                        />
                                                                    </Table.Cell>
                                                                );
                                                            })}
                                                            <Table.Cell collapsing>
                                                                <Modal
                                                                    open={this.state.activeModal === s.id}
                                                                    size="mini"
                                                                    trigger={
                                                                        <Button
                                                                            icon="print"
                                                                            basic
                                                                            size="small"
                                                                            onClick={this.showModal.bind(this, s.id, s.artikl.nazivZaPrint)}
                                                                        />
                                                                    }
                                                                    closeOnDimmerClick={false}
                                                                >
                                                                    <Modal.Header>
                                                                        {localization.printingComponents.ceduljicaSCijenom.potvrdaNazivaArtikla}
                                                                    </Modal.Header>
                                                                    <Modal.Content>
                                                                        <span style={styles.labelNaziva}>
                                                                            {localization.printingComponents.ceduljicaSCijenom.nazivArtikla + ':'}
                                                                        </span>
                                                                        <Input
                                                                            placeholder={localization.printingComponents.ceduljicaSCijenom.nazivArtikla}
                                                                            value={this.state.naziv}
                                                                            onChange={this.onChangeNaziv}
                                                                        />
                                                                    </Modal.Content>
                                                                    <Modal.Actions>
                                                                        <Button icon="remove" content={localization.common.odustani} onClick={this.hideModal} />
                                                                        <Button
                                                                            icon="checkmark"
                                                                            color="green"
                                                                            content={
                                                                                s.artikl.nazivZaPrint === this.state.naziv
                                                                                    ? localization.printingComponents.ceduljicaSCijenom.potvrdiNaziv
                                                                                    : localization.printingComponents.ceduljicaSCijenom.promijeniNaziv
                                                                            }
                                                                            onClick={this.onPrintCeduljica.bind(this, s)}
                                                                        />
                                                                    </Modal.Actions>
                                                                </Modal>
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    );
                                                })}
                                            </Table.Body>
                                        </Table>
                                        <div style={styles.paginationContainer}>
                                            <Pagination
                                                activePage={this.state.pageActive}
                                                totalPages={totalPages}
                                                onPageChange={this.onChangePage}
                                                ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
                                                firstItem={{ content: <Icon name="angle double left" />, icon: true }}
                                                lastItem={{ content: <Icon name="angle double right" />, icon: true }}
                                                prevItem={{ content: <Icon name="angle left" />, icon: true }}
                                                nextItem={{ content: <Icon name="angle right" />, icon: true }}
                                            />
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                            </React.Fragment>
                        ) : (
                            <SearchResultsMessage
                                icon={icons.cijeneOglasavanje}
                                header={
                                    this.state.value !== ''
                                        ? localization.common.unesenaPretragaNemaRezultata
                                        : localization.stanjeSkladista.nemaArtikalaNaSkladistu
                                }
                                content={this.state.value !== '' ? localization.common.pokusajDrugacijuPretragu : undefined}
                            />
                        )}
                    </Grid>
                </Segment>
                <Dimmer active={this.state.loader} page>
                    <Loader />
                </Dimmer>
            </div>
        );
    }
}

CijeneOglasavanje.propTypes = {
    history: PropTypes.object.isRequired,
    stavke: PropTypes.array.isRequired,
    stanjeSkladistaCache: PropTypes.bool.isRequired,
    appPrefs: PropTypes.object.isRequired,
    skladisteDataAccess: PropTypes.object.isRequired
};

let mapStateToProps = function(state, ownProps) {
    let stavke = [];
    if (state.skladisteReducer.cachedStanjeSkladista) {
        stavke = state.skladisteReducer.stanjeSkladista.stanja
            .flatMap(mjesto => mjesto.stavke)
            .filter(ss => {
                return ss.kolicinaSlobodnih > 0;
            });
        stavke = stavke.map(ss => {
            let counter = 0;
            ss.flags.forEach(f => {
                if (f.isSet) {
                    counter++;
                }
            });
            return Object.assign({}, ss, {
                flagValues: counter
            });
        });
        stavke.sort((a, b) => a.flagValues - b.flagValues);
    }
    return {
        stavke: stavke,
        stanjeSkladistaCache: state.skladisteReducer.cachedStanjeSkladista,
        appPrefs: state.appPreferencesReducer.appPrefs
    };
};

let mapDispatchToProps = function(dispatch) {
    return {
        skladisteDataAccess: bindActionCreators(SkladisteDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CijeneOglasavanje);
