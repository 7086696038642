import $ from 'jquery';

import * as success from '../actions/PorezniZakoniActions';

import endpoint from '../environments/endpoints';
import { showAlert } from '../utils/ErrorHandler';

export function getPorezniZakoni(onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'porezni-zakoni'
        })
            .done(data => {
                dispatch(success.getPorezniZakoni(data));
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function createOrUpdatePorezniZakoni(porezniZakoni, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'porezni-zakoni',
            data: JSON.stringify(porezniZakoni)
        })
            .done(data => {
                dispatch(getPorezniZakoni());
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function deletePorezniZakon(id, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'porezni-zakoni/' + id
        })
            .done(data => {
                dispatch(getPorezniZakoni());
            })
            .fail(error => {
                showAlert(error);
            });
    };
}
