let calculateUkupnoRacun = racun => {
    let ukupno = 0;
    if (racun.iznosGotovina) {
        ukupno += racun.iznosGotovina;
    }
    if (racun.karticnoPlacanje) {
        ukupno += racun.karticnoPlacanje.iznosKarticno;
    }
    return ukupno;
};

let calculateUkupno = list => {
    let ukupno = 0;
    list.forEach(el => {
        ukupno += el.kolicina * el.jedNabavnaCijena;
    });
    return ukupno;
};

export default {
    'PREDRACUN.TO_RACUN': radnja => {
        return calculateUkupnoRacun(radnja.racun);
    },
    'RACUN.CREATE': radnja => {
        return calculateUkupnoRacun(radnja.racun);
    },
    'RACUN.STORNO': radnja => {
        return calculateUkupnoRacun(radnja.racun);
    },
    'REZERVACIJA.CREATE': radnja => {
        return calculateUkupno(radnja.zahtjevZaRezervaciju.rezervacije);
    },
    'REZERVACIJA.PRODUZENJE': radnja => {
        let ukupnoOtkup = calculateUkupno(radnja.otkupniBlok.skladisniUlazi);
        let ukupnoRacun = calculateUkupnoRacun(radnja.racun);
        return ukupnoRacun - ukupnoOtkup;
    },
    'REZERVACIJA.PODIZANJE': radnja => {
        return calculateUkupnoRacun(radnja.racun);
    },
    'SUB.CREATE': radnja => {
        return calculateUkupno(radnja.sklUlazniBlok.skladisniUlazi);
    },
    'SUB.STORNO': radnja => {
        return calculateUkupno(radnja.sklUlazniBlok.skladisniUlazi);
    },
    UPLATA: radnja => {
        return Math.abs(radnja.uplatnica.iznos);
    },
    PRETAKANJE: radnja => {
        return radnja.pretok.iznos;
    }
};
