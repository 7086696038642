import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import localization from '../../locales/localization';
import { Button, Dimmer, Loader, Table } from 'semantic-ui-react';
import * as ProcjenaDataAccess from '../../data_access/ProcjenaDataAccess';
import { bindActionCreators } from 'redux';
import FormatIznosa from '../common_components/FormatIznosa';

const styles = {
    container: {
        textAlign: 'center'
    },
    kolicinaIcijena: {
        fontFamily: 'monospace'
    },
    actionButtons: {
        marginTop: 16,
        display: 'flex',
        justifyContent: 'flex-end'
    }
};

class ArhiviranjeProcjeneModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false
        };
        this.showLoader = this.showLoader.bind(this);
        this.hideLoader = this.hideLoader.bind(this);
        this.onArhivirajProcjene = this.onArhivirajProcjene.bind(this);
    }

    showLoader(callback) {
        this.setState(
            {
                loader: true
            },
            callback
        );
    }

    hideLoader(callback) {
        this.setState(
            {
                loader: false
            },
            callback
        );
    }

    onArhivirajProcjene() {
        this.props.procjenaDataAccess.arhivirajProcjenu(
            this.props.zahtjevZaProcjenu.id,
            this.props.zahtjevZaProcjenu.procjene.map(p => p.id),
            () => {
                this.hideLoader(this.props.closeModal);
                this.props.onArhiviranjeSuccess && this.props.onArhiviranjeSuccess();
            },
            () => {
                this.hideLoader();
                this.props.onArhiviranjeError && this.props.onArhiviranjeError();
            }
        );
    }

    render() {
        return (
            <div style={styles.container}>
                <Table celled structured stackable selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>{localization.artikl.sifra}</Table.HeaderCell>
                            <Table.HeaderCell>{localization.artikl.naziv}</Table.HeaderCell>
                            <Table.HeaderCell>{localization.artikl.serijskiBroj}</Table.HeaderCell>
                            <Table.HeaderCell>{localization.artikl.kategorija}</Table.HeaderCell>
                            <Table.HeaderCell>{localization.common.kolicina}</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">{localization.procjena.ocekivanaJedinicnaVrijednost}</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center" collapsing>
                                {localization.common.vrijednost}
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.props.zahtjevZaProcjenu.procjene.map(procjena => {
                            return (
                                <Table.Row key={procjena.id}>
                                    <Table.Cell>{procjena.predmet.sifra}</Table.Cell>
                                    <Table.Cell>{procjena.predmet.naziv}</Table.Cell>
                                    <Table.Cell>{procjena.predmet.serijskiBroj || '-'}</Table.Cell>
                                    <Table.Cell>
                                        {procjena.predmet.kategorija.ime + (procjena.predmet.potkategorija ? ' / ' + procjena.predmet.potkategorija.ime : '')}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">{procjena.kolicina}</Table.Cell>
                                    <Table.Cell textAlign="right" collapsing style={styles.kolicinaIcijena}>
                                        <FormatIznosa value={procjena.ocekivanaJedCijena} suffix={null} />
                                    </Table.Cell>
                                    <Table.Cell textAlign="right" collapsing style={styles.kolicinaIcijena}>
                                        <FormatIznosa value={procjena.kolicina * procjena.ocekivanaJedCijena} />
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
                <div style={styles.actionButtons}>
                    <Button icon="close" content={localization.common.odustani} onClick={this.props.closeModal} />
                    <Button
                        icon="archive"
                        color="red"
                        content={localization.procjene.arhiviraj}
                        onClick={this.showLoader.bind(this, this.onArhivirajProcjene)}
                    />
                </div>
                <Dimmer active={this.state.loader} page>
                    <Loader />
                </Dimmer>
            </div>
        );
    }
}

ArhiviranjeProcjeneModal.propTypes = {
    zahtjevZaProcjenu: PropTypes.object.isRequired,
    procjenaDataAccess: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
    onArhiviranjeSuccess: PropTypes.func,
    onArhiviranjeError: PropTypes.func
};

let mapStateToProps = function(state, ownProps) {
    return {};
};

let mapDispatchToProps = function(dispatch) {
    return {
        procjenaDataAccess: bindActionCreators(ProcjenaDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArhiviranjeProcjeneModal);
