import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Button, Grid, Header, Search, Segment } from 'semantic-ui-react';
import localization from '../../locales/localization';
import { bindActionCreators } from 'redux';
import Artikl from './Artikl';
import * as ArtikliDataAccess from '../../data_access/ArtikliDataAccess';
import { renderItemsResults } from '../../utils/SearchResultsRenderers';
import { generateKey } from '../../utils/KeyGenerator';
import TablicaArtikala from './table_fragments/TablicaArtikala';
import SearchResultsMessage from './SearchResultsMessage';

class OdabirArtikla extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isItemSearchLoading: false,
            itemsResults: [],
            itemValue: '',
            itemsSelected: [],
            newItems: [],
            editItem: undefined
        };
        this.resetItemsSearchComponent = this.resetItemsSearchComponent.bind(this);
        this.handleItemsSearchResultSelect = this.handleItemsSearchResultSelect.bind(this);
        this.handleItemsSearchChange = this.handleItemsSearchChange.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onCreateNewItem = this.onCreateNewItem.bind(this);
        this.onNewItemSave = this.onNewItemSave.bind(this);
        this.handleNewItemResponse = this.handleNewItemResponse.bind(this);
        this.handleSaveAsNewItemResponse = this.handleSaveAsNewItemResponse.bind(this);
        this.onNewItemCancel = this.onNewItemCancel.bind(this);
        this.showHideEditItem = this.showHideEditItem.bind(this);
        this.onRemoveItem = this.onRemoveItem.bind(this);
        this.runCallback = this.runCallback.bind(this);
    }

    componentWillMount() {
        this.resetItemsSearchComponent();
    }

    componentWillReceiveProps(nextProps) {
        let itemsSelected = [];
        if (!nextProps.resetArtikli) {
            itemsSelected = this.state.itemsSelected.map(item => {
                let artikl = nextProps.artikli.find(a => a.id === item.id);
                if (artikl && typeof item.cijena !== 'string') {
                    item.cijena = artikl.cijena;
                }
                return item;
            });
        }
        this.setState({
            itemsSelected: itemsSelected,
            itemValue: nextProps.resetArtikli ? '' : this.state.itemValue,
            itemsResults: nextProps.resetArtikli ? [] : this.state.itemsResults
        });
    }

    resetItemsSearchComponent() {
        this.setState({
            isItemSearchLoading: false,
            itemsResults: [],
            itemValue: ''
        });
    }

    handleItemsSearchResultSelect(e, { result }) {
        if (!this.props.maxNumOfArtikli) {
            let comparator = (first, second) => first.id === second.id && first.sifra === second.sifra;
            this.setState(
                {
                    itemsSelected: _.unionWith(this.state.itemsSelected, [Object.assign({}, result.result)], comparator),
                    itemValue: '',
                    editItem: result.result.serijskiBroj !== null ? result.result.sifra : undefined
                },
                this.runCallback
            );
        } else {
            this.setState(
                {
                    itemsSelected: [Object.assign({}, result.result)],
                    itemValue: '',
                    editItem: result.result.serijskiBroj !== null ? result.result.sifra : undefined
                },
                this.runCallback
            );
        }
    }

    handleItemsSearchChange(e, { value }) {
        this.setState({
            isItemSearchLoading: true,
            itemValue: value
        });

        setTimeout(() => {
            if (this.state.itemValue.length < 1) {
                return this.resetItemsSearchComponent();
            }

            const re = new RegExp(_.escapeRegExp(this.state.itemValue), 'i');
            const isMatch = result => re.test(result.sifra) || re.test(result.naziv) || re.test(result.serijskiBroj);

            this.setState({
                isItemSearchLoading: false,
                itemsResults: _.filter(this.props.artikli, isMatch).map(r => {
                    return { key: r.id, result: r };
                })
            });
        }, 500);
    }

    onChange(sifra, e, { name, value, checked }) {
        if (value === undefined) {
            value = checked;
        }
        this.setState(
            {
                itemsSelected: this.state.itemsSelected.map(item => {
                    if (item.sifra === sifra) {
                        item[name] = value;
                    }
                    return Object.assign({}, item);
                })
            },
            this.runCallback
        );
    }

    onCreateNewItem() {
        this.setState({ newItems: [...this.state.newItems, { key: generateKey() }] });
    }

    onNewItemSave(callback, predmet) {
        this.props.artikliDataAccess.createPredmet(predmet, callback);
    }

    handleNewItemResponse(key, item) {
        this.setState(
            {
                itemsSelected: _.unionWith(this.state.itemsSelected, [item], _.isEqual),
                newItems: _.xor(this.state.newItems, [key])
            },
            this.runCallback
        );
    }

    handleSaveAsNewItemResponse(item) {
        this.setState(
            {
                itemsSelected: _.unionWith(
                    this.state.itemsSelected.filter(i => i.sifra !== this.state.editItem),
                    [item],
                    _.isEqual
                ),
                editItem: undefined
            },
            this.runCallback
        );
    }

    onNewItemCancel(key) {
        this.setState({ newItems: _.xor(this.state.newItems, [key]) });
    }

    showHideEditItem(value) {
        this.setState({ editItem: value });
    }

    onRemoveItem(item) {
        this.setState(
            {
                itemsSelected: _.xor(this.state.itemsSelected, [item])
            },
            this.runCallback
        );
    }

    runCallback() {
        this.props.onItemSelected(this.state.itemsSelected);
    }

    render() {
        return (
            <Grid stackable>
                <Grid.Row columns={6}>
                    <Grid.Column verticalAlign="middle">
                        <Header as="h3">{this.props.title || localization.common.artikli}</Header>
                    </Grid.Column>
                    {this.props.maxNumOfArtikli && this.state.itemsSelected.length === this.props.maxNumOfArtikli ? null : (
                        <React.Fragment>
                            <Grid.Column verticalAlign="middle">
                                <Search
                                    input={{ fluid: true }}
                                    loading={this.state.isItemSearchLoading}
                                    onResultSelect={this.handleItemsSearchResultSelect}
                                    onSearchChange={this.handleItemsSearchChange}
                                    results={this.state.itemsResults}
                                    resultRenderer={renderItemsResults}
                                    value={this.state.itemValue}
                                />
                            </Grid.Column>
                            {!this.props.newItemDisabled ? (
                                <Grid.Column verticalAlign="middle">
                                    <Button fluid compact basic color="black" onClick={this.onCreateNewItem}>
                                        {localization.common.unosNovogArtikla}
                                    </Button>
                                </Grid.Column>
                            ) : null}
                        </React.Fragment>
                    )}
                </Grid.Row>
                {this.state.itemsSelected.length ? (
                    <Grid.Row>
                        <Grid.Column verticalAlign="middle">
                            <Segment>
                                <TablicaArtikala
                                    type={this.props.type}
                                    artikli={this.state.itemsSelected}
                                    showActions={true}
                                    showFooter={this.props.showFooter !== undefined ? this.props.showFooter : true}
                                    editingItem={this.state.editItem}
                                    onChange={this.onChange}
                                    showHideEditItem={this.showHideEditItem}
                                    onSaveAsNewItem={this.onNewItemSave.bind(this, this.handleSaveAsNewItemResponse)}
                                    onRemoveItem={this.onRemoveItem}
                                />
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                ) : null}
                {this.state.newItems.map(item => {
                    return (
                        <Grid.Row key={item.key}>
                            <Grid.Column verticalAlign="middle">
                                <Segment>
                                    <Artikl
                                        item={{}}
                                        onSave={this.onNewItemSave.bind(this, this.handleNewItemResponse.bind(this, item))}
                                        onCancel={this.onNewItemCancel.bind(this, item)}
                                    />
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    );
                })}
                {!this.state.itemsSelected.length && !this.state.newItems.length ? (
                    <SearchResultsMessage
                        icon="in cart"
                        header={localization.common.placeholderUnosArtiklaTitle}
                        content={localization.common.placeholderUnosArtiklaContent}
                    />
                ) : null}
            </Grid>
        );
    }
}

OdabirArtikla.propTypes = {
    artikli: PropTypes.array.isRequired,
    artikliDataAccess: PropTypes.object.isRequired,
    maxNumOfArtikli: PropTypes.number,
    newItemDisabled: PropTypes.bool,
    onItemSelected: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    title: PropTypes.string,
    showFooter: PropTypes.bool,
    resetArtikli: PropTypes.bool
};

let mapStateToProps = function(state, ownProps) {
    return {};
};

let mapDispatchToProps = function(dispatch) {
    return {
        artikliDataAccess: bindActionCreators(ArtikliDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OdabirArtikla);
