import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, Header, Segment } from 'semantic-ui-react';

import Kategorija from '../common_components/Kategorija';
import AddNewButton from '../common_components/AddNewButton';
import { generateKey } from '../../utils/KeyGenerator';
import enums from '../../utils/Enums';
import localization from '../../locales/localization';

const styles = {};

class Kategorije extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            kategorije: this.props.kategorije,
            novaKategorija: false
        };
        this.onDeleteKategorija = this.onDeleteKategorija.bind(this);
        this.onAddNew = this.onAddNew.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            kategorije: nextProps.kategorije,
            novaKategorija: false
        });
    }

    onDeleteKategorija(index) {
        this.setState({
            kategorije: this.state.kategorije.slice(0, index).concat(this.state.kategorije.slice(index + 1)),
            novaKategorija: this.state.novaKategorija && !(index === this.state.kategorije.length - 1)
        });
    }

    onAddNew(e) {
        this.setState({
            kategorije: [
                ...this.state.kategorije,
                {
                    ime: '',
                    tipKategorije: enums.tipKategorije.PREDMET,
                    nova: true,
                    potkategorije: []
                }
            ],
            novaKategorija: true
        });
    }

    render() {
        return (
            <Segment tertiary>
                <Header as="h2">{localization.postavke.kategorije}</Header>
                <Card.Group itemsPerRow={1} stackable>
                    {this.state.kategorije.map((k, index) => {
                        return (
                            <Card key={index}>
                                <Card.Content textAlign="center">
                                    <Kategorija novo={k.nova} kategorija={k} onDelete={this.onDeleteKategorija.bind(this, index)} />
                                </Card.Content>
                            </Card>
                        );
                    })}
                    {!this.state.novaKategorija ? (
                        <AddNewButton key={generateKey()} text={localization.postavke.dodajNovuKategoriju} name="novaKategorija" onAddNew={this.onAddNew} />
                    ) : null}
                </Card.Group>
            </Segment>
        );
    }
}

Kategorije.propTypes = {
    history: PropTypes.object.isRequired,
    kategorije: PropTypes.array.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {
        kategorije: state.kategorijeReducer.kategorije || []
    };
};

let mapDispatchToProps = function(dispatch) {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Kategorije);
