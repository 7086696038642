import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Accordion, Button, Dimmer, Form, Grid, Header, Icon, Loader, Message, Modal, Pagination, Search, Segment, Table } from 'semantic-ui-react';

import localization from '../../locales/localization';
import endpoints from '../../environments/endpoints';
import { storeDocumentAndGenerateKey } from '../../utils/PrintingDocumentsHandler';
import moment from 'moment';
import * as OtkupiDataAccess from '../../data_access/OtkupiDataAccess';
import * as OtkupiApi from '../../data_access/OtkupiApi';
import { bindActionCreators } from 'redux';
import enums from '../../utils/Enums';
import icons from '../../utils/DefinedIcons';
import SearchResultsMessage from '../common_components/SearchResultsMessage';
import Diskusija from '../common_components/Diskusija';
import OtkupProdajaContent from './content_components/OtkupProdajaContent';
import FormatIznosa from '../common_components/FormatIznosa';
import FilterSortButton from '../common_components/FilterSortButton';

// TODO #pagination #refactor Extract this to some other place when pagination for other components is implemented
//      and we solve the Redux/DataAccess dilemma.
const MAX_API_FETCH_ATTEMPTS = 3;
// TODO #pagination #refactor Probably extract somewhere else and use across all search components
const SEARCH_DEBOUNCE_RATE_MS = 1000;

const styles = {
    headerTable: {
        padding: '.75em 1em'
    },
    iconCell: {
        width: 50
    },
    brojUgovoraOtkupaCell: {
        width: 180
    },
    klijentCell: {
        width: 500
    },
    ukupnaCijenaCell: {
        width: 250
    },
    djelatnikCell: {
        width: 250
    },
    accordionHeaderContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    accordionHeaderText: {
        paddingLeft: 4,
        paddingRight: 2,
        paddingBottom: '0.5em'
    },
    actionButtons: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    semaforStyle: {
        marginLeft: 4
    }
};

const LIST_STATE = {
    initial: 'initial',
    empty: 'empty',
    noResult: 'noResult',
    populated: 'populated',
    error: 'error'
};

const ITEMS_PER_PAGE = 10;

class Otkupi extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            activeOtkup: [],
            activeComments: [],
            noveNapomene: [],
            sluzbenaNapomena: '',
            nesluzbenaNapomena: '',
            activePotvrda: undefined,
            loader: false,
            filter: 0,
            pageActive: 1
        };
        this.onNoviOtkup = this.onNoviOtkup.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
        this.applyFilterAndSort = this.applyFilterAndSort.bind(this);
        this.resetSearchComponent = this.resetSearchComponent.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.onChangeNapomene = this.onChangeNapomene.bind(this);
        this.calculateUkupno = this.calculateUkupno.bind(this);
        this.onChangeNovaNapomena = this.onChangeNovaNapomena.bind(this);
        this.expandOtkup = this.expandOtkup.bind(this);
        this.expandComments = this.expandComments.bind(this);
        this.changeFlagsAndPrintOtkupniBlok = this.changeFlagsAndPrintOtkupniBlok.bind(this);
        this.changeFlagsAndPrintLatestOtkupniBlok = this.changeFlagsAndPrintLatestOtkupniBlok.bind(this);
        this.ispisOtkupnogBloka = this.ispisOtkupnogBloka.bind(this);
        this.ispisUgovoraOtkupa = this.ispisUgovoraOtkupa.bind(this);
        this.stornoOtkupa = this.stornoOtkupa.bind(this);
        this.dodajNapomenu = this.dodajNapomenu.bind(this);
        this.resetNapomena = this.resetNapomena.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.showLoader = this.showLoader.bind(this);
        this.hideLoader = this.hideLoader.bind(this);
        this.fetchOtkupi = this.fetchOtkupi.bind(this);
        this.refreshOtkupi = this.refreshOtkupi.bind(this);
        this.getPoslovnicaIdForFilter = this.getPoslovnicaIdForFilter.bind(this);
        this.renderInitialComponent = this.renderInitialComponent.bind(this);
        this.renderEmptyComponent = this.renderEmptyComponent.bind(this);
        this.renderNoResultComponent = this.renderNoResultComponent.bind(this);
        this.renderErrorComponent = this.renderErrorComponent.bind(this);
        this.renderListComponent = this.renderListComponent.bind(this);
        this.debouncedFetchOtkupi = _.debounce(function (filter, page) {
            this.fetchOtkupi(filter, page);
        }, SEARCH_DEBOUNCE_RATE_MS).bind(this);

        if (sessionStorage.userRole !== enums.tipKorisnika.DJELATNIK) {
            this.FILTER_ITEMS = [{
                name: localization.common.svePoslovnice,
                apiParam: null
            }];
            this.props.poslovnice.forEach(p => this.FILTER_ITEMS.push({
                name: p.naziv,
                apiParam: p.id
            }));
        }
    }

    componentWillMount() {
        this.resetSearchComponent();
    }

    onNoviOtkup() {
        this.props.history.push(endpoints.appEndpoints.otkup);
    }

    onChangePage(e, { activePage }) {
        this.fetchOtkupi(this.state.filter, activePage);
    }

    applyFilterAndSort(filter, sort) {
        this.fetchOtkupi(filter);
    }

    resetSearchComponent() {
        this.setState({
            listState: LIST_STATE.initial,
            fetchAttempts: 0
        }, () => {
            this.fetchOtkupi();
        });
    }

    handleSearchChange(e, { value }) {
        this.setState({
            value: value
        }, () => {
            this.debouncedFetchOtkupi();
        });
    }

    onChangeNapomene(e, { name, value }) {
        this.setState({ [name]: value });
    }

    calculateUkupno(otkup) {
        let ukupno = 0;
        otkup.skladisniUlazi.forEach(su => {
            if (otkup.rbrStorniranogSUBa === null) {
                ukupno += su.kolicina * su.jedNabavnaCijena;
            } else {
                ukupno += -su.kolicina * su.jedNabavnaCijena;
            }
        });
        return ukupno;
    }

    onChangeNovaNapomena(id, e, { value }) {
        let noveNapomene = [];
        let index = this.state.noveNapomene.findIndex(el => el.id === id);
        if (index > -1) {
            noveNapomene = [...this.state.noveNapomene];
            noveNapomene[index] = {
                id: id,
                napomena: value
            };
        } else {
            noveNapomene = [
                ...this.state.noveNapomene,
                {
                    id: id,
                    napomena: value
                }
            ];
        }
        this.setState({
            noveNapomene: noveNapomene
        });
    }

    expandOtkup(e, { index }) {
        let activeOtkup = [];
        if (this.state.activeOtkup.includes(index)) {
            activeOtkup = _.xor(this.state.activeOtkup, [index]);
        } else {
            activeOtkup = [...this.state.activeOtkup, index];
        }
        this.setState({
            activeOtkup: activeOtkup
        });
    }

    expandComments(e, { index }) {
        let activeComments = [];
        if (this.state.activeComments.includes(index)) {
            activeComments = _.xor(this.state.activeComments, [index]);
        } else {
            activeComments = [...this.state.activeComments, index];
        }
        this.setState({
            activeComments: activeComments
        });
    }

    changeFlagsAndPrintOtkupniBlok(otkupniBlokId) {
        this.showLoader();
        this.props.otkupiDataAccess.updateIsPrintanFlag(
            {
                subId: otkupniBlokId,
                flagValue: true
            },
            (otkupniBlok) => {
                this.ispisOtkupnogBloka(otkupniBlok);
                this.hideLoader();
            },
            () => this.hideLoader()
        );
    }

    changeFlagsAndPrintLatestOtkupniBlok() {
        this.showLoader();
        OtkupiApi.getLatestOtkupniBlok(
            this.getPoslovnicaIdForFilter(this.state.filter),
            (otkupniBlok) => this.changeFlagsAndPrintOtkupniBlok(otkupniBlok.id),
            () => this.hideLoader()
        );
    }

    ispisOtkupnogBloka(otkupniBlok) {
        window.open(endpoints.appEndpoints.print.otkupniBlok + storeDocumentAndGenerateKey(otkupniBlok), '_blank');
    }

    ispisUgovoraOtkupa(otkup) {
        window.open(endpoints.appEndpoints.print.ugovorOtkupa + storeDocumentAndGenerateKey(otkup), '_blank');
    }

    stornoOtkupa(otkupId) {
        this.showLoader();
        this.props.otkupiDataAccess.stornoOtkupa(
            otkupId,
            {
                sluzbenaNapomena: this.state.sluzbenaNapomena,
                nesluzbenaNapomena: this.state.nesluzbenaNapomena || null
            },
            () => this.hideLoader(() => this.hideModal(() => this.refreshOtkupi())),
            () => this.hideLoader()
        );
    }

    dodajNapomenu(otkupId) {
        this.showLoader();
        this.props.otkupiDataAccess.dodavanjeNesluzbeneNapomeneNaOtkup(
            otkupId,
            this.state.noveNapomene.find(el => el.id === otkupId).napomena,
            () => this.hideLoader(() => this.resetNapomena(otkupId, () => this.refreshOtkupi())),
            () => this.hideLoader()
        );
    }

    resetNapomena(id, callback) {
        this.setState({
            noveNapomene: _.reject(this.state.noveNapomene, (napomena) => napomena.id === id)
        }, callback);
    }

    showModal(id) {
        this.setState({ activePotvrda: id });
    }
    
    hideModal(callback) {
        this.setState({ activePotvrda: false }, callback);
    }

    showLoader() {
        this.setState(
            {
                loader: true
            }
        );
    }

    hideLoader(callback) {
        this.setState(
            {
                loader: false
            },
            callback
        );
    }

    fetchOtkupi(filter = this.state.filter, page = 1) {

        let nextStateCommon = {
            loader: false,
            fetchAttempts: 0,
            filter: filter
        };

        let onFetchSuccess = (data) => {
            if (data.items.length !== 0) {
                let nextStateNonEmpty = {
                    listState: LIST_STATE.populated,
                    otkupi: data.items,
                    pages: data.totalPages,
                    pageActive: page
                };
                this.setState(Object.assign({}, nextStateCommon, nextStateNonEmpty));

            } else {

                let noFilteredApplied = this.FILTER_ITEMS && !this.FILTER_ITEMS[filter].apiParam;
                let queryEmpty = this.state.value === '';
                let fetchedAll = noFilteredApplied && queryEmpty;

                let newListState = fetchedAll ? LIST_STATE.empty : LIST_STATE.noResult;
                let nextStateEmpty = {
                    listState: newListState,
                    otkupi: []
                };

                this.setState(Object.assign({}, nextStateCommon, nextStateEmpty));
            }
        };

        let onFetchError = () => {
            let fetchAttempts = this.state.fetchAttempts + 1;
            if (fetchAttempts === MAX_API_FETCH_ATTEMPTS) {
                let nextStateError = {
                    listState: LIST_STATE.error
                };
                this.setState(Object.assign({}, nextStateCommon, nextStateError));
            } else {
                this.setState({
                    fetchAttempts: fetchAttempts
                }, () => {
                    this.fetchOtkupi(filter, page);
                });
            }

        };

        this.setState({
            loader: true
        });

        OtkupiApi.getOtkupiPaginated(
            this.state.value, page, ITEMS_PER_PAGE, this.getPoslovnicaIdForFilter(filter),
            OtkupiApi.TIP_SKL_UL_BLOKA.otkupniBlok, onFetchSuccess, onFetchError
        );
    }

    refreshOtkupi() {
        this.fetchOtkupi(this.state.filter, this.state.pageActive);
    }

    getPoslovnicaIdForFilter(filter) {
        return this.FILTER_ITEMS
            ? this.FILTER_ITEMS[filter].apiParam
            : JSON.parse(sessionStorage.poslovnicaId);
    }

    renderInitialComponent() {
        return (
            <SearchResultsMessage
                icon={icons.otkup}
                header={localization.common.ucitavanje}
                content={localization.common.molimoPricekajte}
            />
        );
    }

    renderEmptyComponent() {
        return (
            <SearchResultsMessage
                icon={icons.otkup}
                header={localization.otkupi.nemaUgovoraOtkupa}
                content={undefined}
            />
        );
    }

    renderNoResultComponent() {
        return (
            <SearchResultsMessage
                icon={icons.otkup}
                header={localization.common.unesenaPretragaNemaRezultata}
                content={localization.common.pokusajDrugacijuPretragu}
            />
        );
    }

    renderErrorComponent() {
        return (
            <SearchResultsMessage
                icon={icons.otkup}
                header={localization.common.dogodilaSeGreska}
                content={localization.common.pokusajtePonovno}
            />
        );
    }

    renderListComponent() {
        return (
            <React.Fragment>
                <Grid.Row>
                    <Grid.Column>
                        <Message style={styles.headerTable}>
                            <Table structured stackable>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell style={styles.iconCell} />
                                        <Table.HeaderCell style={styles.brojUgovoraOtkupaCell}>
                                            {localization.otkupi.brojUgovoraOtkupa}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell style={styles.klijentCell}>{localization.otkupi.klijent}</Table.HeaderCell>
                                        {sessionStorage.userRole === enums.tipKorisnika.VLASNIK ? (
                                            <Table.HeaderCell style={styles.ukupnaCijenaCell}>
                                                {localization.common.ukupnaCijena}
                                            </Table.HeaderCell>
                                        ) : null}
                                        <Table.HeaderCell>{localization.otkupi.datumIVrijemeUgovoraOtkupa}</Table.HeaderCell>
                                        <Table.HeaderCell style={styles.djelatnikCell}>{localization.otkupi.djelatnik}</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                            </Table>
                        </Message>
                    </Grid.Column>
                </Grid.Row>
                {this.state.otkupi.map(otkup => {
                    return (
                        <Grid.Row key={otkup.id}>
                            <Grid.Column verticalAlign="middle">
                                <Accordion fluid styled>
                                    <Accordion.Title
                                        active={this.state.activeOtkup.includes(otkup.id)}
                                        index={otkup.id}
                                        onClick={this.expandOtkup}
                                    >
                                        <Table structured stackable color={otkup.isStorniran ? 'grey' : undefined} inverted={otkup.isStorniran}>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.Cell style={styles.iconCell}>
                                                        <Icon name="dropdown" />
                                                    </Table.Cell>
                                                    <Table.Cell style={styles.brojUgovoraOtkupaCell}>{otkup.rbrOtkupniBlok}</Table.Cell>
                                                    <Table.Cell style={styles.klijentCell}>
                                                        {otkup.isStorniran === null && otkup.rbrStorniranogSUBa
                                                            ? localization.otkupi.stornoOtkupa +
                                                              ': ' +
                                                              otkup.rbrStorniranogSUBa +
                                                              ' - ' +
                                                              otkup.fizickaOsoba.ime +
                                                              ' ' +
                                                              otkup.fizickaOsoba.prezime
                                                            : otkup.fizickaOsoba.ime + ' ' + otkup.fizickaOsoba.prezime}
                                                    </Table.Cell>
                                                    {sessionStorage.userRole === enums.tipKorisnika.VLASNIK ? (
                                                        <Table.Cell style={styles.ukupnaCijenaCell}>
                                                            <FormatIznosa value={this.calculateUkupno(otkup)} />
                                                        </Table.Cell>
                                                    ) : null}
                                                    <Table.Cell>{moment(otkup.datumIVrijeme).format('DD.MM.YYYY. HH:mm')}</Table.Cell>
                                                    <Table.Cell style={styles.djelatnikCell}>
                                                        {otkup.user.ime + ' ' + otkup.user.prezime}
                                                    </Table.Cell>
                                                </Table.Row>
                                            </Table.Header>
                                        </Table>
                                    </Accordion.Title>
                                    <Accordion.Content active={this.state.activeOtkup.includes(otkup.id)}>
                                        <OtkupProdajaContent
                                            klijent={otkup.fizickaOsoba}
                                            type={enums.tipTabliceArtikala.OTKUP_FIXED}
                                            artikli={otkup.skladisniUlazi.map(su => {
                                                return Object.assign({}, su, su.predmet, {
                                                    cijena: su.jedNabavnaCijena,
                                                    kolicina: otkup.rbrStorniranogSUBa === null ? su.kolicina : -su.kolicina
                                                });
                                            })}
                                        />
                                        <div style={styles.actionButtons}>
                                            <Button
                                                icon="print"
                                                color="green"
                                                content={localization.otkupi.ispisUgovoraOtkupa}
                                                onClick={this.ispisUgovoraOtkupa.bind(this, otkup)}
                                            />
                                            {sessionStorage.userRole !== enums.tipKorisnika.DJELATNIK ? (
                                                <Button
                                                    icon="print"
                                                    color="green"
                                                    content={localization.otkupi.ispisOtkupnogBloka}
                                                    onClick={this.changeFlagsAndPrintOtkupniBlok.bind(this, otkup.id)}
                                                />
                                            ) : null}
                                            {!otkup.isStorniran &&
                                            !otkup.rbrStorniranogSUBa &&
                                            sessionStorage.userRole === enums.tipKorisnika.DJELATNIK ? (
                                                <Modal
                                                    open={this.state.activePotvrda === otkup.id}
                                                    closeOnDimmerClick={false}
                                                    trigger={
                                                        <Button
                                                            icon="undo"
                                                            color="red"
                                                            content={localization.otkupi.storniranjeOtkupa}
                                                            onClick={() => this.showModal(otkup.id)}
                                                        />
                                                    }
                                                >
                                                    <Header icon="undo" content={localization.common.potvrda} />
                                                    <Modal.Content>
                                                        <p>{localization.otkupi.storniranjePitanjePotvrde}</p>
                                                        <Form>
                                                            <Form.TextArea
                                                                rows={2}
                                                                placeholder={localization.otkup.sluzbenaNapomenaOtkupa}
                                                                name="sluzbenaNapomena"
                                                                onChange={this.onChangeNapomene}
                                                            />
                                                            <Form.TextArea
                                                                rows={2}
                                                                placeholder={localization.otkup.nesluzbenaNapomenaOtkupa}
                                                                name="nesluzbenaNapomena"
                                                                onChange={this.onChangeNapomene}
                                                            />
                                                        </Form>
                                                    </Modal.Content>
                                                    <Modal.Actions>
                                                        <Button
                                                            icon="remove"
                                                            content={localization.common.odustani}
                                                            onClick={() => this.hideModal()}
                                                        />
                                                        <Button
                                                            icon="copy"
                                                            color="red"
                                                            content={localization.otkupi.storniraj}
                                                            disabled={!this.state.sluzbenaNapomena}
                                                            onClick={() => this.stornoOtkupa(otkup.id)}
                                                        />
                                                    </Modal.Actions>
                                                </Modal>
                                            ) : null}
                                        </div>
                                        {otkup.sluzbenaNapomena ? (
                                            <Message>
                                                <Message.Header>{localization.common.sluzbenaNapomena}</Message.Header>
                                                <p>{otkup.sluzbenaNapomena}</p>
                                            </Message>
                                        ) : null}
                                        <Accordion fluid styled>
                                            <Accordion.Title
                                                active={this.state.activeComments.includes(otkup.id)}
                                                index={otkup.id}
                                                onClick={this.expandComments}
                                            >
                                                <Header as="h5" dividing style={styles.accordionHeaderContainer}>
                                                    <Icon name="dropdown" />
                                                    {localization.common.napomene}
                                                    {otkup.nesluzbeneNapomene.length ? (
                                                        <Icon name="exclamation circle" color="red" style={styles.semaforStyle} />
                                                    ) : null}
                                                </Header>
                                            </Accordion.Title>
                                            <Accordion.Content active={this.state.activeComments.includes(otkup.id)}>
                                                <Diskusija
                                                    napomene={otkup.nesluzbeneNapomene}
                                                    textAreaValue={
                                                        this.state.noveNapomene.find(el => el.id === otkup.id)
                                                            ? this.state.noveNapomene.find(el => el.id === otkup.id).napomena
                                                            : ''
                                                    }
                                                    onChangeNovaNapomena={this.onChangeNovaNapomena.bind(this, otkup.id)}
                                                    onDodajNapomenu={() => this.dodajNapomenu(otkup.id)}
                                                />
                                            </Accordion.Content>
                                        </Accordion>
                                    </Accordion.Content>
                                </Accordion>
                            </Grid.Column>
                        </Grid.Row>
                    );
                })}
                <Grid.Row centered>
                    <Pagination
                        activePage={this.state.pageActive}
                        totalPages={this.state.pages}
                        onPageChange={this.onChangePage}
                        ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
                        firstItem={{ content: <Icon name="angle double left" />, icon: true }}
                        lastItem={{ content: <Icon name="angle double right" />, icon: true }}
                        prevItem={{ content: <Icon name="angle left" />, icon: true }}
                        nextItem={{ content: <Icon name="angle right" />, icon: true }}
                    />
                </Grid.Row>
            </React.Fragment>
        );
    }

    render() {
        return (
            <div>
                <Segment tertiary>
                    <Grid stackable>
                        <Grid.Row>
                            <Grid.Column verticalAlign="middle" width={3}>
                                <Header as="h2">{localization.otkupi.otkupi}</Header>
                            </Grid.Column>
                            <Grid.Column verticalAlign="middle" width={3}>
                                <Search
                                    input={{ fluid: true }}
                                    loading={this.state.isSearchLoading}
                                    onSearchChange={this.handleSearchChange}
                                    value={this.state.value}
                                    open={false}
                                />
                            </Grid.Column>
                            <Grid.Column width={3} />
                            {sessionStorage.userRole === enums.tipKorisnika.DJELATNIK ? (
                                <React.Fragment>
                                    <Grid.Column textAlign="right" verticalAlign="middle" width={3}>
                                        <Button icon="asterisk" basic color="black" fluid content={localization.otkupi.noviOtkup} onClick={this.onNoviOtkup} />
                                    </Grid.Column>
                                    {this.state.listState !== LIST_STATE.empty ? (
                                        <Grid.Column textAlign="right" verticalAlign="middle" width={4}>
                                            <Button
                                                icon="print"
                                                basic
                                                color="black"
                                                fluid
                                                content={localization.otkupi.ispisZadnjegOtkupnogBloka}
                                                onClick={() => this.changeFlagsAndPrintLatestOtkupniBlok()}
                                            />
                                        </Grid.Column>
                                    ) : (
                                        <Grid.Column width={4} />
                                    )}
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <Grid.Column width={3} />
                                    <Grid.Column textAlign="right" verticalAlign="middle" width={4}>
                                        <FilterSortButton
                                            buttonName={localization.common.filtriranje}
                                            filteringOptions={this.FILTER_ITEMS.map((f) => f.name)}
                                            activeFilter={this.state.filter}
                                            callback={this.applyFilterAndSort}
                                        />
                                    </Grid.Column>
                                </React.Fragment>
                            )}
                        </Grid.Row>
                        {(() => {
                            switch (this.state.listState) {
                                case LIST_STATE.initial:
                                    return this.renderInitialComponent();
                                case LIST_STATE.populated:
                                    return this.renderListComponent();
                                case LIST_STATE.empty:
                                    return this.renderEmptyComponent();
                                case LIST_STATE.noResult:
                                    return this.renderNoResultComponent();
                                case LIST_STATE.error:
                                    return this.renderErrorComponent();
                            }
                        })()}
                    </Grid>
                </Segment>
                <Dimmer active={this.state.loader} page>
                    <Loader />
                </Dimmer>
            </div>
        );
    }
}

Otkupi.propTypes = {
    history: PropTypes.object.isRequired,
    poslovnice: PropTypes.array.isRequired,
    otkupiDataAccess: PropTypes.object.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {
        poslovnice: state.mjestaReducer.poslovnice || []
    };
};

let mapDispatchToProps = function(dispatch) {
    return {
        otkupiDataAccess: bindActionCreators(OtkupiDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Otkupi);
