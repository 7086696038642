import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import localization from '../../locales/localization';
import { Button, Dimmer, Loader, Table } from 'semantic-ui-react';
import InputField from '../common_components/InputField';
import * as SkladisteDataAccess from '../../data_access/SkladisteDataAccess';
import { bindActionCreators } from 'redux';
import FormatIznosa from '../common_components/FormatIznosa';

const styles = {
    container: {
        textAlign: 'center'
    },
    kolicinaIcijena: {
        fontFamily: 'monospace'
    },
    actionButtons: {
        marginTop: 16,
        display: 'flex',
        justifyContent: 'flex-end'
    }
};

class UnosProdajnihCijena extends React.Component {
    constructor(props) {
        super(props);
        this.calculateInitialPrice = this.calculateInitialPrice.bind(this);
        this.onChangeCijena = this.onChangeCijena.bind(this);
        this.onUnesiProdajneCijene = this.onUnesiProdajneCijene.bind(this);
        if (this.props.skladisnaStavka) {
            this.state = {
                stanjeSkladista: [
                    Object.assign({}, this.props.skladisnaStavka, {
                        cijena: this.props.skladisnaStavka.isPotvrdenaCijena ? this.props.skladisnaStavka.jedProdajnaCijena : undefined
                    })
                ]
            };
        } else {
            this.state = {
                stanjeSkladista: [],
                loader: true
            };
            this.props.skladisteDataAccess.getStanjeSkladistaSNepotvrdenimCijenama(this.calculateInitialPrice);
        }
    }

    calculateInitialPrice(stanjeSkladista) {
        this.setState({
            stanjeSkladista: stanjeSkladista.stanja
                .flatMap(mjesto => mjesto.stavke)
                .map(ss => {
                    return Object.assign({}, ss, {
                        cijena: ss.isPotvrdenaCijena ? ss.jedProdajnaCijena : undefined
                    });
                }),
            loader: false
        });
    }

    onChangeCijena(skladisnaStavkaId, e, { value }) {
        this.setState({
            stanjeSkladista: this.state.stanjeSkladista.map(ss => {
                if (ss.id === skladisnaStavkaId) {
                    ss.cijena = value;
                }
                return ss;
            })
        });
    }

    onUnesiProdajneCijene() {
        let prodajneCijene = this.state.stanjeSkladista
            .map(ss => {
                return {
                    skladisnaStavkaId: ss.id,
                    jedProdajnaCijena: parseFloat(ss.cijena)
                };
            })
            .filter(ss => ss.jedProdajnaCijena && ss.jedProdajnaCijena > 0);
        this.props.skladisteDataAccess.promjenaProdajnihCijena(prodajneCijene, this.props.closeModal);
    }

    render() {
        return (
            <div style={styles.container}>
                <Table celled structured stackable selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>{localization.artikl.sifra}</Table.HeaderCell>
                            <Table.HeaderCell>{localization.artikl.naziv}</Table.HeaderCell>
                            <Table.HeaderCell>{localization.artikl.serijskiBroj}</Table.HeaderCell>
                            {this.props.showNabavnaCijena && (
                                <Table.HeaderCell textAlign="center">{localization.stanjeSkladista.jedinicnaCijenaOtkupa}</Table.HeaderCell>
                            )}
                            <Table.HeaderCell textAlign="center">{localization.stanjeSkladista.predlozenaProdajnaCijena}</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">{localization.stanjeSkladista.jedinicnaProdajnaCijena}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.state.stanjeSkladista.map(ss => (
                            <Table.Row key={ss.artikl.sifra}>
                                <Table.Cell>{ss.artikl.sifra}</Table.Cell>
                                <Table.Cell>{ss.artikl.naziv}</Table.Cell>
                                <Table.Cell>{ss.artikl.serijskiBroj || '-'}</Table.Cell>
                                {this.props.showNabavnaCijena && (
                                    <Table.Cell textAlign="right" collapsing style={styles.kolicinaIcijena}>
                                        <FormatIznosa value={ss.jedNabavnaCijena} />
                                    </Table.Cell>
                                )}
                                <Table.Cell textAlign="right" collapsing style={styles.kolicinaIcijena}>
                                    <FormatIznosa value={ss.jedProdajnaCijena} />
                                </Table.Cell>
                                <Table.Cell collapsing>
                                    <InputField
                                        label={localization.common.eur}
                                        placeholder={localization.common.cijena}
                                        name="cijena"
                                        value={ss.cijena}
                                        onChange={this.onChangeCijena.bind(this, ss.id)}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
                <div style={styles.actionButtons}>
                    <Button icon="remove" content={localization.common.odustani} onClick={this.props.closeModal} />
                    <Button icon="dollar" color="green" content={localization.stanjeSkladista.potvrdiProdajneCijene} onClick={this.onUnesiProdajneCijene} />
                </div>
                <Dimmer active={this.state.loader}>
                    <Loader />
                </Dimmer>
            </div>
        );
    }
}

UnosProdajnihCijena.propTypes = {
    skladisnaStavka: PropTypes.object,
    showNabavnaCijena: PropTypes.bool.isRequired,
    skladisteDataAccess: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {};
};

let mapDispatchToProps = function(dispatch) {
    return {
        skladisteDataAccess: bindActionCreators(SkladisteDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UnosProdajnihCijena);
