import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Accordion, Button, Dimmer, Grid, Header, Icon, Loader, Message, Modal, Pagination, Search, Segment, Table } from 'semantic-ui-react';

import localization from '../../locales/localization';
import endpoints from '../../environments/endpoints';
import enums from '../../utils/Enums';
import icons from '../../utils/DefinedIcons';
import { storeDocumentAndGenerateKey } from '../../utils/PrintingDocumentsHandler';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import * as MeduskladisniceDataAccess from '../../data_access/MeduskladisniceDataAccess';
import * as MeduskladisniceApi from '../../data_access/MeduskladisniceApi';
import SearchResultsMessage from '../common_components/SearchResultsMessage';
import Diskusija from '../common_components/Diskusija';
import MeduskladisnicaContent from './content_components/MeduskladisnicaContent';
import FilterSortButton from '../common_components/FilterSortButton';

// TODO #pagination #refactor Extract this to some other place when pagination for other components is implemented
//      and we solve the Redux/DataAccess dilemma.
const MAX_API_FETCH_ATTEMPTS = 3;
// TODO #pagination #refactor Probably extract somewhere else and use across all search components
const SEARCH_DEBOUNCE_RATE_MS = 1000;

const styles = {
    headerTable: {
        padding: '.75em 1em'
    },
    iconCell: {
        width: 50
    },
    brojMeduskladisniceCell: {
        width: 200
    },
    mjestoCell: {
        width: 300
    },
    isIzvrsenaCell: {
        width: 100
    },
    accordionHeaderContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    accordionHeaderText: {
        paddingLeft: 4,
        paddingRight: 2,
        paddingBottom: '0.5em'
    },
    actionButtons: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
};

const potvrdeEnums = {
    storniranjeMeduskladisnice: 'storniranjeMeduskladisnice',
    izvrsavanjeMeduskladisnice: 'izvrsavanjeMeduskladisnice'
};

const LIST_STATE = {
    initial: 'initial',
    empty: 'empty',
    noResult: 'noResult',
    populated: 'populated',
    error: 'error'
};

const ITEMS_PER_PAGE = 10;

class Meduskladisnice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            activeMeduskladisnice: [],
            activeComments: [],
            noveNapomene: [],
            loader: false,
            activePotvrda: undefined,
            filter: 0,
            pageActive: 1
        };
        this.onNovaMeduskladisnica = this.onNovaMeduskladisnica.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
        this.applyFilterAndSort = this.applyFilterAndSort.bind(this);
        this.resetSearchComponent = this.resetSearchComponent.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.expandMeduskladisnica = this.expandMeduskladisnica.bind(this);
        this.onChangeNovaNapomena = this.onChangeNovaNapomena.bind(this);
        this.expandComments = this.expandComments.bind(this);
        this.ispisMeduskladisnice = this.ispisMeduskladisnice.bind(this);
        this.izvrsiMeduskladisnicu = this.izvrsiMeduskladisnicu.bind(this);
        this.stornoMeduskladisnice = this.stornoMeduskladisnice.bind(this);
        this.dodajNapomenu = this.dodajNapomenu.bind(this);
        this.resetNapomena = this.resetNapomena.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.showLoader = this.showLoader.bind(this);
        this.hideLoader = this.hideLoader.bind(this);
        this.fetchMeduskladisnice = this.fetchMeduskladisnice.bind(this);
        this.refreshMeduskladisnice = this.refreshMeduskladisnice.bind(this);
        this.getMjestoIdForFilter = this.getMjestoIdForFilter.bind(this);
        this.renderInitialComponent = this.renderInitialComponent.bind(this);
        this.renderEmptyComponent = this.renderEmptyComponent.bind(this);
        this.renderNoResultComponent = this.renderNoResultComponent.bind(this);
        this.renderErrorComponent = this.renderErrorComponent.bind(this);
        this.renderListComponent = this.renderListComponent.bind(this);
        this.debouncedFetchMeduskladisnice = _.debounce(function (filter, page) {
            this.fetchMeduskladisnice(filter, page);
        }, SEARCH_DEBOUNCE_RATE_MS).bind(this);

        if (sessionStorage.userRole !== enums.tipKorisnika.DJELATNIK) {
            this.FILTER_ITEMS = [{
                name: localization.common.svePoslovnice,
                apiParam: null
            }];
            this.props.poslovnice.forEach(p => this.FILTER_ITEMS.push({
                name: p.naziv,
                apiParam: p.id
            }));
        }
    }

    componentWillMount() {
        this.resetSearchComponent();
    }

    onNovaMeduskladisnica() {
        this.props.history.push(endpoints.appEndpoints.meduskladisnica);
    }

    onChangePage(e, { activePage }) {
        this.fetchMeduskladisnice(this.state.filter, activePage);
    }

    applyFilterAndSort(filter, sort) {
        this.fetchMeduskladisnice(filter);
    }

    resetSearchComponent() {
        this.setState({
            listState: LIST_STATE.initial,
            fetchAttempts: 0
        }, () => {
            this.fetchMeduskladisnice();
        });
    }

    handleSearchChange(e, { value }) {
        this.setState({
            value: value
        }, () => {
            this.debouncedFetchMeduskladisnice();
        });
    }

    expandMeduskladisnica(e, { index }) {
        let activeMeduskladisnice = [];
        if (this.state.activeMeduskladisnice.includes(index)) {
            activeMeduskladisnice = _.xor(this.state.activeMeduskladisnice, [index]);
        } else {
            activeMeduskladisnice = [...this.state.activeMeduskladisnice, index];
        }
        this.setState({
            activeMeduskladisnice: activeMeduskladisnice
        });
    }

    onChangeNovaNapomena(id, e, { value }) {
        let noveNapomene = [];
        let index = this.state.noveNapomene.findIndex(el => el.id === id);
        if (index > -1) {
            noveNapomene = [...this.state.noveNapomene];
            noveNapomene[index] = {
                id: id,
                napomena: value
            };
        } else {
            noveNapomene = [
                ...this.state.noveNapomene,
                {
                    id: id,
                    napomena: value
                }
            ];
        }
        this.setState({
            noveNapomene: noveNapomene
        });
    }

    expandComments(e, { index }) {
        let activeComments = [];
        if (this.state.activeComments.includes(index)) {
            activeComments = _.xor(this.state.activeComments, [index]);
        } else {
            activeComments = [...this.state.activeComments, index];
        }
        this.setState({
            activeComments: activeComments
        });
    }

    ispisMeduskladisnice(meduskladisnica) {
        window.open(endpoints.appEndpoints.print.meduskladisnica + storeDocumentAndGenerateKey(meduskladisnica), '_blank');
    }

    izvrsiMeduskladisnicu(meduskladisnicaId) {
        this.showLoader();
        this.props.meduskladisniceDataAccess.izvrsiMeduskladisnicu(
            meduskladisnicaId,
            () => this.hideLoader(() => this.hideModal(() => this.refreshMeduskladisnice())),
            () => this.hideLoader()
        );
    }

    stornoMeduskladisnice(meduskladisnicaId) {
        this.showLoader();
        this.props.meduskladisniceDataAccess.stornoMeduskladisnice(
            meduskladisnicaId,
            () => this.hideLoader(() => this.hideModal(() => this.refreshMeduskladisnice())),
            () => this.hideLoader()
        );
    }

    dodajNapomenu(meduskladisnicaId) {
        this.showLoader();
        this.props.meduskladisniceDataAccess.dodavanjeNesluzbeneNapomeneNaMeduskladisnicu(
            meduskladisnicaId,
            this.state.noveNapomene.find(el => el.id === meduskladisnicaId).napomena,
            () => this.hideLoader(() => this.resetNapomena(meduskladisnicaId, () => this.refreshMeduskladisnice())),
            () => this.hideLoader()
        );
    }

    resetNapomena(id, callback) {
        this.setState({
            noveNapomene: _.reject(this.state.noveNapomene, (napomena) => napomena.id === id)
        }, callback);
    }

    showModal(name, sifra) {
        this.setState({
            activePotvrda: {
                type: name,
                id: sifra
            }
        });
    }

    hideModal(callback) {
        this.setState({ activePotvrda: undefined }, callback);
    }

    showLoader() {
        this.setState(
            {
                loader: true
            }
        );
    }

    hideLoader(callback) {
        this.setState(
            {
                loader: false
            },
            callback
        );
    }

    fetchMeduskladisnice(filter = this.state.filter, page = 1) {

        let nextStateCommon = {
            loader: false,
            fetchAttempts: 0,
            filter: filter
        };

        let onFetchSuccess = (data) => {
            if (data.items.length !== 0) {
                let nextStateNonEmpty = {
                    listState: LIST_STATE.populated,
                    meduskladisnice: data.items,
                    pages: data.totalPages,
                    pageActive: page
                };
                this.setState(Object.assign({}, nextStateCommon, nextStateNonEmpty));

            } else {

                let fetchedAll = this.state.value === '';

                let newListState = fetchedAll ? LIST_STATE.empty : LIST_STATE.noResult;
                let nextStateEmpty = {
                    listState: newListState,
                    meduskladisnice: []
                };

                this.setState(Object.assign({}, nextStateCommon, nextStateEmpty));
            }
        };

        let onFetchError = () => {
            let fetchAttempts = this.state.fetchAttempts + 1;
            if (fetchAttempts === MAX_API_FETCH_ATTEMPTS) {
                let nextStateError = {
                    listState: LIST_STATE.error
                };
                this.setState(Object.assign({}, nextStateCommon, nextStateError));
            } else {
                this.setState({
                    fetchAttempts: fetchAttempts
                }, () => {
                    this.fetchMeduskladisnice(filter, page);
                });
            }

        };

        this.showLoader();

        MeduskladisniceApi.getMeduskladisnicePaginated(
            this.state.value, page, ITEMS_PER_PAGE, this.getMjestoIdForFilter(filter),
            onFetchSuccess, onFetchError
        );
    }

    refreshMeduskladisnice() {
        this.fetchMeduskladisnice(this.state.filter, this.state.pageActive);
    }

    getMjestoIdForFilter(filter) {
        return this.FILTER_ITEMS
            ? this.FILTER_ITEMS[filter].apiParam
            : JSON.parse(sessionStorage.poslovnicaId);
    }

    renderInitialComponent() {
        return (
            <SearchResultsMessage
                icon={icons.meduskladisnica}
                header={localization.common.ucitavanje}
                content={localization.common.molimoPricekajte}
            />
        );
    }

    renderEmptyComponent() {
        return (
            <SearchResultsMessage
                icon={icons.meduskladisnica}
                header={localization.meduskladisnice.nemaMeduskladisnica}
                content={undefined}
            />
        );
    }

    renderNoResultComponent() {
        return (
            <SearchResultsMessage
                icon={icons.meduskladisnica}
                header={localization.common.unesenaPretragaNemaRezultata}
                content={localization.common.pokusajDrugacijuPretragu}
            />
        );
    }

    renderErrorComponent() {
        return (
            <SearchResultsMessage
                icon={icons.meduskladisnica}
                header={localization.common.dogodilaSeGreska}
                content={localization.common.pokusajtePonovno}
            />
        );
    }

    renderListComponent() {
        return (
            <React.Fragment>
                <Grid.Row>
                    <Grid.Column>
                        <Message style={styles.headerTable}>
                            <Table structured stackable>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell style={styles.iconCell} />
                                        <Table.HeaderCell style={styles.brojMeduskladisniceCell}>
                                            {localization.meduskladisnice.brojMeduskladisnice}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell style={styles.mjestoCell}>{localization.meduskladisnice.polaziste}</Table.HeaderCell>
                                        <Table.HeaderCell style={styles.mjestoCell}>{localization.meduskladisnice.odrediste}</Table.HeaderCell>
                                        <Table.HeaderCell>{localization.meduskladisnice.datumIVrijeme}</Table.HeaderCell>
                                        <Table.HeaderCell style={styles.isIzvrsenaCell}>
                                            {localization.meduskladisnice.izvrsena}
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                            </Table>
                        </Message>
                    </Grid.Column>
                </Grid.Row>
                {this.state.meduskladisnice.map(meduskladisnica => {
                    return (
                        <Grid.Row key={meduskladisnica.id}>
                            <Grid.Column verticalAlign="middle">
                                <Accordion fluid styled>
                                    <Accordion.Title
                                        active={this.state.activeMeduskladisnice.includes(meduskladisnica.id)}
                                        index={meduskladisnica.id}
                                        onClick={this.expandMeduskladisnica}
                                    >
                                        <Table
                                            structured
                                            stackable
                                            color={meduskladisnica.isStornirana ? 'grey' : undefined}
                                            inverted={meduskladisnica.isStornirana}
                                        >
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.Cell style={styles.iconCell}>
                                                        <Icon name="dropdown" />
                                                    </Table.Cell>
                                                    <Table.Cell style={styles.brojMeduskladisniceCell}>
                                                        {meduskladisnica.isStornirana === null && meduskladisnica.rbrStorniraneMeduskladisnice
                                                            ? meduskladisnica.rbrMeduskladisnice +
                                                            ' - ' +
                                                            localization.meduskladisnice.stornoMeduskladisnice +
                                                            ': ' +
                                                            meduskladisnica.rbrStorniraneMeduskladisnice
                                                            : meduskladisnica.rbrMeduskladisnice}
                                                    </Table.Cell>
                                                    <Table.Cell style={styles.mjestoCell}>{meduskladisnica.src.naziv}</Table.Cell>
                                                    <Table.Cell style={styles.mjestoCell}>{meduskladisnica.dest.naziv}</Table.Cell>
                                                    <Table.Cell>{moment(meduskladisnica.datumIVrijeme).format('DD.MM.YYYY. HH:mm')}</Table.Cell>
                                                    <Table.Cell style={styles.isIzvrsenaCell}>
                                                        {meduskladisnica.isIzvrsena ? localization.common.da : localization.common.ne}
                                                    </Table.Cell>
                                                </Table.Row>
                                            </Table.Header>
                                        </Table>
                                    </Accordion.Title>
                                    <Accordion.Content active={this.state.activeMeduskladisnice.includes(meduskladisnica.id)}>
                                        <MeduskladisnicaContent
                                            artikli={meduskladisnica.stavke.map(ss => {
                                                return Object.assign({}, ss, ss.predmet, {
                                                    kolicina: meduskladisnica.rbrStorniraneMeduskladisnice === null ? ss.kolicina : -ss.kolicina
                                                });
                                            })}
                                        />
                                        <div style={styles.actionButtons}>
                                            <Button
                                                icon="print"
                                                color="green"
                                                content={localization.meduskladisnice.ispisMeduskladisnice}
                                                onClick={this.ispisMeduskladisnice.bind(this, meduskladisnica)}
                                            />
                                            {!meduskladisnica.isIzvrsena ? (
                                                <Modal
                                                    open={
                                                        this.state.activePotvrda &&
                                                        this.state.activePotvrda.type === potvrdeEnums.izvrsavanjeMeduskladisnice &&
                                                        this.state.activePotvrda.id === meduskladisnica.id
                                                    }
                                                    closeOnDimmerClick={false}
                                                    trigger={
                                                        <Button
                                                            icon={icons.meduskladisnica}
                                                            color="green"
                                                            content={localization.meduskladisnice.izvrsavanjeMeduskladisnice}
                                                            onClick={() => this.showModal(potvrdeEnums.izvrsavanjeMeduskladisnice, meduskladisnica.id)}
                                                        />
                                                    }
                                                >
                                                    <Header icon={icons.meduskladisnica} content={localization.common.potvrda} />
                                                    <Modal.Content>
                                                        <p>{localization.meduskladisnice.izvrsavanjeMeduskladisnicePitanjePotvrde}</p>
                                                    </Modal.Content>
                                                    <Modal.Actions>
                                                        <Button
                                                            icon="remove"
                                                            content={localization.common.odustani}
                                                            onClick={() => this.hideModal()}
                                                        />
                                                        <Button
                                                            icon="copy"
                                                            color="green"
                                                            content={localization.meduskladisnice.izvrsiMeduskladisnicu}
                                                            onClick={() => this.izvrsiMeduskladisnicu(meduskladisnica.id)}
                                                        />
                                                    </Modal.Actions>
                                                </Modal>
                                            ) : null}
                                            {!meduskladisnica.isStornirana && !meduskladisnica.rbrStorniraneMeduskladisnice ? (
                                                <Modal
                                                    open={
                                                        this.state.activePotvrda &&
                                                        this.state.activePotvrda.type === potvrdeEnums.storniranjeMeduskladisnice &&
                                                        this.state.activePotvrda.id === meduskladisnica.id
                                                    }
                                                    closeOnDimmerClick={false}
                                                    trigger={
                                                        <Button
                                                            icon="undo"
                                                            color="red"
                                                            content={localization.meduskladisnice.storniranjeMeduskladisnice}
                                                            onClick={() => this.showModal(potvrdeEnums.storniranjeMeduskladisnice, meduskladisnica.id)}
                                                        />
                                                    }
                                                >
                                                    <Header icon="undo" content={localization.common.potvrda} />
                                                    <Modal.Content>
                                                        <p>{localization.meduskladisnice.storniranjePitanjePotvrde}</p>
                                                    </Modal.Content>
                                                    <Modal.Actions>
                                                        <Button
                                                            icon="remove"
                                                            content={localization.common.odustani}
                                                            onClick={() => this.hideModal()}
                                                        />
                                                        <Button
                                                            icon="copy"
                                                            color="red"
                                                            content={localization.meduskladisnice.storniraj}
                                                            onClick={() => this.stornoMeduskladisnice(meduskladisnica.id)}
                                                        />
                                                    </Modal.Actions>
                                                </Modal>
                                            ) : null}
                                        </div>
                                        {meduskladisnica.sluzbenaNapomena ? (
                                            <Message>
                                                <Message.Header>{localization.common.sluzbenaNapomena}</Message.Header>
                                                <p>{meduskladisnica.sluzbenaNapomena}</p>
                                            </Message>
                                        ) : null}
                                        <Accordion fluid styled>
                                            <Accordion.Title
                                                active={this.state.activeComments.includes(meduskladisnica.id)}
                                                index={meduskladisnica.id}
                                                onClick={this.expandComments}
                                            >
                                                <Header as="h5" dividing style={styles.accordionHeaderContainer}>
                                                    <Icon name="dropdown" />
                                                    {localization.common.napomene}
                                                    {meduskladisnica.nesluzbeneNapomene.length ? (
                                                        <Icon name="exclamation circle" color="red" style={styles.semaforStyle} />
                                                    ) : null}
                                                </Header>
                                            </Accordion.Title>
                                            <Accordion.Content active={this.state.activeComments.includes(meduskladisnica.id)}>
                                                <Diskusija
                                                    napomene={meduskladisnica.nesluzbeneNapomene}
                                                    textAreaValue={
                                                        this.state.noveNapomene.find(el => el.id === meduskladisnica.id)
                                                            ? this.state.noveNapomene.find(el => el.id === meduskladisnica.id).napomena
                                                            : ''
                                                    }
                                                    onChangeNovaNapomena={this.onChangeNovaNapomena.bind(this, meduskladisnica.id)}
                                                    onDodajNapomenu={() => this.dodajNapomenu(meduskladisnica.id)}
                                                />
                                            </Accordion.Content>
                                        </Accordion>
                                    </Accordion.Content>
                                </Accordion>
                            </Grid.Column>
                        </Grid.Row>
                    );
                })}
                <Grid.Row centered>
                    <Pagination
                        activePage={this.state.pageActive}
                        totalPages={this.state.pages}
                        onPageChange={this.onChangePage}
                        ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
                        firstItem={{ content: <Icon name="angle double left" />, icon: true }}
                        lastItem={{ content: <Icon name="angle double right" />, icon: true }}
                        prevItem={{ content: <Icon name="angle left" />, icon: true }}
                        nextItem={{ content: <Icon name="angle right" />, icon: true }}
                    />
                </Grid.Row>
            </React.Fragment>
        );
    }

    render() {
        return (
            <div>
                <Segment tertiary>
                    <Grid stackable>
                        <Grid.Row columns={5}>
                            <Grid.Column verticalAlign="middle">
                                <Header as="h2">{localization.meduskladisnice.meduskladisnice}</Header>
                            </Grid.Column>
                            <Grid.Column verticalAlign="middle">
                                <Search
                                    input={{ fluid: true }}
                                    loading={this.state.isSearchLoading}
                                    onSearchChange={this.handleSearchChange}
                                    value={this.state.value}
                                    open={false}
                                />
                            </Grid.Column>
                            {sessionStorage.userRole === enums.tipKorisnika.DJELATNIK ? (
                                <React.Fragment>
                                    <Grid.Column />
                                    <Grid.Column />
                                    <Grid.Column textAlign="right" verticalAlign="middle">
                                        <Button
                                            icon="asterisk"
                                            basic
                                            color="black"
                                            content={localization.meduskladisnice.novaMeduskladisnica}
                                            onClick={this.onNovaMeduskladisnica}
                                        />
                                    </Grid.Column>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <Grid.Column />
                                    <Grid.Column />
                                    <Grid.Column textAlign="right" verticalAlign="middle">
                                        <FilterSortButton
                                            buttonName={localization.common.filtriranje}
                                            filteringOptions={this.FILTER_ITEMS.map((f) => f.name)}
                                            activeFilter={this.state.filter}
                                            callback={this.applyFilterAndSort}
                                        />
                                    </Grid.Column>
                                </React.Fragment>
                            )}
                        </Grid.Row>
                        {(() => {
                            switch (this.state.listState) {
                                case LIST_STATE.initial:
                                    return this.renderInitialComponent();
                                case LIST_STATE.populated:
                                    return this.renderListComponent();
                                case LIST_STATE.empty:
                                    return this.renderEmptyComponent();
                                case LIST_STATE.noResult:
                                    return this.renderNoResultComponent();
                                case LIST_STATE.error:
                                    return this.renderErrorComponent();
                            }
                        })()}
                    </Grid>
                </Segment>
                <Dimmer active={this.state.loader} page>
                    <Loader />
                </Dimmer>
            </div>
        );
    }
}

Meduskladisnice.propTypes = {
    history: PropTypes.object.isRequired,
    poslovnice: PropTypes.array.isRequired,
    meduskladisniceDataAccess: PropTypes.object.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {
        poslovnice: state.mjestaReducer.poslovnice || []
    };
};

let mapDispatchToProps = function(dispatch) {
    return {
        meduskladisniceDataAccess: bindActionCreators(MeduskladisniceDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Meduskladisnice);
