import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form } from 'semantic-ui-react';

import localization from '../../locales/localization';
import * as FirmaDataAccess from '../../data_access/FirmaDataAccess';
import InputField from './InputField';

const styles = {
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
};

let validateOIBLength = oib => {
    return oib.length !== 11;
};

class Firma extends React.Component {
    constructor(props) {
        super(props);
        this.composeFirmaObject = this.composeFirmaObject.bind(this);
        this.state = {
            firma: this.composeFirmaObject(this.props.firma),
            enableSaveButton: false
        };
        this.onReset = this.onReset.bind(this);
        this.onChange = this.onChange.bind(this);
        this.enableSaveButton = this.enableSaveButton.bind(this);
        this.checkFirmaRequiredFields = this.checkFirmaRequiredFields.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            firma: Object.assign({}, this.state.firma, this.composeFirmaObject(nextProps.firma)),
            enableSaveButton: false
        });
    }

    onReset() {
        this.setState({
            firma: Object.assign({}, this.state.firma, this.composeFirmaObject(this.props.firma)),
            enableSaveButton: false
        });
    }

    composeFirmaObject(props) {
        return {
            ime: props.ime,
            OIB: props.oib,
            sjedisteAdresa: props.sjedisteAdresa,
            sjedistePostanskiBroj: props.sjedistePostanskiBroj,
            sjedisteNaselje: props.sjedisteNaselje,
            oFirmi: props.oFirmi,
            amountToFixateFiskalnuBlagajnu: props.amountToFixateFiskalnuBlagajnu,
            maxPologFirme: props.maxPologFirme,
            numberOfPoslovnica: props.numberOfPoslovnica,
            uSustavuPDVa: props.usustavuPDVa
        };
    }

    onChange(e, { name, value, checked }) {
        if (value === undefined && checked !== undefined) {
            value = checked;
        }
        this.setState(
            {
                firma: Object.assign({}, this.state.firma, { [name]: value })
            },
            this.enableSaveButton
        );
    }

    enableSaveButton() {
        this.setState({
            enableSaveButton: this.checkFirmaRequiredFields()
        });
    }

    checkFirmaRequiredFields() {
        if (
            !this.state.firma.ime ||
            validateOIBLength(this.state.firma.OIB) ||
            !this.state.firma.sjedisteAdresa ||
            !this.state.firma.sjedistePostanskiBroj ||
            !this.state.firma.oFirmi ||
            !this.state.firma.amountToFixateFiskalnuBlagajnu ||
            !this.state.firma.maxPologFirme ||
            !this.state.firma.numberOfPoslovnica
        ) {
            return false;
        }
        return true;
    }

    onSave() {
        this.props.firmaDataAccess.createOrUpdateFirma(this.state.firma);
    }

    render() {
        return (
            <Form>
                <Form.Input fluid label={localization.postavke.imeFirme} name="ime" onChange={this.onChange} value={this.state.firma.ime} required />
                <Form.Input fluid label={localization.postavke.oibFirme} name="OIB" onChange={this.onChange} value={this.state.firma.OIB} required />
                <Form.Input
                    fluid
                    label={localization.postavke.adresaSjedista}
                    name="sjedisteAdresa"
                    onChange={this.onChange}
                    value={this.state.firma.sjedisteAdresa}
                    required
                />
                <Form.Input
                    fluid
                    label={localization.postavke.postanskiBrojSjedista}
                    name="sjedistePostanskiBroj"
                    onChange={this.onChange}
                    value={this.state.firma.sjedistePostanskiBroj}
                    required
                />
                <Form.Input
                    fluid
                    label={localization.postavke.naseljeSjedista}
                    name="sjedisteNaselje"
                    onChange={this.onChange}
                    value={this.state.firma.sjedisteNaselje}
                    required
                />
                <Form.Input fluid label={localization.postavke.oFirmi} name="oFirmi" onChange={this.onChange} value={this.state.firma.oFirmi} required />
                <Form.Field required>
                    <label>{localization.postavke.brojPoslovnica}</label>
                    <InputField
                        label={''}
                        placeholder={''}
                        name="numberOfPoslovnica"
                        value={this.state.firma.numberOfPoslovnica}
                        onChange={this.onChange}
                        isCjelobrojno={true}
                    />
                </Form.Field>
                <Form.Field required>
                    <label>{localization.postavke.iznosFiskalneBlagajne}</label>
                    <InputField
                        label={localization.common.eur}
                        placeholder={localization.common.eur}
                        name="amountToFixateFiskalnuBlagajnu"
                        value={this.state.firma.amountToFixateFiskalnuBlagajnu}
                        onChange={this.onChange}
                        isCjelobrojno={true}
                    />
                </Form.Field>
                <Form.Field required>
                    <label>{localization.postavke.maksimalniPolog}</label>
                    <InputField
                        label={localization.common.eur}
                        placeholder={localization.common.eur}
                        name="maxPologFirme"
                        value={this.state.firma.maxPologFirme}
                        onChange={this.onChange}
                        isCjelobrojno={true}
                    />
                </Form.Field>
                <Form.Checkbox
                    label={localization.postavke.uSustavuPDVa}
                    name="uSustavuPDVa"
                    checked={this.state.firma.uSustavuPDVa}
                    onChange={this.onChange}
                />
                <Form.Group style={styles.buttons}>
                    <Form.Button icon="cancel" content={localization.common.vratiNaSpremljeno} onClick={this.onReset} />
                    <Form.Button icon="save" color="green" disabled={!this.state.enableSaveButton} content={localization.common.spremi} onClick={this.onSave} />
                </Form.Group>
            </Form>
        );
    }
}

Firma.propTypes = {
    firma: PropTypes.object.isRequired,
    poslovnice: PropTypes.array.isRequired,
    mjesta: PropTypes.array.isRequired,
    firmaDataAccess: PropTypes.object.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {};
};

let mapDispatchToProps = function(dispatch) {
    return {
        firmaDataAccess: bindActionCreators(FirmaDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Firma);
