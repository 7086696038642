import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Checkbox, Dimmer, Grid, Header, Loader, Modal, Segment, Select } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

import localization from '../../locales/localization';
import endpoints from '../../environments/endpoints';
import enums from '../../utils/Enums';
import icons from '../../utils/DefinedIcons';
import { storeDocumentAndGenerateKey } from '../../utils/PrintingDocumentsHandler';
import * as ProdajaDataAccess from '../../data_access/ProdajaDataAccess';
import { bindActionCreators } from 'redux';
import OdabirKlijenta from '../common_components/OdabirKlijenta';
import OdabirArtiklaPaginated from '../common_components/OdabirArtiklaPaginated';
import Napomene from '../common_components/Napomene';
import InputField from '../common_components/InputField';
import FormatIznosa from '../common_components/FormatIznosa';
import RequiredText from '../common_components/RequiredText';
import * as StavkeZaProdajuApi from '../../data_access/StavkeZaProdajuApi';

const styles = {
    checkbox: {
        marginLeft: 24,
        marginRight: 24
    },
    confirmButton: {
        textAlign: 'center',
        marginBottom: 16
    },
    dualnoInput: {
        paddingRight: 12
    }
};

const racuni = [
    {
        tip: enums.nacinPlacanja.GOTOVINSKI_RACUN,
        naziv: localization.prodaja.gotovinskiRacun
    },
    {
        tip: enums.nacinPlacanja.KARTICNI_RACUN,
        naziv: localization.prodaja.karticniRacun
    },
    {
        tip: enums.nacinPlacanja.TRANSAKCIJSKI_RACUN,
        naziv: localization.prodaja.transakcijskiRacun
    },
    {
        tip: enums.nacinPlacanja.DUALNI_RACUN,
        naziv: localization.prodaja.dualniRacun
    }
];

const tipKlijenta = [
    {
        tip: enums.tipKlijentaNaRacunu.PRIVATNA_OSOBA,
        naziv: localization.prodaja.privatnaOsoba
    },
    {
        tip: enums.tipKlijentaNaRacunu.FIZICKA_OSOBA,
        naziv: localization.prodaja.fizickaOsoba
    },
    {
        tip: enums.tipKlijentaNaRacunu.PRAVNA_OSOBA,
        naziv: localization.prodaja.pravnaOsoba
    }
];

const tipKlijentaTransakcijski = [
    {
        tip: enums.tipKlijentaNaRacunu.FIZICKA_OSOBA,
        naziv: localization.prodaja.fizickaOsoba
    },
    {
        tip: enums.tipKlijentaNaRacunu.PRAVNA_OSOBA,
        naziv: localization.prodaja.pravnaOsoba
    }
];

const GRANICNI_IZNOS_INV_ZLATA = 0; // BILO JE 15000 kn (2000 eur) ali je receno da je uvijek potreban upis

class NoviRacun extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isPredracun: this.props.isPredracun || false,
            client: undefined,
            items: [],
            racun: {
                nacinPlacanja: racuni[0].tip,
                tipKlijenta: tipKlijenta[0].tip,
                kartica: null,
                brojRata: 1,
                dualnoNaKarticu: 0,
                ukupno: undefined
            },
            predracun: {
                datumIstekaPredracuna: moment().add(7, 'days')
            },
            sluzbenaNapomena: '',
            nesluzbenaNapomena: '',
            obveznaNesluzbenaNapomena: false,
            showNapomenaInvZlato: false,
            napraviRacunPredracunEnabled: false,
            potvrdaRacuna: false,
            loader: false
        };
        this.onChangeRacunPredracun = this.onChangeRacunPredracun.bind(this);
        this.onChangeTipRacuna = this.onChangeTipRacuna.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleOdabirKlijenta = this.handleOdabirKlijenta.bind(this);
        this.handleOdabirArtikla = this.handleOdabirArtikla.bind(this);
        this.setUkupnaCijenaINapomena = this.setUkupnaCijenaINapomena.bind(this);
        this.onChangeDatumIstekaPredracuna = this.onChangeDatumIstekaPredracuna.bind(this);
        this.onChangeNapomena = this.onChangeNapomena.bind(this);
        this.onChangeDualnoNaKarticu = this.onChangeDualnoNaKarticu.bind(this);
        this.calculateProdajnaCijena = this.calculateProdajnaCijena.bind(this);
        this.enableNapraviRacunButton = this.enableNapraviRacunButton.bind(this);
        this.checkKomponenteProdaje = this.checkKomponenteProdaje.bind(this);
        this.showHidePotvrda = this.showHidePotvrda.bind(this);
        this.showLoader = this.showLoader.bind(this);
        this.hideLoader = this.hideLoader.bind(this);
        this.napraviRacun = this.napraviRacun.bind(this);
        this.openRacun = this.openRacun.bind(this);
        this.openPredracun = this.openPredracun.bind(this);
        this.getTypeOfStavkaProdaje = this.getTypeOfStavkaProdaje.bind(this);
        this.stavkaProdajeToOdabirArtiklaItem = this.stavkaProdajeToOdabirArtiklaItem.bind(this);
        this.fetchOdabirArtiklaItems = this.fetchOdabirArtiklaItems.bind(this);
    }

    // TODO @Kec #pagination #refactor Should probably be moved into some sort of common utils file for stavke prodaje
    getTypeOfStavkaProdaje(stavka) {
        switch (stavka.tip) {
            case 'PREDMET':
                return enums.tipArtikla.PREDMET;
            case 'USLUGA':
                return enums.tipArtikla.USLUGA;
            default:
                throw new Error("Unhandled tip stavke prodaje " + stavka.tip + ".");
        }
    }

    // TODO @Kec #pagination #refactor Should probably be moved into some sort of common utils file for stavke prodaje
    stavkaProdajeToOdabirArtiklaItem(stavkaProdaje) {
        let type = this.getTypeOfStavkaProdaje(stavkaProdaje);
        switch (type) {
            case enums.tipArtikla.PREDMET:
                return Object.assign({}, stavkaProdaje, stavkaProdaje.artikl, {
                    maxKolicina: stavkaProdaje.kolicina,
                    kolicina: stavkaProdaje.kolicina >= 1 ? 1 : stavkaProdaje.kolicina,
                    cijena: this.calculateProdajnaCijena(stavkaProdaje.jedProdajnaCijena)
                });
            case enums.tipArtikla.USLUGA:
                return Object.assign({}, stavkaProdaje.artikl, {
                    maxKolicina: '∞',
                    kolicina: 1,
                    serijskiBroj: '-',
                    kategorija: { ime: enums.tipArtikla.USLUGA },
                    cijena: this.calculateProdajnaCijena(stavkaProdaje.jedCijena)
                });
            default:
                throw new Error("Unhandled tip artikla " + type + ".");
        }
    }

    fetchOdabirArtiklaItems(q, page, pageSize, onFetchSuccess, onFetchError) {
        let poslovnicaId = JSON.parse(sessionStorage.poslovnicaId);
        StavkeZaProdajuApi.getStavkeZaProdajuPaginated(q, poslovnicaId, page, pageSize,
            (data) => {
                let stavkeProdaje = data.items;
                let odabirArtiklaItem = stavkeProdaje.map(this.stavkaProdajeToOdabirArtiklaItem);
                onFetchSuccess(Object.assign({}, data, {
                    items: odabirArtiklaItem
                }));
            },
            onFetchError
        );
    }

    onChangeRacunPredracun(e, { checked }) {
        this.setState(
            {
                isPredracun: checked
            },
            this.enableNapraviRacunButton
        );
    }

    onChangeTipRacuna(e, { value }) {
        if (value === enums.nacinPlacanja.TRANSAKCIJSKI_RACUN) {
            this.setState(
                {
                    racun: Object.assign({}, this.state.racun, {
                        nacinPlacanja: value,
                        tipKlijenta: enums.tipKlijentaNaRacunu.PRAVNA_OSOBA
                    })
                },
                this.setUkupnaCijenaINapomena
            );
        } else {
            this.setState(
                {
                    racun: Object.assign({}, this.state.racun, {
                        nacinPlacanja: value,
                        tipKlijenta: enums.tipKlijentaNaRacunu.PRIVATNA_OSOBA,
                        kartica: null
                    })
                },
                this.setUkupnaCijenaINapomena
            );
        }
    }

    onChange(e, { name, value }) {
        this.setState(
            {
                racun: Object.assign({}, this.state.racun, { [name]: value })
            },
            this.enableNapraviRacunButton
        );
    }

    handleOdabirKlijenta(clientSelected) {
        this.setState({ client: clientSelected }, this.setUkupnaCijenaINapomena);
    }

    handleOdabirArtikla(itemsSelected) {
        this.setState({ items: [...itemsSelected] }, this.setUkupnaCijenaINapomena);
    }

    setUkupnaCijenaINapomena() {
        let ukupno = 0,
            ukupnoInvZlato = 0,
            missingData = false,
            obveznaNesluzbenaNapomena = false;
        this.state.items.forEach(item => {
            if (item.kolicina && item.cijena) {
                ukupno += item.kolicina * item.cijena;
                if (item.kategorija.tipKategorije === enums.tipKategorije.INV_ZLATO) {
                    ukupnoInvZlato += item.kolicina * item.cijena;
                }
            } else {
                missingData = true;
            }
            if (!this.state.nesluzbenaNapomena) {
                if (item.tipArtikla === enums.tipArtikla.PREDMET) {
                    if (
                        this.state.racun.nacinPlacanja === enums.nacinPlacanja.KARTICNI_RACUN ||
                        this.state.racun.nacinPlacanja === enums.nacinPlacanja.DUALNI_RACUN
                    ) {
                        if (item.cijena != item.jedProdajnaCijena * (1 + this.props.appPrefs.postotakZaKarticnoPlacanje / 100)) {
                            obveznaNesluzbenaNapomena = true;
                        }
                    } else {
                        if (item.cijena != item.jedProdajnaCijena) {
                            obveznaNesluzbenaNapomena = true;
                        }
                    }
                } else {
                    if (
                        this.state.racun.nacinPlacanja === enums.nacinPlacanja.KARTICNI_RACUN ||
                        this.state.racun.nacinPlacanja === enums.nacinPlacanja.DUALNI_RACUN
                    ) {
                        if (item.cijena != item.jedCijena * (1 + this.props.appPrefs.postotakZaKarticnoPlacanje / 100)) {
                            obveznaNesluzbenaNapomena = true;
                        }
                    } else {
                        if (item.cijena != item.jedCijena) {
                            obveznaNesluzbenaNapomena = true;
                        }
                    }
                }
            }
        });
        this.setState(
            {
                racun: Object.assign({}, this.state.racun, {
                    ukupno: !missingData ? ukupno : undefined
                }),
                obveznaNesluzbenaNapomena: obveznaNesluzbenaNapomena,
                showNapomenaInvZlato: ukupnoInvZlato > GRANICNI_IZNOS_INV_ZLATA && !this.state.client
            },
            this.enableNapraviRacunButton
        );
    }

    onChangeDatumIstekaPredracuna(date) {
        this.setState(
            {
                predracun: Object.assign({}, this.state.predracun, {
                    datumIstekaPredracuna: date
                })
            },
            this.enableNapraviRacunButton
        );
    }

    onChangeNapomena(e, { name, value }) {
        this.setState({ [name]: value }, this.setUkupnaCijenaINapomena);
    }

    onChangeDualnoNaKarticu(e, { value }) {
        this.setState({
            racun: Object.assign({}, this.state.racun, {
                dualnoNaKarticu: value
            })
        });
    }

    calculateProdajnaCijena(cijena) {
        if (this.state.racun.nacinPlacanja === enums.nacinPlacanja.KARTICNI_RACUN || this.state.racun.nacinPlacanja === enums.nacinPlacanja.DUALNI_RACUN) {
            return cijena * (1 + this.props.appPrefs.postotakZaKarticnoPlacanje / 100);
        }
        return cijena;
    }

    enableNapraviRacunButton() {
        this.setState({
            napraviRacunPredracunEnabled: this.checkKomponenteProdaje()
        });
    }

    checkKomponenteProdaje() {
        let checkItems = () => {
            if (!this.state.items.length) {
                return false;
            } else {
                for (let i = 0; i < this.state.items.length; i++) {
                    if (!this.state.items[i].kolicina || !this.state.items[i].cijena) {
                        return false;
                    }
                }
            }
            return true;
        };
        if (this.state.showNapomenaInvZlato) {
            return false;
        }
        if (this.state.obveznaNesluzbenaNapomena && !this.state.nesluzbenaNapomena) {
            return false;
        }
        if (this.state.isPredracun) {
            return this.state.client && this.state.predracun.datumIstekaPredracuna && checkItems();
        } else if (this.state.racun.nacinPlacanja === enums.nacinPlacanja.GOTOVINSKI_RACUN) {
            if (this.state.racun.tipKlijenta === enums.tipKlijentaNaRacunu.PRIVATNA_OSOBA) {
                return checkItems();
            } else {
                return this.state.client && checkItems();
            }
        } else if (this.state.racun.nacinPlacanja === enums.nacinPlacanja.KARTICNI_RACUN) {
            if (this.state.racun.tipKlijenta === enums.tipKlijentaNaRacunu.PRIVATNA_OSOBA) {
                return this.state.racun.kartica !== null && this.state.racun.brojRata && checkItems();
            } else {
                return this.state.racun.kartica !== null && this.state.racun.brojRata && this.state.client && checkItems();
            }
        } else if (this.state.racun.nacinPlacanja === enums.nacinPlacanja.TRANSAKCIJSKI_RACUN) {
            return this.state.client && checkItems();
        } else if (this.state.racun.nacinPlacanja === enums.nacinPlacanja.DUALNI_RACUN) {
            if (this.state.racun.tipKlijenta === enums.tipKlijentaNaRacunu.PRIVATNA_OSOBA) {
                return this.state.racun.kartica !== null && this.state.racun.brojRata && checkItems();
            } else {
                return this.state.racun.kartica !== null && this.state.racun.brojRata && this.state.client && checkItems();
            }
        }
        return true;
    }

    showHidePotvrda(value) {
        this.setState({
            potvrdaRacuna: value
        });
    }

    showLoader(callback) {
        this.setState(
            {
                loader: true
            },
            callback
        );
    }

    hideLoader(callback, data) {
        this.setState(
            {
                loader: false
            },
            callback.bind(this, data)
        );
    }

    napraviRacun() {
        let racun = {
            poslovnicaId: JSON.parse(sessionStorage.poslovnicaId),
            klijentId: this.state.client ? this.state.client.id : null,
            skladisniIzlazi: this.state.items.map(artikl => {
                return {
                    artiklId: artikl.id,
                    kolicina: parseFloat(artikl.kolicina),
                    jedCijena: parseFloat(artikl.cijena)
                };
            }),
            sluzbenaNapomena: this.state.sluzbenaNapomena || null,
            nesluzbenaNapomena: this.state.nesluzbenaNapomena || null
        };
        if (this.state.isPredracun) {
            racun.datumIstekaPredracuna = this.state.predracun.datumIstekaPredracuna.format('YYYY-MM-DD');
            racun.stavkePredracuna = racun.skladisniIzlazi;
            delete racun.skladisniIzlazi;
        } else if (this.state.racun.nacinPlacanja === enums.nacinPlacanja.TRANSAKCIJSKI_RACUN) {
            racun.tipRacuna = enums.tipRacuna.TRANSAKCIJSKI_RACUN;
        } else {
            racun.tipRacuna = enums.tipRacuna.FISKALNI_RACUN;
            if (this.state.racun.nacinPlacanja === enums.nacinPlacanja.GOTOVINSKI_RACUN) {
                racun.iznosGotovina = this.state.racun.ukupno;
                racun.karticnoPlacanje = null;
            } else if (this.state.racun.nacinPlacanja === enums.nacinPlacanja.KARTICNI_RACUN) {
                racun.iznosGotovina = null;
                racun.karticnoPlacanje = {
                    iznosKarticno: this.state.racun.ukupno,
                    karticaId: this.state.racun.kartica,
                    brojRata: this.state.racun.brojRata
                };
            } else {
                racun.iznosGotovina = this.state.racun.ukupno - this.state.racun.dualnoNaKarticu;
                racun.karticnoPlacanje = {
                    iznosKarticno: parseFloat(this.state.racun.dualnoNaKarticu),
                    karticaId: this.state.racun.kartica,
                    brojRata: this.state.racun.brojRata
                };
            }
        }
        if (!this.state.isPredracun) {
            this.props.prodajaDataAccess.createRacun(racun, this.hideLoader.bind(this, this.openRacun));
        } else {
            this.props.prodajaDataAccess.createPredracun(racun, this.hideLoader.bind(this, this.openPredracun));
        }
    }

    openRacun(racun) {
        window.open(endpoints.appEndpoints.print.racun + storeDocumentAndGenerateKey(racun), '_blank');
        racun.skladisniIzlazi.forEach(si => {
            if (si.isProdanoIspodNabavne) {
                window.open(
                    endpoints.appEndpoints.print.zapisnikOProdajiIspodNabavneCijene +
                        storeDocumentAndGenerateKey(
                            Object.assign(si, {
                                firma: racun.firma,
                                poslovnica: racun.poslovnica,
                                datumIVrijeme: racun.datumIVrijeme
                            })
                        ),
                    '_blank'
                );
            }
        });
        this.props.history.goBack();
    }

    openPredracun(predracun) {
        window.open(endpoints.appEndpoints.print.predracun + storeDocumentAndGenerateKey(predracun), '_blank');
        this.props.history.goBack();
    }

    render() {
        return (
            <div>
                <Segment tertiary>
                    <Header as="h2">
                        {!this.state.isPredracun ? localization.prodaja.noviRacun : localization.prodaja.noviPredracun}
                        <Checkbox
                            label={localization.prodaja.predracun}
                            style={styles.checkbox}
                            onChange={this.onChangeRacunPredracun}
                            checked={this.state.isPredracun}
                        />
                    </Header>
                </Segment>
                {!this.state.isPredracun ? (
                    <Segment tertiary>
                        <Grid stackable>
                            <Grid.Row columns={5} divided>
                                <Grid.Column verticalAlign="middle">
                                    <Header as="h3">{localization.prodaja.nacinPlacanja + ':'}</Header>
                                    <Select
                                        options={racuni.map(racun => {
                                            return { key: racun.tip, text: racun.naziv, value: racun.tip };
                                        })}
                                        value={this.state.racun.nacinPlacanja}
                                        onChange={this.onChangeTipRacuna}
                                        search
                                    />
                                </Grid.Column>
                                {this.state.racun.nacinPlacanja === enums.nacinPlacanja.KARTICNI_RACUN ||
                                this.state.racun.nacinPlacanja === enums.nacinPlacanja.DUALNI_RACUN ? (
                                    <React.Fragment>
                                        <Grid.Column verticalAlign="middle">
                                            <Header as="h3">{localization.prodaja.kartica + ':'}</Header>
                                            <Select
                                                options={this.props.kartice.map(card => {
                                                    return { key: card.id, text: card.naziv, value: card.id };
                                                })}
                                                name="kartica"
                                                search
                                                value={this.state.racun.kartica}
                                                onChange={this.onChange}
                                                placeholder={localization.prodaja.odaberiKarticu}
                                            />
                                        </Grid.Column>
                                        <Grid.Column verticalAlign="middle">
                                            <Header as="h3">{localization.prodaja.brojRata + ':'}</Header>
                                            <Select
                                                options={Array.from(new Array(24), (val, index) => {
                                                    if (index > 0) {
                                                        return { key: index, text: index + 1, value: index + 1 };
                                                    }
                                                    return { key: index, text: localization.prodaja.jednokratno, value: index + 1 };
                                                })}
                                                name="brojRata"
                                                search
                                                value={this.state.racun.brojRata}
                                                onChange={this.onChange}
                                                placeholder={localization.prodaja.odaberiBrojRata}
                                            />
                                        </Grid.Column>
                                    </React.Fragment>
                                ) : null}
                                <Grid.Column verticalAlign="middle">
                                    <Header as="h3">{localization.common.klijent + ':'}</Header>
                                    <Select
                                        options={(this.state.racun.nacinPlacanja !== enums.nacinPlacanja.TRANSAKCIJSKI_RACUN
                                            ? tipKlijenta
                                            : tipKlijentaTransakcijski
                                        ).map(klijent => {
                                            return { key: klijent.tip, text: klijent.naziv, value: klijent.tip };
                                        })}
                                        name="tipKlijenta"
                                        search
                                        value={this.state.racun.tipKlijenta}
                                        disabled={this.state.racun.tipKlijenta !== enums.tipKlijentaNaRacunu.PRIVATNA_OSOBA && this.state.client}
                                        onChange={this.onChange}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                ) : null}
                {this.state.isPredracun ||
                this.state.racun.tipKlijenta !== enums.tipKlijentaNaRacunu.PRIVATNA_OSOBA ||
                this.state.racun.nacinPlacanja === enums.nacinPlacanja.TRANSAKCIJSKI_RACUN ? (
                    <Segment tertiary>
                        <OdabirKlijenta
                            onClientSelected={this.handleOdabirKlijenta}
                            allowedClientType={
                                this.state.isPredracun
                                    ? undefined
                                    : this.state.racun.tipKlijenta === enums.tipKlijentaNaRacunu.PRAVNA_OSOBA
                                    ? enums.tipKlijenta.PRAVNA_OSOBA
                                    : enums.tipKlijenta.FIZICKA_OSOBA
                            }
                        />
                    </Segment>
                ) : null}
                <Segment tertiary>
                    <OdabirArtiklaPaginated
                        fetchArtikli={this.fetchOdabirArtiklaItems}
                        onItemSelected={this.handleOdabirArtikla}
                        type={enums.tipTabliceArtikala.RACUN_EDITABLE}
                        newItemDisabled={true}
                    />
                </Segment>
                {this.state.isPredracun ? (
                    <Segment tertiary>
                        <Grid stackable>
                            <Grid.Row columns={5}>
                                <Grid.Column verticalAlign="middle">
                                    <Header as="h3">{localization.prodaja.datumIstekaPredracuna}</Header>
                                </Grid.Column>
                                <Grid.Column verticalAlign="middle">
                                    <DatePicker
                                        customInput={
                                            <Button fluid compact basic color="black">
                                                {this.state.predracun.datumIstekaPredracuna.format('DD.MM.YYYY.')}
                                            </Button>
                                        }
                                        dateFormat="DD.MM.YYYY."
                                        locale="hr-HR"
                                        selected={this.state.predracun.datumIstekaPredracuna}
                                        onChange={this.onChangeDatumIstekaPredracuna}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                ) : null}
                <Napomene
                    sluzbenaNapomenaHeader={
                        !this.state.isPredracun ? localization.prodaja.sluzbenaNapomenaRacuna : localization.prodaja.sluzbenaNapomenaPredracuna
                    }
                    nesluzbenaNapomenaHeader={
                        !this.state.isPredracun ? localization.prodaja.nesluzbenaNapomenaRacuna : localization.prodaja.nesluzbenaNapomenaPredracuna
                    }
                    onChangeNapomena={this.onChangeNapomena}
                    required={this.state.obveznaNesluzbenaNapomena}
                    requiredText={localization.prodaja.obveznaNesluzbenaNapomena}
                />
                <div style={styles.confirmButton}>
                    <Modal
                        open={this.state.potvrdaRacuna}
                        closeOnDimmerClick={false}
                        trigger={
                            <Button
                                color="green"
                                content={!this.state.isPredracun ? localization.prodaja.napraviRacun : localization.prodaja.napraviPredracun}
                                disabled={!this.state.napraviRacunPredracunEnabled}
                                onClick={this.showHidePotvrda.bind(this, true)}
                            />
                        }
                    >
                        <Header
                            icon={icons.prodaja}
                            content={!this.state.isPredracun ? localization.prodaja.potvrdaRacuna : localization.prodaja.potvrdaPredracuna}
                        />
                        <Modal.Content>
                            {this.state.racun.nacinPlacanja !== enums.nacinPlacanja.DUALNI_RACUN ? (
                                <p>{!this.state.isPredracun ? localization.prodaja.pitanjePotvrdeRacuna : localization.prodaja.pitanjePotvrdePredracuna}</p>
                            ) : (
                                <div>
                                    <p>{localization.prodaja.dualniRacunPitanje}</p>
                                    <InputField
                                        label={localization.common.eur}
                                        placeholder={localization.prodaja.cijenaNaKarticu}
                                        name="cijena"
                                        value={this.state.racun.dualnoNaKarticu}
                                        onChange={this.onChangeDualnoNaKarticu}
                                        maxValue={this.state.racun.ukupno}
                                        style={styles.dualnoInput}
                                    />
                                    <span>
                                        {' / '}
                                        <b>
                                            <FormatIznosa value={this.state.racun.ukupno} />
                                        </b>
                                    </span>
                                    <br />
                                    <br />
                                    <p>{localization.prodaja.pitanjePotvrdeRacuna}</p>
                                </div>
                            )}
                        </Modal.Content>
                        <Modal.Actions>
                            <Button icon="remove" content={localization.common.odustani} onClick={this.showHidePotvrda.bind(this, false)} />
                            <Button
                                icon="checkmark"
                                color="green"
                                disabled={this.state.racun.nacinPlacanja === enums.nacinPlacanja.DUALNI_RACUN && this.state.racun.dualnoNaKarticu <= 0}
                                content={!this.state.isPredracun ? localization.prodaja.napraviRacun : localization.prodaja.napraviPredracun}
                                onClick={this.showLoader.bind(this, this.napraviRacun)}
                            />
                        </Modal.Actions>
                    </Modal>
                </div>
                {this.state.showNapomenaInvZlato ? (
                    <div style={styles.confirmButton}>
                        <RequiredText requiredText={localization.prodaja.napomenaInvesticijskoZlato} />
                    </div>
                ) : null}
                <Dimmer active={this.state.loader} page>
                    <Loader />
                </Dimmer>
            </div>
        );
    }
}

NoviRacun.propTypes = {
    history: PropTypes.object.isRequired,
    isPredracun: PropTypes.bool,
    appPrefs: PropTypes.object.isRequired,
    prodajaDataAccess: PropTypes.object.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {
        appPrefs: state.appPreferencesReducer.appPrefs,
        kartice: state.karticeReducer.kartice || []
    };
};

let mapDispatchToProps = function(dispatch) {
    return {
        prodajaDataAccess: bindActionCreators(ProdajaDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NoviRacun);
