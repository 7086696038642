import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'semantic-ui-react';
import localization from '../../../locales/localization';
import enums from '../../../utils/Enums';

import EditableRezervacijaOtkupHeaderCells from './headers/EditableRezervacijaOtkupHeaderCells';
import EditableRezervacijaImportHeaderCells from './headers/EditableRezervacijaImportHeaderCells';
import FixedRezervacijaOtkupHeaderCells from './headers/FixedRezervacijaOtkupHeaderCells';
import EditablePrimkaHeaderCells from './headers/EditablePrimkaHeaderCells';
import FixedPrimkaHeaderCells from './headers/FixedPrimkaHeaderCells';
import EditableRacunHeaderCells from './headers/EditableRacunHeaderCells';
import FixedRacunHeaderCells from './headers/FixedRacunHeaderCells';
import EditableIzdatnicaHeaderCells from './headers/EditableIzdatnicaHeaderCells';
import FixedIzdatnicaHeaderCells from './headers/FixedIzdatnicaHeaderCells';
import ProcjenaHeaderCells from './headers/ProcjenaHeaderCells';
import KlijentovPredmetHeaderCells from './headers/KlijentovPredmetHeaderCells';
import EditableMeduskladisnicaHeaderCells from './headers/EditableMeduskladisnicaHeaderCells';
import FixedMeduskladisnicaHeaderCells from './headers/FixedMeduskladisnicaHeaderCells';

import EditablePredmetBodyCells from './bodies/EditablePredmetBodyCells';
import EditableRezervacijaImportBodyCells from './bodies/EditableRezervacijaImportBodyCells';
import FixedOtkupBodyCells from './bodies/FixedOtkupBodyCells';
import EditableOtkupBodyCells from './bodies/EditableOtkupBodyCells';
import FixedPrimkaBodyCells from './bodies/FixedPrimkaBodyCells';
import EditablePrimkaBodyCells from './bodies/EditablePrimkaBodyCells';
import FixedRacunBodyCells from './bodies/FixedRacunBodyCells';
import EditableRacunBodyCells from './bodies/EditableRacunBodyCells';
import EditableIzdatnicaBodyCells from './bodies/EditableIzdatnicaBodyCells';
import FixedIzdatnicaBodyCells from './bodies/FixedIzdatnicaBodyCells';
import FixedKlijentovPredmetBodyCells from './bodies/FixedKlijentovPredmetBodyCells';
import FixedMeduskladisnicaBodyCells from './bodies/FixedMeduskladisnicaBodyCells';
import EditableMeduskladisnicaBodyCells from './bodies/EditableMeduskladisnicaBodyCells';

import FooterCells from './footers/FooterCells';

class TablicaArtikala extends React.Component {
    constructor(props) {
        super(props);
        this.detectHeaderCells = this.detectHeaderCells.bind(this);
        this.detectBodyCells = this.detectBodyCells.bind(this);
        this.detectFooterCells = this.detectFooterCells.bind(this);
        this.calculateUkupno = this.calculateUkupno.bind(this);
    }

    detectHeaderCells() {
        switch (this.props.type) {
            case enums.tipTabliceArtikala.REZERVACIJA_EDITABLE: {
                return <EditableRezervacijaOtkupHeaderCells />;
            }
            case enums.tipTabliceArtikala.REZERVACIJA_IMPORT: {
                return <EditableRezervacijaImportHeaderCells />;
            }
            case enums.tipTabliceArtikala.OTKUP_FIXED: {
                return <FixedRezervacijaOtkupHeaderCells />;
            }
            case enums.tipTabliceArtikala.OTKUP_EDITABLE: {
                return <EditableRezervacijaOtkupHeaderCells />;
            }
            case enums.tipTabliceArtikala.PRIMKA_FIXED: {
                return <FixedPrimkaHeaderCells />;
            }
            case enums.tipTabliceArtikala.PRIMKA_EDITABLE: {
                return <EditablePrimkaHeaderCells />;
            }
            case enums.tipTabliceArtikala.RACUN_FIXED: {
                return <FixedRacunHeaderCells />;
            }
            case enums.tipTabliceArtikala.RACUN_EDITABLE: {
                return <EditableRacunHeaderCells />;
            }
            case enums.tipTabliceArtikala.IZDATNICA_EDITABLE: {
                return <EditableIzdatnicaHeaderCells />;
            }
            case enums.tipTabliceArtikala.IZDATNICA_FIXED: {
                return <FixedIzdatnicaHeaderCells />;
            }
            case enums.tipTabliceArtikala.PROCJENA_EDITABLE: {
                return <ProcjenaHeaderCells />;
            }
            case enums.tipTabliceArtikala.RADNINALOG_FIXED: {
                return <KlijentovPredmetHeaderCells />;
            }
            case enums.tipTabliceArtikala.RADNINALOG_EDITABLE: {
                return <KlijentovPredmetHeaderCells />;
            }
            case enums.tipTabliceArtikala.MEDUSKLADISNICA_FIXED: {
                return <FixedMeduskladisnicaHeaderCells />;
            }
            case enums.tipTabliceArtikala.MEDUSKLADISNICA_EDITABLE: {
                return <EditableMeduskladisnicaHeaderCells />;
            }
        }
    }

    detectBodyCells(cellsProps) {
        switch (this.props.type) {
            case enums.tipTabliceArtikala.REZERVACIJA_EDITABLE: {
                return <EditablePredmetBodyCells {...cellsProps} />;
            }
            case enums.tipTabliceArtikala.REZERVACIJA_IMPORT: {
                return <EditableRezervacijaImportBodyCells {...cellsProps} />;
            }
            case enums.tipTabliceArtikala.OTKUP_FIXED: {
                return <FixedOtkupBodyCells {...cellsProps} />;
            }
            case enums.tipTabliceArtikala.OTKUP_EDITABLE: {
                return <EditableOtkupBodyCells {...cellsProps} />;
            }
            case enums.tipTabliceArtikala.PRIMKA_FIXED: {
                return <FixedPrimkaBodyCells {...cellsProps} />;
            }
            case enums.tipTabliceArtikala.PRIMKA_EDITABLE: {
                return <EditablePrimkaBodyCells {...cellsProps} />;
            }
            case enums.tipTabliceArtikala.RACUN_FIXED: {
                return <FixedRacunBodyCells {...cellsProps} />;
            }
            case enums.tipTabliceArtikala.RACUN_EDITABLE: {
                return <EditableRacunBodyCells {...cellsProps} />;
            }
            case enums.tipTabliceArtikala.IZDATNICA_EDITABLE: {
                return <EditableIzdatnicaBodyCells {...cellsProps} />;
            }
            case enums.tipTabliceArtikala.IZDATNICA_FIXED: {
                return <FixedIzdatnicaBodyCells {...cellsProps} />;
            }
            case enums.tipTabliceArtikala.PROCJENA_EDITABLE: {
                return <EditablePredmetBodyCells {...cellsProps} />;
            }
            case enums.tipTabliceArtikala.RADNINALOG_FIXED: {
                return <FixedKlijentovPredmetBodyCells {...cellsProps} />;
            }
            case enums.tipTabliceArtikala.RADNINALOG_EDITABLE: {
                return <EditablePredmetBodyCells {...cellsProps} />;
            }
            case enums.tipTabliceArtikala.MEDUSKLADISNICA_FIXED: {
                return <FixedMeduskladisnicaBodyCells {...cellsProps} />;
            }
            case enums.tipTabliceArtikala.MEDUSKLADISNICA_EDITABLE: {
                return <EditableMeduskladisnicaBodyCells {...cellsProps} />;
            }
        }
    }

    detectFooterCells(ukupno) {
        switch (this.props.type) {
            case enums.tipTabliceArtikala.REZERVACIJA_EDITABLE: {
                return <FooterCells ukupno={ukupno} numOfColsMerged={5} />;
            }
            case enums.tipTabliceArtikala.REZERVACIJA_IMPORT: {
                return null;
            }
            case enums.tipTabliceArtikala.OTKUP_FIXED: {
                return <FooterCells ukupno={ukupno} numOfColsMerged={sessionStorage.userRole === enums.tipKorisnika.VLASNIK ? 5 : 3} />;
            }
            case enums.tipTabliceArtikala.OTKUP_EDITABLE: {
                return <FooterCells ukupno={ukupno} numOfColsMerged={5} />;
            }
            case enums.tipTabliceArtikala.PRIMKA_FIXED: {
                return <FooterCells ukupno={ukupno} numOfColsMerged={sessionStorage.userRole === enums.tipKorisnika.VLASNIK ? 6 : 4} />;
            }
            case enums.tipTabliceArtikala.PRIMKA_EDITABLE: {
                return <FooterCells ukupno={ukupno} numOfColsMerged={6} />;
            }
            case enums.tipTabliceArtikala.RACUN_FIXED: {
                return <FooterCells ukupno={ukupno} numOfColsMerged={4} />;
            }
            case enums.tipTabliceArtikala.RACUN_EDITABLE: {
                return <FooterCells ukupno={ukupno} numOfColsMerged={6} />;
            }
            case enums.tipTabliceArtikala.IZDATNICA_EDITABLE: {
                return <FooterCells ukupno={ukupno} numOfColsMerged={6} />;
            }
            case enums.tipTabliceArtikala.IZDATNICA_FIXED: {
                return <FooterCells ukupno={ukupno} numOfColsMerged={4} />;
            }
            case enums.tipTabliceArtikala.PROCJENA_EDITABLE: {
                return <FooterCells ukupno={ukupno} numOfColsMerged={5} />;
            }
            case enums.tipTabliceArtikala.RADNINALOG_FIXED: {
                return <FooterCells ukupno={ukupno} numOfColsMerged={5} />;
            }
            case enums.tipTabliceArtikala.RADNINALOG_EDITABLE: {
                return <FooterCells ukupno={ukupno} numOfColsMerged={5} />;
            }
            case enums.tipTabliceArtikala.MEDUSKLADISNICA_FIXED: {
                return null;
            }
            case enums.tipTabliceArtikala.MEDUSKLADISNICA_EDITABLE: {
                return null;
            }
        }
    }

    calculateUkupno() {
        let ukupno = 0;
        this.props.artikli.forEach(item => {
            ukupno += item.kolicina * item.cijena;
        });
        return ukupno;
    }

    render() {
        let footer;
        if (this.props.showFooter) {
            footer = this.detectFooterCells(this.calculateUkupno());
        }
        return (
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>{localization.artikl.sifra}</Table.HeaderCell>
                        <Table.HeaderCell>{localization.artikl.naziv}</Table.HeaderCell>
                        <Table.HeaderCell>{localization.artikl.serijskiBroj}</Table.HeaderCell>
                        {this.detectHeaderCells()}
                        {this.props.showActions ? <Table.HeaderCell /> : null}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {this.props.artikli.map(item => {
                        return (
                            <Table.Row key={item.sifra}>
                                <Table.Cell>{item.sifra}</Table.Cell>
                                <Table.Cell>{item.naziv}</Table.Cell>
                                <Table.Cell>{item.serijskiBroj || '-'}</Table.Cell>
                                {this.detectBodyCells({
                                    item: item,
                                    editingModalOpen: this.props.editingItem === item.sifra,
                                    onChange: this.props.onChange,
                                    showHideEditItem: this.props.showHideEditItem,
                                    onSaveAsNewItem: this.props.onSaveAsNewItem,
                                    onRemoveItem: this.props.onRemoveItem
                                })}
                            </Table.Row>
                        );
                    })}
                </Table.Body>
                {this.props.showFooter ? (
                    <Table.Footer>
                        <Table.Row>
                            {footer}
                            {footer !== null && this.props.showActions ? <Table.HeaderCell /> : null}
                        </Table.Row>
                    </Table.Footer>
                ) : null}
            </Table>
        );
    }
}

TablicaArtikala.propTypes = {
    type: PropTypes.string.isRequired,
    artikli: PropTypes.array.isRequired,
    showActions: PropTypes.bool,
    showFooter: PropTypes.bool,
    editingItem: PropTypes.string,
    onChange: PropTypes.func,
    showHideEditItem: PropTypes.func,
    onSaveAsNewItem: PropTypes.func,
    onRemoveItem: PropTypes.func
};

let mapStateToProps = function(state, ownProps) {
    return {};
};

let mapDispatchToProps = function(dispatch) {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TablicaArtikala);
