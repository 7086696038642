import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { Button, Card, Form, Header, Modal, Segment } from 'semantic-ui-react';

import { generateKey } from '../../utils/KeyGenerator';
import enums from '../../utils/Enums';
import localization from '../../locales/localization';
import * as KategorijeDataAccess from '../../data_access/KategorijeDataAccess';

const styles = {
    deleteButton: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    container: {
        textAlign: 'left'
    },
    label: {
        display: 'block',
        textAlign: 'left',
        color: 'rgba(0,0,0,0.87)',
        fontWeight: 700,
        marginTop: 8,
        marginBottom: 8
    },
    cardButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    actionButtons: {
        marginTop: 16,
        display: 'flex',
        justifyContent: 'flex-end'
    }
};

const tipKategorije = [enums.tipKategorije.PREDMET, enums.tipKategorije.INV_ZLATO];

class Kategorija extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ime: this.props.kategorija.ime,
            tipKategorije: this.props.kategorija.tipKategorije,
            potkategorije: this.props.kategorija.potkategorije,
            activeModal: false,
            enableSaveButton: false,
            novaPotkategorija: false
        };
        this.onChange = this.onChange.bind(this);
        this.onChangePotkategorije = this.onChangePotkategorije.bind(this);
        this.onDeletePotkategorije = this.onDeletePotkategorije.bind(this);
        this.onAddNew = this.onAddNew.bind(this);
        this.enableSaveButton = this.enableSaveButton.bind(this);
        this.checkKategorijaRequiredFields = this.checkKategorijaRequiredFields.bind(this);
        this.showHideModal = this.showHideModal.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            ime: nextProps.kategorija.ime,
            tipKategorije: nextProps.kategorija.tipKategorije,
            potkategorije: nextProps.kategorija.potkategorije,
            activeModal: false,
            enableSaveButton: false,
            novaPotkategorija: false
        });
    }

    onChange(e, { name, value }) {
        this.setState({ [name]: value }, this.enableSaveButton);
    }

    onChangePotkategorije(index, e, { value }) {
        let potkategorija = Object.assign({}, this.state.potkategorije[index], {
            ime: value,
            changed: true
        });
        this.setState(
            {
                potkategorije: [...this.state.potkategorije.slice(0, index), ...[potkategorija], ...this.state.potkategorije.slice(index + 1)]
            },
            this.enableSaveButton
        );
    }

    onDeletePotkategorije(index) {
        this.setState(
            {
                potkategorije: this.state.potkategorije.slice(0, index).concat(this.state.potkategorije.slice(index + 1)),
                novaPotkategorija: this.state.novaPotkategorija && !(index === this.state.potkategorije.length - 1)
            },
            this.enableSaveButton
        );
    }

    onAddNew(e) {
        this.setState(
            {
                potkategorije: [
                    ...this.state.potkategorije,
                    {
                        ime: ''
                    }
                ],
                novaPotkategorija: true
            },
            this.enableSaveButton
        );
    }

    enableSaveButton() {
        this.setState({ enableSaveButton: this.checkKategorijaRequiredFields() });
    }

    checkKategorijaRequiredFields() {
        let checkPotkategorije = potkategorije => {
            for (let i = 0; i < potkategorije.length; i++) {
                if (potkategorije[i].ime === undefined) {
                    return true;
                }
            }
            return false;
        };
        if (!this.state.ime || !this.state.tipKategorije || checkPotkategorije(this.state.potkategorije)) {
            return false;
        }
        return true;
    }

    showHideModal(value) {
        this.setState({ activeModal: value });
    }

    onCancel() {
        if (this.props.novo) {
            this.props.onDelete();
        } else {
            this.setState({
                ime: this.props.kategorija.ime,
                tipKategorije: this.props.kategorija.tipKategorije,
                potkategorije: this.props.kategorija.potkategorije,
                activeModal: false,
                enableSaveButton: false,
                novaPotkategorija: false
            });
        }
    }

    onSave() {
        let kategorija = {
            ime: this.state.ime,
            tipKategorije: this.state.tipKategorije
        };
        if (this.props.novo) {
            this.props.kategorijeDataAccess.createKategorija(kategorija, this.state.potkategorije.length, k => {
                this.state.potkategorije.forEach(p => {
                    this.props.kategorijeDataAccess.createPotkategorija(k.id, { ime: p.ime });
                });
            });
        } else {
            if (kategorija.ime !== this.props.kategorija.ime || kategorija.tipKategorije !== this.props.kategorija.tipKategorije) {
                this.props.kategorijeDataAccess.updateKategorija(this.props.kategorija.id, kategorija);
            }
            let potkategorijeToCreate = this.state.potkategorije.filter(p => p.id === undefined);
            let potkategorijeToUpdate = this.state.potkategorije.filter(p => p.id && p.changed);
            let potkategorijeToDelete = _.differenceWith(
                this.props.kategorija.potkategorije,
                this.state.potkategorije,
                (first, second) => first.id === second.id
            );

            potkategorijeToCreate.forEach(p => {
                this.props.kategorijeDataAccess.createPotkategorija(this.props.kategorija.id, { ime: p.ime });
            });
            potkategorijeToUpdate.forEach(p => {
                this.props.kategorijeDataAccess.updatePotkategorija(p.id, { ime: p.ime });
            });
            potkategorijeToDelete.forEach(p => {
                this.props.kategorijeDataAccess.deletePotkategorija(p.id);
            });
        }
    }

    onDelete() {
        this.props.kategorijeDataAccess.deleteKategorija(this.props.kategorija.id);
    }

    render() {
        return (
            <Segment>
                {!this.props.novo ? (
                    <div style={styles.deleteButton}>
                        <Modal
                            open={this.state.activeModal}
                            trigger={<Button icon="delete" color="red" basic size="small" onClick={this.showHideModal.bind(this, true)} />}
                        >
                            <Header icon="trash" content={localization.common.potvrdaBrisanja} />
                            <Modal.Content>
                                <p>{localization.postavke.brisanjeKategorijePotvrda}</p>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button icon="remove" content={localization.common.odustani} onClick={this.showHideModal.bind(this, false)} />
                                <Button icon="trash" color="red" content={localization.common.obrisi} onClick={this.onDelete} />
                            </Modal.Actions>
                        </Modal>
                    </div>
                ) : null}
                <Form style={styles.container}>
                    <Form.Input fluid label={localization.postavke.imeKategorije} name="ime" onChange={this.onChange} value={this.state.ime} required />
                    <Form.Select
                        style={styles.selectField}
                        label={localization.postavke.tipKategorije}
                        options={tipKategorije.map((k, index) => {
                            return { key: index, text: k, value: k };
                        })}
                        name="tipKategorije"
                        onChange={this.onChange}
                        value={this.state.tipKategorije}
                        required
                        search
                    />
                </Form>
                <div>
                    <label style={styles.label}>{localization.postavke.potkategorije}</label>
                    <Card.Group itemsPerRow={3} stackable>
                        {this.state.potkategorije.map((potkategorija, index) => {
                            return (
                                <Card key={index}>
                                    <Card.Content textAlign="center">
                                        <Form style={styles.container}>
                                            <Form.Group widths={'equal'}>
                                                <Form.Input
                                                    fluid
                                                    label={localization.postavke.imePotkategorije}
                                                    onChange={this.onChangePotkategorije.bind(this, index)}
                                                    value={potkategorija.ime}
                                                    required
                                                />
                                                <Form.Button
                                                    width={2}
                                                    basic
                                                    size="small"
                                                    icon="remove"
                                                    onClick={this.onDeletePotkategorije.bind(this, index)}
                                                />
                                            </Form.Group>
                                        </Form>
                                    </Card.Content>
                                </Card>
                            );
                        })}
                        {!this.state.novaPotkategorija ? (
                            <Card key={generateKey()}>
                                <Card.Content textAlign="center" style={styles.cardButton}>
                                    <Button icon="plus" content={localization.postavke.dodajNovuPotkategoriju} onClick={this.onAddNew} />
                                </Card.Content>
                            </Card>
                        ) : null}
                    </Card.Group>
                </div>
                <div style={styles.actionButtons}>
                    <Button
                        icon="cancel"
                        content={this.props.novo ? localization.common.odustani : localization.common.vratiNaSpremljeno}
                        onClick={this.onCancel}
                    />
                    <Button icon="save" color="green" disabled={!this.state.enableSaveButton} content={localization.common.spremi} onClick={this.onSave} />
                </div>
            </Segment>
        );
    }
}

Kategorija.propTypes = {
    novo: PropTypes.bool,
    kategorija: PropTypes.object.isRequired,
    kategorijeDataAccess: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {};
};

let mapDispatchToProps = function(dispatch) {
    return {
        kategorijeDataAccess: bindActionCreators(KategorijeDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Kategorija);
