import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import localization from '../../../locales/localization';
import UslugaHeaderCells from './headers/UslugaHeaderCells';
import EditableUslugaBodyCells from './bodies/EditableUslugaBodyCells';
import FixedUslugaBodyCells from './bodies/FixedUslugaBodyCells';
import enums from '../../../utils/Enums';

export default class TablicaUsluga extends React.Component {
    constructor(props) {
        super(props);
        this.detectBodyCells = this.detectBodyCells.bind(this);
    }

    detectBodyCells(cellsProps) {
        switch (this.props.type) {
            case enums.tipTabliceArtikala.RADNINALOG_FIXED: {
                return <FixedUslugaBodyCells {...cellsProps} />;
            }
            case enums.tipTabliceArtikala.RADNINALOG_EDITABLE: {
                return <EditableUslugaBodyCells {...cellsProps} />;
            }
        }
    }

    render() {
        return (
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>{localization.usluga.sifra}</Table.HeaderCell>
                        <Table.HeaderCell>{localization.usluga.naziv}</Table.HeaderCell>
                        <UslugaHeaderCells />
                        {this.props.showActions ? <Table.HeaderCell /> : null}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row key={this.props.usluga.sifra}>
                        <Table.Cell>{this.props.usluga.sifra}</Table.Cell>
                        <Table.Cell>{this.props.usluga.naziv}</Table.Cell>
                        {this.detectBodyCells({
                            item: this.props.usluga,
                            onChange: this.props.onChange,
                            onRemoveItem: this.props.onRemoveItem
                        })}
                    </Table.Row>
                </Table.Body>
            </Table>
        );
    }
}

TablicaUsluga.propTypes = {
    type: PropTypes.string.isRequired,
    usluga: PropTypes.object.isRequired,
    showActions: PropTypes.bool,
    onChange: PropTypes.func,
    onRemoveItem: PropTypes.func
};
