import React from 'react';
import PropTypes from 'prop-types';
import { Header, Segment } from 'semantic-ui-react';

import TablicaArtikala from '../../common_components/table_fragments/TablicaArtikala';
import enums from '../../../utils/Enums';
import localization from '../../../locales/localization';

export default class MeduskladisnicaContent extends React.Component {
    render() {
        return (
            <Segment>
                <Header as="h4">{localization.meduskladisnice.artikliSaSkladista}</Header>
                <TablicaArtikala type={enums.tipTabliceArtikala.MEDUSKLADISNICA_FIXED} artikli={this.props.artikli} />
            </Segment>
        );
    }
}

MeduskladisnicaContent.propTypes = {
    artikli: PropTypes.array.isRequired
};
