import React from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Modal, Table } from 'semantic-ui-react';
import InputField from '../../InputField';
import localization from '../../../../locales/localization';
import Artikl from '../../Artikl';
import NapomenaUzArtikl from '../../NapomenaUzArtikl';
import FormatIznosa from '../../FormatIznosa';

const styles = {
    kolicinaIcijena: {
        fontFamily: 'monospace'
    }
};
const tipModala = {
    EDIT: 'edit',
    COMMENT: 'comment'
};

export default class EditablePrimkaBodyCells extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: this.props.editingModalOpen ? tipModala.EDIT : undefined
        };
        this.onModalTrigger = this.onModalTrigger.bind(this);
        this.hideModalAndRemoveItem = this.hideModalAndRemoveItem.bind(this);
    }

    onModalTrigger(e, { name }) {
        this.setState({ modal: name }, this.props.showHideEditItem.bind(this, this.props.item.sifra));
    }

    hideModalAndRemoveItem() {
        this.props.showHideEditItem(undefined);
        this.props.onRemoveItem(this.props.item);
    }

    render() {
        return (
            <React.Fragment>
                <Table.Cell>{this.props.item.kategorija.ime + (this.props.item.potkategorija ? ' / ' + this.props.item.potkategorija.ime : '')}</Table.Cell>
                <Table.Cell collapsing textAlign="center">
                    <Checkbox checked={this.props.item.isNoviPredmet} name="isNoviPredmet" onChange={this.props.onChange.bind(this, this.props.item.sifra)} />
                </Table.Cell>
                <Table.Cell collapsing>
                    <InputField
                        label={this.props.item.mjernaJedinica.naziv}
                        placeholder={localization.common.kol}
                        name="kolicina"
                        value={this.props.item.kolicina}
                        onChange={this.props.onChange.bind(this, this.props.item.sifra)}
                        isCjelobrojno={this.props.item.mjernaJedinica.isCjelobrojna}
                    />
                </Table.Cell>
                <Table.Cell collapsing>
                    <InputField
                        label={localization.common.eur}
                        placeholder={localization.common.cijena}
                        name="cijena"
                        value={this.props.item.cijena}
                        onChange={this.props.onChange.bind(this, this.props.item.sifra)}
                    />
                </Table.Cell>
                <Table.Cell textAlign="right" collapsing style={styles.kolicinaIcijena}>
                    <b>
                        {this.props.item.kolicina && this.props.item.cijena ? <FormatIznosa value={this.props.item.kolicina * this.props.item.cijena} /> : '-'}
                    </b>
                </Table.Cell>
                <Table.Cell collapsing>
                    <Button.Group basic size="small">
                        <Modal
                            open={this.props.editingModalOpen && this.state.modal === tipModala.COMMENT}
                            closeOnDimmerClick={false}
                            trigger={<Button icon="comment outline" name={tipModala.COMMENT} onClick={this.onModalTrigger} />}
                        >
                            <Modal.Header>{localization.otkup.napomenaUzArtikl}</Modal.Header>
                            <Modal.Content>
                                <NapomenaUzArtikl
                                    napomena={this.props.item.napomena}
                                    onSave={this.props.onChange.bind(this, this.props.item.sifra)}
                                    onCancel={this.props.showHideEditItem.bind(this, undefined)}
                                />
                            </Modal.Content>
                        </Modal>
                        <Button icon="delete" onClick={this.props.onRemoveItem.bind(this, this.props.item)} />
                    </Button.Group>
                </Table.Cell>
                <Modal open={this.props.editingModalOpen && this.state.modal === tipModala.EDIT} closeOnDimmerClick={false}>
                    <Modal.Header>{localization.common.urediArtikl}</Modal.Header>
                    <Modal.Content>
                        <Artikl item={this.props.item} saveAsNewItem={this.props.onSaveAsNewItem} onCancel={this.hideModalAndRemoveItem} />
                    </Modal.Content>
                </Modal>
            </React.Fragment>
        );
    }
}

EditablePrimkaBodyCells.propTypes = {
    item: PropTypes.object.isRequired,
    editingModalOpen: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    showHideEditItem: PropTypes.func.isRequired,
    onSaveAsNewItem: PropTypes.func.isRequired,
    onRemoveItem: PropTypes.func.isRequired
};
