/**
 * A store holds the whole state tree of application.
 * The store is not a class. It's just an object with a few methods on it.
 */
import { applyMiddleware, createStore } from 'redux';
import rootReducer from '../reducers/rootReducer';
import thunk from 'redux-thunk';

/**
 * Store constructor for production environment
 */
export default function storeConstructor(initialState) {
    return createStore(rootReducer, initialState, applyMiddleware(thunk));
}
