import React from 'react';
import PropTypes from 'prop-types';
import { Header, Segment } from 'semantic-ui-react';

import TablicaKlijenata from '../../common_components/table_fragments/TablicaKlijenata';
import TablicaArtikala from '../../common_components/table_fragments/TablicaArtikala';
import localization from '../../../locales/localization';

export default class OtkupProdajaContent extends React.Component {
    render() {
        return (
            <Segment>
                {this.props.klijent ? (
                    <React.Fragment>
                        <Header as="h4">{localization.racuni.klijent}</Header>
                        <TablicaKlijenata klijent={this.props.klijent} />
                    </React.Fragment>
                ) : null}
                <Header as="h4">{localization.racuni.artikli}</Header>
                <TablicaArtikala type={this.props.type} artikli={this.props.artikli} />
            </Segment>
        );
    }
}

OtkupProdajaContent.propTypes = {
    klijent: PropTypes.object,
    type: PropTypes.string.isRequired,
    artikli: PropTypes.array.isRequired
};
