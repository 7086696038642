let initialState = {
    mjerneJedinice: [],
    cached: false
};

export default function mjerneJediniceReducer(state = initialState, action) {
    switch (action.type) {
        case 'GET_MJERNE_JEDINICE': {
            return Object.assign({}, state, {
                mjerneJedinice: [...action.mjerneJedinice],
                cached: true
            });
        }
        default:
            return state;
    }
}
