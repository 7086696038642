let initialState = {
    radniNalozi: [],
    cached: false
};

export default function radniNaloziReducer(state = initialState, action) {
    switch (action.type) {
        case 'GET_RADNI_NALOZI': {
            return Object.assign({}, state, {
                radniNalozi: [...action.radniNalozi],
                cached: true
            });
        }
        default:
            return state;
    }
}
