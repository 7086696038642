import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Header, Segment, Table } from 'semantic-ui-react';

import TablicaKlijenata from '../../common_components/table_fragments/TablicaKlijenata';
import localization from '../../../locales/localization';
import FormatIznosa from '../../common_components/FormatIznosa';

const styles = {
    kolicinaIcijena: {
        fontFamily: 'monospace'
    }
};

export default class ProcjenaContent extends React.Component {
    render() {
        return (
            <Segment>
                <Header as="h4">{localization.procjene.klijent}</Header>
                <TablicaKlijenata klijent={this.props.zahtjevZaProcjenu.klijent} />
                {this.props.imageUploadModal}
                <Header as="h4">{localization.procjene.artikli}</Header>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            {this.props.enableRowChecking ? <Table.HeaderCell /> : null}
                            <Table.HeaderCell>{localization.artikl.sifra}</Table.HeaderCell>
                            <Table.HeaderCell>{localization.artikl.naziv}</Table.HeaderCell>
                            <Table.HeaderCell>{localization.artikl.serijskiBroj}</Table.HeaderCell>
                            <Table.HeaderCell>{localization.artikl.kategorija}</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center" collapsing>
                                {localization.common.kolicina}
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center" collapsing>
                                {localization.procjena.ocekivanaJedinicnaVrijednost}
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center" collapsing>
                                {localization.common.vrijednost}
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.props.zahtjevZaProcjenu.procjene.map(procjena => {
                            if (this.props.showArhivirani || (!this.props.showArhivirani && !procjena.isArhivirana)) {
                                return (
                                    <Table.Row key={procjena.id}>
                                        {this.props.enableRowChecking ? (
                                            <Table.Cell>
                                                <Checkbox
                                                    checked={!this.props.listOfDeselected.find(p => p.id === procjena.id)}
                                                    onChange={this.props.onRowSelected.bind(this, procjena, this.props.zahtjevZaProcjenu.id)}
                                                />
                                            </Table.Cell>
                                        ) : null}
                                        <Table.Cell>{procjena.predmet.sifra}</Table.Cell>
                                        <Table.Cell>{procjena.predmet.naziv}</Table.Cell>
                                        <Table.Cell>{procjena.predmet.serijskiBroj || '-'}</Table.Cell>
                                        <Table.Cell>
                                            {procjena.predmet.kategorija.ime +
                                                (procjena.predmet.potkategorija ? ' / ' + procjena.predmet.potkategorija.ime : '')}
                                        </Table.Cell>
                                        <Table.Cell style={styles.kolicinaIcijena} textAlign="right" collapsing>
                                            {procjena.kolicina}
                                        </Table.Cell>
                                        <Table.Cell style={styles.kolicinaIcijena} textAlign="right" collapsing>
                                            <FormatIznosa value={procjena.ocekivanaJedCijena} suffix={null} />
                                        </Table.Cell>
                                        <Table.Cell style={styles.kolicinaIcijena} textAlign="right" collapsing>
                                            <FormatIznosa value={procjena.kolicina * procjena.ocekivanaJedCijena} />
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            } else {
                                return null;
                            }
                        })}
                    </Table.Body>
                </Table>
            </Segment>
        );
    }
}

ProcjenaContent.propTypes = {
    zahtjevZaProcjenu: PropTypes.object.isRequired,
    showArhivirani: PropTypes.bool.isRequired,
    imageUploadModal: PropTypes.node,
    enableRowChecking: PropTypes.bool,
    listOfDeselected: PropTypes.array,
    onRowSelected: PropTypes.func
};
