const tipKlijenta = {
    FIZICKA_OSOBA: 'FIZICKA_OSOBA',
    PRAVNA_OSOBA: 'PRAVNA_OSOBA'
};

const idBrojTip = {
    OIB: 'OIB',
    BR_OSOBNE: 'BR_OSOBNE',
    BR_PUT: 'BR_PUT'
};

const tipKategorije = {
    PREDMET: 'PREDMET',
    INV_ZLATO: 'INV_ZLATO'
};

const tipArtikla = {
    PREDMET: 'PREDMET',
    USLUGA: 'USLUGA'
};

const tipKorisnika = {
    DJELATNIK: 'DJELATNIK',
    VODITELJ: 'VODITELJ',
    VLASNIK: 'VLASNIK',
    KONTROLA: 'KONTROLA'
};

const tipSkladisnogUlaznogBloka = {
    OTKUPNI_BLOK: 'OTKUPNI_BLOK',
    PRIMKA: 'PRIMKA'
};

const tipCijeneRezervacije = {
    FIKSNO: 'FIKSNO',
    POSTOTAK: 'POSTOTAK'
};

const tipRacuna = {
    FISKALNI_RACUN: 'FISKALNI',
    TRANSAKCIJSKI_RACUN: 'TRANSAKCIJSKI'
};

const nacinPlacanja = {
    GOTOVINSKI_RACUN: 'GOTOVINSKI_RACUN',
    KARTICNI_RACUN: 'KARTICNI_RACUN',
    TRANSAKCIJSKI_RACUN: 'TRANSAKCIJSKI_RACUN',
    DUALNI_RACUN: 'DUALNI_RACUN'
};

const tipKlijentaNaRacunu = {
    PRIVATNA_OSOBA: 'PRIVATNA_OSOBA',
    FIZICKA_OSOBA: 'FIZICKA_OSOBA',
    PRAVNA_OSOBA: 'PRAVNA_OSOBA'
};

const tipTabliceArtikala = {
    REZERVACIJA_EDITABLE: 'REZERVACIJA_EDITABLE',
    REZERVACIJA_IMPORT: 'REZERVACIJA_IMPORT',
    OTKUP_EDITABLE: 'OTKUP_EDITABLE',
    OTKUP_FIXED: 'OTKUP_FIXED',
    PRIMKA_EDITABLE: 'PRIMKA_EDITABLE',
    PRIMKA_FIXED: 'PRIMKA_FIXED',
    RACUN_EDITABLE: 'RACUN_EDITABLE',
    RACUN_FIXED: 'RACUN_FIXED',
    IZDATNICA_EDITABLE: 'IZDATNICA_EDITABLE',
    IZDATNICA_FIXED: 'IZDATNICA_FIXED',
    PROCJENA_EDITABLE: 'PROCJENA_EDITABLE',
    PROCJENA_FIXED: 'PROCJENA_FIXED',
    RADNINALOG_EDITABLE: 'RADNINALOG_EDITABLE',
    RADNINALOG_FIXED: 'RADNINALOG_FIXED',
    MEDUSKLADISNICA_EDITABLE: 'MEDUSKLADISNICA_EDITABLE',
    MEDUSKLADISNICA_FIXED: 'MEDUSKLADISNICA_FIXED'
};

const tipUplatnicaIsplatnica = {
    UPLATNICA: 'UPLATNICA',
    ISPLATNICA: 'ISPLATNICA'
};

const tipBlagajne = {
    FISKALNA_BLAGAJNA: 'FISKALNA_BLAGAJNA',
    BLAGAJNA_OTKUPA: 'BLAGAJNA_OTKUPA'
};

const tipBlagajneRadnje = {
    OTKUPNA: 'OTKUPNA',
    FISKALNA: 'FISKALNA'
};

const tipMjesta = {
    POSLOVNICA: 'POSLOVNICA',
    MJESTO: 'MJESTO'
};

const tipPoreza = {
    NOVI_PREDMET: 'NOVI_PREDMET',
    RABLJENI_PREDMET: 'RABLJENI_PREDMET',
    INVESTICIJSKO_ZLATO: 'INVESTICIJSKO_ZLATO',
    USLUGA: 'USLUGA',
    BEZ_PDV: 'BEZ_PDV'
};

const tipTroska = {
    ONE_TIME_UPLATNICKI: 'ONE_TIME_UPLATNICKI',
    ONE_TIME_BANKOVNI: 'ONE_TIME_BANKOVNI',
    RECURRING_PAID_UPLATNICKI: 'RECURRING_PAID_UPLATNICKI',
    RECURRING_PAID_BANKOVNI: 'RECURRING_PAID_BANKOVNI',
    RECURRING_PENDING: 'RECURRING_PENDING',
    RECURRING_DISMISSED: 'RECURRING_DISMISSED'
};

export default {
    tipKlijenta,
    idBrojTip,
    tipKategorije,
    tipArtikla,
    tipKorisnika,
    tipSkladisnogUlaznogBloka,
    tipCijeneRezervacije,
    tipRacuna,
    nacinPlacanja,
    tipKlijentaNaRacunu,
    tipTabliceArtikala,
    tipUplatnicaIsplatnica,
    tipBlagajne,
    tipBlagajneRadnje,
    tipMjesta,
    tipPoreza,
    tipTroska
};
