import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import localization from '../../locales/localization';
import { Button, Dimmer, Loader, Table } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import * as RezervacijaDataAccess from '../../data_access/RezervacijaDataAccess';
import { bindActionCreators } from 'redux';
import NesluzbenaNapomena from '../common_components/NesluzbenaNapomena';

const styles = {
    container: {
        textAlign: 'center'
    },
    quantityAndPrice: {
        width: 130
    },
    actionButtons: {
        marginTop: 16,
        display: 'flex',
        justifyContent: 'flex-end'
    }
};

class ProduzenjeRokaZaPodizanjeModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rokovi: this.calculateInitialRokovi(),
            nesluzbenaNapomena: '',
            loader: false
        };
        this.calculateInitialRokovi = this.calculateInitialRokovi.bind(this);
        this.calculateMinDate = this.calculateMinDate.bind(this);
        this.onChangeRokZaPodizanje = this.onChangeRokZaPodizanje.bind(this);
        this.onChange = this.onChange.bind(this);
        this.showLoader = this.showLoader.bind(this);
        this.hideLoader = this.hideLoader.bind(this);
        this.onProduziRokZaPodizanje = this.onProduziRokZaPodizanje.bind(this);
    }

    calculateInitialRokovi() {
        return this.props.zahtjevRezervacije.rezervacije.map(r => {
            let rokZaPodizanje = moment(r.rokZaPodizanje);
            let now = moment();
            if (rokZaPodizanje.isBefore(now)) {
                rokZaPodizanje = now;
            }
            return {
                rezervacijaId: r.id,
                rokZaPodizanje: rokZaPodizanje
            };
        });
    }

    calculateMinDate(rezervacija) {
        let datumIsteka = moment(rezervacija.datumIsteka);
        let now = moment();
        return datumIsteka.isAfter(now) ? datumIsteka : now;
    }

    onChangeRokZaPodizanje(rezervacijaId, date) {
        this.setState({
            rokovi: this.state.rokovi.map(rok => {
                if (rok.rezervacijaId === rezervacijaId) {
                    rok.rokZaPodizanje = date;
                }
                return rok;
            })
        });
    }

    onChange(e, { name, value }) {
        this.setState({ [name]: value });
    }

    showLoader(callback) {
        this.setState(
            {
                loader: true
            },
            callback
        );
    }

    hideLoader() {
        this.setState(
            {
                loader: false
            }
        );
    }

    onProduziRokZaPodizanje() {
        let rezervacijeData = {
            nesluzbenaNapomena: this.state.nesluzbenaNapomena || null,
            rokovi: this.props.zahtjevRezervacije.rezervacije.map(r => {
                return {
                    rezervacijaId: r.id,
                    rokZaPodizanje: this.state.rokovi.find(rok => rok.rezervacijaId === r.id).rokZaPodizanje.format('YYYY-MM-DD')
                };
            })
        };
        this.props.rezervacijaDataAccess.produziRokZaPodizanje(
            this.props.zahtjevRezervacije.id,
            rezervacijeData,
            () => {
                this.hideLoader();
                this.props.closeModal();
                this.props.onProduzenRok();
            },
            () => this.hideModal()
        );
    }

    render() {
        return (
            <div style={styles.container}>
                <Table celled structured stackable selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>{localization.artikl.sifra}</Table.HeaderCell>
                            <Table.HeaderCell>{localization.artikl.naziv}</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">{localization.common.kolicina}</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">{localization.rezervacije.datumIsteka}</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">{localization.rezervacije.rokZaPodizanje}</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center" collapsing>
                                {localization.rezervacije.noviRokZaPodizanje}
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.props.zahtjevRezervacije.rezervacije.map(rezervacija => {
                            let noviRokZaPodizanje = this.state.rokovi.find(rok => rok.rezervacijaId === rezervacija.id).rokZaPodizanje;
                            return (
                                <Table.Row key={rezervacija.id}>
                                    <Table.Cell>{rezervacija.predmet.sifra}</Table.Cell>
                                    <Table.Cell>{rezervacija.predmet.naziv}</Table.Cell>
                                    <Table.Cell textAlign="center">{rezervacija.kolicina}</Table.Cell>
                                    <Table.Cell textAlign="center">{moment(rezervacija.datumIsteka).format('DD.MM.YYYY.')}</Table.Cell>
                                    <Table.Cell textAlign="center">{moment(rezervacija.rokZaPodizanje).format('DD.MM.YYYY.')}</Table.Cell>
                                    <Table.Cell textAlign="center">
                                        <DatePicker
                                            customInput={
                                                <Button fluid compact basic color="black">
                                                    {noviRokZaPodizanje.format('DD.MM.YYYY.')}
                                                </Button>
                                            }
                                            dateFormat="DD.MM.YYYY."
                                            locale="hr-HR"
                                            minDate={this.calculateMinDate(rezervacija)}
                                            showDisabledMonthNavigation
                                            selected={noviRokZaPodizanje}
                                            onChange={this.onChangeRokZaPodizanje.bind(this, rezervacija.id)}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
                <NesluzbenaNapomena
                    onChangeNapomena={this.onChange}
                    placeholder={localization.common.nesluzbenaNapomena}
                    required={!this.state.nesluzbenaNapomena}
                    requiredText={localization.rezervacije.produzenjeRokaRezervacijeObveznaNesluzbenaNapomena}
                />
                <div style={styles.actionButtons}>
                    <Button icon="close" content={localization.common.odustani} onClick={() => this.props.closeModal()} />
                    <Button
                        icon="calendar outline"
                        color="green"
                        disabled={!this.state.nesluzbenaNapomena}
                        content={localization.rezervacije.produziRokZaPodizanje}
                        onClick={this.showLoader.bind(this, this.onProduziRokZaPodizanje)}
                    />
                </div>
                <Dimmer active={this.state.loader} page>
                    <Loader />
                </Dimmer>
            </div>
        );
    }
}

ProduzenjeRokaZaPodizanjeModal.propTypes = {
    zahtjevRezervacije: PropTypes.object.isRequired,
    rezervacijaDataAccess: PropTypes.object.isRequired,
    onProduzenRok: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {};
};

let mapDispatchToProps = function(dispatch) {
    return {
        rezervacijaDataAccess: bindActionCreators(RezervacijaDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProduzenjeRokaZaPodizanjeModal);
