import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Form, Header, Modal, Segment } from 'semantic-ui-react';

import InputField from '../common_components/InputField';
import RadnoVrijeme from '../common_components/RadnoVrijeme';
import localization from '../../locales/localization';
import enums from '../../utils/Enums';
import * as MjestaDataAccess from '../../data_access/MjestaDataAccess';

const styles = {
    container: {
        textAlign: 'left'
    },
    deleteContainer: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
};

const daniUTjednu = [
    localization.postavke.pon,
    localization.postavke.uto,
    localization.postavke.sri,
    localization.postavke.cet,
    localization.postavke.pet,
    localization.postavke.sub,
    localization.postavke.ned
];

class Mjesto extends React.Component {
    constructor(props) {
        super(props);
        this.composeMjestoObject = this.composeMjestoObject.bind(this);
        this.state = {
            mjesto: this.composeMjestoObject(this.props.mjesto),
            enableSaveButton: false
        };
        this.onReset = this.onReset.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeRadnoVrijeme = this.onChangeRadnoVrijeme.bind(this);
        this.enableSaveButton = this.enableSaveButton.bind(this);
        this.checkMjestoRequiredFields = this.checkMjestoRequiredFields.bind(this);
        this.showHideModal = this.showHideModal.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            mjesto: Object.assign({}, this.state.mjesto, nextProps.mjesto),
            enableSaveButton: false
        });
    }

    onReset() {
        this.setState({
            mjesto: Object.assign({}, this.state.mjesto, this.props.mjesto),
            enableSaveButton: false
        });
    }

    composeMjestoObject(props) {
        let mjesto = {
            naziv: props.naziv || '',
            adresa: props.adresa || ''
        };
        if (this.props.tipMjesta === enums.tipMjesta.POSLOVNICA) {
            mjesto.naselje = props.naselje || '';
            mjesto.brojPoslovnice = props.brojPoslovnice || '';
            mjesto.radnoVrijeme = props.radnoVrijeme || Array(7).fill(null);
            if (this.props.novo) {
                mjesto.pocetnoStanjeBlagOtkupa = 0;
                mjesto.pocetnoStanjeFiskBlag = 0;
            }
        }
        return mjesto;
    }

    onChange(e, { name, value }) {
        this.setState(
            {
                mjesto: Object.assign({}, this.state.mjesto, { [name]: value })
            },
            this.enableSaveButton
        );
    }

    onChangeRadnoVrijeme(index, radnoVrijeme) {
        let rv = null;
        if (radnoVrijeme.radniDan) {
            rv = {
                pocetak: radnoVrijeme.pocetak,
                kraj: radnoVrijeme.kraj
            };
        }
        this.setState(
            {
                mjesto: Object.assign({}, this.state.mjesto, {
                    radnoVrijeme: [...this.state.mjesto.radnoVrijeme.slice(0, index), ...[rv], ...this.state.mjesto.radnoVrijeme.slice(index + 1)]
                })
            },
            this.enableSaveButton
        );
    }

    enableSaveButton() {
        this.setState({
            enableSaveButton: this.checkMjestoRequiredFields()
        });
    }

    checkMjestoRequiredFields() {
        if (this.props.tipMjesta === enums.tipMjesta.POSLOVNICA) {
            if (!this.state.mjesto.naziv || !this.state.mjesto.adresa || !this.state.mjesto.naselje || !this.state.mjesto.brojPoslovnice) {
                return false;
            }
        } else {
            if (!this.state.mjesto.naziv || !this.state.mjesto.adresa) {
                return false;
            }
        }
        return true;
    }

    showHideModal(value) {
        this.setState({ activeModal: value });
    }

    onSave() {
        if (this.props.tipMjesta === enums.tipMjesta.POSLOVNICA) {
            if (this.props.novo) {
                this.props.mjestaDataAccess.createPoslovnica(this.state.mjesto);
            } else {
                this.props.mjestaDataAccess.updatePoslovnica(this.props.mjesto.id, this.state.mjesto);
            }
        } else {
            if (this.props.novo) {
                this.props.mjestaDataAccess.createMjesto(this.state.mjesto);
            } else {
                this.props.mjestaDataAccess.updateMjesto(this.props.mjesto.id, this.state.mjesto);
            }
        }
    }

    onCancel(e) {
        if (this.props.novo) {
            this.props.onCancel(e, { name: this.props.name });
        } else {
            this.onReset();
        }
    }

    onDelete() {
        if (this.props.tipMjesta === enums.tipMjesta.POSLOVNICA) {
            this.props.mjestaDataAccess.deletePoslovnica(this.props.mjesto.id);
        } else {
            this.props.mjestaDataAccess.deleteMjesto(this.props.mjesto.id);
        }
    }

    render() {
        return (
            <Segment>
                {!this.props.novo ? (
                    <div style={styles.deleteContainer}>
                        <Modal
                            open={this.state.activeModal}
                            trigger={<Button icon="delete" color="red" basic size="small" onClick={this.showHideModal.bind(this, true)} />}
                        >
                            <Header icon="trash" content={localization.common.potvrdaBrisanja} />
                            <Modal.Content>
                                <p>
                                    {this.props.tipMjesta === enums.tipMjesta.POSLOVNICA
                                        ? localization.postavke.brisanjePoslovnicePotvrda
                                        : localization.postavke.brisanjeSkladistaPotvrda}
                                </p>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button icon="remove" content={localization.common.odustani} onClick={this.showHideModal.bind(this, false)} />
                                <Button icon="trash" color="red" content={localization.common.obrisi} onClick={this.onDelete} />
                            </Modal.Actions>
                        </Modal>
                    </div>
                ) : null}
                <Form style={styles.container}>
                    <Form.Input
                        fluid
                        label={localization.postavke.nazivSkladista}
                        name="naziv"
                        onChange={this.onChange}
                        value={this.state.mjesto.naziv}
                        required
                    />
                    <Form.Input
                        fluid
                        label={localization.postavke.adresaSkladista}
                        name="adresa"
                        onChange={this.onChange}
                        value={this.state.mjesto.adresa}
                        required
                    />
                    {this.props.tipMjesta === enums.tipMjesta.POSLOVNICA ? (
                        <React.Fragment>
                            <Form.Input
                                fluid
                                label={localization.postavke.mjestoSkladista}
                                name="naselje"
                                onChange={this.onChange}
                                value={this.state.mjesto.naselje}
                                required
                            />
                            <Form.Input
                                fluid
                                label={localization.postavke.brojPoslovnice}
                                name="brojPoslovnice"
                                onChange={this.onChange}
                                value={this.state.mjesto.brojPoslovnice}
                                required
                            />
                            {this.props.novo ? (
                                <React.Fragment>
                                    <Form.Field required>
                                        <label>{localization.postavke.pocetnoStanjeBlagOtkupa}</label>
                                        <InputField
                                            label={localization.common.eur}
                                            placeholder={localization.common.eur}
                                            name="pocetnoStanjeBlagOtkupa"
                                            value={this.state.mjesto.pocetnoStanjeBlagOtkupa}
                                            onChange={this.onChange}
                                            isCjelobrojno={true}
                                        />
                                    </Form.Field>
                                    <Form.Field required>
                                        <label>{localization.postavke.pocetnoStanjeFiskBlag}</label>
                                        <InputField
                                            label={localization.common.eur}
                                            placeholder={localization.common.eur}
                                            name="pocetnoStanjeFiskBlag"
                                            value={this.state.mjesto.pocetnoStanjeFiskBlag}
                                            onChange={this.onChange}
                                            isCjelobrojno={true}
                                        />
                                    </Form.Field>
                                </React.Fragment>
                            ) : null}
                            <Form.Field required>
                                <label>{localization.postavke.radnoVrijeme}</label>
                                {this.state.mjesto.radnoVrijeme.map((rv, index) => {
                                    return rv ? (
                                        <RadnoVrijeme
                                            key={index}
                                            dayName={daniUTjednu[index]}
                                            startTime={rv.pocetak}
                                            endTime={rv.kraj}
                                            onChange={this.onChangeRadnoVrijeme.bind(this, index)}
                                        />
                                    ) : (
                                        <RadnoVrijeme key={index} dayName={daniUTjednu[index]} onChange={this.onChangeRadnoVrijeme.bind(this, index)} />
                                    );
                                })}
                            </Form.Field>
                        </React.Fragment>
                    ) : null}
                    <Form.Group style={styles.buttons}>
                        <Form.Button
                            icon="cancel"
                            content={this.props.novo ? localization.common.odustani : localization.common.vratiNaSpremljeno}
                            onClick={this.onCancel}
                        />
                        <Form.Button
                            icon="save"
                            color="green"
                            disabled={!this.state.enableSaveButton}
                            content={localization.common.spremi}
                            onClick={this.onSave}
                        />
                    </Form.Group>
                </Form>
            </Segment>
        );
    }
}

Mjesto.propTypes = {
    tipMjesta: PropTypes.string.isRequired,
    novo: PropTypes.bool.isRequired,
    mjesto: PropTypes.object,
    mjestaDataAccess: PropTypes.object.isRequired,
    name: PropTypes.string,
    onCancel: PropTypes.func
};

let mapStateToProps = function(state, ownProps) {
    return {};
};

let mapDispatchToProps = function(dispatch) {
    return {
        mjestaDataAccess: bindActionCreators(MjestaDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Mjesto);
