import React from 'react';
import PropTypes from 'prop-types';

const styles = {
    requiredText: {
        color: 'red'
    }
};

export default class RequiredText extends React.Component {
    render() {
        return <p style={styles.requiredText}>{'* ' + this.props.requiredText}</p>;
    }
}

RequiredText.propTypes = {
    requiredText: PropTypes.string
};
