import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NovaPrimka from './otkup_components/NovaPrimka';

const styles = {};

class Primka extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <NovaPrimka history={this.props.history} />;
    }
}

Primka.propTypes = {
    history: PropTypes.object.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {};
};

let mapDispatchToProps = function(dispatch) {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Primka);
