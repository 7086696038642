import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Accordion, Button, Dimmer, Grid, Header, Icon, Loader, Message, Modal, Pagination, Search, Segment, Table } from 'semantic-ui-react';

import localization from '../../locales/localization';
import endpoints from '../../environments/endpoints';
import icons from '../../utils/DefinedIcons';
import { storeDocumentAndGenerateKey } from '../../utils/PrintingDocumentsHandler';
import SearchResultsMessage from '../common_components/SearchResultsMessage';
import ImageUploadModal from '../common_components/ImageUploadModal';
import Diskusija from '../common_components/Diskusija';
import ArhiviranjeProcjeneModal from '../procjena_components/ArhiviranjeProcjeneModal';
import enums from '../../utils/Enums';
import * as ProcjenaDataAccess from '../../data_access/ProcjenaDataAccess';
import * as ProcjenaApi from '../../data_access/ProcjenaApi';
import moment from 'moment';
import ProcjenaContent from './content_components/ProcjenaContent';
import FilterSortButton from '../common_components/FilterSortButton';

// TODO #pagination #refactor Extract this to some other place when pagination for other components is implemented
//      and we solve the Redux/DataAccess dilemma.
const MAX_API_FETCH_ATTEMPTS = 3;
// TODO #pagination #refactor Probably extract somewhere else and use across all search components
const SEARCH_DEBOUNCE_RATE_MS = 1000;

const styles = {
    headerTable: {
        padding: '.75em 1em'
    },
    iconCell: {
        width: 50
    },
    brojProcjeneCell: {
        width: 200
    },
    klijentCell: {
        width: 500
    },
    accordionHeaderContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    accordionHeaderText: {
        paddingLeft: 4,
        paddingRight: 2,
        paddingBottom: '0.5em'
    },
    artikliContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between'
    },
    artikliHeader: {
        marginBottom: 8
    },
    actionButtons: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    semaforStyle: {
        marginLeft: 4
    }
};

const modaliEnums = {
    galerijaSlika: 'galerijaSlika',
    arhiviranjeProcjene: 'arhiviranjeProcjene'
};

const LIST_STATE = {
    initial: 'initial',
    empty: 'empty',
    noResult: 'noResult',
    populated: 'populated',
    error: 'error'
};

const ITEMS_PER_PAGE = 10;

class ZahtjeviZaProcjenu extends React.Component {
    constructor(props) {
        super(props);
        this.predeselectProcjene = this.predeselectProcjene.bind(this);
        this.state = {
            value: '',
            deselected: [],
            activeZahtjevZaProcjenu: [],
            activeComments: [],
            noveNapomene: [],
            activeModal: undefined,
            loader: false,
            filter: 0,
            pageActive: 1
        };
        this.onNoviZahtjevZaProcjenu = this.onNoviZahtjevZaProcjenu.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
        this.applyFilterAndSort = this.applyFilterAndSort.bind(this);
        this.resetSearchComponent = this.resetSearchComponent.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.onDeselectProcjena = this.onDeselectProcjena.bind(this);
        this.onChangeNovaNapomena = this.onChangeNovaNapomena.bind(this);
        this.expandZahtjevZaProcjenu = this.expandZahtjevZaProcjenu.bind(this);
        this.expandComments = this.expandComments.bind(this);
        this.extractProcjene = this.extractProcjene.bind(this);
        this.dodajNapomenu = this.dodajNapomenu.bind(this);
        this.resetNapomena = this.resetNapomena.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.showLoader = this.showLoader.bind(this);
        this.hideLoader = this.hideLoader.bind(this);
        this.ispisZahtjevaZaProcjenom = this.ispisZahtjevaZaProcjenom.bind(this);
        this.fetchProcjene = this.fetchProcjene.bind(this);
        this.refreshProcjene = this.refreshProcjene.bind(this);
        this.getPoslovnicaIdForFilter = this.getPoslovnicaIdForFilter.bind(this);
        this.renderInitialComponent = this.renderInitialComponent.bind(this);
        this.renderEmptyComponent = this.renderEmptyComponent.bind(this);
        this.renderNoResultComponent = this.renderNoResultComponent.bind(this);
        this.renderErrorComponent = this.renderErrorComponent.bind(this);
        this.renderListComponent = this.renderListComponent.bind(this);
        this.debouncedFetchProcjene = _.debounce(function (filter, page) {
            this.fetchProcjene(filter, page);
        }, SEARCH_DEBOUNCE_RATE_MS).bind(this);

        if (sessionStorage.userRole !== enums.tipKorisnika.DJELATNIK) {
            this.FILTER_ITEMS = [{
                name: localization.common.svePoslovnice,
                apiParam: null
            }];
            this.props.poslovnice.forEach(p => this.FILTER_ITEMS.push({
                name: p.naziv,
                apiParam: p.id
            }));
        }
    }

    componentWillMount() {
        this.resetSearchComponent();
    }

    predeselectProcjene(zahtjeviZaProcjenom) {
        let arhiviraneProcjene = [];
        zahtjeviZaProcjenom.forEach(zp => {
            zp.procjene.forEach(p => {
                if (p.isArhivirana) {
                    arhiviraneProcjene.push(
                        Object.assign({}, p, {
                            zahtjevZaProcjenom: zp.id
                        })
                    );
                }
            });
        });
        return arhiviraneProcjene;
    }

    onNoviZahtjevZaProcjenu() {
        this.props.history.push(endpoints.appEndpoints.procjena);
    }

    onChangePage(e, { activePage }) {
        this.fetchProcjene(this.state.filter, activePage);
    }

    applyFilterAndSort(filter, sort) {
        this.fetchProcjene(filter);
    }

    resetSearchComponent() {
        this.setState({
            listState: LIST_STATE.initial,
            fetchAttempts: 0
        }, () => {
            this.fetchProcjene();
        });
    }

    handleSearchChange(e, { value }) {
        this.setState({
            value: value
        }, () => {
            this.debouncedFetchProcjene();
        });
    }

    onDeselectProcjena(procjena, id) {
        let comparator = (first, second) => first.id === second.id && first.zahtjevZaProcjenom === second.zahtjevZaProcjenom;
        let p = Object.assign({}, procjena, {
            zahtjevZaProcjenom: id
        });
        this.setState({
            deselected: _.xorWith(this.state.deselected, [p], comparator)
        });
    }

    onChangeNovaNapomena(id, e, { value }) {
        let noveNapomene = [];
        let index = this.state.noveNapomene.findIndex(el => el.id === id);
        if (index > -1) {
            noveNapomene = [...this.state.noveNapomene];
            noveNapomene[index] = {
                id: id,
                napomena: value
            };
        } else {
            noveNapomene = [
                ...this.state.noveNapomene,
                {
                    id: id,
                    napomena: value
                }
            ];
        }
        this.setState({
            noveNapomene: noveNapomene
        });
    }

    expandZahtjevZaProcjenu(e, { index }) {
        let activeZahtjevZaProcjenu = [];
        if (this.state.activeZahtjevZaProcjenu.includes(index)) {
            activeZahtjevZaProcjenu = _.xor(this.state.activeZahtjevZaProcjenu, [index]);
        } else {
            activeZahtjevZaProcjenu = [...this.state.activeZahtjevZaProcjenu, index];
        }
        this.setState({
            activeZahtjevZaProcjenu: activeZahtjevZaProcjenu
        });
    }

    expandComments(e, { index }) {
        let activeComments = [];
        if (this.state.activeComments.includes(index)) {
            activeComments = _.xor(this.state.activeComments, [index]);
        } else {
            activeComments = [...this.state.activeComments, index];
        }
        this.setState({
            activeComments: activeComments
        });
    }

    extractProcjene(zahtjevZaProcjenu) {
        let res = this.state.deselected.filter(r => r.zahtjevZaProcjenom === zahtjevZaProcjenu.id);
        if (res.length) {
            zahtjevZaProcjenu.procjene = _.xorBy(zahtjevZaProcjenu.procjene, res, 'id');
        }
        return zahtjevZaProcjenu;
    }

    dodajNapomenu(zahtjevZaProcjenuId) {
        this.showLoader();
        this.props.procjenaDataAccess.dodavanjeNesluzbeneNapomeneNaProcjenu(
            zahtjevZaProcjenuId,
            this.state.noveNapomene.find(el => el.id === zahtjevZaProcjenuId).napomena,
            () => this.hideLoader(() => this.resetNapomena(zahtjevZaProcjenuId, () => this.refreshProcjene())),
            () => this.hideLoader()
        );
    }

    resetNapomena(id, callback) {
        this.setState({
            noveNapomene: _.reject(this.state.noveNapomene, (napomena) => napomena.id === id)
        }, callback);
    }

    showModal(name, sifra) {
        this.setState({
            activeModal: {
                type: name,
                id: sifra
            }
        });
    }

    hideModal(callback) {
        this.setState({ activeModal: undefined }, callback);
    }

    showLoader() {
        this.setState(
            {
                loader: true
            }
        );
    }

    hideLoader(callback) {
        this.setState(
            {
                loader: false
            },
            callback
        );
    }

    ispisZahtjevaZaProcjenom(zahtjevZaProcjenom) {
        window.open(endpoints.appEndpoints.print.zahtjevZaProcjenom + storeDocumentAndGenerateKey(zahtjevZaProcjenom), '_blank');
    }

    fetchProcjene(filter = this.state.filter, page = 1) {

        let nextStateCommon = {
            loader: false,
            fetchAttempts: 0,
            filter: filter
        };

        let onFetchSuccess = (data) => {
            if (data.items.length !== 0) {
                let nextStateNonEmpty = {
                    listState: LIST_STATE.populated,
                    procjene: data.items,
                    deselected: this.predeselectProcjene(data.items),
                    pages: data.totalPages,
                    pageActive: page
                };
                this.setState(Object.assign({}, nextStateCommon, nextStateNonEmpty));

            } else {

                let fetchedAll = this.state.value === '';

                let newListState = fetchedAll ? LIST_STATE.empty : LIST_STATE.noResult;
                let nextStateEmpty = {
                    listState: newListState,
                    procjene: [],
                    deselected: []
                };

                this.setState(Object.assign({}, nextStateCommon, nextStateEmpty));
            }
        };

        let onFetchError = () => {
            let fetchAttempts = this.state.fetchAttempts + 1;
            if (fetchAttempts === MAX_API_FETCH_ATTEMPTS) {
                let nextStateError = {
                    listState: LIST_STATE.error
                };
                this.setState(Object.assign({}, nextStateCommon, nextStateError));
            } else {
                this.setState({
                    fetchAttempts: fetchAttempts
                }, () => {
                    this.fetchProcjene(filter, page);
                });
            }

        };

        this.showLoader();

        ProcjenaApi.getProcjenePaginated(
            this.state.value, page, ITEMS_PER_PAGE, this.getPoslovnicaIdForFilter(filter),
            null, null, onFetchSuccess, onFetchError
        );
    }

    refreshProcjene() {
        this.fetchProcjene(this.state.filter, this.state.pageActive);
    }

    getPoslovnicaIdForFilter(filter) {
        return this.FILTER_ITEMS
            ? this.FILTER_ITEMS[filter].apiParam
            : JSON.parse(sessionStorage.poslovnicaId);
    }

    renderInitialComponent() {
        return (
            <SearchResultsMessage
                icon={icons.procjena}
                header={localization.common.ucitavanje}
                content={localization.common.molimoPricekajte}
            />
        );
    }

    renderEmptyComponent() {
        return (
            <SearchResultsMessage
                icon={icons.procjena}
                header={localization.procjene.nemaProcjena}
                content={undefined}
            />
        );
    }

    renderNoResultComponent() {
        return (
            <SearchResultsMessage
                icon={icons.procjena}
                header={localization.common.unesenaPretragaNemaRezultata}
                content={localization.common.pokusajDrugacijuPretragu}
            />
        );
    }

    renderErrorComponent() {
        return (
            <SearchResultsMessage
                icon={icons.procjena}
                header={localization.common.dogodilaSeGreska}
                content={localization.common.pokusajtePonovno}
            />
        );
    }

    renderListComponent() {
        return (
            <React.Fragment>
                <Grid.Row>
                    <Grid.Column>
                        <Message style={styles.headerTable}>
                            <Table structured stackable>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell style={styles.iconCell} />
                                        <Table.HeaderCell style={styles.brojProcjeneCell}>
                                            {localization.procjene.brojZahtjevaZaProcjenom}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell style={styles.klijentCell}>{localization.procjene.klijent}</Table.HeaderCell>
                                        <Table.HeaderCell>{localization.procjene.datumIVrijemeZahtjevaZaProcjenom}</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                            </Table>
                        </Message>
                    </Grid.Column>
                </Grid.Row>
                {this.state.procjene.map(zahtjevZaProcjenu => {
                    return (
                        <Grid.Row key={zahtjevZaProcjenu.id}>
                            <Grid.Column verticalAlign="middle">
                                <Accordion fluid styled>
                                    <Accordion.Title
                                        active={this.state.activeZahtjevZaProcjenu.includes(zahtjevZaProcjenu.id)}
                                        index={zahtjevZaProcjenu.id}
                                        onClick={this.expandZahtjevZaProcjenu}
                                    >
                                        <Table structured stackable>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.Cell style={styles.iconCell}>
                                                        <Icon name="dropdown" />
                                                    </Table.Cell>
                                                    <Table.Cell style={styles.brojProcjeneCell}>
                                                        {zahtjevZaProcjenu.rbrZahtjevZaProcjenu}
                                                    </Table.Cell>
                                                    <Table.Cell style={styles.klijentCell}>
                                                        {zahtjevZaProcjenu.klijent.tipKlijenta === enums.tipKlijenta.FIZICKA_OSOBA
                                                            ? zahtjevZaProcjenu.klijent.ime + ' ' + zahtjevZaProcjenu.klijent.prezime
                                                            : zahtjevZaProcjenu.klijent.naziv}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {moment(zahtjevZaProcjenu.datumIVrijeme).format('DD.MM.YYYY. HH:mm')}
                                                    </Table.Cell>
                                                </Table.Row>
                                            </Table.Header>
                                        </Table>
                                    </Accordion.Title>
                                    <Accordion.Content active={this.state.activeZahtjevZaProcjenu.includes(zahtjevZaProcjenu.id)}>
                                        <ProcjenaContent
                                            zahtjevZaProcjenu={zahtjevZaProcjenu}
                                            showArhivirani={false}
                                            imageUploadModal={
                                                <div style={styles.artikliContainer}>
                                                    <Header as="h4" style={styles.artikliHeader}>
                                                        {localization.rezervacije.artikli}
                                                    </Header>
                                                    <Modal
                                                        open={
                                                            this.state.activeModal &&
                                                            this.state.activeModal.type === modaliEnums.galerijaSlika &&
                                                            this.state.activeModal.id === zahtjevZaProcjenu.id
                                                        }
                                                        trigger={
                                                            <Button
                                                                icon="image"
                                                                content={localization.common.galerijaSlika}
                                                                onClick={() => this.showModal(modaliEnums.galerijaSlika, zahtjevZaProcjenu.id)}
                                                            />
                                                        }
                                                    >
                                                        <Header icon="image" content={localization.common.galerijaSlika} />
                                                        <Modal.Content>
                                                            <ImageUploadModal
                                                                imageIds={zahtjevZaProcjenu.imageIds}
                                                                saveImages={(images, callback) => {
                                                                    this.props.procjenaDataAccess.dodajSlikeNaProcjenu(
                                                                        zahtjevZaProcjenu.id, images,
                                                                        () => {
                                                                            // TODO @Kec This is freaking weird, fix this mess after paginating all pages since ImageUploadModal is used in multiple places
                                                                            callback();
                                                                            this.refreshProcjene();
                                                                        }
                                                                    );
                                                                }}
                                                                deleteImages={(imageIds, callback) => {
                                                                    this.props.procjenaDataAccess.izbrisiSlikeSaProcjene(
                                                                        zahtjevZaProcjenu.id, imageIds,
                                                                        () => {
                                                                            // TODO @Kec This is freaking weird, fix this mess after paginating all pages since ImageUploadModal is used in multiple places
                                                                            callback();
                                                                            this.refreshProcjene();
                                                                        }
                                                                    );
                                                                }}
                                                                closeModal={() => this.hideModal()}
                                                            />
                                                        </Modal.Content>
                                                    </Modal>
                                                </div>
                                            }
                                            enableRowChecking={true}
                                            listOfDeselected={this.state.deselected}
                                            onRowSelected={this.onDeselectProcjena}
                                        />
                                        <div style={styles.actionButtons}>
                                            <Button
                                                icon="print"
                                                color="green"
                                                content={localization.procjene.ispisProcjene}
                                                onClick={this.ispisZahtjevaZaProcjenom.bind(this, zahtjevZaProcjenu)}
                                            />
                                            {_.filter(this.state.deselected, zzp => zzp.zahtjevZaProcjenom === zahtjevZaProcjenu.id).length !==
                                            zahtjevZaProcjenu.procjene.length ? (
                                                <Modal
                                                    open={
                                                        this.state.activeModal &&
                                                        this.state.activeModal.type === modaliEnums.arhiviranjeProcjene &&
                                                        this.state.activeModal.id === zahtjevZaProcjenu.id
                                                    }
                                                    trigger={
                                                        <Button
                                                            icon="archive"
                                                            color="red"
                                                            content={localization.procjene.arhiviraj}
                                                            onClick={() => this.showModal(modaliEnums.arhiviranjeProcjene, zahtjevZaProcjenu.id)}
                                                        />
                                                    }
                                                >
                                                    <Header icon="archive" content={localization.procjene.arhiviranjeProcjene} />
                                                    <Modal.Content>
                                                        <ArhiviranjeProcjeneModal
                                                            zahtjevZaProcjenu={this.extractProcjene(Object.assign({}, zahtjevZaProcjenu))}
                                                            closeModal={() => this.hideModal()}
                                                            onArhiviranjeSuccess={this.refreshProcjene}
                                                        />
                                                    </Modal.Content>
                                                </Modal>
                                            ) : (
                                                <Button icon="archive" color="red" disabled={true} content={localization.procjene.arhiviraj} />
                                            )}
                                        </div>
                                        {zahtjevZaProcjenu.sluzbenaNapomena ? (
                                            <Message>
                                                <Message.Header>{localization.common.sluzbenaNapomena}</Message.Header>
                                                <p>{zahtjevZaProcjenu.sluzbenaNapomena}</p>
                                            </Message>
                                        ) : null}
                                        <Accordion fluid styled>
                                            <Accordion.Title
                                                active={this.state.activeComments.includes(zahtjevZaProcjenu.id)}
                                                index={zahtjevZaProcjenu.id}
                                                onClick={this.expandComments}
                                            >
                                                <Header as="h5" dividing style={styles.accordionHeaderContainer}>
                                                    <Icon name="dropdown" />
                                                    {localization.common.napomene}
                                                    {zahtjevZaProcjenu.nesluzbeneNapomene.length ? (
                                                        <Icon name="exclamation circle" color="red" style={styles.semaforStyle} />
                                                    ) : null}
                                                </Header>
                                            </Accordion.Title>
                                            <Accordion.Content active={this.state.activeComments.includes(zahtjevZaProcjenu.id)}>
                                                <Diskusija
                                                    napomene={zahtjevZaProcjenu.nesluzbeneNapomene}
                                                    textAreaValue={
                                                        this.state.noveNapomene.find(el => el.id === zahtjevZaProcjenu.id)
                                                            ? this.state.noveNapomene.find(el => el.id === zahtjevZaProcjenu.id).napomena
                                                            : ''
                                                    }
                                                    onChangeNovaNapomena={this.onChangeNovaNapomena.bind(this, zahtjevZaProcjenu.id)}
                                                    onDodajNapomenu={() => this.dodajNapomenu(zahtjevZaProcjenu.id)}
                                                />
                                            </Accordion.Content>
                                        </Accordion>
                                    </Accordion.Content>
                                </Accordion>
                            </Grid.Column>
                        </Grid.Row>
                    );
                })}
                <Grid.Row centered>
                    <Pagination
                        activePage={this.state.pageActive}
                        totalPages={this.state.pages}
                        onPageChange={this.onChangePage}
                        ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
                        firstItem={{ content: <Icon name="angle double left" />, icon: true }}
                        lastItem={{ content: <Icon name="angle double right" />, icon: true }}
                        prevItem={{ content: <Icon name="angle left" />, icon: true }}
                        nextItem={{ content: <Icon name="angle right" />, icon: true }}
                    />
                </Grid.Row>
            </React.Fragment>
        );
    }

    render() {
        return (
            <div>
                <Segment tertiary>
                    <Grid stackable>
                        <Grid.Row columns={6}>
                            <Grid.Column verticalAlign="middle">
                                <Header as="h2">{localization.procjene.procjene}</Header>
                            </Grid.Column>
                            <Grid.Column verticalAlign="middle">
                                <Search
                                    input={{ fluid: true }}
                                    loading={this.state.isSearchLoading}
                                    onSearchChange={this.handleSearchChange}
                                    value={this.state.value}
                                    open={false}
                                />
                            </Grid.Column>
                            {sessionStorage.userRole === enums.tipKorisnika.DJELATNIK ? (
                                <React.Fragment>
                                    <Grid.Column />
                                    <Grid.Column />
                                    <Grid.Column />
                                    <Grid.Column textAlign="right" verticalAlign="middle">
                                        <Button
                                            icon="asterisk"
                                            basic
                                            color="black"
                                            content={localization.procjene.novaProcjena}
                                            onClick={this.onNoviZahtjevZaProcjenu}
                                        />
                                    </Grid.Column>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <Grid.Column />
                                    <Grid.Column />
                                    <Grid.Column />
                                    <Grid.Column textAlign="right" verticalAlign="middle">
                                        <FilterSortButton
                                            buttonName={localization.common.filtriranje}
                                            filteringOptions={this.FILTER_ITEMS.map((f) => f.name)}
                                            activeFilter={this.state.filter}
                                            callback={this.applyFilterAndSort}
                                        />
                                    </Grid.Column>
                                </React.Fragment>
                            )}
                        </Grid.Row>
                        {(() => {
                            switch (this.state.listState) {
                                case LIST_STATE.initial:
                                    return this.renderInitialComponent();
                                case LIST_STATE.populated:
                                    return this.renderListComponent();
                                case LIST_STATE.empty:
                                    return this.renderEmptyComponent();
                                case LIST_STATE.noResult:
                                    return this.renderNoResultComponent();
                                case LIST_STATE.error:
                                    return this.renderErrorComponent();
                            }
                        })()}
                    </Grid>
                </Segment>
                <Dimmer active={this.state.loader} page>
                    <Loader />
                </Dimmer>
            </div>
        );
    }
}

ZahtjeviZaProcjenu.propTypes = {
    history: PropTypes.object.isRequired,
    poslovnice: PropTypes.array.isRequired,
    procjenaDataAccess: PropTypes.object.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {
        poslovnice: state.mjestaReducer.poslovnice || []
    };
};

let mapDispatchToProps = function(dispatch) {
    return {
        procjenaDataAccess: bindActionCreators(ProcjenaDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ZahtjeviZaProcjenu);
