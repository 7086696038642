import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Dimmer, Grid, Header, Loader, Modal, Segment } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';

import localization from '../../locales/localization';
import endpoints from '../../environments/endpoints';
import { storeDocumentAndGenerateKey } from '../../utils/PrintingDocumentsHandler';
import * as ProdajaDataAccess from '../../data_access/ProdajaDataAccess';
import * as RadniNalogDataAccess from '../../data_access/RadniNalogDataAccess';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import OdabirKlijenta from '../common_components/OdabirKlijenta';
import OdabirUsluge from '../common_components/OdabirUsluge';
import Napomene from '../common_components/Napomene';
import ImageUploadModal from '../common_components/ImageUploadModal';
import icons from '../../utils/DefinedIcons';
import FormatIznosa from '../common_components/FormatIznosa';

const styles = {
    confirmButton: {
        textAlign: 'center',
        marginBottom: 16
    },
    kolicinaIcijena: {
        fontFamily: 'monospace'
    }
};

class NoviRadniNalog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            client: undefined,
            usluga: [],
            ukupno: undefined,
            ocekivaniDatumZavrsetka: moment().add(7, 'days'),
            sluzbenaNapomena: '',
            nesluzbenaNapomena: '',
            viewModel: {
                kartice: [],
                predmeti: [],
                skladisneStavke: [],
                usluge: []
            },
            napraviRadniNalogEnabled: false,
            potvrdaRadnogNaloga: false,
            loader: true,
            radniNalogId: undefined
        };
        this.onViewModelLoaded = this.onViewModelLoaded.bind(this);
        this.handleOdabirKlijenta = this.handleOdabirKlijenta.bind(this);
        this.handleOdabirUsluge = this.handleOdabirUsluge.bind(this);
        this.onChangeOcekivaniDatumZavrsetka = this.onChangeOcekivaniDatumZavrsetka.bind(this);
        this.onChangeNapomena = this.onChangeNapomena.bind(this);
        this.calculateUkupno = this.calculateUkupno.bind(this);
        this.enableNapraviRadniNalogButton = this.enableNapraviRadniNalogButton.bind(this);
        this.checkKomponenteRadnogNaloga = this.checkKomponenteRadnogNaloga.bind(this);
        this.showHidePotvrdaRadnogNaloga = this.showHidePotvrdaRadnogNaloga.bind(this);
        this.showLoader = this.showLoader.bind(this);
        this.hideLoader = this.hideLoader.bind(this);
        this.napraviRadniNalog = this.napraviRadniNalog.bind(this);
        this.openRadniNalog = this.openRadniNalog.bind(this);
        this.redirect = this.redirect.bind(this);

        this.props.prodajaDataAccess.getProdajaViewModel(JSON.parse(sessionStorage.poslovnicaId), this.onViewModelLoaded);
    }

    onViewModelLoaded(data) {
        this.setState({
            viewModel: data,
            loader: false
        });
    }

    handleOdabirKlijenta(clientSelected) {
        this.setState({ client: clientSelected }, this.enableNapraviRadniNalogButton);
    }

    handleOdabirUsluge(uslugeSelected) {
        this.setState({ usluga: uslugeSelected }, this.calculateUkupno);
    }

    onChangeOcekivaniDatumZavrsetka(dateTime) {
        this.setState(
            {
                ocekivaniDatumZavrsetka: dateTime
            },
            this.enableNapraviRadniNalogButton
        );
    }

    onChangeNapomena(e, { name, value }) {
        this.setState({ [name]: value });
    }

    calculateUkupno() {
        let ukupno = 0,
            missingData = false;
        this.state.usluga.forEach(usluga => {
            if (usluga.kolicina && usluga.jedCijena) {
                ukupno += usluga.kolicina * usluga.jedCijena;
            } else {
                missingData = true;
            }
            if (usluga.stavkeRadnogNaloga && usluga.stavkeRadnogNaloga.length) {
                usluga.stavkeRadnogNaloga.forEach(srn => {
                    if (srn.kolicina && srn.cijena) {
                        ukupno += srn.kolicina * srn.cijena;
                    } else {
                        missingData = true;
                    }
                });
            }
        });
        this.setState(
            {
                ukupno: !missingData ? ukupno : undefined
            },
            this.enableNapraviRadniNalogButton
        );
    }

    enableNapraviRadniNalogButton() {
        this.setState({
            napraviRadniNalogEnabled: this.checkKomponenteRadnogNaloga()
        });
    }

    checkKomponenteRadnogNaloga() {
        if (!this.state.client) {
            return false;
        }
        if (!this.state.usluga.length) {
            return false;
        } else {
            for (let i = 0; i < this.state.usluga.length; i++) {
                if (!this.state.usluga[i].kolicina || !this.state.usluga[i].jedCijena) {
                    return false;
                }
                if (
                    this.state.usluga[i].klijentovPredmet &&
                    (!this.state.usluga[i].klijentovPredmet.kolicina || !this.state.usluga[i].klijentovPredmet.cijena)
                ) {
                    return false;
                }
                if (this.state.usluga[i].stavkeRadnogNaloga && this.state.usluga[i].stavkeRadnogNaloga.length) {
                    for (let j = 0; j < this.state.usluga[i].stavkeRadnogNaloga.length; j++) {
                        if (!this.state.usluga[i].stavkeRadnogNaloga[j].kolicina || !this.state.usluga[i].stavkeRadnogNaloga[j].cijena) {
                            return false;
                        }
                    }
                }
            }
        }
        if (!this.state.ocekivaniDatumZavrsetka) {
            return false;
        }
        return true;
    }

    showHidePotvrdaRadnogNaloga(value, callback) {
        if (typeof callback === 'function') {
            this.setState({ potvrdaRadnogNaloga: value }, callback);
        } else {
            this.setState({ potvrdaRadnogNaloga: value });
        }
    }

    showLoader(callback) {
        this.setState(
            {
                loader: true
            },
            callback
        );
    }

    hideLoader(callback, data) {
        this.setState(
            {
                loader: false
            },
            callback.bind(this, data)
        );
    }

    napraviRadniNalog() {
        let radniNalog = {
            poslovnicaId: JSON.parse(sessionStorage.poslovnicaId),
            klijentId: this.state.client.id,
            ocekivaniDatumZavrsetka: this.state.ocekivaniDatumZavrsetka.format('YYYY-MM-DD'),
            sluzbenaNapomena: this.state.sluzbenaNapomena || null,
            nesluzbenaNapomena: this.state.nesluzbenaNapomena || null,
            radniNalozi: this.state.usluga.map(usluga => {
                return {
                    usluga: {
                        uslugaId: usluga.id,
                        kolicina: parseFloat(usluga.kolicina),
                        ocekivanaJedCijena: parseFloat(usluga.jedCijena)
                    },
                    klijentovPredmet: usluga.klijentovPredmet
                        ? {
                              predmetId: usluga.klijentovPredmet.id,
                              kolicina: parseFloat(usluga.klijentovPredmet.kolicina),
                              jedVrijednost: parseFloat(usluga.klijentovPredmet.cijena)
                          }
                        : null,
                    stavkeRadnogNaloga:
                        usluga.stavkeRadnogNaloga && usluga.stavkeRadnogNaloga.length
                            ? usluga.stavkeRadnogNaloga.map(srn => {
                                  return {
                                      skladisniPredmetId: srn.id,
                                      kolicina: parseFloat(srn.kolicina),
                                      jedCijena: parseFloat(srn.cijena)
                                  };
                              })
                            : []
                };
            })
        };
        this.props.radniNalogDataAccess.createRadniNalog(radniNalog, this.hideLoader.bind(this, this.openRadniNalog));
    }

    openRadniNalog(radniNalog) {
        window.open(endpoints.appEndpoints.print.radniNalog + storeDocumentAndGenerateKey(radniNalog), '_blank');
        if (this.state.usluga.filter(u => u.klijentovPredmet).length) {
            this.setState({
                radniNalogId: radniNalog.id
            });
        } else {
            this.redirect();
        }
    }

    redirect() {
        this.props.history.goBack();
    }

    render() {
        return (
            <div>
                <Segment tertiary>
                    <Header as="h2">{localization.radniNalog.noviRadniNalog}</Header>
                </Segment>
                <Segment tertiary>
                    <OdabirKlijenta onClientSelected={this.handleOdabirKlijenta} />
                </Segment>
                <Segment tertiary>
                    <OdabirUsluge viewModel={this.state.viewModel} onUslugaSelected={this.handleOdabirUsluge} />
                </Segment>
                <Segment tertiary>
                    <Grid stackable>
                        <Grid.Row columns={6}>
                            <Grid.Column />
                            <Grid.Column />
                            <Grid.Column />
                            <Grid.Column />
                            <Grid.Column verticalAlign="middle">
                                <Header as="h3">{localization.common.ukupno + ':'}</Header>
                            </Grid.Column>
                            <Grid.Column verticalAlign="middle">
                                <Header as="h3" style={styles.kolicinaIcijena}>
                                    <FormatIznosa value={this.state.ukupno} />
                                </Header>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Segment tertiary>
                    <Grid stackable>
                        <Grid.Row columns={4}>
                            <Grid.Column verticalAlign="middle">
                                <Header as="h3">{localization.radniNalog.ocekivaniDatumZavrsetka}</Header>
                            </Grid.Column>
                            <Grid.Column verticalAlign="middle">
                                <DatePicker
                                    customInput={
                                        <Button fluid compact basic color="black">
                                            {this.state.ocekivaniDatumZavrsetka.format('DD.MM.YYYY.')}
                                        </Button>
                                    }
                                    dateFormat="DD.MM.YYYY."
                                    locale="hr-HR"
                                    minDate={moment()}
                                    selected={this.state.ocekivaniDatumZavrsetka}
                                    onChange={this.onChangeOcekivaniDatumZavrsetka}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Napomene
                    sluzbenaNapomenaHeader={localization.radniNalog.sluzbenaNapomenaRadnogNaloga}
                    nesluzbenaNapomenaHeader={localization.radniNalog.nesluzbenaNapomenaRadnogNaloga}
                    onChangeNapomena={this.onChangeNapomena}
                />
                <div style={styles.confirmButton}>
                    <Modal
                        open={this.state.potvrdaRadnogNaloga}
                        closeOnDimmerClick={false}
                        trigger={
                            <Button
                                color="green"
                                content={localization.radniNalog.napraviRadniNalog}
                                disabled={!this.state.napraviRadniNalogEnabled}
                                onClick={this.showHidePotvrdaRadnogNaloga.bind(this, true)}
                            />
                        }
                    >
                        {!this.state.radniNalogId ? (
                            <React.Fragment>
                                <Header icon={icons.radniNalog} content={localization.radniNalog.potvrdaRadnogNaloga} />
                                <Modal.Content>
                                    <p>{localization.radniNalog.pitanjePotvrdeRadnogNaloga}</p>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button icon="remove" content={localization.common.odustani} onClick={this.showHidePotvrdaRadnogNaloga.bind(this, false)} />
                                    <Button
                                        icon="checkmark"
                                        color="green"
                                        content={localization.radniNalog.napraviRadniNalog}
                                        onClick={this.showLoader.bind(this, this.napraviRadniNalog)}
                                    />
                                </Modal.Actions>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Header icon="image" content={localization.radniNalog.ucitavanjeSlika} />
                                <Modal.Content>
                                    <ImageUploadModal
                                        saveImages={this.props.radniNalogDataAccess.dodajSlikeNaRadniNalog.bind(this, this.state.radniNalogId)}
                                        closeModal={this.showHidePotvrdaRadnogNaloga.bind(this, false, this.redirect)}
                                    />
                                </Modal.Content>
                            </React.Fragment>
                        )}
                    </Modal>
                </div>
                <Dimmer active={this.state.loader} page>
                    <Loader />
                </Dimmer>
            </div>
        );
    }
}

NoviRadniNalog.propTypes = {
    history: PropTypes.object.isRequired,
    predmeti: PropTypes.array.isRequired,
    prodajaDataAccess: PropTypes.object.isRequired,
    radniNalogDataAccess: PropTypes.object.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {
    };
};

let mapDispatchToProps = function(dispatch) {
    return {
        prodajaDataAccess: bindActionCreators(ProdajaDataAccess, dispatch),
        radniNalogDataAccess: bindActionCreators(RadniNalogDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NoviRadniNalog);
