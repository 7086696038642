export function formatNumber(number) {
    if (number.startsWith('+')) {
        number = number.slice(0, 4) + ' ' + number.slice(4, 6) + ' ' + number.slice(6, 8) + ' ' + number.slice(8, 10) + ' ' + number.slice(10);
    } else if (number.startsWith('00')) {
        number = number.slice(0, 5) + ' ' + number.slice(5, 7) + ' ' + number.slice(7, 9) + ' ' + number.slice(9, 11) + ' ' + number.slice(11);
    } else {
        number = number.slice(0, 3) + ' ' + number.slice(3, 5) + ' ' + number.slice(5, 7) + ' ' + number.slice(7);
    }
    return number;
}
