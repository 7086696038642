import React from 'react';
import PropTypes from 'prop-types';
import PrintProvider, { Print, NoPrint } from 'react-easy-print';
import ZaglavljeTvrtke from './common_components/ZaglavljeTvrtke';
import ZaglavljeKlijenta from './common_components/ZaglavljeKlijenta';
import { Button, Table } from 'semantic-ui-react';
import localization from '../../locales/localization';
import DatumIMjestoRacun from './common_components/DatumIMjestoRacun';
import ValutaNacinPlacanjaRacun from './common_components/ValutaNacinPlacanjaRacun';
import moment from 'moment';
import FormatIznosa from '../common_components/FormatIznosa';

const styles = {
    container: {
        padding: 24
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    zaglavlja: {
        width: '50%'
    },
    zaglavljeTvrtke: {
        paddingBottom: 24
    },
    poslovnaJedinica: {
        fontSize: 14
    },
    datumImjesto: {
        paddingBottom: 8
    },
    p: {
        fontSize: 10
    },
    title: {
        paddingTop: 48
    },
    table: {
        fontSize: 'xx-small'
    },
    tableCell: {
        paddingTop: 'unset',
        paddingBottom: 'unset'
    },
    tableCellKol: {
        paddingTop: 'unset',
        paddingBottom: 'unset',
        fontFamily: 'monospace'
    },
    tableCellUkupno: {
        paddingTop: 'unset',
        paddingBottom: 'unset',
        verticalAlign: 'top'
    },
    footerContainer: {
        display: 'flex',
        paddingTop: 16,
        paddingBottom: 16
    },
    mp: {
        fontSize: 10,
        textAlign: 'center'
    },
    footerTvrtka: {
        textAlign: 'center'
    },
    printButton: {
        textAlign: 'center',
        paddingBottom: 8
    }
};

export default class Predracun extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            document: JSON.parse(sessionStorage.getItem(this.props.match.params.id))
        };
        this.printDocument = this.printDocument.bind(this);
    }

    printDocument() {
        window.print();
    }

    calculateUkupno(artikli) {
        let ukupno = 0;
        artikli.forEach(a => {
            ukupno += a.kolicina * a.jedCijena;
        });
        return ukupno;
    }

    render() {
        let ukupno = this.calculateUkupno(this.state.document.stavkePredracuna);
        return (
            <PrintProvider>
                <Print>
                    <div style={styles.container}>
                        <div style={styles.headerContainer}>
                            <div style={styles.zaglavlja}>
                                {localization.printingComponents.common.prodavatelj + ':'}
                                <div style={styles.zaglavljeTvrtke}>
                                    <ZaglavljeTvrtke firma={this.state.document.firma} small={true} />
                                </div>
                                <p style={styles.poslovnaJedinica}>
                                    {localization.printingComponents.common.poslovnaJedinica + ': '}
                                    <b>{this.state.document.poslovnica.naziv}</b>
                                </p>
                                <div style={styles.datumImjesto}>
                                    <DatumIMjestoRacun
                                        datum={moment(this.state.document.datumIVrijeme).format('DD.MM.YYYY.')}
                                        mjesto={this.state.document.poslovnica.naselje}
                                        vrijeme={moment(this.state.document.datumIVrijeme).format('HH:mm')}
                                    />
                                </div>
                                <div style={styles.datumImjesto}>
                                    <ValutaNacinPlacanjaRacun
                                        valutaPlacanja={moment(this.state.document.datumIstekaPredracuna).format('DD.MM.YYYY.')}
                                        nacinPlacanja={localization.printingComponents.common.transakcijskiRacun.toUpperCase()}
                                        datumOtpreme={moment(this.state.document.datumIstekaPredracuna).format('DD.MM.YYYY.')}
                                        nacinOtpreme={'OSOBNO PREUZIMANJE'}
                                    />
                                </div>
                            </div>
                            <div style={styles.zaglavlja}>
                                {localization.printingComponents.common.kupac + ':'}
                                <br />
                                <br />
                                <ZaglavljeKlijenta klijent={this.state.document.klijent} />
                                <div style={styles.title}>
                                    <h3>
                                        {localization.printingComponents.predracun.naslov +
                                            ' ' +
                                            localization.printingComponents.common.br +
                                            ' ' +
                                            this.state.document.rbrPredracun}
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <Table style={styles.table}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={styles.tableCell} />
                                    <Table.HeaderCell style={styles.tableCell}>{localization.artikl.sifra}</Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell}>{localization.artikl.naziv}</Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell} textAlign="center" collapsing>
                                        {localization.artikl.mjernaJedinica}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell} textAlign="center" collapsing>
                                        {localization.common.kolicina}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell} textAlign="center" collapsing>
                                        {localization.common.cijena}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell} textAlign="center" collapsing>
                                        {localization.common.vrijednost}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell} textAlign="center" collapsing>
                                        {localization.common.popust}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell} textAlign="center" collapsing>
                                        {localization.common.iznos}
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.state.document.stavkePredracuna.map((sp, index) => {
                                    return (
                                        <Table.Row key={index}>
                                            <Table.Cell style={styles.tableCell}>{index + 1}</Table.Cell>
                                            <Table.Cell style={styles.tableCell}>{sp.sifraArtikla}</Table.Cell>
                                            <Table.Cell style={styles.tableCell}>
                                                <b>{sp.nazivArtikla}</b>
                                            </Table.Cell>
                                            <Table.Cell style={styles.tableCell} textAlign="center" collapsing>
                                                {sp.mjernaJedinica}
                                            </Table.Cell>
                                            <Table.Cell style={styles.tableCellKol} textAlign="right" collapsing>
                                                {sp.kolicina}
                                            </Table.Cell>
                                            <Table.Cell style={styles.tableCellKol} textAlign="right" collapsing>
                                                <FormatIznosa value={sp.jedCijena} suffix={null} />
                                            </Table.Cell>
                                            <Table.Cell style={styles.tableCellKol} textAlign="right" collapsing>
                                                <FormatIznosa value={sp.kolicina * sp.jedCijena} suffix={null} />
                                            </Table.Cell>
                                            <Table.Cell style={styles.tableCellKol} textAlign="right" collapsing>
                                                {(0).toFixed(2)}
                                            </Table.Cell>
                                            <Table.Cell style={styles.tableCellKol} textAlign="right" collapsing>
                                                <FormatIznosa value={sp.kolicina * sp.jedCijena} />
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                            <Table.Footer>
                                <Table.Row>
                                    <Table.HeaderCell style={styles.tableCell} colSpan={7} />
                                    <Table.HeaderCell style={styles.tableCellUkupno} textAlign="center">
                                        <b>{localization.common.ukupno + ':'}</b>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCellKol} textAlign="right" collapsing>
                                        <b>
                                            <FormatIznosa value={ukupno} /><br />
                                        </b>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        </Table>
                        {this.state.document.sluzbenaNapomena ? (
                            <React.Fragment>
                                <br />
                                <div>
                                    <p style={styles.p}>
                                        <b>{localization.printingComponents.common.sluzbenaNapomena + ':'}</b>
                                    </p>
                                    <p style={styles.p}>{this.state.document.sluzbenaNapomena}</p>
                                </div>
                            </React.Fragment>
                        ) : null}
                        <p style={styles.mp}>{localization.printingComponents.common.dokumentObradenElektronickimPutem}</p>
                        <div style={styles.footerTvrtka}>
                            <p style={styles.p}>{this.state.document.firma.oFirmi}</p>
                        </div>
                    </div>
                </Print>
                <NoPrint>
                    <div style={styles.printButton}>
                        <Button color="green" icon="print" size="massive" content={localization.common.ispis} onClick={this.printDocument} />
                    </div>
                </NoPrint>
            </PrintProvider>
        );
    }
}

Predracun.propTypes = {
    match: PropTypes.object.isRequired
};
