import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Form } from 'semantic-ui-react';
import countries from '../../utils/Countries';
import postanskiBrojevi from '../../utils/PostanskiBrojevi';
import localization from '../../locales/localization';
import enums from '../../utils/Enums';
import moment from 'moment';

const styles = {
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 12
    }
};

const clientType = [
    {
        key: enums.tipKlijenta.FIZICKA_OSOBA,
        text: localization.klijent.fizickaOsoba,
        value: enums.tipKlijenta.FIZICKA_OSOBA
    },
    {
        key: enums.tipKlijenta.PRAVNA_OSOBA,
        text: localization.klijent.pravnaOsoba,
        value: enums.tipKlijenta.PRAVNA_OSOBA
    }
];

const countriesSelection = countries.countries.map(country => {
    return {
        key: country.countryCode,
        text: country.name,
        value: country.name,
        flag: country.countryCode
    };
});

const postanskiBrojeviNaselja = postanskiBrojevi.postanskiBrojevi.map(pbn => {
    return {
        key: pbn.postanskiBroj,
        text: pbn.postanskiBroj + ' ' + pbn.naselje,
        value: pbn.postanskiBroj + ' ' + pbn.naselje
    };
});

const idType = [
    {
        key: enums.idBrojTip.OIB,
        text: localization.klijent.oib,
        value: enums.idBrojTip.OIB
    },
    {
        key: enums.idBrojTip.BR_OSOBNE,
        text: localization.klijent.brojOsobne,
        value: enums.idBrojTip.BR_OSOBNE
    },
    {
        key: enums.idBrojTip.BR_PUT,
        text: localization.klijent.brojPutovnice,
        value: enums.idBrojTip.BR_PUT
    }
];

const HRVATSKA = 'Hrvatska';

let validateOIBLength = oib => {
    return oib.length !== 11;
};
let validateBrOsobneLength = brojOsobne => {
    return brojOsobne.length !== 9;
};
let validateEmail = email => {
    // eslint-disable-next-line
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};
let validateNumber = num => {
    // eslint-disable-next-line
    let re = /^(\d+)$/;
    return re.test(num);
};
let validatePhoneNumber = phone => {
    // eslint-disable-next-line
    let re = /^(\+?\d+)$/;
    return re.test(phone);
};
let validateDatumRodjenja = datumRodenja => {
    // eslint-disable-next-line
    let re = /^(\d{2}.\d{2}.\d{4}.)$/;
    return re.test(datumRodenja);
};

class Klijent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tipKlijenta: this.props.clientType ? this.props.clientType : this.props.client.tipKlijenta || enums.tipKlijenta.FIZICKA_OSOBA,
            ime: this.props.client.ime || '',
            prezime: this.props.client.prezime || '',
            drzava: this.props.client.drzava || HRVATSKA,
            idBrojTip: this.props.client.idBrojTip || enums.idBrojTip.OIB,
            idBroj: this.props.client.idBroj || '',
            datumRodenja: this.props.client.datumRodenja ? moment(this.props.client.datumRodenja).format('DD.MM.YYYY.') : '',
            adresa: this.props.client.adresa || '',
            kontaktBroj: this.props.client.kontaktBroj || '',
            kontaktEmail: this.props.client.kontaktEmail || '',
            naziv: this.props.client.naziv || '',
            kontaktOsoba: this.props.client.kontaktOsoba || '',
            napomenaOpreza: this.props.client.napomenaOpreza || '',
            postanskiBrojNaselje: this.props.client.postanskiBrojNaselje || ''
        };
        this.state = Object.assign(this.state, this.checkKlijentRequiredFields());
        this.handleChangeType = this.handleChangeType.bind(this);
        this.handleChangeState = this.handleChangeState.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeOIB = this.handleChangeOIB.bind(this);
        this.handleChangeBrOsobne = this.handleChangeBrOsobne.bind(this);
        this.handleChangeDatumRodenja = this.handleChangeDatumRodenja.bind(this);
        this.handleChangeKontaktBroj = this.handleChangeKontaktBroj.bind(this);
        this.handleChangeNumber = this.handleChangeNumber.bind(this);
        this.checkFizickaOsoba = this.checkFizickaOsoba.bind(this);
        this.checkPravnaOsoba = this.checkPravnaOsoba.bind(this);
        this.checkIdBroj = this.checkIdBroj.bind(this);
        this.checkAdresa = this.checkAdresa.bind(this);
        this.checkDatumRodenja = this.checkDatumRodenja.bind(this);
        this.checkKontaktBroj = this.checkKontaktBroj.bind(this);
        this.checkKontaktEmail = this.checkKontaktEmail.bind(this);
        this.checkKlijentRequiredFields = this.checkKlijentRequiredFields.bind(this);
        this.enableSaveButton = this.enableSaveButton.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    handleChangeType(e, { name, value }) {
        this.setState(
            {
                tipKlijenta: value,
                ime: this.props.client.ime || '',
                prezime: this.props.client.prezime || '',
                drzava: this.props.client.drzava || HRVATSKA,
                idBrojTip: this.props.client.idBrojTip || enums.idBrojTip.OIB,
                idBroj: this.props.client.idBroj || '',
                idBrojError: false,
                datumRodenja: this.props.client.datumRodenja ? moment(this.props.client.datumRodenja).format('DD.MM.YYYY.') : '',
                datumRodenjaError: false,
                adresa: this.props.client.adresa || '',
                adresaError: false,
                kontaktBroj: this.props.client.kontaktBroj || '',
                kontaktBrojError: false,
                kontaktEmail: this.props.client.kontaktEmail || '',
                kontaktEmailError: false,
                naziv: this.props.client.naziv || '',
                kontaktOsoba: this.props.client.kontaktOsoba || '',
                napomenaOpreza: this.props.client.napomenaOpreza || '',
                postanskiBrojNaselje: this.props.client.postanskiBrojNaselje || ''
            },
            this.enableSaveButton
        );
    }

    handleChangeState(e, { name, value }) {
        if (value === HRVATSKA) {
            this.setState(
                {
                    drzava: value,
                    idBrojTip: enums.idBrojTip.OIB
                },
                this.enableSaveButton
            );
        } else {
            this.setState(
                {
                    drzava: value,
                    idBrojTip: enums.idBrojTip.BR_PUT
                },
                this.enableSaveButton
            );
        }
    }

    handleChange(e, { name, value }) {
        this.setState({ [name]: value }, this.enableSaveButton);
    }

    handleChangeOIB(e, { name, value }) {
        if ((validateNumber(value) && value.length <= 11) || value === '') {
            this.setState({ [name]: value }, this.enableSaveButton);
        }
    }

    handleChangeBrOsobne(e, { name, value }) {
        if ((validateNumber(value) && value.length <= 9) || value === '') {
            this.setState({ [name]: value }, this.enableSaveButton);
        }
    }

    handleChangeDatumRodenja(e, { value }) {
        // eslint-disable-next-line
        let re = /^[0-9\.]*$/;
        if (re.test(value)) {
            this.setState({ datumRodenja: value }, this.enableSaveButton);
        }
    }

    handleChangeKontaktBroj(e, { value }) {
        if (validatePhoneNumber(value) || value === '' || value === '+') {
            this.setState({ kontaktBroj: value }, this.enableSaveButton);
        }
    }

    handleChangeNumber(e, { name, value }) {
        if (validateNumber(value) || value === '') {
            this.setState({ [name]: value }, this.enableSaveButton);
        }
    }

    checkFizickaOsoba() {
        if (!this.state.ime || !this.state.prezime || !this.state.drzava || !this.state.idBrojTip || !this.state.idBroj) {
            return false;
        }
        return true;
    }

    checkPravnaOsoba() {
        if (!this.state.naziv || !this.state.drzava || !this.state.idBrojTip || !this.state.idBroj) {
            return false;
        }
        return true;
    }

    checkIdBroj() {
        let idBrojError = false;
        if (this.state.idBrojTip === enums.idBrojTip.OIB) {
            idBrojError = validateOIBLength(this.state.idBroj);
        }
        if (this.state.idBrojTip === enums.idBrojTip.BR_OSOBNE) {
            idBrojError = validateBrOsobneLength(this.state.idBroj);
        }
        return idBrojError;
    }

    checkAdresa() {
        let adresaError = false;
        if (this.state.drzava !== HRVATSKA && !this.state.adresa) {
            adresaError = true;
        }
        return adresaError;
    }

    checkDatumRodenja() {
        let datumRodenjaError = false;
        if (this.state.drzava !== HRVATSKA && !this.state.datumRodenja) {
            datumRodenjaError = true;
        }
        if (this.state.datumRodenja) {
            datumRodenjaError = !validateDatumRodjenja(this.state.datumRodenja);
        }
        return datumRodenjaError;
    }

    checkKontaktBroj() {
        let kontaktBrojError = false;
        if (this.props.kontaktiRequired && !this.state.kontaktBroj && !this.state.kontaktEmail) {
            kontaktBrojError = true;
        }
        if (this.state.kontaktBroj) {
            kontaktBrojError = !validatePhoneNumber(this.state.kontaktBroj);
        }
        return kontaktBrojError;
    }

    checkKontaktEmail() {
        let kontaktEmailError = false;
        if (this.props.kontaktiRequired && !this.state.kontaktEmail && !this.state.kontaktBroj) {
            kontaktEmailError = true;
        }
        if (this.state.kontaktEmail) {
            kontaktEmailError = !validateEmail(this.state.kontaktEmail);
        }
        return kontaktEmailError;
    }

    checkKlijentRequiredFields() {
        let enableSaveButton = false,
            idBrojError = false,
            adresaError = false,
            datumRodenjaError = false,
            kontaktBrojError = false,
            kontaktEmailError = false;
        if (this.state.tipKlijenta) {
            if (this.state.tipKlijenta === enums.tipKlijenta.FIZICKA_OSOBA) {
                if (this.checkFizickaOsoba()) {
                    enableSaveButton = true;
                    idBrojError = this.checkIdBroj();
                    adresaError = this.checkAdresa();
                    datumRodenjaError = this.checkDatumRodenja();
                    kontaktBrojError = this.checkKontaktBroj();
                    kontaktEmailError = this.checkKontaktEmail();
                }
            } else {
                if (this.checkPravnaOsoba()) {
                    enableSaveButton = true;
                    idBrojError = this.checkIdBroj();
                    kontaktBrojError = this.checkKontaktBroj();
                    kontaktEmailError = this.checkKontaktEmail();
                }
            }
        }
        return {
            idBrojError: idBrojError,
            adresaError: adresaError,
            datumRodenjaError: datumRodenjaError,
            kontaktBrojError: kontaktBrojError,
            kontaktEmailError: kontaktEmailError,
            enableSaveButton: enableSaveButton && !idBrojError && !adresaError && !datumRodenjaError && !kontaktBrojError && !kontaktEmailError
        };
    }

    enableSaveButton() {
        this.setState(this.checkKlijentRequiredFields());
    }

    onSave() {
        let klijent = {
            adresa: this.state.adresa || null,
            postanskiBrojNaselje: this.state.postanskiBrojNaselje || null,
            drzava: this.state.drzava,
            idBroj: this.state.idBroj,
            kontaktBroj: this.state.kontaktBroj || null,
            kontaktEmail: this.state.kontaktEmail || null,
            napomenaOpreza: this.state.napomenaOpreza || null
        };
        if (this.state.tipKlijenta === enums.tipKlijenta.FIZICKA_OSOBA) {
            klijent.ime = this.state.ime;
            klijent.prezime = this.state.prezime;
            let date = this.state.datumRodenja || null;
            if (date) {
                date = date.split('.').reverse();
                date.shift();
                date = date.join('-');
            }
            klijent.datumRodenja = date;
            klijent.idBrojTip = this.state.idBrojTip;
            klijent.tipKlijenta = enums.tipKlijenta.FIZICKA_OSOBA;
        } else {
            klijent.naziv = this.state.naziv;
            klijent.kontaktOsoba = this.state.kontaktOsoba || null;
            klijent.idBrojTip = enums.idBrojTip.OIB;
            klijent.tipKlijenta = enums.tipKlijenta.PRAVNA_OSOBA;
        }
        this.props.onSave(klijent);
    }

    render() {
        return (
            <Form>
                <Form.Select
                    label={localization.klijent.tipKlijenta}
                    options={clientType}
                    placeholder={localization.klijent.tipKlijenta}
                    name="tipKlijenta"
                    disabled={!!(this.props.client.tipKlijenta || this.props.clientType)}
                    onChange={this.handleChangeType}
                    value={this.state.tipKlijenta}
                    fluid
                    search
                />
                {this.state.tipKlijenta === enums.tipKlijenta.FIZICKA_OSOBA ? (
                    <div>
                        <Form.Group widths="equal">
                            <Form.Input
                                label={localization.klijent.ime}
                                placeholder={localization.klijent.ime}
                                name="ime"
                                onChange={this.handleChange}
                                value={this.state.ime}
                                fluid
                                required
                            />
                            <Form.Input
                                label={localization.klijent.prezime}
                                placeholder={localization.klijent.prezime}
                                name="prezime"
                                onChange={this.handleChange}
                                value={this.state.prezime}
                                fluid
                                required
                            />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Select
                                label={localization.klijent.drzava}
                                options={countriesSelection}
                                placeholder={localization.klijent.drzava}
                                name="drzava"
                                onChange={this.handleChangeState}
                                value={this.state.drzava}
                                fluid
                                required
                                search
                            />
                            <Form.Select
                                label={localization.klijent.tipIdentifikacijskogBroja}
                                options={idType}
                                placeholder={localization.klijent.tipIdentifikacijskogBroja}
                                name="idBrojTip"
                                onChange={this.handleChange}
                                value={this.state.idBrojTip}
                                disabled={this.state.drzava !== HRVATSKA}
                                fluid
                                required
                                search
                            />
                            {this.state.idBrojTip === enums.idBrojTip.OIB ? (
                                <Form.Input
                                    label={localization.klijent.oib}
                                    placeholder={localization.klijent.oib}
                                    name="idBroj"
                                    onChange={this.handleChangeOIB}
                                    value={this.state.idBroj}
                                    error={this.state.idBrojError}
                                    fluid
                                    required
                                />
                            ) : this.state.idBrojTip === enums.idBrojTip.BR_OSOBNE ? (
                                <Form.Input
                                    label={localization.klijent.brojOsobne}
                                    placeholder={localization.klijent.brojOsobne}
                                    name="idBroj"
                                    onChange={this.handleChangeBrOsobne}
                                    value={this.state.idBroj}
                                    error={this.state.idBrojError}
                                    fluid
                                    required
                                />
                            ) : (
                                <Form.Input
                                    label={localization.klijent.brojPutovnice}
                                    placeholder={localization.klijent.brojPutovnice}
                                    name="idBroj"
                                    onChange={this.handleChangeNumber}
                                    value={this.state.idBroj}
                                    fluid
                                    required
                                />
                            )}
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Input
                                label={localization.klijent.adresa}
                                placeholder={localization.klijent.adresa}
                                name="adresa"
                                onChange={this.handleChange}
                                value={this.state.adresa}
                                required={this.state.drzava !== HRVATSKA}
                                error={this.state.adresaError}
                                fluid
                            />
                            <Form.Select
                                label={localization.klijent.postanskiBrojNaselje}
                                options={postanskiBrojeviNaselja}
                                placeholder={localization.klijent.postanskiBrojNaselje}
                                name="postanskiBrojNaselje"
                                onChange={this.handleChange}
                                value={this.state.postanskiBrojNaselje}
                                fluid
                                search
                            />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Input
                                label={localization.klijent.datumRodenja}
                                placeholder={localization.klijent.datumRodenjaPlaceholder}
                                name="datumRodenja"
                                onChange={this.handleChangeDatumRodenja}
                                value={this.state.datumRodenja}
                                required={this.state.drzava !== HRVATSKA}
                                error={this.state.datumRodenjaError}
                                fluid
                            />
                            <Form.Input
                                label={localization.klijent.kontaktBroj}
                                placeholder={localization.klijent.kontaktBrojPlaceholder}
                                name="kontaktBroj"
                                onChange={this.handleChangeKontaktBroj}
                                value={this.state.kontaktBroj}
                                required={this.state.drzava !== HRVATSKA}
                                error={this.state.kontaktBrojError}
                                fluid
                            />
                            <Form.Input
                                label={localization.klijent.kontaktEmail}
                                placeholder={localization.klijent.kontaktEmail}
                                name="kontaktEmail"
                                onChange={this.handleChange}
                                value={this.state.kontaktEmail}
                                required={this.state.drzava !== HRVATSKA}
                                error={this.state.kontaktEmailError}
                                fluid
                            />
                        </Form.Group>
                        <Form.Input
                            label={localization.klijent.napomenaOpreza}
                            placeholder={localization.klijent.napomenaOpreza}
                            name="napomenaOpreza"
                            onChange={this.handleChange}
                            value={this.state.napomenaOpreza}
                            fluid
                        />
                    </div>
                ) : (
                    <div>
                        <Form.Input
                            label={localization.klijent.naziv}
                            placeholder={localization.klijent.naziv}
                            name="naziv"
                            onChange={this.handleChange}
                            value={this.state.naziv}
                            fluid
                            required
                        />
                        <Form.Group widths="equal">
                            <Form.Select
                                label={localization.klijent.drzava}
                                options={countriesSelection}
                                placeholder={localization.klijent.drzava}
                                value={this.state.drzava}
                                disabled
                                fluid
                                required
                                search
                            />
                            <Form.Select
                                label={localization.klijent.tipIdentifikacijskogBroja}
                                options={idType}
                                placeholder={localization.klijent.tipIdentifikacijskogBroja}
                                value={this.state.idBrojTip}
                                disabled
                                fluid
                                required
                                search
                            />
                            <Form.Input
                                label={localization.klijent.oib}
                                placeholder={localization.klijent.oib}
                                name="idBroj"
                                onChange={this.handleChangeOIB}
                                value={this.state.idBroj}
                                error={this.state.idBrojError}
                                fluid
                                required
                            />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Input
                                label={localization.klijent.adresa}
                                placeholder={localization.klijent.adresa}
                                name="adresa"
                                onChange={this.handleChange}
                                value={this.state.adresa}
                                fluid
                            />
                            <Form.Select
                                label={localization.klijent.postanskiBrojNaselje}
                                options={postanskiBrojeviNaselja}
                                placeholder={localization.klijent.postanskiBrojNaselje}
                                name="postanskiBrojNaselje"
                                onChange={this.handleChange}
                                value={this.state.postanskiBrojNaselje}
                                fluid
                                search
                            />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Input
                                label={localization.klijent.kontaktOsoba}
                                placeholder={localization.klijent.kontaktOsoba}
                                name="kontaktOsoba"
                                onChange={this.handleChange}
                                value={this.state.kontaktOsoba}
                                fluid
                            />
                            <Form.Input
                                label={localization.klijent.kontaktBroj}
                                placeholder={localization.klijent.kontaktBroj}
                                name="kontaktBroj"
                                onChange={this.handleChangeKontaktBroj}
                                value={this.state.kontaktBroj}
                                error={this.state.kontaktBrojError}
                                fluid
                            />
                            <Form.Input
                                label={localization.klijent.kontaktEmail}
                                placeholder={localization.klijent.kontaktEmail}
                                name="kontaktEmail"
                                onChange={this.handleChange}
                                value={this.state.kontaktEmail}
                                error={this.state.kontaktEmailError}
                                fluid
                            />
                        </Form.Group>
                        <Form.Input
                            label={localization.klijent.napomenaOpreza}
                            placeholder={localization.klijent.napomenaOpreza}
                            name="napomenaOpreza"
                            onChange={this.handleChange}
                            value={this.state.napomenaOpreza}
                            fluid
                        />
                    </div>
                )}
                <Form.Group style={styles.buttons}>
                    <Form.Button icon="cancel" content={localization.common.odustani} onClick={this.props.onCancel} />
                    <Form.Button icon="save" color="green" disabled={!this.state.enableSaveButton} content={localization.common.spremi} onClick={this.onSave} />
                </Form.Group>
            </Form>
        );
    }
}

Klijent.propTypes = {
    client: PropTypes.object.isRequired,
    clientType: PropTypes.string,
    kontaktiRequired: PropTypes.bool,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {};
};

let mapDispatchToProps = function(dispatch) {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Klijent);
