let initialState = {
    firma: {},
    cached: false
};

export default function firmaReducer(state = initialState, action) {
    switch (action.type) {
        case 'GET_FIRMA': {
            return Object.assign({}, state, {
                firma: action.firma,
                cached: true
            });
        }
        default:
            return state;
    }
}
