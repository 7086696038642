import $ from 'jquery';

import endpoint from '../environments/endpoints';
import enums from '../utils/Enums';

export const TIP_RACUNA = {
    all: 'all',
    fiskalni: 'fiskalni',
    transakcijski: 'transakcijski',
    gotovinski: 'gotovinski',
    karticni: 'karticni'
};

// TODO @Kec @Vranjes Parameter order?
export function getRacuniPaginated(q, page, pageSize, poslovnicaId, tipRacuna, onSuccess, onError) {
    $.ajax({
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + sessionStorage.token
        },
        url: endpoint.apiEndpoint + 'prodaja/racuni/paginated' +
            '?q=' + q +
            '&tipRacuna=' + tipRacuna +
            '&poslovnicaId=' + (poslovnicaId ? poslovnicaId : '') +
            '&page=' + page +
            '&pageSize=' + pageSize
    })
        .done(onSuccess)
        .fail(onError);
}

export function getRacuniWithinDateRange(poslovnicaId, tipRacuna, startDateTimeIncl, endDateTimeIncl, onSuccess, onError) {
    $.ajax({
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + sessionStorage.token
        },
        url: endpoint.apiEndpoint + 'prodaja/racuni/rekapitulacija' +
            '?tipRacuna=' + tipRacunaToApiParam(tipRacuna) +
            '&poslovnicaId=' + poslovnicaId +
            '&startDateTimeIncl=' + startDateTimeIncl.format("YYYY-MM-DDTHH:mm:ss") +
            '&endDateTimeIncl=' + endDateTimeIncl.format("YYYY-MM-DDTHH:mm:ss")
    })
        .done(onSuccess)
        .fail(onError);
}

function tipRacunaToApiParam(tipRacuna) {
    switch (tipRacuna) {
        case enums.tipRacuna.FISKALNI_RACUN:
            return 'fiskalni';
        case enums.tipRacuna.TRANSAKCIJSKI_RACUN:
            return 'transakcijski';
        default:
            throw new Error("Unhandled tip racuna " + tipRacuna + ".");
    }
}
