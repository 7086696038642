let initialState = {
    appPrefs: {},
    cached: false
};

export default function appPreferencesReducer(state = initialState, action) {
    switch (action.type) {
        case 'GET_APP_PREFS': {
            return Object.assign({}, state, {
                appPrefs: action.appPrefs,
                cached: true
            });
        }
        default:
            return state;
    }
}
