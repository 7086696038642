import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Accordion, Button, Comment, Dimmer, Form, Grid, Header, Icon, Loader, Message, Modal, Pagination, Search, Segment, Table } from 'semantic-ui-react';

import localization from '../../locales/localization';
import endpoints from '../../environments/endpoints';
import enums from '../../utils/Enums';
import icons from '../../utils/DefinedIcons';
import { storeDocumentAndGenerateKey } from '../../utils/PrintingDocumentsHandler';
import moment from 'moment';
import * as IzdatniceDataAccess from '../../data_access/IzdatniceDataAccess';
import { bindActionCreators } from 'redux';
import SearchResultsMessage from '../common_components/SearchResultsMessage';
import TablicaArtikala from '../common_components/table_fragments/TablicaArtikala';
import FormatIznosa from '../common_components/FormatIznosa';
import FilterSortButton from '../common_components/FilterSortButton';

const styles = {
    headerTable: {
        padding: '.75em 1em'
    },
    iconCell: {
        width: 50
    },
    brojIzdatniceCell: {
        width: 400
    },
    ukupnaCijenaCell: {
        width: 400
    },
    actionButtons: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
};

const potvrdeEnums = {
    STORNIRANJE_IZDATNICE: 'STORNIRANJE_IZDATNICE'
};

const ITEMS_PER_PAGE = 10;

class Izdatnice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSearchLoading: false,
            value: '',
            izdatnice: this.props.izdatnice,
            results: this.props.izdatnice,
            activeIzdatnice: [],
            activePotvrda: undefined,
            loader: !this.props.izdatniceCache,
            filter: 0,
            pageActive: 1
        };
        this.onNovaIzdatnica = this.onNovaIzdatnica.bind(this);
        this.calculatePage = this.calculatePage.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.filter = this.filter.bind(this);
        this.filterPoslovnice = this.filterPoslovnice.bind(this);
        this.resetSearchComponent = this.resetSearchComponent.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.search = this.search.bind(this);
        this.calculateUkupno = this.calculateUkupno.bind(this);
        this.expandIzdatnicu = this.expandIzdatnicu.bind(this);
        this.ispisIzdatnice = this.ispisIzdatnice.bind(this);
        this.stornoIzdatnica = this.stornoIzdatnica.bind(this);
        this.showHideModal = this.showHideModal.bind(this);
        this.showLoader = this.showLoader.bind(this);
        this.hideLoader = this.hideLoader.bind(this);

        this.FILTER_ITEMS = [localization.common.svePoslovnice];
        this.props.poslovnice.forEach(p => this.FILTER_ITEMS.push(p.naziv));
    }

    componentWillMount() {
        this.resetSearchComponent();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.izdatniceCache) {
            this.setState({
                izdatnice: nextProps.izdatnice,
                results: nextProps.izdatnice,
                loader: false
            });
        }
    }

    onNovaIzdatnica() {
        this.props.history.push(endpoints.appEndpoints.novaIzdatnica);
    }

    calculatePage(activePage) {
        let startItem = (activePage - 1) * ITEMS_PER_PAGE;
        let endItem = activePage * ITEMS_PER_PAGE;
        return [startItem, endItem];
    }

    onChangePage(e, { activePage }) {
        this.setState({
            pageActive: activePage
        });
    }

    applyFilter(filter, _) {
        this.setState(
            {
                filter: filter,
                izdatnice: this.filter(this.props.izdatnice, filter)
            },
            this.search
        );
    }

    filter(izdatnice, filterPoslovnice) {
        izdatnice = this.filterPoslovnice(izdatnice, filterPoslovnice);
        return izdatnice;
    }

    filterPoslovnice(izdatnice, filter) {
        switch (this.FILTER_ITEMS[filter]) {
            case localization.common.svePoslovnice: {
                return izdatnice;
            }
            default: {
                izdatnice = _.filter(izdatnice, o => {
                    return o.poslovnica.naziv === this.FILTER_ITEMS[filter];
                });
                return izdatnice;
            }
        }
    }

    resetSearchComponent() {
        this.setState({
            isSearchLoading: false,
            value: ''
        });
    }

    handleSearchChange(e, { value }) {
        this.setState({
            isSearchLoading: true,
            value: value,
            pageActive: 1
        });

        setTimeout(this.search, 500);
    }

    search() {
        const re = new RegExp(_.escapeRegExp(this.state.value), 'i');
        const isMatch = result => {
            let testingArray = [result.rbrIzdatnice, moment(result.datumIVrijeme).format('DD.MM.YYYY.')];
            result.stavke.forEach(stavka => {
                testingArray = _.concat(testingArray, [stavka.predmet.sifra, stavka.predmet.naziv, stavka.predmet.serijskiBroj]);
            });
            for (let i = 0; i < testingArray.length; i++) {
                if (re.test(testingArray[i])) {
                    return true;
                }
            }
            return false;
        };

        this.setState({
            isSearchLoading: false,
            results: _.filter(this.state.izdatnice, isMatch)
        });
    }

    calculateUkupno(izdatnica) {
        let ukupno = 0;
        izdatnica.stavke.forEach(stavka => {
            if (izdatnica.rbrStorniraneIzdatnice === null) {
                ukupno += stavka.kolicina * stavka.jedNabavnaCijena;
            } else {
                ukupno += -stavka.kolicina * stavka.jedNabavnaCijena;
            }
        });
        return ukupno;
    }

    expandIzdatnicu(e, { index }) {
        let activeIzdatnice = [];
        if (this.state.activeIzdatnice.includes(index)) {
            activeIzdatnice = _.xor(this.state.activeIzdatnice, [index]);
        } else {
            activeIzdatnice = [...this.state.activeIzdatnice, index];
        }
        this.setState({
            activeIzdatnice: activeIzdatnice
        });
    }

    ispisIzdatnice(izdatnica) {
        window.open(endpoints.appEndpoints.print.izdatnica + storeDocumentAndGenerateKey(izdatnica), '_blank');
    }

    stornoIzdatnica(izdatnicaId) {
        this.props.izdatniceDataAccess.stornirajIzdatnica(
            izdatnicaId,
            this.hideLoader.bind(this, this.showHideModal.bind(this, false))
        );
    }

    showHideModal(value, name, sifra) {
        if (value) {
            this.setState({
                activePotvrda: {
                    type: name,
                    id: sifra
                }
            });
        } else {
            this.setState({ activePotvrda: undefined });
        }
    }

    showLoader(callback) {
        this.setState(
            {
                loader: true
            },
            callback
        );
    }

    hideLoader(callback, data) {
        this.setState(
            {
                loader: false
            },
            callback.bind(this, data)
        );
    }

    render() {
        let [startItem, endItem] = this.calculatePage(this.state.pageActive);
        let totalPages = Math.ceil(this.state.results.length / ITEMS_PER_PAGE);
        let res = this.state.results.slice(startItem, endItem);
        return (
            <div>
                <Segment tertiary>
                    <Grid stackable>
                        <Grid.Row columns={6}>
                            <Grid.Column verticalAlign="middle">
                                <Header as="h2">{localization.izdatnice.izdatnice}</Header>
                            </Grid.Column>
                            <Grid.Column verticalAlign="middle">
                                <Search
                                    input={{ fluid: true }}
                                    loading={this.state.isSearchLoading}
                                    onSearchChange={this.handleSearchChange}
                                    value={this.state.value}
                                    open={false}
                                />
                            </Grid.Column>
                            <Grid.Column />
                            <Grid.Column />
                            <Grid.Column textAlign="right" verticalAlign="middle">
                                <FilterSortButton
                                    buttonName={localization.common.filtriranje}
                                    filteringOptions={this.FILTER_ITEMS}
                                    activeFilter={this.state.filter}
                                    callback={this.applyFilter}
                                />
                            </Grid.Column> 
                            <Grid.Column textAlign="right" verticalAlign="middle">
                                <Button icon="asterisk" basic color="black" content={localization.izdatnice.novaIzdatnica} onClick={this.onNovaIzdatnica} />
                            </Grid.Column>
                        </Grid.Row>
                        {res.length ? (
                            <React.Fragment>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Message style={styles.headerTable}>
                                            <Table structured stackable>
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell style={styles.iconCell} />
                                                        <Table.HeaderCell style={styles.brojIzdatniceCell}>{localization.izdatnice.brojIzdatnice}</Table.HeaderCell>
                                                        <Table.HeaderCell style={styles.ukupnaCijenaCell}>{localization.common.ukupnaCijena}</Table.HeaderCell>
                                                        <Table.HeaderCell>{localization.izdatnice.datumIzdatnice}</Table.HeaderCell>
                                                    </Table.Row>
                                                </Table.Header>
                                            </Table>
                                        </Message>
                                    </Grid.Column>
                                </Grid.Row>
                                {res.map(izdatnica => {
                                    return (
                                        <Grid.Row key={izdatnica.id}>
                                            <Grid.Column verticalAlign="middle">
                                                <Accordion fluid styled>
                                                    <Accordion.Title
                                                        active={this.state.activeIzdatnice.includes(izdatnica.id)}
                                                        index={izdatnica.id}
                                                        onClick={this.expandIzdatnicu}
                                                    >
                                                        <Table structured stackable color={izdatnica.isStornirana ? 'grey' : izdatnica.rbrStorniraneIzdatnice ? 'red' : undefined} inverted={izdatnica.isStornirana}>
                                                            <Table.Header>
                                                                <Table.Row>
                                                                    <Table.Cell style={styles.iconCell}>
                                                                        <Icon name="dropdown" />
                                                                    </Table.Cell>
                                                                    <Table.Cell style={styles.brojIzdatniceCell}>
                                                                        {izdatnica.rbrIzdatnice}
                                                                    </Table.Cell>
                                                                    <Table.Cell style={styles.ukupnaCijenaCell}>
                                                                        <FormatIznosa value={this.calculateUkupno(izdatnica)} />
                                                                    </Table.Cell>
                                                                    <Table.Cell>{moment(izdatnica.datumIVrijeme).format('DD.MM.YYYY.')}</Table.Cell>
                                                                </Table.Row>
                                                            </Table.Header>
                                                        </Table>
                                                    </Accordion.Title>
                                                    <Accordion.Content active={this.state.activeIzdatnice.includes(izdatnica.id)}>
                                                        <Segment>
                                                            <Header as="h4">{localization.izdatnice.artikli}</Header>
                                                            <TablicaArtikala type={enums.tipTabliceArtikala.IZDATNICA_FIXED} artikli={izdatnica.stavke.map(stavka => {
                                                                return Object.assign({}, stavka, {
                                                                    sifra: stavka.predmet.sifra,
                                                                    naziv: stavka.predmet.naziv,
                                                                    serijskiBroj: stavka.predmet.serijskiBroj,
                                                                    jedCijena: stavka.jedNabavnaCijena, 
                                                                    kolicina: izdatnica.rbrStorniraneIzdatnice === null ? stavka.kolicina : -stavka.kolicina
                                                                });
                                                            })} />
                                                        </Segment>
                                                        <div style={styles.actionButtons}>
                                                            <Button
                                                                icon="print"
                                                                color="green"
                                                                content={localization.izdatnice.ispisIzdatnice}
                                                                onClick={this.ispisIzdatnice.bind(this, izdatnica)}
                                                            />
                                                            {!izdatnica.isStornirana && !izdatnica.rbrStorniraneIzdatnice ? (
                                                                <Modal
                                                                    open={
                                                                        this.state.activePotvrda &&
                                                                        this.state.activePotvrda.type === potvrdeEnums.STORNIRANJE_IZDATNICE &&
                                                                        this.state.activePotvrda.id === izdatnica.id
                                                                    }
                                                                    closeOnDimmerClick={false}
                                                                    trigger={
                                                                        <Button
                                                                            icon="undo"
                                                                            color="red"
                                                                            content={localization.izdatnice.storniranjeIzdatnice}
                                                                            onClick={this.showHideModal.bind(
                                                                                this,
                                                                                true,
                                                                                potvrdeEnums.STORNIRANJE_IZDATNICE,
                                                                                izdatnica.id
                                                                            )}
                                                                        />
                                                                    }
                                                                >
                                                                    <Header icon="undo" content={localization.common.potvrda} />
                                                                    <Modal.Content>
                                                                        <p>{localization.izdatnice.storniranjePitanjePotvrde}</p>
                                                                    </Modal.Content>
                                                                    <Modal.Actions>
                                                                        <Button
                                                                            icon="remove"
                                                                            content={localization.common.odustani}
                                                                            onClick={this.showHideModal.bind(this, false)}
                                                                        />
                                                                        <Button
                                                                            icon="copy"
                                                                            color="red"
                                                                            content={localization.izdatnice.storniraj}
                                                                            onClick={this.showLoader.bind(this, this.stornoIzdatnica.bind(this, izdatnica.id))}
                                                                        />
                                                                    </Modal.Actions>
                                                                </Modal>
                                                            ) : null}
                                                        </div>
                                                        {izdatnica.sluzbenaNapomena ? (
                                                            <Message>
                                                                <Message.Header>{localization.common.sluzbenaNapomena}</Message.Header>
                                                                <p>{izdatnica.sluzbenaNapomena}</p>
                                                            </Message>
                                                        ) : null}
                                                        {izdatnica.nesluzbeneNapomene.length ? (
                                                                <Comment.Group>
                                                                {localization.common.nesluzbenaNapomena}
                                                                {izdatnica.nesluzbeneNapomene.map((nap, index) => {
                                                                    return (
                                                                        <Comment key={index}>
                                                                            <Comment.Content>
                                                                                <Comment.Author>{nap.user.ime + ' ' + nap.user.prezime}</Comment.Author>
                                                                                <Comment.Metadata>
                                                                                    <div>{moment(nap.datumIVrijeme).format('DD.MM.YYYY.')}</div>
                                                                                </Comment.Metadata>
                                                                                <Comment.Text>{nap.tekst}</Comment.Text>
                                                                            </Comment.Content>
                                                                        </Comment>
                                                                    );
                                                                })}
                                                            </Comment.Group>
                                                        ) : null}
                                                    </Accordion.Content>
                                                </Accordion>
                                            </Grid.Column>
                                        </Grid.Row>
                                    );
                                })}
                                <Grid.Row centered>
                                    <Pagination
                                        activePage={this.state.pageActive}
                                        totalPages={totalPages}
                                        onPageChange={this.onChangePage}
                                        ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
                                        firstItem={{ content: <Icon name="angle double left" />, icon: true }}
                                        lastItem={{ content: <Icon name="angle double right" />, icon: true }}
                                        prevItem={{ content: <Icon name="angle left" />, icon: true }}
                                        nextItem={{ content: <Icon name="angle right" />, icon: true }}
                                    />
                                </Grid.Row>
                            </React.Fragment>
                        ) : (
                            <SearchResultsMessage
                                icon={icons.izdatnice}
                                header={this.state.value !== '' ? localization.common.unesenaPretragaNemaRezultata : localization.izdatnice.nemaIzdatnice}
                                content={this.state.value !== '' ? localization.common.pokusajDrugacijuPretragu : undefined}
                            />
                        )}
                    </Grid>
                </Segment>
                <Dimmer active={this.state.loader} page>
                    <Loader />
                </Dimmer>
            </div>
        );
    }
}

Izdatnice.propTypes = {
    history: PropTypes.object.isRequired,
    izdatnice: PropTypes.array.isRequired,
    izdatniceCache: PropTypes.bool.isRequired,
    poslovnice: PropTypes.array.isRequired,
    izdatniceDataAccess: PropTypes.object.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {
        izdatnice: state.izdatniceReducer.izdatnice || [],
        izdatniceCache: state.izdatniceReducer.cached,
        poslovnice: state.mjestaReducer.poslovnice || []
    };
};

let mapDispatchToProps = function(dispatch) {
    return {
        izdatniceDataAccess: bindActionCreators(IzdatniceDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Izdatnice);
