import React from 'react';
import PropTypes from 'prop-types';
import { Header, Segment } from 'semantic-ui-react';

import RezervacijeAkcijeContent from './RezervacijeAkcijeContent';
import OtkupProdajaContent from './OtkupProdajaContent';
import enums from '../../../utils/Enums';
import localization from '../../../locales/localization';

export default class PodizanjeRezervacijeContent extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Segment>
                    <Header as="h3">{localization.dnevnaEvidencija.podignuteRezervacije}</Header>
                    <RezervacijeAkcijeContent rezervacije={this.props.podignuteRezervacije} />
                </Segment>
                <Segment>
                    <Header as="h3">{localization.dnevnaEvidencija.racun}</Header>
                    <OtkupProdajaContent
                        klijent={this.props.racun.klijent}
                        type={enums.tipTabliceArtikala.RACUN_FIXED}
                        artikli={this.props.racun.skladisniIzlazi.map(si => {
                            return Object.assign({}, si, {
                                sifra: si.sifraArtikla,
                                naziv: si.nazivArtikla,
                                cijena: si.jedCijena,
                                kolicina: this.props.racun.rbrStorniranogRacuna === null ? si.kolicina : -si.kolicina
                            });
                        })}
                    />
                </Segment>
            </React.Fragment>
        );
    }
}

PodizanjeRezervacijeContent.propTypes = {
    podignuteRezervacije: PropTypes.array.isRequired,
    racun: PropTypes.object.isRequired
};
