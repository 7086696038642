import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown, Table } from 'semantic-ui-react';
import FormatIznosa from '../../FormatIznosa';
import localization from '../../../../locales/localization';
import InputField from '../../InputField';

const styles = {
    kolicinaIcijena: {
        fontFamily: 'monospace'
    }
};

export default class EditableRezervacijaImportBodyCells extends React.Component {
    render() {
        let skladisnaJedinka = this.props.item.jedinke.find(k => k.id === this.props.item.selectedJedinka);
        return (
            <React.Fragment>
                <Table.Cell>{this.props.item.kategorija.ime + (this.props.item.potkategorija ? ' / ' + this.props.item.potkategorija.ime : '')}</Table.Cell>
                <Table.Cell singleLine>
                    <Dropdown
                        selection
                        fluid
                        value={this.props.item.selectedJedinka}
                        name="selectedJedinka"
                        onChange={this.props.onChange.bind(this, this.props.item.sifra)}
                        options={this.props.item.jedinke.map((j, index) => {
                            return {
                                text:
                                    localization.common.kolicina +
                                    ': ' +
                                    j.kolicina +
                                    ';  ' +
                                    localization.otkup.jedinicnaCijenaOtkupa +
                                    ': ' +
                                    j.jedNabavnaCijena.toFixed(2),
                                value: j.id
                            };
                        })}
                    />
                </Table.Cell>
                <Table.Cell textAlign="right" collapsing style={styles.kolicinaIcijena}>
                    <b>
                        <FormatIznosa value={skladisnaJedinka.kolicina * skladisnaJedinka.jedNabavnaCijena} />
                    </b>
                </Table.Cell>
                <Table.Cell collapsing>
                    <InputField
                        label={localization.common.eur}
                        placeholder={localization.common.cijena}
                        name="cijena"
                        value={this.props.item.cijena}
                        onChange={this.props.onChange.bind(this, this.props.item.sifra)}
                    />
                </Table.Cell>
                <Table.Cell collapsing>
                    <Button.Group basic size="small">
                        <Button icon="delete" onClick={this.props.onRemoveItem.bind(this, this.props.item)} />
                    </Button.Group>
                </Table.Cell>
            </React.Fragment>
        );
    }
}

EditableRezervacijaImportBodyCells.propTypes = {
    item: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onRemoveItem: PropTypes.func.isRequired
};
