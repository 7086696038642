import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Form, Header, Modal, Segment } from 'semantic-ui-react';

import localization from '../../locales/localization';
import * as TroskoviDataAccess from '../../data_access/TroskoviDataAccess';
import InputField from './InputField';
import FormatIznosa from './FormatIznosa';

const styles = {
    deleteButton: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    container: {
        textAlign: 'left'
    },
    label: {
        fontWeight: 'normal',
        marginLeft: 8
    },
    kolicinaIcijena: {
        fontWeight: 'normal',
        marginLeft: 8,
        fontFamily: 'monospace'
    },
    actionButtons: {
        marginTop: 16,
        display: 'flex',
        justifyContent: 'flex-end'
    }
};

const timeSpanType = [
    {
        tip: 'DAY',
        naziv: localization.postavke.dnevniTrosak
    },
    {
        tip: 'MONTH',
        naziv: localization.postavke.mjesecniTrosak
    },
    {
        tip: 'YEAR',
        naziv: localization.postavke.godisniTrosak
    }
];

class ReccuringTrosak extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            timeSpanType: this.props.reccuringTrosak.timeSpanType,
            ime: this.props.reccuringTrosak.ime,
            defaultIznos: this.props.reccuringTrosak.defaultIznos,
            activeModal: false,
            enableSaveButton: false
        };
        this.onChange = this.onChange.bind(this);
        this.enableSaveButton = this.enableSaveButton.bind(this);
        this.checkReccuringTrosakRequiredFields = this.checkReccuringTrosakRequiredFields.bind(this);
        this.showHideModal = this.showHideModal.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            timeSpanType: nextProps.reccuringTrosak.timeSpanType,
            ime: nextProps.reccuringTrosak.ime,
            defaultIznos: nextProps.reccuringTrosak.defaultIznos,
            activeModal: false,
            enableSaveButton: false
        });
    }

    onChange(e, { name, value }) {
        this.setState({ [name]: value }, this.enableSaveButton);
    }

    enableSaveButton() {
        this.setState({ enableSaveButton: this.checkReccuringTrosakRequiredFields() });
    }

    checkReccuringTrosakRequiredFields() {
        if (!this.state.timeSpanType || !this.state.ime || this.state.defaultIznos <= 0) {
            return false;
        }
        return true;
    }

    showHideModal(value) {
        this.setState({ activeModal: value });
    }

    onCancel() {
        if (this.props.novi) {
            this.props.onDelete();
        } else if (this.props.reccuringTrosak.isActive) {
            this.props.troskoviDataAccess.deactivateReccuringTrosak(this.props.reccuringTrosak.id);
        } else {
            this.props.troskoviDataAccess.activateReccuringTrosak(this.props.reccuringTrosak.id);
        }
    }

    onSave() {
        let reccuringTrosak = {
            timeSpanType: this.state.timeSpanType,
            ime: this.state.ime,
            defaultIznos: this.state.defaultIznos
        };
        this.props.troskoviDataAccess.createReccuringTrosakDefinition(reccuringTrosak);
    }

    onDelete() {
        this.props.troskoviDataAccess.deleteReccuringTrosakDefinition(this.props.reccuringTrosak.id);
    }

    render() {
        return (
            <Segment>
                {!this.props.novi ? (
                    <div style={styles.deleteButton}>
                        <Modal
                            open={this.state.activeModal}
                            trigger={<Button icon="delete" color="red" basic size="small" onClick={this.showHideModal.bind(this, true)} />}
                        >
                            <Header icon="trash" content={localization.common.potvrdaBrisanja} />
                            <Modal.Content>
                                <p>{localization.postavke.brisanjePonavljajucegTroskaPotvrda}</p>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button icon="remove" content={localization.common.odustani} onClick={this.showHideModal.bind(this, false)} />
                                <Button icon="trash" color="red" content={localization.common.obrisi} onClick={this.onDelete} />
                            </Modal.Actions>
                        </Modal>
                    </div>
                ) : null}
                {this.props.novi ? (
                    <Form style={styles.container}>
                        <Form.Select
                            label={localization.postavke.tipTroska}
                            disabled={!this.props.novi}
                            options={timeSpanType.map((t, index) => {
                                return { key: index, text: t.naziv, value: t.tip };
                            })}
                            name="timeSpanType"
                            onChange={this.onChange}
                            value={this.state.timeSpanType}
                            required
                            search
                        />
                        <Form.Input
                            fluid
                            label={localization.postavke.nazivTroska}
                            disabled={!this.props.novi}
                            name="ime"
                            onChange={this.onChange}
                            value={this.state.ime}
                            required
                        />
                        <Form.Field required disabled={!this.props.novi}>
                            <label>{localization.postavke.iznosTroska}</label>
                            <InputField
                                label={localization.common.eur}
                                placeholder={localization.common.eur}
                                name="defaultIznos"
                                value={this.state.defaultIznos}
                                onChange={this.onChange}
                                isCjelobrojno={false}
                            />
                        </Form.Field>
                    </Form>
                ) : (
                    <Form style={styles.container}>
                        <Form.Field>
                            <label>{localization.postavke.tipTroska}</label>
                            <label style={styles.label}>{timeSpanType.find(t => t.tip === this.state.timeSpanType).naziv}</label>
                        </Form.Field>
                        <Form.Field>
                            <label>{localization.postavke.nazivTroska}</label>
                            <label style={styles.label}>{this.state.ime}</label>
                        </Form.Field>
                        <Form.Field>
                            <label>{localization.postavke.iznosTroska}</label>
                            <label style={styles.kolicinaIcijena}>
                                <FormatIznosa value={this.state.defaultIznos} />
                            </label>
                        </Form.Field>
                    </Form>
                )}
                <div style={styles.actionButtons}>
                    <Button
                        icon="cancel"
                        content={
                            this.props.novi
                                ? localization.common.odustani
                                : this.props.reccuringTrosak.isActive
                                ? localization.postavke.deaktivirajTrosak
                                : localization.postavke.aktivirajTrosak
                        }
                        onClick={this.onCancel}
                    />
                    {this.props.novi ? (
                        <Button icon="save" color="green" disabled={!this.state.enableSaveButton} content={localization.common.spremi} onClick={this.onSave} />
                    ) : null}
                </div>
            </Segment>
        );
    }
}

ReccuringTrosak.propTypes = {
    novi: PropTypes.bool,
    reccuringTrosak: PropTypes.object.isRequired,
    troskoviDataAccess: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {};
};

let mapDispatchToProps = function(dispatch) {
    return {
        troskoviDataAccess: bindActionCreators(TroskoviDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReccuringTrosak);
