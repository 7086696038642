import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NovaMeduskladisnica from './meduskladisnica_components/NovaMeduskladisnica';

const styles = {};

class Meduskladisnica extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <NovaMeduskladisnica history={this.props.history} />;
    }
}

Meduskladisnica.propTypes = {
    history: PropTypes.object.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {};
};

let mapDispatchToProps = function(dispatch) {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Meduskladisnica);
