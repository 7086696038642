import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route, Switch } from 'react-router';
import { Button, Grid, Header, Modal, Tab } from 'semantic-ui-react';

import DjelatnikPreloaderAndMenu from './DjelatnikPreloaderAndMenu';
import Rezervacija from './Rezervacija';
import Otkup from './Otkup';
import Primka from './Primka';
import Prodaja from './Prodaja';
import Procjena from './Procjena';
import RadniNalog from './RadniNalog';
import Meduskladisnica from './Meduskladisnica';
import Dokumenti from './Dokumenti';
import Kontrola from './Kontrola';
import UplatnicaIsplatnica from './common_components/UplatnicaIsplatnica';
import Troskovi from './common_components/Troskovi';
import endpoints from '../environments/endpoints';
import enums from '../utils/Enums';
import localization from '../locales/localization';
import FormatIznosa from './common_components/FormatIznosa';
import InterniPrijenos from './common_components/InterniPrijenos';
import * as AuthActions from '../actions/AuthActions';

const styles = {
    rootContainer: {
        padding: 8
    },
    header: {
        cursor: 'pointer'
    },
    contentContainer: {
        paddingTop: 8
    },
    poslovnicaLabel: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'left',
        fontSize: 'medium'
    },
    imePrezimeLabel: {
        fontSize: 'medium',
        marginRight: 12
    },
    blagajneInfo: {
        textAlign: 'right'
    },
    blagajneFont: {
        paddingLeft: 8,
        color: 'red',
        fontFamily: 'monospace'
    }
};

const modali = {
    odjavaModal: 'odjavaModal',
    uplatniceTroskoviModal: 'uplatniceTroskoviModal'
};

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            odjavaModal: false,
            uplatniceTroskoviModal: false
        };
        this.onLogout = this.onLogout.bind(this);
        this.showHideModal = this.showHideModal.bind(this);
        this.getPane = this.getPane.bind(this);
    }

    onLogout() {
        sessionStorage.clear();
        this.props.authActions.userLogout();
        this.props.history.push(endpoints.appEndpoints.root);
    }

    showHideModal(name, value, e) {
        if (e) {
            e.stopPropagation();
        }
        this.setState({ [name]: value });
    }

    resolveRoutes() {
        switch (sessionStorage.userRole) {
            case enums.tipKorisnika.VODITELJ: {
                return <Route path={endpoints.appEndpoints.root} component={Dokumenti} />;
            }
            case enums.tipKorisnika.VLASNIK: {
                return <Route path={endpoints.appEndpoints.root} component={Dokumenti} />;
            }
            case enums.tipKorisnika.KONTROLA: {
                return <Route path={endpoints.appEndpoints.root} component={Kontrola} />;
            }
            case enums.tipKorisnika.DJELATNIK: {
                return (
                    <Switch>
                        <Route exact path={endpoints.appEndpoints.root} component={DjelatnikPreloaderAndMenu} />
                        <Route path={endpoints.appEndpoints.rezervacije} component={Rezervacija} />
                        <Route path={endpoints.appEndpoints.otkup} component={Otkup} />
                        <Route path={endpoints.appEndpoints.primka} component={Primka} />
                        <Route path={endpoints.appEndpoints.prodaja} component={Prodaja} />
                        <Route path={endpoints.appEndpoints.procjena} component={Procjena} />
                        <Route path={endpoints.appEndpoints.radniNalog} component={RadniNalog} />
                        <Route path={endpoints.appEndpoints.meduskladisnica} component={Meduskladisnica} />
                        <Route path={endpoints.appEndpoints.dokumentiRoot} component={Dokumenti} />
                    </Switch>
                );
            }
        }
    }

    getPane() {
        let INTERNI_PRIJENOS_PANE = {
            menuItem: localization.blagajne.interniPrijenos,
            render: () => (
                <Tab.Pane attached={false}>
                    <InterniPrijenos closeModal={this.showHideModal.bind(this, modali.uplatniceTroskoviModal, false)} />
                </Tab.Pane>
            )
        };
        let UPLATNICA_ISPLATNICA_PANE = {
            menuItem: localization.blagajne.napraviUplatnicuIsplatnicu,
            render: () => (
                <Tab.Pane attached={false}>
                    <UplatnicaIsplatnica closeModal={this.showHideModal.bind(this, modali.uplatniceTroskoviModal, false)} />
                </Tab.Pane>
            )
        };
        let UNOS_TROSKA_PANE = {
            menuItem: localization.blagajne.unosTroska,
            render: () => (
                <Tab.Pane attached={false}>
                    <Troskovi bankovni={false} closeModal={this.showHideModal.bind(this, modali.uplatniceTroskoviModal, false)} />
                </Tab.Pane>
            )
        };
        let UNOS_BANKOVNOG_TROSKA_PANE = {
            menuItem: localization.blagajne.unosBankovnogTroska,
            render: () => (
                <Tab.Pane attached={false}>
                    <Troskovi bankovni={true} closeModal={this.showHideModal.bind(this, modali.uplatniceTroskoviModal, false)} />
                </Tab.Pane>
            )
        };
        if (sessionStorage.userRole === enums.tipKorisnika.VODITELJ || sessionStorage.userRole === enums.tipKorisnika.VLASNIK) {
            return [UNOS_BANKOVNOG_TROSKA_PANE, UNOS_TROSKA_PANE, INTERNI_PRIJENOS_PANE, UPLATNICA_ISPLATNICA_PANE];
        } else {
            return [INTERNI_PRIJENOS_PANE, UPLATNICA_ISPLATNICA_PANE, UNOS_TROSKA_PANE];
        }
    }

    render() {
        let user = JSON.parse(sessionStorage.user);
        return (
            <div style={styles.rootContainer}>
                <div style={styles.header} onClick={() => this.props.history.push(endpoints.appEndpoints.root)}>
                    <Header as="h2" textAlign="center" attached="top" inverted>
                        <Grid stackable>
                            <Grid.Row columns={3}>
                                {sessionStorage.userRole === enums.tipKorisnika.DJELATNIK ? (
                                    <Grid.Column style={styles.poslovnicaLabel}>
                                        <span>{JSON.parse(sessionStorage.poslovnica).naziv}</span>
                                    </Grid.Column>
                                ) : (
                                    <Grid.Column />
                                )}
                                <Grid.Column verticalAlign="middle">{localization.zalagaonica} {localization.verzija}</Grid.Column>
                                <Grid.Column style={styles.blagajneInfo} verticalAlign="middle">
                                    <span style={styles.imePrezimeLabel}>{user.ime + ' ' + user.prezime}</span>
                                    <Modal
                                        open={this.state.odjavaModal}
                                        basic
                                        size="small"
                                        trigger={
                                            <Button
                                                icon="sign out"
                                                basic
                                                inverted
                                                content={localization.login.odjava.toUpperCase()}
                                                onClick={this.showHideModal.bind(this, modali.odjavaModal, true)}
                                            />
                                        }
                                    >
                                        <Header icon="sign out" content={localization.login.odjava.toUpperCase()} />
                                        <Modal.Content>
                                            <p>{localization.login.odjavaPotvrda}</p>
                                        </Modal.Content>
                                        <Modal.Actions>
                                            <Button
                                                color="red"
                                                inverted
                                                icon="remove"
                                                content={localization.common.odustani}
                                                onClick={this.showHideModal.bind(this, modali.odjavaModal, false)}
                                            />
                                            <Button color="green" inverted icon="sign out" content={localization.login.odjava} onClick={this.onLogout} />
                                        </Modal.Actions>
                                    </Modal>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Header>
                    {sessionStorage.userRole !== enums.tipKorisnika.KONTROLA ? (
                        <Header as="h4" textAlign="center" attached="bottom">
                            <Grid stackable>
                                <Grid.Row columns={4}>
                                    <Grid.Column />
                                    {sessionStorage.userRole === enums.tipKorisnika.DJELATNIK ? (
                                        <React.Fragment>
                                            <Grid.Column style={styles.blagajneInfo} verticalAlign="middle">
                                                {localization.blagajne.stanjeFiskalneBlagajne + ':'}
                                                <span style={styles.blagajneFont}>
                                                    <FormatIznosa value={this.props.stanjeBlagajni.stanjeFiskBlag} />
                                                </span>
                                            </Grid.Column>
                                            <Grid.Column style={styles.blagajneInfo} verticalAlign="middle">
                                                {localization.blagajne.stanjeBlagajneOtkupa + ':'}
                                                <span style={styles.blagajneFont}>
                                                    <FormatIznosa value={this.props.stanjeBlagajni.stanjeBlagOtkupa} />
                                                </span>
                                            </Grid.Column>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <Grid.Column />
                                            <Grid.Column />
                                        </React.Fragment>
                                    )}
                                    <Grid.Column style={styles.blagajneInfo} verticalAlign="middle">
                                        <Modal
                                            open={this.state.uplatniceTroskoviModal}
                                            trigger={
                                                <Button
                                                    icon="money"
                                                    basic
                                                    content={localization.blagajne.uplateIsplateTroskovi}
                                                    onClick={this.showHideModal.bind(this, modali.uplatniceTroskoviModal, true)}
                                                />
                                            }
                                        >
                                            <Header icon="money" content={localization.blagajne.uplateIsplateTroskovi} />
                                            <Modal.Content>
                                                <Tab menu={{ secondary: true, pointing: true }} panes={this.getPane()} />
                                            </Modal.Content>
                                        </Modal>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Header>
                    ) : null}
                </div>
                <div style={styles.contentContainer}>{this.resolveRoutes()}</div>
            </div>
        );
    }
}

App.propTypes = {
    history: PropTypes.object.isRequired,
    stanjeBlagajni: PropTypes.object.isRequired,
    authActions: PropTypes.object.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {
        stanjeBlagajni: state.blagajneReducer.stanje
    };
};

let mapDispatchToProps = function(dispatch) {
    return {
        authActions: bindActionCreators(AuthActions, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
