import React from 'react';
import PropTypes from 'prop-types';
import PrintProvider, { Print, NoPrint } from 'react-easy-print';
import { Button } from 'semantic-ui-react';
import localization from '../../locales/localization';
import FormatIznosa from '../common_components/FormatIznosa';

const styles = {
    container: {
        width: '33%',
        border: '1px dashed'
    },
    artikl: {
        textAlign: 'center',
        fontSize: 20
    },
    sifra: {
        textAlign: 'center'
    },
    cijenikBox: {
        margin: 4,
        display: 'flex',
        justifyContent: 'space-between'
    },
    kolicinaIcijena: {
        fontFamily: 'monospace'
    },
    printButton: {
        paddingTop: 24,
        paddingBottom: 8
    }
};

export default class CeduljicaSCijenom extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            document: JSON.parse(sessionStorage.getItem(this.props.match.params.id))
        };
        this.printDocument = this.printDocument.bind(this);
    }

    printDocument() {
        window.print();
    }

    render() {
        return (
            <PrintProvider>
                <Print>
                    <div style={styles.container}>
                        <p style={styles.artikl}>
                            <b>{this.state.document.nazivZaPrint}</b>
                        </p>
                        <p style={styles.sifra}>
                            {localization.printingComponents.ceduljicaSCijenom.sifra + ': '}
                            <b>{this.state.document.predmet.sifra}</b>
                        </p>
                        <p style={styles.cijenikBox}>
                            <span>
                                <b>{localization.printingComponents.ceduljicaSCijenom.gotovina}</b>
                            </span>
                            <span style={styles.kolicinaIcijena}>
                                <FormatIznosa value={this.state.document.jedProdajnaCijena} />
                            </span>
                        </p>
                        <p style={styles.cijenikBox}>
                            <span>
                                <b>{localization.printingComponents.ceduljicaSCijenom.kartice}</b>
                            </span>
                            <span style={styles.kolicinaIcijena}>
                                <FormatIznosa
                                    value={Math.round(this.state.document.jedProdajnaCijena * (1 + this.state.document.postotakNaKartice / 100) * 100) / 100}
                                />
                            </span>
                        </p>
                        <p style={styles.cijenikBox}>
                            <span>
                                <b>{localization.printingComponents.ceduljicaSCijenom.rata}</b>
                            </span>
                            <span style={styles.kolicinaIcijena}>
                                <FormatIznosa
                                    value={
                                        Math.round(this.state.document.jedProdajnaCijena * (1 + this.state.document.postotakNaKartice / 100) * 100) / 100 / 12
                                    }
                                />
                            </span>
                        </p>
                    </div>
                </Print>
                <NoPrint>
                    <div style={styles.printButton}>
                        <Button color="green" icon="print" size="massive" content={localization.common.ispis} onClick={this.printDocument} />
                    </div>
                </NoPrint>
            </PrintProvider>
        );
    }
}

CeduljicaSCijenom.propTypes = {
    match: PropTypes.object.isRequired
};
