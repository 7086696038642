import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Button, Dimmer, Form, Grid, Header, Loader, Segment } from 'semantic-ui-react';

import localization from '../../locales/localization';
import endpoints from '../../environments/endpoints';
import { storeDocumentAndGenerateKey } from '../../utils/PrintingDocumentsHandler';
import moment from 'moment';
import * as BlagajneDataAccess from '../../data_access/BlagajneDataAccess';
import * as OtkupiApi from '../../data_access/OtkupiApi';
import * as RacuniApi from '../../data_access/RacuniApi';
import { bindActionCreators } from 'redux';
import enums from '../../utils/Enums';

const styles = {
    dateContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    picker: {
        marginRight: 8
    },
    filterButton: {
        marginLeft: 16
    }
};

const GODINE = _.range(2019, parseInt(moment().get('year')) + 1);

const TIP_REKAPITULACIJE = [
    localization.rekapitulacije.fiskalniRacuni,
    localization.rekapitulacije.transakcijskiRacuni,
    localization.rekapitulacije.otkupniBlokovi,
    localization.rekapitulacije.blagajnickiDnevnikFisk,
    localization.rekapitulacije.blagajnickiDnevnikBlagajnaOtkupa
];

class Rekapitulacije extends React.Component {
    constructor(props) {
        super(props);
        let date = moment();
        this.state = {
            tipRekapitulacije: 0,
            poslovnicaId: this.props.poslovnice[0].id,
            godina: parseInt(date.format('YYYY')),
            mjesec: parseInt(date.format('MM')),
            loader: false
        };
        this.onChange = this.onChange.bind(this);
        this.napraviRekapitulaciju = this.napraviRekapitulaciju.bind(this);
        this.ispisRekapitulacijeRacuna = this.ispisRekapitulacijeRacuna.bind(this);
        this.ispisRekapitulacijeOtkupnihBlokova = this.ispisRekapitulacijeOtkupnihBlokova.bind(this);
        this.ispisBlagajnickogDnevnikaFiskalnaBlagajna = this.ispisBlagajnickogDnevnikaFiskalnaBlagajna.bind(this);
        this.ispisBlagajnickogDnevnikaOtkupnaBlagajna = this.ispisBlagajnickogDnevnikaOtkupnaBlagajna.bind(this);
        this.showLoader = this.showLoader.bind(this);
        this.hideLoader = this.hideLoader.bind(this);
    }

    onChange(e, { name, value }) {
        this.setState({ [name]: value });
    }

    napraviRekapitulaciju() {
        let startDate = moment(this.state.godina + '-' + this.state.mjesec + '-01');
        let startDateStr = startDate.format('YYYY-MM-DD');
        let endDate = moment(startDate).endOf('month');
        switch (TIP_REKAPITULACIJE[this.state.tipRekapitulacije]) {
            case localization.rekapitulacije.fiskalniRacuni: {
                this.showLoader();
                RacuniApi.getRacuniWithinDateRange(
                    this.state.poslovnicaId, enums.tipRacuna.FISKALNI_RACUN,
                    startDate, endDate,
                    (racuni) => this.hideLoader(() => this.ispisRekapitulacijeRacuna(racuni, enums.tipRacuna.FISKALNI_RACUN)),
                    () => this.hideLoader()
                )
                return;
            }
            case localization.rekapitulacije.transakcijskiRacuni: {
                this.showLoader();
                RacuniApi.getRacuniWithinDateRange(
                    this.state.poslovnicaId, enums.tipRacuna.TRANSAKCIJSKI_RACUN,
                    startDate, endDate,
                    (racuni) => this.hideLoader(() => this.ispisRekapitulacijeRacuna(racuni, enums.tipRacuna.TRANSAKCIJSKI_RACUN)),
                    () => this.hideLoader()
                )
                return;
            }
            case localization.rekapitulacije.otkupniBlokovi: {
                this.showLoader();
                OtkupiApi.getOtkupniBlokoviWithinDateRange(
                    this.state.poslovnicaId,
                    startDate, endDate,
                    (otkupniBlokovi) => this.hideLoader(() => this.ispisRekapitulacijeOtkupnihBlokova(otkupniBlokovi)),
                    () => this.hideLoader()
                )
                return;
            }
            case localization.rekapitulacije.blagajnickiDnevnikFisk: {
                endDate = endDate.format('YYYY-MM-DD');
                this.props.blagajneDataAccess.getBlagajnickiDnevnici(
                    this.state.poslovnicaId,
                    startDateStr,
                    endDate,
                    this.ispisBlagajnickogDnevnikaFiskalnaBlagajna
                );
                return;
            }
            case localization.rekapitulacije.blagajnickiDnevnikBlagajnaOtkupa: {
                endDate = endDate.format('YYYY-MM-DD');
                this.props.blagajneDataAccess.getBlagajnickiDnevnici(
                    this.state.poslovnicaId,
                    startDateStr,
                    endDate,
                    this.ispisBlagajnickogDnevnikaOtkupnaBlagajna
                );
                return;
            }
        }
    }

    ispisRekapitulacijeRacuna(racuni, tip) {
        let naslov;
        if (tip === enums.tipRacuna.FISKALNI_RACUN) {
            naslov = localization.rekapitulacije.rekapitulacijaRacuna.rekapitulacijaFiskalnihRacuna;
        } else {
            naslov = localization.rekapitulacije.rekapitulacijaRacuna.rekapitulacijaTransakcijskihRacuna;
        }
        let rekapitulacijaRacuna = {
            naslov: naslov + ' ' + localization.common.za + ' ' + this.state.mjesec + '. ' + localization.common.mjesec + ' ' + this.state.godina + '.',
            poslovnica: this.props.poslovnice.find(p => p.id === this.state.poslovnicaId),
            tipRacuna: tip,
            racuni: racuni.reverse()
        };
        window.open(endpoints.appEndpoints.print.rekapitulacijaRacuna + storeDocumentAndGenerateKey(rekapitulacijaRacuna), '_blank');
        this.hideLoader();
    }

    ispisRekapitulacijeOtkupnihBlokova(otkupniBlokovi) {
        let rekapitulacijaOtkupnihBlokova = {
            naslov:
                localization.rekapitulacije.rekapitulacijaOtkupnihBlokova.rekapitulacijaOtkupnihBlokova +
                ' ' +
                localization.common.za +
                ' ' +
                this.state.mjesec +
                '. ' +
                localization.common.mjesec +
                ' ' +
                this.state.godina +
                '.',
            godina: this.state.godina,
            poslovnica: this.props.poslovnice.find(p => p.id === this.state.poslovnicaId),
            otkupniBlokovi: otkupniBlokovi.reverse()
        };
        window.open(endpoints.appEndpoints.print.rekapitulacijaOtkupnihBlokova + storeDocumentAndGenerateKey(rekapitulacijaOtkupnihBlokova), '_blank');
        this.hideLoader();
    }

    ispisBlagajnickogDnevnikaFiskalnaBlagajna(blagajnickiDnevnikFisk) {
        let blagajnickiDnevnik = {
            naslov:
                localization.blagajnickiDnevnici.blagajnickiDnevnikFisk +
                ' ' +
                localization.common.za +
                ' ' +
                this.state.mjesec +
                '. ' +
                localization.common.mjesec +
                ' ' +
                this.state.godina +
                '.',
            poslovnica: this.props.poslovnice.find(p => p.id === this.state.poslovnicaId),
            blagajnickiDnevnik: blagajnickiDnevnikFisk,
            tipBlagajnickogDnevnika: enums.tipBlagajneRadnje.FISKALNA
        };
        window.open(endpoints.appEndpoints.print.blagajnickiDnevnik + storeDocumentAndGenerateKey(blagajnickiDnevnik), '_blank');
        this.hideLoader();
    }

    ispisBlagajnickogDnevnikaOtkupnaBlagajna(blagajnickiDnevnikFisk, blagajnickiDnevnikBlagajnaOtkupa) {
        let blagajnickiDnevnik = {
            naslov:
                localization.blagajnickiDnevnici.blagajnickiDnevnikOtkup +
                ' ' +
                localization.common.za +
                ' ' +
                this.state.mjesec +
                '. ' +
                localization.common.mjesec +
                ' ' +
                this.state.godina +
                '.',
            godina: this.state.godina,
            poslovnica: this.props.poslovnice.find(p => p.id === this.state.poslovnicaId),
            blagajnickiDnevnik: blagajnickiDnevnikBlagajnaOtkupa,
            tipBlagajnickogDnevnika: enums.tipBlagajneRadnje.OTKUPNA
        };
        window.open(endpoints.appEndpoints.print.blagajnickiDnevnik + storeDocumentAndGenerateKey(blagajnickiDnevnik), '_blank');
        this.hideLoader();
    }

    showLoader(callback) {
        this.setState(
            {
                loader: true
            },
            callback
        );
    }

    hideLoader(callback) {
        this.setState({
            loader: false
        }, callback);
    }

    render() {
        return (
            <div>
                <Segment tertiary>
                    <Grid stackable>
                        <Grid.Row>
                            <Grid.Column verticalAlign="middle">
                                <Header as="h2">{localization.rekapitulacije.rekapitulacije}</Header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column verticalAlign="middle">
                                <div style={styles.dateContainer}>
                                    <Form.Select
                                        label={localization.rekapitulacije.tipRekapitulacije}
                                        style={styles.picker}
                                        options={TIP_REKAPITULACIJE.map((r, index) => {
                                            return { key: index, text: r, value: index };
                                        })}
                                        name="tipRekapitulacije"
                                        onChange={this.onChange}
                                        value={this.state.tipRekapitulacije}
                                        compact
                                        required
                                        search
                                    />
                                    <Form.Select
                                        label={localization.rekapitulacije.zaPoslovnicu}
                                        style={styles.picker}
                                        options={this.props.poslovnice.map(p => {
                                            return { key: p.id, text: p.naziv, value: p.id };
                                        })}
                                        name="poslovnicaId"
                                        onChange={this.onChange}
                                        value={this.state.poslovnicaId}
                                        compact
                                        required
                                        search
                                    />
                                    <Form.Select
                                        label={localization.rekapitulacije.zaMjesec}
                                        style={styles.picker}
                                        options={Array.from(new Array(12), (val, index) => {
                                            return { key: index, text: index + 1 + '.', value: index + 1 };
                                        })}
                                        name="mjesec"
                                        labeled
                                        onChange={this.onChange}
                                        value={this.state.mjesec}
                                        compact
                                        required
                                        search
                                    />
                                    <Form.Select
                                        label={localization.rekapitulacije.uGodini}
                                        style={styles.picker}
                                        options={GODINE.map((g, index) => {
                                            return { key: index, text: g + '.', value: g };
                                        })}
                                        name="godina"
                                        onChange={this.onChange}
                                        value={this.state.godina}
                                        compact
                                        required
                                        search
                                    />
                                    <Button
                                        icon="table"
                                        basic
                                        color="black"
                                        size="small"
                                        style={styles.filterButton}
                                        content={localization.rekapitulacije.napraviRekapitulaciju}
                                        onClick={this.showLoader.bind(this, this.napraviRekapitulaciju)}
                                    />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Dimmer active={this.state.loader} page>
                    <Loader />
                </Dimmer>
            </div>
        );
    }
}

Rekapitulacije.propTypes = {
    poslovnice: PropTypes.array.isRequired,
    blagajnickiDnevnikFisk: PropTypes.object.isRequired,
    blagajneDataAccess: PropTypes.object.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {
        poslovnice: state.mjestaReducer.poslovnice || [],
        blagajnickiDnevnikFisk: state.blagajneReducer.blagajnickiDnevnikFisk
    };
};

let mapDispatchToProps = function(dispatch) {
    return {
        blagajneDataAccess: bindActionCreators(BlagajneDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Rekapitulacije);
