import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NoviRacun from './prodaja_components/NoviRacun';

const styles = {};

class Prodaja extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.location.state !== 'predracun') {
            return <NoviRacun history={this.props.history} />;
        } else {
            return <NoviRacun history={this.props.history} isPredracun={true} />;
        }
    }
}

Prodaja.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {};
};

let mapDispatchToProps = function(dispatch) {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Prodaja);
