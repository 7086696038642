import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Card, Header, Segment } from 'semantic-ui-react';

import Korisnik from '../common_components/Korisnik';
import AddNewButton from '../common_components/AddNewButton';
import localization from '../../locales/localization';
import { generateKey } from '../../utils/KeyGenerator';
import enums from '../../utils/Enums';
import * as UsersDataAccess from '../../data_access/UsersDataAccess';

const styles = {
    actionButtons: {
        display: 'flex',
        justifyContent: 'center'
    }
};

class UpravljanjeKorisnicima extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            korisnici: this.props.korisnici,
            noviKorisnik: false
        };
        this.onDeleteKorisnik = this.onDeleteKorisnik.bind(this);
        this.onAddNew = this.onAddNew.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            korisnici: nextProps.korisnici,
            noviKorisnik: false
        });
    }

    onDeleteKorisnik(index) {
        this.setState({
            korisnici: this.state.korisnici.slice(0, index).concat(this.state.korisnici.slice(index + 1)),
            noviKorisnik: this.state.noviKorisnik && !(index === this.state.korisnici.length - 1)
        });
    }

    onAddNew(e) {
        this.setState({
            korisnici: [
                ...this.state.korisnici,
                {
                    novi: true,
                    role: enums.tipKorisnika.DJELATNIK,
                    username: '',
                    ime: '',
                    prezime: '',
                    email: ''
                }
            ],
            noviKorisnik: true
        });
    }

    render() {
        return (
            <Segment tertiary>
                <Header as="h2">{localization.postavke.upravljanjeKorisnicima}</Header>
                <Card.Group itemsPerRow={2} stackable>
                    {this.state.korisnici.map((korisnik, index) => {
                        return (
                            <Card key={index}>
                                <Card.Content textAlign="center">
                                    <Korisnik novo={!!korisnik.novi} korisnik={korisnik} onCancel={this.onDeleteKorisnik.bind(this, index)} />
                                </Card.Content>
                            </Card>
                        );
                    })}
                    {!this.state.noviKorisnik ? (
                        <AddNewButton key={generateKey()} text={localization.postavke.dodajNovogKorisnika} name="noviKorisnik" onAddNew={this.onAddNew} />
                    ) : null}
                </Card.Group>
            </Segment>
        );
    }
}

UpravljanjeKorisnicima.propTypes = {
    history: PropTypes.object.isRequired,
    korisnici: PropTypes.array.isRequired,
    usersDataAccess: PropTypes.object.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {
        korisnici: state.korisniciReducer.korisnici
    };
};

let mapDispatchToProps = function(dispatch) {
    return {
        usersDataAccess: bindActionCreators(UsersDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpravljanjeKorisnicima);
