// TODO @Kec #pagination #pagination-rezervacije #refactor This should probably be moved into some sort of utils file       
export function attachStanjeAndMergeRezervationList(zr) {
    if (zr.rezervacije.istekaoRZP.length) {
        zr.stanjeZR = 1;
    } else if (zr.rezervacije.nijeIstekaoRZPIstekla.length) {
        zr.stanjeZR = 2;
    } else {
        zr.stanjeZR = 3;
    }
    let listaRezervacija = zr.rezervacije.istekaoRZP.concat(zr.rezervacije.nijeIstekaoRZPIstekla, zr.rezervacije.nijeIstekaoRZPNijeIstekla);
    zr.rezervacije = [...listaRezervacija];
    return zr;
}
