import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Accordion, Button, Dimmer, Form, Grid, Header, Icon, Loader, Message, Modal, Pagination, Search, Segment, Select, Table } from 'semantic-ui-react';

import localization from '../../locales/localization';
import endpoints from '../../environments/endpoints';
import enums from '../../utils/Enums';
import icons from '../../utils/DefinedIcons';
import { storeDocumentAndGenerateKey } from '../../utils/PrintingDocumentsHandler';
import moment from 'moment';
import * as ProdajaDataAccess from '../../data_access/ProdajaDataAccess';
import * as RacuniApi from '../../data_access/RacuniApi';
import { bindActionCreators } from 'redux';
import SearchResultsMessage from '../common_components/SearchResultsMessage';
import Diskusija from '../common_components/Diskusija';
import OtkupProdajaContent from './content_components/OtkupProdajaContent';
import FormatIznosa from '../common_components/FormatIznosa';
import FilterSortButton from '../common_components/FilterSortButton';

// TODO #pagination #refactor Extract this to some other place when pagination for other components is implemented
//      and we solve the Redux/DataAccess dilemma.
const MAX_API_FETCH_ATTEMPTS = 3;
// TODO #pagination #refactor Probably extract somewhere else and use across all search components
const SEARCH_DEBOUNCE_RATE_MS = 1000;

const styles = {
    headerTable: {
        padding: '.75em 1em'
    },
    iconCell: {
        width: 50
    },
    brojRacunaCell: {
        width: 150
    },
    tipRacunaCell: {
        width: 200
    },
    klijentCell: {
        width: 350
    },
    ukupnaCijenaCell: {
        width: 300
    },
    accordionHeaderContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    accordionHeaderText: {
        paddingLeft: 4,
        paddingRight: 2,
        paddingBottom: '0.5em'
    },
    actionButtons: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    semaforStyle: {
        marginLeft: 4
    }
};

const potvrdeEnums = {
    storniranjeRacuna: 'storniranjeRacuna'
};

const TIP_RACUNA_FILTER = [
    {
        name: localization.racuni.sviRacuni,
        apiParam: RacuniApi.TIP_RACUNA.all
    },
    {
        name: localization.racuni.fiskalniRacuni,
        apiParam: RacuniApi.TIP_RACUNA.fiskalni
    },
    {
        name: localization.racuni.transakcijskiRacuni,
        apiParam: RacuniApi.TIP_RACUNA.transakcijski
    },
    {
        name: localization.racuni.gotovinskiRacuni,
        apiParam: RacuniApi.TIP_RACUNA.gotovinski
    },
    {
        name: localization.racuni.karticniRacuni,
        apiParam: RacuniApi.TIP_RACUNA.karticni
    }
];

const LIST_STATE = {
    initial: 'initial',
    empty: 'empty',
    noResult: 'noResult',
    populated: 'populated',
    error: 'error'
};

const ITEMS_PER_PAGE = 10;

class Racuni extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            activeInvoices: [],
            activeComments: [],
            activePotvrda: undefined,
            sluzbenaNapomena: '',
            nesluzbenaNapomena: '',
            noveNapomene: [],
            loader: false,
            filter: 0,
            filterRacuna: 0,
            pageActive: 1
        };
        this.onNoviRacun = this.onNoviRacun.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
        this.applyFilterTipaRacuna = this.applyFilterTipaRacuna.bind(this);
        this.applyFilterAndSort = this.applyFilterAndSort.bind(this);
        this.resetSearchComponent = this.resetSearchComponent.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.onChangeNapomene = this.onChangeNapomene.bind(this);
        this.calculateUkupno = this.calculateUkupno.bind(this);
        this.onChangeNovaNapomena = this.onChangeNovaNapomena.bind(this);
        this.expandInvoice = this.expandInvoice.bind(this);
        this.expandComments = this.expandComments.bind(this);
        this.ispisRacuna = this.ispisRacuna.bind(this);
        this.stornoRacuna = this.stornoRacuna.bind(this);
        this.dodajNapomenu = this.dodajNapomenu.bind(this);
        this.resetNapomena = this.resetNapomena.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.showLoader = this.showLoader.bind(this);
        this.hideLoader = this.hideLoader.bind(this);
        this.fetchRacuni = this.fetchRacuni.bind(this);
        this.refreshRacuni = this.refreshRacuni.bind(this);
        this.getPoslovnicaIdForFilter = this.getPoslovnicaIdForFilter.bind(this);
        this.renderInitialComponent = this.renderInitialComponent.bind(this);
        this.renderEmptyComponent = this.renderEmptyComponent.bind(this);
        this.renderNoResultComponent = this.renderNoResultComponent.bind(this);
        this.renderErrorComponent = this.renderErrorComponent.bind(this);
        this.renderListComponent = this.renderListComponent.bind(this);
        this.debouncedFetchRacuni = _.debounce(function (filter, filterRacuna, page) {
            this.fetchRacuni(filter, filterRacuna, page);
        }, SEARCH_DEBOUNCE_RATE_MS).bind(this);

        if (sessionStorage.userRole !== enums.tipKorisnika.DJELATNIK) {
            this.FILTER_ITEMS = [{
                name: localization.common.svePoslovnice,
                apiParam: null
            }];
            this.props.poslovnice.forEach(p => this.FILTER_ITEMS.push({
                name: p.naziv,
                apiParam: p.id
            }));
        }
    }

    componentWillMount() {
        this.resetSearchComponent();
    }

    onNoviRacun() {
        this.props.history.push(endpoints.appEndpoints.prodaja);
    }

    onChangePage(e, { activePage }) {
        this.fetchRacuni(this.state.filter, this.state.filterRacuna, activePage);
    }

    applyFilterTipaRacuna(e, { value }) {
        this.fetchRacuni(this.state.filter, value);
    }

    applyFilterAndSort(filter, sort) {
        this.fetchRacuni(filter);
    }

    resetSearchComponent() {
        this.setState({
            listState: LIST_STATE.initial,
            fetchAttempts: 0
        }, () => {
            this.fetchRacuni();
        });
    }

    handleSearchChange(e, { value }) {
        this.setState({
            value: value
        }, () => {
            this.debouncedFetchRacuni();
        });
    }

    onChangeNapomene(e, { name, value }) {
        this.setState({ [name]: value });
    }

    calculateUkupno(racun) {
        let ukupno = 0;
        racun.skladisniIzlazi.forEach(si => {
            if (racun.rbrStorniranogRacuna === null) {
                ukupno += si.kolicina * si.jedCijena;
            } else {
                ukupno += -si.kolicina * si.jedCijena;
            }
        });
        return ukupno;
    }

    onChangeNovaNapomena(id, e, { value }) {
        let noveNapomene = [];
        let index = this.state.noveNapomene.findIndex(el => el.id === id);
        if (index > -1) {
            noveNapomene = [...this.state.noveNapomene];
            noveNapomene[index] = {
                id: id,
                napomena: value
            };
        } else {
            noveNapomene = [
                ...this.state.noveNapomene,
                {
                    id: id,
                    napomena: value
                }
            ];
        }
        this.setState({
            noveNapomene: noveNapomene
        });
    }

    expandInvoice(e, { index }) {
        let activeInvoices = [];
        if (this.state.activeInvoices.includes(index)) {
            activeInvoices = _.xor(this.state.activeInvoices, [index]);
        } else {
            activeInvoices = [...this.state.activeInvoices, index];
        }
        this.setState({
            activeInvoices: activeInvoices
        });
    }

    expandComments(e, { index }) {
        let activeComments = [];
        if (this.state.activeComments.includes(index)) {
            activeComments = _.xor(this.state.activeComments, [index]);
        } else {
            activeComments = [...this.state.activeComments, index];
        }
        this.setState({
            activeComments: activeComments
        });
    }

    ispisRacuna(racun) {
        window.open(endpoints.appEndpoints.print.racun + storeDocumentAndGenerateKey(racun), '_blank');
    }

    stornoRacuna(racunId) {
        this.showLoader();
        this.props.prodajaDataAccess.stornirajRacun(
            racunId,
            {
                sluzbenaNapomena: this.state.sluzbenaNapomena,
                nesluzbenaNapomena: this.state.nesluzbenaNapomena || null
            },
            () => this.hideLoader(() => this.hideModal(() => this.refreshRacuni())),
            () => this.hideLoader()
        );
    }

    dodajNapomenu(racunId) {
        this.showLoader();
        this.props.prodajaDataAccess.dodavanjeNesluzbeneNapomeneNaRacun(
            racunId,
            this.state.noveNapomene.find(el => el.id === racunId).napomena,
            () => this.hideLoader(() => this.resetNapomena(racunId, () => this.refreshRacuni())),
            () => this.hideLoader()
        );
    }

    resetNapomena(id, callback) {
        this.setState({
            noveNapomene: _.reject(this.state.noveNapomene, (napomena) => napomena.id === id)
        }, callback);
    }

    showModal(name, sifra) {
        this.setState({
            activePotvrda: {
                type: name,
                id: sifra
            }
        });
    }

    hideModal(callback) {
        this.setState({ activePotvrda: undefined }, callback);
    }

    showLoader() {
        this.setState(
            {
                loader: true
            }
        );
    }

    hideLoader(callback) {
        this.setState(
            {
                loader: false
            },
            callback
        );
    }

    fetchRacuni(filter = this.state.filter, filterRacuna = this.state.filterRacuna, page = 1) {

        let nextStateCommon = {
            loader: false,
            fetchAttempts: 0,
            filter: filter,
            filterRacuna: filterRacuna
        };

        let onFetchSuccess = (data) => {
            if (data.items.length !== 0) {
                let nextStateNonEmpty = {
                    listState: LIST_STATE.populated,
                    racuni: data.items,
                    pages: data.totalPages,
                    pageActive: page
                };
                this.setState(Object.assign({}, nextStateCommon, nextStateNonEmpty));

            } else {

                let fetchedAll = this.state.value === '';

                let newListState = fetchedAll ? LIST_STATE.empty : LIST_STATE.noResult;
                let nextStateEmpty = {
                    listState: newListState,
                    racuni: []
                };

                this.setState(Object.assign({}, nextStateCommon, nextStateEmpty));
            }
        };

        let onFetchError = () => {
            let fetchAttempts = this.state.fetchAttempts + 1;
            if (fetchAttempts === MAX_API_FETCH_ATTEMPTS) {
                let nextStateError = {
                    listState: LIST_STATE.error
                };
                this.setState(Object.assign({}, nextStateCommon, nextStateError));
            } else {
                this.setState({
                    fetchAttempts: fetchAttempts
                }, () => {
                    this.fetchRacuni(filter, filterRacuna, page);
                });
            }

        };

        this.showLoader();

        RacuniApi.getRacuniPaginated(
            this.state.value, page, ITEMS_PER_PAGE, this.getPoslovnicaIdForFilter(filter),
            TIP_RACUNA_FILTER[filterRacuna].apiParam, onFetchSuccess, onFetchError
        );
    }

    refreshRacuni() {
        this.fetchRacuni(this.state.filter, this.state.filterRacuna, this.state.pageActive);
    }

    getPoslovnicaIdForFilter(filter) {
        return this.FILTER_ITEMS
            ? this.FILTER_ITEMS[filter].apiParam
            : JSON.parse(sessionStorage.poslovnicaId);
    }

    renderInitialComponent() {
        return (
            <SearchResultsMessage
                icon={icons.racun}
                header={localization.common.ucitavanje}
                content={localization.common.molimoPricekajte}
            />
        );
    }

    renderEmptyComponent() {
        return (
            <SearchResultsMessage
                icon={icons.racun}
                header={localization.racuni.nemaRacuna}
                content={undefined}
            />
        );
    }

    renderNoResultComponent() {
        return (
            <SearchResultsMessage
                icon={icons.racun}
                header={localization.common.unesenaPretragaNemaRezultata}
                content={localization.common.pokusajDrugacijuPretragu}
            />
        );
    }

    renderErrorComponent() {
        return (
            <SearchResultsMessage
                icon={icons.racun}
                header={localization.common.dogodilaSeGreska}
                content={localization.common.pokusajtePonovno}
            />
        );
    }

    renderListComponent() {
        return (
            <React.Fragment>
                <Grid.Row>
                    <Grid.Column>
                        <Message style={styles.headerTable}>
                            <Table structured stackable>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell style={styles.iconCell} />
                                        <Table.HeaderCell style={styles.brojRacunaCell}>{localization.racuni.brojRacuna}</Table.HeaderCell>
                                        <Table.HeaderCell style={styles.tipRacunaCell}>{localization.racuni.tipRacuna}</Table.HeaderCell>
                                        <Table.HeaderCell style={styles.klijentCell}>{localization.racuni.klijent}</Table.HeaderCell>
                                        <Table.HeaderCell style={styles.ukupnaCijenaCell}>{localization.common.ukupnaCijena}</Table.HeaderCell>
                                        <Table.HeaderCell>{localization.racuni.datumIVrijemeRacuna}</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                            </Table>
                        </Message>
                    </Grid.Column>
                </Grid.Row>
                {this.state.racuni.map(racun => {
                    return (
                        <Grid.Row key={racun.id}>
                            <Grid.Column verticalAlign="middle">
                                <Accordion fluid styled>
                                    <Accordion.Title
                                        active={this.state.activeInvoices.includes(racun.id)}
                                        index={racun.id}
                                        onClick={this.expandInvoice}
                                    >
                                        <Table structured stackable color={racun.isStorniran ? 'grey' : undefined} inverted={racun.isStorniran}>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.Cell style={styles.iconCell}>
                                                        <Icon name="dropdown" />
                                                    </Table.Cell>
                                                    <Table.Cell style={styles.brojRacunaCell}>
                                                        {racun.rbrFiskalniRacun || racun.rbrTransakcijskiRacun}
                                                    </Table.Cell>
                                                    <Table.Cell style={styles.tipRacunaCell}>
                                                        {racun.isStorniran === null && racun.rbrStorniranogRacuna
                                                            ? localization.racuni.stornoracuna +
                                                              ': ' +
                                                              racun.tipRacuna +
                                                              ' ' +
                                                              racun.rbrStorniranogRacuna
                                                            : racun.tipRacuna}
                                                    </Table.Cell>
                                                    <Table.Cell style={styles.klijentCell}>
                                                        {racun.klijent
                                                            ? racun.klijent.tipKlijenta === enums.tipKlijenta.FIZICKA_OSOBA
                                                                ? racun.klijent.ime + ' ' + racun.klijent.prezime
                                                                : racun.klijent.naziv
                                                            : localization.prodaja.privatnaOsoba}
                                                    </Table.Cell>
                                                    <Table.Cell style={styles.ukupnaCijenaCell}>
                                                        <FormatIznosa value={this.calculateUkupno(racun)} />
                                                    </Table.Cell>
                                                    <Table.Cell>{moment(racun.datumIVrijeme).format('DD.MM.YYYY. HH:mm')}</Table.Cell>
                                                </Table.Row>
                                            </Table.Header>
                                        </Table>
                                    </Accordion.Title>
                                    <Accordion.Content active={this.state.activeInvoices.includes(racun.id)}>
                                        <OtkupProdajaContent
                                            klijent={racun.klijent}
                                            type={enums.tipTabliceArtikala.RACUN_FIXED}
                                            artikli={racun.skladisniIzlazi.map(si => {
                                                return Object.assign({}, si, {
                                                    sifra: si.sifraArtikla,
                                                    naziv: si.nazivArtikla,
                                                    cijena: si.jedCijena,
                                                    kolicina: racun.rbrStorniranogRacuna === null ? si.kolicina : -si.kolicina
                                                });
                                            })}
                                        />
                                        <div style={styles.actionButtons}>
                                            <Button
                                                icon="print"
                                                color="green"
                                                content={localization.racuni.ispisRacuna}
                                                onClick={this.ispisRacuna.bind(this, racun)}
                                            />
                                            {!racun.isStorniran &&
                                            !racun.rbrStorniranogRacuna &&
                                            sessionStorage.userRole === enums.tipKorisnika.DJELATNIK ? (
                                                <Modal
                                                    open={
                                                        this.state.activePotvrda &&
                                                        this.state.activePotvrda.type === potvrdeEnums.storniranjeRacuna &&
                                                        this.state.activePotvrda.id === racun.id
                                                    }
                                                    closeOnDimmerClick={false}
                                                    trigger={
                                                        <Button
                                                            icon="undo"
                                                            color="red"
                                                            content={localization.racuni.storniranjeRacuna}
                                                            onClick={() => this.showModal(potvrdeEnums.storniranjeRacuna, racun.id)}
                                                        />
                                                    }
                                                >
                                                    <Header icon="undo" content={localization.common.potvrda} />
                                                    <Modal.Content>
                                                        <p>{localization.racuni.storniranjePitanjePotvrde}</p>
                                                        <Form>
                                                            <Form.TextArea
                                                                rows={2}
                                                                placeholder={localization.prodaja.sluzbenaNapomenaRacuna}
                                                                name="sluzbenaNapomena"
                                                                onChange={this.onChangeNapomene}
                                                            />
                                                            <Form.TextArea
                                                                rows={2}
                                                                placeholder={localization.prodaja.nesluzbenaNapomenaRacuna}
                                                                name="nesluzbenaNapomena"
                                                                onChange={this.onChangeNapomene}
                                                            />
                                                        </Form>
                                                    </Modal.Content>
                                                    <Modal.Actions>
                                                        <Button
                                                            icon="remove"
                                                            content={localization.common.odustani}
                                                            onClick={() => this.hideModal()}
                                                        />
                                                        <Button
                                                            icon="copy"
                                                            color="red"
                                                            content={localization.racuni.storniraj}
                                                            disabled={!this.state.sluzbenaNapomena}
                                                            onClick={() => this.stornoRacuna(racun.id)}
                                                        />
                                                    </Modal.Actions>
                                                </Modal>
                                            ) : null}
                                        </div>
                                        {racun.sluzbenaNapomena ? (
                                            <Message>
                                                <Message.Header>{localization.common.sluzbenaNapomena}</Message.Header>
                                                <p>{racun.sluzbenaNapomena}</p>
                                            </Message>
                                        ) : null}
                                        <Accordion fluid styled>
                                            <Accordion.Title
                                                active={this.state.activeComments.includes(racun.id)}
                                                index={racun.id}
                                                onClick={this.expandComments}
                                            >
                                                <Header as="h5" dividing style={styles.accordionHeaderContainer}>
                                                    <Icon name="dropdown" />
                                                    {localization.common.napomene}
                                                    {racun.nesluzbeneNapomene.length ? (
                                                        <Icon name="exclamation circle" color="red" style={styles.semaforStyle} />
                                                    ) : null}
                                                </Header>
                                            </Accordion.Title>
                                            <Accordion.Content active={this.state.activeComments.includes(racun.id)}>
                                                <Diskusija
                                                    napomene={racun.nesluzbeneNapomene}
                                                    textAreaValue={
                                                        this.state.noveNapomene.find(el => el.id === racun.id)
                                                            ? this.state.noveNapomene.find(el => el.id === racun.id).napomena
                                                            : ''
                                                    }
                                                    onChangeNovaNapomena={this.onChangeNovaNapomena.bind(this, racun.id)}
                                                    onDodajNapomenu={() => this.dodajNapomenu(racun.id)}
                                                />
                                            </Accordion.Content>
                                        </Accordion>
                                    </Accordion.Content>
                                </Accordion>
                            </Grid.Column>
                        </Grid.Row>
                    );
                })}
                <Grid.Row centered>
                    <Pagination
                        activePage={this.state.pageActive}
                        totalPages={this.state.pages}
                        onPageChange={this.onChangePage}
                        ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
                        firstItem={{ content: <Icon name="angle double left" />, icon: true }}
                        lastItem={{ content: <Icon name="angle double right" />, icon: true }}
                        prevItem={{ content: <Icon name="angle left" />, icon: true }}
                        nextItem={{ content: <Icon name="angle right" />, icon: true }}
                    />
                </Grid.Row>
            </React.Fragment>
        );
    }

    render() {
        return (
            <div>
                <Segment tertiary>
                    <Grid stackable>
                        <Grid.Row columns={6}>
                            <Grid.Column verticalAlign="middle">
                                <Header as="h2">{localization.racuni.racuni}</Header>
                            </Grid.Column>
                            <Grid.Column verticalAlign="middle">
                                <Search
                                    input={{ fluid: true }}
                                    loading={this.state.isSearchLoading}
                                    onSearchChange={this.handleSearchChange}
                                    value={this.state.value}
                                    open={false}
                                />
                            </Grid.Column>
                            <Grid.Column />
                            <Grid.Column />
                            <Grid.Column textAlign="right" verticalAlign="middle">
                                <Select
                                    options={TIP_RACUNA_FILTER.map((t, index) => {
                                        return { key: index, text: t.name, value: index };
                                    })}
                                    onChange={this.applyFilterTipaRacuna}
                                    value={this.state.filterRacuna}
                                    compact
                                    required
                                    search
                                />
                            </Grid.Column>
                            {sessionStorage.userRole === enums.tipKorisnika.DJELATNIK ? (
                                <Grid.Column textAlign="right" verticalAlign="middle">
                                    <Button icon="asterisk" basic color="black" content={localization.racuni.noviRacun} onClick={this.onNoviRacun} />
                                </Grid.Column>
                            ) : (
                                <Grid.Column textAlign="right" verticalAlign="middle">
                                    <FilterSortButton
                                        buttonName={localization.common.filtriranje}
                                        filteringOptions={this.FILTER_ITEMS.map((f) => f.name)}
                                        activeFilter={this.state.filter}
                                        callback={this.applyFilterAndSort}
                                    />
                                </Grid.Column>
                            )}
                        </Grid.Row>
                        {(() => {
                            switch (this.state.listState) {
                                case LIST_STATE.initial:
                                    return this.renderInitialComponent();
                                case LIST_STATE.populated:
                                    return this.renderListComponent();
                                case LIST_STATE.empty:
                                    return this.renderEmptyComponent();
                                case LIST_STATE.noResult:
                                    return this.renderNoResultComponent();
                                case LIST_STATE.error:
                                    return this.renderErrorComponent();
                            }
                        })()}
                    </Grid>
                </Segment>
                <Dimmer active={this.state.loader} page>
                    <Loader />
                </Dimmer>
            </div>
        );
    }
}

Racuni.propTypes = {
    history: PropTypes.object.isRequired,
    poslovnice: PropTypes.array.isRequired,
    prodajaDataAccess: PropTypes.object.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {
        poslovnice: state.mjestaReducer.poslovnice || []
    };
};

let mapDispatchToProps = function(dispatch) {
    return {
        prodajaDataAccess: bindActionCreators(ProdajaDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Racuni);
