import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Dimmer, Form, Grid, Header, Loader, Modal, Segment } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

import localization from '../../locales/localization';
import endpoints from '../../environments/endpoints';
import enums from '../../utils/Enums';
import icons from '../../utils/DefinedIcons';
import { storeDocumentAndGenerateKey } from '../../utils/PrintingDocumentsHandler';
import * as IzdatniceDataAccess from '../../data_access/IzdatniceDataAccess';
import { bindActionCreators } from 'redux';
import OdabirArtikla from '../common_components/OdabirArtikla';
import Napomene from '../common_components/Napomene';

const styles = {
    confirmButton: {
        textAlign: 'center',
        marginBottom: 16,
    },
};

class NovaIzdatnica extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            poslovnicaId: this.props.poslovnice.length === 1 ? this.props.poslovnice[0].id : undefined,
            items: [],
            viewModel: {
                artikli: [],
            },
            izdatnica: {
                ukupno: undefined,
            },
            datum: moment().subtract(1, 'days'),
            sluzbenaNapomena: '',
            nesluzbenaNapomena: '',
            napraviIzdatnicuEnabled: false,
            potvrdaIzdatnice: false,
            loader: this.props.poslovnice.length === 1,
        };
        this.onViewModelLoaded = this.onViewModelLoaded.bind(this);
        this.handleOdabirArtikla = this.handleOdabirArtikla.bind(this);
        this.setUkupnaCijena = this.setUkupnaCijena.bind(this);
        this.onChangeNapomena = this.onChangeNapomena.bind(this);
        this.onChangeDatum = this.onChangeDatum.bind(this);
        this.onChangePoslovnica = this.onChangePoslovnica.bind(this);
        this.enableNapraviIzdatnicuButton = this.enableNapraviIzdatnicuButton.bind(this);
        this.checkKomponenteIzdatnice = this.checkKomponenteIzdatnice.bind(this);
        this.showHidePotvrda = this.showHidePotvrda.bind(this);
        this.showLoader = this.showLoader.bind(this);
        this.hideLoader = this.hideLoader.bind(this);
        this.napraviIzdatnicu = this.napraviIzdatnicu.bind(this);
        this.openIzdatnicu = this.openIzdatnicu.bind(this);

        if (this.props.poslovnice.length === 1) {
            this.props.izdatniceDataAccess.getIzdatniceViewModel(this.props.poslovnice[0].id, this.onViewModelLoaded);
        }
    }

    onViewModelLoaded(data) {
        this.setState({
            viewModel: {
                artikli: data.stavke,
            },
            loader: false,
        });
    }

    handleOdabirArtikla(itemsSelected) {
        this.setState({ items: [...itemsSelected] }, this.setUkupnaCijena);
    }

    setUkupnaCijena() {
        let ukupno = 0,
            missingData = false;
        this.state.items.forEach((item) => {
            if (item.kolicina && item.cijena) {
                ukupno += item.kolicina * item.cijena;
            } else {
                missingData = true;
            }
        });
        this.setState(
            {
                izdatnica: Object.assign({}, this.state.izdatnica, {
                    ukupno: !missingData ? ukupno : undefined,
                }),
            },
            this.enableNapraviIzdatnicuButton
        );
    }

    onChangePoslovnica(e, { name, value }) {
        this.setState({ poslovnicaId: value, loader: true });
        this.props.izdatniceDataAccess.getIzdatniceViewModel(value, this.onViewModelLoaded);
    }

    onChangeDatum(date) {
        this.setState({ datum: date });
    }

    onChangeNapomena(e, { name, value }) {
        this.setState({ [name]: value }, this.setUkupnaCijena);
    }

    enableNapraviIzdatnicuButton() {
        this.setState({
            napraviIzdatnicuEnabled: this.checkKomponenteIzdatnice(),
        });
    }

    checkKomponenteIzdatnice() {
        if (!this.state.items.length) {
            return false;
        } else {
            for (let i = 0; i < this.state.items.length; i++) {
                if (!this.state.items[i].kolicina) {
                    return false;
                }
            }
        }
        return true;
    }

    showHidePotvrda(value) {
        this.setState({
            potvrdaIzdatnice: value,
        });
    }

    showLoader(callback) {
        this.setState(
            {
                loader: true,
            },
            callback
        );
    }

    hideLoader(callback, data) {
        this.setState(
            {
                loader: false,
            },
            callback.bind(this, data)
        );
    }

    napraviIzdatnicu() {
        let izdatnica = {
            poslovnicaId: this.state.poslovnicaId,
            datum: this.state.datum.format('YYYY-MM-DD'),
            stavke: this.state.items.map((artikl) => {
                return {
                    predmetId: artikl.id,
                    kolicina: parseFloat(artikl.kolicina),
                };
            }),
            sluzbenaNapomena: this.state.sluzbenaNapomena || null,
            nesluzbenaNapomena: this.state.nesluzbenaNapomena || null,
        };
        this.props.izdatniceDataAccess.createIzdatnica(izdatnica, this.hideLoader.bind(this, this.openIzdatnicu));
    }

    openIzdatnicu(izdatnica) {
        window.open(endpoints.appEndpoints.print.izdatnica + storeDocumentAndGenerateKey(izdatnica), '_blank');
        this.props.history.goBack();
    }

    render() {
        return (
            <div>
                {!this.state.poslovnicaId ? (
                    <Form className="attached fluid segment">
                        <Form.Select
                            fluid
                            label={localization.izdatnice.poslovnica}
                            options={this.props.poslovnice.map((poslovnica) => {
                                return { key: poslovnica.id, text: poslovnica.naziv, value: poslovnica.id };
                            })}
                            placeholder={localization.izdatnice.poslovnica}
                            name="poslovnica"
                            onChange={this.onChangePoslovnica}
                            search
                        />
                    </Form>
                ) : (
                    <div>
                        <Segment tertiary>
                            <Header as="h2">{localization.izdatnice.novaIzdatnica}</Header>
                        </Segment>
                        <Segment tertiary>
                            <OdabirArtikla
                                artikli={this.state.viewModel.artikli.map((a) => {
                                    let artikl;
                                    artikl = Object.assign({}, a, a.predmet, {
                                        maxKolicina: a.kolicina,
                                        kolicina: a.kolicina >= 1 ? 1 : a.kolicina,
                                        cijena: a.jedNabavnaCijena,
                                    });
                                    return artikl;
                                })}
                                onItemSelected={this.handleOdabirArtikla}
                                type={enums.tipTabliceArtikala.IZDATNICA_EDITABLE}
                                newItemDisabled={true}
                            />
                        </Segment>
                        <Segment tertiary>
                            <Grid stackable>
                                <Grid.Row columns={5}>
                                    <Grid.Column verticalAlign="middle">
                                        <Header as="h3">{localization.izdatnice.datumIzdatnice}</Header>
                                    </Grid.Column>
                                    <Grid.Column verticalAlign="middle">
                                        <DatePicker
                                            customInput={
                                                <Button fluid compact basic color="black">
                                                    {this.state.datum.format('DD.MM.YYYY.')}
                                                </Button>
                                            }
                                            dateFormat="DD.MM.YYYY."
                                            locale="hr-HR"
                                            maxDate={moment()}
                                            selected={this.state.datum}
                                            onChange={this.onChangeDatum}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                        <Napomene
                            sluzbenaNapomenaHeader={localization.izdatnice.sluzbenaNapomenaIzdatnice}
                            nesluzbenaNapomenaHeader={localization.izdatnice.nesluzbenaNapomenaIzdatnice}
                            onChangeNapomena={this.onChangeNapomena}
                        />
                        <div style={styles.confirmButton}>
                            <Modal
                                open={this.state.potvrdaIzdatnice}
                                closeOnDimmerClick={false}
                                trigger={
                                    <Button
                                        color="green"
                                        content={localization.izdatnice.napraviIzdatnicu}
                                        disabled={!this.state.napraviIzdatnicuEnabled}
                                        onClick={this.showHidePotvrda.bind(this, true)}
                                    />
                                }
                            >
                                <Header icon={icons.izdatnice} content={localization.izdatnice.potvrdaIzdatnice} />
                                <Modal.Content>
                                    <p>{localization.izdatnice.pitanjePotvrdeIzdatnice}</p>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button icon="remove" content={localization.common.odustani} onClick={this.showHidePotvrda.bind(this, false)} />
                                    <Button
                                        icon="checkmark"
                                        color="green"
                                        content={localization.izdatnice.napraviIzdatnicu}
                                        onClick={this.showLoader.bind(this, this.napraviIzdatnicu)}
                                    />
                                </Modal.Actions>
                            </Modal>
                        </div>
                    </div>
                )}
                <Dimmer active={this.state.loader} page>
                    <Loader />
                </Dimmer>
            </div>
        );
    }
}

NovaIzdatnica.propTypes = {
    history: PropTypes.object.isRequired,
    appPrefs: PropTypes.object.isRequired,
    poslovnice: PropTypes.array.isRequired,
    izdatniceDataAccess: PropTypes.object.isRequired,
};

let mapStateToProps = function (state, ownProps) {
    return {
        appPrefs: state.appPreferencesReducer.appPrefs,
        poslovnice: state.mjestaReducer.poslovnice || [],
    };
};

let mapDispatchToProps = function (dispatch) {
    return {
        izdatniceDataAccess: bindActionCreators(IzdatniceDataAccess, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NovaIzdatnica);
