import React from 'react';
import PropTypes from 'prop-types';
import { Button, Comment, Form } from 'semantic-ui-react';
import localization from '../../locales/localization';
import moment from 'moment';

const styles = {
    commentsTextArea: {
        height: 80
    },
    textAreaWithMaxWidth: {
        height: 80,
        maxWidth: 650
    },
    actionButtons: {
        marginTop: 16,
        display: 'flex',
        justifyContent: 'flex-end'
    }
};

export default class Diskusija extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Comment.Group>
                    {this.props.napomene.map((nap, index) => {
                        return (
                            <Comment key={index}>
                                <Comment.Content>
                                    <Comment.Author>{nap.user.ime + ' ' + nap.user.prezime}</Comment.Author>
                                    <Comment.Metadata>
                                        <div>{moment(nap.datumIVrijeme).format('DD.MM.YYYY. HH:mm')}</div>
                                    </Comment.Metadata>
                                    <Comment.Text>{nap.tekst}</Comment.Text>
                                </Comment.Content>
                            </Comment>
                        );
                    })}
                </Comment.Group>
                <Form reply>
                    <Form.TextArea
                        style={this.props.closeModal ? styles.commentsTextArea : styles.textAreaWithMaxWidth}
                        value={this.props.textAreaValue}
                        onChange={this.props.onChangeNovaNapomena}
                    />
                    {!this.props.closeModal ? (
                        <Button content={localization.common.dodajNapomenu} labelPosition="left" icon="edit" primary onClick={this.props.onDodajNapomenu} />
                    ) : (
                        <div style={styles.actionButtons}>
                            <Button icon="close" content={localization.common.odustani} onClick={this.props.closeModal} />
                            <Button icon="calendar plus" color="green" content={localization.common.dodajNapomenu} onClick={this.props.onDodajNapomenu} />
                        </div>
                    )}
                </Form>
            </React.Fragment>
        );
    }
}

Diskusija.propTypes = {
    napomene: PropTypes.array.isRequired,
    textAreaValue: PropTypes.string.isRequired,
    onChangeNovaNapomena: PropTypes.func.isRequired,
    onDodajNapomenu: PropTypes.func.isRequired,
    closeModal: PropTypes.func
};
