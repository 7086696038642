import $ from 'jquery';

import endpoint from '../environments/endpoints';
import { attachStanjeAndMergeRezervationList } from '../reducers/rezervacijeReducer';

// TODO #pagination #refactor Does this have to somehow be reconciliated with redux?
//      How does redux work then the result of the API fetch does not have to be dispatched
//      throughout the whole application but only on a certain sub-page?
export function getZahtjeviZaRezervacijuPaginated(q, poslovnicaId, page, pageSize, onSuccess, onError) {
    $.ajax({
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + sessionStorage.token
        },
        url: endpoint.apiEndpoint + 'rezervacije/paginated' +
            '?q=' + q +
            '&poslovnicaId=' + (poslovnicaId ? poslovnicaId : '') +
            '&page=' + page +
            '&pageSize=' + pageSize
    })
        .done((data) => {
            // TODO @Kec @Vranjes There is a discrepancy between how the API returns zahtjevi za rezervaciju and how the frontend needs them.
            //      That is what the mapping below is for. However, there is a question whether the mapping should be done here in the API access layer
            //      or should the individual components make the mapping as they see fit. Since the mapping was previously done in the reducer,
            //      the decision for now is to do the same in this API access layer.
            onSuccess(
                Object.assign({}, data, {
                    items: data.items.map(attachStanjeAndMergeRezervationList),
                })
            );
        })
        .fail(onError);
}
