import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Card, Form, Header, Segment } from 'semantic-ui-react';

import RezervacijskiRazred from '../common_components/RezervacijskiRazred';
import InputField from '../common_components/InputField';
import AddNewButton from '../common_components/AddNewButton';
import localization from '../../locales/localization';
import { generateKey } from '../../utils/KeyGenerator';
import enums from '../../utils/Enums';
import * as AppPreferencesDataAccess from '../../data_access/AppPreferencesDataAccess';

const styles = {
    actionButtons: {
        display: 'flex',
        justifyContent: 'center'
    }
};

class UpravljanjePostotcima extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            appPrefs: this.props.appPrefs,
            novaCijenaRezervacije: false,
            enableSaveButton: false
        };
        this.onChangeRezervacijskiRazred = this.onChangeRezervacijskiRazred.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onAddNew = this.onAddNew.bind(this);
        this.enableSaveButton = this.enableSaveButton.bind(this);
        this.checkAppPrefsRequiredFields = this.checkAppPrefsRequiredFields.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            appPrefs: nextProps.appPrefs,
            novaCijenaRezervacije: false,
            enableSaveButton: false
        });
    }

    onChangeRezervacijskiRazred(index, value) {
        this.setState(
            {
                appPrefs: Object.assign({}, this.state.appPrefs, {
                    cijeneRezervacijaCalcPrefs: [
                        ...this.state.appPrefs.cijeneRezervacijaCalcPrefs.slice(0, index),
                        ...[value],
                        ...this.state.appPrefs.cijeneRezervacijaCalcPrefs.slice(index + 1)
                    ]
                })
            },
            this.enableSaveButton
        );
    }

    onDeleteRezervacijskiRazred(index) {
        this.setState(
            {
                appPrefs: Object.assign({}, this.state.appPrefs, {
                    cijeneRezervacijaCalcPrefs: this.state.appPrefs.cijeneRezervacijaCalcPrefs
                        .slice(0, index)
                        .concat(this.state.appPrefs.cijeneRezervacijaCalcPrefs.slice(index + 1))
                }),
                novaCijenaRezervacije: this.state.novaCijenaRezervacije && !(index === this.state.appPrefs.cijeneRezervacijaCalcPrefs.length - 1)
            },
            this.enableSaveButton
        );
    }

    onChange(e, { name, value }) {
        this.setState(
            {
                appPrefs: Object.assign({}, this.state.appPrefs, { [name]: value })
            },
            this.enableSaveButton
        );
    }

    onAddNew(e) {
        this.setState(
            {
                appPrefs: Object.assign({}, this.state.appPrefs, {
                    cijeneRezervacijaCalcPrefs: [
                        ...this.state.appPrefs.cijeneRezervacijaCalcPrefs,
                        {
                            tip: enums.tipCijeneRezervacije.POSTOTAK,
                            donjaGrCijeneArtikla: undefined,
                            vrijednost: undefined
                        }
                    ]
                }),
                novaCijenaRezervacije: true
            },
            this.enableSaveButton
        );
    }

    enableSaveButton() {
        this.setState({
            enableSaveButton: this.checkAppPrefsRequiredFields()
        });
    }

    checkAppPrefsRequiredFields() {
        let checkCijeneRezervacije = cijeneRezervacija => {
            for (let i = 0; i < cijeneRezervacija.length; i++) {
                if (!cijeneRezervacija[i].tip || cijeneRezervacija[i].donjaGrCijeneArtikla === undefined || cijeneRezervacija[i].vrijednost === undefined) {
                    return true;
                }
            }
            return false;
        };
        if (
            !this.state.appPrefs.cijeneRezervacijaCalcPrefs.length ||
            checkCijeneRezervacije(this.state.appPrefs.cijeneRezervacijaCalcPrefs) ||
            !this.state.appPrefs.postotakPovecanjaOtkupneCijene ||
            !this.state.appPrefs.postotakZaKarticnoPlacanje
        ) {
            return false;
        }
        return true;
    }

    onCancel() {
        this.setState({
            appPrefs: this.props.appPrefs,
            novaCijenaRezervacije: false,
            enableSaveButton: false
        });
    }

    onSave() {
        let prefs = {
            cijeneRezervacijaCalcPrefs: this.state.appPrefs.cijeneRezervacijaCalcPrefs.map(cr => {
                return {
                    tip: cr.tip,
                    donjaGrCijeneArtikla: parseFloat(cr.donjaGrCijeneArtikla),
                    vrijednost: parseFloat(cr.vrijednost)
                };
            }),
            postotakPovecanjaOtkupneCijene: parseFloat(this.state.appPrefs.postotakPovecanjaOtkupneCijene),
            postotakZaKarticnoPlacanje: parseFloat(this.state.appPrefs.postotakZaKarticnoPlacanje)
        };
        this.props.appPreferencesDataAccess.updateAppPrefs(prefs);
    }

    render() {
        return (
            <div>
                <Segment tertiary>
                    <Header as="h2">{localization.postavke.upravljanjePostotcima}</Header>
                </Segment>
                <Segment tertiary>
                    <Header as="h3">{localization.postavke.postotciRezervacija}</Header>
                    <Card.Group itemsPerRow={1} stackable>
                        {this.state.appPrefs.cijeneRezervacijaCalcPrefs.map((rr, index) => {
                            return (
                                <Card key={index}>
                                    <Card.Content textAlign="center">
                                        <RezervacijskiRazred
                                            rezervacijskiRazred={rr}
                                            onChange={this.onChangeRezervacijskiRazred.bind(this, index)}
                                            onDelete={this.onDeleteRezervacijskiRazred.bind(this, index)}
                                        />
                                    </Card.Content>
                                </Card>
                            );
                        })}
                        {!this.state.novaCijenaRezervacije ? (
                            <AddNewButton
                                key={generateKey()}
                                text={localization.postavke.dodajNovuCijenuRezervacije}
                                name="novaCijenaRezervacije"
                                onAddNew={this.onAddNew}
                            />
                        ) : null}
                    </Card.Group>
                </Segment>
                <Segment tertiary>
                    <Header as="h3">{localization.postavke.ostaliPostotci}</Header>
                    <Segment>
                        <Form>
                            <Form.Field required>
                                <label>{localization.postavke.postotakSmanjivanjaOdProdajneCijene}</label>
                                <InputField
                                    label={localization.common.percentageSign}
                                    placeholder={localization.common.percentageSign}
                                    name="postotakPovecanjaOtkupneCijene"
                                    value={this.state.appPrefs.postotakPovecanjaOtkupneCijene}
                                    onChange={this.onChange}
                                    isCjelobrojno={true}
                                />
                            </Form.Field>
                            <Form.Field required>
                                <label>{localization.postavke.postotakZaKarticnoPlacanje}</label>
                                <InputField
                                    label={localization.common.percentageSign}
                                    placeholder={localization.common.percentageSign}
                                    name="postotakZaKarticnoPlacanje"
                                    value={this.state.appPrefs.postotakZaKarticnoPlacanje}
                                    onChange={this.onChange}
                                    isCjelobrojno={true}
                                />
                            </Form.Field>
                        </Form>
                    </Segment>
                </Segment>
                <div style={styles.actionButtons}>
                    <Button icon="cancel" content={localization.common.vratiNaSpremljeno} onClick={this.onCancel} />
                    <Button icon="save" color="green" disabled={!this.state.enableSaveButton} content={localization.common.spremi} onClick={this.onSave} />
                </div>
            </div>
        );
    }
}

UpravljanjePostotcima.propTypes = {
    history: PropTypes.object.isRequired,
    appPrefs: PropTypes.object.isRequired,
    appPreferencesDataAccess: PropTypes.object.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {
        appPrefs: state.appPreferencesReducer.appPrefs
    };
};

let mapDispatchToProps = function(dispatch) {
    return {
        appPreferencesDataAccess: bindActionCreators(AppPreferencesDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpravljanjePostotcima);
