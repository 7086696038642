import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import localization from '../../locales/localization';

export default class FormatIznosa extends React.Component {
    render() {
        if (this.props.value != null) {
            return (
                <NumberFormat
                    value={this.props.value.toFixed(2)}
                    renderText={value => <span style={{whiteSpace: "pre"}}>{value}</span>}
                    displayType="text"
                    thousandSeparator={','}
                    decimalSeparator={'.'}
                    suffix={this.props.suffix ? ' ' + this.props.suffix.padLeft(3) : undefined}
                />
            );
        } else {
            return '-';
        }
    }
}

FormatIznosa.propTypes = {
    value: PropTypes.number.isRequired,
    suffix: PropTypes.string
};

FormatIznosa.defaultProps = {
    suffix: localization.common.eur
};
