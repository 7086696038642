import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Button, Grid, Header, Modal, Search, Segment } from 'semantic-ui-react';
import localization from '../../locales/localization';
import { bindActionCreators } from 'redux';
import Klijent from './Klijent';
import * as KlijentiDataAccess from '../../data_access/KlijentiDataAccess';
import * as KlijentiApi from '../../data_access/KlijentiApi';
import { renderClientResults } from '../../utils/SearchResultsRenderers';
import TablicaKlijenata from './table_fragments/TablicaKlijenata';
import SearchResultsMessage from './SearchResultsMessage';
import SearchPaginated from './SearchPaginated';

class OdabirKlijenta extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isClientSearchLoading: false,
            clientsResults: [],
            clientValue: '',
            clientSelected: undefined,
            newClient: false,
            editClient: false,
            showNapomenaOpreza: false
        };
        this.resetClientSearchComponent = this.resetClientSearchComponent.bind(this);
        this.checkIsKontaktRequired = this.checkIsKontaktRequired.bind(this);
        this.handleClientSearchResultSelect = this.handleClientSearchResultSelect.bind(this);
        this.showHideCreateClient = this.showHideCreateClient.bind(this);
        this.onNewClientSave = this.onNewClientSave.bind(this);
        this.showHideEditClient = this.showHideEditClient.bind(this);
        this.onEditClientSave = this.onEditClientSave.bind(this);
        this.onRemoveClient = this.onRemoveClient.bind(this);
        this.hideNapomenaOpreza = this.hideNapomenaOpreza.bind(this);
        this.fetchKlijenti = this.fetchKlijenti.bind(this);
    }

    componentWillMount() {
        this.resetClientSearchComponent();
    }

    resetClientSearchComponent() {
        this.setState({
            isClientSearchLoading: false,
            clientsResults: [],
            clientValue: ''
        });
    }

    checkIsKontaktRequired(klijent) {
        return this.props.kontaktiRequired && !klijent.kontaktBroj && !klijent.kontaktEmail;
    }

    handleClientSearchResultSelect(e, { result }) {
        this.setState(
            {
                clientSelected: result,
                clientValue: '',
                editClient: this.checkIsKontaktRequired(result),
                showNapomenaOpreza: result.napomenaOpreza !== null
            },
            this.props.onClientSelected.bind(this, result)
        );
    }

    showHideCreateClient(value) {
        this.setState({ newClient: value });
    }

    onNewClientSave(klijent) {
        this.props.klijentiDataAccess.createKlijent(klijent, client => {
            this.setState(
                {
                    clientSelected: client,
                    newClient: false
                },
                this.props.onClientSelected.bind(this, client)
            );
        });
    }

    showHideEditClient(value) {
        this.setState({ editClient: value });
    }

    onEditClientSave(klijent) {
        this.props.klijentiDataAccess.updateKlijent(this.state.clientSelected.id, klijent, client => {
            this.setState(
                {
                    clientSelected: client,
                    editClient: false
                },
                this.props.onClientSelected.bind(this, client)
            );
        });
    }

    onRemoveClient() {
        this.setState({ clientSelected: undefined }, this.props.onClientSelected.bind(this, undefined));
    }

    hideNapomenaOpreza() {
        this.setState({ showNapomenaOpreza: false });
    }

    fetchKlijenti(q, page, pageSize, onFetchSuccess, onFetchError) {
        let filter = this.props.allowedClientType ? KlijentiApi.filterForTipKlijenta(this.props.allowedClientType) : KlijentiApi.FILTER.all;
        KlijentiApi.getKlijentiPaginated(q, page, pageSize, KlijentiApi.SORT.naziv, filter, onFetchSuccess, onFetchError);
    }

    render() {
        return (
            <Grid stackable>
                <Grid.Row columns={6}>
                    <Grid.Column verticalAlign="middle">
                        <Header as="h3">{localization.common.klijent}</Header>
                    </Grid.Column>
                    {this.state.clientSelected || this.state.newClient ? null : (
                        <Grid.Column verticalAlign="middle">
                            <SearchPaginated
                                input={{ fluid: true }}
                                onResultSelect={this.handleClientSearchResultSelect}
                                // TODO @Kec #pagination Avoid wrapping in a special object with result property.
                                //      This requires changing the renderClientResults function which is used in other places
                                resultRenderer={(klijent) => renderClientResults({result: klijent})}
                                errorStateText={localization.common.dogodilaSeGreska}
                                fetch={this.fetchKlijenti}
                            />
                        </Grid.Column>
                    )}
                    {this.state.clientSelected || this.state.newClient ? null : (
                        <Grid.Column verticalAlign="middle">
                            <Button fluid compact basic color="black" onClick={this.showHideCreateClient.bind(this, true)}>
                                {localization.common.unosNovogKlijenta}
                            </Button>
                        </Grid.Column>
                    )}
                </Grid.Row>
                {this.state.clientSelected ? (
                    <Grid.Row>
                        <Grid.Column verticalAlign="middle">
                            <Segment>
                                <TablicaKlijenata
                                    klijent={this.state.clientSelected}
                                    kontaktiRequired={this.props.kontaktiRequired}
                                    showActions={true}
                                    editingModalOpen={this.state.editClient}
                                    showHideEditClient={!this.checkIsKontaktRequired(this.state.clientSelected) ? this.showHideEditClient : this.onRemoveClient}
                                    onEditClientSave={this.onEditClientSave}
                                    onRemoveClient={this.onRemoveClient}
                                />
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                ) : null}
                {this.state.newClient ? (
                    <Grid.Row>
                        <Grid.Column verticalAlign="middle">
                            <Segment>
                                <Klijent
                                    client={{}}
                                    clientType={this.props.allowedClientType}
                                    kontaktiRequired={this.props.kontaktiRequired}
                                    onSave={this.onNewClientSave}
                                    onCancel={this.showHideCreateClient.bind(this, false)}
                                />
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                ) : null}
                {this.state.clientSelected === undefined && !this.state.newClient ? (
                    <SearchResultsMessage
                        icon="user x"
                        header={localization.common.placeholderUnosKlijentaTitle}
                        content={localization.common.placeholderUnosKlijentaContent}
                    />
                ) : null}
                {this.state.clientSelected && this.state.showNapomenaOpreza ? (
                    <Modal open={this.state.showNapomenaOpreza} closeOnDimmerClick={false}>
                        <Modal.Header>{localization.klijent.napomenaOpreza + '!'}</Modal.Header>
                        <Modal.Content>
                            <p>
                                {localization.klijent.napomenaOprezaModal + ':'}
                                <br />
                                <b>{this.state.clientSelected.napomenaOpreza}</b>
                            </p>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={this.hideNapomenaOpreza} negative content={localization.common.ok} />
                        </Modal.Actions>
                    </Modal>
                ) : null}
            </Grid>
        );
    }
}

OdabirKlijenta.propTypes = {
    klijentiDataAccess: PropTypes.object.isRequired,
    onClientSelected: PropTypes.func.isRequired,
    allowedClientType: PropTypes.string,
    kontaktiRequired: PropTypes.bool
};

let mapStateToProps = function(state, ownProps) {
    return {};
};

let mapDispatchToProps = function(dispatch) {
    return {
        klijentiDataAccess: bindActionCreators(KlijentiDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OdabirKlijenta);
