import $ from 'jquery';

import * as success from '../actions/FlagsActions';

import endpoint from '../environments/endpoints';
import { showAlert } from '../utils/ErrorHandler';

export function getFlags(onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'flag-definitions'
        })
            .done(data => {
                dispatch(success.getFlags(data));
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function createFlag(flag, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'flag-definitions',
            data: JSON.stringify(flag)
        })
            .done(data => {
                dispatch(getFlags());
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function updateFlag(id, flag, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'flag-definitions/' + id,
            data: JSON.stringify(flag)
        })
            .done(data => {
                dispatch(getFlags());
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function deleteFlag(id, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'flag-definitions/' + id
        })
            .done(data => {
                dispatch(getFlags());
            })
            .fail(error => {
                showAlert(error);
            });
    };
}
