import $ from 'jquery';

import endpoint from '../environments/endpoints';
import enums from '../utils/Enums';

export const FILTER = {
    all: 'all',
    fizicka: 'fizicka',
    pravna: 'pravna',
    sNapomenomOpreza: 'sNapomenomOpreza'
};

export const SORT = {
    naziv: 'naziv',
    drzava: 'drzava'
};

export function filterForTipKlijenta(tipKlijenta) {
    switch (tipKlijenta) {
        case enums.tipKlijenta.FIZICKA_OSOBA:
            return FILTER.fizicka;
        case enums.tipKlijenta.PRAVNA_OSOBA:
            return FILTER.pravna;
        default:
            throw new Error("Unhandled tip klijenta " + tipKlijenta + " when converting to API filter.");
    }
}

// TODO #pagination #refactor Does this have to somehow be reconciliated with redux?
//      How does redux work then the result of the API fetch does not have to be dispatched
//      throughout the whole application but only on a certain sub-page? 
export function getKlijentiPaginated(q, page, pageSize, sort, filter, onSuccess, onError) {
    $.ajax({
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + sessionStorage.token
        },
        url: endpoint.apiEndpoint + 'klijenti/paginated' +
            '?q=' + q +
            '&page=' + page +
            '&pageSize=' + pageSize +
            '&sort=' + sort +
            '&filter=' + filter
    })
        .done(onSuccess)
        .fail(onError);
}
