import React from 'react';
import PropTypes from 'prop-types';
import localization from '../../../locales/localization';

const styles = {
    fiskalizacijskiPodaci: {
        fontSize: 10,
        margin: 0
    }
};

class FiskalizacijaPodaciRacun extends React.Component {
    render() {
        return (
            <div>
                <p style={styles.fiskalizacijskiPodaci}>{localization.printingComponents.common.izradio + ':'}</p>
                <p style={styles.fiskalizacijskiPodaci}>
                    {localization.printingComponents.common.oznakaOperatera + ': '}
                    <b>{this.props.oznakaOperatera}</b>
                </p>
                <p style={styles.fiskalizacijskiPodaci}>
                    {localization.printingComponents.common.zki + ': '}
                    <b>{this.props.zki || ''}</b>
                </p>
                <p style={styles.fiskalizacijskiPodaci}>
                    {localization.printingComponents.common.jir + ': '}
                    <b>{this.props.jir || ''}</b>
                </p>
            </div>
        );
    }
}

FiskalizacijaPodaciRacun.propTypes = {
    oznakaOperatera: PropTypes.string.isRequired,
    zki: PropTypes.string,
    jir: PropTypes.string
};

export default FiskalizacijaPodaciRacun;
