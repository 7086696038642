import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, Header, Segment } from 'semantic-ui-react';

import ReccuringTrosak from '../common_components/ReccuringTrosak';
import AddNewButton from '../common_components/AddNewButton';
import { generateKey } from '../../utils/KeyGenerator';
import localization from '../../locales/localization';

const styles = {};

const timeSpanType = {
    DAY: 'DAY',
    MONTH: 'MONTH',
    YEAR: 'YEAR'
};

class ReccuringTroskovi extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reccuringTroskovi: this.props.reccuringTroskoviDefinitions,
            noviReccuringTrosak: false
        };
        this.onDeleteReccuringTrosak = this.onDeleteReccuringTrosak.bind(this);
        this.onAddNew = this.onAddNew.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            reccuringTroskovi: nextProps.reccuringTroskoviDefinitions,
            noviReccuringTrosak: false
        });
    }

    onDeleteReccuringTrosak(index) {
        this.setState({
            reccuringTroskovi: this.state.reccuringTroskovi.slice(0, index).concat(this.state.reccuringTroskovi.slice(index + 1)),
            noviReccuringTrosak: this.state.noviReccuringTrosak && !(index === this.state.reccuringTroskovi.length - 1)
        });
    }

    onAddNew(e) {
        this.setState({
            reccuringTroskovi: [
                ...this.state.reccuringTroskovi,
                {
                    timeSpanType: timeSpanType.MONTH,
                    ime: '',
                    poslovnicaId: this.props.poslovnice[0].id,
                    defaultIznos: 0,
                    novi: true
                }
            ],
            noviReccuringTrosak: true
        });
    }

    render() {
        return (
            <Segment tertiary>
                <Header as="h2">{localization.postavke.ponavljajuciTroskovi}</Header>
                <Card.Group itemsPerRow={3} stackable>
                    {this.state.reccuringTroskovi.map((rt, index) => {
                        return (
                            <Card key={index}>
                                <Card.Content textAlign="center">
                                    <ReccuringTrosak novi={rt.novi} reccuringTrosak={rt} onDelete={this.onDeleteReccuringTrosak.bind(this, index)} />
                                </Card.Content>
                            </Card>
                        );
                    })}
                    {!this.state.noviReccuringTrosak ? (
                        <AddNewButton
                            key={generateKey()}
                            text={localization.postavke.dodajNoviPonavljajuciTrosak}
                            name="noviReccuringTrosak"
                            onAddNew={this.onAddNew}
                        />
                    ) : null}
                </Card.Group>
            </Segment>
        );
    }
}

ReccuringTroskovi.propTypes = {
    history: PropTypes.object.isRequired,
    reccuringTroskoviDefinitions: PropTypes.array.isRequired,
    poslovnice: PropTypes.array.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {
        reccuringTroskoviDefinitions: state.troskoviReducer.reccuringTroskoviDefinitions || [],
        poslovnice: state.mjestaReducer.poslovnice || []
    };
};

let mapDispatchToProps = function(dispatch) {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ReccuringTroskovi);
