import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Header, Modal, Segment } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

const styles = {
    inlineContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    dayName: {
        width: 50
    },
    span: {
        padding: 8
    }
};

let convertMinutesToTimeFormat = minutes => {
    return minutes !== undefined
        ? moment()
              .hours(Math.floor(minutes / 60))
              .minutes(minutes % 60)
        : undefined;
};

let convertTimeFormatToMinutes = time => {
    return time.hours() * 60 + time.minutes();
};

export default class RadnoVrijeme extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            radniDan: this.props.startTime !== undefined && this.props.endTime !== undefined,
            pocetak: convertMinutesToTimeFormat(this.props.startTime),
            kraj: convertMinutesToTimeFormat(this.props.endTime)
        };
        this.onChange = this.onChange.bind(this);
        this.showRadnoVrijeme = this.showRadnoVrijeme.bind(this);
        this.deleteRadnoVrijeme = this.deleteRadnoVrijeme.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            radniDan: nextProps.startTime !== undefined && nextProps.endTime !== undefined,
            pocetak: convertMinutesToTimeFormat(nextProps.startTime),
            kraj: convertMinutesToTimeFormat(nextProps.endTime)
        });
    }

    onChange(name, time) {
        if (name === 'pocetak') {
            this.props.onChange({
                radniDan: this.state.radniDan,
                pocetak: convertTimeFormatToMinutes(time),
                kraj: convertTimeFormatToMinutes(this.state.kraj)
            });
        } else {
            this.props.onChange({
                radniDan: this.state.radniDan,
                pocetak: convertTimeFormatToMinutes(this.state.pocetak),
                kraj: convertTimeFormatToMinutes(time)
            });
        }
    }

    showRadnoVrijeme() {
        this.props.onChange({
            radniDan: true,
            pocetak: 480,
            kraj: 1200
        });
    }

    deleteRadnoVrijeme() {
        this.props.onChange({
            radniDan: false,
            pocetak: convertTimeFormatToMinutes(this.state.pocetak),
            kraj: convertTimeFormatToMinutes(this.state.kraj)
        });
    }

    render() {
        return (
            <div style={styles.inlineContainer}>
                <span style={styles.dayName}>{this.props.dayName}</span>
                {this.state.radniDan ? (
                    <React.Fragment>
                        <DatePicker
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={30}
                            timeFormat="HH:mm"
                            dateFormat="HH:mm"
                            selected={this.state.pocetak}
                            onChange={this.onChange.bind(this, 'pocetak')}
                        />
                        <span style={styles.span}>{'-'}</span>
                        <DatePicker
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={30}
                            timeFormat="HH:mm"
                            dateFormat="HH:mm"
                            selected={this.state.kraj}
                            onChange={this.onChange.bind(this, 'kraj')}
                        />
                        <Button icon="delete" basic size="small" onClick={this.deleteRadnoVrijeme} />
                    </React.Fragment>
                ) : (
                    <Button icon="plus" basic size="small" onClick={this.showRadnoVrijeme} />
                )}
            </div>
        );
    }
}

RadnoVrijeme.propTypes = {
    dayName: PropTypes.string.isRequired,
    startTime: PropTypes.number,
    endTime: PropTypes.number,
    onChange: PropTypes.func.isRequired
};
