import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import endpoints from '../../environments/endpoints';
import { Form, Grid, Message } from 'semantic-ui-react';
import localization from '../../locales/localization';

const styles = {
    grid: {
        height: '100%'
    },
    column: {
        maxWidth: 450,
        margin: 'auto',
        marginTop: 48
    },
    title: {
        textAlign: 'center'
    }
};

class OdabirPoslovnice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            poslovnica: undefined
        };
        this.handleChange = this.handleChange.bind(this);
        this.onProceed = this.onProceed.bind(this);
    }

    handleChange(e, { name, value }) {
        this.setState({ [name]: value });
    }

    onProceed() {
        sessionStorage.setItem('isAuthenticated', true);
        sessionStorage.setItem('poslovnicaId', this.state.poslovnica);
        sessionStorage.setItem('poslovnica', JSON.stringify(this.props.poslovnice.find(p => p.id === this.state.poslovnica)));
        this.props.history.push(endpoints.appEndpoints.root);
    }

    render() {
        return (
            <Grid verticalAlign="middle" style={styles.grid}>
                <Grid.Column style={styles.column}>
                    <Message attached>
                        <Message.Header style={styles.title}>{localization.zalagaonica} {localization.verzija}</Message.Header>
                    </Message>
                    <Form className="attached fluid segment">
                        <Form.Select
                            fluid
                            label={localization.login.poslovnica}
                            options={this.props.poslovnice.map(poslovnica => {
                                return { key: poslovnica.id, text: poslovnica.naziv, value: poslovnica.id };
                            })}
                            placeholder={localization.login.poslovnica}
                            name="poslovnica"
                            onChange={this.handleChange}
                            search
                        />
                        <Form.Button fluid color="blue" content={localization.login.prijava} onClick={this.onProceed} />
                    </Form>
                </Grid.Column>
            </Grid>
        );
    }
}

OdabirPoslovnice.propTypes = {
    history: PropTypes.object.isRequired,
    poslovnice: PropTypes.array.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {
        poslovnice: state.mjestaReducer.poslovnice || []
    };
};

let mapDispatchToProps = function(dispatch) {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(OdabirPoslovnice);
