import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Form, Header, Modal, Segment } from 'semantic-ui-react';

import localization from '../../locales/localization';
import * as MjerneJediniceDataAccess from '../../data_access/MjerneJediniceDataAccess';

const styles = {
    deleteButton: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    container: {
        textAlign: 'left'
    },
    actionButtons: {
        marginTop: 16,
        display: 'flex',
        justifyContent: 'flex-end'
    }
};

class MjernaJedinica extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            naziv: this.props.mjernaJedinica.naziv,
            isCjelobrojna: this.props.mjernaJedinica.isCjelobrojna,
            activeModal: false,
            enableSaveButton: false
        };
        this.onChange = this.onChange.bind(this);
        this.enableSaveButton = this.enableSaveButton.bind(this);
        this.checkMjerneJediniceRequiredFields = this.checkMjerneJediniceRequiredFields.bind(this);
        this.showHideModal = this.showHideModal.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            naziv: nextProps.mjernaJedinica.naziv,
            isCjelobrojna: nextProps.mjernaJedinica.isCjelobrojna,
            activeModal: false,
            enableSaveButton: false
        });
    }

    onChange(e, { name, value, checked }) {
        if (value === undefined && checked !== undefined) {
            value = checked;
        }
        this.setState({ [name]: value }, this.enableSaveButton);
    }

    enableSaveButton() {
        this.setState({ enableSaveButton: this.checkMjerneJediniceRequiredFields() });
    }

    checkMjerneJediniceRequiredFields() {
        if (!this.state.naziv || this.state.isCjelobrojna === undefined) {
            return false;
        }
        return true;
    }

    showHideModal(value) {
        this.setState({ activeModal: value });
    }

    onCancel() {
        if (this.props.novo) {
            this.props.onDelete();
        } else {
            this.setState({
                naziv: this.props.mjernaJedinica.naziv,
                isCjelobrojna: this.props.mjernaJedinica.isCjelobrojna,
                activeModal: false,
                enableSaveButton: false
            });
        }
    }

    onSave() {
        let mjernaJedinica = {
            naziv: this.state.naziv,
            isCjelobrojna: this.state.isCjelobrojna
        };
        if (this.props.novo) {
            this.props.mjerneJediniceDataAccess.createMjerneJedinica(mjernaJedinica);
        } else {
            this.props.mjerneJediniceDataAccess.updateMjernaJedinica(this.props.mjernaJedinica.id, mjernaJedinica);
        }
    }

    onDelete() {
        this.props.mjerneJediniceDataAccess.deleteMjerneJedinica(this.props.mjernaJedinica.id);
    }

    render() {
        return (
            <Segment>
                {!this.props.novo ? (
                    <div style={styles.deleteButton}>
                        <Modal
                            open={this.state.activeModal}
                            trigger={<Button icon="delete" color="red" basic size="small" onClick={this.showHideModal.bind(this, true)} />}
                        >
                            <Header icon="trash" content={localization.common.potvrdaBrisanja} />
                            <Modal.Content>
                                <p>{localization.postavke.brisanjeMjerneJedinicePotvrda}</p>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button icon="remove" content={localization.common.odustani} onClick={this.showHideModal.bind(this, false)} />
                                <Button icon="trash" color="red" content={localization.common.obrisi} onClick={this.onDelete} />
                            </Modal.Actions>
                        </Modal>
                    </div>
                ) : null}
                <Form style={styles.container}>
                    <Form.Input
                        fluid
                        label={localization.postavke.nazivMjerneJedinice}
                        name="naziv"
                        onChange={this.onChange}
                        value={this.state.naziv}
                        required
                    />
                    <Form.Checkbox
                        label={localization.postavke.mjernaJedinicaJeCjelobrojna}
                        checked={this.state.isCjelobrojna}
                        name="isCjelobrojna"
                        onChange={this.onChange}
                    />
                </Form>
                <div style={styles.actionButtons}>
                    <Button
                        icon="cancel"
                        content={this.props.novo ? localization.common.odustani : localization.common.vratiNaSpremljeno}
                        onClick={this.onCancel}
                    />
                    <Button icon="save" color="green" disabled={!this.state.enableSaveButton} content={localization.common.spremi} onClick={this.onSave} />
                </div>
            </Segment>
        );
    }
}

MjernaJedinica.propTypes = {
    novo: PropTypes.bool,
    mjernaJedinica: PropTypes.object.isRequired,
    mjerneJediniceDataAccess: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {};
};

let mapDispatchToProps = function(dispatch) {
    return {
        mjerneJediniceDataAccess: bindActionCreators(MjerneJediniceDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MjernaJedinica);
