import React from 'react';
import PropTypes from 'prop-types';
import { Header, Segment } from 'semantic-ui-react';

import OtkupProdajaContent from './OtkupProdajaContent';
import enums from '../../../utils/Enums';
import localization from '../../../locales/localization';

export default class StornoSUBContent extends React.Component {
    render() {
        let sub, stornoSUB, klijentProp, type;
        if (this.props.tip === enums.tipSkladisnogUlaznogBloka.OTKUPNI_BLOK) {
            sub = localization.dnevnaEvidencija.otkup;
            stornoSUB = localization.dnevnaEvidencija.storniraniOtkup;
            klijentProp = 'fizickaOsoba';
            type = enums.tipTabliceArtikala.OTKUP_FIXED;
        } else {
            sub = localization.dnevnaEvidencija.primka;
            stornoSUB = localization.dnevnaEvidencija.storniranaPrimkaMalo;
            klijentProp = 'pravnaOsoba';
            type = enums.tipTabliceArtikala.PRIMKA_FIXED;
        }
        return (
            <React.Fragment>
                <Segment>
                    <Header as="h3">{sub}</Header>
                    <OtkupProdajaContent
                        klijent={this.props.SUB[klijentProp]}
                        type={type}
                        artikli={this.props.SUB.skladisniUlazi.map(su => {
                            return Object.assign({}, su, su.predmet, {
                                cijena: su.jedNabavnaCijena,
                                kolicina: this.props.SUB.rbrStorniranogSUBa === null ? su.kolicina : -su.kolicina
                            });
                        })}
                    />
                </Segment>
                <Segment>
                    <Header as="h3">{stornoSUB}</Header>
                    <OtkupProdajaContent
                        klijent={this.props.stornoSUB[klijentProp]}
                        type={type}
                        artikli={this.props.stornoSUB.skladisniUlazi.map(su => {
                            return Object.assign({}, su, su.predmet, {
                                cijena: su.jedNabavnaCijena
                            });
                        })}
                    />
                </Segment>
            </React.Fragment>
        );
    }
}

StornoSUBContent.propTypes = {
    tip: PropTypes.string.isRequired,
    SUB: PropTypes.object.isRequired,
    stornoSUB: PropTypes.object.isRequired
};
