import React from 'react';
import PropTypes from 'prop-types';
import PrintProvider, { Print, NoPrint } from 'react-easy-print';
import ZaglavljeTvrtke from './common_components/ZaglavljeTvrtke';
import ZaglavljeKlijenta from './common_components/ZaglavljeKlijenta';
import { Button, Table } from 'semantic-ui-react';
import localization from '../../locales/localization';
import DatumIMjestoObicno from './common_components/DatumIMjestoObicno';
import moment from 'moment';

const styles = {
    container: {
        padding: 24
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    zaglavlja: {
        width: '50%'
    },
    zaglavljeTvrtke: {
        paddingBottom: 24
    },
    poslovnaJedinica: {
        fontSize: 14
    },
    datumImjesto: {
        paddingBottom: 8
    },
    p: {
        fontSize: 10
    },
    title: {
        padding: 12,
        textAlign: 'center'
    },
    table: {
        fontSize: 'xx-small'
    },
    tableCell: {
        paddingTop: 'unset',
        paddingBottom: 'unset'
    },
    tableCellKol: {
        paddingTop: 'unset',
        paddingBottom: 'unset',
        fontFamily: 'monospace'
    },
    footerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: 16,
        paddingBottom: 16
    },
    printButton: {
        textAlign: 'center',
        paddingBottom: 8
    }
};

export default class UgovorOtkupa extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            document: JSON.parse(sessionStorage.getItem(this.props.match.params.id))
        };
        this.printDocument = this.printDocument.bind(this);
    }

    printDocument() {
        window.print();
    }

    render() {
        return (
            <PrintProvider>
                <Print>
                    <div style={styles.container}>
                        <div style={styles.headerContainer}>
                            <div style={styles.zaglavlja}>
                                {localization.printingComponents.common.kupac + ':'}
                                <div style={styles.zaglavljeTvrtke}>
                                    <ZaglavljeTvrtke firma={this.state.document.firma} small={true} />
                                </div>
                                <p style={styles.poslovnaJedinica}>
                                    {localization.printingComponents.common.poslovnaJedinica + ': '}
                                    <b>{this.state.document.poslovnica.naziv}</b>
                                </p>
                                <div style={styles.datumImjesto}>
                                    <DatumIMjestoObicno
                                        datum={moment(this.state.document.datumIVrijeme).format('DD.MM.YYYY.')}
                                        mjesto={this.state.document.poslovnica.naselje}
                                    />
                                </div>
                                <p style={styles.p}>
                                    {localization.printingComponents.common.skladiste + ': '}
                                    <b>{this.state.document.poslovnica.naziv}</b>
                                </p>
                            </div>
                            <div style={styles.zaglavlja}>
                                {localization.printingComponents.common.prodavatelj + ':'}
                                <br />
                                <br />
                                <ZaglavljeKlijenta klijent={this.state.document.fizickaOsoba} />
                            </div>
                        </div>
                        <div style={styles.title}>
                            <h3>
                                {localization.printingComponents.ugovorOtkupa.naslov + ' '}
                                <h5>{localization.printingComponents.common.br + ' ' + this.state.document.rbrOtkupniBlok}</h5>
                            </h3>
                        </div>
                        <Table style={styles.table}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={styles.tableCell} />
                                    <Table.HeaderCell style={styles.tableCell}>{localization.artikl.sifra}</Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell}>{localization.artikl.naziv}</Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell} textAlign="center" collapsing>
                                        {localization.artikl.mjernaJedinica}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell} textAlign="center" collapsing>
                                        {localization.common.kolicina}
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.state.document.skladisniUlazi.map((su, index) => {
                                    if (this.state.document.rbrStorniranogSUBa) {
                                        su.kolicina = -su.kolicina;
                                    }
                                    return (
                                        <Table.Row key={index}>
                                            <Table.Cell style={styles.tableCell}>{index + 1}</Table.Cell>
                                            <Table.Cell style={styles.tableCell}>{su.predmet.sifra}</Table.Cell>
                                            <Table.Cell style={styles.tableCell}>
                                                <b>{su.predmet.naziv}</b>
                                            </Table.Cell>
                                            <Table.Cell style={styles.tableCell} textAlign="center" collapsing>
                                                {su.predmet.mjernaJedinica.naziv}
                                            </Table.Cell>
                                            <Table.Cell style={styles.tableCellKol} textAlign="right" collapsing>
                                                {su.kolicina}
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </Table>
                        {this.state.document.sluzbenaNapomena ? (
                            <React.Fragment>
                                <br />
                                <div>
                                    <p style={styles.p}>
                                        <b>{localization.printingComponents.common.sluzbenaNapomena + ':'}</b>
                                    </p>
                                    <p style={styles.p}>{this.state.document.sluzbenaNapomena}</p>
                                </div>
                            </React.Fragment>
                        ) : null}
                        <div>
                            <p style={styles.p}>
                                <b>{localization.printingComponents.ugovorOtkupa.izjavaProdavatelja + ':'}</b>
                            </p>
                            <p style={styles.p}>{localization.printingComponents.ugovorOtkupa.suglasnost.replace('%firma%', this.state.document.firma.ime)}</p>
                            <p style={styles.p}>{localization.printingComponents.common.izjavaPorezniObveznik}</p>
                            <p style={styles.p}>{localization.printingComponents.ugovorOtkupa.garancija}</p>
                        </div>
                        <br />
                        <br />
                        <p style={styles.p}>{localization.printingComponents.common.dokumentObradenElektronickimPutem}</p>
                        <br />
                        <div style={styles.footerContainer}>
                            <p style={styles.p}>{localization.printingComponents.common.kupac + ':'}</p>
                            <p style={styles.p}>{localization.printingComponents.common.prodavatelj + ':'}</p>
                        </div>
                    </div>
                </Print>
                <NoPrint>
                    <div style={styles.printButton}>
                        <Button color="green" icon="print" size="massive" content={localization.common.ispis} onClick={this.printDocument} />
                    </div>
                </NoPrint>
            </PrintProvider>
        );
    }
}

UgovorOtkupa.propTypes = {
    match: PropTypes.object.isRequired
};
