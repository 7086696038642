import React from 'react';
import PropTypes from 'prop-types';
import localization from '../../../locales/localization';

const styles = {
    valutaInacinPlacanja: {
        fontSize: 10,
        margin: 0
    }
};

class ValutaNacinPlacanjaRacun extends React.Component {
    render() {
        return (
            <div>
                <p style={styles.valutaInacinPlacanja}>
                    {localization.printingComponents.common.valutaPlacanja + ': '}
                    <b>{this.props.valutaPlacanja}</b>
                </p>
                <p style={styles.valutaInacinPlacanja}>
                    {localization.printingComponents.common.nacinPlacanja + ': '}
                    <b>{this.props.nacinPlacanja}</b>
                </p>
                <p style={styles.valutaInacinPlacanja}>
                    {localization.printingComponents.common.datumOtpreme + ': '}
                    <b>{this.props.datumOtpreme}</b>
                </p>
                <p style={styles.valutaInacinPlacanja}>
                    {localization.printingComponents.common.nacinOtpreme + ': '}
                    <b>{this.props.nacinOtpreme}</b>
                </p>
            </div>
        );
    }
}

ValutaNacinPlacanjaRacun.propTypes = {
    valutaPlacanja: PropTypes.string.isRequired,
    nacinPlacanja: PropTypes.string.isRequired,
    datumOtpreme: PropTypes.string.isRequired,
    nacinOtpreme: PropTypes.string.isRequired
};

export default ValutaNacinPlacanjaRacun;
