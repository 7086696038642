import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Dimmer, Grid, Header, Loader, Segment, Select, Table } from 'semantic-ui-react';

import BlagajnickiDnevniciMappings from '../../utils/BlagajnickiDnevniciMappings';
import { storeDocumentAndGenerateKey } from '../../utils/PrintingDocumentsHandler';
import localization from '../../locales/localization';
import * as BlagajneDataAccess from '../../data_access/BlagajneDataAccess';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import FormatIznosa from '../common_components/FormatIznosa';
import endpoints from '../../environments/endpoints';
import enums from '../../utils/Enums';

const styles = {
    dateContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    poslovnicaButton: {
        marginRight: 8
    },
    filterButton: {
        marginLeft: 8
    },
    datumIVrijemeCell: {
        width: 250
    },
    kolicinaIcijena: {
        fontFamily: 'monospace'
    }
};

class BlagajnickiDnevnikBlagajnaOtkupa extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            poslovnicaId: this.props.poslovnice[0].id,
            selectedDate: moment(),
            loader: true
        };
        this.onChange = this.onChange.bind(this);
        this.onChangeSelectedDate = this.onChangeSelectedDate.bind(this);
        this.onFilter = this.onFilter.bind(this);
        this.ispisBlagajnickogDnevnika = this.ispisBlagajnickogDnevnika.bind(this);
        this.showLoader = this.showLoader.bind(this);
        this.hideLoader = this.hideLoader.bind(this);

        this.onFilter();
    }

    componentWillReceiveProps(nextProps) {
        if (Object.keys(nextProps.blagajnickiDnevnikOtkup).length) {
            this.setState({
                selectedDate: nextProps.blagajnickiDnevnikOtkup.stavke.length
                    ? moment(nextProps.blagajnickiDnevnikOtkup.stavke[0].datumIVrijeme)
                    : this.state.selectedDate,
                loader: false
            });
        }
    }

    onChange(e, { name, value }) {
        this.setState({ [name]: value });
    }

    onChangeSelectedDate(date) {
        this.setState({
            selectedDate: date
        });
    }

    onFilter() {
        let date = this.state.selectedDate.format('YYYY-MM-DD');
        this.props.blagajneDataAccess.getBlagajnickiDnevnici(this.state.poslovnicaId, date, date, this.hideLoader);
    }

    ispisBlagajnickogDnevnika() {
        let blagajnickiDnevnik = {
            naslov:
                localization.blagajnickiDnevnici.blagajnickiDnevnikOtkup + ' ' + localization.common.za + ' ' + this.state.selectedDate.format('DD.MM.YYYY.'),
            poslovnica: this.props.poslovnice.find(p => p.id === this.state.poslovnicaId),
            blagajnickiDnevnik: this.props.blagajnickiDnevnikOtkup,
            tipBlagajnickogDnevnika: enums.tipBlagajneRadnje.OTKUPNA
        };
        window.open(endpoints.appEndpoints.print.blagajnickiDnevnik + storeDocumentAndGenerateKey(blagajnickiDnevnik), '_blank');
    }

    showLoader(callback) {
        this.setState(
            {
                loader: true
            },
            callback
        );
    }

    hideLoader() {
        this.setState({
            loader: false
        });
    }

    render() {
        return (
            <div>
                <Segment tertiary>
                    <Grid stackable>
                        <Grid.Row columns={2}>
                            <Grid.Column verticalAlign="middle">
                                <Header as="h2">{localization.blagajnickiDnevnici.blagajnickiDnevnikOtkup}</Header>
                            </Grid.Column>
                            <Grid.Column verticalAlign="middle">
                                <div style={styles.dateContainer}>
                                    <Select
                                        style={styles.poslovnicaButton}
                                        options={this.props.poslovnice.map(p => {
                                            return { key: p.id, text: p.naziv, value: p.id };
                                        })}
                                        name="poslovnicaId"
                                        onChange={this.onChange}
                                        value={this.state.poslovnicaId}
                                        compact
                                        required
                                        search
                                    />
                                    <DatePicker
                                        customInput={
                                            <Button fluid compact basic size="large" color="black">
                                                {this.state.selectedDate.format('DD.MM.YYYY.')}
                                            </Button>
                                        }
                                        dateFormat="DD.MM.YYYY."
                                        locale="hr-HR"
                                        maxDate={moment()}
                                        selected={this.state.selectedDate}
                                        onChange={this.onChangeSelectedDate}
                                    />
                                    <Button
                                        icon="filter"
                                        basic
                                        color="black"
                                        size="small"
                                        style={styles.filterButton}
                                        content={localization.blagajnickiDnevnici.filtriraj}
                                        onClick={this.showLoader.bind(this, this.onFilter)}
                                    />
                                    <Button
                                        icon="print"
                                        basic
                                        color="black"
                                        size="small"
                                        content={localization.common.ispis}
                                        onClick={this.ispisBlagajnickogDnevnika}
                                    />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column verticalAlign="middle">
                                <Table structured stackable>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell />
                                            <Table.HeaderCell style={styles.datumIVrijemeCell}>
                                                {localization.blagajnickiDnevnici.datumIVrijeme}
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>{localization.blagajnickiDnevnici.stavkaBlagajne}</Table.HeaderCell>
                                            <Table.HeaderCell textAlign="center">{localization.blagajnickiDnevnici.uplata}</Table.HeaderCell>
                                            <Table.HeaderCell textAlign="center">{localization.blagajnickiDnevnici.isplata}</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell colSpan={2} />
                                            <Table.Cell>
                                                <b>{localization.blagajnickiDnevnici.pocetnoStanje}</b>
                                            </Table.Cell>
                                            <Table.Cell collapsing textAlign="center" style={styles.kolicinaIcijena} colSpan={2}>
                                                <b>
                                                    <FormatIznosa value={this.props.blagajnickiDnevnikOtkup.pocetnoStanje} />
                                                </b>
                                            </Table.Cell>
                                        </Table.Row>
                                        {Object.keys(this.props.blagajnickiDnevnikOtkup).length
                                            ? this.props.blagajnickiDnevnikOtkup.stavke.map((stavkaBlagajne, index) => {
                                                  let mappedStavka = BlagajnickiDnevniciMappings[stavkaBlagajne.tipStavkeBlagajne](
                                                      stavkaBlagajne,
                                                      enums.tipBlagajneRadnje.OTKUPNA
                                                  );
                                                  return (
                                                      <Table.Row key={index}>
                                                          <Table.Cell collapsing>{index + 1 + '.'}</Table.Cell>
                                                          <Table.Cell style={styles.datumIVrijemeCell}>
                                                              {moment(stavkaBlagajne.datumIVrijeme).format('DD.MM.YYYY. HH:mm')}
                                                          </Table.Cell>
                                                          <Table.Cell>{mappedStavka.imeStavke}</Table.Cell>
                                                          {mappedStavka.iznos > 0 ? (
                                                              <React.Fragment>
                                                                  <Table.Cell style={styles.kolicinaIcijena} collapsing textAlign="right">
                                                                      <FormatIznosa value={mappedStavka.iznos} />
                                                                  </Table.Cell>
                                                                  <Table.Cell style={styles.kolicinaIcijena} />
                                                              </React.Fragment>
                                                          ) : (
                                                              <React.Fragment>
                                                                  <Table.Cell style={styles.kolicinaIcijena} />
                                                                  <Table.Cell style={styles.kolicinaIcijena} collapsing textAlign="right">
                                                                      <FormatIznosa value={Math.abs(mappedStavka.iznos)} />
                                                                  </Table.Cell>
                                                              </React.Fragment>
                                                          )}
                                                      </Table.Row>
                                                  );
                                              })
                                            : null}
                                        <Table.Row>
                                            <Table.Cell colSpan={2} />
                                            <Table.Cell>
                                                <b>{localization.blagajnickiDnevnici.zavrsnoStanje}</b>
                                            </Table.Cell>
                                            <Table.Cell collapsing textAlign="center" style={styles.kolicinaIcijena} colSpan={2}>
                                                <b>
                                                    <FormatIznosa value={this.props.blagajnickiDnevnikOtkup.zavrsnoStanje} />
                                                </b>
                                            </Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Dimmer active={this.state.loader} page>
                    <Loader />
                </Dimmer>
            </div>
        );
    }
}

BlagajnickiDnevnikBlagajnaOtkupa.propTypes = {
    history: PropTypes.object.isRequired,
    blagajnickiDnevnikOtkup: PropTypes.object.isRequired,
    poslovnice: PropTypes.array.isRequired,
    blagajneDataAccess: PropTypes.object.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {
        blagajnickiDnevnikOtkup: state.blagajneReducer.blagajnickiDnevnikOtkup,
        poslovnice: state.mjestaReducer.poslovnice || []
    };
};

let mapDispatchToProps = function(dispatch) {
    return {
        blagajneDataAccess: bindActionCreators(BlagajneDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BlagajnickiDnevnikBlagajnaOtkupa);
