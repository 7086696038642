import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import localization from '../../locales/localization';
import endpoints from '../../environments/endpoints';
import { storeDocumentAndGenerateKey, findItemWithLatestDate } from '../../utils/PrintingDocumentsHandler';
import { Button, Dimmer, Dropdown, Grid, Header, Icon, Loader, Segment, Select, Table } from 'semantic-ui-react';
import moment from 'moment';
import * as RezervacijaDataAccess from '../../data_access/RezervacijaDataAccess';
import { bindActionCreators } from 'redux';
import enums from '../../utils/Enums';
import NesluzbenaNapomena from '../common_components/NesluzbenaNapomena';
import InputField from '../common_components/InputField';
import FormatIznosa from '../common_components/FormatIznosa';

const styles = {
    container: {
        textAlign: 'center'
    },
    kolicinaIcijena: {
        fontFamily: 'monospace'
    },
    actionButtons: {
        marginTop: 16,
        display: 'flex',
        justifyContent: 'flex-end'
    }
};

const racuni = [
    {
        tip: enums.nacinPlacanja.GOTOVINSKI_RACUN,
        naziv: localization.prodaja.gotovinskiRacun
    },
    {
        tip: enums.nacinPlacanja.KARTICNI_RACUN,
        naziv: localization.prodaja.karticniRacun
    }
];

const tipNapomene = {
    RAZLICITA_CIJENA: 'razlicita_cijena',
    PREKRATKO_PRODUZENJE: 'prekratko_produzenje'
};

const MAX_BROJ_MJESECI_REZERVACIJE = 6;

class ProduzenjeRezervacijeModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mjeseciRezervacije: this.calculateInitialMonths(),
            cijene: this.calculateInitialPrice(),
            kartica: null,
            brojRata: 1,
            nesluzbenaNapomena: '',
            nacinPlacanja: enums.nacinPlacanja.GOTOVINSKI_RACUN,
            obveznaNesluzbenaNapomena: undefined,
            enableProduziRezervacijuButton: true,
            loader: false
        };
        this.calculateDatumIsteka = this.calculateDatumIsteka.bind(this);
        this.onChangeMjeseci = this.onChangeMjeseci.bind(this);
        this.onChangeCijena = this.onChangeCijena.bind(this);
        this.onChange = this.onChange.bind(this);
        this.checkNapomena = this.checkNapomena.bind(this);
        this.calculateUkupno = this.calculateUkupno.bind(this);
        this.enableProduziRezervacijuButton = this.enableProduziRezervacijuButton.bind(this);
        this.checkKomponenteProduzivanja = this.checkKomponenteProduzivanja.bind(this);
        this.showLoader = this.showLoader.bind(this);
        this.hideLoader = this.hideLoader.bind(this);
        this.onProduziRezervacije = this.onProduziRezervacije.bind(this);
        this.openDokumenti = this.openDokumenti.bind(this);
    }

    calculateInitialMonths() {
        return this.props.zahtjevRezervacije.rezervacije.map(r => {
            let noviDatumIsteka = new Date(r.datumIsteka);
            let brMjeseci = 0;
            while (noviDatumIsteka.getTime() < Date.now()) {
                noviDatumIsteka.setMonth(noviDatumIsteka.getMonth() + 1);
                brMjeseci++;
            }
            if (brMjeseci > 0) {
                return {
                    rezervacijaId: r.id,
                    brMjeseci: brMjeseci
                };
            } else {
                return {
                    rezervacijaId: r.id,
                    brMjeseci: 1
                };
            }
        });
    }

    calculateInitialPrice() {
        return this.props.zahtjevRezervacije.rezervacije.map(r => {
            return {
                rezervacijaId: r.id,
                cijena: undefined
            };
        });
    }

    calculateDatumIsteka(extraMonths, time) {
        let noviDatumIsteka = new Date(time);
        noviDatumIsteka.setMonth(noviDatumIsteka.getMonth() + extraMonths);
        return noviDatumIsteka;
    }

    onChangeMjeseci(rezervacijaId, e, { value }) {
        this.setState(
            {
                mjeseciRezervacije: this.state.mjeseciRezervacije.map(mr => {
                    if (mr.rezervacijaId === rezervacijaId) {
                        mr.brMjeseci = value;
                    }
                    return mr;
                })
            },
            this.checkNapomena
        );
    }

    onChangeCijena(rezervacijaId, e, { value }) {
        this.setState(
            {
                cijene: this.state.cijene.map(c => {
                    if (c.rezervacijaId === rezervacijaId) {
                        c.cijena = value;
                    }
                    return c;
                })
            },
            this.checkNapomena
        );
    }

    onChange(e, { name, value }) {
        this.setState({ [name]: value }, this.checkNapomena);
    }

    checkNapomena() {
        let obveznaNesluzbenaNapomena = undefined;
        this.props.zahtjevRezervacije.rezervacije.forEach(r => {
            let brMjeseci = this.state.mjeseciRezervacije.find(mr => mr.rezervacijaId === r.id).brMjeseci;
            let cijena = this.state.cijene.find(c => c.rezervacijaId === r.id).cijena;
            let noviDatumIsteka = this.calculateDatumIsteka(brMjeseci, r.datumIsteka);
            let defaultnaCijena = Math.round((r.jedProdajnaCijena - r.jedNabavnaCijena) * 100) / 100;
            if (!this.state.nesluzbenaNapomena) {
                if (cijena !== undefined && cijena != defaultnaCijena) {
                    obveznaNesluzbenaNapomena = tipNapomene.RAZLICITA_CIJENA;
                } else if (noviDatumIsteka.getTime() <= Date.now()) {
                    obveznaNesluzbenaNapomena = tipNapomene.PREKRATKO_PRODUZENJE;
                }
            }
        });
        this.setState({ obveznaNesluzbenaNapomena: obveznaNesluzbenaNapomena }, this.enableProduziRezervacijuButton);
    }

    calculateUkupno() {
        let ukupno = 0,
            missingData = false;
        this.props.zahtjevRezervacije.rezervacije.forEach(r => {
            let cijena = this.state.cijene.find(c => c.rezervacijaId === r.id).cijena;
            let brMjeseci = this.state.mjeseciRezervacije.find(mr => mr.rezervacijaId === r.id).brMjeseci;
            if (cijena === '') {
                missingData = true;
            } else if (cijena !== undefined) {
                ukupno += brMjeseci * cijena * r.kolicina;
            } else {
                let tempUkupno = brMjeseci * r.kolicina * Math.round((r.jedProdajnaCijena - r.jedNabavnaCijena) * 100) / 100;
                if (this.state.nacinPlacanja !== enums.nacinPlacanja.GOTOVINSKI_RACUN) {
                    tempUkupno *= 1 + 1 / this.props.appPrefs.postotakZaKarticnoPlacanje;
                }
                ukupno += tempUkupno;
            }
        });
        return !missingData ? Math.round(ukupno * 100) / 100 : undefined;
    }

    enableProduziRezervacijuButton() {
        this.setState({ enableProduziRezervacijuButton: this.checkKomponenteProduzivanja() });
    }

    checkKomponenteProduzivanja() {
        if (!this.calculateUkupno()) {
            return false;
        }
        if (this.state.nacinPlacanja === enums.nacinPlacanja.KARTICNI_RACUN) {
            if (this.state.kartica === null) {
                return false;
            }
            if (!this.state.brojRata) {
                return false;
            }
        }
        if (this.state.obveznaNesluzbenaNapomena && !this.state.nesluzbenaNapomena) {
            return false;
        }
        return true;
    }

    showLoader(callback) {
        this.setState(
            {
                loader: true
            },
            callback
        );
    }

    hideLoader(callback, data) {
        this.setState(
            {
                loader: false
            },
            callback.bind(this, data)
        );
    }

    onProduziRezervacije() {
        let ukupnoCijenaProduzivanja = this.calculateUkupno();
        let ukupnoCijenaRezervacije = ukupnoCijenaProduzivanja;
        this.props.zahtjevRezervacije.rezervacije.forEach(r => {
            ukupnoCijenaRezervacije += r.jedNabavnaCijena * r.kolicina;
        });
        let rezervacijeData = {
            nesluzbenaNapomena: this.state.nesluzbenaNapomena || null,
            rezervacije: this.props.zahtjevRezervacije.rezervacije.map(r => {
                let cijena = this.state.cijene.find(c => c.rezervacijaId === r.id).cijena;
                if (cijena !== undefined) {
                    cijena = parseFloat(cijena);
                } else {
                    cijena = r.jedProdajnaCijena - r.jedNabavnaCijena;
                    if (this.state.nacinPlacanja !== enums.nacinPlacanja.GOTOVINSKI_RACUN) {
                        cijena = cijena * (1 + 1 / this.props.appPrefs.postotakZaKarticnoPlacanje);
                    }
                    cijena = Math.round(cijena * 100) / 100;
                }
                return {
                    rezervacijaId: r.id,
                    brojMjeseci: this.state.mjeseciRezervacije.find(mr => mr.rezervacijaId === r.id).brMjeseci,
                    jedCijenaNaplateMjeseca: cijena
                };
            }),
            iznosGotovina:
                this.state.nacinPlacanja === enums.nacinPlacanja.GOTOVINSKI_RACUN
                    ? ukupnoCijenaRezervacije
                    : ukupnoCijenaRezervacije - ukupnoCijenaProduzivanja,
            karticnoPlacanje:
                this.state.nacinPlacanja !== enums.nacinPlacanja.GOTOVINSKI_RACUN
                    ? {
                          karticaId: this.state.kartica,
                          iznosKarticno: ukupnoCijenaProduzivanja,
                          brojRata: this.state.brojRata
                      }
                    : null
        };
        this.props.rezervacijaDataAccess.produziRezervacije(
            this.props.zahtjevRezervacije.id,
            rezervacijeData,
            (rezervacija) => {
                this.hideLoader(() => this.openDokumenti(rezervacija));
                this.props.onProduzenjeSuccess();
            }
        );
    }

    openDokumenti(rezervacija) {
        let otkupniBlok = findItemWithLatestDate(rezervacija.otkupniBlokovi);
        let listaRezervacija = rezervacija.rezervacije.istekaoRZP.concat(
            rezervacija.rezervacije.nijeIstekaoRZPIstekla,
            rezervacija.rezervacije.nijeIstekaoRZPNijeIstekla
        );
        window.open(
            endpoints.appEndpoints.print.potvrdaRezervacije +
                storeDocumentAndGenerateKey(
                    Object.assign(otkupniBlok, {
                        rezervacije: [...listaRezervacija],
                        sluzbenaNapomena: rezervacija.sluzbenaNapomena
                    })
                ),
            '_blank'
        );
        window.open(endpoints.appEndpoints.print.ugovorOtkupa + storeDocumentAndGenerateKey(otkupniBlok), '_blank');
        window.open(endpoints.appEndpoints.print.racun + storeDocumentAndGenerateKey(findItemWithLatestDate(rezervacija.racuni)), '_blank');
        this.props.closeModal();
    }

    render() {
        let ukupno = this.calculateUkupno();
        return (
            <div style={styles.container}>
                <Segment>
                    <Grid stackable>
                        <Grid.Row columns={3} divided>
                            <Grid.Column verticalAlign="middle">
                                <Header as="h3">{localization.prodaja.nacinPlacanja + ':'}</Header>
                                <Select
                                    options={racuni.map(racun => {
                                        return { key: racun.tip, text: racun.naziv, value: racun.tip };
                                    })}
                                    name="nacinPlacanja"
                                    value={this.state.nacinPlacanja}
                                    onChange={this.onChange}
                                    search
                                />
                            </Grid.Column>
                            {this.state.nacinPlacanja === enums.nacinPlacanja.KARTICNI_RACUN ? (
                                <React.Fragment>
                                    <Grid.Column verticalAlign="middle">
                                        <Header as="h3">{localization.prodaja.kartica + ':'}</Header>
                                        <Select
                                            upward
                                            options={this.props.kartice.map(card => {
                                                return { key: card.id, text: card.naziv, value: card.id };
                                            })}
                                            name="kartica"
                                            value={this.state.kartica}
                                            onChange={this.onChange}
                                            placeholder={localization.prodaja.odaberiKarticu}
                                            search
                                        />
                                    </Grid.Column>
                                    <Grid.Column verticalAlign="middle">
                                        <Header as="h3">{localization.prodaja.brojRata + ':'}</Header>
                                        <Select
                                            upward
                                            options={Array.from(new Array(12), (val, index) => {
                                                return { key: index, text: index + 1, value: index + 1 };
                                            })}
                                            name="brojRata"
                                            value={this.state.brojRata}
                                            onChange={this.onChange}
                                            placeholder={localization.prodaja.odaberiBrojRata}
                                            search
                                        />
                                    </Grid.Column>
                                </React.Fragment>
                            ) : null}
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Table celled structured stackable selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>{localization.artikl.sifra}</Table.HeaderCell>
                            <Table.HeaderCell>{localization.artikl.naziv}</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">{localization.rezervacije.datumIsteka}</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center" collapsing>
                                {localization.rezervacije.produzenjeRezervacije + ' ' + localization.common.za + ':'}
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center" collapsing>
                                {localization.rezervacije.mjesecnaCijena}
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center" collapsing>
                                {localization.common.kolicina}
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center" collapsing>
                                {localization.rezervacije.cijenaRezervacije}
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.props.zahtjevRezervacije.rezervacije.map(rezervacija => {
                            let brMjeseci = this.state.mjeseciRezervacije.find(mr => mr.rezervacijaId === rezervacija.id).brMjeseci;
                            let noviDatumIsteka = this.calculateDatumIsteka(brMjeseci, rezervacija.datumIsteka);
                            let cijena = this.state.cijene.find(c => c.rezervacijaId === rezervacija.id).cijena;
                            if (cijena === undefined) {
                                cijena = rezervacija.jedProdajnaCijena - rezervacija.jedNabavnaCijena;
                                if (this.state.nacinPlacanja !== enums.nacinPlacanja.GOTOVINSKI_RACUN) {
                                    cijena = cijena * (1 + 1 / this.props.appPrefs.postotakZaKarticnoPlacanje);
                                }
                                cijena = Math.round(cijena * 100) / 100;
                            }
                            return (
                                <Table.Row key={rezervacija.id}>
                                    <Table.Cell>{rezervacija.predmet.sifra}</Table.Cell>
                                    <Table.Cell>{rezervacija.predmet.naziv}</Table.Cell>
                                    {noviDatumIsteka.getTime() > Date.now() ? (
                                        <Table.Cell positive textAlign="center">
                                            <Icon name="checkmark" />
                                            {moment(noviDatumIsteka).format('DD.MM.YYYY.')}
                                        </Table.Cell>
                                    ) : (
                                        <Table.Cell error textAlign="center">
                                            <Icon name="attention" />
                                            {moment(noviDatumIsteka).format('DD.MM.YYYY.')}
                                        </Table.Cell>
                                    )}
                                    <Table.Cell textAlign="center" collapsing>
                                        <Dropdown
                                            selection
                                            compact
                                            value={brMjeseci}
                                            onChange={this.onChangeMjeseci.bind(this, rezervacija.id)}
                                            options={Array.from(new Array(MAX_BROJ_MJESECI_REZERVACIJE), (val, index) => {
                                                return {
                                                    text: index + 1,
                                                    value: index + 1
                                                };
                                            })}
                                        />
                                        {'  '}
                                        {brMjeseci === 1
                                            ? localization.common.mjesec
                                            : [2, 3, 4].includes(brMjeseci)
                                            ? localization.common.mjeseca
                                            : localization.common.mjeseci}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center" collapsing>
                                        <InputField
                                            label={localization.common.eur}
                                            placeholder={localization.common.cijena}
                                            name="cijena"
                                            value={cijena}
                                            onChange={this.onChangeCijena.bind(this, rezervacija.id)}
                                        />
                                    </Table.Cell>
                                    <Table.Cell textAlign="right" collapsing style={styles.kolicinaIcijena}>
                                        {rezervacija.kolicina}
                                    </Table.Cell>
                                    <Table.Cell textAlign="right" collapsing style={styles.kolicinaIcijena}>
                                        <FormatIznosa value={cijena * rezervacija.kolicina * brMjeseci} />
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan={5} />
                            <Table.HeaderCell textAlign="center">
                                <b>{localization.common.ukupno + ':'}</b>
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="right" collapsing style={styles.kolicinaIcijena}>
                                <FormatIznosa value={ukupno} />
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
                <NesluzbenaNapomena
                    onChangeNapomena={this.onChange}
                    placeholder={localization.common.nesluzbenaNapomena}
                    required={!!this.state.obveznaNesluzbenaNapomena}
                    requiredText={
                        this.state.obveznaNesluzbenaNapomena === tipNapomene.RAZLICITA_CIJENA
                            ? localization.rezervacije.produzenjeRezervacijeRazlicitaCijenaNapomena
                            : localization.rezervacije.produzenjeRezervacijePrekratkoProduzenjeNapomena
                    }
                />
                <div style={styles.actionButtons}>
                    <Button icon="close" content={localization.common.odustani} onClick={this.props.closeModal} />
                    <Button
                        icon="calendar plus"
                        color="green"
                        disabled={!this.state.enableProduziRezervacijuButton}
                        content={localization.rezervacije.produziRezervacije}
                        onClick={this.showLoader.bind(this, this.onProduziRezervacije)}
                    />
                </div>
                <Dimmer active={this.state.loader} page>
                    <Loader />
                </Dimmer>
            </div>
        );
    }
}

ProduzenjeRezervacijeModal.propTypes = {
    zahtjevRezervacije: PropTypes.object.isRequired,
    appPrefs: PropTypes.object.isRequired,
    kartice: PropTypes.array.isRequired,
    rezervacijaDataAccess: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
    onProduzenjeSuccess: PropTypes.func.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {
        appPrefs: state.appPreferencesReducer.appPrefs,
        kartice: state.karticeReducer.kartice || []
    };
};

let mapDispatchToProps = function(dispatch) {
    return {
        rezervacijaDataAccess: bindActionCreators(RezervacijaDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProduzenjeRezervacijeModal);
