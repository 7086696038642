import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';

import DokumentiPreloaderAndMenu from './DokumentiPreloaderAndMenu';
import Klijenti from './dokumenti_components/Klijenti';
import Artikli from './dokumenti_components/Artikli';
import Usluge from './dokumenti_components/Usluge';
import StanjeSkladista from './dokumenti_components/StanjeSkladista';
import Rezervacija from './Rezervacija';
import Otkupi from './dokumenti_components/Otkupi';
import Primke from './dokumenti_components/Primke';
import Racuni from './dokumenti_components/Racuni';
import Predracuni from './dokumenti_components/Predracuni';
import RadniNalozi from './dokumenti_components/RadniNalozi';
import ZahtjeviZaProcjenu from './dokumenti_components/ZahtjeviZaProcjenu';
import Meduskladisnice from './dokumenti_components/Meduskladisnice';
import BlagajnickiDnevnikFiskalnaBlagajna from './dokumenti_components/BlagajnickiDnevnikFiskalnaBlagajna';
import BlagajnickiDnevnikBlagajnaOtkupa from './dokumenti_components/BlagajnickiDnevnikBlagajnaOtkupa';
import CijeneOglasavanje from './dokumenti_components/CijeneOglasavanje';
import MaknutiSOglasavanja from './dokumenti_components/MaknutiSOglasavanja';
import DnevnaEvidencija from './dokumenti_components/DnevnaEvidencija';
import PregledTroskova from './dokumenti_components/PregledTroskova';
import Rekapitulacije from './dokumenti_components/Rekapitulacije';
import PregledBankovnihTransakcija from './dokumenti_components/PregledBankovnihTransakcija';
import Kontrola from './Kontrola';
import Postavke from './postavke_components/Postavke';
import endpoints from '../environments/endpoints';
import enums from '../utils/Enums';
import Izdatnice from './dokumenti_components/Izdatnice';
import NovaIzdatnica from './dokumenti_components/NovaIzdatnica';

class Dokumenti extends React.Component {
    render() {
        let prefix = '',
            rootEndpoint = endpoints.appEndpoints.root;
        if (sessionStorage.userRole === enums.tipKorisnika.DJELATNIK) {
            prefix = endpoints.appEndpoints.dokumentiRoot;
            rootEndpoint = endpoints.appEndpoints.dokumentiRoot;
        }
        return (
            <Switch>
                <Route exact path={rootEndpoint} component={DokumentiPreloaderAndMenu} />
                <Route path={prefix + endpoints.appEndpoints.klijenti} component={Klijenti} />
                <Route path={prefix + endpoints.appEndpoints.artikli} component={Artikli} />
                <Route path={prefix + endpoints.appEndpoints.usluge} component={Usluge} />
                <Route path={prefix + endpoints.appEndpoints.rezervacije} component={Rezervacija} />
                <Route path={prefix + endpoints.appEndpoints.stanjeSkladista} component={StanjeSkladista} />
                <Route path={prefix + endpoints.appEndpoints.otkupi} component={Otkupi} />
                <Route path={prefix + endpoints.appEndpoints.primke} component={Primke} />
                <Route path={prefix + endpoints.appEndpoints.racuni} component={Racuni} />
                <Route path={prefix + endpoints.appEndpoints.predracuni} component={Predracuni} />
                <Route path={prefix + endpoints.appEndpoints.procjene} component={ZahtjeviZaProcjenu} />
                <Route path={prefix + endpoints.appEndpoints.radniNalozi} component={RadniNalozi} />
                <Route path={prefix + endpoints.appEndpoints.meduskladisnice} component={Meduskladisnice} />
                {sessionStorage.userRole !== enums.tipKorisnika.DJELATNIK ? (
                    <Route path={prefix + endpoints.appEndpoints.izdatnice} component={Izdatnice} />
                ) : null}
                {sessionStorage.userRole !== enums.tipKorisnika.DJELATNIK ? (
                    <Route path={prefix + endpoints.appEndpoints.novaIzdatnica} component={NovaIzdatnica} />
                ) : null}
                <Route path={prefix + endpoints.appEndpoints.cijeneOglasavanje} component={CijeneOglasavanje} />
                <Route path={prefix + endpoints.appEndpoints.maknutiSOglasavanja} component={MaknutiSOglasavanja} />
                <Route path={prefix + endpoints.appEndpoints.blagajnickiDnevnikFisk} component={BlagajnickiDnevnikFiskalnaBlagajna} />
                {sessionStorage.userRole !== enums.tipKorisnika.DJELATNIK ? (
                    <Route path={prefix + endpoints.appEndpoints.blagajnickiDnevnikOtkup} component={BlagajnickiDnevnikBlagajnaOtkupa} />
                ) : null}
                {sessionStorage.userRole !== enums.tipKorisnika.DJELATNIK ? (
                    <Route path={prefix + endpoints.appEndpoints.dnevnaEvidencija} component={DnevnaEvidencija} />
                ) : null}
                {sessionStorage.userRole !== enums.tipKorisnika.DJELATNIK ? (
                    <Route path={prefix + endpoints.appEndpoints.pregledTroskova} component={PregledTroskova} />
                ) : null}
                {sessionStorage.userRole !== enums.tipKorisnika.DJELATNIK ? (
                    <Route path={prefix + endpoints.appEndpoints.rekapitulacije} component={Rekapitulacije} />
                ) : null}
                {sessionStorage.userRole !== enums.tipKorisnika.DJELATNIK ? (
                    <Route path={prefix + endpoints.appEndpoints.pregledBankovnihTransakcija} component={PregledBankovnihTransakcija} />
                ) : null}
                {sessionStorage.userRole === enums.tipKorisnika.VLASNIK ? <Route path={prefix + endpoints.appEndpoints.kontrola} component={Kontrola} /> : null}
                {sessionStorage.userRole === enums.tipKorisnika.VLASNIK ? <Route path={prefix + endpoints.appEndpoints.postavke} component={Postavke} /> : null}
            </Switch>
        );
    }
}

Dokumenti.propTypes = {};

let mapStateToProps = function(state, ownProps) {
    return {};
};

let mapDispatchToProps = function(dispatch) {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Dokumenti);
