import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Button, Dimmer, Grid, Header, Icon, Loader, Modal, Pagination, Search, Segment, Table } from 'semantic-ui-react';

import localization from '../../locales/localization';
import icons from '../../utils/DefinedIcons';
import enums from '../../utils/Enums';
import SearchResultsMessage from '../common_components/SearchResultsMessage';
import Usluga from '../common_components/Usluga';
import * as ArtikliDataAccess from '../../data_access/ArtikliDataAccess';
import * as ArtikliApi from '../../data_access/ArtikliApi';
import { bindActionCreators } from 'redux';
import FormatIznosa from '../common_components/FormatIznosa';
import FilterSortButton from '../common_components/FilterSortButton';

// TODO #pagination #refactor Extract this to some other place when pagination for other components is implemented
//      and we solve the Redux/DataAccess dilemma.
const MAX_API_FETCH_ATTEMPTS = 3;
// TODO #pagination #refactor Probably extract somewhere else and use across all search components
const SEARCH_DEBOUNCE_RATE_MS = 1000;

const styles = {
    paginationContainer: {
        textAlign: 'center'
    },
    kolicinaIcijena: {
        fontFamily: 'monospace'
    }
};

const SORT = [
    {
        name: localization.usluga.sifra,
        apiParam: ArtikliApi.SORT.sifra
    },
    {
        name: localization.usluga.naziv,
        apiParam: ArtikliApi.SORT.naziv
    }
];

const LIST_STATE = {
    initial: 'initial',
    empty: 'empty',
    noResult: 'noResult',
    populated: 'populated',
    error: 'error'
};

const ITEMS_PER_PAGE = 20;

class Usluge extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            newItem: false,
            editItem: undefined,
            loader: false,
            sort: 1,
            pageActive: 1
        };
        this.showNewUsluga = this.showNewUsluga.bind(this);
        this.hideNewUsluga = this.hideNewUsluga.bind(this);
        this.showEditUsluga = this.showEditUsluga.bind(this);
        this.hideEditUsluga = this.hideEditUsluga.bind(this);
        this.showLoader = this.showLoader.bind(this);
        this.hideLoader = this.hideLoader.bind(this);
        this.onNovaUsluga = this.onNovaUsluga.bind(this);
        this.onEditUslugaSave = this.onEditUslugaSave.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
        this.applyFilterAndSort = this.applyFilterAndSort.bind(this);
        this.resetSearchComponent = this.resetSearchComponent.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.fetchUsluge = this.fetchUsluge.bind(this);
        this.refreshUsluge = this.refreshUsluge.bind(this);
        this.renderInitialComponent = this.renderInitialComponent.bind(this);
        this.renderEmptyComponent = this.renderEmptyComponent.bind(this);
        this.renderNoResultComponent = this.renderNoResultComponent.bind(this);
        this.renderErrorComponent = this.renderErrorComponent.bind(this);
        this.renderListComponent = this.renderListComponent.bind(this);
        this.debouncedFetchUsluge = _.debounce(function (sort, page) {
            this.fetchUsluge(sort, page);
        }, SEARCH_DEBOUNCE_RATE_MS).bind(this);

    }

    componentWillMount() {
        this.resetSearchComponent();
    }

    showNewUsluga() {
        this.setState({
            newItem: true
        });
    }

    hideNewUsluga(callback) {
        this.setState({
            newItem: false
        }, callback);
    }

    showEditUsluga(sifraUsluge) {
        this.setState({
            editItem: sifraUsluge
        });
    }

    hideEditUsluga(callback) {
        this.setState({
            editItem: undefined
        }, callback);
    }

    showLoader() {
        this.setState({
            loader: true
        });
    }

    hideLoader(callback) {
        this.setState({
            loader: false
        }, callback);
    }

    onNovaUsluga(usluga) {
        this.showLoader();
        this.props.artikliDataAccess.createUsluga(
            usluga,
            () => this.hideLoader(() => this.hideNewUsluga(() => this.refreshUsluge())),
            () => this.hideLoader()
        );
    }

    onEditUslugaSave(id, usluga) {
        this.showLoader();
        this.props.artikliDataAccess.updateUsluga(
            id, usluga,
            () => this.hideLoader(() => this.hideEditUsluga(() => this.refreshUsluge())),
            () => this.hideLoader()
        );
    }

    onChangePage(e, { activePage }) {
        this.fetchUsluge(this.state.sort, activePage);
    }

    applyFilterAndSort(filter, sort) {
        this.fetchUsluge(sort);
    }

    resetSearchComponent() {
        this.setState({
            listState: LIST_STATE.initial,
            fetchAttempts: 0
        }, () => {
            this.fetchUsluge();
        });
    }

    handleSearchChange(e, { value }) {
        this.setState({
            value: value
        }, () => {
            this.debouncedFetchUsluge();
        });
    }

    fetchUsluge(sort = this.state.sort, page = 1) {

        let nextStateCommon = {
            loader: false,
            fetchAttempts: 0,
            sort: sort
        };

        let onFetchSuccess = (data) => {
            if (data.items.length !== 0) {
                let nextStateNonEmpty = {
                    listState: LIST_STATE.populated,
                    usluge: data.items,
                    pages: data.totalPages,
                    pageActive: page
                };
                this.setState(Object.assign({}, nextStateCommon, nextStateNonEmpty));

            } else {

                let fetchedAll = this.state.value === '';

                let newListState = fetchedAll ? LIST_STATE.empty : LIST_STATE.noResult;
                let nextStateEmpty = {
                    listState: newListState,
                    usluge: []
                };

                this.setState(Object.assign({}, nextStateCommon, nextStateEmpty));
            }
        };

        let onFetchError = () => {
            let fetchAttempts = this.state.fetchAttempts + 1;
            if (fetchAttempts === MAX_API_FETCH_ATTEMPTS) {
                let nextStateError = {
                    listState: LIST_STATE.error
                };
                this.setState(Object.assign({}, nextStateCommon, nextStateError));
            } else {
                this.setState({
                    fetchAttempts: fetchAttempts
                }, () => {
                    this.fetchUsluge(sort, page);
                });
            }

        };

        this.showLoader();

        ArtikliApi.getArtikliPaginated(
            this.state.value, page, ITEMS_PER_PAGE, SORT[sort].apiParam, ArtikliApi.TIP_ARTIKLA.usluga,
            onFetchSuccess, onFetchError
        );
    }

    refreshUsluge() {
        this.fetchUsluge(this.state.sort, this.state.pageActive);
    }

    renderInitialComponent() {
        return (
            <SearchResultsMessage
                icon={icons.usluge}
                header={localization.common.ucitavanje}
                content={localization.common.molimoPricekajte}
            />
        );
    }

    renderEmptyComponent() {
        return (
            <SearchResultsMessage
                icon={icons.usluge}
                header={localization.usluge.nemaUsluga}
                content={undefined}
            />
        );
    }

    renderNoResultComponent() {
        return (
            <SearchResultsMessage
                icon={icons.usluge}
                header={localization.common.unesenaPretragaNemaRezultata}
                content={localization.common.pokusajDrugacijuPretragu}
            />
        );
    }

    renderErrorComponent() {
        return (
            <SearchResultsMessage
                icon={icons.usluge}
                header={localization.common.dogodilaSeGreska}
                content={localization.common.pokusajtePonovno}
            />
        );
    }

    renderListComponent() {
        return (
            <React.Fragment>
                <Grid.Row>
                    <Grid.Column verticalAlign="middle">
                        <Table stackable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>{localization.usluga.sifra}</Table.HeaderCell>
                                    <Table.HeaderCell>{localization.usluga.naziv}</Table.HeaderCell>
                                    <Table.HeaderCell textAlign="center" collapsing>
                                        {localization.usluga.jedinicnaCijena}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell />
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.state.usluge.map(usluga => {
                                    return (
                                        <Table.Row key={usluga.id}>
                                            <Table.Cell>{usluga.sifra}</Table.Cell>
                                            <Table.Cell>{usluga.naziv}</Table.Cell>
                                            <Table.Cell collapsing textAlign="right" style={styles.kolicinaIcijena}>
                                                <FormatIznosa value={usluga.jedCijena} />
                                            </Table.Cell>
                                            <Table.Cell collapsing>
                                                {sessionStorage.userRole !== enums.tipKorisnika.DJELATNIK ? (
                                                    <Button.Group basic size="small">
                                                        <Modal
                                                            open={this.state.editItem === usluga.sifra}
                                                            closeOnDimmerClick={false}
                                                            trigger={
                                                                <Button
                                                                    icon="write"
                                                                    onClick={this.showEditUsluga.bind(this, usluga.sifra)}
                                                                />
                                                            }
                                                        >
                                                            <Modal.Header>{localization.common.urediUslugu}</Modal.Header>
                                                            <Modal.Content>
                                                                <Usluga
                                                                    item={usluga}
                                                                    onSave={this.onEditUslugaSave.bind(this, usluga.id)}
                                                                    onCancel={() => this.hideEditUsluga()}
                                                                />
                                                            </Modal.Content>
                                                        </Modal>
                                                    </Button.Group>
                                                ) : null}
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </Table>
                        <div style={styles.paginationContainer}>
                            <Pagination
                                activePage={this.state.pageActive}
                                totalPages={this.state.pages}
                                onPageChange={this.onChangePage}
                                ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
                                firstItem={{ content: <Icon name="angle double left" />, icon: true }}
                                lastItem={{ content: <Icon name="angle double right" />, icon: true }}
                                prevItem={{ content: <Icon name="angle left" />, icon: true }}
                                nextItem={{ content: <Icon name="angle right" />, icon: true }}
                            />
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </React.Fragment>
        );
    }

    render() {
        return (
            <div>
                <Segment tertiary>
                    <Grid stackable>
                        <Grid.Row columns={6}>
                            <Grid.Column verticalAlign="middle">
                                <Header as="h2">{localization.usluge.usluge}</Header>
                            </Grid.Column>
                            <Grid.Column verticalAlign="middle">
                                <Search
                                    input={{ fluid: true }}
                                    loading={this.state.isSearchLoading}
                                    onSearchChange={this.handleSearchChange}
                                    value={this.state.value}
                                    open={false}
                                />
                            </Grid.Column>
                            <Grid.Column />
                            <Grid.Column />
                            {sessionStorage.userRole !== enums.tipKorisnika.DJELATNIK ? (
                                <React.Fragment>
                                    <Grid.Column textAlign="right" verticalAlign="middle">
                                        <FilterSortButton
                                            buttonName={localization.common.sortiranje}
                                            sortingOptions={SORT.map((s) => s.name)}
                                            activeSort={this.state.sort}
                                            callback={this.applyFilterAndSort}
                                        />
                                    </Grid.Column>
                                    <Grid.Column textAlign="right" verticalAlign="middle">
                                        <Modal
                                            open={this.state.newItem}
                                            closeOnDimmerClick={false}
                                            trigger={
                                                <Button
                                                    icon="asterisk"
                                                    basic
                                                    color="black"
                                                    content={localization.usluge.novaUsluga}
                                                    onClick={() => this.showNewUsluga()}
                                                />
                                            }
                                        >
                                            <Modal.Header>{localization.usluge.novaUsluga}</Modal.Header>
                                            <Modal.Content>
                                                <Usluga item={{}} onSave={this.onNovaUsluga} onCancel={() => this.hideNewUsluga()} />
                                            </Modal.Content>
                                        </Modal>
                                    </Grid.Column>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <Grid.Column />
                                    <Grid.Column textAlign="right" verticalAlign="middle">
                                        <FilterSortButton
                                            buttonName={localization.common.sortiranje}
                                            sortingOptions={SORT.map((s) => s.name)}
                                            activeSort={this.state.sort}
                                            callback={this.applyFilterAndSort}
                                        />
                                    </Grid.Column>
                                </React.Fragment>
                            )}
                        </Grid.Row>
                        {(() => {
                            switch (this.state.listState) {
                                case LIST_STATE.initial:
                                    return this.renderInitialComponent();
                                case LIST_STATE.populated:
                                    return this.renderListComponent();
                                case LIST_STATE.empty:
                                    return this.renderEmptyComponent();
                                case LIST_STATE.noResult:
                                    return this.renderNoResultComponent();
                                case LIST_STATE.error:
                                    return this.renderErrorComponent();
                            }
                        })()}
                    </Grid>
                </Segment>
                <Dimmer active={this.state.loader} page>
                    <Loader />
                </Dimmer>
            </div>
        );
    }
}

Usluge.propTypes = {
    artikliDataAccess: PropTypes.object.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {
    };
};

let mapDispatchToProps = function(dispatch) {
    return {
        artikliDataAccess: bindActionCreators(ArtikliDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Usluge);
