import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import RequiredText from './RequiredText';
import localization from '../../locales/localization';

const styles = {
    required: {
        borderColor: 'red'
    },
    requiredText: {
        color: 'red'
    }
};

export default class NesluzbenaNapomena extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Form>
                    <Form.TextArea
                        placeholder={this.props.placeholder || localization.common.napomena}
                        style={this.props.required ? styles.required : undefined}
                        name="nesluzbenaNapomena"
                        onChange={this.props.onChangeNapomena}
                    />
                </Form>
                {this.props.required ? <RequiredText requiredText={this.props.requiredText} /> : null}
            </React.Fragment>
        );
    }
}

NesluzbenaNapomena.propTypes = {
    onChangeNapomena: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    requiredText: PropTypes.string
};
