import $ from 'jquery';

import endpoint from '../environments/endpoints';

export const TIP_SKL_UL_BLOKA = {
    all: 'all',
    otkupniBlok: 'ob',
    primka: 'primka'
};

// TODO @Kec @Vranjes Parameter order?
export function getOtkupiPaginated(q, page, pageSize, poslovnicaId, tipSklUlBloka, onSuccess, onError) {
    $.ajax({
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + sessionStorage.token
        },
        url: endpoint.apiEndpoint + 'otkupi/paginated' +
            '?q=' + q +
            '&tipSklUlBloka=' + tipSklUlBloka +
            '&poslovnicaId=' + (poslovnicaId ? poslovnicaId : '') +
            '&page=' + page +
            '&pageSize=' + pageSize
    })
        .done(onSuccess)
        .fail(onError);
}

export function getLatestOtkupniBlok(poslovnicaId, onSuccess, onError) {
    getOtkupiPaginated(
        '', 1, 1, poslovnicaId, TIP_SKL_UL_BLOKA.otkupniBlok,
        (data) => onSuccess(data.items[0]),
        onError
    );
}

export function getOtkupniBlokoviWithinDateRange(poslovnicaId, startDateTimeIncl, endDateTimeIncl, onSuccess, onError) {
    $.ajax({
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + sessionStorage.token
        },
        url: endpoint.apiEndpoint + 'otkupi/otkupni-blokovi/rekapitulacija' +
            '?poslovnicaId=' + poslovnicaId +
            '&startDateTimeIncl=' + startDateTimeIncl.format("YYYY-MM-DDTHH:mm:ss") +
            '&endDateTimeIncl=' + endDateTimeIncl.format("YYYY-MM-DDTHH:mm:ss")
    })
        .done(onSuccess)
        .fail(onError);
}
