import $ from 'jquery';

import endpoint from '../environments/endpoints';
import { showAlert } from '../utils/ErrorHandler';

// export function searchKlijenti(query, onSuccess, onError) {
//     return function (dispatch) {
//         $.ajax({
//             method: 'GET',
//             headers: {
//                 'Accept': 'application/json',
//                 'Content-Type': 'application/json',
//                 'Authorization': 'Bearer ' + sessionStorage.token
//             },
//             url: endpoint.apiEndpoint + 'klijenti?q=' + query
//         }).done((data) => {

//         }).fail((error) => {

//         });
//     };
// }

export function createKlijent(klijent, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'klijenti',
            data: JSON.stringify(klijent)
        })
            .done(data => {
                onSuccess(data);
            })
            .fail(error => {
                showAlert(error);
                onError();
            });
    };
}

export function updateKlijent(id, klijent, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'klijenti/' + id,
            data: JSON.stringify(klijent)
        })
            .done(data => {
                onSuccess(data);
            })
            .fail(error => {
                showAlert(error);
                onError();
            });
    };
}

// export function deleteKlijent(id, onSuccess, onError) {
//     return function (dispatch) {
//         $.ajax({
//             method: 'DELETE',
//             headers: {
//                 'Accept': 'application/json',
//                 'Content-Type': 'application/json',
//                 'Authorization': 'Bearer ' + sessionStorage.token
//             },
//             url: endpoint.apiEndpoint + 'klijenti/' + id
//         }).done((data) => {

//         }).fail((error) => {

//         });
//     };
// }
