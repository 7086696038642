import React from 'react';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';

export default class UplataNapomeneContent extends React.Component {
    render() {
        return (
            <Message>
                <Message.Header>{this.props.header}</Message.Header>
                <p>{this.props.napomena}</p>
            </Message>
        );
    }
}

UplataNapomeneContent.propTypes = {
    header: PropTypes.string.isRequired,
    napomena: PropTypes.string.isRequired
};
