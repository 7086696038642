import $ from 'jquery';

import * as success from '../actions/RadniNalogActions';

import endpoint from '../environments/endpoints';
import enums from '../utils/Enums';
import { showAlert } from '../utils/ErrorHandler';

export function getRadniNalozi(onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'radni-nalozi'
        })
            .done(data => {
                dispatch(success.getRadniNalozi(data));
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function getRadniNaloziZaPoslovnicu(poslovnicaId, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'radni-nalozi?poslovnicaId=' + poslovnicaId
        })
            .done(data => {
                dispatch(success.getRadniNalozi(data));
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function createRadniNalog(radniNalog, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'radni-nalozi',
            data: JSON.stringify(radniNalog)
        })
            .done(data => {
                dispatch(getRadniNaloziZaPoslovnicu(JSON.parse(sessionStorage.poslovnicaId)));
                onSuccess(data);
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function arhivirajRadniNalog(radniNalogId, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'radni-nalozi/' + radniNalogId + '/arhiviraj'
        })
            .done(data => {
                if (sessionStorage.userRole === enums.tipKorisnika.DJELATNIK) {
                    dispatch(getRadniNaloziZaPoslovnicu(JSON.parse(sessionStorage.poslovnicaId)));
                } else {
                    dispatch(getRadniNalozi());
                }
                onSuccess();
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function dodavanjeNesluzbeneNapomeneNaRadniNalog(radniNalogId, napomena, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'radni-nalozi/' + radniNalogId + '/nesluzbene-napomene',
            data: napomena
        })
            .done(data => {
                if (sessionStorage.userRole === enums.tipKorisnika.DJELATNIK) {
                    dispatch(getRadniNaloziZaPoslovnicu(JSON.parse(sessionStorage.poslovnicaId)));
                } else {
                    dispatch(getRadniNalozi());
                }
                onSuccess();
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function dodajSlikeNaRadniNalog(radniNalogId, slike, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'radni-nalozi/' + radniNalogId + '/klijentov-predmet/slike/b64',
            data: JSON.stringify(slike)
        })
            .done(data => {
                if (sessionStorage.userRole === enums.tipKorisnika.DJELATNIK) {
                    dispatch(getRadniNaloziZaPoslovnicu(JSON.parse(sessionStorage.poslovnicaId)));
                } else {
                    dispatch(getRadniNalozi());
                }
                onSuccess();
            })
            .fail(error => {
                showAlert(error);
            });
    };
}

export function izbrisiSlikeSaRadnogNaloga(radniNalogId, slike, onSuccess, onError) {
    return function(dispatch) {
        $.ajax({
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + sessionStorage.token
            },
            url: endpoint.apiEndpoint + 'radni-nalozi/' + radniNalogId + '/klijentov-predmet/slike',
            data: JSON.stringify(slike)
        })
            .done(data => {
                if (sessionStorage.userRole === enums.tipKorisnika.DJELATNIK) {
                    dispatch(getRadniNaloziZaPoslovnicu(JSON.parse(sessionStorage.poslovnicaId)));
                } else {
                    dispatch(getRadniNalozi());
                }
                onSuccess();
            })
            .fail(error => {
                showAlert(error);
            });
    };
}
