import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Header, Segment, Table } from 'semantic-ui-react';

import TablicaKlijenata from '../../common_components/table_fragments/TablicaKlijenata';
import localization from '../../../locales/localization';
import moment from 'moment';
import FormatIznosa from '../../common_components/FormatIznosa';
import enums from '../../../utils/Enums';

const styles = {
    kolicinaIcijena: {
        fontFamily: 'monospace'
    }
};

export default class RezervacijaContent extends React.Component {
    render() {
        return (
            <Segment>
                <Header as="h4">{localization.rezervacije.klijent}</Header>
                <TablicaKlijenata klijent={this.props.zahtjevRezervacije.klijent} />
                {this.props.imageUploadModal ? this.props.imageUploadModal : <Header as="h4">{localization.rezervacije.artikli}</Header>}
                <Table>
                    <Table.Header>
                        <Table.Row>
                            {this.props.enableRowChecking ? <Table.HeaderCell /> : null}
                            <Table.HeaderCell>{localization.artikl.sifra}</Table.HeaderCell>
                            <Table.HeaderCell>{localization.artikl.naziv}</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center" collapsing>
                                {localization.common.kolicina}
                            </Table.HeaderCell>
                            {sessionStorage.userRole === enums.tipKorisnika.VLASNIK ? (
                                <Table.HeaderCell textAlign="center" collapsing>
                                    {localization.common.trzisnaOtkupnaVrijednost}
                                </Table.HeaderCell>
                            ) : null}
                            <Table.HeaderCell textAlign="center">{localization.rezervacije.datumIsteka}</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">{localization.rezervacije.rokZaPodizanje}</Table.HeaderCell>
                            {sessionStorage.userRole !== enums.tipKorisnika.DJELATNIK ? (
                                <Table.HeaderCell textAlign="center" collapsing>
                                    {localization.rezervacije.mjesecnaNaknada}
                                </Table.HeaderCell>
                            ) : null}
                            {sessionStorage.userRole !== enums.tipKorisnika.DJELATNIK ? (
                                <Table.HeaderCell textAlign="center" collapsing>
                                    {localization.rezervacije.cijenaPodizanja}
                                </Table.HeaderCell>
                            ) : null}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.props.zahtjevRezervacije.rezervacije.map(rezervacija => {
                            if (this.props.showNevazece || (!this.props.showNevazece && rezervacija.isVazeca)) {
                                return (
                                    <Table.Row key={rezervacija.id}>
                                        {this.props.enableRowChecking ? (
                                            <Table.Cell>
                                                <Checkbox
                                                    checked={!this.props.listOfDeselected.find(r => r.id === rezervacija.id)}
                                                    onChange={this.props.onRowSelected.bind(this, rezervacija, this.props.zahtjevRezervacije.id)}
                                                />
                                            </Table.Cell>
                                        ) : null}
                                        <Table.Cell>{rezervacija.predmet.sifra}</Table.Cell>
                                        <Table.Cell>{rezervacija.predmet.naziv}</Table.Cell>
                                        <Table.Cell collapsing textAlign="right" style={styles.kolicinaIcijena}>
                                            {rezervacija.kolicina}
                                        </Table.Cell>
                                        {sessionStorage.userRole === enums.tipKorisnika.VLASNIK ? (
                                            <Table.Cell collapsing textAlign="right" style={styles.kolicinaIcijena}>
                                                <FormatIznosa value={rezervacija.kolicina * rezervacija.jedNabavnaCijena} />
                                            </Table.Cell>
                                        ) : null}
                                        <Table.Cell textAlign="center">{moment(rezervacija.datumIsteka).format('DD.MM.YYYY.')}</Table.Cell>
                                        <Table.Cell textAlign="center">{moment(rezervacija.rokZaPodizanje).format('DD.MM.YYYY.')}</Table.Cell>
                                        {sessionStorage.userRole !== enums.tipKorisnika.DJELATNIK ? (
                                            <Table.Cell collapsing textAlign="right" style={styles.kolicinaIcijena}>
                                                <FormatIznosa value={rezervacija.kolicina * (rezervacija.jedProdajnaCijena - rezervacija.jedNabavnaCijena)} />
                                            </Table.Cell>
                                        ) : null}
                                        {sessionStorage.userRole !== enums.tipKorisnika.DJELATNIK ? (
                                            <Table.Cell collapsing textAlign="right" style={styles.kolicinaIcijena}>
                                                <FormatIznosa value={rezervacija.kolicina * rezervacija.jedProdajnaCijena} />
                                            </Table.Cell>
                                        ) : null}
                                    </Table.Row>
                                );
                            } else {
                                return null;
                            }
                        })}
                    </Table.Body>
                </Table>
            </Segment>
        );
    }
}

RezervacijaContent.propTypes = {
    zahtjevRezervacije: PropTypes.object.isRequired,
    showNevazece: PropTypes.bool.isRequired,
    imageUploadModal: PropTypes.node,
    enableRowChecking: PropTypes.bool,
    listOfDeselected: PropTypes.array,
    onRowSelected: PropTypes.func
};
