import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import FormatIznosa from '../../FormatIznosa';

const styles = {
    kolicinaIcijena: {
        fontFamily: 'monospace'
    }
};

export default class FixedRacunBodyCells extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Table.Cell textAlign="right" collapsing style={styles.kolicinaIcijena}>
                    {this.props.item.kolicina}
                </Table.Cell>
                <Table.Cell textAlign="right" collapsing style={styles.kolicinaIcijena}>
                    <FormatIznosa value={this.props.item.jedCijena} suffix={null} />
                </Table.Cell>
                <Table.Cell textAlign="right" collapsing style={styles.kolicinaIcijena}>
                    <FormatIznosa value={this.props.item.kolicina * this.props.item.jedCijena} />
                </Table.Cell>
            </React.Fragment>
        );
    }
}

FixedRacunBodyCells.propTypes = {
    item: PropTypes.object.isRequired
};
