import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Form } from 'semantic-ui-react';
import enums from '../../utils/Enums';
import localization from '../../locales/localization';
import InputField from './InputField';

const styles = {
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
};

class Usluga extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            naziv: this.props.item.naziv || '',
            mjernaJedinica: this.props.item.mjernaJedinica ? this.props.item.mjernaJedinica.id : this.props.mjerneJedinice[0].id,
            jedCijena: this.props.item.jedCijena || ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    handleChange(e, { name, value }) {
        this.setState({ [name]: value });
    }

    onSave() {
        let usluga = {
            naziv: this.state.naziv,
            mjernaJedinicaId: this.state.mjernaJedinica,
            jedCijena: parseFloat(this.state.jedCijena),
            tipArtikla: enums.tipArtikla.USLUGA
        };
        this.props.onSave(usluga);
    }

    render() {
        let mjerneJedinice = this.props.mjerneJedinice.map(mj => {
            return {
                key: mj.id,
                text: mj.naziv,
                value: mj.id
            };
        });
        return (
            <Form>
                <Form.Input
                    fluid
                    label={localization.usluga.naziv}
                    placeholder={localization.usluga.naziv}
                    name="naziv"
                    onChange={this.handleChange}
                    value={this.state.naziv}
                    required
                />
                <Form.Group widths="equal">
                    <Form.Select
                        fluid
                        label={localization.usluga.mjernaJedinica}
                        options={mjerneJedinice}
                        placeholder={localization.usluga.mjernaJedinica}
                        name="mjernaJedinica"
                        onChange={this.handleChange}
                        value={this.state.mjernaJedinica}
                        required
                        search
                    />

                    <Form.Field required>
                        <label>{localization.usluga.jedinicnaCijena}</label>
                        <InputField
                            label={localization.common.eur}
                            placeholder={localization.usluga.jedinicnaCijena}
                            name="jedCijena"
                            value={this.state.jedCijena}
                            onChange={this.handleChange}
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group style={styles.buttons}>
                    <Form.Button icon="cancel" content={localization.common.odustani} onClick={this.props.onCancel} />
                    <Form.Button icon="save" color="green" content={localization.common.spremi} onClick={this.onSave} />
                </Form.Group>
            </Form>
        );
    }
}

Usluga.propTypes = {
    item: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    mjerneJedinice: PropTypes.array.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {
        mjerneJedinice: state.mjerneJediniceReducer.mjerneJedinice || []
    };
};

let mapDispatchToProps = function(dispatch) {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Usluga);
