import $ from 'jquery';

import endpoint from '../environments/endpoints';

// TODO @Kec @Vranjes Parameter order?
export function getMeduskladisnicePaginated(q, page, pageSize, mjestoId, onSuccess, onError) {
    $.ajax({
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + sessionStorage.token
        },
        url: endpoint.apiEndpoint + 'meduskladisnice/paginated' +
            '?q=' + q +
            '&mjestoId=' + (mjestoId ? mjestoId : '') +
            '&page=' + page +
            '&pageSize=' + pageSize
    })
        .done(onSuccess)
        .fail(onError);
}
