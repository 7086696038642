import React from 'react';
import PropTypes from 'prop-types';
import { Header, Segment, Table } from 'semantic-ui-react';

import localization from '../../../locales/localization';
import FormatIznosa from '../../common_components/FormatIznosa';

const styles = {
    kolicinaIcijena: {
        fontFamily: 'monospace'
    }
};

export default class ArhiviraneProcjeneContent extends React.Component {
    render() {
        return (
            <Segment>
                <Header as="h4">{localization.dnevnaEvidencija.arhiviraneProcjene}</Header>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>{localization.artikl.sifra}</Table.HeaderCell>
                            <Table.HeaderCell>{localization.artikl.naziv}</Table.HeaderCell>
                            <Table.HeaderCell>{localization.artikl.serijskiBroj}</Table.HeaderCell>
                            <Table.HeaderCell>{localization.artikl.kategorija}</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center" collapsing>
                                {localization.common.kolicina}
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center" collapsing>
                                {localization.procjena.ocekivanaJedinicnaVrijednost}
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center" collapsing>
                                {localization.common.vrijednost}
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.props.procjene.map(procjena => {
                            return (
                                <Table.Row key={procjena.id}>
                                    <Table.Cell>{procjena.predmet.sifra}</Table.Cell>
                                    <Table.Cell>{procjena.predmet.naziv}</Table.Cell>
                                    <Table.Cell>{procjena.predmet.serijskiBroj || '-'}</Table.Cell>
                                    <Table.Cell>
                                        {procjena.predmet.kategorija.ime + (procjena.predmet.potkategorija ? ' / ' + procjena.predmet.potkategorija.ime : '')}
                                    </Table.Cell>
                                    <Table.Cell style={styles.kolicinaIcijena} textAlign="right" collapsing>
                                        {procjena.kolicina}
                                    </Table.Cell>
                                    <Table.Cell style={styles.kolicinaIcijena} textAlign="right" collapsing>
                                        <FormatIznosa value={procjena.ocekivanaJedCijena} suffix={null} />
                                    </Table.Cell>
                                    <Table.Cell style={styles.kolicinaIcijena} textAlign="right" collapsing>
                                        <FormatIznosa value={procjena.kolicina * procjena.ocekivanaJedCijena} />
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            </Segment>
        );
    }
}

ArhiviraneProcjeneContent.propTypes = {
    procjene: PropTypes.array.isRequired
};
