import React from 'react';
import PropTypes from 'prop-types';
import localization from '../../../locales/localization';
import enums from '../../../utils/Enums';
import { formatNumber } from '../../../utils/PhoneNumberFormatter';

const styles = {
    container: {
        border: 'thin solid black',
        borderRadius: 16,
        padding: 16
    },
    podaci: {
        fontSize: 10,
        margin: 0
    }
};

class ZaglavljeKlijenta extends React.Component {
    getIdBrojTipLabel(idBrojTip) {
        switch (idBrojTip) {
            case enums.idBrojTip.BR_OSOBNE:
                return localization.printingComponents.common.brojOsobne;
            case enums.idBrojTip.BR_PUT:
                return localization.printingComponents.common.brojPutovnice;
            default:
                return localization.printingComponents.common.oib;
        }
    }

    render() {
        return (
            <div style={styles.container}>
                {this.props.klijent ? (
                    <React.Fragment>
                        <h5>{this.props.klijent.puniNaziv.toUpperCase()}</h5>
                        <p style={styles.podaci}>
                            {this.getIdBrojTipLabel(this.props.klijent.idBrojTip) + ': '}
                            <b>{this.props.klijent.idBroj}</b>
                        </p>
                        {this.props.klijent.adresa ? (
                            <p style={styles.podaci}>
                                <b>{this.props.klijent.adresa}</b>
                            </p>
                        ) : null}
                        {this.props.pokaziBrojTelefona ? (
                            this.props.klijent.kontaktBroj ? (
                                <p style={styles.podaci}>
                                    <b>{formatNumber(this.props.klijent.kontaktBroj)}</b>
                                </p>
                            ) : (
                                <p style={styles.podaci}>
                                    <b>{this.props.klijent.kontaktEmail}</b>
                                </p>
                            )
                        ) : null}
                        {this.props.klijent.postanskiBrojNaselje ? (
                            <p style={styles.podaci}>
                                <b>{this.props.klijent.postanskiBrojNaselje}</b>
                            </p>
                        ) : null}
                        <p style={styles.podaci}>
                            <b>{this.props.klijent.drzava}</b>
                        </p>
                    </React.Fragment>
                ) : (
                    <h5>{localization.printingComponents.common.privatnaOsoba.toUpperCase()}</h5>
                )}
            </div>
        );
    }
}

ZaglavljeKlijenta.propTypes = {
    klijent: PropTypes.object.isRequired,
    pokaziBrojTelefona: PropTypes.bool
};

export default ZaglavljeKlijenta;
