import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import localization from '../../locales/localization';
import { Button, Table } from 'semantic-ui-react';
import InputField from './InputField';
import * as SkladisteDataAccess from '../../data_access/SkladisteDataAccess';
import { bindActionCreators } from 'redux';
import icons from '../../utils/DefinedIcons';
import FormatIznosa from './FormatIznosa';

const styles = {
    container: {
        textAlign: 'center'
    },
    kolicinaIcijena: {
        fontFamily: 'monospace'
    },
    actionButtons: {
        marginTop: 16,
        display: 'flex',
        justifyContent: 'flex-end'
    }
};

class PredlaganjeProdajnihCijenaModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cijene: this.calculateInitialPrice()
        };
        this.calculateInitialPrice = this.calculateInitialPrice.bind(this);
        this.onChangeCijena = this.onChangeCijena.bind(this);
        this.onPredloziProdajneCijene = this.onPredloziProdajneCijene.bind(this);
    }

    calculateInitialPrice() {
        return this.props.ulazi.map(su => {
            return {
                skladisnaStavkaId: su.skladisnaStavkaId,
                jedProdajnaCijena: undefined
            };
        });
    }

    onChangeCijena(skladisnaStavkaId, e, { value }) {
        this.setState({
            cijene: this.state.cijene.map(c => {
                if (c.skladisnaStavkaId === skladisnaStavkaId) {
                    c.jedProdajnaCijena = value;
                }
                return c;
            })
        });
    }

    onPredloziProdajneCijene() {
        let prodajneCijene = this.state.cijene
            .map(c => {
                return {
                    skladisnaStavkaId: c.skladisnaStavkaId,
                    jedProdajnaCijena: parseFloat(c.jedProdajnaCijena)
                };
            })
            .filter(c => c.jedProdajnaCijena && c.jedProdajnaCijena > 0);
        this.props.skladisteDataAccess.prijedlogProdajnihCijena(prodajneCijene, this.props.closeModal);
    }

    render() {
        return (
            <div style={styles.container}>
                <Table celled structured stackable selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>{localization.artikl.sifra}</Table.HeaderCell>
                            <Table.HeaderCell>{localization.artikl.naziv}</Table.HeaderCell>
                            <Table.HeaderCell>{localization.artikl.serijskiBroj}</Table.HeaderCell>
                            <Table.HeaderCell collapsing textAlign="center">
                                {localization.otkup.jedinicnaCijenaOtkupa}
                            </Table.HeaderCell>
                            <Table.HeaderCell collapsing textAlign="center">
                                {localization.otkup.prijedlogProdajneCijene}
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.props.ulazi.map(su => {
                            let cijena = this.state.cijene.find(c => c.skladisnaStavkaId === su.skladisnaStavkaId).jedProdajnaCijena || '';
                            return (
                                <Table.Row key={su.predmet.sifra}>
                                    <Table.Cell>{su.predmet.sifra}</Table.Cell>
                                    <Table.Cell>{su.predmet.naziv}</Table.Cell>
                                    <Table.Cell>{su.predmet.serijskiBroj || '-'}</Table.Cell>
                                    <Table.Cell textAlign="right" collapsing style={styles.kolicinaIcijena}>
                                        <FormatIznosa value={su.jedNabavnaCijena} />
                                    </Table.Cell>
                                    <Table.Cell collapsing>
                                        <InputField
                                            label={localization.common.eur}
                                            placeholder={localization.common.cijena}
                                            name="cijena"
                                            value={cijena}
                                            onChange={this.onChangeCijena.bind(this, su.skladisnaStavkaId)}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
                <div style={styles.actionButtons}>
                    <Button icon={icons.otkup} color="green" content={localization.otkup.predloziProdajneCijene} onClick={this.onPredloziProdajneCijene} />
                </div>
            </div>
        );
    }
}

PredlaganjeProdajnihCijenaModal.propTypes = {
    ulazi: PropTypes.array.isRequired,
    skladisteDataAccess: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {};
};

let mapDispatchToProps = function(dispatch) {
    return {
        skladisteDataAccess: bindActionCreators(SkladisteDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PredlaganjeProdajnihCijenaModal);
