import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import PrintProvider, { Print, NoPrint } from 'react-easy-print';
import QRCode from 'qrcode.react';
import ZaglavljeTvrtke from './common_components/ZaglavljeTvrtke';
import ZaglavljeKlijenta from './common_components/ZaglavljeKlijenta';
import { Button, Table } from 'semantic-ui-react';
import localization from '../../locales/localization';
import DatumIMjestoRacun from './common_components/DatumIMjestoRacun';
import ValutaNacinPlacanjaRacun from './common_components/ValutaNacinPlacanjaRacun';
import FiskalizacijaPodaciRacun from './common_components/FiskalizacijaPodaciRacun';
import enums from '../../utils/Enums';
import moment from 'moment';
import FormatIznosa from '../common_components/FormatIznosa';
import { calculateBrojRacuna } from '../../utils/BrojRacunaCalculator';

const styles = {
    container: {
        padding: 24
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    zaglavlja: {
        width: '50%'
    },
    zaglavljeTvrtke: {
        paddingBottom: 24
    },
    poslovnaJedinica: {
        fontSize: 14
    },
    datumImjesto: {
        paddingBottom: 8
    },
    p: {
        fontSize: 10
    },
    title: {
        paddingTop: 48
    },
    table: {
        fontSize: 'xx-small'
    },
    tableCell: {
        paddingTop: 'unset',
        paddingBottom: 'unset'
    },
    tableCellUkupno: {
        paddingTop: 'unset',
        paddingBottom: 'unset',
        verticalAlign: 'top'
    },
    tableCellKol: {
        paddingTop: 'unset',
        paddingBottom: 'unset',
        fontFamily: 'monospace'
    },
    rekapitulacijaPDVa: {
        fontSize: 'xx-small'
    },
    footerContainer: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: 16,
        paddingBottom: 16
    },
    qrCodeContainer: {
        marginRight: 16  
    },
    mp: {
        fontSize: 10,
        textAlign: 'center'
    },
    footerTvrtka: {
        textAlign: 'center'
    },
    printButton: {
        textAlign: 'center',
        paddingBottom: 8
    }
};

export default class Racun extends React.Component {
    constructor(props) {
        super(props);
        this.defineStarsList = this.defineStarsList.bind(this);
        let racun = JSON.parse(sessionStorage.getItem(this.props.match.params.id));
        this.state = {
            document: racun,
            starsList: this.defineStarsList(racun)
        };
        this.printDocument = this.printDocument.bind(this);
        this.detectNacinPlacanja = this.detectNacinPlacanja.bind(this);
    }

    printDocument() {
        window.print();
    }

    defineStarsList(racun) {
        let stars = [];
        racun.skladisniIzlazi.forEach((si, index) => {
            if (si.tekstZakonaPDV) {
                stars.push({
                    tipPoreza: si.tipPoreza,
                    tekst: si.tekstZakonaPDV
                });
            }
        });
        return _.uniqWith(stars, _.isEqual);
    }

    detectNacinPlacanja() {
        if (this.state.document.tipRacuna === enums.tipRacuna.FISKALNI_RACUN) {
            if (this.state.document.karticnoPlacanje === null) {
                return localization.printingComponents.racun.gotovina;
            } else if (this.state.document.iznosGotovina === null) {
                return localization.printingComponents.racun.kartica;
            } else {
                return localization.printingComponents.racun.gotovinaIkartica;
            }
        } else {
            return localization.printingComponents.racun.transakcijskiRacun;
        }
    }

    calculateUkupno(artikli) {
        let ukupnoRabljeni = 0,
            ukupnoBezPoreza = 0,
            ukupno = 0;
        artikli.forEach(a => {
            if (this.state.document.rbrStorniranogRacuna) {
                a.kolicina = -a.kolicina;
            }
            ukupno += a.kolicina * a.jedCijena;
            if (a.tipPoreza !== enums.tipPoreza.RABLJENI_PREDMET) {
                ukupnoBezPoreza += (a.kolicina * a.jedCijena) / (1 + a.postotakPDV / 100);
            } else {
                ukupnoRabljeni += a.kolicina * a.jedCijena;
            }
        });
        return [ukupnoRabljeni, ukupnoBezPoreza, ukupno];
    }

    composeFiskalUrl(zki, datumIVrijeme, iznos) {
        datumIVrijeme = moment(datumIVrijeme).format('YYYYMMDD_HHmm');
        iznos = iznos.toFixed(2).replace('.', '');
        return `https://porezna.gov.hr/rn?zki=${zki}&datv=${datumIVrijeme}&izn=${iznos}`;
    }

    render() {
        let prikazatiDodatnoObjasnjenje =
            this.state.starsList.findIndex(sl => sl.tipPoreza === enums.tipPoreza.RABLJENI_PREDMET || sl.tipPoreza === enums.tipPoreza.INVESTICIJSKO_ZLATO) >
            -1;
        let [ukupnoRabljeni, ukupnoBezPoreza, ukupno] = this.calculateUkupno([...this.state.document.skladisniIzlazi]);
        return (
            <PrintProvider>
                <Print>
                    <div style={styles.container}>
                        <div style={styles.headerContainer}>
                            <div style={styles.zaglavlja}>
                                {localization.printingComponents.common.prodavatelj + ':'}
                                <div style={styles.zaglavljeTvrtke}>
                                    <ZaglavljeTvrtke firma={this.state.document.firma} small={true} />
                                </div>
                                <p style={styles.poslovnaJedinica}>
                                    {localization.printingComponents.common.poslovnaJedinica + ' ' + this.state.document.poslovnica.brojPoslovnice + ': '}
                                    <b>{this.state.document.poslovnica.naziv}</b>
                                </p>
                                <div style={styles.datumImjesto}>
                                    <DatumIMjestoRacun
                                        datum={moment(this.state.document.datumIVrijeme).format('DD.MM.YYYY.')}
                                        mjesto={this.state.document.poslovnica.naselje}
                                        vrijeme={moment(this.state.document.datumIVrijeme).format('HH:mm:ss')}
                                    />
                                </div>
                                <div style={styles.datumImjesto}>
                                    <ValutaNacinPlacanjaRacun
                                        valutaPlacanja={moment(this.state.document.datumIVrijeme).format('DD.MM.YYYY.')}
                                        nacinPlacanja={this.detectNacinPlacanja()}
                                        datumOtpreme={moment(this.state.document.datumIVrijeme).format('DD.MM.YYYY.')}
                                        nacinOtpreme={'OSOBNO PREUZIMANJE'}
                                    />
                                </div>
                            </div>
                            <div style={styles.zaglavlja}>
                                {localization.printingComponents.common.kupac + ':'}
                                <br />
                                <br />
                                <ZaglavljeKlijenta klijent={this.state.document.klijent} />
                                <div style={styles.title}>
                                    <h3>
                                        {localization.printingComponents.racun.naslov +
                                            ' ' +
                                            localization.printingComponents.common.br +
                                            ' ' +
                                            calculateBrojRacuna(this.state.document)}
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <Table style={styles.table}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={styles.tableCell} />
                                    <Table.HeaderCell style={styles.tableCell}>{localization.artikl.sifra}</Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell}>{localization.artikl.naziv}</Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell} textAlign="center">
                                        {localization.artikl.mjernaJedinica}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell} textAlign="center">
                                        {localization.common.kolicina}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell} textAlign="center">
                                        {localization.common.cijena}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell} textAlign="center">
                                        {localization.common.vrijednost}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell} textAlign="center">
                                        {localization.common.popust}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell} textAlign="center" collapsing>
                                        {localization.common.pdv + ' ' + localization.common.percentageSign}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCell} textAlign="center">
                                        {localization.common.iznos}
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.state.document.skladisniIzlazi.map((si, index) => {
                                    if (this.state.document.rbrStorniranogRacuna) {
                                        si.kolicina = -si.kolicina;
                                    }
                                    return (
                                        <Table.Row key={index}>
                                            <Table.Cell style={styles.tableCell}>{index + 1}</Table.Cell>
                                            {this.state.starsList.length > 1 ? (
                                                <Table.Cell style={styles.tableCell}>
                                                    {si.sifraArtikla +
                                                        ' ' +
                                                        '*'.repeat(this.state.starsList.findIndex(sl => sl.tipPoreza === si.tipPoreza) + 1)}
                                                </Table.Cell>
                                            ) : (
                                                <Table.Cell style={styles.tableCell}>{si.sifraArtikla}</Table.Cell>
                                            )}
                                            <Table.Cell style={styles.tableCell} singleLine>
                                                <b>{si.nazivArtikla}</b>
                                            </Table.Cell>
                                            <Table.Cell style={styles.tableCell} textAlign="center" collapsing>
                                                {si.mjernaJedinica}
                                            </Table.Cell>
                                            <Table.Cell style={styles.tableCellKol} textAlign="right" collapsing>
                                                {si.kolicina}
                                            </Table.Cell>
                                            <Table.Cell style={styles.tableCellKol} textAlign="right" collapsing>
                                                <FormatIznosa value={si.jedCijena} suffix={null} />
                                            </Table.Cell>
                                            <Table.Cell style={styles.tableCellKol} textAlign="right" collapsing>
                                                <FormatIznosa value={si.kolicina * si.jedCijena} suffix={null} />
                                            </Table.Cell>
                                            <Table.Cell style={styles.tableCellKol} textAlign="right" collapsing>
                                                {(0).toFixed(2)}
                                            </Table.Cell>
                                            <Table.Cell style={styles.tableCellKol} textAlign="right" collapsing>
                                                {si.tipPoreza === enums.tipPoreza.RABLJENI_PREDMET ? (
                                                    '*'.repeat(this.state.starsList.findIndex(sl => sl.tipPoreza === si.tipPoreza) + 1)
                                                ) : si.tipPoreza === enums.tipPoreza.INVESTICIJSKO_ZLATO ? (
                                                    <React.Fragment>
                                                        {'*'.repeat(this.state.starsList.findIndex(sl => sl.tipPoreza === si.tipPoreza) + 1) + ' '}
                                                        <FormatIznosa value={si.postotakPDV} suffix={null} />
                                                    </React.Fragment>
                                                ) : (
                                                    <FormatIznosa value={si.postotakPDV} suffix={null} />
                                                )}
                                            </Table.Cell>
                                            <Table.Cell style={styles.tableCellKol} textAlign="right" collapsing>
                                                <FormatIznosa value={si.kolicina * si.jedCijena} />
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                            <Table.Footer>
                                <Table.Row style={styles.rekapitulacijaPDVa}>
                                    <Table.HeaderCell style={styles.tableCell} colSpan={7} rowSpan={5} />
                                    <Table.HeaderCell style={styles.tableCell} colSpan={2} collapsing>
                                        {localization.printingComponents.racun.ukupnoRabljeniPredmeti + ':'}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCellKol} textAlign="right" collapsing>
                                        <FormatIznosa value={ukupnoRabljeni} suffix={null} />
                                    </Table.HeaderCell>
                                </Table.Row>
                                <Table.Row style={styles.rekapitulacijaPDVa}>
                                    <Table.HeaderCell style={styles.tableCell} colSpan={2} collapsing>
                                        {localization.printingComponents.racun.ukupnoBezPDVa + ':'}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCellKol} textAlign="right" collapsing>
                                        <FormatIznosa value={ukupnoBezPoreza} suffix={null} />
                                    </Table.HeaderCell>
                                </Table.Row>
                                <Table.Row style={styles.rekapitulacijaPDVa}>
                                    <Table.HeaderCell style={styles.tableCell} colSpan={2} collapsing>
                                        {localization.common.popust + ':'}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCellKol} textAlign="right" collapsing>
                                        {(0).toFixed(2)}
                                    </Table.HeaderCell>
                                </Table.Row>
                                <Table.Row style={styles.rekapitulacijaPDVa}>
                                    <Table.HeaderCell style={styles.tableCell} colSpan={2} collapsing>
                                        {localization.printingComponents.racun.ukupnoBezPDVaIPopusta + ':'}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCellKol} textAlign="right" collapsing>
                                        <FormatIznosa value={ukupnoBezPoreza} suffix={null} />
                                    </Table.HeaderCell>
                                </Table.Row>
                                <Table.Row style={styles.rekapitulacijaPDVa}>
                                    <Table.HeaderCell style={styles.tableCell} colSpan={2} collapsing>
                                        {localization.common.pdv + ':'}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCellKol} textAlign="right" collapsing>
                                        {prikazatiDodatnoObjasnjenje ? '*'.repeat(this.state.starsList.length + 1) + ' ' : ''}
                                        <FormatIznosa value={ukupno - ukupnoBezPoreza - ukupnoRabljeni} suffix={null} />
                                    </Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.HeaderCell style={styles.tableCell} colSpan={7} />
                                    <Table.HeaderCell style={styles.tableCellUkupno} colSpan={2}>
                                        <b>{localization.common.ukupno + ':'}</b>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={styles.tableCellKol} textAlign="right" collapsing>
                                        <b>
                                            <FormatIznosa value={ukupno} /><br />
                                        </b>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        </Table>
                        {this.state.document.tipRacuna === enums.tipRacuna.FISKALNI_RACUN &&
                        this.state.document.iznosGotovina !== null &&
                        this.state.document.karticnoPlacanje !== null ? (
                            <div>
                                <p style={styles.p}>
                                    {localization.printingComponents.racun.gotovina + ': '}
                                    <b>
                                        <FormatIznosa value={this.state.document.iznosGotovina} />
                                    </b>
                                    <br />
                                    {localization.printingComponents.racun.kartica + ': '}
                                    <b>
                                        <FormatIznosa value={this.state.document.karticnoPlacanje.iznosKarticno} />
                                    </b>
                                </p>
                                <br />
                            </div>
                        ) : null}
                        <div>
                            {this.state.starsList.filter(s => s.tipPoreza === enums.tipPoreza.RABLJENI_PREDMET).length ? (
                                <p style={styles.p}>{localization.printingComponents.racun.izjavaVidenoKupljeno}</p>
                            ) : null}
                            {this.state.document.tipRacuna === enums.tipRacuna.TRANSAKCIJSKI_RACUN && (
                                <p style={styles.p}>{localization.printingComponents.racun.napomenaTransakcijskiRacun}</p>
                            )}
                            {this.state.starsList.map((s, index) => {
                                if (
                                    this.state.starsList.length > 1 ||
                                    s.tipPoreza === enums.tipPoreza.RABLJENI_PREDMET ||
                                    s.tipPoreza === enums.tipPoreza.INVESTICIJSKO_ZLATO
                                ) {
                                    return (
                                        <p style={styles.p}>
                                            <b>{'*'.repeat(index + 1) + ' ' + s.tekst}</b>
                                        </p>
                                    );
                                } else {
                                    return (
                                        <p style={styles.p}>
                                            <b>{s.tekst}</b>
                                        </p>
                                    );
                                }
                            })}
                            {prikazatiDodatnoObjasnjenje ? (
                                <p style={styles.p}>
                                    <b>{'*'.repeat(this.state.starsList.length + 1) + ' ' + localization.printingComponents.racun.dodatnoPojasnjenjePDVa}</b>
                                </p>
                            ) : null}
                        </div>
                        {this.state.document.sluzbenaNapomena ? (
                            <React.Fragment>
                                <br />
                                <div>
                                    <p style={styles.p}>
                                        <b>{localization.printingComponents.common.sluzbenaNapomena + ':'}</b>
                                    </p>
                                    <p style={styles.p}>{this.state.document.sluzbenaNapomena}</p>
                                </div>
                            </React.Fragment>
                        ) : null}
                        <div style={styles.footerContainer}>
                            {this.state.document.fiskal &&
                                <div style={styles.qrCodeContainer}>
                                    <QRCode value={this.composeFiskalUrl(this.state.document.fiskal.zki, this.state.document.datumIVrijeme, ukupno)} size={100} />
                                </div>}
                            <FiskalizacijaPodaciRacun
                                oznakaOperatera={this.state.document.user.ime + ' ' + this.state.document.user.prezime}
                                zki={this.state.document.fiskal ? this.state.document.fiskal.zki : undefined}
                                jir={this.state.document.fiskal ? this.state.document.fiskal.jir : undefined}
                            />
                        </div>
                        <p style={styles.mp}>{localization.printingComponents.common.dokumentObradenElektronickimPutem}</p>
                        <div style={styles.footerTvrtka}>
                            <p style={styles.p}>{this.state.document.firma.oFirmi}</p>
                        </div>
                    </div>
                </Print>
                <NoPrint>
                    <div style={styles.printButton}>
                        <Button color="green" icon="print" size="massive" content={localization.common.ispis} onClick={this.printDocument} />
                    </div>
                </NoPrint>
            </PrintProvider>
        );
    }
}

Racun.propTypes = {
    match: PropTypes.object.isRequired
};
