import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Form, Header, Modal, Segment } from 'semantic-ui-react';

import localization from '../../locales/localization';
import * as FlagsDataAccess from '../../data_access/FlagsDataAccess';

const styles = {
    deleteButton: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    container: {
        textAlign: 'left'
    },
    actionButtons: {
        marginTop: 16,
        display: 'flex',
        justifyContent: 'flex-end'
    }
};

class Flag extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            naziv: this.props.flag.naziv,
            activeModal: false,
            enableSaveButton: false
        };
        this.onChange = this.onChange.bind(this);
        this.enableSaveButton = this.enableSaveButton.bind(this);
        this.checkFlagRequiredFields = this.checkFlagRequiredFields.bind(this);
        this.showHideModal = this.showHideModal.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            naziv: nextProps.flag.naziv,
            activeModal: false,
            enableSaveButton: false
        });
    }

    onChange(e, { name, value }) {
        this.setState({ [name]: value }, this.enableSaveButton);
    }

    enableSaveButton() {
        this.setState({ enableSaveButton: this.checkFlagRequiredFields() });
    }

    checkFlagRequiredFields() {
        if (!this.state.naziv) {
            return false;
        }
        return true;
    }

    showHideModal(value) {
        this.setState({ activeModal: value });
    }

    onCancel() {
        if (this.props.novi) {
            this.props.onDelete();
        } else {
            this.setState({
                naziv: this.props.flag.naziv,
                activeModal: false,
                enableSaveButton: false
            });
        }
    }

    onSave() {
        let flag = {
            naziv: this.state.naziv
        };
        if (this.props.novi) {
            this.props.flagsDataAccess.createFlag(flag);
        } else {
            this.props.flagsDataAccess.updateFlag(this.props.flag.id, flag);
        }
    }

    onDelete() {
        this.props.flagsDataAccess.deleteFlag(this.props.flag.id);
    }

    render() {
        return (
            <Segment>
                {!this.props.novi ? (
                    <div style={styles.deleteButton}>
                        <Modal
                            open={this.state.activeModal}
                            trigger={<Button icon="delete" color="red" basic size="small" onClick={this.showHideModal.bind(this, true)} />}
                        >
                            <Header icon="trash" content={localization.common.potvrdaBrisanja} />
                            <Modal.Content>
                                <p>{localization.postavke.brisanjeOznakePotvrda}</p>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button icon="remove" content={localization.common.odustani} onClick={this.showHideModal.bind(this, false)} />
                                <Button icon="trash" color="red" content={localization.common.obrisi} onClick={this.onDelete} />
                            </Modal.Actions>
                        </Modal>
                    </div>
                ) : null}
                <Form style={styles.container}>
                    <Form.Input fluid label={localization.postavke.nazivOznake} name="naziv" onChange={this.onChange} value={this.state.naziv} required />
                </Form>
                <div style={styles.actionButtons}>
                    <Button
                        icon="cancel"
                        content={this.props.novi ? localization.common.odustani : localization.common.vratiNaSpremljeno}
                        onClick={this.onCancel}
                    />
                    <Button icon="save" color="green" disabled={!this.state.enableSaveButton} content={localization.common.spremi} onClick={this.onSave} />
                </div>
            </Segment>
        );
    }
}

Flag.propTypes = {
    novi: PropTypes.bool,
    flag: PropTypes.object.isRequired,
    flagsDataAccess: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired
};

let mapStateToProps = function(state, ownProps) {
    return {};
};

let mapDispatchToProps = function(dispatch) {
    return {
        flagsDataAccess: bindActionCreators(FlagsDataAccess, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Flag);
