import React from 'react';
import PropTypes from 'prop-types';
import { Button, Table } from 'semantic-ui-react';
import InputField from '../../InputField';
import localization from '../../../../locales/localization';
import FormatIznosa from '../../FormatIznosa';

const styles = {
    kolicinaIcijena: {
        fontFamily: 'monospace',
    },
};

export default class EditableRacunBodyCells extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Table.Cell>{this.props.item.kategorija.ime + (this.props.item.potkategorija ? ' / ' + this.props.item.potkategorija.ime : '')}</Table.Cell>
                <Table.Cell collapsing>
                    <InputField
                        label={this.props.item.mjernaJedinica.naziv}
                        placeholder={localization.common.kol}
                        name="kolicina"
                        value={this.props.item.kolicina}
                        onChange={this.props.onChange.bind(this, this.props.item.sifra)}
                        isCjelobrojno={this.props.item.mjernaJedinica.isCjelobrojna}
                        maxValue={this.props.item.maxKolicina}
                    />
                </Table.Cell>
                <Table.Cell collapsing textAlign="right" style={styles.kolicinaIcijena}>
                    {this.props.item.maxKolicina}
                </Table.Cell>
                <Table.Cell collapsing textAlign="right" style={styles.kolicinaIcijena}>
                    {this.props.item.cijena ? <FormatIznosa value={this.props.item.cijena} /> : '-'}
                </Table.Cell>
                <Table.Cell textAlign="right" collapsing style={styles.kolicinaIcijena}>
                    <b>
                        {this.props.item.kolicina && this.props.item.cijena ? <FormatIznosa value={this.props.item.kolicina * this.props.item.cijena} /> : '-'}
                    </b>
                </Table.Cell>
                <Table.Cell collapsing>
                    <Button icon="delete" basic size="small" onClick={this.props.onRemoveItem.bind(this, this.props.item)} />
                </Table.Cell>
            </React.Fragment>
        );
    }
}

EditableRacunBodyCells.propTypes = {
    item: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onRemoveItem: PropTypes.func.isRequired,
};
