export default {
    postanskiBrojevi: [
        { naselje: 'Aržano', postanskiBroj: '21246' },
        { naselje: 'Babina Greda', postanskiBroj: '32276' },
        { naselje: 'Babino Polje', postanskiBroj: '20225' },
        { naselje: 'Baderna', postanskiBroj: '52445' },
        { naselje: 'Badljevina', postanskiBroj: '34552' },
        { naselje: 'Bakar', postanskiBroj: '51222' },
        { naselje: 'Bakarac', postanskiBroj: '51261' },
        { naselje: 'Bale (Valle)', postanskiBroj: '52211' },
        { naselje: 'Banova Jaruga', postanskiBroj: '44321' },
        { naselje: 'Banovci', postanskiBroj: '32247' },
        { naselje: 'Bapska', postanskiBroj: '32235' },
        { naselje: 'Barban', postanskiBroj: '52207' },
        { naselje: 'Barilović', postanskiBroj: '47252' },
        { naselje: 'Baška', postanskiBroj: '51523' },
        { naselje: 'Baška Voda', postanskiBroj: '21320' },
        { naselje: 'Bebrina', postanskiBroj: '35254' },
        { naselje: 'Bedekovčina', postanskiBroj: '49221' },
        { naselje: 'Bedenica', postanskiBroj: '10381' },
        { naselje: 'Bednja', postanskiBroj: '42253' },
        { naselje: 'Bektež', postanskiBroj: '34343' },
        { naselje: 'Belec', postanskiBroj: '49254' },
        { naselje: 'Belej', postanskiBroj: '51555' },
        { naselje: 'Beli', postanskiBroj: '51559' },
        { naselje: 'Belica', postanskiBroj: '40319' },
        { naselje: 'Belišće', postanskiBroj: '31551' },
        { naselje: 'Belovar', postanskiBroj: '10363' },
        { naselje: 'Benkovac', postanskiBroj: '23420' },
        { naselje: 'Berek', postanskiBroj: '43232' },
        { naselje: 'Beretinec', postanskiBroj: '42201' },
        { naselje: 'Bestovje', postanskiBroj: '10437' },
        { naselje: 'Betina', postanskiBroj: '22244' },
        { naselje: 'Bibinje', postanskiBroj: '23205' },
        { naselje: 'Bilje', postanskiBroj: '31327' },
        { naselje: 'Biograd na moru', postanskiBroj: '23210' },
        { naselje: 'Bjelovar', postanskiBroj: '43000' },
        { naselje: 'Blace', postanskiBroj: '20357' },
        { naselje: 'Blato', postanskiBroj: '20271' },
        { naselje: 'Blato na Cetini', postanskiBroj: '21254' },
        { naselje: 'Blinjski Kut', postanskiBroj: '44211' },
        { naselje: 'Blizna Donja', postanskiBroj: '21228' },
        { naselje: 'Bobota', postanskiBroj: '32225' },
        { naselje: 'Bol', postanskiBroj: '21420' },
        { naselje: 'Boljun', postanskiBroj: '52434' },
        { naselje: 'Boraja', postanskiBroj: '22206' },
        { naselje: 'Borovo', postanskiBroj: '32227' },
        { naselje: 'Bosiljevo', postanskiBroj: '47251' },
        { naselje: 'Bošnjaci', postanskiBroj: '32275' },
        { naselje: 'Božava', postanskiBroj: '23286' },
        { naselje: 'Bračevci', postanskiBroj: '31423' },
        { naselje: 'Brbinj', postanskiBroj: '23285' },
        { naselje: 'Bregana', postanskiBroj: '10432' },
        { naselje: 'Brela', postanskiBroj: '21322' },
        { naselje: 'Brestovac', postanskiBroj: '34322' },
        { naselje: 'Brestovec Orehovički', postanskiBroj: '49228' },
        { naselje: 'Breznički Hum', postanskiBroj: '42225' },
        { naselje: 'Brezovica', postanskiBroj: '10257' },
        { naselje: 'Bribir', postanskiBroj: '51253' },
        { naselje: 'Brinje', postanskiBroj: '53260' },
        { naselje: 'Brod Moravice', postanskiBroj: '51312' },
        { naselje: 'Brod na Kupi', postanskiBroj: '51301' },
        { naselje: 'Brseč', postanskiBroj: '51418' },
        { naselje: 'Bršadin', postanskiBroj: '32222' },
        { naselje: 'Brtonigla (Verteneglio)', postanskiBroj: '52474' },
        { naselje: 'Brusje', postanskiBroj: '21454' },
        { naselje: 'Brušane', postanskiBroj: '53206' },
        { naselje: 'Bučje', postanskiBroj: '34553' },
        { naselje: 'Budimci', postanskiBroj: '31432' },
        { naselje: 'Budinšćina', postanskiBroj: '49284' },
        { naselje: 'Buje (Buie)', postanskiBroj: '52460' },
        { naselje: 'Bukovlje', postanskiBroj: '35209' },
        { naselje: 'Bulina', cpostanskiBroj: '43273' },
        { naselje: 'Buševec', postanskiBroj: '10417' },
        { naselje: 'Buzet', postanskiBroj: '52420' },
        { naselje: 'Cabuna', postanskiBroj: '33412' },
        { naselje: 'Cavtat', postanskiBroj: '20210' },
        { naselje: 'Cerna', postanskiBroj: '32272' },
        { naselje: 'Cernik', postanskiBroj: '35404' },
        { naselje: 'Cerovac Vukmanički', postanskiBroj: '47241' },
        { naselje: 'Cerovlje', postanskiBroj: '52402' },
        { naselje: 'Cestica', postanskiBroj: '42208' },
        { naselje: 'Cetingrad', postanskiBroj: '47222' },
        { naselje: 'Cista Provo', postanskiBroj: '21256' },
        { naselje: 'Cista Velika', postanskiBroj: '21244' },
        { naselje: 'Cres', postanskiBroj: '51557' },
        { naselje: 'Crikvenica', postanskiBroj: '51260' },
        { naselje: 'Crivac', postanskiBroj: '21229' },
        { naselje: 'Crnac', postanskiBroj: '33507' },
        { naselje: 'Crni Lug', postanskiBroj: '51317' },
        { naselje: 'Čabar', postanskiBroj: '51306' },
        { naselje: 'Čačinci', postanskiBroj: '33514' },
        { naselje: 'Čađavica', postanskiBroj: '33523' },
        { naselje: 'Čaglin', postanskiBroj: '34350' },
        { naselje: 'Čakovci', postanskiBroj: '32238' },
        { naselje: 'Čakovec', postanskiBroj: '40000' },
        { naselje: 'Čara', postanskiBroj: '20273' },
        { naselje: 'Čazma', postanskiBroj: '43240' },
        { naselje: 'Čepin', postanskiBroj: '31431' },
        { naselje: 'Červar Porat', postanskiBroj: '52449' },
        { naselje: 'Čilipi', postanskiBroj: '20213' },
        { naselje: 'Čista Velika', postanskiBroj: '22214' },
        { naselje: 'Črnkovci', postanskiBroj: '31553' },
        { naselje: 'Ćunski', postanskiBroj: '51564' },
        { naselje: 'Darda', postanskiBroj: '31326' },
        { naselje: 'Daruvar', postanskiBroj: '43500' },
        { naselje: 'Dekanovec', postanskiBroj: '40318' },
        { naselje: 'Delnice', postanskiBroj: '51300' },
        { naselje: 'Desinić', postanskiBroj: '49216' },
        { naselje: 'Dežanovac', postanskiBroj: '43506' },
        { naselje: 'Dicmo', postanskiBroj: '21232' },
        { naselje: 'Divuša', postanskiBroj: '44435' },
        { naselje: 'Dobrinj', postanskiBroj: '51514' },
        { naselje: 'Doli', postanskiBroj: '20231' },
        { naselje: 'Donja Bistra', postanskiBroj: '10298' },
        { naselje: 'Donja Dubrava', postanskiBroj: '40328' },
        { naselje: 'Donja Lomnica', postanskiBroj: '10412' },
        { naselje: 'Donja Motičina', postanskiBroj: '31513' },
        { naselje: 'Donja Pušća', postanskiBroj: '10294' },
        { naselje: 'Donja Stubica', postanskiBroj: '49240' },
        { naselje: 'Donja Višnjica', postanskiBroj: '42255' },
        { naselje: 'Donja Voća', postanskiBroj: '42245' },
        { naselje: 'Donja Zelina', postanskiBroj: '10382' },
        { naselje: 'Donje Ogorje', postanskiBroj: '21206' },
        { naselje: 'Donje Pazarište', postanskiBroj: '53213' },
        { naselje: 'Donji Andrijevci', postanskiBroj: '35214' },
        { naselje: 'Donji Dolac', postanskiBroj: '21205' },
        { naselje: 'Donji Dragonožec', postanskiBroj: '10253' },
        { naselje: 'Donji Kraljevec', postanskiBroj: '40320' },
        { naselje: 'Donji Kukuruzari', postanskiBroj: '44431' },
        { naselje: 'Donji Lapac', postanskiBroj: '53250' },
        { naselje: 'Donji Martijanec', postanskiBroj: '42232' },
        { naselje: 'Donji Miholjac', postanskiBroj: '31540' },
        { naselje: 'Donji Muć', postanskiBroj: '21203' },
        { naselje: 'Donji Proložac', postanskiBroj: '21264' },
        { naselje: 'Donji Vidovec', postanskiBroj: '40327' },
        { naselje: 'Draga Bašćanska', postanskiBroj: '51522' },
        { naselje: 'Dragalić', postanskiBroj: '35428' },
        { naselje: 'Draganići', postanskiBroj: '47201' },
        { naselje: 'Dragljane', postanskiBroj: '21275' },
        { naselje: 'Dramalj', postanskiBroj: '51265' },
        { naselje: 'Draškovec', postanskiBroj: '40325' },
        { naselje: 'Drašnice', postanskiBroj: '21328' },
        { naselje: 'Dražice', postanskiBroj: '51218' },
        { naselje: 'Drenje', postanskiBroj: '31418' },
        { naselje: 'Drenovci', postanskiBroj: '32257' },
        { naselje: 'Drežnica', postanskiBroj: '47313' },
        { naselje: 'Drežnik Grad', postanskiBroj: '47246' },
        { naselje: 'Drinovci', postanskiBroj: '22324' },
        { naselje: 'Drivenik', postanskiBroj: '51242' },
        { naselje: 'Drniš', postanskiBroj: '22320' },
        { naselje: 'Drnje', postanskiBroj: '48322' },
        { naselje: 'Drvenik', postanskiBroj: '21333' },
        { naselje: 'Drvenik Veliki', postanskiBroj: '21225' },
        { naselje: 'Dubranec', postanskiBroj: '10418' },
        { naselje: 'Dubrava', postanskiBroj: '10342' },
        { naselje: 'Dubravica', postanskiBroj: '10293' },
        { naselje: 'Dubravka', postanskiBroj: '20216' },
        { naselje: 'Dubrovnik', postanskiBroj: '20000' },
        { naselje: 'Duga Resa', postanskiBroj: '47250' },
        { naselje: 'Dugi Rat', postanskiBroj: '21315' },
        { naselje: 'Dugo Selo', postanskiBroj: '10370' },
        { naselje: 'Dugopolje', postanskiBroj: '21204' },
        { naselje: 'Dvor', postanskiBroj: '44440' },
        { naselje: 'Đakovo', postanskiBroj: '31400' },
        { naselje: 'Đelekovec', postanskiBroj: '48316' },
        { naselje: 'Đeletovci', postanskiBroj: '32244' },
        { naselje: 'Đulovac', postanskiBroj: '43532' },
        { naselje: 'Đurđenovac', postanskiBroj: '31511' },
        { naselje: 'Đurđevac', postanskiBroj: '48350' },
        { naselje: 'Đurići', postanskiBroj: '32263' },
        { naselje: 'Đurmanec', postanskiBroj: '49225' },
        { naselje: 'Farkaševac', postanskiBroj: '10344' },
        { naselje: 'Fažana (Fasana)', postanskiBroj: '52212' },
        { naselje: 'Ferdinandovac', postanskiBroj: '48356' },
        { naselje: 'Feričanci', postanskiBroj: '31512' },
        { naselje: 'Funtana (Fontane)', postanskiBroj: '52452' },
        { naselje: 'Fužine', postanskiBroj: '51322' },
        { naselje: 'Gaboš', postanskiBroj: '32212' },
        { naselje: 'Galižana (Gallesano)', postanskiBroj: '52216' },
        { naselje: 'Garešnica', postanskiBroj: '43280' },
        { naselje: 'Gat', postanskiBroj: '31554' },
        { naselje: 'Gata', postanskiBroj: '21253' },
        { naselje: 'Gdinj', postanskiBroj: '21467' },
        { naselje: 'Generalski Stol', postanskiBroj: '47262' },
        { naselje: 'Gerovo', postanskiBroj: '51304' },
        { naselje: 'Glina', postanskiBroj: '44400' },
        { naselje: 'Gola', postanskiBroj: '48331' },
        { naselje: 'Golubić', postanskiBroj: '22301' },
        { naselje: 'Gomirje', postanskiBroj: '51327' },
        { naselje: 'Gorica Svetojanska', postanskiBroj: '10453' },
        { naselje: 'Goričan', postanskiBroj: '40324' },
        { naselje: 'Gorjani', postanskiBroj: '31422' },
        { naselje: 'Gornja Rijeka', postanskiBroj: '48268' },
        { naselje: 'Gornja Stubica', postanskiBroj: '49245' },
        { naselje: 'Gornje Bazje', postanskiBroj: '33407' },
        { naselje: 'Gornje Jesenje', postanskiBroj: '49233' },
        { naselje: 'Gornje Zagorje', postanskiBroj: '47307' },
        { naselje: 'Gornji Stupnik', postanskiBroj: '10255' },
        { naselje: 'Gospić', postanskiBroj: '53000' },
        { naselje: 'Goveđari', postanskiBroj: '20226' },
        { naselje: 'Grab', postanskiBroj: '21242' },
        { naselje: 'Graberje Ivaničko', postanskiBroj: '10313' },
        { naselje: 'Grabovac', postanskiBroj: '21271' },
        { naselje: 'Gračac', postanskiBroj: '23440' },
        { naselje: 'Gračišće', postanskiBroj: '52403' },
        { naselje: 'Gradac', postanskiBroj: '21330' },
        { naselje: 'Gradec', postanskiBroj: '10345' },
        { naselje: 'Gradina', postanskiBroj: '33411' },
        { naselje: 'Gradište', postanskiBroj: '32273' },
        { naselje: 'Grižane', postanskiBroj: '51244' },
        { naselje: 'Grohote', postanskiBroj: '21430' },
        { naselje: 'Grožnjan (Grisignana)', postanskiBroj: '52429' },
        { naselje: 'Grubišno Polje', postanskiBroj: '43290' },
        { naselje: 'Gruda', postanskiBroj: '20215' },
        { naselje: 'Gudovac', postanskiBroj: '43251' },
        { naselje: 'Gunja', postanskiBroj: '32260' },
        { naselje: 'Gušće', postanskiBroj: '44203' },
        { naselje: 'Gvozd', postanskiBroj: '44410' },
        { naselje: 'Hercegovac', postanskiBroj: '43284' },
        { naselje: 'Hlebine', postanskiBroj: '48323' },
        { naselje: 'Hraščina-Trgovišće', postanskiBroj: '49283' },
        { naselje: 'Hreljin', postanskiBroj: '51226' },
        { naselje: 'Hrvace', postanskiBroj: '21233' },
        { naselje: 'Hrvatska Dubica', postanskiBroj: '44450' },
        { naselje: 'Hrvatska Kostajnica', postanskiBroj: '44430' },
        { naselje: 'Hrvatski Leskovac', postanskiBroj: '10251' },
        { naselje: 'Hum na Sutli', postanskiBroj: '49231' },
        { naselje: 'Hvar', postanskiBroj: '21450' },
        { naselje: 'Ičići', postanskiBroj: '51414' },
        { naselje: 'Igrane', postanskiBroj: '21329' },
        { naselje: 'Ilača', postanskiBroj: '32248' },
        { naselje: 'Ilok', postanskiBroj: '32236' },
        { naselje: 'Ilovik', postanskiBroj: '51552' },
        { naselje: 'Imotski', postanskiBroj: '21260' },
        { naselje: 'Ist', postanskiBroj: '23293' },
        { naselje: 'Ivanec', postanskiBroj: '42240' },
        { naselje: 'Ivanić-Grad', postanskiBroj: '10310' },
        { naselje: 'Ivanja Reka', postanskiBroj: '10373' },
        { naselje: 'Ivankovo', postanskiBroj: '32281' },
        { naselje: 'Ivanska', postanskiBroj: '43231' },
        { naselje: 'Jablanac', postanskiBroj: '53287' },
        { naselje: 'Jabukovac', postanskiBroj: '44204' },
        { naselje: 'Jadranovo', postanskiBroj: '51264' },
        { naselje: 'Jakovlje', postanskiBroj: '10297' },
        { naselje: 'Jakšić', postanskiBroj: '34308' },
        { naselje: 'Jalžabet', postanskiBroj: '42203' },
        { naselje: 'Janjina', postanskiBroj: '20246' },
        { naselje: 'Jarmina', postanskiBroj: '32280' },
        { naselje: 'Jasenak', postanskiBroj: '47314' },
        { naselje: 'Jasenice', postanskiBroj: '23243' },
        { naselje: 'Jasenovac', postanskiBroj: '44324' },
        { naselje: 'Jastrebarsko', postanskiBroj: '10450' },
        { naselje: 'Jelsa', postanskiBroj: '21465' },
        { naselje: 'Jesenice', postanskiBroj: '21314' },
        { naselje: 'Jezera', postanskiBroj: '22242' },
        { naselje: 'Jezerane', postanskiBroj: '53262' },
        { naselje: 'Josipdol', postanskiBroj: '47303' },
        { naselje: 'Jurdani', postanskiBroj: '51213' },
        { naselje: 'Kali', postanskiBroj: '23272' },
        { naselje: 'Kalinovac', postanskiBroj: '48361' },
        { naselje: 'Kalje', postanskiBroj: '10456' },
        { naselje: 'Kalnik', postanskiBroj: '48269' },
        { naselje: 'Kamanje', postanskiBroj: '47282' },
        { naselje: 'Kamenmost', postanskiBroj: '21262' },
        { naselje: 'Kanfanar', postanskiBroj: '52352' },
        { naselje: 'Kaniška Iva', postanskiBroj: '43283' },
        { naselje: 'Kapela', postanskiBroj: '43203' },
        { naselje: 'Kaprije', postanskiBroj: '22235' },
        { naselje: 'Kaptol', postanskiBroj: '34334' },
        { naselje: 'Karin', postanskiBroj: '23452' },
        { naselje: 'Karlobag', postanskiBroj: '53288' },
        { naselje: 'Karlovac', postanskiBroj: '47000' },
        { naselje: 'Karojba', postanskiBroj: '52423' },
        { naselje: 'Kastav', postanskiBroj: '51215' },
        { naselje: 'Kašina', postanskiBroj: '10362' },
        { naselje: 'Kašt', postanskiBroj: '47284' },
        { naselje: 'Kaštel Gomilica', postanskiBroj: '21213' },
        { naselje: 'Kaštel Kambelovac', postanskiBroj: '21214' },
        { naselje: 'Kaštel Lukšić', postanskiBroj: '21215' },
        { naselje: 'Kaštel Stari', postanskiBroj: '21216' },
        { naselje: 'Kaštel Sućurac', postanskiBroj: '21212' },
        { naselje: 'Kaštel Štafilić', postanskiBroj: '21217' },
        { naselje: 'Kaštelir (Castelliere)', postanskiBroj: '52464' },
        { naselje: 'Kijevo', postanskiBroj: '22310' },
        { naselje: 'Kistanje', postanskiBroj: '22305' },
        { naselje: 'Klana', postanskiBroj: '51217' },
        { naselje: 'Klanac', postanskiBroj: '53212' },
        { naselje: 'Klanjec', postanskiBroj: '49290' },
        { naselje: 'Klek', postanskiBroj: '20356' },
        { naselje: 'Klenovica', postanskiBroj: '51252' },
        { naselje: 'Klenovnik', postanskiBroj: '42244' },
        { naselje: 'Klis', postanskiBroj: '21231' },
        { naselje: 'Kloštar Ivanić', postanskiBroj: '10312' },
        { naselje: 'Kloštar Podravski', postanskiBroj: '48362' },
        { naselje: 'Kloštar Vojakovački', postanskiBroj: '48264' },
        { naselje: 'Knin', postanskiBroj: '22300' },
        { naselje: 'Kolan', postanskiBroj: '23251' },
        { naselje: 'Koločep', postanskiBroj: '20221' },
        { naselje: 'Komin (Dalma)', postanskiBroj: '20344' },
        { naselje: 'Komin', postanskiBroj: '10383' },
        { naselje: 'Komiža', postanskiBroj: '21485' },
        { naselje: 'Komletinci', postanskiBroj: '32253' },
        { naselje: 'Končanica (Končenice)', postanskiBroj: '43505' },
        { naselje: 'Konjščina', postanskiBroj: '49282' },
        { naselje: 'Koprivnica', postanskiBroj: '48000' },
        { naselje: 'Koprivnički Ivanec', postanskiBroj: '48314' },
        { naselje: 'Korčula', postanskiBroj: '20260' },
        { naselje: 'Korenica', postanskiBroj: '53230' },
        { naselje: 'Kornić', postanskiBroj: '51517' },
        { naselje: 'Koromačno', postanskiBroj: '52222' },
        { naselje: 'Kosinj', postanskiBroj: '53203' },
        { naselje: 'Kostanje', postanskiBroj: '21207' },
        { naselje: 'Kostanjevac', postanskiBroj: '10455' },
        { naselje: 'Kostrena', postanskiBroj: '51221' },
        { naselje: 'Kotoriba', postanskiBroj: '40329' },
        { naselje: 'Kraljevec na Sutli', postanskiBroj: '49294' },
        { naselje: 'Kraljevica', postanskiBroj: '51262' },
        { naselje: 'Krapina', postanskiBroj: '49000' },
        { naselje: 'Krapinske Toplice', postanskiBroj: '49217' },
        { naselje: 'Krapje', postanskiBroj: '44325' },
        { naselje: 'Krasica', postanskiBroj: '51224' },
        { naselje: 'Krasno', postanskiBroj: '53274' },
        { naselje: 'Krašić', postanskiBroj: '10454' },
        { naselje: 'Kratečko', postanskiBroj: '44213' },
        { naselje: 'Kravarsko', postanskiBroj: '10413' },
        { naselje: 'Krivi Put', postanskiBroj: '53271' },
        { naselje: 'Krivodol', postanskiBroj: '21263' },
        { naselje: 'Križ', postanskiBroj: '10314' },
        { naselje: 'Križevci', postanskiBroj: '48260' },
        { naselje: 'Križišće', postanskiBroj: '51241' },
        { naselje: 'Križpolje', postanskiBroj: '53261' },
        { naselje: 'Krk', postanskiBroj: '51500' },
        { naselje: 'Krnica', postanskiBroj: '52208' },
        { naselje: 'Krnjak', postanskiBroj: '47242' },
        { naselje: 'Kršan', postanskiBroj: '52232' },
        { naselje: 'Kučiće', postanskiBroj: '21208' },
        { naselje: 'Kućište', postanskiBroj: '20267' },
        { naselje: 'Kukljica', postanskiBroj: '23271' },
        { naselje: 'Kukuljanovo', postanskiBroj: '51227' },
        { naselje: 'Kula Norinska', postanskiBroj: '20341' },
        { naselje: 'Kumrovec', postanskiBroj: '49295' },
        { naselje: 'Kuna', postanskiBroj: '20243' },
        { naselje: 'Kunovec', postanskiBroj: '48311' },
        { naselje: 'Kupjak', postanskiBroj: '51313' },
        { naselje: 'Kupljenovo', postanskiBroj: '10295' },
        { naselje: 'Kutina', postanskiBroj: '44320' },
        { naselje: 'Kutjevo', postanskiBroj: '34340' },
        { naselje: 'Kuzmica', postanskiBroj: '34311' },
        { naselje: 'Kuželj', postanskiBroj: '51302' },
        { naselje: 'Labin', postanskiBroj: '52220' },
        { naselje: 'Lanišće', postanskiBroj: '52422' },
        { naselje: 'Lasinja', postanskiBroj: '47206' },
        { naselje: 'Lastovo', postanskiBroj: '20290' },
        { naselje: 'Lećevica', postanskiBroj: '21202' },
        { naselje: 'Ledenice', postanskiBroj: '51251' },
        { naselje: 'Legrad', postanskiBroj: '48317' },
        { naselje: 'Lekenik', postanskiBroj: '44272' },
        { naselje: 'Lepajci', postanskiBroj: '49224' },
        { naselje: 'Lepoglava', postanskiBroj: '42250' },
        { naselje: 'Levanjska Varoš', postanskiBroj: '31416' },
        { naselje: 'Lič', postanskiBroj: '51323' },
        { naselje: 'Lički Osik', postanskiBroj: '53201' },
        { naselje: 'Ličko Lešće', postanskiBroj: '53224' },
        { naselje: 'Ličko Petrovo Selo', postanskiBroj: '53233' },
        { naselje: 'Lijevi Dubrovčak', postanskiBroj: '10316' },
        { naselje: 'Lipik', postanskiBroj: '34551' },
        { naselje: 'Lipovac', postanskiBroj: '32246' },
        { naselje: 'Lipovljani', postanskiBroj: '44322' },
        { naselje: 'Livade (Levade)', postanskiBroj: '52427' },
        { naselje: 'Ližnjan (Lisignano)', postanskiBroj: '52204' },
        { naselje: 'Lobor', postanskiBroj: '49253' },
        { naselje: 'Lokva Rogoznica', postanskiBroj: '21317' },
        { naselje: 'Lokve', postanskiBroj: '51316' },
        { naselje: 'Lonjica', postanskiBroj: '10341' },
        { naselje: 'Lopar', postanskiBroj: '51281' },
        { naselje: 'Lopatinec', postanskiBroj: '40311' },
        { naselje: 'Lopud', postanskiBroj: '20222' },
        { naselje: 'Lovas', postanskiBroj: '32237' },
        { naselje: 'Lovinac', postanskiBroj: '53244' },
        { naselje: 'Lovište', postanskiBroj: '20269' },
        { naselje: 'Lovran', postanskiBroj: '51415' },
        { naselje: 'Lovreć', postanskiBroj: '21257' },
        { naselje: 'Lozovac', postanskiBroj: '22221' },
        { naselje: 'Ložišća', postanskiBroj: '21404' },
        { naselje: 'Lučko', postanskiBroj: '10250' },
        { naselje: 'Ludbreg', postanskiBroj: '42230' },
        { naselje: 'Lug (Laskó)', postanskiBroj: '31328' },
        { naselje: 'Luka', postanskiBroj: '10296' },
        { naselje: 'Lukač', postanskiBroj: '33406' },
        { naselje: 'Lukoran', postanskiBroj: '23274' },
        { naselje: 'Lukovdol', postanskiBroj: '51328' },
        { naselje: 'Lukovo Šugarje', postanskiBroj: '53289' },
        { naselje: 'Lumbarda', postanskiBroj: '20263' },
        { naselje: 'Lun', postanskiBroj: '53294' },
        { naselje: 'Lupoglav', postanskiBroj: '52426' },
        { naselje: 'Ljubeščica', postanskiBroj: '42222' },
        { naselje: 'Macinec', postanskiBroj: '40306' },
        { naselje: 'Mače', postanskiBroj: '49251' },
        { naselje: 'Magadenovac', postanskiBroj: '31542' },
        { naselje: 'Mahično', postanskiBroj: '47286' },
        { naselje: 'Makarska', postanskiBroj: '21300' },
        { naselje: 'Mala Subotica', postanskiBroj: '40321' },
        { naselje: 'Mali Bukovec', postanskiBroj: '42231' },
        { naselje: 'Mali Erjavec', postanskiBroj: '47281' },
        { naselje: 'Mali Lošinj', postanskiBroj: '51550' },
        { naselje: 'Malinska', postanskiBroj: '51511' },
        { naselje: 'Maranovići', postanskiBroj: '20224' },
        { naselje: 'Marčana', postanskiBroj: '52206' },
        { naselje: 'Marija Bistrica', postanskiBroj: '49246' },
        { naselje: 'Marija Gorica', postanskiBroj: '10299' },
        { naselje: 'Marijanci', postanskiBroj: '31555' },
        { naselje: 'Marina', postanskiBroj: '21222' },
        { naselje: 'Markušica', postanskiBroj: '32213' },
        { naselje: 'Martinska Ves', postanskiBroj: '44201' },
        { naselje: 'Martinšćica', postanskiBroj: '51556' },
        { naselje: 'Maruševec', postanskiBroj: '42243' },
        { naselje: 'Matulji', postanskiBroj: '51211' },
        { naselje: 'Medulin', postanskiBroj: '52203' },
        { naselje: 'Metković', postanskiBroj: '20350' },
        { naselje: 'Miholjački Poreč', postanskiBroj: '31543' },
        { naselje: 'Mihovljan', postanskiBroj: '49252' },
        { naselje: 'Mikleuš', postanskiBroj: '33517' },
        { naselje: 'Milna', postanskiBroj: '21405' },
        { naselje: 'Mimice', postanskiBroj: '21318' },
        { naselje: 'Mlini', postanskiBroj: '20207' },
        { naselje: 'Mlinište', postanskiBroj: '20353' },
        { naselje: 'Mokošica', postanskiBroj: '20236' },
        { naselje: 'Molat', postanskiBroj: '23292' },
        { naselje: 'Molve', postanskiBroj: '48327' },
        { naselje: 'Momjan (Momiano)', postanskiBroj: '52462' },
        { naselje: 'Moravice', postanskiBroj: '51325' },
        { naselje: 'Mošćenica', postanskiBroj: '44253' },
        { naselje: 'Mošćenička Draga', postanskiBroj: '51417' },
        { naselje: 'Motovun (Montana)', postanskiBroj: '52424' },
        { naselje: 'Mravince', postanskiBroj: '21209' },
        { naselje: 'Mrkopalj', postanskiBroj: '51315' },
        { naselje: 'Mursko Središče', postanskiBroj: '40315' },
        { naselje: 'Murter', postanskiBroj: '22243' },
        { naselje: 'Narta', postanskiBroj: '43247' },
        { naselje: 'Našice', postanskiBroj: '31500' },
        { naselje: 'Nedelišće', postanskiBroj: '40305' },
        { naselje: 'Nedešćina', postanskiBroj: '52231' },
        { naselje: 'Negoslavci', postanskiBroj: '32239' },
        { naselje: 'Neorić', postanskiBroj: '21247' },
        { naselje: 'Nerezine', postanskiBroj: '51554' },
        { naselje: 'Nerežišća', postanskiBroj: '21423' },
        { naselje: 'Netretić', postanskiBroj: '47271' },
        { naselje: 'Neviđane', postanskiBroj: '23264' },
        { naselje: 'Nijemci', postanskiBroj: '32245' },
        { naselje: 'Nin', postanskiBroj: '23232' },
        { naselje: 'Nova Bukovica', postanskiBroj: '33518' },
        { naselje: 'Nova Kapela', postanskiBroj: '10343' },
        { naselje: 'Nova Kapela', postanskiBroj: '35410' },
        { naselje: 'Nova Rača', postanskiBroj: '43272' },
        { naselje: 'Nova Sela', postanskiBroj: '20278' },
        { naselje: 'Nova Vas', postanskiBroj: '52446' },
        { naselje: 'Novalja', postanskiBroj: '53291' },
        { naselje: 'Novi Golubovec', postanskiBroj: '49255' },
        { naselje: 'Novi Marof', postanskiBroj: '42220' },
        { naselje: 'Novi Vinodolski', postanskiBroj: '51250' },
        { naselje: 'Novigrad (Cittanova)', postanskiBroj: '52466' },
        { naselje: 'Novigrad (Dalmacija)', postanskiBroj: '23312' },
        { naselje: 'Novigrad Podravski', postanskiBroj: '48325' },
        { naselje: 'Novo Čiče', postanskiBroj: '10415' },
        { naselje: 'Novo Virje', postanskiBroj: '48355' },
        { naselje: 'Novoselec', postanskiBroj: '10315' },
        { naselje: 'Novska', postanskiBroj: '44330' },
        { naselje: 'Nuštar', postanskiBroj: '32221' },
        { naselje: 'Njivice', postanskiBroj: '51512' },
        { naselje: 'Oborovo', postanskiBroj: '10372' },
        { naselje: 'Obrovac', postanskiBroj: '23450' },
        { naselje: 'Obrovac Sinjski', postanskiBroj: '21241' },
        { naselje: 'Ogulin', postanskiBroj: '47300' },
        { naselje: 'Oklaj', postanskiBroj: '22303' },
        { naselje: 'Okrug Gornji', postanskiBroj: '21223' },
        { naselje: 'Okučani', postanskiBroj: '35430' },
        { naselje: 'Olib', postanskiBroj: '23296' },
        { naselje: 'Omiš', postanskiBroj: '21310' },
        { naselje: 'Omišalj', postanskiBroj: '51513' },
        { naselje: 'Opatija', postanskiBroj: '51410' },
        { naselje: 'Opatovac', postanskiBroj: '32233' },
        { naselje: 'Oprtalj (Portole)', postanskiBroj: '52428' },
        { naselje: 'Opuzen', postanskiBroj: '20355' },
        { naselje: 'Orahovica', postanskiBroj: '33515' },
        { naselje: 'Orašac', postanskiBroj: '20234' },
        { naselje: 'Orebić', postanskiBroj: '20250' },
        { naselje: 'Orehovec', postanskiBroj: '48267' },
        { naselje: 'Orehovica', postanskiBroj: '40322' },
        { naselje: 'Oriovac', postanskiBroj: '35250' },
        { naselje: 'Orle', postanskiBroj: '10411' },
        { naselje: 'Orolik', postanskiBroj: '32243' },
        { naselje: 'Oroslavje', postanskiBroj: '49243' },
        { naselje: 'Osijek', postanskiBroj: '31000' },
        { naselje: 'Oskorušno', postanskiBroj: '20242' },
        { naselje: 'Ostrovo', postanskiBroj: '32211' },
        { naselje: 'Oštarije', postanskiBroj: '47302' },
        { naselje: 'Otočac', postanskiBroj: '53220' },
        { naselje: 'Otok (Dalmacija)', postanskiBroj: '21238' },
        { naselje: 'Otok', postanskiBroj: '32252' },
        { naselje: 'Otrić Seoci', postanskiBroj: '20342' },
        { naselje: 'Ozalj', postanskiBroj: '47280' },
        { naselje: 'Pag', postanskiBroj: '23250' },
        { naselje: 'Pakoštane', postanskiBroj: '23211' },
        { naselje: 'Pakrac', postanskiBroj: '34550' },
        { naselje: 'Pašman', postanskiBroj: '23262' },
        { naselje: 'Pazin', postanskiBroj: '52000' },
        { naselje: 'Perković', postanskiBroj: '22205' },
        { naselje: 'Perušić', postanskiBroj: '53202' },
        { naselje: 'Peteranec', postanskiBroj: '48321' },
        { naselje: 'Petrčane', postanskiBroj: '23231' },
        { naselje: 'Petrijanec', postanskiBroj: '42206' },
        { naselje: 'Petrinja', postanskiBroj: '44250' },
        { naselje: 'Petrovci', postanskiBroj: '32229' },
        { naselje: 'Petrovsko', postanskiBroj: '49234' },
        { naselje: 'Pićan', postanskiBroj: '52332' },
        { naselje: 'Pirovac', postanskiBroj: '22213' },
        { naselje: 'Pisarovina', postanskiBroj: '10451' },
        { naselje: 'Piškorevci', postanskiBroj: '31417' },
        { naselje: 'Pitomača', postanskiBroj: '33405' },
        { naselje: 'Pivnica Slavonska', postanskiBroj: '33533' },
        { naselje: 'Plaški', postanskiBroj: '47304' },
        { naselje: 'Plešce', postanskiBroj: '51303' },
        { naselje: 'Pleternica', postanskiBroj: '34310' },
        { naselje: 'Plitvička Jezera', postanskiBroj: '53231' },
        { naselje: 'Ploče', postanskiBroj: '20340' },
        { naselje: 'Pločice', postanskiBroj: '20218' },
        { naselje: 'Plomin', postanskiBroj: '52234' },
        { naselje: 'Podaca', postanskiBroj: '21335' },
        { naselje: 'Podcrkavlje', postanskiBroj: '35201' },
        { naselje: 'Podgajci Podravski', postanskiBroj: '31552' },
        { naselje: 'Podgora', postanskiBroj: '21327' },
        { naselje: 'Podgorač', postanskiBroj: '31433' },
        { naselje: 'Podlapača', postanskiBroj: '53236' },
        { naselje: 'Podpićan', postanskiBroj: '52333' },
        { naselje: 'Podravska Moslavina', postanskiBroj: '31530' },
        { naselje: 'Podravske Sesvete', postanskiBroj: '48363' },
        { naselje: 'Podstrana', postanskiBroj: '21312' },
        { naselje: 'Podšpilje', postanskiBroj: '21483' },
        { naselje: 'Podturen', postanskiBroj: '40317' },
        { naselje: 'Podvinje', postanskiBroj: '35107' },
        { naselje: 'Pokupsko', postanskiBroj: '10414' },
        { naselje: 'Polača', postanskiBroj: '23423' },
        { naselje: 'Poličnik', postanskiBroj: '23241' },
        { naselje: 'Poljana', postanskiBroj: '34543' },
        { naselje: 'Popovača', postanskiBroj: '44317' },
        { naselje: 'Poreč (Parenzo)', postanskiBroj: '52440' },
        { naselje: 'Posavski Bregi', postanskiBroj: '10311' },
        { naselje: 'Posavski Podgajci', postanskiBroj: '32258' },
        { naselje: 'Posedarje', postanskiBroj: '23242' },
        { naselje: 'Postira', postanskiBroj: '21410' },
        { naselje: 'Potomje', postanskiBroj: '20244' },
        { naselje: 'Povlja', postanskiBroj: '21413' },
        { naselje: 'Povljana', postanskiBroj: '23249' },
        { naselje: 'Poznanovec', postanskiBroj: '49222' },
        { naselje: 'Požega', postanskiBroj: '34000' },
        { naselje: 'Praputnjak', postanskiBroj: '51225' },
        { naselje: 'Pražnica', postanskiBroj: '21424' },
        { naselje: 'Predava', postanskiBroj: '43211' },
        { naselje: 'Pregrada', postanskiBroj: '49218' },
        { naselje: 'Preko', postanskiBroj: '23273' },
        { naselje: 'Prelog', postanskiBroj: '40323' },
        { naselje: 'Premuda', postanskiBroj: '23294' },
        { naselje: 'Preseka', postanskiBroj: '10346' },
        { naselje: 'Prezid', postanskiBroj: '51307' },
        { naselje: 'Prgomelje', postanskiBroj: '43252' },
        { naselje: 'Prgomet', postanskiBroj: '21201' },
        { naselje: 'Pridraga', postanskiBroj: '23226' },
        { naselje: 'Pridvorje', postanskiBroj: '20217' },
        { naselje: 'Prigorje Brdovečko', postanskiBroj: '10291' },
        { naselje: 'Primorski Dolac', postanskiBroj: '21227' },
        { naselje: 'Primošten', postanskiBroj: '22202' },
        { naselje: 'Privlaka (Dalmacija)', postanskiBroj: '23233' },
        { naselje: 'Privlaka', postanskiBroj: '32251' },
        { naselje: 'Promajna', postanskiBroj: '21323' },
        { naselje: 'Prvić Luka', postanskiBroj: '22233' },
        { naselje: 'Prvić Šepurine', postanskiBroj: '22234' },
        { naselje: 'Pučišća', postanskiBroj: '21412' },
        { naselje: 'Pula (Pola)', postanskiBroj: '52100' },
        { naselje: 'Punat', postanskiBroj: '51521' },
        { naselje: 'Punitovci', postanskiBroj: '31424' },
        { naselje: 'Pupnat', postanskiBroj: '20274' },
        { naselje: 'Putniković', postanskiBroj: '20248' },
        { naselje: 'Rab', postanskiBroj: '51280' },
        { naselje: 'Rabac', postanskiBroj: '52221' },
        { naselje: 'Račinovci', postanskiBroj: '32262' },
        { naselje: 'Račišće', postanskiBroj: '20264' },
        { naselje: 'Radatovići', postanskiBroj: '47285' },
        { naselje: 'Radoboj', postanskiBroj: '49232' },
        { naselje: 'Radovan', postanskiBroj: '42242' },
        { naselje: 'Rajevo Selo', postanskiBroj: '32261' },
        { naselje: 'Rajić', postanskiBroj: '44323' },
        { naselje: 'Rakov Potok', postanskiBroj: '10436' },
        { naselje: 'Rakovec', postanskiBroj: '10347' },
        { naselje: 'Rakovica', postanskiBroj: '47245' },
        { naselje: 'Rasinja', postanskiBroj: '48312' },
        { naselje: 'Raša', postanskiBroj: '52223' },
        { naselje: 'Ratkovica', postanskiBroj: '34315' },
        { naselje: 'Rava', postanskiBroj: '23283' },
        { naselje: 'Raven', postanskiBroj: '48265' },
        { naselje: 'Ravna Gora', postanskiBroj: '51314' },
        { naselje: 'Ražanac', postanskiBroj: '23248' },
        { naselje: 'Rečica', postanskiBroj: '47203' },
        { naselje: 'Retkovci', postanskiBroj: '32282' },
        { naselje: 'Ribnik', postanskiBroj: '47272' },
        { naselje: 'Ričice', postanskiBroj: '21267' },
        { naselje: 'Rijeka', postanskiBroj: '51000' },
        { naselje: 'Roč', postanskiBroj: '52425' },
        { naselje: 'Rogotin', postanskiBroj: '20343' },
        { naselje: 'Rogoznica', postanskiBroj: '22203' },
        { naselje: 'Rokovci Andrijaševci', postanskiBroj: '32271' },
        { naselje: 'Rovinj (Rovigno)', postanskiBroj: '52210' },
        { naselje: 'Rovišće', postanskiBroj: '43212' },
        { naselje: 'Runović', postanskiBroj: '21261' },
        { naselje: 'Ružić', postanskiBroj: '22322' },
        { naselje: 'Saborsko', postanskiBroj: '47306' },
        { naselje: 'Sali', postanskiBroj: '23281' },
        { naselje: 'Samobor', postanskiBroj: '10430' },
        { naselje: 'Satnica Đakovačka', postanskiBroj: '31421' },
        { naselje: 'Savudrija (Salvore)', postanskiBroj: '52475' },
        { naselje: 'Sela', postanskiBroj: '44273' },
        { naselje: 'Selca', postanskiBroj: '21425' },
        { naselje: 'Selce', postanskiBroj: '51266' },
        { naselje: 'Selci Đakovački', postanskiBroj: '31415' },
        { naselje: 'Selnica', postanskiBroj: '40314' },
        { naselje: 'Semeljci', postanskiBroj: '31402' },
        { naselje: 'Senj', postanskiBroj: '53270' },
        { naselje: 'Sestrunj', postanskiBroj: '23291' },
        { naselje: 'Sesvete (kod Požege)', postanskiBroj: '34312' },
        { naselje: 'Sesvete', postanskiBroj: '10360' },
        { naselje: 'Sesvete-Kraljevec', postanskiBroj: '10361' },
        { naselje: 'Severin', postanskiBroj: '43274' },
        { naselje: 'Severin na Kupi', postanskiBroj: '51329' },
        { naselje: 'Sibinj', postanskiBroj: '35252' },
        { naselje: 'Silba', postanskiBroj: '23295' },
        { naselje: 'Sinj', postanskiBroj: '21230' },
        { naselje: 'Sirač', postanskiBroj: '43541' },
        { naselje: 'Sisak', postanskiBroj: '44000' },
        { naselje: 'Sisak-Caprag', postanskiBroj: '44010' },
        { naselje: 'Siverić', postanskiBroj: '22321' },
        { naselje: 'Skakavac', postanskiBroj: '47212' },
        { naselje: 'Skrad', postanskiBroj: '51311' },
        { naselje: 'Skradin', postanskiBroj: '22222' },
        { naselje: 'Slakovci', postanskiBroj: '32242' },
        { naselje: 'Slano', postanskiBroj: '20232' },
        { naselje: 'Slatina', postanskiBroj: '33520' },
        { naselje: 'Slatine', postanskiBroj: '21224' },
        { naselje: 'Slavonski Brod', postanskiBroj: '35105' },
        { naselje: 'Slavonski Brod', postanskiBroj: '35106' },
        { naselje: 'Slivno', postanskiBroj: '21272' },
        { naselje: 'Slunj', postanskiBroj: '47240' },
        { naselje: 'Smiljan', postanskiBroj: '53211' },
        { naselje: 'Smokvica', postanskiBroj: '20272' },
        { naselje: 'Sokolovac', postanskiBroj: '48306' },
        { naselje: 'Solin', postanskiBroj: '21210' },
        { naselje: 'Soljani', postanskiBroj: '32255' },
        { naselje: 'Sopje', postanskiBroj: '33525' },
        { naselje: 'Sošice', postanskiBroj: '10457' },
        { naselje: 'Sotin', postanskiBroj: '32232' },
        { naselje: 'Split', postanskiBroj: '21000' },
        { naselje: 'Sračinec', postanskiBroj: '42209' },
        { naselje: 'Srb', postanskiBroj: '23445' },
        { naselje: 'Srinjine', postanskiBroj: '21292' },
        { naselje: 'Stankovci', postanskiBroj: '23422' },
        { naselje: 'Stari Grad', postanskiBroj: '21460' },
        { naselje: 'Stari Jankovci', postanskiBroj: '32241' },
        { naselje: 'Stari Mikanovci', postanskiBroj: '32284' },
        { naselje: 'Starigrad Paklenica', postanskiBroj: '23244' },
        { naselje: 'Staševica', postanskiBroj: '20345' },
        { naselje: 'Stobreč', postanskiBroj: '21311' },
        { naselje: 'Stomorska', postanskiBroj: '21432' },
        { naselje: 'Ston', postanskiBroj: '20230' },
        { naselje: 'Strizivojna', postanskiBroj: '31410' },
        { naselje: 'Strmec Samoborski', postanskiBroj: '10434' },
        { naselje: 'Strošinci', postanskiBroj: '32256' },
        { naselje: 'Stubičke Toplice', postanskiBroj: '49244' },
        { naselje: 'Studenci', postanskiBroj: '21265' },
        { naselje: 'Sućuraj', postanskiBroj: '21469' },
        { naselje: 'Suhopolje', postanskiBroj: '33410' },
        { naselje: 'Sukošan', postanskiBroj: '23206' },
        { naselje: 'Sunja', postanskiBroj: '44210' },
        { naselje: 'Supetar', postanskiBroj: '21400' },
        { naselje: 'Susak', postanskiBroj: '51561' },
        { naselje: 'Sutivan', postanskiBroj: '21403' },
        { naselje: 'Sveta Marija', postanskiBroj: '40326' },
        { naselje: 'Sveta Nedjelja', postanskiBroj: '10431' },
        { naselje: 'Sveti Đurđ', postanskiBroj: '42233' },
        { naselje: 'Sveti Filip i Jakov', postanskiBroj: '23207' },
        { naselje: 'Sveti Ilija', postanskiBroj: '42214' },
        { naselje: 'Sveti Ivan Zelina', postanskiBroj: '10380' },
        { naselje: 'Sveti Ivan Žabno', postanskiBroj: '48214' },
        { naselje: 'Sveti Juraj', postanskiBroj: '53284' },
        { naselje: 'Sveti Križ Začretje', postanskiBroj: '49223' },
        { naselje: 'Sveti Lovreč', postanskiBroj: '52448' },
        { naselje: 'Sveti Martin na Muri', postanskiBroj: '40313' },
        { naselje: 'Sveti Martin pod Okićem', postanskiBroj: '10435' },
        { naselje: 'Sveti Petar u šumi', postanskiBroj: '52404' },
        { naselje: 'Svetvinčenat', postanskiBroj: '52342' },
        { naselje: 'Šandrovac', postanskiBroj: '43227' },
        { naselje: 'Šapjane', postanskiBroj: '51214' },
        { naselje: 'Šarengrad', postanskiBroj: '32234' },
        { naselje: 'Šenkovec', postanskiBroj: '10292' },
        { naselje: 'Šestanovac', postanskiBroj: '21250' },
        { naselje: 'Šibenik', postanskiBroj: '22000' },
        { naselje: 'Šibenik-Zablaće', postanskiBroj: '22030' },
        { naselje: 'Šilo', postanskiBroj: '51515' },
        { naselje: 'Šipanjska Luka', postanskiBroj: '20223' },
        { naselje: 'Široke', postanskiBroj: '22204' },
        { naselje: 'Šišljavić', postanskiBroj: '47204' },
        { naselje: 'Škabrnja', postanskiBroj: '23223' },
        { naselje: 'Škrljevo', postanskiBroj: '51223' },
        { naselje: 'Šmrika', postanskiBroj: '51263' },
        { naselje: 'Špišić Bukovica', postanskiBroj: '33404' },
        { naselje: 'Štefanje', postanskiBroj: '43246' },
        { naselje: 'Štitar', postanskiBroj: '32274' },
        { naselje: 'Štrigova', postanskiBroj: '40312' },
        { naselje: 'Šušnjevica', postanskiBroj: '52233' },
        { naselje: 'Tar (Torre)', postanskiBroj: '52465' },
        { naselje: 'Tijarica', postanskiBroj: '21245' },
        { naselje: 'Tinjan', postanskiBroj: '52444' },
        { naselje: 'Tisno', postanskiBroj: '22240' },
        { naselje: 'Tkon', postanskiBroj: '23212' },
        { naselje: 'Topolo', postanskiBroj: '20205' },
        { naselje: 'Topolovac', postanskiBroj: '44202' },
        { naselje: 'Topusko', postanskiBroj: '44415' },
        { naselje: 'Tordinci', postanskiBroj: '32214' },
        { naselje: 'Tounj', postanskiBroj: '47264' },
        { naselje: 'Tovarnik', postanskiBroj: '32249' },
        { naselje: 'Trakošćan', postanskiBroj: '42254' },
        { naselje: 'Trget', postanskiBroj: '52224' },
        { naselje: 'Tribalj', postanskiBroj: '51243' },
        { naselje: 'Tribanj', postanskiBroj: '23245' },
        { naselje: 'Tribunj', postanskiBroj: '22212' },
        { naselje: 'Trilj', postanskiBroj: '21240' },
        { naselje: 'Trnava', postanskiBroj: '31411' },
        { naselje: 'Trnovec Bartolovečki', postanskiBroj: '42202' },
        { naselje: 'Trnovitički Popovac', postanskiBroj: '43233' },
        { naselje: 'Trogir', postanskiBroj: '21220' },
        { naselje: 'Trpanj', postanskiBroj: '20240' },
        { naselje: 'Trpinja', postanskiBroj: '32224' },
        { naselje: 'Trstenik', postanskiBroj: '20245' },
        { naselje: 'Trsteno', postanskiBroj: '20233' },
        { naselje: 'Tršće', postanskiBroj: '51305' },
        { naselje: 'Tučepi', postanskiBroj: '21325' },
        { naselje: 'Tugare', postanskiBroj: '21252' },
        { naselje: 'Tuhelj', postanskiBroj: '49215' },
        { naselje: 'Turčin', postanskiBroj: '42204' },
        { naselje: 'Udbina', postanskiBroj: '53234' },
        { naselje: 'Ugljan', postanskiBroj: '23275' },
        { naselje: 'Ugljane', postanskiBroj: '21243' },
        { naselje: 'Uljanik', postanskiBroj: '43507' },
        { naselje: 'Umag (Umago)', postanskiBroj: '52470' },
        { naselje: 'Unešić', postanskiBroj: '22323' },
        { naselje: 'Unije', postanskiBroj: '51562' },
        { naselje: 'Valpovo', postanskiBroj: '31550' },
        { naselje: 'Varaždin', postanskiBroj: '42000' },
        { naselje: 'Varaždinske Toplice', postanskiBroj: '42223' },
        { naselje: 'Vela Luka', postanskiBroj: '20270' },
        { naselje: 'Vele Mune', postanskiBroj: '51212' },
        { naselje: 'Veli Iž', postanskiBroj: '23284' },
        { naselje: 'Veli Lošinj', postanskiBroj: '51551' },
        { naselje: 'Veli Rat', postanskiBroj: '23287' },
        { naselje: 'Velika', postanskiBroj: '34330' },
        { naselje: 'Velika Gorica', postanskiBroj: '10410' },
        { naselje: 'Velika Ludina', postanskiBroj: '44316' },
        { naselje: 'Velika Mlaka', postanskiBroj: '10408' },
        { naselje: 'Veliki Bastaji', postanskiBroj: '43531' },
        { naselje: 'Veliki Grđevac', postanskiBroj: '43270' },
        { naselje: 'Veliki Prolog', postanskiBroj: '21277' },
        { naselje: 'Veliki Zdenci', postanskiBroj: '43293' },
        { naselje: 'Veliko Trgovišće', postanskiBroj: '49214' },
        { naselje: 'Veliko Trojstvo', postanskiBroj: '43226' },
        { naselje: 'Veliko Vukovje', postanskiBroj: '43282' },
        { naselje: 'Vetovo', postanskiBroj: '34335' },
        { naselje: 'Vid', postanskiBroj: '20352' },
        { naselje: 'Vidovec', postanskiBroj: '42205' },
        { naselje: 'Viljevo', postanskiBroj: '31531' },
        { naselje: 'Vinica', postanskiBroj: '42207' },
        { naselje: 'Vinišće', postanskiBroj: '21226' },
        { naselje: 'Vinjerac', postanskiBroj: '23247' },
        { naselje: 'Vinkovci', postanskiBroj: '32100' },
        { naselje: 'Vir', postanskiBroj: '23234' },
        { naselje: 'Virje', postanskiBroj: '48326' },
        { naselje: 'Virovitica', postanskiBroj: '33000' },
        { naselje: 'Vis', postanskiBroj: '21480' },
        { naselje: 'Viškovci', postanskiBroj: '31401' },
        { naselje: 'Viškovo', postanskiBroj: '51216' },
        { naselje: 'Višnjan (Visignano)', postanskiBroj: '52463' },
        { naselje: 'Vivodina', postanskiBroj: '47283' },
        { naselje: 'Vižinada (Visinada)', postanskiBroj: '52447' },
        { naselje: 'Vladislavci', postanskiBroj: '31404' },
        { naselje: 'Voćin', postanskiBroj: '33522' },
        { naselje: 'Vodice', postanskiBroj: '22211' },
        { naselje: 'Vodnjan (Dignano)', postanskiBroj: '52215' },
        { naselje: 'Vođinci', postanskiBroj: '32283' },
        { naselje: 'Vojnić', postanskiBroj: '47220' },
        { naselje: 'Voloder', postanskiBroj: '44318' },
        { naselje: 'Vratnik', postanskiBroj: '53273' },
        { naselje: 'Vrbanj', postanskiBroj: '21462' },
        { naselje: 'Vrbanja', postanskiBroj: '32254' },
        { naselje: 'Vrbnik', postanskiBroj: '51516' },
        { naselje: 'Vrboska', postanskiBroj: '21463' },
        { naselje: 'Vrbovec', postanskiBroj: '10340' },
        { naselje: 'Vrbovsko', postanskiBroj: '51326' },
        { naselje: 'Vrgorac', postanskiBroj: '21276' },
        { naselje: 'Vrhovine', postanskiBroj: '53223' },
        { naselje: 'Vrlika', postanskiBroj: '21236' },
        { naselje: 'Vrsar (Orsera)', postanskiBroj: '52450' },
        { naselje: 'Vrsi', postanskiBroj: '23235' },
        { naselje: 'Vuka', postanskiBroj: '31403' },
        { naselje: 'Vukovar', postanskiBroj: '32000' },
        { naselje: 'Vukovar', postanskiBroj: '32010' },
        { naselje: 'Vukovina', postanskiBroj: '10419' },
        { naselje: 'Zabok', postanskiBroj: '49210' },
        { naselje: 'Zadar', postanskiBroj: '23000' },
        { naselje: 'Zadvarje', postanskiBroj: '21255' },
        { naselje: 'Zagorska Sela', postanskiBroj: '49296' },
        { naselje: 'Zagreb', postanskiBroj: '10000' },
        { naselje: 'Zagreb', postanskiBroj: '10110' },
        { naselje: 'Zagreb-Dubrava', postanskiBroj: '10040' },
        { naselje: 'Zagreb-Novi Zagreb', postanskiBroj: '10020' },
        { naselje: 'Zagreb-Sloboština', postanskiBroj: '10010' },
        { naselje: 'Zagreb-Susedgrad', postanskiBroj: '10090' },
        { naselje: 'Zagvozd', postanskiBroj: '21270' },
        { naselje: 'Zaostrog', postanskiBroj: '21334' },
        { naselje: 'Zapolje', postanskiBroj: '35422' },
        { naselje: 'Zaprešić', postanskiBroj: '10290' },
        { naselje: 'Zastražišće', postanskiBroj: '21466' },
        { naselje: 'Zaton', postanskiBroj: '22215' },
        { naselje: 'Zaton Veliki', postanskiBroj: '20235' },
        { naselje: 'Zdenci', postanskiBroj: '33513' },
        { naselje: 'Zemunik', postanskiBroj: '23222' },
        { naselje: 'Zlarin', postanskiBroj: '22232' },
        { naselje: 'Zlatar', postanskiBroj: '49250' },
        { naselje: 'Zlatar Bistrica', postanskiBroj: '49247' },
        { naselje: 'Zlobin', postanskiBroj: '51324' },
        { naselje: 'Zmijavci', postanskiBroj: '21266' },
        { naselje: 'Zrinski Topolovac', postanskiBroj: '43202' },
        { naselje: 'Zubovići', postanskiBroj: '53296' },
        { naselje: 'Zvečaj', postanskiBroj: '47261' },
        { naselje: 'Žakanje', postanskiBroj: '47276' },
        { naselje: 'Ždala', postanskiBroj: '48332' },
        { naselje: 'Ždrelac', postanskiBroj: '23263' },
        { naselje: 'Žirje', postanskiBroj: '22236' },
        { naselje: 'Žman', postanskiBroj: '23282' },
        { naselje: 'Žminj', postanskiBroj: '52341' },
        { naselje: 'Žrnovnica', postanskiBroj: '21251' },
        { naselje: 'Žrnovo', postanskiBroj: '20275' },
        { naselje: 'Žuljana', postanskiBroj: '20247' },
        { naselje: 'Župa', postanskiBroj: '21273' },
        { naselje: 'Županja', postanskiBroj: '32270' }
    ]
};
